import * as Types from './types';

export const setOpen = (payload: boolean) => ({
  type: Types.DISCLAIMER_MODAL_SET_OPEN,
  payload,
});
export const showCloseButton = (payload: boolean) => ({
  type: Types.DISCLAIMER_MODAL_SHOW_CLOSE_BUTTON,
  payload,
});
