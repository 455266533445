import React from 'react';
import { NotificationContext, NotificationMessage } from '../../NotificationContextProvider';
import NotificationItem from './NotificationItem';
import { NotificationTypes } from '../../constants/Enums';

import './NotificationBanner.less';

interface ErrorBannerProps {
  location: number;
}

const NotificationBanner = (props: ErrorBannerProps) => {
  const {
    errors,
    clearErrors,
    showAllErrors,
    updateShowAllErrors,
    removeError,
    warnings,
    clearWarnings,
    showAllWarnings,
    updateShowAllWarnings,
    removeWarning,
  } = React.useContext(NotificationContext);

  const { location } = props;
  const shouldShowAllErros = showAllErrors.includes(location);
  const shouldShowAllWarnings = showAllWarnings.includes(location);
  const locationFilteredErrors = errors.filter((error: NotificationMessage) => error.location === location);
  const locationFilteredWarnings = warnings.filter((warning: NotificationMessage) => warning.location === location);

  if (!locationFilteredErrors.length && !locationFilteredWarnings.length) {
    return null;
  }

  return (
    <div>
      <div className="linq-banner linq-banner--error">
        {locationFilteredErrors.map((error: NotificationMessage, index: number) => {
          if (index === 0 || shouldShowAllErros) {
            return (
              <NotificationItem
                notification={error.message}
                onCloseClick={() => removeError(index)}
                totalNotifications={locationFilteredErrors.length}
                isFirst={index === 0}
                showAllNotifications={shouldShowAllErros}
                onViewAllClick={() => updateShowAllErrors(location)}
                onClearAllClick={() => clearErrors(location)}
                key={index}
                type={NotificationTypes.Error}
              />
            );
          }
          return null;
        })}
      </div>
      <div className="linq-banner linq-banner--warn">
        {locationFilteredWarnings.map((warning: NotificationMessage, index: number) => {
          if (index === 0 || shouldShowAllWarnings) {
            return (
              <NotificationItem
                notification={warning.message}
                onCloseClick={() => removeWarning(index)}
                totalNotifications={locationFilteredWarnings.length}
                isFirst={index === 0}
                showAllNotifications={shouldShowAllWarnings}
                onViewAllClick={() => updateShowAllWarnings(location)}
                onClearAllClick={() => clearWarnings(location)}
                key={index}
                type={NotificationTypes.Warning}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default NotificationBanner;
