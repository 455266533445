import React, { FC, useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import numeral from 'numeral';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';
import { cloneDeep } from 'lodash';
import { useFormik } from 'formik';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from 'Components/Button/Button';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import NutritionFacts from 'Components/NutritionFacts/NutritionFacts';
import SpecialMealAccommodations from 'Components/SpecialMealAccommodations/SpecialMealAccommodations';
import { FoodGroupSelector } from 'Components/Form/FoodGroupSelector';
import * as CommonSelectors from 'State/selectors';
import * as CommonActions from 'State/actions';
import * as CartActions from 'Components/Shop/Cart/state/actions';
import { Drawer, DrawerFooter, DrawerHeader, DrawerContent } from 'Components/Drawer';
import * as MenuItemModalSelectors from './state/selectors';
import * as MenuItemModalActions from './state/actions';
import * as MenuTypePickerSelectors from 'Components/MenuTypePicker/state/selectors';
import * as CancelOrderModalActions from 'Components/Shop/CancelOrderModal/state/actions';
import * as ShopSelectors from 'Pages/Shop/state/selectors';
import * as Constants from 'Constants/Constants';
import * as Interfaces from 'Interfaces/Interfaces';
import { Role, isAuthorized, TokenClaims } from 'Auth/User';
import { PageLoader } from 'Components/Loaders/Loaders';
import { canSubmitForm } from './menuItemUtilities';
import { getProductPrice } from 'Components/Shop/ShopUtil';
import { InputField } from 'Components/Form/MaterialForm';
import { InputMode, SidebarLocations } from 'Constants/Enums';
import { Delete } from '@material-ui/icons';
import { Divider, IconButton } from '@material-ui/core';
import { getMenuItems } from '../Cart/state/selectors';
import { NotificationDialog } from 'Components/Dialogs/NotificationDialog';

import './MenuItemModal.less';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';

const convertMenuItemToCartItem = (
  menuItem: Interfaces.MenuItem,
  price: number,
  products: Record<string, Interfaces.Product>,
  siteId: string,
): Interfaces.CartItem => {
  const product = menuItem.product || products[menuItem.productId] || {};
  return {
    count: 0,
    productId: menuItem.productId,
    mealTypeId: menuItem.mealTypeId,
    date: menuItem.date,
    id: menuItem.id,
    productName: product?.name || '',
    productCategoryId: product?.productCategoryId,
    price,
    siteId,
  };
};

const MenuItemModal: FC = () => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const showModal = useSelector((state: Interfaces.AppState) =>
    CommonSelectors.getSidebarOpen(state, SidebarLocations.MENU_ITEM_SIDE_BAR),
  );
  const menuItemId = useSelector(MenuItemModalSelectors.getMenuItemId);
  const ordered = useSelector(MenuItemModalSelectors.isExistingOrder);
  const productId = useSelector(MenuItemModalSelectors.getProductId);
  const menuTypeId = useSelector(MenuItemModalSelectors.getMenuTypeId);
  const menuTypes = useSelector(MenuTypePickerSelectors.getMenuTypes);
  const orderItems = useSelector(MenuItemModalSelectors.getOrderItems);
  const menuItems = useSelector(CommonSelectors.getMenuItems);
  const menuItemsGrouped = useSelector(MenuItemModalSelectors.getMenuItemsByDateGrouped);
  const selectedFoodGroupOptions = useSelector(MenuItemModalSelectors.getSelectedFoodGroupOptions);
  const pickupLocationId = useSelector(MenuItemModalSelectors.getPickupLocationId);
  const products = useSelector(CommonSelectors.getProducts);
  const menuItem = menuItems[menuItemId] as Interfaces.MenuItem;
  let menuType = menuTypes[menuItem?.mealTypeId] as Interfaces.MenuType;
  if (!menuType && menuTypeId) {
    menuType = menuTypes[menuTypeId] as Interfaces.MenuType;
  }
  const pickupLocations = useSelector(CommonSelectors.getPickupLocationsWithDefault);
  const pickupLocation = pickupLocations[pickupLocationId] as Interfaces.PickupLocation;
  const [foodGroupOptions, setFoodGroupOptions] = useState<{ [key: string]: Interfaces.ProductCategory }>({});
  let product = products[menuItem?.productId] as Interfaces.Product;
  if (!product && productId) {
    product = products[productId as string];
  }
  const productCategory = Constants.PRODUCT_CATEGORIES[product?.productCategoryId];
  const student = useSelector(ShopSelectors.getStudent);
  const price = getProductPrice(
    product,
    student?.siteGroupTypeId,
    useSelector(ShopSelectors.getProductPrice(userRole)),
  );
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const isALaCarte = product?.productCategoryId === Constants.ALACARTE_KEY;
  const cartItems = useSelector(getMenuItems);
  const cartItem = cartItems[`${menuItem?.productId}.${menuItem?.mealTypeId}.${menuItem?.date}`];
  const pickupLocationOptions = useSelector(CommonSelectors.getPickupLocationOptions);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const siteId = useSelector(ShopSelectors.getSiteId);

  const formik = useFormik({
    initialValues: {
      quantity: cartItem?.count || 1,
    },
    onSubmit: async (values) => {
      handleAddToOrder();
    },
    enableReinitialize: true,
  });

  const displayPrice = isAuthorized(userRole, [Role.PARENT]) ? numeral(price).format(Constants.USD_FORMAT) : '';

  const handleCloseClick = useCallback(() => {
    dispatch(CommonActions.setSidebarOpen(false, SidebarLocations.MENU_ITEM_SIDE_BAR));
    dispatch(MenuItemModalActions.setSelectedFoodGroupOptions({}));
  }, [dispatch]);

  const handleFoodGroupOnChange = useCallback(
    (productCategoryId, menuItem) => {
      const options = cloneDeep(selectedFoodGroupOptions);
      if (!options[productCategoryId] || menuItem === null) {
        options[productCategoryId] = [];
      }

      // Toggle menuItemIds for each Product Category and check allowed multiple categories
      if (Constants.FOOD_GROUPS_MULTIPLE_ALLOWED.includes(productCategoryId)) {
        if (options[productCategoryId].some((item) => item.id === menuItem.id)) {
          options[productCategoryId] = options[productCategoryId].filter((item) => item.id !== menuItem.id);
        } else {
          options[productCategoryId].push(menuItem);
        }
      } else if (Constants.FOOD_GROUPS_MULTIPLE_NOT_ALLOWED.includes(productCategoryId)) {
        if (Constants.FOOD_GROUPS_REQUIRED.includes(productCategoryId)) {
          options[productCategoryId] = [menuItem];
        } else if (Constants.FOOD_GROUPS_OPTIONAL.includes(productCategoryId)) {
          if (options[productCategoryId].some((item) => item.id === menuItem.id)) {
            options[productCategoryId] = options[productCategoryId].filter((item) => item.id !== menuItem.id);
          } else {
            options[productCategoryId] = [menuItem];
          }
        }
      }

      dispatch(MenuItemModalActions.setSelectedFoodGroupOptions(options));
    },
    [dispatch, selectedFoodGroupOptions],
  );

  const handleAddToOrder = useCallback(() => {
    let item: Interfaces.CartItem = convertMenuItemToCartItem(
      menuItem,
      price ?? 0,
      products,
      siteId ?? student?.siteId,
    );

    if (!cartItem) {
      const count = isALaCarte ? formik.values.quantity : 1;
      dispatch(CartActions.addItem(item, count, pickupLocationOptions));
    } else if (cartItem && isALaCarte) {
      const cartCount = cartItem.count;
      item = convertMenuItemToCartItem(menuItem, price ?? 0, products, siteId ?? student?.siteId);
      dispatch(CartActions.addItem(item, formik.values.quantity - cartCount, pickupLocationOptions));
    }

    if (item.productCategoryId !== Constants.PRODUCT_CATEGORIES.ALACARTE.id) {
      Object.values(selectedFoodGroupOptions as Interfaces.FoodGroupOptions).forEach(
        (childItems: Interfaces.MenuItem[] | null) => {
          if (childItems) {
            childItems.forEach((childItem) => {
              const childCartItem = convertMenuItemToCartItem(
                childItem,
                price ?? 0,
                products,
                siteId ?? student?.siteId,
              );
              dispatch(CartActions.addChildItem(menuItem.id, childCartItem));
            });
          }
        },
      );
    }
    dispatch(CommonActions.setSidebarOpen(false, SidebarLocations.MENU_ITEM_SIDE_BAR));
    dispatch(MenuItemModalActions.setSelectedFoodGroupOptions({}));
  }, [
    dispatch,
    menuItem,
    price,
    products,
    selectedFoodGroupOptions,
    cartItem,
    formik.values.quantity,
    isALaCarte,
    pickupLocationOptions,
    student?.siteId,
    siteId,
  ]);

  const handleDeleteOrder = () => {
    dispatch(CancelOrderModalActions.setOpen(true, orderItems));
  };

  useEffect(() => {
    setFoodGroupOptions(
      Object.values(Constants.FOOD_GROUPS).reduce((acc, foodGroup) => {
        if (menuItemsGrouped[foodGroup.id]) {
          acc[foodGroup.id] = foodGroup;
        }
        return acc;
      }, {} as any),
    );
  }, [menuItemsGrouped, setFoodGroupOptions, menuItemId]);

  const isInPast = useMemo(() => {
    const orderItemValues = Object.values(orderItems);
    if (orderItemValues.length) {
      return moment(orderItemValues[0].date).isBefore(moment(), 'day');
    }
    return false;
  }, [orderItems]);

  if (!product) {
    return null;
  }

  return (
    <Drawer
      anchor="right"
      open={showModal}
      onClose={() => {
        dispatch(CommonActions.setSidebarOpen(false, SidebarLocations.MENU_ITEM_SIDE_BAR));
        const buttonInGrid: HTMLButtonElement | null = document.querySelector(`[data-menu-id="${menuItemId}"]`);
        if (buttonInGrid) {
          buttonInGrid.focus();
        }
      }}
      className={classNames('menu-item-modal-container', { mobile: isMobile })}
    >
      <PageLoader />
      <form onSubmit={formik.handleSubmit} className="linq-drawer-form-content menu-item-modal-content">
        <DrawerHeader aria-label="Header for the current menu item.">
          <h2>{ordered && !isInPast ? 'Cancel Order' : 'Add Item To Cart'}</h2>
          <CloseIconButton onClick={handleCloseClick} />
        </DrawerHeader>
        <NotificationBanner location={SidebarLocations.MENU_ITEM_SIDE_BAR} />
        <DrawerContent id="menu-item-modal-content" className="menu-item-modal-content">
          <div className="product-category">{`${menuType?.name || ''} ${productCategory?.name}`}</div>
          <div className="name">{product.name}</div>
          <div className="price">{displayPrice}</div>
          <div className="description">{product.description}</div>
          <Divider />
          <Accordion className="menu-item-accordion">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <h4>View Ingredients</h4>
            </AccordionSummary>
            <AccordionDetails>
              <div className="ingredients">{product.ingredients}</div>
            </AccordionDetails>
          </Accordion>
          <Divider />
          <Accordion className="menu-item-accordion">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <h4>View Nutritional Information</h4>
            </AccordionSummary>
            <AccordionDetails>
              <NutritionFacts product={products[product.id]} />
            </AccordionDetails>
          </Accordion>
          <Divider />
          <Accordion className="menu-item-accordion">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <h4>View Image</h4>
            </AccordionSummary>
            <AccordionDetails>
              <img className="menu-item-accordion__product-image" src={product.imageUrl} alt={product.name} />
            </AccordionDetails>
          </Accordion>
          <Divider />
          {isALaCarte && <div>{product?.specialMealNotes}</div>}
          {isALaCarte && (
            <div className="order-details">
              <div className="header">Order Details</div>
              <div className="date">{moment(menuItem.date).format(Constants.DATE_FORMAT_DMD)}</div>
              <div className="oo-row">
                <div className="menu-type heading">{menuType?.name}</div>
                <div className="quantity">Quantity</div>
                <div className="total">Total</div>
                <div className="delete" />
              </div>
              <div className="oo-row">
                <div className="menu-type">{menuItem?.product?.name}</div>
                <div className="quantity">
                  <InputField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.quantity}
                    id="quantity"
                    name="quantity"
                    mode={InputMode.Integer}
                    type="number"
                    min={1}
                    style={{ marginBottom: 0, marginTop: 8 }}
                    inputProps={{
                      'data-test-id': 'quantityInput',
                    }}
                  />
                </div>
                <div className="total">
                  {price && numeral(price * formik.values.quantity).format(Constants.USD_FORMAT)}
                </div>
                <div className="delete">
                  {cartItem && (
                    <IconButton type="button" onClick={() => setShowDeleteDialog(true)}>
                      <Delete />
                    </IconButton>
                  )}
                </div>
              </div>
            </div>
          )}
          {!isALaCarte && (
            <div>
              <SpecialMealAccommodations
                specialMealAccommodationIds={product.specialMealAccommodationIds}
                customSpecialMealAccommodations={product.customSpecialMealAccommodations}
              />
              <div className="food-groups">
                {Object.values(foodGroupOptions).map((foodGroup) => {
                  const options = menuItemsGrouped[foodGroup.id];

                  if (!options || !options.length) {
                    return null;
                  }

                  return (
                    <FoodGroupSelector
                      products={products}
                      productCategoryId={foodGroup.id}
                      options={menuItemsGrouped[foodGroup.id]}
                      onChange={handleFoodGroupOnChange}
                      ordered={ordered}
                      foodGroupName={foodGroup.name}
                      allowNoneOption={false}
                      selectedOptions={selectedFoodGroupOptions}
                      key={foodGroup.id}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {ordered && pickupLocation && (
            <div className="pickup-location">
              <div className="label">Pickup Location</div>
              <div className="value">{pickupLocation.name}</div>
            </div>
          )}
        </DrawerContent>
        <DrawerFooter className="footer" aria-label="Footer for the Menu Item Form">
          {ordered && !isInPast && (
            <Button type="button" variant="contained" color="primary" onClick={handleDeleteOrder} fullWidth>
              Cancel Order
            </Button>
          )}
          {!ordered && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                formik.submitForm();
              }}
              fullWidth
              variant="contained"
              color="primary"
              disabled={
                !canSubmitForm(
                  product,
                  selectedFoodGroupOptions,
                  Object.keys(foodGroupOptions) as Constants.FOOD_GROUP_TYPES[],
                ) || !student
              }
              type="submit"
              data-test-id={cartItem ? 'updateOrderBtn' : 'addToOrderBtn'}
            >
              {cartItem ? 'Update Order' : 'Add To Order'}
            </Button>
          )}
        </DrawerFooter>
      </form>
      <NotificationDialog
        showDialog={showDeleteDialog}
        title="Remove Cart Item"
        message="Are you sure you want to remove this item from your cart?"
        okButtonText="Ok"
        onOkClick={() => {
          if (cartItem) {
            dispatch(CartActions.removeItem(cartItem, -cartItem.count));
            dispatch(CommonActions.setSidebarOpen(false, SidebarLocations.MENU_ITEM_SIDE_BAR));
          }
          setShowDeleteDialog(false);
        }}
        showCancel={true}
        cancelButtonText="Cancel"
        onCancelClick={() => setShowDeleteDialog(false)}
      />
    </Drawer>
  );
};

export default MenuItemModal;
