import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, FieldProps, FormikValues, FormikProps } from 'formik';
import * as yup from 'yup';
import MultiActionCard from 'Components/MultiActionCard/MultiActionCard';
import { useAuth0 } from '@auth0/auth0-react';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { Link } from '@material-ui/icons';
import { InputField } from 'Components/Form/MaterialForm';
import { useCommonActions } from 'State/actions';
import * as CommonSelectors from 'State/selectors';
import { MOBILE_MEDIA_QUERY } from 'Constants/Constants';
import { TokenClaims } from 'Auth/User';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import { CalloutCard } from 'Components/CalloutCard/CalloutCard';

import './PaymentProvider.less';

const PaymentProvider = () => {
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);
  const districts = useSelector(CommonSelectors.getDistricts);
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY });
  const { updateDistrict } = useCommonActions();

  const [loading, setLoading] = useState(false);
  const district = districts[districtId];

  const validationSchema = yup.object().shape({
    paymentProviderLink: yup.string().required('Required Field').url('Invalid Link'),
  });

  const handleSubmit = async (values: FormikValues) => {
    setLoading(true);
    const newDistrict = {
      paymentProviderLink: values.paymentProviderLink,
    };
    await updateDistrict(newDistrict);
    setLoading(false);
  };

  return (
    <div className={classNames('payment-provider-container', { 'payment-provider-container-mobile': isMobile })}>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{
          paymentProviderLink: district?.paymentProviderLink || '',
        }}
      >
        {({ resetForm, dirty }: FormikProps<{}>) => (
          <Form>
            <SubHeader title="Configure Payment Provider" />
            <div className="page-content-wrapper">
              <div className="oo-row">
                <div>
                  <CalloutCard title="About Payment Link" icon={<Link />}>
                    <p>
                      The payment link will direct parent users to the correct payment center when they select "Add to
                      Balance" on their student's profile or order confirmation page.
                    </p>
                  </CalloutCard>
                </div>
                <MultiActionCard
                  title="Payment Provider Link"
                  description="Please enter your payment provider link."
                  onCancelAction={resetForm}
                  cancelActionDisabled={!dirty}
                  cancelActionLabel={'Cancel'}
                  cancelActionAriaLabel={'Cancel'}
                  submitDisabled={loading}
                  submitLabel={'Save'}
                  submitAriaLabel={'Submit'}
                  loading={loading}
                  testIds={{
                    cancel: 'cancelBtn',
                    submit: 'submitBtn',
                  }}
                >
                  <div>
                    <Field name="paymentProviderLink">
                      {({ field, meta }: FieldProps) => (
                        <InputField
                          id="paymentProviderLink"
                          name="paymentProviderLink"
                          value={field.value}
                          label="Payment Provider Link"
                          onChange={field.onChange}
                          autoComplete="off"
                          touched={meta.touched}
                          error={!!meta.error}
                          errorMsg={meta.error}
                          inputProps={{
                            'data-test-id': 'paymentProviderInput',
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </MultiActionCard>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PaymentProvider;
