import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Formik, FormikProps, Form, FormikValues, Field, FieldProps } from 'formik';
import { FormControl, Divider } from '@material-ui/core';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'Components/Drawer';
import { SelectField } from 'Components/Form/MaterialForm';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import TimePicker from 'Components/TimePicker/TimePicker';
import Button from 'Components/Button/Button';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';
import { useCommonActions } from 'State/actions';
import * as Constants from 'Constants/Constants';
import { SidebarLocations } from 'Constants/Enums';
import { OrderingRules } from 'Interfaces/Interfaces';

import './OrderingRulesSidebar.less';

export interface OrderingRulesSidebarProps {
  open: boolean;
  orderingRules: OrderingRules;
  setSidebarOpen: (open: boolean) => void;
}

const OrderingRulesSidebar: FC<OrderingRulesSidebarProps> = ({ open, orderingRules, setSidebarOpen }) => {
  const [loading, setLoading] = useState(false);
  const [orderRange, setOrderRange] = useState(
    orderingRules?.orderRange ?? Constants.ORDER_RANGE_OPTIONS.DAILY.toString(),
  );
  const [maxOrderRange, setMaxOrderRange] = useState(
    orderingRules?.maxOrderRange || Constants.MAX_ORDER_RANGE_OPTIONS.DAYS.toString(),
  );
  const [teacherMaxOrderRange, setTeacherMaxOrderRange] = useState(
    orderingRules?.teacherMaxOrderRange || Constants.MAX_ORDER_RANGE_OPTIONS.DAYS.toString(),
  );
  const [teacherOrderRange, setTeacherOrderRange] = useState(Constants.ORDER_RANGE_OPTIONS.DAILY.toString());
  const { updateDistrict } = useCommonActions();

  const initialValues = {
    orderRange: orderingRules?.orderRange || '',
    orderDueBy: orderingRules?.orderDueBy || '',
    orderDueByMonthly: orderingRules?.orderDueByMonthly || '',
    cutoffDay: orderingRules?.cutoffDay || '',
    cutoffTime: orderingRules?.cutoffTime || '',
    maxCutoffTime: orderingRules?.maxCutoffTime || '',
    maxOrderRange: orderingRules?.maxOrderRange || '',
    maxCutoffDays: orderingRules?.maxCutoffDays || '',
    maxCutoffWeeks: orderingRules?.maxCutoffWeeks || '',
    maxCutoffMonths: orderingRules?.maxCutoffMonths || '',
    teacherOrderRange: orderingRules?.teacherOrderRange || '',
    teacherOrderDueBy: orderingRules?.teacherOrderDueBy || '',
    teacherOrderDueByMonthly: orderingRules?.teacherOrderDueByMonthly || '',
    teacherCutoffDay: orderingRules?.teacherCutoffDay || '',
    teacherCutoffTime: orderingRules?.teacherCutoffTime || '',
    teacherMaxOrderRange: orderingRules?.teacherMaxOrderRange || '',
    teacherMaxCutoffDays: orderingRules?.teacherMaxCutoffDays || '',
    teacherMaxCutoffWeeks: orderingRules?.teacherMaxCutoffWeeks || '',
    teacherMaxCutoffMonths: orderingRules?.teacherMaxCutoffMonths || '',
  };

  const handleSubmit = async (values: FormikValues) => {
    setLoading(true);
    await updateDistrict({ orderingRules: { ...orderingRules, ...values } });
    setLoading(false);
    setSidebarOpen(false);
  };

  const handleChange = useCallback((field) => {
    if (field.name === 'orderRange') {
      setOrderRange(field.value);
    }
    if (field.name === 'teacherOrderRange') {
      setTeacherOrderRange(field.value);
    }
    if (field.name === 'maxOrderRange') {
      setMaxOrderRange(field.value);
    }
    if (field.name === 'teacherMaxOrderRange') {
      setTeacherMaxOrderRange(field.value);
    }
  }, []);

  useEffect(() => {
    setOrderRange((orderingRules?.orderRange as string) || Constants.ORDER_RANGE_OPTIONS.DAILY.toString());
    setTeacherOrderRange(
      (orderingRules?.teacherOrderRange as string) || Constants.ORDER_RANGE_OPTIONS.DAILY.toString(),
    );
  }, [orderingRules]);

  const [
    orderRangeOptions,
    orderDueByOptions,
    cutoffDayOptions,
    cutoffMonthOptions,
    maxOrderRangeOptions,
    maxRangeDaysOptions,
    maxRangeWeeksOptions,
    maxRangeMonthsOptions,
  ] = useMemo(() => {
    const mapIdAndName = ({ id: value, name: text }: { id: string; name: string }) => ({ value, text });
    return [
      Object.values(Constants.ORDER_RANGES).map(mapIdAndName),
      Object.values(Constants.ORDER_DUE_BY_RULES).map(mapIdAndName),
      Object.values(Constants.ORDER_CUT_OFF_DAYS).map(mapIdAndName),
      Object.values(Constants.ORDER_DUE_BY_MONTH).map(mapIdAndName),
      Object.values(Constants.MAX_ORDER_RANGES).map(mapIdAndName),
      Object.values(Constants.MAX_ORDER_UP_TO_DAYS).map(mapIdAndName),
      Object.values(Constants.MAX_ORDER_UP_TO_WEEKS).map(mapIdAndName),
      Object.values(Constants.MAX_ORDER_UP_TO_MONTHS).map(mapIdAndName),
    ];
  }, []);

  return (
    <Drawer
      open={open}
      anchor={'right'}
      className="ordering-rules-sidebar-container"
      id="ordering-rules-sidebar-slideout"
    >
      <Formik onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize>
        {({ handleReset, setFieldValue }: FormikProps<{}>) => (
          <Form className="linq-drawer-form-content oo-drawer-form">
            <DrawerHeader>
              <h2>Edit Order Cutoff and Max Range</h2>
              <CloseIconButton
                onClick={() => {
                  setSidebarOpen(false);
                  handleReset();
                }}
              />
            </DrawerHeader>
            <NotificationBanner location={SidebarLocations.ORDERING_RULES_SIDE_BAR} />
            <DrawerContent className="ordering-rules-sidebar-content">
              <section>
                <h4 className="ordering-rules__section-header">Parent Order Cutoff</h4>
                <p>Please choose time and cutoff.</p>
                <div className="ordering-rules__section-option">
                  <Field name="orderRange">
                    {({ field }: FieldProps) => (
                      <FormControl fullWidth className="ordering-rules__section-range">
                        <SelectField
                          id="orderRange"
                          name="orderRange"
                          value={field.value}
                          fieldLabel="Order Range"
                          options={orderRangeOptions}
                          onChange={(e) => {
                            field.onChange(e);
                            handleChange(e.target);
                          }}
                        />
                      </FormControl>
                    )}
                  </Field>
                  {orderRange && orderRange === Constants.ORDER_RANGE_OPTIONS.DAILY && (
                    <Field name="orderDueBy">
                      {({ field }: FieldProps) => (
                        <FormControl fullWidth>
                          <SelectField
                            id="orderDueBy"
                            name="orderDueBy"
                            value={field.value}
                            fieldLabel="Order Due By"
                            options={orderDueByOptions}
                            onChange={field.onChange}
                          />
                        </FormControl>
                      )}
                    </Field>
                  )}
                  {orderRange && orderRange === Constants.ORDER_RANGE_OPTIONS.WEEKLY && (
                    <Field name="cutoffDay">
                      {({ field }: FieldProps) => (
                        <FormControl fullWidth className="ordering-rules__section-option">
                          <SelectField
                            id="cutoffDay"
                            name="cutoffDay"
                            value={field.value}
                            fieldLabel="Cut Off Day"
                            options={cutoffDayOptions}
                            onChange={field.onChange}
                          />
                        </FormControl>
                      )}
                    </Field>
                  )}
                  {orderRange && orderRange === Constants.ORDER_RANGE_OPTIONS.MONTHLY && (
                    <Field name="orderDueByMonthly">
                      {({ field }: FieldProps) => (
                        <FormControl fullWidth className="ordering-rules__section-option">
                          <SelectField
                            id="orderDueByMonthly"
                            name="orderDueByMonthly"
                            value={field.value}
                            fieldLabel="Order Due By"
                            options={cutoffMonthOptions}
                            onChange={field.onChange}
                          />
                        </FormControl>
                      )}
                    </Field>
                  )}
                </div>

                <p className="ordering-rules__section-cut-off">Cutoff Time</p>
                <Field name="cutoffTime">
                  {({ field, form }: FieldProps) => (
                    <TimePicker
                      value={field.value}
                      onChange={(value: string) => {
                        form.setFieldValue('cutoffTime', value);
                      }}
                    />
                  )}
                </Field>
                <h4 className="ordering-rules__section-header max-range">Parent Max Order Range</h4>
                <p>Please choose how far in advance orders may be placed.</p>
                <div className="ordering-rules__section-option">
                  <Field name="maxOrderRange">
                    {({ field }: FieldProps) => (
                      <FormControl fullWidth className="ordering-rules__section-range">
                        <SelectField
                          id="maxOrderRange"
                          name="maxOrderRange"
                          value={field.value}
                          fieldLabel="Max Order Range"
                          options={maxOrderRangeOptions}
                          onChange={(e) => {
                            field.onChange(e);
                            handleChange(e.target);
                          }}
                        />
                      </FormControl>
                    )}
                  </Field>
                  {maxOrderRange && maxOrderRange === Constants.MAX_ORDER_RANGE_OPTIONS.DAYS && (
                    <Field name="maxCutoffDays">
                      {({ field }: FieldProps) => (
                        <FormControl fullWidth>
                          <SelectField
                            id="maxCutoffDays"
                            name="maxCutoffDays"
                            value={field.value}
                            fieldLabel="Max Cutoff"
                            options={maxRangeDaysOptions}
                            onChange={field.onChange}
                          />
                        </FormControl>
                      )}
                    </Field>
                  )}
                  {maxOrderRange && maxOrderRange === Constants.MAX_ORDER_RANGE_OPTIONS.WEEKS && (
                    <Field name="maxCutoffWeeks">
                      {({ field }: FieldProps) => (
                        <FormControl fullWidth className="ordering-rules__section-option">
                          <SelectField
                            id="maxCutoffWeeks"
                            name="maxCutoffWeeks"
                            value={field.value}
                            fieldLabel="Max Cutoff"
                            options={maxRangeWeeksOptions}
                            onChange={field.onChange}
                          />
                        </FormControl>
                      )}
                    </Field>
                  )}
                  {maxOrderRange && maxOrderRange === Constants.MAX_ORDER_RANGE_OPTIONS.MONTHS && (
                    <Field name="maxCutoffMonths">
                      {({ field }: FieldProps) => (
                        <FormControl fullWidth className="ordering-rules__section-option">
                          <SelectField
                            id="maxCutoffMonths"
                            name="maxCutoffMonths"
                            value={field.value}
                            fieldLabel="Max Cutoff"
                            options={maxRangeMonthsOptions}
                            onChange={field.onChange}
                          />
                        </FormControl>
                      )}
                    </Field>
                  )}
                </div>

                <div className="clear-btn-container">
                  <Button
                    color="primary"
                    variant="outlined"
                    type="button"
                    className="clear-min"
                    onClick={() => {
                      setFieldValue('orderRange', '');
                      setFieldValue('orderDueBy', '');
                      setFieldValue('cutoffDay', '');
                      setFieldValue('cutoffTime', '');
                      setFieldValue('orderDueByMonthly', '');
                    }}
                    data-test-id="clearParentBtn"
                  >
                    Clear Parent Cutoff
                  </Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    type="button"
                    onClick={() => {
                      setFieldValue('maxOrderRange', '');
                      setFieldValue('maxCutoffDays', '');
                      setFieldValue('maxCutoffWeeks', '');
                      setFieldValue('maxCutoffMonths', '');
                      setFieldValue('maxCutoffTime', '');
                    }}
                    data-test-id="clearMaxParentBtn"
                  >
                    Clear Parent Range
                  </Button>
                </div>

                <Divider />
              </section>
              <section>
                <h4 className="ordering-rules__section-header">Teacher Order Cutoff</h4>
                <p>Please choose the time and cutoff.</p>
                <div className="ordering-rules__section-option">
                  <Field name="teacherOrderRange">
                    {({ field }: FieldProps) => (
                      <FormControl fullWidth className="ordering-rules__section-range">
                        <SelectField
                          id="teacherOrderRange"
                          name="teacherOrderRange"
                          value={field.value}
                          fieldLabel="Order Range"
                          options={orderRangeOptions}
                          onChange={(e) => {
                            field.onChange(e);
                            handleChange(e.target);
                          }}
                        />
                      </FormControl>
                    )}
                  </Field>
                  {teacherOrderRange && teacherOrderRange === Constants.ORDER_RANGE_OPTIONS.DAILY && (
                    <Field name="teacherOrderDueBy">
                      {({ field }: FieldProps) => (
                        <FormControl fullWidth>
                          <SelectField
                            id="teacherOrderDueBy"
                            name="teacherOrderDueBy"
                            value={field.value}
                            fieldLabel="Order Due By"
                            options={orderDueByOptions}
                            onChange={field.onChange}
                          />
                        </FormControl>
                      )}
                    </Field>
                  )}
                  {teacherOrderRange && teacherOrderRange === Constants.ORDER_RANGE_OPTIONS.WEEKLY && (
                    <Field name="teacherCutoffDay">
                      {({ field }: FieldProps) => (
                        <FormControl fullWidth className="ordering-rules__section-option">
                          <SelectField
                            id="teacherCutoffDay"
                            name="teacherCutoffDay"
                            value={field.value}
                            fieldLabel="Cutoff Day"
                            options={cutoffDayOptions}
                            onChange={field.onChange}
                          />
                        </FormControl>
                      )}
                    </Field>
                  )}
                  {teacherOrderRange && teacherOrderRange === Constants.ORDER_RANGE_OPTIONS.MONTHLY && (
                    <Field name="teacherOrderDueByMonthly">
                      {({ field }: FieldProps) => (
                        <FormControl fullWidth className="ordering-rules__section-option">
                          <SelectField
                            id="teacherOrderDueByMonthly"
                            name="teacherOrderDueByMonthly"
                            value={field.value}
                            fieldLabel="Order Due by"
                            options={cutoffMonthOptions}
                            onChange={field.onChange}
                          />
                        </FormControl>
                      )}
                    </Field>
                  )}
                </div>
                <div>
                  <p className="ordering-rules__section-cut-off">Cutoff Time</p>
                  <Field name="teacherCutoffTime">
                    {({ field, form }: FieldProps) => (
                      <TimePicker
                        value={field.value}
                        onChange={(value: string) => {
                          form.setFieldValue('teacherCutoffTime', value);
                        }}
                      />
                    )}
                  </Field>

                  <h4 className="ordering-rules__section-header max-range">Teacher Max Order Range</h4>
                  <p>Please choose how far in advance orders may be placed.</p>
                  <div className="ordering-rules__section-option">
                    <Field name="teacherMaxOrderRange">
                      {({ field }: FieldProps) => (
                        <FormControl fullWidth className="ordering-rules__section-range">
                          <SelectField
                            id="teacherMaxOrderRange"
                            name="teacherMaxOrderRange"
                            value={field.value}
                            fieldLabel="Max Order Range"
                            options={maxOrderRangeOptions}
                            onChange={(e) => {
                              field.onChange(e);
                              handleChange(e.target);
                            }}
                          />
                        </FormControl>
                      )}
                    </Field>
                    {teacherMaxOrderRange && teacherMaxOrderRange === Constants.MAX_ORDER_RANGE_OPTIONS.DAYS && (
                      <Field name="teacherMaxCutoffDays">
                        {({ field }: FieldProps) => (
                          <FormControl fullWidth>
                            <SelectField
                              id="teacherMaxCutoffDays"
                              name="teacherMaxCutoffDays"
                              value={field.value}
                              fieldLabel="Max Cutoff"
                              options={maxRangeDaysOptions}
                              onChange={field.onChange}
                            />
                          </FormControl>
                        )}
                      </Field>
                    )}
                    {teacherMaxOrderRange && teacherMaxOrderRange === Constants.MAX_ORDER_RANGE_OPTIONS.WEEKS && (
                      <Field name="teacherMaxCutoffWeeks">
                        {({ field }: FieldProps) => (
                          <FormControl fullWidth className="ordering-rules__section-option">
                            <SelectField
                              id="teacherMaxCutoffWeeks"
                              name="teacherMaxCutoffWeeks"
                              value={field.value}
                              fieldLabel="Max Cutoff"
                              options={maxRangeWeeksOptions}
                              onChange={field.onChange}
                            />
                          </FormControl>
                        )}
                      </Field>
                    )}
                    {teacherMaxOrderRange && teacherMaxOrderRange === Constants.MAX_ORDER_RANGE_OPTIONS.MONTHS && (
                      <Field name="teacherMaxCutoffMonths">
                        {({ field }: FieldProps) => (
                          <FormControl fullWidth className="ordering-rules__section-option">
                            <SelectField
                              id="teacherMaxCutoffMonths"
                              name="teacherMaxCutoffMonths"
                              value={field.value}
                              fieldLabel="Max Cutoff"
                              options={maxRangeMonthsOptions}
                              onChange={field.onChange}
                            />
                          </FormControl>
                        )}
                      </Field>
                    )}
                  </div>

                  <div className="clear-btn-container">
                    <Button
                      className="clear-min"
                      color="primary"
                      variant="outlined"
                      type="button"
                      onClick={() => {
                        setFieldValue('teacherOrderRange', '');
                        setFieldValue('teacherOrderDueBy', '');
                        setFieldValue('teacherCutoffDay', '');
                        setFieldValue('teacherCutoffTime', '');
                      }}
                      data-test-id="clearTeacherBtn"
                    >
                      Clear Teacher Cutoff
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      type="button"
                      onClick={() => {
                        setFieldValue('teacherMaxOrderRange', '');
                        setFieldValue('teacherMaxCutoffDays', '');
                        setFieldValue('teacherMaxCutoffWeeks', '');
                        setFieldValue('teacherMaxCutoffMonths', '');
                        setFieldValue('teacherMaxCutoffTime', '');
                      }}
                      data-test-id="clearMaxTeacherBtn"
                    >
                      Clear Teacher Range
                    </Button>
                  </div>
                </div>
              </section>
            </DrawerContent>
            <DrawerFooter>
              <div>
                <Button
                  className="linq-mr-2"
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setSidebarOpen(false);
                    handleReset();
                  }}
                  data-test-id="cancelBtn"
                >
                  Cancel
                </Button>
              </div>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading}
                loading={loading}
                data-test-id="submitBtn"
              >
                Save
              </Button>
            </DrawerFooter>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default OrderingRulesSidebar;
