import { Link, LinkBaseProps } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

import './ButtonLink.less';

export enum ButtonLinkColors {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum ButtonLinkVariants {
  Outlined = 'outlined',
  Text = 'text',
  Contained = 'contained',
}

export interface ButtonLinkProps extends LinkBaseProps {
  buttonVariant?: ButtonLinkVariants;
  color?: ButtonLinkColors;
  fullWidth?: boolean;
  text: string;
  disabled?: boolean;
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  buttonVariant = ButtonLinkVariants.Text,
  color = ButtonLinkColors.Primary,
  fullWidth,
  text,
  disabled,
  ...props
}) => {
  const className = classNames(
    'button-link MuiButtonBase-root MuiButton-root',
    props.className,
    {
      'MuiButton-textPrimary': buttonVariant === ButtonLinkVariants.Text && color === ButtonLinkColors.Primary,
      'MuiButton-textSecondary': color === ButtonLinkColors.Secondary,
      'MuiButton-text': buttonVariant === ButtonLinkVariants.Text,
      'MuiButton-outlined': buttonVariant === ButtonLinkVariants.Outlined,
      'MuiButton-outlinedPrimary': buttonVariant === ButtonLinkVariants.Outlined && color === ButtonLinkColors.Primary,
      'MuiButton-outlinedSecondary':
        buttonVariant === ButtonLinkVariants.Outlined && color === ButtonLinkColors.Secondary,
      'MuiButton-contained': buttonVariant === ButtonLinkVariants.Contained,
      'MuiButton-containedPrimary': color === ButtonLinkColors.Primary && buttonVariant === 'contained',
      'MuiButton-containedSecondary':
        buttonVariant === ButtonLinkVariants.Contained && color === ButtonLinkColors.Secondary,
      'MuiButton-fullWidth': fullWidth,
    },
    disabled && 'btn-link-disabled',
  );
  if (disabled) {
    return (
      <div className={className}>
        <span className="MuiButton-label">{text}</span>
        <span className="MuiTouchRipple-root"></span>
      </div>
    );
  }
  return (
    <Link {...props} className={className}>
      <span className="MuiButton-label">{text}</span>
      <span className="MuiTouchRipple-root"></span>
    </Link>
  );
};
