import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { FormikValues, useFormik } from 'formik';
import { flattenDeep, get, isEqual } from 'lodash';
import numeral from 'numeral';
import * as yup from 'yup';
import { FormControlLabel, IconButton } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import EditIcon from '@material-ui/icons/Edit';
import TrashIcon from '@material-ui/icons/Delete';
import { useDistrict } from 'State/hooks';
import * as CommonSelectors from 'State/selectors';
import { TokenClaims } from 'Auth/User';
import {
  ReimbursableMealType,
  ReimbursableMealPrice,
  PricingMode,
  ReimbursableMealPriceByGradeGroup,
} from 'Interfaces/Interfaces';
import * as Enums from 'Constants/Enums';
import * as Constants from 'Constants/Constants';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'Components/Drawer';
import { useDistrictActions } from 'Components/District/state/actions';
import * as DistrictSelectors from 'Components/District/state/selectors';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import { InputField } from 'Components/Form/MaterialForm';
import Button from 'Components/Button/Button';
import * as MenuTypePickerSelectors from 'Components/MenuTypePicker/state/selectors';
import { ReactTable } from 'Components/ReactTable';
import { NotificationDialog } from 'Components/Dialogs/NotificationDialog';
import { compareGrade, isEmpty } from 'Helpers/Helper';
import GradeGroupPricingModal from './GradeGroupPricingModal';

export interface Fields {
  name: string;
  id: string;
}

export interface FormValues extends FormikValues {
  offerPricingByGrade: boolean;
  gradePricingGroups: { grades: string[]; price: number }[];
}

const getSortedGroups = (gradePricingGroups: { grades: string[]; price: number }[]) => {
  const sortedGradePricingGroups = [...gradePricingGroups];
  sortedGradePricingGroups.sort((gradePricingGroupA, gradePricingGroupB) => {
    return compareGrade(gradePricingGroupA.grades[0], gradePricingGroupB.grades[0]);
  });

  return sortedGradePricingGroups;
};

const getAvailableGradeOptions = (gradeOptions: any, gradePricingGroups: any, editPricingGroup: any) => {
  const gradesUsedInPricingGroups = flattenDeep(
    gradePricingGroups.map((pricingGroup: any) => pricingGroup.grades),
  ).filter((usedGrade: any) => !editPricingGroup?.grades?.includes(usedGrade));

  return gradeOptions?.filter((gradeOption: any) => {
    return !gradesUsedInPricingGroups.includes(gradeOption.value);
  });
};

const ReimbursableMealPricingItemSidebar: React.FC<{
  open?: boolean;
  onClose: () => void;
  fields: Fields[];
  reimbursableMealType?: ReimbursableMealType;
}> = ({ fields = [], open = false, onClose, reimbursableMealType }) => {
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);
  const districtLoading = useDistrict(districtId);

  // selectors
  const reimbursableMealPricingModes = useSelector(DistrictSelectors.getReimbursableMealPricingModes);
  const reimbursableMealPricesByGradeGroups = useSelector(
    DistrictSelectors.getReimbursableMealPricesByGradeGroups(reimbursableMealType?.id),
  );
  const reimbursableMealPrices = useSelector(DistrictSelectors.getReimbursableMealPrices);
  const gradeOptions = useSelector(CommonSelectors.getGradeOptions(districtId));
  const menuTypeId = useSelector(MenuTypePickerSelectors.getMenuTypeId) || '';
  const districtActions = useDistrictActions();

  // component state
  const [priceForAllBuildingsToggle, setPriceForAllBuildingsToggle] = useState<boolean>(false);
  const [priceForAllBuildings, setPriceForAllBuildings] = useState<string>('');
  const [addGradeGroupPricingModalOpen, setAddgradeGroupPricingModalOpen] = React.useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editPricingGroup, setEditPricingGroup] = React.useState<{ price: string; grades: string[] } | undefined>(
    undefined,
  );
  const [showMissingPricesWarning, setShowMissingPricesWarning] = React.useState(false);
  const [missingPriceGroups, setMissingPriceGroups] = React.useState<string[]>([]);

  const isAdult = reimbursableMealType?.name === Constants.REIMBURSABLE_MEAL_TYPES['ADULT'].name;

  const handleClose = useCallback(() => {
    setPriceForAllBuildings('');
    setPriceForAllBuildingsToggle(false);
    onClose();
  }, [onClose]);

  const handleSubmit = (values: FormikValues) => {
    const missingSiteGroupPrices: any[] = Object.values(Constants.SITE_GROUP_TYPES).filter((siteGroupType: any) =>
      isEmpty(values[siteGroupType.id]),
    );

    // Display warning if offering grade pricing and missing grade prices
    if (values.offerPricingByGrade && availableGradeOptions.length > 0) {
      setMissingPriceGroups(availableGradeOptions.map((option: { text: string; value: string }) => option.value));
      setShowMissingPricesWarning(true);
      return;
    }

    // Display warning if offering site group pricing and missing site group prices
    if (!values.offerPricingByGrade && missingSiteGroupPrices.length > 0) {
      setMissingPriceGroups(missingSiteGroupPrices.map((siteGroup) => siteGroup.name));
      setShowMissingPricesWarning(true);
      return;
    }

    submitPrices(values);
  };

  const submitPrices = useCallback(
    async (values) => {
      if (!reimbursableMealType) {
        return;
      }

      setLoading(true);

      const reimbursableMealPricesByGrade: ReimbursableMealPriceByGradeGroup[] = [];

      if (values.offerPricingByGrade) {
        values.gradePricingGroups.forEach((gradeGroup: any) => {
          reimbursableMealPricesByGrade.push({
            grades: gradeGroup.grades,
            price: gradeGroup.price,
            reimbursableMealTypeId: reimbursableMealType.id,
            mealTypeId: menuTypeId,
          });
        });

        const updateReimbMealPricesPromise = districtActions.updateReimbursableMealPricesByGrade(
          reimbursableMealPricesByGrade,
          new URLSearchParams({ menuTypeId, reimbursableMealTypeId: reimbursableMealType.id }),
          reimbursableMealType.id,
        );
        const updateReimbursableMealPricingModePromise = districtActions.updateReimbursableMealPricingMode(
          menuTypeId,
          reimbursableMealType.id,
          PricingMode.PRICING_BY_GRADE,
        );

        await Promise.all([updateReimbMealPricesPromise, updateReimbursableMealPricingModePromise]);
      } else {
        const reimbursableMealPrices: ReimbursableMealPrice[] = Object.values(Constants.SITE_GROUP_TYPES).map(
          (siteGroupType: any) => {
            const price = !isEmpty(values[siteGroupType.id]) ? parseFloat(values[siteGroupType.id]) : undefined;
            return {
              reimbursableMealTypeId: reimbursableMealType.id,
              mealTypeId: menuTypeId,
              siteGroupTypeId: siteGroupType.id,
              price: priceForAllBuildingsToggle && priceForAllBuildings ? parseFloat(priceForAllBuildings) : price,
            };
          },
        );

        const updateReimbMealPricesPromise = districtActions.updateReimbursableMealPrices(
          reimbursableMealPrices,
          new URLSearchParams({ menuTypeId }),
        );
        const updateReimbursableMealPricingModePromise = districtActions.updateReimbursableMealPricingMode(
          menuTypeId,
          reimbursableMealType.id,
          PricingMode.PRICING_BY_SITE,
        );

        await Promise.all([updateReimbMealPricesPromise, updateReimbursableMealPricingModePromise]);
      }

      setLoading(false);

      handleClose();
    },
    [menuTypeId, reimbursableMealType, districtActions, priceForAllBuildings, priceForAllBuildingsToggle, handleClose],
  );

  const getMissingPricesMessage = (missingPrices: string[], offerPricingByGrade: boolean) => {
    const message = offerPricingByGrade
      ? Constants.MISSING_GRADE_PRICES_WARNING
      : Constants.MISSING_SITE_GROUP_PRICES_WARNING;

    return (
      <>
        <span>{message}</span>
        <ul>
          {missingPrices.map((missingPrice) => (
            <li key={missingPrice}>{missingPrice}</li>
          ))}
        </ul>
      </>
    );
  };

  const initialValues = useMemo((): any => {
    const pricesBySite = Object.values(Constants.SITE_GROUP_TYPES).reduce((acc, currentValue) => {
      const accumulator = acc as { [key: string]: number | '' };
      const siteGroupType = currentValue as { name: string; id: string };

      const price = get(reimbursableMealPrices, [`${reimbursableMealType?.id}.${siteGroupType.id}`, 'price'], '');
      accumulator[siteGroupType.id] = price;
      return acc;
    }, {} as { [key: string]: string | number });

    const pricingMode = reimbursableMealType
      ? reimbursableMealPricingModes[`${menuTypeId}.${reimbursableMealType.id}`]?.pricingMode ??
        PricingMode.PRICING_BY_SITE
      : PricingMode.PRICING_BY_SITE;

    const offerPricingByGrade = pricingMode === PricingMode.PRICING_BY_GRADE;

    return {
      gradePricingGroups: reimbursableMealPricesByGradeGroups,
      offerPricingByGrade,
      ...(pricesBySite as Object),
    };
  }, [
    reimbursableMealPrices,
    reimbursableMealPricesByGradeGroups,
    reimbursableMealPricingModes,
    reimbursableMealType,
    menuTypeId,
  ]);

  const formik = useFormik<FormValues>({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: yup.object().shape(
      fields.reduce((obj, field) => {
        return {
          ...obj,
          [field.id]: yup.number(),
        };
      }, {}),
    ),
  });

  const availableGradeOptions = React.useMemo(() => {
    return getAvailableGradeOptions(gradeOptions, formik.values.gradePricingGroups, editPricingGroup);
  }, [gradeOptions, formik.values.gradePricingGroups, editPricingGroup]);

  if (!reimbursableMealType) {
    return null;
  }

  const handleAddGradeSubmit = (submitValues: { grades: string[]; price: string }) => {
    const gradePricingGroups = formik.values.gradePricingGroups.filter(
      (group) => !isEqual(group.grades, editPricingGroup?.grades),
    );

    const price = parseFloat(submitValues.price);

    const newGradePricingGroup = {
      price,
      grades: submitValues.grades,
    };

    newGradePricingGroup?.grades?.sort(compareGrade);

    const sortedGradePricingGroups = getSortedGroups([...gradePricingGroups, newGradePricingGroup]);

    formik.setFieldValue('gradePricingGroups', sortedGradePricingGroups);
    setAddgradeGroupPricingModalOpen(false);
    setEditPricingGroup(undefined);
  };

  return (
    <Drawer
      anchor={'right'}
      open={open}
      className="reimbursable-meal-pricing-slideout-container"
      id="reimbursable-meal-pricing-slideout"
    >
      <form onSubmit={formik.handleSubmit} className="linq-drawer-form-content oo-drawer-form">
        <DrawerHeader>
          <h2>Edit {reimbursableMealType?.name} Pricing</h2>
          <CloseIconButton onClick={() => handleClose()} />
        </DrawerHeader>
        <NotificationBanner location={Enums.SidebarLocations.REIMB_PRICING_SIDE_BAR} />
        <DrawerContent>
          {!isAdult && (
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.offerPricingByGrade || false}
                  onChange={formik.handleChange}
                  name="offerPricingByGrade"
                  color="primary"
                  data-test-id="offerPricingByGradeBtn"
                />
              }
              label={<span>Offer pricing by grade</span>}
              style={{ marginBottom: '24px' }}
            />
          )}

          {!formik.values.offerPricingByGrade && (
            <div>
              {Object.values(fields).map((siteGroupType) => (
                <InputField
                  id={siteGroupType.id}
                  key={siteGroupType.id}
                  name={siteGroupType.id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[siteGroupType.id]}
                  disabled={priceForAllBuildingsToggle}
                  error={!!formik.errors[siteGroupType.id]}
                  helperText={formik.errors[siteGroupType.id] as string}
                  touched={!!formik.touched[siteGroupType.id]}
                  isCurrency
                  mode={Enums.InputMode.Decimal}
                  numericSize={[7, 2]}
                  allowNegative={false}
                  label={siteGroupType.name}
                  inputProps={{ 'data-test-id': `${siteGroupType.id}.Input` }}
                />
              ))}
              {isAdult && (
                <>
                  <Switch
                    checked={priceForAllBuildingsToggle}
                    onChange={() => {
                      setPriceForAllBuildingsToggle(!priceForAllBuildingsToggle);
                    }}
                    color="primary"
                    inputProps={{ 'aria-label': 'Set Price For All Building Types Toggle' }}
                  />
                  Set price for all building types
                  <InputField
                    id={'priceForAllBuildings'}
                    onChange={(e) => {
                      setPriceForAllBuildings(e.target.value);
                    }}
                    value={priceForAllBuildings}
                    disabled={!priceForAllBuildingsToggle}
                    isCurrency
                    mode={Enums.InputMode.Decimal}
                    numericSize={[7, 2]}
                  />
                </>
              )}
            </div>
          )}
          {formik.values.offerPricingByGrade && (
            <div>
              <Button
                variant="text"
                color="primary"
                className="add-grade-group"
                onClick={() => {
                  setAddgradeGroupPricingModalOpen(true);
                }}
                data-test-id="addGradeGroupBtn"
              >
                Add Grade Group Pricing +
              </Button>

              <ReactTable
                className="grade-pricing-table"
                data={formik.values.gradePricingGroups}
                columns={[
                  {
                    Header: 'Grades',
                    accessor: 'grades',
                    Cell: (props: any) => {
                      return <span>{props?.row?.original?.grades?.join(', ')?.trimEnd() ?? ''}</span>;
                    },
                    width: 155,
                    disableSortBy: true,
                    dataTestId: (row: any) => `${row.original.grades.join(',')}.gradesLbl`,
                  },
                  {
                    Header: 'Price',
                    accessor: 'price',
                    disableSortBy: true,
                    Cell: (props: any) => {
                      return <span>{numeral(props.row.original.price).format(Constants.USD_FORMAT)}</span>;
                    },
                    dataTestId: (row: any) => `${row.original.grades.join(',')}.priceLbl`,
                  },
                  {
                    id: 'actions',
                    width: 100,
                    disableSortBy: true,
                    className: 'actions-cell',
                    Cell: (props: any) => {
                      return (
                        <>
                          <div className="row-actions">
                            <IconButton
                              onClick={() => {
                                setEditPricingGroup(props.row.original);
                                setAddgradeGroupPricingModalOpen(true);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                const newGradePricingGroups = formik.values.gradePricingGroups?.filter(
                                  (group) => !isEqual(group.grades, props.row.original.grades),
                                );
                                formik.setFieldValue('gradePricingGroups', [...newGradePricingGroups]);
                              }}
                            >
                              <TrashIcon />
                            </IconButton>
                          </div>
                        </>
                      );
                    },
                    dataTestId: (row: any) => `${row.original.grades.join(',')}.rowActionsLbl`,
                  },
                ]}
              />
            </div>
          )}
        </DrawerContent>
        <DrawerFooter>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              handleClose();
              formik.resetForm();
              setEditPricingGroup(undefined);
            }}
            disabled={loading}
            data-test-id="cancelDrawerBtn"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            loading={loading}
            disabled={loading || !!Object.values(formik.errors).length}
            data-test-id="saveDrawerBtn"
          >
            Save
          </Button>
        </DrawerFooter>
      </form>
      <GradeGroupPricingModal
        isOpen={addGradeGroupPricingModalOpen}
        loading={districtLoading}
        handleClose={() => {
          setAddgradeGroupPricingModalOpen(false);
          setEditPricingGroup(undefined);
        }}
        gradeOptions={availableGradeOptions}
        handleSubmit={handleAddGradeSubmit}
        initialValues={editPricingGroup}
      />
      <NotificationDialog
        title={'Missing Reimbursable Meal Prices'}
        message={getMissingPricesMessage(missingPriceGroups, formik.values.offerPricingByGrade)}
        showDialog={showMissingPricesWarning}
        onOkClick={() => {
          setShowMissingPricesWarning(false);
          submitPrices(formik.values);
        }}
        showCancel={true}
        onCancelClick={() => {
          setShowMissingPricesWarning(false);
        }}
        okButtonText={'Continue'}
        cancelButtonText={'Cancel'}
        dialogType={'info'}
      />
    </Drawer>
  );
};

export default ReimbursableMealPricingItemSidebar;
