import { Admin, Role } from 'Auth/User';
import * as Interfaces from 'Interfaces/Interfaces';
import { ERROR_TYPE } from './Constants';

type RoutesMap = {
  [key in RouteId]: Interfaces.Route;
};

export enum RouteId {
  ACCOUNT_CONFIGURATION,
  STUDENT_MANAGEMENT,
  DISTRICT_DASHBOARD,
  DISTRICT_PRINT_LABELS,
  DISTRICT_PRINT_REPORT,
  DISTRICT_REPORTS,
  IMPORT_DATA,
  A_LA_CARTE,
  USER_MANAGEMENT,
  LOGOUT,
  ORDERING_RULES,
  PARENT_DASHBOARD,
  PAYMENT_PROVIDER,
  PICKUP_LOCATIONS,
  PRICING_MANAGEMENT,
  ROOT,
  SHOP,
  TEACHER_DASHBOARD,
  TEACHER_ROSTER_ORDERING_PRINT,
  ORDER_CONFIRMATION,
  ERROR_STANDARD,
  ERROR_PERMISSION_DENIED,
  ERROR_FAIL,
  SYNTHESIS,
  LOGIN,
  NEWS_FEED_MANAGEMENT,
  EDIT_NEWS_FEED,
  PARENT_NEWSFEED,
  TRANSACTION_HISTORY,
  SITE_INFORMATION,
  CLOSE_SCHOOL_YEAR,
  REGISTER,
  LANDING_PAGE,
  PROFILE,
  PREVIEW_MENUS,
  SHOP_PREVIEW,
  DIRECTOR_PROFILE,
}

export const Routes = {
  [RouteId.ACCOUNT_CONFIGURATION]: {
    alias: ['/accountconfiguration'],
    path: '/account-configuration',
    allowedRoles: [Role.TEACHER, Role.MANAGER],
    adminSidebar: true,
  },
  [RouteId.STUDENT_MANAGEMENT]: {
    path: '/student-management',
    allowedRoles: [Role.DIRECTOR],
    adminSidebar: true,
  },
  [RouteId.DISTRICT_DASHBOARD]: {
    path: '/district-dashboard',
    alias: ['/districtdashboard'],
    allowedRoles: [Role.DIRECTOR, Role.MANAGER],
    adminSidebar: true,
  },
  [RouteId.DISTRICT_PRINT_LABELS]: {
    path: '/district-print-labels',
    alias: ['/districtprintlabels'],
    allowedRoles: [Role.MANAGER, Role.DIRECTOR],
    adminSidebar: true,
    isPrintable: true,
  },
  [RouteId.DISTRICT_PRINT_REPORT]: {
    path: '/district-print-report',
    alias: ['/districtprintreport'],
    allowedRoles: [Role.MANAGER, Role.DIRECTOR],
    adminSidebar: true,
    isPrintable: true,
  },
  [RouteId.DISTRICT_REPORTS]: {
    path: '/district-reports',
    alias: ['/districtreports'],
    allowedRoles: [Role.MANAGER, Role.DIRECTOR],
    adminSidebar: true,
  },
  [RouteId.IMPORT_DATA]: {
    path: '/import-data',
    alias: ['/importdata'],
    allowedRoles: [Role.DIRECTOR],
    adminSidebar: true,
  },
  [RouteId.A_LA_CARTE]: {
    path: '/a-la-carte',
    allowedRoles: [Role.DIRECTOR],
    adminSidebar: true,
  },
  [RouteId.USER_MANAGEMENT]: {
    path: '/user-management',
    allowedRoles: [Role.DIRECTOR],
    adminSidebar: true,
  },
  [RouteId.SYNTHESIS]: {
    path: '/synthesis',
    allowedRoles: [Role.DIRECTOR],
    adminSidebar: true,
  },
  [RouteId.LOGOUT]: {
    path: '/logout',
  },
  [RouteId.ORDERING_RULES]: {
    path: '/ordering-rules',
    alias: ['/orderingrules'],
    allowedRoles: [Role.DIRECTOR],
    adminSidebar: true,
  },
  [RouteId.PARENT_DASHBOARD]: {
    path: '/parent-dashboard',
    alias: ['/parentdashboard'],
    allowedRoles: [Role.PARENT],
    adminSidebar: true,
  },
  [RouteId.PAYMENT_PROVIDER]: {
    path: '/payment-provider',
    alias: ['/paymentprovider'],
    allowedRoles: [Role.DIRECTOR],
    adminSidebar: true,
  },
  [RouteId.PICKUP_LOCATIONS]: {
    path: '/pickup-locations',
    alias: ['/pickuplocations'],
    allowedRoles: [Role.MANAGER, Role.DIRECTOR],
    adminSidebar: true,
  },
  [RouteId.PRICING_MANAGEMENT]: {
    path: '/pricing-management',
    alias: ['/pricingmanagement'],
    allowedRoles: [Role.DIRECTOR],
    adminSidebar: true,
  },
  [RouteId.ROOT]: {
    path: '/',
    adminSidebar: true,
  },
  [RouteId.SHOP]: {
    path: '/shop',
    allowedRoles: [Role.PARENT, Role.TEACHER],
    adminSidebar: true,
  },
  [RouteId.TEACHER_DASHBOARD]: {
    path: '/teacher-dashboard',
    alias: ['/teacherdashboard'],
    allowedRoles: [Role.TEACHER],
    adminSidebar: true,
  },
  [RouteId.TEACHER_ROSTER_ORDERING_PRINT]: {
    path: '/teacher-roster-ordering-print',
    alias: ['/TeacherRosterOrderingPrint'],
    allowedRoles: [Role.TEACHER],
    isPrintable: true,
    adminSidebar: true,
  },
  [RouteId.ORDER_CONFIRMATION]: {
    path: '/order-confirmation',
    alias: ['/orderconfirmation'],
    allowedRoles: [Role.PARENT, Role.TEACHER],
    adminSidebar: true,
  },
  [RouteId.ERROR_STANDARD]: {
    path: '/error',
    adminSidebar: true,
  },
  [RouteId.ERROR_PERMISSION_DENIED]: {
    path: '/permission-denied',
    adminSidebar: true,
    props: {
      errorType: ERROR_TYPE.PERMISSION,
    },
  },
  [RouteId.ERROR_FAIL]: {
    path: '/failure',
    adminSidebar: true,
    props: {
      errorType: ERROR_TYPE.FAIL,
    },
  },
  [RouteId.LOGIN]: {
    path: '/login',
  },
  [RouteId.EDIT_NEWS_FEED]: {
    path: '/newsfeed-management/edit/:id',
    alias: ['/newsfeed-management/edit'],
    allowedRoles: [Role.DIRECTOR],
    adminSidebar: true,
  },
  [RouteId.NEWS_FEED_MANAGEMENT]: {
    path: '/newsfeed-management',
    allowedRoles: [Role.DIRECTOR],
    adminSidebar: true,
  },
  [RouteId.PARENT_NEWSFEED]: {
    path: '/newsfeed/:districtId/:id',
    allowedRoles: [Role.PARENT],
    adminSidebar: true,
    hideParentFooter: true,
  },
  [RouteId.TRANSACTION_HISTORY]: {
    path: '/transaction-history',
    allowedRoles: [Role.PARENT],
    adminSidebar: true,
    hideParentFooter: true,
  },
  [RouteId.SITE_INFORMATION]: {
    path: '/site-information',
    allowedRoles: [Role.DIRECTOR],
    adminSidebar: true,
    hideParentFooter: true,
  },
  [RouteId.CLOSE_SCHOOL_YEAR]: {
    path: '/close-school-year',
    allowedRoles: [Role.DIRECTOR],
    adminSidebar: true,
    hideParentFooter: true,
  },
  [RouteId.REGISTER]: {
    path: '/register',
    adminSidebar: false,
    hideParentFooter: true,
  },
  [RouteId.PROFILE]: {
    path: '/profile',
    allowedRoles: [Role.PARENT, Role.MANAGER],
    adminSidebar: false,
    hideParentFooter: true,
  },
  [RouteId.DIRECTOR_PROFILE]: {
    path: '/director-profile',
    allowedRoles: [Role.DIRECTOR, Role.PARENT, Role.MANAGER, Role.TEACHER],
    adminSidebar: true,
    adminLevels: [Admin.DIRECTOR, Admin.SUPER],
    hideParentFooter: true,
  },
  [RouteId.PREVIEW_MENUS]: {
    path: '/preview-menus',
    allowedRoles: [Role.DIRECTOR],
    adminSidebar: true,
    hideParentFooter: true,
  },
  [RouteId.SHOP_PREVIEW]: {
    path: '/preview',
    allowedRoles: [Role.DIRECTOR],
    adminSidebar: true,
    hideParentFooter: true,
  },
} as RoutesMap;

export const getRoute = (routeId: RouteId): Interfaces.Route => Routes[routeId];

export const getRoutePath = (routeId: RouteId): string => Routes[routeId].path;

export const getRouteProps = (routeId: RouteId): Interfaces.RouteWithFallback => {
  const {
    alias = [],
    path,
    allowedRoles,
    adminSidebar,
    adminLevels,
    isPrintable,
    hideParentFooter,
    props = {},
  } = Routes[routeId];

  // Support alias fallback
  return {
    path: alias.length ? [path, ...alias] : [path],
    allowedRoles,
    adminLevels,
    adminSidebar: adminSidebar ?? false,
    isPrintable,
    hideParentFooter,
    props,
  } as Interfaces.RouteWithFallback;
};
