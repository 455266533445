import Button from 'Components/Button/Button';
import { KeyboardDateRangePickerField } from 'Components/Form/MaterialForm/KeyboardDateRangePicker';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import Modal from 'Components/Modal/Modal';
import { DATE_FNS_DATE_FORMAT_USA } from 'Constants/Constants';
import { FormikValues, useFormik } from 'formik';
import * as yup from 'yup';
import React from 'react';
import { isSameOrAfter } from 'Helpers/DateHelpers';
import { differenceInDays, isValid } from 'date-fns';

interface CustomDateModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleSubmit: (fromDate: Date | null, toDate: Date | null) => void;
}

const initialValues = { fromDate: new Date(), toDate: new Date() };
const MAX_DATE_RANGE = 365;

export const CustomDateModal: React.FC<CustomDateModalProps> = ({ open, setOpen, handleSubmit }) => {
  const formik = useFormik({
    initialValues,
    onSubmit: async (values: FormikValues) => {
      handleSubmit(values.fromDate, values.toDate);
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      fromDate: yup.date().nullable().required('Field is required'),
      toDate: yup.date().nullable().required('Field is required'),
    }),
    validate: (values: FormikValues) => {
      const errors: { [key: string]: string } = {};

      if (values.fromDate && values.toDate) {
        const fromDateIsValid = isValid(values.fromDate);
        const toDateIsValid = isValid(values.toDate);

        if (!fromDateIsValid || !toDateIsValid) {
          if (!fromDateIsValid) {
            errors.fromDate = 'Invalid date';
          }

          if (!toDateIsValid) {
            errors.toDate = 'Invalid date';
          }

          return errors;
        } else if (!isSameOrAfter(values.toDate, values.fromDate)) {
          errors.toDate = 'To date should be after from date';
          errors.fromDate = 'From date should be after to date';
        } else if (Math.abs(differenceInDays(values.fromDate, values.toDate)) > MAX_DATE_RANGE) {
          errors.fromDate = `Date range cannot exceed ${MAX_DATE_RANGE} days`;
          errors.toDate = `Date range cannot exceed ${MAX_DATE_RANGE} days`;
        }
      }

      return errors;
    },
  });

  const handleClose = () => {
    setOpen(false);
    if (formik.errors.fromDate || formik.errors.toDate) {
      formik.resetForm();
    }
  };

  return (
    <Modal className="linq-dialog" open={open} maxWidth="md">
      <Modal.Header className="linq-dialog__header d-flex align-items-center" disableTypography={true}>
        <h3 className="linq-heading">Select Custom Date Range</h3>
        <CloseIconButton onClick={handleClose} />
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Content className="linq-dialog__content_scroll">
          <KeyboardDateRangePickerField
            fromDateProps={{
              value: formik.values.fromDate,
              onChange: (date) => {
                formik.setFieldValue('fromDate', date);
                formik.setFieldTouched('fromDate', true, false);
              },
              format: DATE_FNS_DATE_FORMAT_USA,
              label: 'From',
              touched: formik.touched.fromDate as boolean,
              errorMsg: formik.errors.fromDate as string,
              showOnlyTouchedError: true,
              maxDate: formik.values.toDate,
              variant: 'inline',
              autoOk: true,
            }}
            toDateProps={{
              value: formik.values.toDate,
              onChange: (date) => {
                formik.setFieldValue('toDate', date);
                formik.setFieldTouched('toDate', true, false);
              },
              format: DATE_FNS_DATE_FORMAT_USA,
              label: 'To',
              touched: formik.touched.toDate as boolean,
              errorMsg: formik.errors.toDate as string,
              showOnlyTouchedError: true,
              minDate: formik.values.fromDate,
              variant: 'inline',
              PopoverProps: { style: { marginLeft: '15px' } },
              autoOk: true,
            }}
          />
        </Modal.Content>
        <Modal.Actions className="linq-dialog__actions">
          <Button
            id="cancelButton"
            onClick={handleClose}
            aria-label={'cancel'}
            variant="outlined"
            color="primary"
            data-test-id="notificationDialogCancelBtn"
          >
            Cancel
          </Button>
          <Button
            id="okButton"
            type="submit"
            aria-label={'ok'}
            variant="contained"
            color="primary"
            data-test-id="notificationDialogConfirmBtn"
          >
            Ok
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
};
