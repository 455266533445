import React, { ReactElement } from 'react';

import * as Constants from 'Constants/Constants';
import * as Interfaces from 'Interfaces/Interfaces';

import './SpecialMealAccommodations.less';

const SpecialMealAccommodations = ({
  specialMealAccommodationIds,
  customSpecialMealAccommodations,
  showSpecialIconsOnly,
  hideLabel,
}: Interfaces.SpecialMealAccommodationsProps): ReactElement => {
  return (
    <div className="special-meal-accommodations-container">
      {!showSpecialIconsOnly && !hideLabel && <div className="label">Special Meal Accommodations</div>}
      {specialMealAccommodationIds && (
        <div className="list">
          {specialMealAccommodationIds.map((specialMealAccommodationId) => {
            const specialMealAccommodation = Constants.SPECIAL_MEAL_ACCOMMODATIONS[specialMealAccommodationId];
            return (
              <div key={specialMealAccommodationId} className="item">
                <i className={` ${specialMealAccommodation.icon} circle-icon`}></i>
                {!showSpecialIconsOnly && <div className="accommodation-text">{specialMealAccommodation.name}</div>}
              </div>
            );
          })}
        </div>
      )}
      {customSpecialMealAccommodations && (
        <div className="custom">
          <div className="label">May also contain:</div>
          <div className="custom-acc-text">{customSpecialMealAccommodations}</div>
        </div>
      )}
    </div>
  );
};

export default SpecialMealAccommodations;
