import React, { FC } from 'react';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MEDIA_QUERY } from 'Constants/Constants';

import './SubHeader.less';

export interface SubHeaderProps {
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  title?: string;
  className?: string;
}

export const SubHeader: FC<SubHeaderProps> = ({ leftComponent, rightComponent, title, className }) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY });

  return (
    <header className={classnames('sub-header', { 'sub-header--mobile': isMobile }, className)}>
      <div className="sub-header__content">
        {title && <h2 className="sub-header__title">{title}</h2>}
        {leftComponent && <div className="sub-header__content-secondary">{leftComponent}</div>}
      </div>
      {rightComponent && <div className="sub-header__actions">{rightComponent}</div>}
    </header>
  );
};
