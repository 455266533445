import React from 'react';
import classNames from 'classnames';
import { FormControlLabel, Switch as SwitchComponent } from '@material-ui/core';
import * as Interfaces from 'Interfaces/Interfaces';

export const Switch = ({
  id,
  name,
  onChange,
  value,
  color,
  errorMsg,
  onBlur,
  readOnly,
  disabled = false,
  error = false,
  fieldLabel,
  className,
}: Interfaces.SwitchFieldProps) => {
  const hasError = error && errorMsg;
  return (
    <>
      <FormControlLabel
        className={classNames('switch-field', className, disabled && 'switch-disabled')}
        control={
          <SwitchComponent
            checked={value || false}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            color={color}
            data-test-id={`${id}Btn`}
            disabled={disabled || readOnly}
          />
        }
        label={<span className={classNames(hasError && 'switch-error')}>{fieldLabel}</span>}
      />
      {hasError && <span className="MuiFormHelperText-root Mui-error switch-error-msg">{errorMsg}</span>}
    </>
  );
};
