import { Reducer } from 'react';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';

const initialState: Interfaces.ALaCarteState = {};

const ALaCarteReducer: Reducer<Interfaces.ALaCarteState, Interfaces.Action> = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.ALACARTE_SET_PRODUCTS:
      return {
        ...state,
        products: payload,
      };

    default:
      return state;
  }
};

export default ALaCarteReducer;
