import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import Card from 'Components/Card/Card';
import * as DistrictSelectors from 'Components/District/state/selectors';
import * as MenuTypePickerSelectors from 'Components/MenuTypePicker/state/selectors';
import * as Constants from 'Constants/Constants';
import { groupArrayByCounts } from 'Helpers/group';
import * as Interfaces from 'Interfaces/Interfaces';
import { get } from 'lodash';
import numeral from 'numeral';
import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import './ReimbursableMealPricingItem.less';

const getPaddingColumns = (padding: number, index: number) => {
  const paddingColumns: JSX.Element[] = [];

  for (let i = 0; i < padding; i++) {
    paddingColumns.push(<div className="pricing-group" key={`${index}-padding-column-${i}`} />);
  }
  return paddingColumns;
};

const COLUMNS_PER_ROW = 4;

const ReimbursableMealPricingItem = ({
  index,
  reimbursableMealType,
  onEdit,
}: Interfaces.ReimbursableMealPricingItemProps) => {
  const reimbursableMealPricingModes = useSelector(DistrictSelectors.getReimbursableMealPricingModes);
  const reimbursableMealPricesBySite = useSelector(DistrictSelectors.getReimbursableMealPrices);
  const reimbursableMealPricesByGradeGroups = useSelector(
    DistrictSelectors.getReimbursableMealPricesByGradeGroups(reimbursableMealType.id),
  );
  const menuTypeId = useSelector(MenuTypePickerSelectors.getMenuTypeId) || '';

  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });

  const pricingMode = reimbursableMealType
    ? reimbursableMealPricingModes[`${menuTypeId}.${reimbursableMealType.id}`]?.pricingMode ??
      Interfaces.PricingMode.PRICING_BY_SITE
    : Interfaces.PricingMode.PRICING_BY_SITE;
  if (!reimbursableMealType) {
    return null;
  }

  // UX wants the pricing groups to wrap a certain order.
  const groupedSitesForLayout = groupArrayByCounts([4, 4, 3], Object.values(Constants.SITE_GROUP_TYPES));
  groupedSitesForLayout[2].push(undefined);

  return (
    <div
      className={classNames('reimbursable-meal-pricing-item-container', {
        'reimbursable-meal-pricing-item-container-mobile': isMobile,
      })}
    >
      <Card
        title={reimbursableMealType.name}
        description={`Set the price for ${reimbursableMealType.name.toLocaleLowerCase()} entrees`}
        onEditClick={() => onEdit()}
        editAriaLabel={`Click to edit pricing for ${reimbursableMealType.name}`}
      >
        {pricingMode === Interfaces.PricingMode.PRICING_BY_GRADE && (
          <Grid className="flex-column">
            <div className="row">
              <div className="col-md-8 col-sm-12 flex">
                <div className="groups">
                  {reimbursableMealPricesByGradeGroups.map((group, i) => {
                    const count = i + 1;
                    const shouldBreakRow = count % COLUMNS_PER_ROW === 0;
                    return (
                      <React.Fragment key={`groupedGrades-${i}`}>
                        {
                          <div
                            className="pricing-group"
                            key={`${index}${i}${reimbursableMealType.id}.${group.grades?.join(',')}`}
                          >
                            <h6 className="linq-card__h6">{`Grades: ${group.grades?.join(', ').trimEnd()}`}</h6>
                            <div
                              className="price"
                              data-test-id={`${reimbursableMealType.name}.${group.grades?.join(', ').trimEnd()}
                                .priceLbl`}
                            >
                              {group.price !== undefined ? numeral(group.price).format(Constants.USD_FORMAT) : '-'}
                            </div>
                          </div>
                        }
                        {shouldBreakRow && <div className="break-column" />}
                        {count === reimbursableMealPricesByGradeGroups.length && getPaddingColumns(count % i, index)}
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </Grid>
        )}
        {pricingMode !== Interfaces.PricingMode.PRICING_BY_GRADE && (
          <Grid className="flex-column">
            <div className="row">
              <div className="col-md-8 col-sm-12 flex">
                <div className="groups">
                  {groupedSitesForLayout.map((group, i) => (
                    <React.Fragment key={`groupedSites-${i}`}>
                      {group.map((siteGroupType: Interfaces.SiteGroupType) => {
                        if (!siteGroupType) {
                          return <div key={i} className="pricing-group"></div>;
                        }
                        const price = get(reimbursableMealPricesBySite, [
                          `${reimbursableMealType.id}.${siteGroupType.id}`,
                          'price',
                        ]);
                        return (
                          <div
                            className="pricing-group"
                            key={`${index}${i}${reimbursableMealType.id}.${siteGroupType.id}`}
                          >
                            <h6 className="linq-card__h6">{siteGroupType.name}</h6>
                            <div
                              className="price"
                              data-test-id={`${reimbursableMealType.name}.${siteGroupType.name}.priceLbl`}
                            >
                              {price !== undefined ? numeral(price).format(Constants.USD_FORMAT) : '-'}
                            </div>
                          </div>
                        );
                      })}
                      <div className="break-column" />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </Grid>
        )}
      </Card>
    </div>
  );
};

export default ReimbursableMealPricingItem;
