import React from 'react';
import { Card as MuiCard, CardActions, CardContent, CardHeader } from '@material-ui/core';
import Button from 'Components/Button/Button';

import './MultiActionCard.less';
import classNames from 'classnames';

interface MultiActionCardProps {
  title: string;
  description?: string;
  onCancelAction?: () => void;
  cancelActionDisabled?: boolean;
  cancelActionLabel?: string;
  cancelActionAriaLabel?: string;
  submitDisabled?: boolean;
  submitLabel: string;
  submitAriaLabel: string;
  submitButtonVarient?: 'text' | 'outlined' | 'contained';
  loading: boolean;
  cardClassname?: string;
  testIds?: Record<string, string>;
}

const MultiActionCard: React.FC<MultiActionCardProps> = ({
  title,
  description,
  children,
  onCancelAction,
  cancelActionDisabled,
  cancelActionLabel,
  cancelActionAriaLabel,
  submitDisabled,
  submitLabel,
  submitAriaLabel,
  loading,
  cardClassname,
  testIds,
  submitButtonVarient,
}) => {
  return (
    <MuiCard className={classNames('linq-card multi-action-card', cardClassname)}>
      <CardHeader title={title} />
      <CardContent className="linq-card__space">
        {description && <p>{description}</p>}
        {children}
      </CardContent>
      <CardActions className="linq-card__actions">
        <div>
          {onCancelAction && (
            <Button
              disabled={cancelActionDisabled}
              variant="outlined"
              color="secondary"
              onClick={onCancelAction}
              type="button"
              aria-label={cancelActionAriaLabel}
              className="linq-button--alert-stroked"
              data-test-id={testIds?.cancel}
            >
              {cancelActionLabel}
            </Button>
          )}
        </div>
        <div>
          <Button
            disabled={submitDisabled}
            variant={submitButtonVarient ? submitButtonVarient : 'text'}
            color="primary"
            type="submit"
            aria-label={submitAriaLabel}
            loading={loading}
            className="submit-button"
            data-test-id={testIds?.submit}
          >
            {submitLabel}
          </Button>
        </div>
      </CardActions>
    </MuiCard>
  );
};

export default MultiActionCard;
