import React, { useMemo, useCallback } from 'react';
import { useFormik } from 'formik';
import { FormControl } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import * as Yup from 'yup';

import { SelectField } from 'Components/Form/MaterialForm';
import * as Constants from 'Constants/Constants';

import './TimePicker.less';

const TimePicker = (props: any) => {
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const { value, onChange } = props;
  const values = useMemo(() => {
    return !value ? ['', '', ''] : value?.split(/\s|:/g);
  }, [value]);

  const hourOptions = useMemo(() => {
    const hours = [];
    for (let i = 1; i <= 12; ++i) {
      let value = i <= 9 ? '0' + i.toString() : i.toString();
      hours.push({ value, text: value });
    }
    return hours;
  }, []);
  const minuteOptions = useMemo(() => {
    const minutes = [];
    for (let i = 0; i <= 59; ++i) {
      let value = i <= 9 ? '0' + i.toString() : i.toString();
      minutes.push({ value, text: value });
    }
    return minutes;
  }, []);

  const formik = useFormik({
    initialValues: {
      hour: values[0],
      minute: values[1],
      ampm: values[2],
    } as { [key: string]: any },
    onSubmit: (values) => {
      const newTime = `${values.hour}:${values.minute} ${values.ampm}`;
      onChange(newTime);
    },
    validationSchema: Yup.object().shape({
      hour: Yup.string().min(2).required(),
      minute: Yup.string().min(2).required(),
      ampm: Yup.string().min(2).required(),
    }),
    enableReinitialize: true,
  });

  const handleOnChange = useCallback(
    async (e) => {
      await formik.handleChange(e);
      await formik.submitForm();
    },
    [formik],
  );

  return (
    <div className={classNames('timepicker-container', { 'timepicker-container-mobile': isMobile })}>
      <FormControl>
        <SelectField
          id="hour"
          name="hour"
          value={formik.values.hour}
          options={hourOptions}
          fullWidth={false}
          onChange={handleOnChange}
          fieldLabel="Hour"
          inputLabelProps={{ className: 'sr-only' }}
        />
      </FormControl>
      <FormControl>
        <SelectField
          id="minute"
          name="minute"
          value={formik.values.minute}
          options={minuteOptions}
          fullWidth={false}
          onChange={handleOnChange}
          fieldLabel="Minute"
          inputLabelProps={{ className: 'sr-only' }}
        />
      </FormControl>
      <FormControl>
        <SelectField
          id="ampm"
          name="ampm"
          value={formik.values.ampm}
          options={Constants.AM_PM}
          fullWidth={false}
          onChange={handleOnChange}
          fieldLabel="AM or PM"
          inputLabelProps={{ className: 'sr-only' }}
        />
      </FormControl>
    </div>
  );
};

export default TimePicker;
