import React, { FC, HTMLAttributes } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import './Modal.less';

// Could not get this properly typed!
// If anyone else can, please do!
export interface ModalStaticProperties {
  Actions?: any;
  Content?: any;
  ContentText?: any;
  Header?: any;
  Title?: any;
}

/**
 * SemanticUI is not accessible. In order to make the site
 * accessible the modal needs to be replaced with the MartialUI
 * dialog component. Because all the files and logic is named
 * Modal, this component exports a MaterialUI dialog but
 * renames the component to modal for backwards compatibility.
 */
const Modal: FC<DialogProps> & ModalStaticProperties = Dialog;

const Header: FC<HTMLAttributes<HTMLElement>> = ({ children, ...attr }) => <header {...attr}>{children}</header>;

// Extend the Dialog component to mimic SemanticUI
Modal.Actions = DialogActions;
Modal.Content = DialogContent;
Modal.ContentText = DialogContentText;
Modal.Header = Header;
Modal.Title = DialogTitle;

export default Modal;
