import { formatISO } from 'date-fns';
import { Row } from 'react-table';
import numeral from 'numeral';

export const sortDateColumn = (rowA: Row<any>, rowB: Row<any>, columnId: string) => {
  const dateA = formatISO(new Date(rowA.original[columnId]), { representation: 'date' });
  const dateB = formatISO(new Date(rowB.original[columnId]), { representation: 'date' });
  return dateA.localeCompare(dateB) < 0 ? -1 : 1;
};

export const sortMoneyColumn = (rowA: Row<any>, rowB: Row<any>, columnId: string) => {
  const amountA = numeral(rowA.original[columnId]).value();
  const amountB = numeral(rowB.original[columnId]).value();

  if (amountA === amountB) {
    return 0;
  }

  return amountA < amountB ? -1 : 1;
};
