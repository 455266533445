import './polyfills';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';
import { theme } from './styles/theme';
import AxeLoader from './components/AxeLoader/AxeLoader';
import { createBrowserHistory } from 'history';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { globalConfigUrl, globalConfig } from './config';

import './index.less';

process.hrtime = require('browser-process-hrtime');

const { store, persistor } = configureStore();

export const history = createBrowserHistory();

const onRedirectCallback = (appState: AppState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};

axios
  .get(`${process.env.PUBLIC_URL}/${globalConfigUrl}`)
  .then((response) => {
    globalConfig.setConfig(response.data);

    const config = globalConfig.getPortalConfig();
    datadogLogs.init({
      env: config.env,
      clientToken: 'pubc0c030dd452f5945669217e35b02c2a5',
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sampleRate: config.env === 'development' || config.env === 'production' || config.env === 'staging' ? 100 : 0,
      service: 'online-ordering.react-app',
      version: config.version,
    });

    ReactDOM.render(
      <Auth0Provider
        domain={config.domain}
        clientId={config.clientId}
        redirectUri={config.redirect}
        onRedirectCallback={onRedirectCallback}
        audience={config.audience}
        scope={config.scope}
        cacheLocation="localstorage"
        useRefreshTokens={true}
      >
        <ThemeProvider theme={theme}>
          <Helmet>
            <link
              href="https://unpkg.com/material-components-web@11.0.0/dist/material-components-web.min.css"
              rel="stylesheet"
              media="all"
            />
            <link href="https://design.linq.com/9.1.7/theme/blueberry-muffin.css" rel="stylesheet" media="all" />
            <link href="https://design.linq.com/9.1.7/core/linq-snackpaq-core.css" rel="stylesheet" media="all" />
            <link href="https://design.linq.com/9.1.7/pattern-library/css/icons.css" rel="stylesheet" media="all" />
          </Helmet>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
          <AxeLoader environment={config.env} />
        </ThemeProvider>
      </Auth0Provider>,
      document.getElementById('root'),
    );
  })
  .catch(() => {
    // TODO: can't connect to domain.  show maintenace page?
    console.log('app configuration could be found');
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
