import { Reducer } from 'react';

import * as Types from './types';

import { CommonState, Action, OrderItem, PickupLocation, District, Student } from 'Interfaces/Interfaces';

const initialState: CommonState = {
  pickupLocations: {},
  homerooms: {},
  menuTypes: {},
  menuItems: {},
  products: {},
  productCategories: {},
  reimbursableMealTypes: {},
  siteGroupTypes: {},
  specialMealAccommodations: {},
  students: {},
  studentsTotalCount: 0,
  studentsLastUpdatedAt: '',
  orderItems: {},
  districts: {},
  sites: {},
  openSidebars: [],
};

const CommonReducer: Reducer<CommonState, Action> = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_PICKUP_LOCATIONS:
      return {
        ...state,
        pickupLocations: { ...payload },
      };

    case Types.UPDATE_PICKUP_LOCATIONS:
      return {
        ...state,
        pickupLocations: updatePickupLocations(state, payload),
      };
    case Types.PICKUP_LOCATION_DELETED:
      return {
        ...state,
        pickupLocations: removePickupLocation(state, payload),
      };

    case Types.SET_HOME_ROOMS:
      return {
        ...state,
        homerooms: { ...payload },
      };

    case Types.SET_MENU_TYPES:
      return {
        ...state,
        menuTypes: { ...payload },
      };

    case Types.SET_MENU_ITEMS:
      return {
        ...state,
        menuItems: { ...payload },
      };

    case Types.SET_PRODUCTS:
      return {
        ...state,
        products: { ...payload },
      };

    case Types.SET_PRODUCT_CATEGORIES:
      return {
        ...state,
        productCategories: { ...payload },
      };

    case Types.SET_REIMBURSABLE_MEAL_TYPES:
      return {
        ...state,
        reimbursableMealTypes: { ...payload },
      };

    case Types.SET_SITE_GROUP_TYPES:
      return {
        ...state,
        siteGroupTypes: { ...payload },
      };

    case Types.SET_SPECIAL_MEAL_ACCOMMODATIONS:
      return {
        ...state,
        specialMealAccommodations: { ...payload },
      };

    case Types.SET_STUDENTS:
      return {
        ...state,
        students: { ...payload.students },
        studentsTotalCount: payload.students.length,
        studentsLastUpdatedAt: payload.lastUpdatedAt ?? state.studentsLastUpdatedAt,
      };

    case Types.REMOVE_STUDENT:
      return {
        ...state,
        students: removeStudent(state, payload),
      };

    case Types.ADD_STUDENT:
      const newStudent = {
        [payload.id]: payload,
      };
      return {
        ...state,
        students: { ...state.students, ...newStudent },
      };

    case Types.SET_ORDERS:
      return {
        ...state,
        orderItems: payload,
      };

    case Types.SET_DISTRICTS:
      return {
        ...state,
        districts: { ...payload },
      };

    case Types.SET_DISTRICT:
      return {
        ...state,
        districts: setDistrict(state, payload),
      };

    case Types.SET_SITES:
      return {
        ...state,
        sites: { ...payload },
      };

    case Types.ORDER_ITEM_DELETED:
      return {
        ...state,
        orderItems: removeOrderItem(state, payload),
      };

    case Types.ORDER_ITEM_PATCHED:
      return {
        ...state,
        orderItems: updateOrderItem(state, payload),
      };

    case Types.APPEND_MENU_ITEMS:
      return {
        ...state,
        menuItems: { ...state.menuItems, ...payload },
      };

    case Types.APPEND_ORDER_ITEMS:
      return {
        ...state,
        orderItems: { ...state.orderItems, ...payload },
      };

    case Types.APPEND_PRODUCTS:
      return {
        ...state,
        products: { ...state.products, ...payload },
      };

    case Types.UPDATE_STUDENT: {
      const newStudents = { ...state.students };
      const index = Object.values(state.students).findIndex((student: Student) => {
        return student.id === payload.id;
      });

      if (index === -1) {
        return state;
      } else {
        newStudents[index] = payload;
        return { ...state, students: newStudents };
      }
    }

    case Types.SET_SIDEBAR_OPEN: {
      const alreadyOpenSidebars = state.openSidebars;
      if (payload.isOpen && !alreadyOpenSidebars.includes(payload.location)) {
        return { ...state, openSidebars: [...alreadyOpenSidebars, payload.location] };
      } else if (!payload.isOpen && alreadyOpenSidebars.includes(payload.location)) {
        return { ...state, openSidebars: alreadyOpenSidebars.filter((location) => location !== payload.location) };
      }

      return state;
    }

    case Types.TOGGLE_SIDEBAR_OPEN: {
      const alreadyOpenSidebars = state.openSidebars;
      if (!alreadyOpenSidebars.includes(payload.location)) {
        return { ...state, openSidebars: [...alreadyOpenSidebars, payload.location] };
      } else {
        return { ...state, openSidebars: alreadyOpenSidebars.filter((location) => location !== payload.location) };
      }
    }

    default:
      return state;
  }
};

const removeOrderItem = (state: CommonState, orderItem: OrderItem): any => {
  const newOrderItems = { ...state.orderItems };
  delete newOrderItems[orderItem.id];
  return newOrderItems;
};

const updateOrderItem = (state: CommonState, orderItem: OrderItem) => {
  const currentOrderItems = { ...state.orderItems };
  currentOrderItems[orderItem.id] = orderItem;
  return currentOrderItems;
};

const removePickupLocation = (state: CommonState, pickupLocation: PickupLocation): any => {
  const newPickupLocations = { ...state.pickupLocations };
  delete newPickupLocations[pickupLocation.id];
  return newPickupLocations;
};

const updatePickupLocations = (state: CommonState, pickupLocations: { [key: string]: PickupLocation }) => {
  const currentPickupLocations = { ...state.pickupLocations };
  Object.values(pickupLocations).forEach((pickupLocation) => {
    currentPickupLocations[pickupLocation.id] = pickupLocation;
  });
  return currentPickupLocations;
};

const removeStudent = (state: CommonState, studentId: string): any => {
  const newStudents = { ...state.students };
  delete newStudents[studentId];
  return newStudents;
};

const setDistrict = (state: CommonState, district: District): any => {
  const newDistricts = { ...state.districts };
  newDistricts[district.id] = district;
  return newDistricts;
};

export default CommonReducer;
