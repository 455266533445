import React, { ReactElement, useEffect, useMemo } from 'react';
import { useSelector, useDispatch, batch, shallowEqual } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import * as Constants from 'Constants/Constants';
import MenuTypePicker from 'Components/MenuTypePicker/MenuTypePicker';
import CalendarWeek from 'Components/Shop/CalendarWeek/CalendarWeek';
import MenuItemModal from 'Components/Shop/MenuItemModal/MenuItemModal';
import CancelOrderModal from 'Components/Shop/CancelOrderModal/CancelOrderModal';
import { ComponentLoader } from 'Components/Loaders/Loaders';
import * as MenuTypePickerSelectors from 'Components/MenuTypePicker/state/selectors';
import { AutocompleteDropdown } from 'Components/Form/MaterialForm';
import * as DisclaimerModalActions from 'Components/Shop/DisclaimerModal/state/actions';
import * as LoadersSelectors from 'Components/Loaders/state/selectors';
import CutOffWarningMessage from 'Components/Shop/CutOffWarning/CutOffWarning';
import { getDayRange } from 'Components/DateRangeNavigator/state/selectors';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import * as CommonActions from 'State/actions';
import * as CommonSelectors from 'State/selectors';
import * as Interfaces from 'Interfaces/Interfaces';
import { SidebarLocations } from 'Constants/Enums';
import DateRangeNavigator from 'Components/DateRangeNavigator/DateRangeNavigator';
import * as CommonHooks from 'State/hooks';
import * as ShopHooks from './state/hooks';
import * as ShopSelectors from './state/selectors';
import * as ShopActions from './state/actions';

import { Role, isAuthorized, TokenClaims } from 'Auth/User';

import './Shop.less';
import { useMediaQuery } from 'react-responsive';

const Shop = (): ReactElement => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const principalId = TokenClaims.getPrincipalId(user);
  const isLoading = useSelector(LoadersSelectors.getComponentLoaderActive);
  const showModal = useSelector((state: Interfaces.AppState) =>
    CommonSelectors.getSidebarOpen(state, SidebarLocations.MENU_ITEM_SIDE_BAR),
  );
  const student = useSelector(ShopSelectors.getStudent);
  const parentAcceptedTerms = useSelector(ShopSelectors.hasParentAcceptedTerms(principalId));
  const isCutoffWarningVisible = useSelector(ShopSelectors.isCutoffWarningVisible(userRole));
  const userDistrictId = TokenClaims.getDistrictId(user);
  const isTeacher = isAuthorized(userRole, [Role.TEACHER]);
  const studentDistrictId = student?.districtId;
  const studentId = student?.id;
  const defaultMenuType = useSelector(MenuTypePickerSelectors.getDefaultMenuType);
  const menuTypeId = useSelector(MenuTypePickerSelectors.getMenuTypeId) ?? defaultMenuType.id;
  const orderingRules = useSelector(ShopSelectors.getOrderingRules);
  const dayRange = useSelector(getDayRange);
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const isAdult = student?.reimbursableMealTypeId === Constants.REIMBURSABLE_MEAL_TYPES.ADULT.id;
  const { getSites } = CommonActions.useCommonActions();
  const siteOptions = useSelector(CommonSelectors.getSiteOptions, shallowEqual);
  const selectedSite = useSelector(ShopSelectors.getSiteId) ?? undefined;

  useEffect(() => {
    if (studentDistrictId) {
      getSites(studentDistrictId);
    }
  }, [dispatch, getSites, studentDistrictId]);

  useEffect(() => {
    dispatch(ShopActions.setSiteId(student?.siteId));
  }, [student, dispatch]);

  const { startDate, endDate } = useMemo(() => {
    return {
      startDate: dayRange.startDate.format(Constants.DATE_FORMAT_YMD),
      endDate: dayRange.endDate.format(Constants.DATE_FORMAT_YMD),
    };
  }, [dayRange]);

  CommonHooks.useStudents(isTeacher, userDistrictId);
  CommonHooks.useDistrict(studentDistrictId);
  const { getShopData } = ShopHooks.useShopData(studentId, studentDistrictId, menuTypeId, selectedSite);

  useEffect(() => {
    batch(() => {
      dispatch(CommonActions.setMenuItems());
      dispatch(CommonActions.setProducts());
      dispatch(CommonActions.setOrderItems());
    });
    getShopData();
  }, [dispatch, studentId, menuTypeId, startDate, endDate, getShopData]);

  // toggle disclaimer
  useEffect(() => {
    dispatch(DisclaimerModalActions.showCloseButton(false));
    if (student && !parentAcceptedTerms && !isTeacher) {
      dispatch(DisclaimerModalActions.setOpen(true));
    } else {
      dispatch(DisclaimerModalActions.setOpen(false));
    }
    return () => {
      dispatch(DisclaimerModalActions.setOpen(false));
    };
  }, [parentAcceptedTerms, dispatch, student, isTeacher]);

  return (
    <div className="shop-container">
      <div className="container-fluid m-0 p-0">
        <div className="row g-0">
          <div className="col-sm-12 px-0">
            <SubHeader
              rightComponent={isCutoffWarningVisible && <CutOffWarningMessage orderingRules={orderingRules} />}
            />
          </div>
        </div>
        <div className="row g-0">
          <div
            className={classNames('col-sm-12 col-lg-6 col-xl-6 px-0', 'shop-options-container', { mobile: isMobile })}
          >
            {isAdult && siteOptions && (
              <AutocompleteDropdown
                label="Location"
                id="siteId"
                className={classNames('location-dropdown', { mobile: isMobile })}
                value={selectedSite}
                onChange={(e, selectedOption) => {
                  if (selectedOption) {
                    dispatch(ShopActions.setSiteId(selectedOption.value));
                  }
                }}
                options={siteOptions}
              />
            )}

            <MenuTypePicker />
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4 px-0"></div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4 px-0">
            <DateRangeNavigator mode={Constants.DateRangeMode.WEEK} defaultDayOfYearToWeekday={true} />
          </div>
        </div>
        <ComponentLoader />
        <CancelOrderModal getShopData={getShopData} />
        {!isLoading && <CalendarWeek />}
        {showModal && <MenuItemModal />}
      </div>
    </div>
  );
};

export default Shop;
