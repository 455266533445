import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { PageLoader } from 'Components/Loaders/Loaders';
import { RedirectLoginOptions } from '@auth0/auth0-spa-js/dist/typings/global';
import { getRoutePath, RouteId } from 'Constants/Routes';
import { isFamilyPortal } from 'Helpers/Helper';

const AuthenticationValidator: React.FC = ({ children }) => {
  const { user, isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const familyPortal = isFamilyPortal();

  useEffect(() => {
    async function getToken() {
      try {
        await getAccessTokenSilently();

        // handle case where token is valid
        if (location.pathname === getRoutePath(RouteId.LOGIN)) {
          navigate(getRoutePath(RouteId.ROOT));
        }
      } catch (err: any) {
        if (err.message === 'Login required') {
          const options: RedirectLoginOptions = {};

          if (location.pathname === getRoutePath(RouteId.LOGIN)) {
            const params = new URLSearchParams(location.search);
            const invitation = params.get('invitation') as string;
            const loginHint = params.get('login_hint') as string;

            if (invitation) {
              options.invitation = invitation;
            }

            if (loginHint) {
              options.login_hint = loginHint;
            }
          }
          loginWithRedirect(options);
        }
      }
    }

    if ((!familyPortal && !isAuthenticated) || (familyPortal && !isAuthenticated && user) || location.pathname === getRoutePath(RouteId.LOGIN)) {
      getToken();
    }
  }, [
    loginWithRedirect,
    isAuthenticated,
    getAccessTokenSilently,
    location.pathname,
    location.search,
    user,
    familyPortal,
  ]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (!familyPortal && !isAuthenticated) {
    return null;
  }

  return <>{children}</>;
};

export default AuthenticationValidator;
