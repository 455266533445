import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Dictionary, groupBy, sortBy } from 'lodash';
import { ButtonLink } from 'Components/ButtonLink/ButtonLink';
import { ComponentLoaderNoRedux } from 'Components/Loaders/Loaders';
import { getFriendlyNameFromSnakeCase } from 'Helpers/Helper';
import { SITE_GROUP_SORT_ORDER } from 'Constants/Constants';
import { RouteId, getRoutePath } from 'Constants/Routes';
import { SchoolMenu } from 'Interfaces/Interfaces';

import './SchoolMenuList.less';

export interface SchoolMenuListProps {
  publisehdMenuData: SchoolMenu[];
  loading: boolean;
}

const sortSiteGroups = (siteGroupsData: Dictionary<SchoolMenu[]>) => {
  const sortedGroups: SchoolMenu[][] = [];

  // Iterate over grouped site groups and place values in new array based on defined site group order
  Object.entries(siteGroupsData).forEach(([siteGroupId, values]) => {
    const index = SITE_GROUP_SORT_ORDER[siteGroupId];

    if (index !== undefined) {
      sortedGroups[index] = values;
    }
  });

  // Remove empty indexes from missing site groups
  return sortedGroups.filter((x) => x);
};

export const SchoolMenuList = ({ publisehdMenuData, loading }: SchoolMenuListProps) => {
  const navigate = useNavigate();
  const menuDataBySiteGroup = React.useMemo(() => {
    const siteGroups = groupBy(publisehdMenuData, 'siteGroupTypeId');

    // Sort site groups by set order
    return sortSiteGroups(siteGroups);
  }, [publisehdMenuData]);

  return (
    <div className="school-menus-container" key={'school-menus'}>
      <ComponentLoaderNoRedux loading={loading} />
      {!!publisehdMenuData.length && !loading && (
        <>
          {menuDataBySiteGroup.map((siteMenus) => {
            const groupedSiteMenus = groupBy(sortBy(siteMenus, 'siteName'), 'siteName');
            const siteGroupTypeId = siteMenus[0]?.siteGroupTypeId;

            return (
              <div className="site-group-container" key={`school-menus-${siteGroupTypeId}`}>
                <h3>{getFriendlyNameFromSnakeCase(siteGroupTypeId)}</h3>
                <div className="sites-container" key={siteGroupTypeId}>
                  {Object.values(groupedSiteMenus).map((schoolMenus) => {
                    const siteId = schoolMenus[0]?.siteId ?? '';
                    const siteName = schoolMenus[0]?.siteName ?? '';
                    const publishedMonth = schoolMenus[0]?.publishedMonth;

                    return (
                      <div className="site-menus-container" key={`${siteGroupTypeId}-${siteId}`}>
                        <span key={`${siteGroupTypeId}-${siteId}-name`}>{siteName}</span>
                        {sortBy(schoolMenus, 'name').map((menu, index) => (
                          <div className="site-menu" key={`${siteGroupTypeId}-${siteId}-${menu.menuTypeId}-${index}`}>
                            <ButtonLink
                              text={menu.name}
                              onClick={() => {
                                const queryParams = new URLSearchParams();
                                queryParams.append('siteId', siteId);
                                queryParams.append('siteName', siteName);
                                queryParams.append('siteGroupTypeId', siteGroupTypeId);
                                queryParams.append('menuTypeId', menu.menuTypeId);
                                queryParams.append('menuName', menu.name);
                                queryParams.append('month', publishedMonth);

                                navigate(`${getRoutePath(RouteId.SHOP_PREVIEW)}?${queryParams}`);
                              }}
                              data-test-id={`${siteId}.${menu.menuTypeId}.prevewMenuBtn`}
                            />
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </>
      )}
      {!publisehdMenuData.length && !loading && (
        <div className="no-menus-published">
          <span>No menus published for this month</span>
        </div>
      )}
    </div>
  );
};
