import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from 'Components/Button/Button';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import Modal from 'Components/Modal/Modal';
import * as Constants from 'Constants/Constants';

import './TermsOfUseModal.less';

export interface TermsOfUseModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const TermsOfUseModal = ({ isOpen, handleClose }: TermsOfUseModalProps) => {
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });

  return (
    <Modal open={isOpen} maxWidth={'md'} className="linq-dialog terms-of-use-modal" id="terms-of-use-modal">
      <Modal.Title className="linq-dialog__header d-flex align-items-center" disableTypography={true}>
        <h3 className="linq-header">Terms of Use</h3>
        <CloseIconButton onClick={handleClose} />
      </Modal.Title>
      <Modal.Content className="linq-dialog__content">
        <p>
          These Terms and Conditions of Use (the “Terms of Use”) apply to the EMS LINQ, LLC web site(s) located at
          &nbsp;
          <a href="https://family.onlineordering.linq.com" target="_blank" rel="noreferrer">
            &nbsp; family.onlineordering.linq.com
          </a>
          , and all associated sites linked to &nbsp;
          <a href="https://family.onlineordering.linq.com/" target="_blank" rel="noreferrer">
            family.onlineordering.linq.com &nbsp;
          </a>
          EMS LINQ, LLC, its subsidiaries and affiliates (collectively, the “Site”). The Site is the property of EMS
          LINQ, LLC (“LINQ”) and its licensors. BY USING THE SITE, YOU AGREE TO THESE TERMS OF USE; IF YOU DO NOT AGREE,
          DO NOT USE THE SITE.
        </p>
        <p>
          LINQ reserves the right, at its sole discretion, to change, modify, add or remove portions of these Terms of
          Use, at any time. It is your responsibility to check these Terms of Use periodically for changes. Your
          continued use of the Site following the posting of changes will mean that you accept and agree to the changes.
          As long as you comply with these Terms of Use, LINQ grants you a personal, non-exclusive, non-transferable,
          limited privilege to enter and use the Site.
        </p>
        <p>
          <strong>Content</strong>
        </p>
        <p>
          All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork
          and computer code (collectively, “Content”), including but not limited to the design, structure, selection,
          coordination, expression, “look and feel” and arrangement of such Content, contained on the Site is owned,
          controlled or licensed by or to LINQ, and is protected by trade dress, copyright, patent and trademark laws,
          and various other intellectual property rights and unfair competition laws.
        </p>
        <p>
          Except as expressly provided in these Terms of Use, no part of the Site and no Content may be copied,
          reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed
          in any way (including “mirroring”) to any other computer, server, Web site or other medium for publication or
          distribution or for any commercial enterprise, without LINQ’s express prior written consent.
        </p>
        <p>
          <strong>Your Use of the Site</strong>
        </p>
        <p>
          You may not use any “deep-link”, “page-scrape”, “robot”, “spider” or other automatic device, program,
          algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any
          portion of the Site or any Content, or in any way reproduce or circumvent the navigational structure or
          presentation of the Site or any Content, to obtain or attempt to obtain any materials, documents or
          information through any means not purposely made available through the Site. LINQ reserves the right to bar
          any such activity.
        </p>
        <p>
          You may not attempt to gain unauthorized access to any portion or feature of the Site, or any other systems or
          networks connected to the Site or to any LINQ server, or to any of the services offered on or through the
          Site, by hacking, password “mining” or any other illegitimate means.
        </p>
        <p>
          You may not probe, scan, or test the vulnerability of the Site or any network connected to the Site, nor
          breach the security or authentication measures on the Site or any network connected to the Site. You may not
          reverse look-up, trace or seek to trace any information on any other user of or visitor to the Site, or any
          other customer of LINQ, including any LINQ account not owned by you, to its source, or exploit the Site or any
          service or information made available or offered by or through the Site, in any way where the purpose is to
          reveal any information, including but not limited to personal identification or information, other than your
          own information, as provided for by the Site.
        </p>
        <p>
          You agree that you will not take any action that imposes an unreasonable or disproportionately large load on
          the infrastructure of the Site or LINQ’s systems or networks, or any systems or networks connected to the Site
          or to LINQ.
        </p>
        <p>
          You agree not to use any device, software or routine to interfere or attempt to interfere with the proper
          working of the Site or any transaction being conducted on the Site, or with any other person’s use of the
          Site.
        </p>
        <p>
          You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message
          or transmittal you send to LINQ on or through the Site or any service offered on or through the Site. You may
          not pretend that you are, or that you represent, someone else, or impersonate any other individual or entity.
        </p>
        <p>
          You may not use the Site or any Content for any purpose that is unlawful or prohibited by these Terms of Use,
          or to solicit the performance of any illegal activity or other activity which infringes the rights of LINQ or
          others.
        </p>
        <p>
          <strong>Accounts, Passwords and Security</strong>
        </p>
        <p>
          Certain features or services offered on or through the Site may require you to open an account (including
          setting up a LINQ Online Ordering User ID and password). You are entirely responsible for maintaining the
          confidentiality of the information you hold for your account, including your password, and for any and all
          activity that occurs under your account as a result of your failing to keep this information secure and
          confidential. You agree to notify LINQ immediately of any unauthorized use of your account or password, or any
          other breach of security. You may be held liable for losses incurred by LINQ or any other user of or visitor
          to the Site due to someone else using your LINQ Online Ordering ID, password or account as a result of your
          failing to keep your account information secure and confidential.
        </p>
        <p>
          You may not use anyone else’s LINQ Online Ordering ID, password or account at any time without the express
          permission and consent of the holder of that LINQ Online Ordering ID, password or account. LINQ cannot and
          will not be liable for any loss or damage arising from your failure to comply with these obligations.
        </p>
        <p>
          <strong>Data Privacy</strong>
        </p>
        <p>
          LINQ’s Privacy Policy applies to use of this Site, and its terms are made a part of these Terms of Use by this
          reference. To view LINQ’s Privacy Policy,{' '}
          <a
            href="https://www.linq.com/wp-content/uploads/2023/03/EMS-LINQ-Privacy-Policy-032823.pdf"
            target="_blank"
            rel="noreferrer"
          >
            click here
          </a>
          . Additionally, by using the Site, you acknowledge and agree that Internet transmissions are never completely
          private or secure. You understand that any message or information you send to the Site may be read or
          intercepted by others, even if there is a special notice that a particular transmission (for example, credit
          card information) is encrypted.
        </p>
        <p>
          <strong>Links to Other Sites and to the LINQ Online Ordering Site </strong>
        </p>
        <p>
          This Site may contain links to other independent third-party Web sites (“Linked Sites”). These Linked Sites
          are provided solely as a convenience to our visitors. Such Linked Sites are not under LINQ’s control, and LINQ
          is not responsible for and does not endorse the content of such Linked Sites, including any information or
          materials contained on such Linked Sites. You will need to make your own independent judgment regarding your
          interaction with these Linked Sites.
        </p>
        <p>
          <strong>Disclaimers</strong>
        </p>
        <p>
          LINQ DOES NOT PROMISE THAT THE SITE OR ANY CONTENT, SERVICE OR FEATURE OF THE SITE WILL BE ERROR-FREE OR
          UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED, OR THAT YOUR USE OF THE SITE WILL PROVIDE SPECIFIC
          RESULTS. THE SITE AND ITS CONTENT ARE DELIVERED ON AN “AS-IS” AND “AS-AVAILABLE” BASIS. ALL INFORMATION
          PROVIDED ON THE SITE IS SUBJECT TO CHANGE WITHOUT NOTICE. LINQ DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
          INCLUDING ANY WARRANTIES OF ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
          LINQ DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT OF ANY THIRD PARTIES IN CONNECTION
          WITH OR RELATED TO YOUR USE OF THE SITE AND/OR ANY LINQ SERVICES. YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE
          OF THE SITE AND ANY LINKED SITES. YOUR SOLE REMEDY AGAINST LINQ FOR DISSATISFACTION WITH THE SITE OR ANY
          CONTENT IS TO STOP USING THE SITE OR ANY SUCH CONTENT. THIS LIMITATION OF RELIEF IS A PART OF THE BARGAIN
          BETWEEN THE PARTIES.
        </p>
        <p>
          The above disclaimer applies to any damages, liability or injuries caused by any failure of performance,
          error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus,
          communication line failure, theft or destruction of or unauthorized access to, alteration of, or use, whether
          for breach of contract, tort, negligence or any other cause of action.
        </p>
        <p>
          LINQ reserves the right to do any of the following, at any time, without notice: (1) to modify, suspend or
          terminate operation of or access to the Site, or any portion of the Site, for any reason; (2) to modify or
          change the Site, or any portion of the Site, and any applicable policies or terms; and (3) to interrupt the
          operation of the Site, or any portion of the Site, as necessary to perform routine or non-routine maintenance,
          error correction, or other changes.
        </p>
        <p>
          <strong>Limitation of Liability</strong>
        </p>
        <p>
          Except where prohibited by law, in no event will LINQ be liable to you for any indirect, consequential,
          exemplary, incidental or punitive damages, including lost profits, even if LINQ has been advised of the
          possibility of such damages. If, notwithstanding the other provisions of these Terms of Use, LINQ is found to
          be liable to you for any damage or loss which arises out of or is in any way connected with your use of the
          Site or any Content, LINQ’s liability shall in no event exceed the greater of (1) the total of any
          subscription or similar fees with respect to any service or feature of or on the Site paid in the six months
          prior to the date of the initial claim made against LINQ or (2) US$100.00.
        </p>
        <p>
          <strong>Indemnity</strong>
        </p>
        You agree to indemnify and hold LINQ, its officers, directors, shareholders, predecessors, successors in
        interest, employees, agents, subsidiaries and affiliates, harmless from any demands, loss, liability, claims or
        expenses (including attorneys’ fees), made against LINQ by any third party due to or arising out of or in
        connection with your use of the Site.
        <p>
          <strong>Violation of These Terms of Use</strong>
        </p>
        <p>
          LINQ may disclose any information we have about you (including your identity) if we determine that such
          disclosure is necessary in connection with any investigation or complaint regarding your use of the Site, or
          to identify, contact or bring legal action against someone who may be causing injury to or interference with
          (either intentionally or unintentionally) LINQ’s rights or property, or the rights or property of visitors to
          or users of the Site, including LINQ’s customers. LINQ reserves the right at all times to disclose any
          information that LINQ deems necessary to comply with any applicable law, regulation, legal process or
          governmental request. LINQ also may disclose your information when LINQ determines that applicable law
          requires or permits such disclosure, including exchanging information with other companies and organizations
          for fraud protection purposes.
        </p>
        <p>
          You acknowledge and agree that LINQ may preserve any transmittal or communication by you with LINQ through the
          Site or any service offered on or through the Site, and may also disclose such data if required to do so by
          law or LINQ determines that such preservation or disclosure is reasonably necessary to (1) comply with legal
          process, (2) enforce these Terms of Use, (3) respond to claims that any such data violates the rights of
          others, or (4) protect the rights, property or personal safety of LINQ, its employees, users of or visitors to
          the Site, and the public.
        </p>
        <p>
          You agree that LINQ may, in its sole discretion and without prior notice, terminate your access to the Site
          and/or block your future access to the Site if we determine that you have violated these Terms of Use or other
          agreements or guidelines which may be associated with your use of the Site. You also agree that any violation
          by you of these Terms of Use will constitute an unlawful and unfair business practice, and will cause
          irreparable harm to LINQ, for which monetary damages would be inadequate, and you consent to LINQ obtaining
          any injunctive or equitable relief that LINQ deems necessary or appropriate in such circumstances. These
          remedies are in addition to any other remedies LINQ may have at law or in equity.
        </p>
        <p>
          You agree that LINQ may, in its sole discretion and without prior notice, terminate your access to the Site,
          for cause, which includes (but is not limited to) (1) requests by law enforcement or other government
          agencies, (2) a request by you (self-initiated account deletions), (3) discontinuance or material modification
          of the Site or any service offered on or through the Site, or (4) unexpected technical issues or problems.
        </p>
        <p>
          If LINQ does take any legal action against you as a result of your violation of these Terms of Use, LINQ will
          be entitled to recover from you, and you agree to pay, all reasonable attorneys’ fees and costs of such
          action, in addition to any other relief granted to LINQ. You agree that LINQ will not be liable to you or to
          any third party for termination of your access to the Site as a result of any violation of these Terms of Use.
        </p>
        <p>
          <strong>Governing Law; Dispute Resolution</strong>
        </p>
        <p>
          You agree that all matters relating to your access to or use of the Site, including all disputes, will be
          governed by the laws of the United States and by the laws of the State of North Carolina without regard to its
          conflicts of laws provisions. You agree to the personal jurisdiction by and venue in the state and federal
          courts in New Hanover County, North Carolina, and waive any objection to such jurisdiction or venue. The
          preceding provision regarding venue does not apply if you are a consumer based in the European Union. No
          recovery may be sought or received for damages other than out-of-pocket expenses, except that the prevailing
          party will be entitled to costs and attorneys’ fees. In the event of any controversy or dispute between LINQ
          and you arising out of or in connection with your use of the Site, the parties shall attempt, promptly and in
          good faith, to resolve any such dispute. If we are unable to resolve any such dispute within a reasonable time
          (not to exceed thirty (30) days), then either party may submit such controversy or dispute to mediation. If
          the dispute cannot be resolved through mediation, then the parties shall be free to pursue any right or remedy
          available to them under applicable law.
        </p>
      </Modal.Content>
      <Modal.Actions className="linq-dialog__actions">
        <Button color="primary" variant="contained" fullWidth={isMobile} onClick={handleClose} data-test-id={'closeBtn'}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
