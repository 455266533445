import { Reducer } from 'react';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';

const initialState: Interfaces.MenuItemModal = {
  menuItemId: '',
  productId: '',
  menuTypeId: '',
  showMenuItemModal: false,
  date: '',
  pickupLocationId: '',
  selectedFoodGroupOptions: {},
  orderItems: [],
  isExistingOrder: false,
};

const MenuItemModal: Reducer<Interfaces.MenuItemModal, Interfaces.Action> = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.MENU_ITEM_MODAL_SET_DATE:
      return {
        ...state,
        date: payload,
      };

    case Types.MENU_ITEM_MODAL_SET_MENU_ITEM_ID:
      return {
        ...state,
        menuItemId: payload,
      };

    case Types.MENU_ITEM_MODAL_SET_EXISTING_ORDER:
      return {
        ...state,
        isExistingOrder: payload,
      };

    case Types.MENU_ITEM_MODAL_SET_PRODUCT_ID:
      return {
        ...state,
        productId: payload,
      };

    case Types.MENU_ITEM_MODAL_SET_MENU_TYPE_ID:
      return {
        ...state,
        menuTypeId: payload,
      };

    case Types.MENU_ITEM_MODAL_SET_ORDER_ITEMS:
      return {
        ...state,
        orderItems: payload,
      };

    case Types.MENU_ITEM_MODAL_SET_SHOW_MENU_ITEM_MODAL:
      if (payload) {
        return {
          ...state,
          showMenuItemModal: payload,
        };
      } else {
        return {
          ...initialState,
        };
      }

    case Types.MENU_ITEM_MODAL_SET_SELECTED_FOOD_GROUP_OPTONS:
      return {
        ...state,
        selectedFoodGroupOptions: payload,
      };

    case Types.MENU_ITEM_MODAL_SET_PICKUP_LOCATION:
      return {
        ...state,
        pickupLocationId: payload,
      };

    default:
      return state;
  }
};

export default MenuItemModal;
