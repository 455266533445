import { withAuthenticationRequired } from '@auth0/auth0-react';
import { isAdminAuthorized, isAuthorized, TokenClaims } from 'Auth/User';
import RouteElement from 'Components/RouteElement/RouteElement';
import React from 'react';

export const ProtectedRouteElement = ({
  allowedRoles,
  component,
  adminSidebar,
  adminLevels,
  isPrintable,
  hideParentFooter,
  componentProps,
}: React.PropsWithChildren<any>) => {
  const Component = React.useMemo(
    () =>
      withAuthenticationRequired(component, {
        claimCheck: (user) => {
          // pass admin check if no admin levels are provided for route or user's admin level meets route requirement
          const adminLevelIsSufficient =
            !(adminLevels && adminLevels.length) || isAdminAuthorized(TokenClaims.getAdmin(user), adminLevels);

          return isAuthorized(TokenClaims.getRole(user), allowedRoles) && adminLevelIsSufficient;
        },
      }),
    [allowedRoles, component, adminLevels],
  );
  return (
    <>
      <RouteElement adminSidebar={adminSidebar} isPrintable={isPrintable} hideParentFooter={hideParentFooter}>
        <Component {...componentProps} />
      </RouteElement>
    </>
  );
};
