import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';
import { isMobile } from 'react-device-detect';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { TokenClaims } from 'Auth/User';
import * as _ from 'lodash';
import Button from 'Components/Button/Button';
import { NotificationDialog } from 'Components/Dialogs/NotificationDialog';
import { PageLoaderNoRedux } from 'Components/Loaders/Loaders';
import DragAndDropReactTable from 'Components/ReactTable/DragAndDropReactTable/DragAndDropReactTable';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import { DATE_FORMAT_USA, ToastConfig, ToastType, DRAG_ITEM_TYPES } from 'Constants/Constants';
import { getRoutePath, RouteId } from 'Constants/Routes';
import { sortIsoDateString } from 'Helpers/Helper';
import { Newsfeed } from 'Interfaces/Interfaces';
import { useNewsfeedManagementActions } from './state/actions';
import { useNewsfeeds } from './state/hooks';

import './NewsfeedManagement.less';

const NewsFeedManagement = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);
  const { loading: loadingNewsfeeds, data } = useNewsfeeds(districtId);
  const { delete: deleteAction, patchNewsfeeds: patchNewsfeedsAction } = useNewsfeedManagementActions();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [saveOrderLoading, setSaveOrderLoading] = React.useState<boolean>(false);
  const [newsfeeds, setNewsfeeds] = React.useState<Newsfeed[]>([]);
  const [deleteDialogState, setDeleteDialogState] = React.useState({ show: false, districtId: '', newsfeedId: '' });
  const tableDataRef = React.useRef<Newsfeed[] | null>(null);

  React.useEffect(() => {
    const sortedData = _.sortBy(data, 'sortIndex');
    setNewsfeeds(sortedData);
  }, [data]);

  const deleteNewsfeed = React.useCallback(
    async (districtId, id) => {
      setLoading(true);
      try {
        await deleteAction(districtId, id);
        setNewsfeeds((newsfeeds) => newsfeeds.filter((nf) => nf.id !== id));
        toast.success('Newsfeed has been deleted successfully.', ToastConfig[ToastType.Success]);
      } catch {
      } finally {
        setLoading(false);
      }
    },
    [deleteAction],
  );

  const saveNewsfeedOrder = async () => {
    if (tableDataRef.current) {
      const orderedNewsfeeds = tableDataRef.current;

      const indexedNewsfeeds = orderedNewsfeeds.map((newsfeed: Newsfeed, index: number) => {
        return {
          id: newsfeed.id,
          sortIndex: index,
          startDate: newsfeed.startDate,
          endDate: newsfeed.endDate,
          title: newsfeed.title,
        };
      });
      try {
        setSaveOrderLoading(true);
        await patchNewsfeedsAction(districtId, indexedNewsfeeds);
        toast.success('Newsfeed order saved successfully.', ToastConfig[ToastType.Success]);
      } catch {
      } finally {
        setSaveOrderLoading(false);
      }
    }
  };

  const columns = [
    {
      Header: 'Title',
      accessor: 'title',
      disableSortBy: true,
      width: isMobile ? 250 : 600,
    },
    {
      Header: 'Start Date',
      accessor: 'startDate',
      disableSortBy: true,
      width: isMobile ? 50 : 250,
      sortType: (rowA: any, rowB: any) => sortIsoDateString(rowA.original.startDate, rowB.original.startDate),
      Cell: (data: any) => {
        return data.row.original.startDate ? moment(data.row.original.startDate).format(DATE_FORMAT_USA) : '';
      },
    },
    {
      Header: 'End Date',
      accessor: 'endDate',
      disableSortBy: true,
      width: isMobile ? 50 : 250,
      sortType: (rowA: any, rowB: any) => sortIsoDateString(rowA.original.endDate, rowB.original.endDate),
      Cell: (data: any) => {
        return data.row.original.endDate ? moment(data.row.original.endDate).format(DATE_FORMAT_USA) : '';
      },
    },
    {
      Header: '',
      accessor: 'id',
      width: 10,
      disableSortBy: true,
      Cell: (data: any) => {
        return (
          <>
            <IconButton
              aria-label="Edit newsfeed"
              onClick={() => navigate(`/newsfeed-management/edit/${data.row.original.id}`)}
              data-test-id={`${data.row.original.title}.EditBtn`}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="Delete newsfeed"
              onClick={() => setDeleteDialogState({ show: true, districtId, newsfeedId: data.row.original.id })}
              data-test-id={`${data.row.original.title}.DeleteBtn`}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <SubHeader
        title="Parent Newsfeed Configuration"
        rightComponent={
          <>
            <Button
              variant="text"
              color="primary"
              aria-label="Save newsfeed order"
              loading={saveOrderLoading}
              onClick={saveNewsfeedOrder}
              data-test-id="saveNewsfeedOrderBtn"
            >
              SAVE NEWSFEED ORDER
            </Button>
            <Button
              variant="text"
              color="primary"
              aria-label="Add parent newsfeed"
              onClick={() => {
                navigate(getRoutePath(RouteId.EDIT_NEWS_FEED).replace(':id', ''));
              }}
              data-test-id="addParentNewsfeedBtn"
            >
              + ADD PARENT NEWSFEED
            </Button>
          </>
        }
      />
      <PageLoaderNoRedux loading={loadingNewsfeeds || loading} />
      <DragAndDropReactTable
        className="newsfeed-table"
        columns={columns}
        data={newsfeeds}
        showFooter={true}
        dataRef={tableDataRef}
        dragItemType={DRAG_ITEM_TYPES.NEWSFEED_ROW}
      />
      <NotificationDialog
        title="Delete Newsfeed"
        message="Are you sure you want to delete this newsfeed?"
        showDialog={deleteDialogState.show}
        showCancel={true}
        cancelButtonText="CANCEL"
        okButtonText="DELETE"
        onOkClick={() => {
          deleteNewsfeed(deleteDialogState.districtId, deleteDialogState.newsfeedId);
          setDeleteDialogState({ show: false, districtId: '', newsfeedId: '' });
        }}
        onCancelClick={() => {
          setDeleteDialogState({ show: false, districtId: '', newsfeedId: '' });
        }}
        dialogType="delete"
        loading={loading}
      />
    </React.Fragment>
  );
};

export default NewsFeedManagement;
