import React from 'react';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import classNames from 'classnames';
import * as Constants from 'Constants/Constants';
import { PickupByDateItem } from './PickupByDateItem';

export const PickupByDate = (props: any) => {
  const { date, mealsByType, pickupLocations, existingLocations, options } = props;
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const m = moment(date);
  return (
    <div key={m.format('X')} className="day">
      <div className="title">{m.format(Constants.DATE_FORMAT_DMDDY)}</div>
      <div className={classNames({ dayContents: true, mobile: isMobile })}>
        {Object.keys(mealsByType).map((mealType: any) => {
          const items = mealsByType[mealType];

          return (
            <div>
              {mealType !== 'undefined' && <label>{mealType}</label>}
              <PickupByDateItem
                items={items}
                pickupLocations={pickupLocations}
                existingLocations={existingLocations}
                key={mealType}
                mealType={mealType}
                options={options}
              />
            </div>
          );
        })}
      </div>
      <hr />
    </div>
  );
};
