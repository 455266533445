import { OrderItem } from 'Interfaces/Interfaces';
import * as Types from './types';

export const setOpen = (open: boolean, orderItems: OrderItem[]) => ({
  type: Types.CANCEL_ORDER_MODAL_OPEN,
  payload: {
    open,
    orderItems
  },
});
