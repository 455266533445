import { Reducer } from 'redux';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';

const initialState: Interfaces.SitePickerState = {
  siteId: null,
};

const SitePickerReducer: Reducer<Interfaces.SitePickerState, Interfaces.Action> = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SITE_PICKER_SET_SITE:
      return {
        ...state,
        siteId: payload,
      };
    default:
      return state;
  }
};

export default SitePickerReducer;
