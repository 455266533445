import { Logger, LoggerLevels } from '@linq/logger';

export const globalConfigUrl = 'app-config.json';

export interface AppConfig {
  env: string;
  logLevel: string;
  frKey: string;
  audience: string;
  baseUrl: string;
  domain: string;
  family: {
    clientId: string;
    redirect: string;
  };
  portal: {
    clientId: string;
    redirect: string;
  };
  version?: string;
}

const defaultConfig = {
  env: 'local',
  logLevel: 'debug',
  frKey: 'SDB17hB8B6F4G4A4F3gKTRe2CG1PGe1DESAe1Kg1EBC1Pe2TKoF4I4B3A9A3B5A4B2A3F3==',
  audience: 'https://dev.api.onlineordering.linq.com',
  baseUrl: 'https://dev.api.onlineordering.linq.com/core',
  domain: 'dev.accounts.linq.com',
  family: {
    clientId: 'JW7MHLa7lTe1bJTuuIbf3LAuLgDKJToX',
    redirect: 'http://localhost:3000',
  },
  portal: {
    clientId: 'RNbeflGgEc09qJVJzccMKMMH9anfyCon',
    redirect: 'http://localhost:3000',
  },
};
class GlobalAppConfig {
  private config: AppConfig = defaultConfig;

  setConfig(config: AppConfig) {
    this.config = config;
    Logger.setLogLevel(this.config.logLevel as LoggerLevels);
  }

  getPortalConfig() {
    const app = window.location.origin.includes('family.onlineordering.linq.com')
      ? this.config.family
      : this.config.portal;
    return {
      clientId: app.clientId,
      redirect: app.redirect,
      env: this.config.env,
      domain: this.config.domain,
      baseUrl: this.config.baseUrl ?? this.config.audience,
      audience: this.config.audience,
      scope: 'read:api write:api delete:api',
      version: this.config.version,
      frKey: this.config.frKey,
    };
  }

  getPortalDomain() {
    const app = this.config.portal;
    return app.redirect;
  }
}

export const globalConfig = new GlobalAppConfig();
