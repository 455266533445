import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState, OrderingRules } from 'Interfaces/Interfaces';
import Card from 'Components/Card/Card';
import * as CommonStateActions from 'State/actions';
import * as CommonStateSelectors from 'State/selectors';
import { SidebarLocations } from 'Constants/Enums';
import MenuOrderRuleSidebar from '../Sidebars/MenuOrderRuleSidebar';

export const RuleMenuOrder: FC<{ orderingRules: OrderingRules }> = ({ orderingRules }) => {
  const sidebarOpen = useSelector((state: AppState) =>
    CommonStateSelectors.getSidebarOpen(state, SidebarLocations.MENU_ORDER_RULE_SIDE_BAR),
  );
  const dispatch = useDispatch();

  const setSidebarOpen = (isOpen: boolean) => {
    dispatch(CommonStateActions.setSidebarOpen(isOpen, SidebarLocations.MENU_ORDER_RULE_SIDE_BAR));
  };

  return (
    <>
      <Card
        title="Menu Display Order"
        description="Set menu display order"
        onEditClick={() => setSidebarOpen(true)}
        editAriaLabel="Set menu display order"
      ></Card>
      <MenuOrderRuleSidebar open={sidebarOpen} setSidebarOpen={setSidebarOpen} orderingRules={orderingRules} />
    </>
  );
};
