import { useDispatch, useSelector } from 'react-redux';
import { USD_FORMAT } from 'Constants/Constants';
import { AppState, OrderingRules } from 'Interfaces/Interfaces';
import * as CommonStateSelectors from 'State/selectors';
import * as CommonStateActions from 'State/actions';
import numeral from 'numeral';
import React, { FC } from 'react';
import Card from 'Components/Card/Card';
import ALaCarteRulesSidebar from '../Sidebars/ALaCarteRulesSidebar';
import { SidebarLocations } from 'Constants/Enums';

export const RuleALaCarte: FC<{ orderingRules: OrderingRules }> = ({ orderingRules = {} }) => {
  const sidebarOpen = useSelector((state: AppState) =>
    CommonStateSelectors.getSidebarOpen(state, SidebarLocations.A_LA_CARTE_RULES_SIDE_BAR),
  );
  const dispatch = useDispatch();

  const setSidebarOpen = (isOpen: boolean) => {
    dispatch(CommonStateActions.setSidebarOpen(isOpen, SidebarLocations.A_LA_CARTE_RULES_SIDE_BAR));
  };

  return (
    <>
      <Card
        title="A la Carte"
        description="Set rules for ordering a la carte"
        onEditClick={() => setSidebarOpen(true)}
        editAriaLabel="Edit A la Carte Rules"
      >
        <div className="ordering-rules__rules">
          <div className="ordering-rules__setting">
            <h6 className="linq-card__h6">Minimum balance</h6>
            <div className="setting-value" data-test-id={'aLaCarteMinimumBalanceLbl'}>
              {!orderingRules.aLaCarteMinimum ? '-' : numeral(orderingRules.aLaCarteMinimum).format(USD_FORMAT)}
            </div>
          </div>
          <div className="ordering-rules__setting">
            <h6 className="linq-card__h6">Start Date</h6>
            <div className="setting-value" data-test-id={'aLaCarteStartDateLbl'}>
              {!orderingRules.aLaCarteStartDate ? '-' : orderingRules.aLaCarteStartDate}
            </div>
          </div>
          <div className="ordering-rules__setting">
            <h6 className="linq-card__h6">End Date</h6>
            <div className="setting-value" data-test-id={'aLaCarteEndDateLbl'}>
              {!orderingRules.aLaCarteEndDate ? '-' : orderingRules.aLaCarteEndDate}
            </div>
          </div>
          <div className="ordering-rules__setting">
            <h6 className="linq-card__h6">Offer A La Carte Without Published Menus</h6>
            <div className="setting-value" data-test-id={'aLaCarteWithoutPublishedLbl'}>
              {orderingRules.offerOnlyALaCarteNoPublishedMenus ? 'Offered' : 'Not Offered'}
            </div>
          </div>
        </div>
      </Card>
      <ALaCarteRulesSidebar open={sidebarOpen} setSidebarOpen={setSidebarOpen} orderingRules={orderingRules} />
    </>
  );
};
