import { ActionCreator } from 'redux';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';
import moment from 'moment';

export const setWeekOfYear: ActionCreator<Interfaces.Action> = (payload: number) => ({
  type: Types.DATE_RANGE_NAVIGATOR_SET_WEEK_OF_YEAR,
  payload,
});

export const setDayOfYear: ActionCreator<Interfaces.Action> = (payload: moment.Moment) => ({
  type: Types.DATE_RANGE_NAVIGATOR_SET_DAY,
  payload,
});

export const setStartDate: ActionCreator<Interfaces.Action> = (payload: moment.Moment) => ({
  type: Types.DATE_RANGE_NAVIGATOR_SET_START_DATE,
  payload,
});

export const setDateRange: ActionCreator<Interfaces.Action> = (payload: Interfaces.DayRange) => ({
  type: Types.DATE_RANGE_NAVIGATOR_SET_DATE_RANGE,
  payload,
});
