import React, { useRef } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ButtonCore, { ButtonProps as ButtonCoreProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

import './Button.less';

export interface ButtonCustomProps {
  loading?: boolean;
}

export type ButtonProps = ButtonCustomProps & ButtonCoreProps;

interface ButtonStyleProps {
  loading: boolean;
  originalHeight: number;
  originalWidth: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // Set the min-width of the button if in loading state to avoid jumpy UI
      minWidth: (props: Partial<ButtonStyleProps>) =>
        props.loading ? (props.originalWidth ? `${props.originalWidth}px` : '150px') : 'auto',
      minHeight: (props: Partial<ButtonStyleProps>) =>
        props.loading ? (props.originalHeight ? `${props.originalHeight}px` : 'auto') : 'auto',
    },
  }),
);

const Button: React.FC<ButtonProps> = ({ loading, children, disabled, ...props }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  let originalHeight = undefined;
  let originalWidth = undefined;

  if (buttonRef.current) {
    originalHeight = buttonRef.current.offsetHeight;
    originalWidth = buttonRef.current.offsetWidth;
  }

  const classes = useStyles({ loading, originalWidth, originalHeight });
  const combinedClassNames = classNames(classes.root, disabled && 'btn-disabled');
  return (
    <ButtonCore ref={buttonRef} className={combinedClassNames} disabled={disabled} {...props}>
      {loading ? <CircularProgress size={20} /> : children}
    </ButtonCore>
  );
};

export default Button;
