import { combineReducers, Reducer } from 'redux';

import commonState from 'State/reducer';

import shopState from 'Pages/Shop/state/reducer';

import adminState from 'Components/Admin/state/reducer';
import addStudentModalState from 'Components/Student/AddStudentModal/state/reducer';
import alaCarteState from 'Pages/ALaCarte/state/reducer';
import switchStudentCartModalState from 'Components/Student/SwitchStudentCartModal/state/reducer';
import unlinkStudentModalState from 'Components/Student/UnlinkStudentModal/state/reducer';
import cartState from 'Components/Shop/Cart/state/reducer';
import disclaimerModalState from 'Components/Shop/DisclaimerModal/state/reducer';
import districtState from 'Components/District/state/reducer';
import districtReportsState from 'Pages/DistrictReports/state/reducer';
import headerState from 'Components/Header/state/reducer';
import loadersState from 'Components/Loaders/state/reducer';
import parentState from 'Pages/ParentDashboard/state/reducer';
import menuTypePickerState from 'Components/MenuTypePicker/state/reducer';
import menuItemModalState from 'Components/Shop/MenuItemModal/state/reducer';
import cancelOrderModalState from 'Components/Shop/CancelOrderModal/state/reducer';
import teacherReportState from 'Pages/TeacherRosterOrdering/state/reducer';
import dateRangeNavigatorState from 'Components/DateRangeNavigator/state/reducer';
import sitePickerState from 'Components/SitePicker/state/reducer';
import transactionHistoryState from 'Pages/TransactionHistory/state/reducer';

export default combineReducers({
  adminState,
  addStudentModalState,
  alaCarteState,
  switchStudentCartModalState,
  unlinkStudentModalState,
  cartState,
  commonState,
  disclaimerModalState,
  districtState,
  districtReportsState,
  headerState,
  loadersState,
  parentState,
  menuTypePickerState,
  menuItemModalState,
  cancelOrderModalState,
  shopState,
  teacherReportState,
  dateRangeNavigatorState,
  sitePickerState,
  transactionHistoryState,
}) as Reducer;
