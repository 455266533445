import React from 'react';
import zxcvbn from 'zxcvbn';
import { LinearProgress } from '@material-ui/core';

import './PasswordStrengthMeter.less';

const getStrengthColorClass = (strength: number) => {
  let colorClass = 'red';
  if (strength === 40) {
    colorClass = 'orange-red';
  }
  if (strength === 60) {
    colorClass = 'orange';
  }
  if (strength === 80) {
    colorClass = 'yellow-green';
  }
  if (strength > 80) {
    colorClass = 'green';
  }

  return colorClass;
};

interface PasswordStrengthMeterProps {
  password?: string;
}

export const PasswordStrengthMeter = ({ password }: PasswordStrengthMeterProps) => {
  let score = 0;

  if (password) {
    const zxcvbnResult = zxcvbn(password ?? '');

    score = password ? (zxcvbnResult.score + 1) * 20 : 0;
  }

  return <LinearProgress variant="determinate" value={score} className={getStrengthColorClass(score)} />;
};
