import React from 'react';
import Button from 'Components/Button/Button';
import { Warning } from '@material-ui/icons';
import { ERROR_TYPE } from 'Constants/Constants';
import { Grid, Card, CardContent, CardActions } from '@material-ui/core';

import './ErrorPage.less';

const Errors = {
  [ERROR_TYPE.STANDARD]: {
    title: 'Whoa! Something has gone wrong!',
    message: 'We apologize for the inconvenience. If the problem continues please contact support.',
  },
  [ERROR_TYPE.PERMISSION]: {
    title: 'Permission Denied!',
    message: 'You are trying to access a page without the correct permissions.',
  },
  [ERROR_TYPE.FAIL]: {
    title: 'Ooooops! We messed up!',
    message:
      'Something has gone wrong with our system. We apologize for the inconvenience. Please try again. If the problem continues please contact support.',
  },
  [ERROR_TYPE.UNKNOWN]: {
    title: '404! Page not found!',
    message: 'You are trying to access an unknown page.',
  },
  [ERROR_TYPE.SFTP]: {
    title: 'Permission Denied!',
    message: 'You are an SFTP Only user. You do not have access to Online Ordering functionality.',
  },
  [ERROR_TYPE.ORDER_SUBMISSION]: {
    title: 'Error Submitting Order!',
    message: 'We apologize for the inconvenience. If the problem continues please contact support.',
  },
  [ERROR_TYPE.ORDER_STUDENT_SUSPENDED]: {
    title: 'Error Submitting Order!',
    message: 'The student is unable to place orders. Please contact Nutrition Services Dept.',
  },
  [ERROR_TYPE.EDIT_NEWSFEED_NOT_FOUND]: {
    title: 'Newsfeed not found',
    message: 'The requested newsfeed was not found.',
  },
  [ERROR_TYPE.SITE_NOT_ENABLED_FOR_ORDERING]: {
    title: 'Site Not Enabled!',
    message: "The district has not enabled ordering for this student's school site.",
  },
} as { [key in ERROR_TYPE]: { title: string; message: string } };

const ErrorPage: React.FC<{ errorType?: ERROR_TYPE; secondaryButton?: any; showContactSupport?: boolean }> = ({
  errorType = ERROR_TYPE.STANDARD,
  secondaryButton,
  showContactSupport,
}) => {
  const handleContactSupport = () => {
    // @ts-ignore
    window.open('https://www.linq.com/contact/', '_blank').focus();
  };

  return (
    <section className="oo-error-page">
      <Card className="oo-error-page__card">
        <CardContent>
          <h2 className="oo-error-page__header">
            <Warning className="warning" name="warning" />
            {Errors[errorType].title}
          </h2>
          {Errors[errorType].message}
        </CardContent>
        {(showContactSupport || secondaryButton || true) && (
          <CardActions>
            <Grid container={true}>
              {showContactSupport && (
                <Grid container xs={12}>
                  <Button variant="contained" color="primary" onClick={handleContactSupport}>
                    Contact Support
                  </Button>
                </Grid>
              )}
              <Grid container xs={12}>
                {secondaryButton ? secondaryButton : <div></div>}
              </Grid>
            </Grid>
          </CardActions>
        )}
      </Card>
    </section>
  );
};

export default ErrorPage;
