import { useState } from 'react';

import { useApi } from 'Api/useApi';
import { AxiosResponse } from 'axios';
import { Newsfeed } from 'Interfaces/Interfaces';

export const useNewsfeedManagementActions = () => {
  const api = useApi();

  const [actions] = useState({
    delete: async (districtId: string, id: string): Promise<AxiosResponse<void>> => {
      return api.delete(`/districts/${districtId}/newsfeeds/${id}`);
    },
    patchNewsfeeds: async (districtId: string, newsfeeds: Partial<Newsfeed>[]): Promise<AxiosResponse> => {
      return api.patch(`/districts/${districtId}/newsfeeds`, newsfeeds);
    },
  });

  return actions;
};
