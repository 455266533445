import { get } from 'lodash';
import { createSelector } from 'reselect';
import { groupBy, uniqBy } from 'lodash';

import * as CommonSelectors from 'State/selectors';
import * as MenuTypePickerSelectors from 'Components/MenuTypePicker/state/selectors';

import { OrderItem, MenuItem } from 'Interfaces/Interfaces';

const root = (state: object) => get(state, 'menuItemModalState');

export const getDate = (state: object) => get(root(state), 'date');
export const getMenuItemId = (state: object) => get(root(state), 'menuItemId');
export const isExistingOrder = (state: object) => get(root(state), 'isExistingOrder');
export const getProductId = (state: object) => get(root(state), 'productId');
export const getMenuTypeId = (state: object) => get(root(state), 'menuTypeId');
export const getOrderItems = (state: object): OrderItem[] => get(root(state), 'orderItems');
export const getShowMenuItemModal = (state: object) => get(root(state), 'showMenuItemModal');

export const getPickupLocationId = (state: object) => get(root(state), 'pickupLocationId');

export const getMenuItemsByDateGrouped = createSelector(
  [getDate, CommonSelectors.getMenuItems, CommonSelectors.getProducts, MenuTypePickerSelectors.getMenuTypeId],
  (date, menuItems, products, menuTypeId) => {
    const currentMenuItems = Object.values({ ...menuItems })
      .filter((menuItem) => menuItem.date === date && menuItem.mealTypeId === menuTypeId)
      .map((menuItem) => {
        menuItem.product = products[menuItem.productId];
        return menuItem;
      });
    return groupBy(uniqBy(currentMenuItems, 'productId'), 'product.productCategoryId');
  },
);

const getItemsByCategory = createSelector(getMenuItemsByDateGrouped, (optionsByCategory) => {
  return Object.entries(optionsByCategory).reduce((acc: any, [key, options]) => {
    acc[key] = options.reduce((optionsMap: any, option) => {
      optionsMap[option.productId] = option;
      return optionsMap;
    }, {});
    return acc;
  }, {});
});

export const getSelectedFoodGroupOptions = createSelector([root, getItemsByCategory], (state, itemsByCategory) => {
  // TODO: remove once API is updated to match data structure
  // Fallback for string based grouped vs string[]
  const temp = get(state, 'selectedFoodGroupOptions');

  if (!temp && !Object.keys(temp).length) {
    return {};
  }

  const selectedOptions = Object.entries(temp).reduce(
    (data: { [key: string]: MenuItem[] }, [productCategoryId, selectedOptions]: [string, any]) => {
      data[productCategoryId] = selectedOptions.reduce((acc: MenuItem[], option: string | MenuItem) => {
        if (typeof option === 'string') {
          const items = itemsByCategory[productCategoryId];
          const menuItem = items && items[option];

          if (menuItem) {
            acc.push(menuItem);
          }
        } else {
          acc.push(option);
        }
        return acc;
      }, []);
      return data;
    },
    {},
  );
  return selectedOptions;
});
