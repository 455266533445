import {
  FOOD_GROUP_TYPES,
  FOOD_GROUPS_OPTIONAL,
  FOOD_GROUPS_MULTIPLE_ALLOWED,
  ALACARTE_KEY,
  FOOD_GROUPS,
} from 'Constants/Constants';
import { Product } from 'Interfaces/Interfaces';

export type Selected = { [key in FOOD_GROUP_TYPES]?: string[] };

/**
 * When a user adds an entree to their cart, they should only be required to select one option under either fruit or vegetable
 * A user should be able to select both a fruit and a vegetable
 * A user should be able to select multiple fruits and no vegetables
 * A user should be able to select multiple vegetables and no fruit
 * A user should be able to select one or more fruits and one or more vegetables
 * Grain is optional
 * Milk is optional
 *
 * @param selected Food groups selected by the user.
 * @param groups Food groups required for the menu item.
 */
export const canSubmitForm = (
  product: Product,
  selected: Selected,
  groups: FOOD_GROUP_TYPES[],
  debug: boolean = false,
): boolean => {
  // Allow submitting for A la Carte products
  if (product?.productCategoryId === ALACARTE_KEY) {
    return true;
  }

  // First let's validate all the required arguments contain length
  if (!groups || !Object.keys(groups).length) {
    return true;
  }

  const selectedKeys = Object.entries(selected).reduce((acc, [key, item]) => {
    if (item?.length) {
      acc?.push(key as FOOD_GROUP_TYPES);
    }
    return acc;
  }, [] as FOOD_GROUP_TYPES[]);

  return groups.every((group) => {
    // multiple selection (fruit, vegetable, condiments)
    if (
      FOOD_GROUPS_MULTIPLE_ALLOWED.includes(group) &&
      selectedKeys.some(
        (selectedGroup) =>
          (selectedGroup === FOOD_GROUPS.FRUIT.id || selectedGroup === FOOD_GROUPS.VEGETABLE.id) &&
          FOOD_GROUPS_MULTIPLE_ALLOWED.includes(selectedGroup),
      )
    ) {
      return true;
    }

    if (FOOD_GROUPS_OPTIONAL.includes(group)) {
      return true;
    }

    return false;
  });
};
