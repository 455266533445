import { DialogTitle, FormControl } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import classNames from 'classnames';
import Button from 'Components/Button/Button';
import { AutocompleteDropdown, InputField } from 'Components/Form/MaterialForm';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import Modal from 'Components/Modal/Modal';
import * as Constants from 'Constants/Constants';
import { useFormik } from 'formik';
import * as Interfaces from 'Interfaces/Interfaces';
import { sortBy } from 'lodash';
import React, { ReactElement, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useCommonActions } from 'State/actions';
import * as CommonHooks from 'State/hooks';
import * as CommonSelectors from 'State/selectors';
import * as yup from 'yup';
import './AddStudentModal.less';
import * as AddStudentModalActions from './state/actions';
import * as AddStudentModalSelectors from './state/selectors';

const AddStudentModal = (): ReactElement => {
  const dispatch = useDispatch();
  const districts = useSelector(CommonSelectors.getDistricts);
  const isOpen = useSelector(AddStudentModalSelectors.getOpen);
  const errorMessage = useSelector(AddStudentModalSelectors.getError);
  const [submitting, setSubmit] = useState(false);
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const { attachStudent } = useCommonActions();

  CommonHooks.useDistricts();

  const formik = useFormik({
    initialValues: { districtId: '', sisId: '', lastName: '' },
    onSubmit: async (values) => {
      if (!submitting) {
        setSubmit(true);
        const student: Interfaces.CreateStudent = {
          sisId: values.sisId,
          lastName: values.lastName,
        };
        await attachStudent(student, values.districtId);
        setSubmit(false);
        handleToggleModal(false);
      }
    },
    validationSchema: yup.object().shape({
      districtId: yup.string().required('Required Field'),
      sisId: yup.string().required('Required Field'),
      lastName: yup.string().required('Required Field'),
    }),
  });

  const handleToggleModal = (open: boolean) => {
    if (submitting) return;

    if (!isOpen) {
      dispatch(AddStudentModalActions.setError(''));
      formik.resetForm();
    }
    dispatch(AddStudentModalActions.setOpen(open));
  };

  const districtOptions = useMemo(() => {
    return sortBy(Object.values(districts), 'name').map((district) => ({
      text: district.name,
      value: district.id,
    }));
  }, [districts]);

  return (
    <React.Fragment>
      <button
        className={classNames('linq-card', {
          'add-student-container': !isMobile,
          'add-student-container-mobile': isMobile,
        })}
        onClick={() => handleToggleModal(true)}
        data-test-id="addStudentBtn"
      >
        <AddCircleIcon className="add-student-modal__plus" />
        <div>ADD A STUDENT OR ADULT</div>
      </button>
      <Modal
        id="modal-add-student"
        className="linq-dialog"
        open={isOpen}
        maxWidth="xs"
        onClose={() => handleToggleModal(false)}
      >
        <form className="add-student-modal-container" onSubmit={formik.handleSubmit}>
          <DialogTitle className="linq-dialog__header d-flex align-items-center" disableTypography={true}>
            <h3 className="linq-heading">Add a Student or Adult</h3>
            <CloseIconButton onClick={() => handleToggleModal(false)} data-test-id="closeBtn" />
          </DialogTitle>
          <Modal.Content className="linq-dialog__content">
            <div className="add-student-directions">
              Input Unique ID Number to add your student or adult to your account. The student ID number can be found on
              your child's schedule or report card. If you are unable to obtain this number, please contact your School
              District.
            </div>
            <div className="error-message">{errorMessage}</div>
            <FormControl fullWidth>
              <AutocompleteDropdown
                id="districtId"
                label="School District"
                value={formik.values.districtId}
                options={districtOptions}
                onChange={(e, selectedOption) => {
                  formik.setFieldValue('districtId', selectedOption?.value);
                }}
                errorMsg={formik.errors.districtId}
                touched={formik.touched.districtId}
                showOnlyTouchedError={true}
                hideEmptyError={false}
                disableClearable={true}
                data-test-id="schoolDistrictDropDown"
              />
            </FormControl>
            <FormControl fullWidth>
              <InputField
                onChange={formik.handleChange}
                id="sisId"
                name="sisId"
                label="Student or Staff ID"
                value={formik.values.sisId}
                errorMsg={formik.errors.sisId}
                touched={formik.touched.sisId}
                showOnlyTouchedError={true}
                hideEmptyError={false}
                inputProps={{
                  'data-test-id': 'studentIdInput',
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputField
                onChange={formik.handleChange}
                id="lastName"
                name="lastName"
                label="Last Name"
                value={formik.values.lastName}
                errorMsg={formik.errors.lastName}
                touched={formik.touched.lastName}
                showOnlyTouchedError={true}
                hideEmptyError={false}
                inputProps={{
                  'data-test-id': 'lastNameInput',
                }}
              />
            </FormControl>
          </Modal.Content>
          <Modal.Actions className="linq-dialog__actions">
            <Button
              color="primary"
              variant="outlined"
              onClick={() => handleToggleModal(false)}
              type="button"
              data-test-id="cancelBtn"
            >
              CANCEL
            </Button>
            <Button
              color="primary"
              variant="contained"
              loading={submitting}
              disabled={submitting}
              type="submit"
              data-test-id="addBtn"
            >
              ADD
            </Button>
          </Modal.Actions>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default AddStudentModal;
