import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { MOBILE_MEDIA_QUERY } from 'Constants/Constants';
import { updatePickupLocations } from 'Components/Shop/Cart/state/actions';

export const PickupByOrder = ({ items, existingLocations, options }: any) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY });
  let existingLocation = '';

  const onSelectPickupLocation = useCallback(
    (pickupLocation) => {
      Object.values(items).forEach((item: any) => {
        item.forEach((menu: any) => {
          dispatch(updatePickupLocations(menu.id, pickupLocation.target.value));
        });
      });
    },
    [dispatch, items],
  );

  const firstDay = items[Object.keys(items)[0]];

  if (firstDay) {
    const firstMeal = firstDay[Object.keys(firstDay)[0]];

    if (existingLocations[firstMeal.id]) {
      existingLocation = existingLocations[firstMeal.id];
    }
  }

  return (
    <div className={classNames({ 'single-item': true, mobile: isMobile })} key="all">
      <FormControl component="fieldset">
        <legend aria-label="List of Pickup Locations" aria-hidden="true" className="sr-only" />
        <RadioGroup
          aria-label="Choose a pickup location for your order"
          name="pickuplocation"
          value={existingLocation}
          onChange={onSelectPickupLocation}
        >
          {options.map((option: any) => (
            <FormControlLabel value={option.value} control={<Radio />} label={option.text} key={option.value} />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};
