import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import { Student, District, Site } from 'Interfaces/Interfaces';
import StudentCard from 'Components/Student/StudentCard/StudentCard';
import AddStudentModal from 'Components/Student/AddStudentModal/AddStudentModal';
import ParentNewsfeedCard from 'Components/ParentNewsfeedCard/ParentNewsfeedCard';
import SwitchStudentCartModal from '../SwitchStudentCartModal/SwitchStudentCartModal';
import UnlinkStudentModal from '../UnlinkStudentModal/UnlinkStudentModal';

import * as Constants from 'Constants/Constants';

import './StudentCardList.less';

interface StudentListProps {
  students: { [key: string]: Student };
  districts: { [key: string]: District };
  sites: { [key: string]: Site };
  districtIds: string[];
}
const StudentCardList: FC<StudentListProps> = ({ students, districts, sites, districtIds }) => {
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });

  return (
    <>
      <div className={classNames({ 'student-card-list-container': true, mobile: isMobile })}>
        <ParentNewsfeedCard districts={districts} districtIds={districtIds} />
        {Object.values(students).map((student) => (
          <StudentCard
            student={student}
            key={student.id}
            district={districts[student.districtId]}
            site={sites[student.siteId]}
          />
        ))}
        <AddStudentModal />
        <SwitchStudentCartModal />
        <UnlinkStudentModal />
      </div>
    </>
  );
};

export default StudentCardList;
