import Button from 'Components/Button/Button';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import * as LoadersActions from 'Components/Loaders/state/actions';
import Modal from 'Components/Modal/Modal';
import * as UnlinkStudentModalActions from 'Components/Student/UnlinkStudentModal/state/actions';
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCommonActions } from 'State/actions';
import * as UnlinkStudentModalSelector from './state/selectors';
import './UnlinkStudentModal.less';

const UnlinkStudentModal = (): ReactElement => {
  const dispatch = useDispatch();
  const { unlinkStudent } = useCommonActions();

  const isOpen = useSelector(UnlinkStudentModalSelector.getOpen);
  const studentId = useSelector(UnlinkStudentModalSelector.getStudentId);

  const closeModal = () => {
    dispatch(UnlinkStudentModalActions.setOpen(false, null));
  };

  const onConfirmClick = async () => {
    closeModal();
    dispatch(LoadersActions.setPageLoaderActive(true));
    await unlinkStudent(studentId);
    dispatch(LoadersActions.setPageLoaderActive(false));
  };

  return (
    <Modal className="linq-dialog" open={isOpen} maxWidth="md" onClose={closeModal}>
      <Modal.Title className="linq-dialog__header d-flex align-items-center" disableTypography={true}>
        <h3 className="linq-heading">Remove Student</h3>
        <CloseIconButton onClick={closeModal} />
      </Modal.Title>
      <Modal.Content className="linq-dialog__content unlink-student-modal__content">
        Are you sure you want to remove this student from your account?
      </Modal.Content>
      <Modal.Actions className="linq-dialog__actions">
        <Button variant="outlined" color="primary" onClick={closeModal} aria-label="Click to cancel.">
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirmClick} aria-label="Click to confirm.">
          Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default UnlinkStudentModal;
