import React, { ReactElement, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import * as DateRangeNavigatorSelectors from 'Components/DateRangeNavigator/state/selectors';
import * as DateRangeNavigatorActions from 'Components/DateRangeNavigator/state/actions';
import * as Interfaces from 'Interfaces/Interfaces';
import * as Constants from 'Constants/Constants';

import './WeekDayPicker.less';

const WeekDayPicker = (): ReactElement => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDay] = useState<moment.Moment>(useSelector(DateRangeNavigatorSelectors.getDate));
  const dayRange: Interfaces.DayRange = useSelector(DateRangeNavigatorSelectors.getDayRangeNoWeekends);
  const [navPrevVisible, setnavPrevVisible] = useState(false);
  const [navNextVisible, setnavNextVisible] = useState(false);

  useEffect(() => {
    if (selectedDate.isSame(dayRange.startDate, 'day')) {
      setnavPrevVisible(false);
    } else {
      setnavPrevVisible(true);
    }

    if (selectedDate.isSame(dayRange.endDate, 'day')) {
      setnavNextVisible(false);
    } else {
      setnavNextVisible(true);
    }

    if (selectedDate.isBefore(dayRange.startDate, 'd') || selectedDate.isAfter(dayRange.endDate, 'd')) {
      setSelectedDay(dayRange.startDate);
    }
  }, [selectedDate, dayRange]);

  const handlePrevClick = useCallback(() => {
    const newDay = selectedDate.clone().subtract(1, 'd');
    if (newDay.isSameOrAfter(dayRange.startDate, 'd')) {
      dispatch(DateRangeNavigatorActions.setDayOfYear(newDay));
    }
  }, [dispatch, selectedDate, dayRange]);

  const handleNextClick = useCallback(() => {
    const newDay = selectedDate.clone().add(1, 'd');
    if (newDay.isSameOrBefore(dayRange.endDate, 'd')) {
      dispatch(DateRangeNavigatorActions.setDayOfYear(newDay));
    }
  }, [dispatch, selectedDate, dayRange]);

  return (
    <div className="weekday-picker-container">
      <button
        disabled={!navPrevVisible}
        className="btn-reset"
        onClick={handlePrevClick}
        aria-label="Select Previous Week"
      >
        <ChevronLeftIcon />
      </button>
      <span>{moment(selectedDate).format(Constants.DATE_FORMAT_DD)}</span>
      <button disabled={!navNextVisible} className="btn-reset" onClick={handleNextClick} aria-label="Select Next Week">
        <ChevronRightIcon />
      </button>
    </div>
  );
};

export default WeekDayPicker;
