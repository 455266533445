import { get } from 'lodash';
import { createSelector } from 'reselect';

import * as Interfaces from 'Interfaces/Interfaces';
import { getDistricts } from 'State/selectors';
import { sortMenuTypes } from 'Helpers/Helper';
import { useAuth0 } from '@auth0/auth0-react';
import { Role, TokenClaims } from 'Auth/User';

const root = (state: Interfaces.AppState) => get(state, 'menuTypePickerState');

export const getMenuTypeId = (state: Interfaces.AppState): string | null => get(root(state), 'menuTypeId');
export const getMenuTypes = (state: Interfaces.AppState): Record<string, Interfaces.MenuType> =>
  get(root(state), 'menuTypes');

export const getMenuTypeIds = createSelector(getMenuTypes, (menuTypes) => Object.keys(menuTypes));

export const getMenuTypeOptions = createSelector(getMenuTypes, (menuTypes) => {
  return Object.values(menuTypes || {})
    .sort((a, b) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    })
    .map((mealType: Interfaces.MenuType) => ({
      text: mealType.name,
      value: mealType.id,
    }));
});

export const getSortedMenuTypeOptions = createSelector(
  [
    getDistricts,
    getMenuTypes,
    (state: Interfaces.AppState) => state.shopState.districtId,
    (state: Interfaces.AppState) => state.shopState.siteId,
  ],
  (
    districts: { [key: string]: Interfaces.District },
    menuTypes: Record<string, Interfaces.MenuType>,
    studentDistrictId: string | null,
    studentSiteId: string | null,
  ) => {
    const { user } = useAuth0();
    const userDistrictId = TokenClaims.getDistrictId(user);
    const userSiteId = TokenClaims.getSiteId(user);
    const role = TokenClaims.getRole(user);

    let districtId = null;
    let siteId = null;

    // only apply menu display ordering rule if the role is teacher or parent
    if (role === Role.PARENT) {
      districtId = studentDistrictId;
      siteId = studentSiteId;
    } else if (role === Role.TEACHER) {
      districtId = userDistrictId;
      siteId = userSiteId;
    }

    let menuDisplayOrder: string[] = [];

    if (districtId && siteId) {
      menuDisplayOrder = districts[districtId]?.orderingRules?.siteMenuDisplayOrder?.[siteId] ?? [];
    }

    const sortedMenuTypeOptions = sortMenuTypes(menuTypes, menuDisplayOrder);

    return sortedMenuTypeOptions.map((menuType) => {
      return { text: menuType.name, value: menuType.id };
    });
  },
);

export const getDefaultMenuType = createSelector(getSortedMenuTypeOptions, (menuTypeOptions) => {
  const firstMenuOption = menuTypeOptions[0];
  return { id: firstMenuOption?.value ?? '', name: firstMenuOption?.text ?? '' };
});
