import React, { FC, useState } from 'react';
import { Formik, FormikProps, FormikValues, Field, FieldProps, Form } from 'formik';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'Components/Drawer';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import Button from 'Components/Button/Button';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';
import { useCommonActions } from 'State/actions';
import { PICKUP_LOCATION_RULES } from 'Constants/Constants';
import { SidebarLocations } from 'Constants/Enums';
import { OrderingRules } from 'Interfaces/Interfaces';

export interface PickupLocationsRulesSidebarProps {
  open: boolean;
  orderingRules: OrderingRules;
  setSidebarOpen: (open: boolean) => void;
}

const PickupLocationsRulesSidebar: FC<PickupLocationsRulesSidebarProps> = ({ open, orderingRules, setSidebarOpen }) => {
  const [loading, setLoading] = useState(false);
  const { updateDistrict } = useCommonActions();
  const initialValues = {
    pickupSelectionType: orderingRules?.pickupSelectionType || PICKUP_LOCATION_RULES['IndividualMeal'].id,
  };

  const handleSubmit = async (values: FormikValues) => {
    setLoading(true);
    await updateDistrict({ orderingRules: { ...orderingRules, ...values } });
    setLoading(false);
    setSidebarOpen(false);
  };

  return (
    <Drawer open={open} id={'online-ordering-card'} anchor="right" className="ordering-rules-sidebar-container">
      <Formik onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize>
        {({ handleReset }: FormikProps<{}>) => (
          <Form className="linq-drawer-form-content oo-drawer-form">
            <DrawerHeader>
              <h2>Edit Pickup Locations</h2>
              <CloseIconButton
                onClick={() => {
                  setSidebarOpen(false);
                  handleReset();
                }}
              />
            </DrawerHeader>
            <NotificationBanner location={SidebarLocations.PICKUP_LOCATIONS_RULES_SIDE_BAR} />
            <DrawerContent>
              <p>Please choose if you want pickup location by individual meals or grouped by order.</p>
              <Field name="pickupSelectionType">
                {({ field, form }: FieldProps) => (
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="Please choose if you want pickup location by individual meals or grouped by order"
                      name="pickupType"
                      value={field.value}
                      onChange={(e) => {
                        form.setFieldValue('pickupSelectionType', e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="IndividualMeal"
                        control={
                          <Radio aria-label="Pickup location by individual meal" data-test-id="byIndividualMealBtn" />
                        }
                        label="Pickup location by individual meal"
                      />
                      <FormControlLabel
                        value="ByOrder"
                        control={<Radio aria-label="Pickup location by order" data-test-id="byOrderBtn" />}
                        label="Pickup location by order"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              </Field>
            </DrawerContent>
            <DrawerFooter>
              <Button
                aria-label="Cancel"
                color="primary"
                variant="outlined"
                onClick={() => {
                  setSidebarOpen(false);
                  handleReset();
                }}
                data-test-id="cancelBtn"
              >
                Cancel
              </Button>
              <Button
                aria-label="Submit"
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading}
                loading={loading}
                data-test-id="submitBtn"
              >
                Save
              </Button>
            </DrawerFooter>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default PickupLocationsRulesSidebar;
