import { Reducer } from 'react';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';
import { CLOSE_ALL_SLIDEOUTS_AND_MODALS } from 'State/types';

const initialState: Interfaces.AdminState = {
  showSidebar: false,
};

const AdminReducer: Reducer<Interfaces.AdminState, Interfaces.Action> = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.ADMIN_SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: payload,
      };
    case CLOSE_ALL_SLIDEOUTS_AND_MODALS:
      return {
        ...state,
        showSidebar: payload === 'showSidebar',
      }

    default:
      return state;
  }
};

export default AdminReducer;
