import { Reducer } from 'redux';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';

const initialState: Interfaces.ParentState = {
  studentDistricts: {},
  studentSites: {},
};

const DistrictReportReducer: Reducer<Interfaces.ParentState, Interfaces.Action> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case Types.PARENT_DASHBOARD_SET_STUDENT_DISTRICTS:
      return {
        ...state,
        studentDistricts: payload,
      };
    case Types.PARENT_DASHBOARD_SET_STUDENT_SITES:
      return {
        ...state,
        studentSites: payload,
      };
    default:
      return state;
  }
};

export default DistrictReportReducer;
