import { useAuth0 } from '@auth0/auth0-react';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import classNames from 'classnames';
import { Role, TokenClaims } from 'Auth/User';
import * as DateRangeNavigatorActions from 'Components/DateRangeNavigator/state/actions';
import * as DateRangeNavigatorSelectors from 'Components/DateRangeNavigator/state/selectors';
import * as Constants from 'Constants/Constants';
import { createDayRange } from 'Helpers/Helper';
import moment from 'moment-timezone';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import './DateRangeNavigator.less';

interface DateRangeNavigatorProps {
  mode: Constants.DateRangeMode;
  skipWeekends?: boolean;
  datePicker?: boolean;
  defaultDayOfYearToWeekday?: boolean;
  customFromDate?: Date;
  customEndDate?: Date;
  monthOnly?: boolean;
  disablePast?: boolean;
}

export const getMomentUnit = (mode: Constants.DateRangeMode): moment.unitOfTime.Base => {
  let unit: moment.unitOfTime.Base = 'day';

  switch (mode) {
    case Constants.DateRangeMode.MONTH:
      unit = 'month';
      break;
    case Constants.DateRangeMode.WEEK:
      unit = 'week';
      break;
  }

  return unit;
};

const DateRangeNavigator: FC<DateRangeNavigatorProps> = (
  {
    mode,
    skipWeekends,
    defaultDayOfYearToWeekday,
    customFromDate,
    customEndDate,
    datePicker,
    monthOnly,
    disablePast,
  } = {
    mode: Constants.DateRangeMode.DAY,
    skipWeekends: false,
    datePicker: false,
    customFromDate: undefined,
    customEndDate: undefined,
    monthOnly: false,
    disablePast: false,
  },
) => {
  const dispatch = useDispatch();
  const [dateRangeText, setDateRangeText] = useState('');
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const startDate = useSelector(DateRangeNavigatorSelectors.getStartDate);
  const [pastDisabled, setPastDisabled] = React.useState(false);

  const [datePickerOpen, setDatePickerOpen] = React.useState(false);

  const handlePrevClick = useCallback(() => {
    const unit = getMomentUnit(mode);
    const newStartDate = moment(startDate).subtract(1, unit);

    if (skipWeekends && startDate.day() === 1) {
      newStartDate.subtract(2, 'day');
    }

    if (startDate.isSameOrAfter(moment()) || userRole === Role.DIRECTOR || userRole === Role.MANAGER) {
      dispatch(DateRangeNavigatorActions.setStartDate(newStartDate));
      dispatch(
        DateRangeNavigatorActions.setDateRange(createDayRange({ startDate: newStartDate, unit: getMomentUnit(mode) })),
      );

      // mobile - if in current week, set to today. if in any week after, set to beginning of week
      if (newStartDate.isSameOrAfter(moment(), 'day')) {
        if (defaultDayOfYearToWeekday) {
          const dayOfYear = moment(newStartDate).day(1);
          dispatch(DateRangeNavigatorActions.setDayOfYear(dayOfYear));
        } else {
          dispatch(DateRangeNavigatorActions.setDayOfYear(newStartDate));
        }
      } else {
        dispatch(DateRangeNavigatorActions.setDayOfYear(moment()));
      }
    }
  }, [dispatch, userRole, mode, startDate, defaultDayOfYearToWeekday, skipWeekends]);

  const handleNextClick = useCallback(() => {
    const unit = getMomentUnit(mode);

    const newStartDate = moment(startDate).add(1, unit);

    if (skipWeekends && startDate.day() === 5) {
      newStartDate.add(2, 'day');
    }

    dispatch(DateRangeNavigatorActions.setStartDate(newStartDate));

    if (defaultDayOfYearToWeekday) {
      const dayOfYear = moment(newStartDate).day(1);
      dispatch(DateRangeNavigatorActions.setDayOfYear(dayOfYear));
    } else {
      dispatch(DateRangeNavigatorActions.setDayOfYear(newStartDate));
    }
    dispatch(
      DateRangeNavigatorActions.setDateRange(createDayRange({ startDate: newStartDate, unit: getMomentUnit(mode) })),
    );
  }, [dispatch, mode, startDate, defaultDayOfYearToWeekday, skipWeekends]);

  useEffect(() => {
    if (mode === Constants.DateRangeMode.DAY) {
      setDateRangeText(moment(startDate).format(Constants.DATE_FORMAT_DMDD));
    } else if (mode === Constants.DateRangeMode.WEEK) {
      const startOfWeek = moment(startDate).startOf('week');
      dispatch(DateRangeNavigatorActions.setStartDate(startOfWeek));
      dispatch(
        DateRangeNavigatorActions.setDateRange(createDayRange({ startDate: startOfWeek, unit: getMomentUnit(mode) })),
      );
    } else if (mode === Constants.DateRangeMode.MONTH) {
      const startOfMonth = moment(startDate).startOf('month');
      dispatch(DateRangeNavigatorActions.setStartDate(startOfMonth));
      dispatch(
        DateRangeNavigatorActions.setDateRange(createDayRange({ startDate: startOfMonth, unit: getMomentUnit(mode) })),
      );
    } else if (mode === Constants.DateRangeMode.CUSTOM) {
      const startOfCustomRange = moment(customFromDate);
      const endOfCustomRange = moment(customEndDate);
      dispatch(DateRangeNavigatorActions.setStartDate(startOfCustomRange));
      dispatch(
        DateRangeNavigatorActions.setDateRange(
          createDayRange({ startDate: startOfCustomRange, endDate: endOfCustomRange }),
        ),
      );
    }
    // eslint-disable-next-line
  }, [dispatch, mode]);

  useEffect(() => {
    const getShouldDisablePast = () => {
      return moment(startDate).add(-1, getMomentUnit(mode)).endOf(getMomentUnit(mode)).isBefore(moment());
    };

    if (mode === Constants.DateRangeMode.DAY) {
      setDateRangeText(moment(startDate).format(Constants.DATE_FORMAT_DMDD));
    } else if (mode === Constants.DateRangeMode.WEEK) {
      const startOfWeek = moment(startDate).startOf('week');
      const endOfWeek = moment(startDate).endOf('week');
      const newDateRangeText = `${startOfWeek.format('MMM DD')} - ${endOfWeek.format('MMM DD')}`;
      setDateRangeText(newDateRangeText);
    } else if (mode === Constants.DateRangeMode.MONTH) {
      const startOfMonth = moment(startDate).startOf('month');
      const endOfMonth = moment(startDate).endOf('month');

      let newDateRangeText = '';
      if (monthOnly) {
        newDateRangeText = `${startOfMonth.format('MMMM YYYY')}`;
      } else {
        newDateRangeText = `${startOfMonth.format('MMM DD')} - ${endOfMonth.format('MMM DD')}`;
      }

      setDateRangeText(newDateRangeText);
    } else if (mode === Constants.DateRangeMode.CUSTOM) {
      const startOfCustomRange = moment(customFromDate);
      const endOfCustomRange = moment(customEndDate);
      const newDateRangeText = `${startOfCustomRange.format('MM/DD/YYYY')} - ${endOfCustomRange.format('MM/DD/YYYY')}`;
      setDateRangeText(newDateRangeText);

      dispatch(
        DateRangeNavigatorActions.setDateRange(
          createDayRange({ startDate: startOfCustomRange, endDate: endOfCustomRange }),
        ),
      );
    }

    if (disablePast) {
      setPastDisabled(getShouldDisablePast());
    }
  }, [dispatch, mode, startDate, customFromDate, customEndDate, disablePast, monthOnly]);

  const disableRangeNavigation = mode === Constants.DateRangeMode.CUSTOM;

  return (
    <div className={!isMobile ? 'date-range-nav-container' : 'date-range-nav-container-mobile'}>
      <button
        className={classNames('btn-reset icon-container', (disableRangeNavigation || pastDisabled) && 'btn-disabled')}
        onClick={handlePrevClick}
        aria-label="Load Previous Week"
        disabled={disableRangeNavigation || pastDisabled}
        data-test-id="loadPreviousBtn"
      >
        <ChevronLeft />
      </button>
      {mode === Constants.DateRangeMode.DAY && datePicker ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            InputProps={{
              disableUnderline: true,
              className: 'date-picker',
            }}
            open={datePickerOpen}
            onOpen={() => setDatePickerOpen(true)}
            value={startDate}
            format={Constants.DATE_FNS_FORMAT_DMDD}
            onClose={() => setDatePickerOpen(false)}
            onChange={(value) => dispatch(DateRangeNavigatorActions.setStartDate(moment(value)))}
          />
        </MuiPickersUtilsProvider>
      ) : (
        <div className="text">{dateRangeText}</div>
      )}
      <button
        className={classNames('btn-reset icon-container', disableRangeNavigation && 'btn-disabled')}
        onClick={handleNextClick}
        aria-label="Load Next Week"
        disabled={disableRangeNavigation}
        data-test-id="loadNextBtn"
      >
        <ChevronRight />
      </button>
    </div>
  );
};

export default DateRangeNavigator;
