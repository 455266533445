import { Reducer } from 'redux';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';

const initialState: Interfaces.TransactionHistoryState = {
  orderItems: [],
};

const TranscationHistoryReducer: Reducer<Interfaces.TransactionHistoryState, Interfaces.Action> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case Types.TRANSACTION_HISTORY_SET_HISTORY:
      return {
        ...state,
        orderItems: payload,
      };
    default:
      return state;
  }
};

export default TranscationHistoryReducer;
