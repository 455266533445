import { USD_FORMAT, PRODUCT_CATEGORIES, DATE_FN_FORMATS, PRODUCT_CATEGORIES_SORT_ORDER } from 'Constants/Constants';
import { AppState, OrderItem, Student, TransactionHistoryItem } from 'Interfaces/Interfaces';
import { get, groupBy, orderBy, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { getStudents } from 'State/selectors';
import numeral from 'numeral';
import { format, parse, isValid } from 'date-fns';

const root = (state: AppState) => get(state, 'transactionHistoryState');

export const getOrderItems = (state: AppState): OrderItem[] => get(root(state), 'orderItems');

const createHistoryItem = (student: Student, orderItems: OrderItem[]): TransactionHistoryItem => {
  const account = `${student.firstName} ${student.lastName}`;
  const firstOrderItem = orderItems[0];
  let total = 0;
  const products: Record<string, { productName?: string; categoryId?: string; count: number }> = {};
  const pickupLocationName = firstOrderItem.pickupLocationName || '';
  const parsedDate = parse(firstOrderItem.date, DATE_FN_FORMATS.YYYY_MM_DD, new Date());
  const date = isValid(parsedDate) ? format(parsedDate, DATE_FN_FORMATS.M_DD_YYYY) : '';
  const menuTypeName = firstOrderItem.mealTypeName;
  const type = firstOrderItem.productCategoryId === PRODUCT_CATEGORIES.ALACARTE.id ? 'A La Carte' : 'Meal';
  const orderedAtDate = firstOrderItem.orderedAt
    ? ` - ${format(new Date(firstOrderItem.orderedAt), DATE_FN_FORMATS.M_DD_YY_H_M)}`
    : '';
  const orderedBy = `${firstOrderItem?.orderedBy?.name || firstOrderItem?.orderedBy?.email || ''}${orderedAtDate}`;
  const canceledAtDate = firstOrderItem.canceledAt
    ? ` - ${format(new Date(firstOrderItem.canceledAt), DATE_FN_FORMATS.M_DD_YY_H_M)}`
    : '';
  const canceledBy = `${firstOrderItem?.canceledBy?.name || firstOrderItem?.canceledBy?.email || ''}${canceledAtDate}`;
  const status = firstOrderItem?.canceledBy ? 'Canceled' : '';

  orderItems.forEach((orderItem) => {
    total += orderItem.price || 0;
    if (products[orderItem.productId]) {
      products[orderItem.productId].count += 1;
    } else {
      products[orderItem.productId] = {
        productName: orderItem.productName,
        categoryId: orderItem.productCategoryId,
        count: 1,
      };
    }
  });
  const items = sortBy(Object.values(products), (p) => {
    if (!p.categoryId) {
      return PRODUCT_CATEGORIES_SORT_ORDER.UNKNOWN;
    }
    return PRODUCT_CATEGORIES_SORT_ORDER[p.categoryId];
  })
    .map((p) => {
      if (p.count > 1) {
        return `${p.productName} x${p.count}`;
      }
      return p.productName;
    })
    .join(' | ');

  const historyItem: TransactionHistoryItem = {
    date,
    account,
    type,
    orderedBy,
    canceledBy,
    items,
    status,
    pickupLocationName,
    menuTypeName,
    total: numeral(total).format(USD_FORMAT),
    menuTypeId: firstOrderItem.mealTypeId,
    siteId: firstOrderItem.siteId,
    studentId: student.id,
    districtId: firstOrderItem.districtId,
  };

  return historyItem;
};

export const getOrderHistory = createSelector(
  [getOrderItems, getStudents],
  (orderItems, students): TransactionHistoryItem[] => {
    const historyItems: TransactionHistoryItem[] = [];
    const orderItemsByDate = groupBy(orderItems, 'date');

    Object.values(orderItemsByDate).forEach((oItems) => {
      const itemsByStudentId = groupBy(oItems, 'studentId');

      Object.entries(itemsByStudentId).forEach(([studentId, studentItems]) => {
        if (!studentId) {
          return;
        }

        const student = students[studentId];

        if (!student) {
          return;
        }

        const itemsByOrderId = groupBy(studentItems, 'orderId');

        Object.values(itemsByOrderId).forEach((orders) => {
          const groupedMenuOrders = groupBy(orders, 'mealTypeId');

          Object.values(groupedMenuOrders).forEach((mealOrderItems) => {
            const mealItems = mealOrderItems.filter(
              (item) => item.productCategoryId !== PRODUCT_CATEGORIES.ALACARTE.id,
            );
            const aLaCarteItems = mealOrderItems.filter(
              (item) => item.productCategoryId === PRODUCT_CATEGORIES.ALACARTE.id,
            );
            if (mealItems.length) {
              const mealHistoryItem = createHistoryItem(student, mealItems);
              historyItems.push(mealHistoryItem);
            }

            if (aLaCarteItems.length) {
              const alaCarteHistoryItem = createHistoryItem(student, aLaCarteItems);
              historyItems.push(alaCarteHistoryItem);
            }
          });
        });
      });
    });

    return orderBy(historyItems, 'date', 'desc');
  },
);
