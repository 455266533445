import classNames from 'classnames';
import { InlineLoader } from 'Components/Loaders/Loaders';
import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import './FileDropzone.less';

interface FileDropzoneProps extends DropzoneOptions {
  loading: boolean;
  label?: string;
  className?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

const FileDropzone: React.FC<FileDropzoneProps> = ({
  loading,
  label,
  className,
  inputProps = {},
  ...dropzoneProps
}) => {
  const { getRootProps, getInputProps } = useDropzone(dropzoneProps);

  return (
    <div {...getRootProps()} className={classNames('linq-file-upload', className)}>
      <input {...getInputProps()} data-test-id="dropZoneInput" />
      {label && <span className="linq-file-upload-label">{label}</span>}
      <div className="linq-file-upload-target">
        <div className={classNames('linq-file-upload-target__content', { loading })}>
          {!loading ? (
            <>
              <CloudUploadIcon className="linq-file-upload-target__icon" />
              <span>
                Drag and drop here
                <br />
                or
                <br />
                <span className="file-upload-browse">browse</span>
              </span>
            </>
          ) : (
            <InlineLoader loading={loading} text="Uploading..." />
          )}
          <input {...getInputProps()} />
        </div>
      </div>
    </div>
  );
};

export default FileDropzone;
