import { useAuth0 } from '@auth0/auth0-react';
import { FormControl } from '@material-ui/core';
import { useAuthActions } from 'Auth/actions';
import { Admin, isAdminAuthorized, Role, TokenClaims } from 'Auth/User';
import * as AdminActions from 'Components/Admin/state/actions';
import Button from 'Components/Button/Button';
import { NotificationDialog } from 'Components/Dialogs/NotificationDialog';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'Components/Drawer';
import { AutocompleteDropdown, SelectField } from 'Components/Form/MaterialForm';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';
import { SidebarLocations } from 'Constants/Enums';
import { getRoutePath, RouteId } from 'Constants/Routes';
import { useFormik } from 'formik';
import * as Interfaces from 'Interfaces/Interfaces';
import { sortBy } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as CommonStateActions from 'State/actions';
import * as CommonSelectors from 'State/selectors';
import * as yup from 'yup';
import './AdminSidebar.less';

const AdminSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showSidebar = useSelector((state: Interfaces.AppState) => {
    return CommonSelectors.getSidebarOpen(state, SidebarLocations.ADMIN_SIDE_BAR);
  });

  const districts = useSelector(CommonSelectors.getDistricts);
  const { user } = useAuth0();
  const role = TokenClaims.getRole(user);
  const districtId = TokenClaims.getDistrictId(user);
  const district = districts[districtId];
  const adminRole = TokenClaims.getAdmin(user);
  const [submitting, setSubmitting] = useState(false);
  const [importing, setImporting] = useState(false);
  const [displayUnpublishNotification, setDisplayUnpublishNotification] = useState(false);
  const [unpublishingMenus, setUnpublishingMenus] = useState(false);
  const importIsiteData = AdminActions.useImportIsiteData();
  const authActions = useAuthActions();

  const handleImportIsiteData = useCallback(async () => {
    const data = {
      districtList: [districtId],
    };
    setImporting(true);
    await importIsiteData(data);
    setImporting(false);
  }, [districtId, importIsiteData]);

  const handleUnpublishAllMenus = useCallback(async () => {
    const data = {
      districtList: [districtId],
      unpublishAllMenus: true,
    };
    setDisplayUnpublishNotification(false);
    setUnpublishingMenus(true);
    await importIsiteData(data);
    setUnpublishingMenus(false);
  }, [districtId, importIsiteData]);

  const formik = useFormik({
    initialValues: {
      districtId: districtId || '',
      role: role || '',
      siteId: '',
      homeroomId: '',
    },
    onSubmit: async (values) => {
      if (!submitting) {
        setSubmitting(true);
        await authActions.patchUser(values);
        setSubmitting(false);
        navigate(getRoutePath(RouteId.ROOT));
        dispatch(CommonStateActions.setSidebarOpen(false, SidebarLocations.ADMIN_SIDE_BAR));
      }
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      districtId: yup.string().required('Required Field'),
      role: yup.string().required('Required Field'),
    }),
  });

  const handleCloseClick = useCallback(() => {
    dispatch(CommonStateActions.setSidebarOpen(false, SidebarLocations.ADMIN_SIDE_BAR));
    formik.resetForm();
  }, [dispatch, formik]);

  const districtOptions = useMemo(() => {
    return sortBy(Object.values(districts), 'name').map((district) => ({
      text: district.name,
      value: district.id,
    }));
  }, [districts]);

  const roleOptions = useMemo(() => {
    return Object.values(Role).map((role) => ({
      text: role.toUpperCase(),
      value: role,
    }));
  }, []);

  if (!showSidebar) {
    return null;
  }

  return (
    <Drawer anchor="right" open={showSidebar} className="admin-sidebar-container">
      <DrawerHeader>
        <h2>Admin</h2>
        <CloseIconButton onClick={handleCloseClick} />
      </DrawerHeader>
      <NotificationBanner location={SidebarLocations.ADMIN_SIDE_BAR} />
      <DrawerContent>
        <p>Set User Account</p>
        <form onSubmit={formik.handleSubmit}>
          {isAdminAuthorized(adminRole, [Admin.SUPER]) && (
            <FormControl fullWidth>
              <AutocompleteDropdown
                id="districtId"
                label="School District"
                value={formik.values.districtId ?? ''}
                options={districtOptions}
                onChange={(e, selectedOption) => {
                  formik.setFieldValue('districtId', selectedOption?.value);
                }}
              />
            </FormControl>
          )}
          <FormControl fullWidth>
            <SelectField
              fieldLabel="User Role"
              name="role"
              id="role"
              value={formik.values.role}
              onChange={formik.handleChange}
              options={roleOptions}
              showOnlyTouchedError={true}
              hideEmptyError={true}
            />
          </FormControl>
          <Button type="submit" variant="contained" color="primary" loading={submitting} disabled={submitting}>
            Update Account
          </Button>
        </form>
        {isAdminAuthorized(adminRole, [Admin.SUPER]) && (
          <>
            <hr />
            <p>Import data from ISITE (district, site, menu, product and users)</p>
            <Button
              onClick={handleImportIsiteData}
              variant="contained"
              color="primary"
              loading={importing}
              disabled={importing}
            >
              Import ISITE Data
            </Button>
          </>
        )}
      </DrawerContent>
      <DrawerFooter>
        {isAdminAuthorized(adminRole, [Admin.SUPER]) && (
          <Button
            variant="outlined"
            onClick={() => setDisplayUnpublishNotification(true)}
            loading={unpublishingMenus}
            disabled={unpublishingMenus}
            className="linq-button--alert-stroked"
          >
            Unpublish All Menus
          </Button>
        )}
      </DrawerFooter>
      <NotificationDialog
        showDialog={displayUnpublishNotification}
        title="Are you sure?"
        message={`Do you wish to unpublish all menus for ${district?.name}?`}
        showCancel={true}
        cancelButtonText="Cancel"
        onCancelClick={() => setDisplayUnpublishNotification(false)}
        okButtonText="Ok"
        onOkClick={() => handleUnpublishAllMenus()}
      />
    </Drawer>
  );
};

export default AdminSidebar;
