import { createSelector } from 'reselect';
import { get, groupBy, uniqBy } from 'lodash';

import * as CommonSelectors from 'State/selectors';
import {
  AppState,
  MenuItem,
  OrderingRules,
  Product,
  TeacherBulkOrderingCart,
  TeacherOrderingCart,
} from 'Interfaces/Interfaces';
import { getMenuTypeId } from 'Components/MenuTypePicker/state/selectors';
import { getDateYMD } from 'Components/DateRangeNavigator/state/selectors';
import {
  filterLocationsBySiteAndHomeroom,
  sortAndGroupPickupLocations,
  sortPickupLocations,
  toPickupLocationOption,
} from 'Helpers/group';
import { cutoffWarningVisible } from 'Pages/Shop/state/selectors';

const root = (state: AppState) => get(state, 'teacherReportState');

export const getTeacherReport = (state: AppState) => get(root(state), 'report', {});

export const getHomeroomName = (homeroomId: string) =>
  createSelector([CommonSelectors.getHomerooms], (homerooms) => {
    const homeroom = Object.values(homerooms).find((c) => c.id === homeroomId);
    if (homeroom?.name) {
      return homeroom.name;
    }
    return homeroom?.id ? `Classroom ${homeroom?.id}` : `Classroom`;
  });

export const getEntreeSidebarOpen = (state: AppState) => get(root(state), 'entreeSidebarOpen');

export const getSelectedEntree = (state: AppState): MenuItem => get(root(state), 'entree');

export const getMenuItemsByProductCategory = createSelector(
  [getDateYMD, CommonSelectors.getMenuItems, CommonSelectors.getProducts, getMenuTypeId],
  (date, menuItems, products, menuTypeId) => {
    const currentMenuItems = Object.values({ ...menuItems })
      .filter((menuItem) => menuItem.date === date && menuItem.mealTypeId === menuTypeId)
      .map((menuItem) => {
        menuItem.product = products[menuItem.productId];
        return menuItem;
      });
    return groupBy(uniqBy(currentMenuItems, 'productId'), 'product.productCategoryId');
  },
);

export const getSelectedSideOptions = (state: AppState): Record<string, MenuItem[]> =>
  get(root(state), 'selectedSides');

export const getShowCart = (state: AppState): boolean => get(root(state), 'showCart');

export const getCart =
  (accountId: string) =>
  (state: AppState): TeacherOrderingCart =>
    get(root(state), ['cart', accountId], {});

export const getBulkCart =
  (accountId: string) =>
  (state: AppState): TeacherBulkOrderingCart =>
    get(root(state), ['bulkCart', accountId], {});

export const getCartTotals = (accountId: string) =>
  createSelector([getCart(accountId), getBulkCartTotals(accountId)], (cart, bulkCartTotal) => {
    let total = 0;

    Object.entries(cart).forEach(([date, dateItems]) => {
      Object.entries(dateItems).forEach(([menuTypeIds, cartItems]) => {
        Object.values(cartItems).forEach((entree) => {
          total += entree.studentIds.length;
        });
      });
    });
    return total + bulkCartTotal;
  });

export const getBulkCartTotals = (accountId: string) =>
  createSelector([getBulkCart(accountId)], (bulkCart) => {
    let total = 0;

    Object.entries(bulkCart).forEach(([date, dateItems]) => {
      Object.entries(dateItems).forEach(([menuTypeIds, bulkOrderItems]) => {
        Object.values(bulkOrderItems).forEach((bulkOrderItem) => {
          total += bulkOrderItem.quantity;
        });
      });
    });
    return total;
  });

/**
 * Returns a map of the student id mapped to the entree product id.
 */
export const getStudentIdsInCart = (accountId: string) =>
  createSelector([getCart(accountId)], (cart) => {
    const studentIdsByCartDate: {
      [key: string]: {
        [key: string]: {
          [key: string]: string;
        };
      };
    } = {};

    Object.entries(cart).forEach(([date, menuTypeIds]) => {
      Object.entries(menuTypeIds).forEach(([menuTypeId, cartItems]) => {
        Object.entries(cartItems).forEach(([cartItemId, cartItem]) => {
          cartItem.studentIds.forEach((studentId) => {
            if (!studentIdsByCartDate[date]) {
              studentIdsByCartDate[date] = { [menuTypeId]: {} };
            }
            if (!studentIdsByCartDate[date][menuTypeId]) {
              studentIdsByCartDate[date][menuTypeId] = { [studentId]: cartItemId };
            } else {
              studentIdsByCartDate[date][menuTypeId][studentId] = cartItemId;
            }
          });
        });
      });
    });

    return studentIdsByCartDate;
  });

export const getReportProducts = (state: AppState): Record<string, Product> => get(root(state), 'products', {});

export const getTeacherPickupLocationOptions = createSelector(
  [
    CommonSelectors.getPickupLocations,
    (state: AppState, homeroomId: string, siteId: string) => {
      return { homeroomId, siteId };
    },
  ],
  (pickupLocations, { homeroomId, siteId }) => {
    const teacherPickupLocations = filterLocationsBySiteAndHomeroom(pickupLocations, siteId, homeroomId);
    const pickupLocationsSorted = sortPickupLocations(teacherPickupLocations);
    const options = pickupLocationsSorted.map((pickupLocation) => toPickupLocationOption(pickupLocation));

    return sortAndGroupPickupLocations(options);
  },
);

export const isCutoffWarningVisible = (orderingRules: OrderingRules, isTeacher = false) =>
  createSelector([cutoffWarningVisible], (visible) => {
    return (
      (isTeacher &&
        visible &&
        orderingRules?.teacherOrderRange &&
        (orderingRules?.teacherCutoffDay || orderingRules?.teacherOrderDueBy)) ||
      (orderingRules?.teacherMaxOrderRange &&
        (orderingRules?.teacherMaxCutoffDays ||
          orderingRules?.teacherMaxCutoffWeeks ||
          orderingRules?.teacherMaxCutoffMonths))
    );
  });

export const getIsBulkMode = (state: AppState) => get(root(state), 'isBulkMode', false);

export const getAccountCartHoomroom =
  (accountId: string) =>
  (state: AppState): string => {
    return get(root(state), ['cartHomerooms', accountId], '');
  };
