import React, { FC, useState, useRef } from 'react';
import { Formik, Form, FormikValues, Field, FieldProps, FormikProps } from 'formik';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'Components/Drawer';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import Button from 'Components/Button/Button';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';
import { useFocusTrap } from 'Helpers/focusTrap';
import { useBodyOverflow } from 'Helpers/useBodyOverflow';
import { useCommonActions } from 'State/actions';
import * as Constants from 'Constants/Constants';
import { SidebarLocations } from 'Constants/Enums';
import * as Interfaces from 'Interfaces/Interfaces';

export interface LabelRulesSidebarProps {
  open: boolean;
  orderingRules: Interfaces.OrderingRules;
  setSidebarOpen: (open: boolean) => void;
}

const LabelRulesSidebar: FC<LabelRulesSidebarProps> = ({ open = false, setSidebarOpen, orderingRules = {} }) => {
  const slideOutRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { updateDistrict } = useCommonActions();
  // Default labelsAllergensDisplayType to "none"
  const initialValues = {
    ...orderingRules,
    labelsAllergensDisplayType: orderingRules?.labelsAllergensDisplayType || Interfaces.LabelAllergenOptions.None,
    labelStudentIdDisplayType: orderingRules?.labelStudentIdDisplayType || Interfaces.LabelStudentIdDisplayOptions.Show,
  };

  const handleSubmit = async (values: FormikValues) => {
    setLoading(true);
    await updateDistrict({ orderingRules: { ...orderingRules, ...values } });
    setLoading(false);
    setSidebarOpen(false);
  };

  useBodyOverflow(open);
  useFocusTrap(slideOutRef, { trackStatus: true, isActive: open });

  return (
    <Drawer open={open} id="online-ordering-card-labels" anchor="right" className="ordering-rules-sidebar-container">
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ handleReset }: FormikProps<{}>) => (
          <Form className="linq-drawer-form-content oo-drawer-form">
            <DrawerHeader>
              <h2>Edit Labels</h2>
              <CloseIconButton
                onClick={() => {
                  setSidebarOpen(false);
                }}
              />
            </DrawerHeader>
            <NotificationBanner location={SidebarLocations.LABEL_RULES_SIDE_BAR} />
            <DrawerContent>
              <section>
                <p>Do you want to include student allergens on the labels?</p>
                <Field name="labelsAllergensDisplayType">
                  {({ field }: FieldProps) => (
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="Do you want to include student allergens on the labels?"
                        name="labelsAllergensDisplayType"
                        onChange={field.onChange}
                        value={field.value}
                      >
                        <FormControlLabel
                          value={Interfaces.LabelAllergenOptions.PlainText}
                          control={
                            <Radio
                              aria-label={Constants.LabelAllergenOptionsDisplay.PlainText}
                              data-test-id="includeAllergensOnLabelBtn"
                            />
                          }
                          label={Constants.LabelAllergenOptionsDisplay.PlainText}
                        />
                        <FormControlLabel
                          value={Interfaces.LabelAllergenOptions.None}
                          control={
                            <Radio
                              aria-label={Constants.LabelAllergenOptionsDisplay.None}
                              data-test-id="dontIncludeAllergensOnLabelBtn"
                            />
                          }
                          label={Constants.LabelAllergenOptionsDisplay.None}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                </Field>
              </section>
              <section>
                <p>Do you want to include student ID on the labels?</p>
                <Field name="labelStudentIdDisplayType">
                  {({ field }: FieldProps) => (
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="Do you want to include student ID on the labels?"
                        name="labelStudentIdDisplayType"
                        onChange={field.onChange}
                        value={field.value}
                      >
                        <FormControlLabel
                          value={Interfaces.LabelStudentIdDisplayOptions.Show}
                          control={
                            <Radio
                              aria-label={Constants.LabelStudentIdDisplayOptionsText.Show}
                              data-test-id="includeStudentIdOnLabelBtn"
                            />
                          }
                          label={Constants.LabelStudentIdDisplayOptionsText.Show}
                        />
                        <FormControlLabel
                          value={Interfaces.LabelStudentIdDisplayOptions.Hide}
                          control={
                            <Radio
                              aria-label={Constants.LabelStudentIdDisplayOptionsText.Hide}
                              data-test-id="dontIncludeStudentIdOnLabelBtn"
                            />
                          }
                          label={Constants.LabelStudentIdDisplayOptionsText.Hide}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                </Field>
              </section>
            </DrawerContent>
            <DrawerFooter>
              <Button
                type="button"
                disabled={loading}
                color="primary"
                variant="outlined"
                onClick={() => {
                  setSidebarOpen(false);
                  handleReset();
                }}
                data-test-id="cancelBtn"
              >
                Cancel
              </Button>
              <Button
                aria-label="Submit"
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading}
                loading={loading}
                data-test-id="submitBtn"
              >
                Save
              </Button>
            </DrawerFooter>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default LabelRulesSidebar;
