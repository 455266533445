import { Avatar, Card, CardActions, Chip, IconButton, Menu, MenuItem } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InfoIcon from '@material-ui/icons/Info';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import classNames from 'classnames';
import Barcode from 'Components/Barcode/Barcode';
import Button from 'Components/Button/Button';
import { ButtonLink } from 'Components/ButtonLink/ButtonLink';
import CalendarBlock from 'Components/CalendarBlock/CalendarBlock';
import { NotificationDialog } from 'Components/Dialogs/NotificationDialog';
import * as CartSelectors from 'Components/Shop/Cart/state/selectors';
import * as StudentCartSwitchModalActions from 'Components/Student/SwitchStudentCartModal/state/actions';
import * as UnlinkStudentModalActions from 'Components/Student/UnlinkStudentModal/state/actions';
import * as Constants from 'Constants/Constants';
import { getRoutePath, RouteId } from 'Constants/Routes';
import { getGradeTextFromStudent, isEmpty } from 'Helpers/Helper';
import * as Interfaces from 'Interfaces/Interfaces';
import moment from 'moment';
import numeral from 'numeral';
import * as ShopActions from 'Pages/Shop/state/actions';
import * as ShopSelectors from 'Pages/Shop/state/selectors';
import React, { FC, useCallback, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';
import * as StudentCardListSelectors from './state/selectors';

import './StudentCard.less';

const barCodeOptions = {
  width: 1,
  height: 40,
  background: 'transparent',
  lineColor: '#FFFFFF',
};

const StudentCard: FC<Interfaces.StudentCardProps> = ({ student, district, site }) => {
  const dispatch = useDispatch();
  const [districtInactiveNotificationVisible, setDistrictInactiveNotificationVisible] = useState(false);
  const navigate = useNavigate();
  const hasImage = student.profilePicture;
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const selectedStudent = useSelector(ShopSelectors.getStudent);
  const data = useSelector(CartSelectors.getCheckout);
  const isCartEmpty = data.count === 0;
  const orderingRules = useSelector(ShopSelectors.getOrderingRules);
  const blackoutDates = orderingRules?.blackoutDates || [];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const entreeOrders = useSelector((state) =>
    StudentCardListSelectors.getStudentEntreeOrders(state as Interfaces.AppState, student),
  );

  const aLaCarteOrders = useSelector((state) =>
    StudentCardListSelectors.getStudentALaCarteOrders(state as Interfaces.AppState, student),
  );

  const entreeOrderDates = React.useMemo(() => {
    return entreeOrders.map((order) => {
      return order.date;
    });
  }, [entreeOrders]);

  const alaCarteOrderDates = React.useMemo(() => {
    return aLaCarteOrders.map((order) => {
      return order.date;
    });
  }, [aLaCarteOrders]);

  const handleOrderMealsClick = useCallback(() => {
    if (district?.inactive) {
      setDistrictInactiveNotificationVisible(true);
    } else if ((selectedStudent && selectedStudent.id === student.id) || isCartEmpty) {
      batch(() => {
        dispatch(ShopActions.setStudentId(student.id));
        dispatch(ShopActions.setDistrictId(student.districtId));
        dispatch(ShopActions.setSiteId(student.siteId));
      });
      navigate(getRoutePath(RouteId.SHOP));
    } else {
      dispatch(StudentCartSwitchModalActions.setOpen(true, student.id));
    }
  }, [district, selectedStudent, student.id, student.districtId, student.siteId, isCartEmpty, dispatch, navigate]);

  const handleUnlinkclick = () => {
    dispatch(UnlinkStudentModalActions.setOpen(true, student.id));
  };

  const showStudentBalance = !isEmpty(student?.balance);
  return (
    <>
      <Card
        className={classNames('linq-card', {
          'student-card-container': !isMobile,
          'student-card-container-mobile': isMobile,
        })}
      >
        <div className={classNames('linq-card__profile-card', { mobile: isMobile })}>
          <div className="profile-card">
            <div>
              <div className="card-header__title profile-card__title" data-test-id={`${student.sisId}.nameLbl`}>
                {student.firstName} {student.lastName}
              </div>
              <div className="profile-card__details" data-test-id={`${student.sisId}.sisIdLbl`}>
                ID #: {student.sisId}
                {getGradeTextFromStudent(student)}
              </div>
              <div className="profile-card__details" title={student.siteId} data-test-id={`${student.sisId}.siteIdLbl`}>
                {site?.name ?? `Site ID #: ${student.siteId}`}
              </div>
              <div
                className="profile-card__details"
                title={student.districtId}
                data-test-id={`${student.sisId}.districtId`}
              >
                {district?.name ?? `District #: ${student.districtId}`}
              </div>
            </div>
            <div className="profile-card__avatar">
              {hasImage && (
                <Avatar
                  className="student-card-header__picture"
                  src={student.profilePicture}
                  data-test-id={`${student.sisId}.profilePicture`}
                />
              )}
              {!hasImage && (
                <AccountCircleIcon
                  className="profile-card__no-image student-card__no-image"
                  data-test-id={`${student.sisId}.profilePicture`}
                />
              )}
            </div>
          </div>
          <div className={classNames('barcode', { mobile: isMobile })} data-test-id={`${student.sisId}.barcode`}>
            <Barcode value={student.sisId} options={barCodeOptions} />
          </div>
          {student.suspended && (
            <div className="suspended-container" data-test-id={`${student.sisId}.suspended`}>
              <InfoIcon className="icon" />
              <div className="message">Unable to place a student order, contact Nutrition Services Dept.</div>
            </div>
          )}
        </div>
        <div className="linq-card__space student-card__space">
          <div className={classNames('student-data', { mobile: isMobile })}>
            {showStudentBalance && (
              <>
                <div>
                  <h6 className="linq-card__h6">
                    Meals Balance (as of {moment(student.updatedAt).format('MMM DD h:mm a')})
                  </h6>
                  <div
                    className={classNames((student.balance || 0) < 0 && 'student-card__negative-balance')}
                    data-test-id={`${student.sisId}.balance`}
                  >
                    {numeral(student.balance).format(Constants.USD_FORMAT)}
                  </div>
                </div>
              </>
            )}
            {!showStudentBalance && <div className="student-data-label" />}
            <Chip
              label={
                student.reimbursableMealTypeId === Constants.REIMBURSABLE_MEAL_TYPES.ADULT.id ? 'Adult' : 'Student'
              }
              className="linq-chip student-type-chip"
            />
          </div>
          <div className="student-card__calendar-container">
            <CalendarBlock
              numberOfWeeks={2}
              date={new Date()}
              orderedDates={entreeOrderDates}
              blackoutDates={blackoutDates}
              title="Meals Ordered"
            />
            <CalendarBlock
              className={classNames(isMobile && 'student-card__alacarte-table-mobile')}
              numberOfWeeks={2}
              date={new Date()}
              orderedDates={alaCarteOrderDates}
              blackoutDates={blackoutDates}
              title="A la Carte Ordered"
            />
          </div>
        </div>

        {!student.suspended && (
          <CardActions className="linq-card__actions student-card__actions">
            {!isMobile && (
              <Button
                className="student-action"
                onClick={() => {
                  navigate(`${getRoutePath(RouteId.TRANSACTION_HISTORY)}?studentId=${student.id}`);
                }}
                data-test-id={`${student.sisId}.historyBtn`}
              >
                History
              </Button>
            )}

            {district?.paymentProviderLink && (
              <ButtonLink
                className={classNames({ 'student-action': !isMobile, 'student-action-mobile': isMobile })}
                data-test-id={`${student.sisId}.addBalanceBtn`}
                text={'Add to Balance'}
                href={district?.paymentProviderLink}
                target="_blank"
                rel="noopener noreferrer"
              />
            )}
            <Button
              className={classNames({ 'student-action': !isMobile, 'student-action-mobile': isMobile })}
              onClick={handleOrderMealsClick}
              variant={'text'}
              data-test-id={`${student.sisId}.orderMealsBtn`}
            >
              Order Meals
            </Button>

            <IconButton
              className="card-actions"
              aria-controls="card-actions"
              aria-haspopup="true"
              onClick={handleClick}
              data-test-id={`${student.sisId}.cardActionsBtn`}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu id="card-action-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem
                className="linq-button--text-alert"
                onClick={handleUnlinkclick}
                aria-label="Click to remove student."
                data-test-id={`${student.sisId}.unlinkBtn`}
              >
                Unlink
              </MenuItem>
              {isMobile && (
                <MenuItem className="student-action" color="primary" data-test-id={`${student.sisId}.historyBtn`}>
                  <Link to={`${getRoutePath(RouteId.TRANSACTION_HISTORY)}?studentId=${student.id}`}>History</Link>
                </MenuItem>
              )}
            </Menu>
          </CardActions>
        )}
      </Card>
      <NotificationDialog
        showDialog={districtInactiveNotificationVisible}
        title="Orders No Longer Accepted"
        message={`${district?.name} School District is no longer accepting online orders.`}
        okButtonText="Ok"
        onOkClick={() => setDistrictInactiveNotificationVisible(false)}
      />
    </>
  );
};

export default StudentCard;
