import { Reducer } from 'react';
import { keyBy } from 'lodash';
import * as Interfaces from 'Interfaces/Interfaces';
import * as Types from './types';

const initialState: Interfaces.DistrictState = {
  reimbursableMealPrices: {},
  reimbursableMealPricesByGrade: {},
  reimbursableMealPricingModes: {},
  logs: {},
};

const DistrictReducer: Reducer<Interfaces.DistrictState, Interfaces.Action> = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.DISTRICT_SET_REIMBURSABLE_MEAL_PRICE:
      const newReimbursableMealPrice = {
        [payload.id]: payload,
      };
      return {
        ...state,
        reimbursableMealPrices: { ...state.reimbursableMealPrices, ...newReimbursableMealPrice },
      };

    case Types.DISTRICT_SET_REIMBURSABLE_MEAL_PRICES:
      return {
        ...state,
        reimbursableMealPrices: payload,
      };

    case Types.DISTRICT_SET_REIMBURSABLE_MEAL_PRICES_BY_GRADE:
      return {
        ...state,
        reimbursableMealPricesByGrade: payload,
      };

    case Types.DISTRICT_UPDATE_REIMBURSABLE_MEAL_PRICES:
      return { ...state, reimbursableMealPrices: { ...state.reimbursableMealPrices, ...payload } };

    case Types.DISTRICT_UPDATE_REIMBURSABLE_MEAL_PRICES_BY_GRADE:
      const filteredReimbursableMealPricesByGradeList = Object.values(state.reimbursableMealPricesByGrade).filter(
        (reimbPrice) => reimbPrice.reimbursableMealTypeId !== payload.reimbursableMealTypeId,
      );
      const reimbursableMealPricesByGradeState = keyBy(filteredReimbursableMealPricesByGradeList, 'id');

      return {
        ...state,
        reimbursableMealPricesByGrade: { ...reimbursableMealPricesByGradeState, ...payload.pricesByGrade },
      };

    case Types.DISTRICT_SET_MENU_TYPE_PRICING_MODES:
      return { ...state, reimbursableMealPricingModes: payload };

    case Types.DISTRICT_UPDATE_MENU_TYPE_PRICING_MODES:
      return {
        ...state,
        reimbursableMealPricingModes: { ...state.reimbursableMealPricingModes, [payload.id]: payload },
      };

    case Types.SET_LOGS:
      return {
        ...state,
        logs: { ...payload },
      };

    default:
      return state;
  }
};

export default DistrictReducer;
