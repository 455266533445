import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import * as CommonActions from 'State/actions';
import * as CommonSelectors from 'State/selectors';
import { SidebarLocations } from 'Constants/Enums';
import * as Interfaces from 'Interfaces/Interfaces';
import * as MenuTypePickerSelectors from './state/selectors';
import { useMenuTypePickerActions, setMenuTypeId } from './state/actions';

import { AutocompleteDropdown } from 'Components/Form/MaterialForm';
import { isAuthorized, Role, TokenClaims } from 'Auth/User';
import * as ShopSelectors from 'Pages/Shop/state/selectors';
import { getSiteId } from 'Components/SitePicker/state/selectors';
import { UNASSOCIATED_MEAL_TYPE_ID } from 'Constants/Constants';

import './MenuTypePicker.less';

const MenuTypePicker: FC<{ shouldGetDistrictMenuTypes?: boolean }> = ({ shouldGetDistrictMenuTypes }) => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const districtId = TokenClaims.getDistrictId(user);
  const siteId = TokenClaims.getSiteId(user);

  const menuTypeId = useSelector(MenuTypePickerSelectors.getMenuTypeId);
  const menuItemModalOpen = useSelector((state: Interfaces.AppState) =>
    CommonSelectors.getSidebarOpen(state, SidebarLocations.MENU_ITEM_SIDE_BAR),
  );
  const menuTypeOptions = useSelector(MenuTypePickerSelectors.getSortedMenuTypeOptions);
  const isParent = isAuthorized(userRole, [Role.PARENT]);
  const isManagerOrDirector = isAuthorized(userRole, [Role.MANAGER, Role.DIRECTOR]);
  const selectedSiteId = useSelector(getSiteId);
  const studentDistrictId = useSelector(ShopSelectors.getDistrictId) ?? '';
  const studentSiteId = useSelector(ShopSelectors.getSiteId);
  const userDistrict = useSelector(CommonSelectors.getDistrict(districtId));
  const studentDistrict = useSelector(CommonSelectors.getDistrict(studentDistrictId));

  // User may be a parent with no associated districtId. Student district should be used by default
  const district = studentDistrict ?? userDistrict;

  const [loading, setLoading] = React.useState(false);
  const offerOnlyALaCarteNoPublishedMenus = district?.orderingRules?.offerOnlyALaCarteNoPublishedMenus;
  const { getDistrictMenuTypes, getMenuTypes } = useMenuTypePickerActions(offerOnlyALaCarteNoPublishedMenus);

  useEffect(() => {
    async function loadMenuTypes() {
      setLoading(true);

      if (shouldGetDistrictMenuTypes) {
        await getDistrictMenuTypes(districtId);
      } else {
        if (isParent && studentDistrictId && studentSiteId) {
          await getMenuTypes(studentDistrictId, studentSiteId);
        } else if (isManagerOrDirector && selectedSiteId) {
          await getMenuTypes(districtId, selectedSiteId);
        } else {
          await getMenuTypes(districtId, siteId);
        }
      }
      setLoading(false);
    }
    loadMenuTypes();
  }, [
    districtId,
    isManagerOrDirector,
    isParent,
    selectedSiteId,
    shouldGetDistrictMenuTypes,
    siteId,
    studentDistrictId,
    studentSiteId,
    getDistrictMenuTypes,
    getMenuTypes,
  ]);

  useEffect(
    function defaultToFirstOptionIfEmpty() {
      if (offerOnlyALaCarteNoPublishedMenus) {
        dispatch(setMenuTypeId(UNASSOCIATED_MEAL_TYPE_ID));
      } else if (!menuTypeId && menuTypeOptions.length > 0) {
        const firstOption = menuTypeOptions[0];
        dispatch(setMenuTypeId(firstOption.value));
      } else if (menuTypeId && menuTypeOptions.length > 0) {
        // Set to first option in list if menuTypeId not found
        const hasId = menuTypeOptions.some((option) => option.value === menuTypeId);

        if (!hasId) {
          const firstOption = menuTypeOptions[0];
          dispatch(setMenuTypeId(firstOption.value));
        }
      }
    },
    [dispatch, menuTypeOptions, menuTypeId, offerOnlyALaCarteNoPublishedMenus],
  );

  if (offerOnlyALaCarteNoPublishedMenus) {
    return null;
  }

  return (
    <AutocompleteDropdown
      id="menuTypePicker"
      label="School Menu"
      value={menuTypeId || ''}
      options={menuTypeOptions}
      onChange={(e, selectedOption) => {
        if (menuItemModalOpen) {
          dispatch(CommonActions.setSidebarOpen(false, SidebarLocations.MENU_ITEM_SIDE_BAR));
        }

        dispatch(setMenuTypeId(selectedOption?.value || ''));
      }}
      loading={loading}
      className="menu-type-select"
      disableClearable={true}
      data-test-id="menuTypePickerDropDown"
    />
  );
};

export default MenuTypePicker;
