import { get } from 'lodash';
import { REPORT_SCOPE, REPORT_TIME_FRAME } from 'Constants/Enums';

export interface hasActiveOrdersOptions {
  scope: REPORT_SCOPE;
  timeFrame: REPORT_TIME_FRAME;
  report: any;
  menuTypeId: string;
  studentReport: any;
  siteId: string;
}

/**
 * Check for values based on the REPORT_SCOPE and REPORT_TIME_FRAME
 */
export const hasActiveOrders = ({
  scope,
  timeFrame,
  report,
  menuTypeId,
  studentReport,
  siteId,
}: hasActiveOrdersOptions) => {
  let pass = false;

  const totalsByMenuType = get(report, `totalsByMealType`, {});
  const totalsBySite = get(report, `totalsBySite`, {});
  const ordersByStudent = get(studentReport, 'ordersByStudent', {});
  const bulkOrdersByProduct = get(studentReport, 'bulkOrdersByProduct', {});

  if (timeFrame === REPORT_TIME_FRAME.DAILY) {
    const menuTypeTotals = get(totalsByMenuType, menuTypeId, {});
    const siteTotals = get(totalsBySite, `${siteId}.${menuTypeId}`, {});

    switch (scope) {
      case REPORT_SCOPE.DISTRICT:
        pass = Object.values(menuTypeTotals).some((total) => total);
        break;
      case REPORT_SCOPE.SITE:
        pass = Object.values(siteTotals).some((total) => total);
        break;
      case REPORT_SCOPE.LOCATION:
      case REPORT_SCOPE.LOCATION_GLOBAL:
        pass =
          Object.values(ordersByStudent).some((order: any) => order[menuTypeId]) ||
          Object.keys(bulkOrdersByProduct).length > 0;
        break;
    }
  } else if (
    timeFrame === REPORT_TIME_FRAME.WEEKLY ||
    timeFrame === REPORT_TIME_FRAME.MONTHLY ||
    REPORT_TIME_FRAME.CUSTOM
  ) {
    switch (scope) {
      case REPORT_SCOPE.DISTRICT:
        pass = Object.values(totalsByMenuType[menuTypeId] ?? {}).some((total) => total);
        break;
      case REPORT_SCOPE.SITE:
        const siteMenuTypeTotals = get(totalsBySite, [siteId, menuTypeId], {});
        pass = Object.values(siteMenuTypeTotals).some((total) => total);
        break;
      case REPORT_SCOPE.LOCATION:
      case REPORT_SCOPE.LOCATION_GLOBAL:
        pass =
          Object.values(ordersByStudent).some((studentOrders: any) => Object.keys(studentOrders).length) ||
          Object.keys(bulkOrdersByProduct).length > 0;
        break;
    }
  }

  return pass;
};
