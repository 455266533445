import { IconButton } from '@material-ui/core';
import type { Identifier } from 'dnd-core';
import { DragIndicator } from '@material-ui/icons';
import React, { FC, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { Row } from 'react-table';

interface DraggableRowProps {
  row: Row;
  onHover: (dragIndex: number, hoverIndex: number) => void;
  index: number;
  type: string;
}

export const DraggableRow: FC<DraggableRowProps> = ({ row, onHover, index, type }) => {
  const dragRef = useRef<HTMLButtonElement>(null);
  const dropRef = useRef<HTMLTableRowElement>(null);

  // eslint-disable-next-line
  const [dragProps, drag] = useDrag(
    () => ({
      type,
      item: () => {
        return { row, index };
      },
    }),
    [row],
  );

  const [{ handlerId, isOver }, drop] = useDrop<any, void, { handlerId: Identifier | null; isOver: boolean }>(() => ({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
      };
    },
    hover: (item) => {
      if (dropRef.current && item.index !== index) {
        onHover(item.index, index);
        item.index = index;
      }
    },
  }));

  drop(dropRef);
  drag(dragRef);

  const rowProps = row?.getRowProps ? { ...row.getRowProps() } : null;

  return (
    <tr {...rowProps} ref={dropRef}>
      {row.cells.map((cell: any) => {
        return (
          <td
            {...cell.getCellProps({
              className: cell.column?.className,
            })}
            style={{
              maxWidth: `${cell.column.width}px`,
              minWidth: `${cell.column.width}px`,
              width: `${cell.column.width}`,
            }}
          >
            {cell.render('Cell')}
          </td>
        );
      })}
      <td>
        <IconButton
          ref={dragRef}
          data-handler-id={handlerId}
          className={!isOver ? 'drag-handle-button-inactive' : ''}
          style={isOver ? { backgroundColor: 'rgba(0, 0, 0, 0.2)' } : undefined}
          data-test-id="dragBtn"
        >
          <DragIndicator className="drag-handle" />
        </IconButton>
      </td>
    </tr>
  );
};
