import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState, OrderingRules } from 'Interfaces/Interfaces';
import Card from 'Components/Card/Card';
import * as CommonStateActions from 'State/actions';
import * as CommonStateSelectors from 'State/selectors';
import OrderCommentsSidbar from '../Sidebars/OrderCommentsSidebar';
import { SidebarLocations } from 'Constants/Enums';
import { AllowOrderCommentsLabel, DoNotAllowOrderCommentsLabel } from 'Constants/Constants';

export const RuleOrderComments: FC<{ orderingRules: OrderingRules }> = ({ orderingRules }) => {
  const sidebarOpen = useSelector((state: AppState) =>
    CommonStateSelectors.getSidebarOpen(state, SidebarLocations.ORDERING_COMMENTS_RULE_SIDE_BAR),
  );
  const dispatch = useDispatch();

  const setSidebarOpen = (isOpen: boolean) => {
    dispatch(CommonStateActions.setSidebarOpen(isOpen, SidebarLocations.ORDERING_COMMENTS_RULE_SIDE_BAR));
  };

  return (
    <>
      <Card
        title="Order Comments"
        description="Set rules for order comments"
        onEditClick={() => setSidebarOpen(true)}
        editAriaLabel="Set rule for order comments"
      >
        <div className="ordering-rules__rules">
          <div className="ordering-rules__setting">
            <h6 className="linq-card__h6">Order Comments</h6>
            <div className="setting-value" data-test-id={'allowParentCommentLbl'}>
              {orderingRules?.allowOrderComments ? AllowOrderCommentsLabel : DoNotAllowOrderCommentsLabel}
            </div>
          </div>
        </div>
      </Card>
      <OrderCommentsSidbar open={sidebarOpen} setSidebarOpen={setSidebarOpen} orderingRules={orderingRules} />
    </>
  );
};
