import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AutocompleteDropdown } from 'Components/Form/MaterialForm';
import * as CommonStateSelectors from 'State/selectors';
import * as DistrictReportSelectors from 'Pages/DistrictReports/state/selectors';
import * as DistrictReportsActions from 'Pages/DistrictReports/state/actions';
import { useAuth0 } from '@auth0/auth0-react';
import { TokenClaims } from 'Auth/User';
import { UNASSOCIATED_MEAL_TYPE_ID } from 'Constants/Constants';

const ReportMenuTypePicker: FC<{}> = () => {
  const dispatch = useDispatch();
  const menuTypeOptions = useSelector(DistrictReportSelectors.getReportMenuTypeOptions);
  const menuTypeId = useSelector(DistrictReportSelectors.getReportMenuTypeId);

  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);
  const district = useSelector(CommonStateSelectors.getDistrict(districtId));
  const offerOnlyALaCarteNoPublishedMenus = district?.orderingRules?.offerOnlyALaCarteNoPublishedMenus;

  useEffect(
    function defaultToFirstOptionIfEmpty() {
      if (offerOnlyALaCarteNoPublishedMenus) {
        dispatch(DistrictReportsActions.setReportMenuTypeId(UNASSOCIATED_MEAL_TYPE_ID));
      } else if (!menuTypeId && menuTypeOptions.length > 0) {
        const firstOption = menuTypeOptions[0];
        dispatch(DistrictReportsActions.setReportMenuTypeId(firstOption.value));
      } else if (menuTypeId && menuTypeOptions.length > 0) {
        // Set to first option in list if menuTypeId not found
        const hasId = menuTypeOptions.some((option) => option.value === menuTypeId);
        if (!hasId) {
          const firstOption = menuTypeOptions[0];
          dispatch(DistrictReportsActions.setReportMenuTypeId(firstOption.value));
        }
      }
    },
    [dispatch, menuTypeOptions, menuTypeId, offerOnlyALaCarteNoPublishedMenus],
  );

  if (offerOnlyALaCarteNoPublishedMenus) {
    return null;
  }

  return (
    <AutocompleteDropdown
      id="reportMenuTypePicker"
      label="School Menu"
      options={menuTypeOptions}
      value={menuTypeId || ''}
      onChange={(e, selectedOption) => {
        dispatch(DistrictReportsActions.setReportMenuTypeId(selectedOption?.value || ''));
      }}
      className="menu-type-select"
      disableClearable={true}
    />
  );
};

export default ReportMenuTypePicker;
