import { useAuth0 } from '@auth0/auth0-react';
import { Checkbox } from '@material-ui/core';
import { TokenClaims } from 'Auth/User';
import classNames from 'classnames';
import Button from 'Components/Button/Button';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import Modal from 'Components/Modal/Modal';
import * as Constants from 'Constants/Constants';
import { useFormik } from 'formik';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import * as ShopSelectors from 'Pages/Shop/state/selectors';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useCommonActions } from 'State/actions';
import './DisclaimerModal.less';
import * as DisclaimerModalActions from './state/actions';
import * as DisclaimerModalSelectors from './state/selectors';

const DisclaimerModal = () => {
  const dispatch = useDispatch();
  const student = useSelector(ShopSelectors.getStudent);
  const { user } = useAuth0();
  const { updateStudent } = useCommonActions();
  const subject = TokenClaims.getPrincipalId(user);
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const isOpen = useSelector(DisclaimerModalSelectors.getOpen);
  const showCloseButton = useSelector(DisclaimerModalSelectors.showCloseButton);

  const formik = useFormik({
    initialValues: {
      agree: false,
    },
    onSubmit: (values, { resetForm }) => {
      if (student && !showCloseButton) {
        const newStudent = cloneDeep(student);
        newStudent.acceptedTerms.push({
          parentId: subject,
          timestamp: moment().format('X'),
        });

        updateStudent(newStudent);
      }
      resetForm({ values: { agree: false } });
      dispatch(DisclaimerModalActions.setOpen(false));
    },
  });

  const closeModal = () => {
    dispatch(DisclaimerModalActions.setOpen(false));
  };

  return (
    <Modal open={isOpen} maxWidth={'md'} className="linq-dialog" id="disclaimer-modal">
      <Modal.Title className="linq-dialog__header d-flex align-items-center" disableTypography={true}>
        <h3 className="linq-header">Ingredients and Special Diets Disclaimer</h3>
        <CloseIconButton onClick={closeModal} />
      </Modal.Title>
      <Modal.Content className="linq-dialog__content">
        <p>Important Information about Special Meal Accommodations and Ingredients</p>
        <p>
          TO USE THIS TOOL PLEASE READ AND ACKNOWLEDGE IMPORTANT INFORMATION REGARDING THE USE OF THIS INTERACTIVE MENU
        </p>
        <p>
          This interactive menu is designed to give the user the ability to identify ingredients in menu items that may
          contain selected nutrients and ingredients. This tool should serve ONLY as a general guideline and SHOULD NOT
          be relied on for people with a medical condition or food allergy.
        </p>
        <p>
          <strong>
            IT IS IMPORTANT TO NOTE THAT INGREDIENTS AND MENU ITEMS ARE SUBJECT TO CHANGE OR SUBSTITUTION WITH OR
            WITHOUT NOTICE.
          </strong>
          IN PLANNING AND TREATING ALLERGIES AND MEDICAL CONDITIONS, PLEASE CONTACT THE DISTRICT REGARDING THEIR
          INDIVIDUAL POLICY REGARDING ACCOMMODATIONS FOR SPECIAL DIETARY NEEDS.
        </p>
        <p>
          Please note that the School District, the Nutritional Analysis software provider, or the ISITE Software (the
          interactive menu display software provider) CANNOT guarantee that the information provided is 100% accurate
          and up-to-date. The nutritional and allergen information that is displayed is provided by food manufacturers,
          distributors, and other third parties.
        </p>
        <p>To use this tool, you must first agree to the following terms and conditions.</p>
        <p>
          IF YOU HAVE SPECIFIC ALLERGEN QUESTIONS OR CONCERNS PLEASE CONTACT YOUR SCHOOL’S FOOD AND NUTRITION DEPARTMENT
          DIRECTOR OR DIETITIAN DIRECTLY. THE CONTENT ON THIS WEBSITE IS NOT INTENDED TO BE A SUBSTITUTE FOR DISCUSSIONS
          ABOUT SPECIAL MEAL ACCOMMODATIONS WITH YOUR SCHOOL FOOD SERVICE DIRECTOR.
        </p>
        <p>
          When exact and 100% accurate nutritional or allergen information is critical to you due to a severe allergy,
          diet restriction, or medical condition, please seek further information and guidance from your school food
          service director and do not rely on the information in this interactive web application.
        </p>
        <p>
          If your (or your child's) allergy or condition potentially creates a serious medical risk, you should discuss
          the risks and appropriate precautions for participating in school lunch with both your medical provider and
          your school food service director before participating.
        </p>
        <p>REQUIRED: Medical Statement for Children with Special Dietary Needs</p>
        <p>
          Each special dietary request must be supported by a statement explaining the requested food substitution and
          must be signed by a recognized medical authority. The Medical Statement must include:
        </p>
        <ul>
          <li>An identification of the medical or other special dietary condition which restricts the child’s diet;</li>
          <li>The food or foods to be omitted from the child’s diet; and</li>
          <li>The food or choice of foods to be substituted.</li>
        </ul>
        <p>
          The medical statement must be written and signed by a recognized medical authority. Medical statements
          completed by parents or guardians will not be accepted.
        </p>
        <p>Policy for Special Meal Accommodations & Food Allergies/ Disabilities</p>
        <p>
          USDA Regulation 7 CFR Part 15b requires substitutions or modifications in school meals for children whose
          disabilities restrict their diets. A child with a disability must be provided substitutions in foods when that
          need is supported by a signed statement from a licensed physician. If a child requires a special meal
          accommodation due to a food allergy/intolerance and/or a diagnosed disability, it is the responsibility of the
          child’s parent/guardian to notify the School District’s Food and Nutrition Services Department of the child’s
          situation by submitting a completed Medical Statement to Request Meal Accommodations form (must be signed by a
          recognized medical authority). This form can be requested from the Food and Nutrition Department.
        </p>
        <p>In Cases of Food Allergy</p>
        <p>
          Generally, children with food allergies or intolerances do not have a disability as defined under either
          Section 504 of the Rehabilitation Act or Part B of IDEA, and the school food service may, but is not required
          to, make food substitutions for them. However, when in the licensed physician’s assessment, food allergies may
          result in severe, life-threatening (anaphylactic) reactions, the child’s condition would meet the definition
          of “disability”, and the substitutions prescribed by the licensed physician must be made.
        </p>
        <p>
          In case of severe allergies (i.e. food allergies considered a disability) please contact the Nutrition
          Services Department to discuss your options.
        </p>
        <p>Acceptance of Terms</p>
        <p>
          THESE TERMS AND CONDITIONS OF SERVICE (the “Term’s”) ARE A LEGALLY BINDING AGREEMENT BETWEEN YOU, the
          parent/guardian or student (“You” or “User”); ISITE Software, a Delaware Corporation (“ISITE Software”); and
          the school district whose menu information is displayed (“School District”) (ISITE and School District
          hereafter may be collectively referenced to as “School”) You agree to not use the software (“Software”, which
          shall be defined as any interface, display, code, or graphic deployed on ISITE’s website, mobile, or digital
          applications) until you have fully read these Terms and Conditions of Service, and You agree to be bound by
          the same. Your use of the Software indicates Your acceptance of these Terms and Conditions of Service.
        </p>
        <p>
          You agree that ISITE, its founders, owners, employees, shareholders, board members, affiliates or business
          partners DO NOT make any representations or warranties that the information contained in this Software
          application or the resulting information produced as a result of the usage of this software application, is
          accurate, current, or complete.
        </p>
        <p>
          You agree that the School is not responsible for any errors or omissions, whether the result of the negligence
          or otherwise, in the information or Software, or for the results produced therefrom, or the outcome arising
          out of the use of such information.
        </p>
        <p>
          The information provided through the Software is not a substitute for Your own due diligence or consultation
          with appropriate medical professionals.
        </p>
        <p>
          MENUS, FOOD ITEMS, DESCRIPTIONS, INGREDIENTS, ALLERGEN LISTS, FOOD PREPARATION METHODS, AND OTHER DETAILS
          REGARDING THE FOOD ARE SUBJECT TO FREQUENT CHANGES AND YOU BEAR THE ULTIMATE RESPONSIBILITY OR TAKING THE
          REGULAR OCCURRENCE OF SUCH CHANGES INTO CONSIDERATION BEFORE RELYING ON THE INFORMATION PRODUCED BY THE
          SOFTWARE.
        </p>
        <p>
          BY AGREEING TO USE THIS SOFTWARE, YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT THE SCHOOL IS NOT RESPONSIBLE FOR
          THE RESULTS OF YOUR DECISIONS RESULTING FROM THE USE OF THIS SOFTWARE INCLUDING, BUT NOT LIMITED TO, YOUR
          CHOOSING TO TAKE (OR NOT CHOOSING TO TAKE) A SPECIFIC COURSE OF ACTION BASED ON THE INFORMATION PROVIDED.
        </p>
        <p>THIS SHOULD BE USED AS A GUIDE ONLY:</p>
        <p>
          This Software is intended to serve as a guide only. The purpose of this Software is to provide information
          relative to meal and ingredient options, given an individual’s food preferences. The aim is to provide an
          efficient method to filter through the School’s various menu offerings, (based on the School District’s
          publicly disclosed representations of food ingredients contained in their menu offerings), and, to produce
          instantaneous menus that highlight the details of the ingredients or menu choices for students.
        </p>
        <p>
          You fully understand the software’s GUIDE ONLY spirit and intent. Accordingly, You agree to not assert any
          claims of any manner against the School for any information presented on or through the Software, Furthermore,
          You agree not to present or maintain any claims against School indirectly through any third party, including
          family members, wards, or otherwise.
        </p>
      </Modal.Content>
      <Modal.Actions className="linq-dialog__actions">
        <form
          onSubmit={formik.handleSubmit}
          className={classNames('disclaimer-modal__form', { mobile: isMobile, showClose: showCloseButton })}
        >
          {!showCloseButton && (
            <>
              <div className="disclaimer-modal__checkbox-container">
                <Checkbox
                  tabIndex={0}
                  id="agree"
                  name="agree"
                  onChange={formik.handleChange}
                  checked={formik.values.agree}
                />
                <div>I understand and agree. Do not show this message again.</div>
              </div>
              <Button
                color="primary"
                variant="contained"
                fullWidth={isMobile}
                disabled={!formik.values.agree}
                type="submit"
              >
                Agree
              </Button>
            </>
          )}
          {showCloseButton && (
            <Button color="primary" variant="contained" fullWidth={isMobile} onClick={closeModal}>
              Close
            </Button>
          )}
        </form>
      </Modal.Actions>
    </Modal>
  );
};

export default DisclaimerModal;
