import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Role, isAuthorized, TokenClaims } from 'Auth/User';
import * as CommonStateSelectors from 'State/selectors';
import { useCommonActions } from 'State/actions';
import { PageLoader } from 'Components/Loaders/Loaders';
import * as Interfaces from 'Interfaces/Interfaces';
import { useAuth0 } from '@auth0/auth0-react';
import { getRoutePath, RouteId } from 'Constants/Routes';

export type Validator = { id: string; allowedRoles?: Role[]; userRole?: Role; pass: () => boolean; redirect?: string };
export type Validators = Validator[];

export const isInvalid = (validators: Validators): Validator | undefined => {
  let validator;

  for (let validate in validators) {
    const { userRole, allowedRoles = [], pass } = validators[validate];

    if (isAuthorized(userRole as Role, allowedRoles) && !pass()) {
      validator = validators[validate];
      break;
    }
  }

  return validator;
};

const AccountValidator: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth0();
  const { getHomerooms: getHomeroomsAction } = useCommonActions();
  const userRole = TokenClaims.getRole(user);
  const siteId = TokenClaims.getSiteId(user);
  const homeroomId = TokenClaims.getHomeroomId(user);
  const firstName = user?.given_name;
  const lastName = user?.family_name;
  const userId = TokenClaims.getUserId(user);
  const admin = TokenClaims.getAdmin(user) || TokenClaims.getUserId(user).includes('staff');
  const [loading, setLoading] = React.useState(false);
  const homerooms = useSelector(CommonStateSelectors.getHomerooms);

  // Undefined - Indicates homeroom needs to be determined, null - homeroom was not found
  const [homeroom, setHomeroom] = React.useState<Interfaces.Homeroom | undefined | null>(undefined);

  useEffect(() => {
    async function get() {
      setLoading(true);
      await getHomeroomsAction(siteId);
      setLoading(false);
    }
    if (siteId && userRole === Role.TEACHER) {
      get();
    } else {
      setHomeroom(null);
    }
  }, [siteId, userRole, getHomeroomsAction]);

  useEffect(() => {
    setHomeroom(homerooms[homeroomId] ?? null);
  }, [homeroomId, homerooms, loading]);

  const invalidSession = isInvalid([
    {
      id: 'Teacher missing siteId, homeroomId, or homeroom does not exist',
      userRole,
      allowedRoles: [Role.TEACHER],
      pass: () => !!(siteId && homeroomId && homeroom),
      redirect: getRoutePath(RouteId.ACCOUNT_CONFIGURATION),
    },
    {
      id: 'Manager missing siteId',
      userRole,
      allowedRoles: [Role.MANAGER],
      pass: () => !!siteId,
      redirect: getRoutePath(RouteId.ACCOUNT_CONFIGURATION),
    },
    {
      id: 'Parent missing name.',
      userRole,
      allowedRoles: [Role.PARENT],
      pass: () => (!!firstName && !!lastName) || !!admin || userId.includes('google'),
      redirect: `${getRoutePath(RouteId.PROFILE)}?incomplete`,
    },
  ]);

  if (loading || homeroom === undefined) {
    return <PageLoader />;
  }

  if (
    invalidSession &&
    invalidSession.redirect &&
    location.pathname !== invalidSession.redirect.split('?')[0] &&
    location.pathname !== getRoutePath(RouteId.LOGOUT)
  ) {
    console.warn(`Account Validator Failed: ${invalidSession.id}`);
    navigate(invalidSession.redirect);
    return <></>;
  }

  return <>{children}</>;
};

export default AccountValidator;
