import { useState } from 'react';
import { ActionCreator } from 'redux';
import { Moment } from 'moment-timezone';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import * as Interfaces from 'Interfaces/Interfaces';
import * as Constants from 'Constants/Constants';
import { useAuth0 } from '@auth0/auth0-react';
import { TokenClaims } from 'Auth/User';
import { useApi } from 'Api/useApi';
import { uniqBy } from 'lodash';
import { PrintLabelsStudentNameDisplayOrder } from 'Constants/Enums';
import * as Types from './types';

const CancelToken = axios.CancelToken;
let cancel: any = {};

export const useDistrictReportActions = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);

  const [actions] = useState({
    getReportOrders: async (
      dateFrom: Moment,
      dateTo: Moment,
      includeGlobalPickupLocations = false,
      siteId?: string,
    ) => {
      try {
        if (cancel.report) {
          cancel.report();
        }

        const results = (await api.get(`/utility/reports/districts/${districtId}/orders`, {
          params: {
            dateFrom: dateFrom.format(Constants.DATE_FORMAT_YMD),
            dateTo: dateTo.format(Constants.DATE_FORMAT_YMD),
            globalPickupLocations: includeGlobalPickupLocations,
            siteId,
          },
          cancelToken: new CancelToken(function executor(c) {
            cancel.report = c;
          }),
        })) as any;
        dispatch(setReport(results?.data));
      } catch (ex) {}
    },
    getReportStudents: async (
      dateFrom: Moment,
      dateTo: Moment,
      pickupLocationIds: string[],
      siteId?: string,
      reportMode: Interfaces.ReportMode = Interfaces.ReportMode.Display,
    ) => {
      try {
        if (cancel.studentReport) {
          cancel.studentReport();
        }

        const requests: any[] = [];

        pickupLocationIds.forEach((plId) =>
          requests.push(
            api.get(`/utility/reports/districts/${districtId}/students`, {
              params: {
                reportMode,
                siteId,
                dateFrom: dateFrom.format(Constants.DATE_FORMAT_YMD),
                dateTo: dateTo.format(Constants.DATE_FORMAT_YMD),
                pickupLocationId: plId,
              },
              cancelToken: new CancelToken(function executor(c) {
                cancel.studentReport = c;
              }),
            }),
          ),
        );

        const results = await Promise.all(requests);

        // Merge results if more than 1 pick up location
        if (pickupLocationIds.length > 1) {
          const resultingReport: any = {
            menuTypes: [],
            ordersForPrint: {},
            ordersForLabels: {},
            pickupLocations: {},
            products: {},
            students: {},
            bulkOrdersForPrint: {},
          };
          results.forEach((result) => {
            const report = result.data;
            resultingReport.menuTypes = [...resultingReport.menuTypes, ...report.menuTypes];
            resultingReport.pickupLocations = { ...resultingReport.pickupLocations, ...report.pickupLocations };
            resultingReport.products = { ...resultingReport.products, ...report.products };
            resultingReport.students = { ...resultingReport.students, ...report.students };

            if (report.ordersForLabels) {
              resultingReport.ordersForLabels = { ...resultingReport.ordersForLabels, ...report.ordersForLabels };
            }

            if (report.ordersForPrint) {
              Object.keys(report.ordersForPrint).forEach((key: string) => {
                if (resultingReport.ordersForPrint[key]) {
                  resultingReport.ordersForPrint[key] = {
                    ...resultingReport.ordersForPrint[key],
                    ...report.ordersForPrint[key],
                  };
                } else {
                  resultingReport.ordersForPrint[key] = { ...report.ordersForPrint[key] };
                }
              });
            }

            if (report.bulkOrdersForPrint) {
              Object.keys(report.bulkOrdersForPrint).forEach((key: string) => {
                if (resultingReport.bulkOrdersForPrint[key]) {
                  resultingReport.bulkOrdersForPrint[key] = {
                    ...resultingReport.bulkOrdersForPrint[key],
                    ...report.bulkOrdersForPrint[key],
                  };
                } else {
                  resultingReport.bulkOrdersForPrint[key] = { ...report.bulkOrdersForPrint[key] };
                }
              });
            }
          });
          resultingReport.menuTypes = uniqBy(resultingReport.menuTypes, (menuType: Interfaces.MenuType) => menuType.id);
          dispatch(setReportStudents(resultingReport));
        } else {
          dispatch(setReportStudents(results[0].data));
        }
      } catch (ex) {}
    },
    patchOrderItems: async (orderItems: Interfaces.PatchOrderItem[]) => {
      try {
        if (cancel.patchOrderItems) {
          cancel.patchOrderItems();
        }
        const orderDistrictId = orderItems[0].districtId;
        const response = await api.patch<Interfaces.OrderItem[], Partial<Interfaces.OrderItem>[]>(
          `/districts/${orderDistrictId}/orderItems`,
          orderItems,
          {
            cancelToken: new CancelToken((cancelFn) => {
              cancel.patchOrderItems = cancelFn;
            }),
          },
        );
        dispatch(updateOrderItems(response?.data));
      } catch (ex) {}
    },
  });

  return actions;
};

export const setPickupLocationId: ActionCreator<Interfaces.Action> = (payload: string) => ({
  type: Types.DISTRICT_REPORT_SET_PICKUP_LOCATION_ID,
  payload,
});

export const setSiteId: ActionCreator<Interfaces.Action> = (payload: string) => ({
  type: Types.DISTRICT_REPORT_SET_SITE_ID,
  payload,
});

export const setReport: ActionCreator<Interfaces.Action> = (payload: any) => ({
  type: Types.DISTRICT_REPORT_SET_REPORT,
  payload,
});

export const setReportStudents: ActionCreator<Interfaces.Action> = (payload: any) => ({
  type: Types.DISTRICT_REPORT_SET_REPORT_STUDENTS,
  payload,
});

export const updateOrderItems: ActionCreator<Interfaces.Action> = (payload: Interfaces.OrderItem[]) => ({
  type: Types.DISTRICT_REPORT_PATCH_ORDER_ITEMS,
  payload,
});

export const setReportMenuTypeId: ActionCreator<Interfaces.Action> = (payload: string) => ({
  type: Types.DISTRICT_REPORT_SET_REPORT_MENU_TYPE_ID,
  payload,
});

export const changeBulkOrderQuantity: ActionCreator<Interfaces.Action> = (payload: {
  productId: string;
  menuTypeId: string;
  date: string;
  quantity: number;
}) => ({
  type: Types.DISTRICT_REPORT_CHANGE_BULK_ORDER_QUANTITY,
  payload,
});

export const setPrintLabelsScopeRangeParameters: ActionCreator<Interfaces.Action> = (payload: {
  scope: string;
  timeFrame: string;
  parameters: { sortBy: string[]; studentName: PrintLabelsStudentNameDisplayOrder };
}) => ({
  type: Types.DISTRICT_REPORT_SET_PRINT_LABEL_SCOPE_PARAMETERS,
  payload,
});
