import { District, Newsfeed } from 'Interfaces/Interfaces';
import React from 'react';
import './ParentNewsfeedCard.less';
import { useParentNewsfeeds } from './state/hooks';
import { List, ListItem, Divider } from '@material-ui/core';
import moment from 'moment';
import { DATE_FORMAT_USA, MOBILE_MEDIA_QUERY } from 'Constants/Constants';
import { useNavigate } from 'react-router';
import { InlineLoader } from 'Components/Loaders/Loaders';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { getRoutePath, RouteId } from 'Constants/Routes';

interface ParentNewsfeedCardProps {
  districts: { [key: string]: District };
  districtIds: string[];
}
const ParentNewsfeedCard = ({ districts, districtIds }: ParentNewsfeedCardProps) => {
  const navigate = useNavigate();
  const { loading, data: newsfeeds } = useParentNewsfeeds(districtIds);
  const hasMultipleDistricts = Object.keys(districts).length > 1;
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY });

  return (
    <div className={classNames('linq-card parent-newsfeed-card-container', { mobile: isMobile })}>
      <h3>Parent News</h3>
      <Divider className="parent-newsfeed__divider" />
      <InlineLoader loading={loading} />
      {!loading && newsfeeds.length === 0 && (
        <div className="placeholder">Your Nutrition Department will share news with you here.</div>
      )}
      {!loading && (
        <List component="nav" className="newsfeed-list">
          {newsfeeds.map((newsfeed: Newsfeed, index: number) => {
            const districtId = newsfeed.districtId || '';
            const formattedDate = moment(newsfeed.startDate).format(DATE_FORMAT_USA);
            return (
              <React.Fragment key={newsfeed.id}>
                <ListItem
                  className="newsfeed-item"
                  data-test-id={`${newsfeed.title}.newsTitleBtn`}
                  button
                  onClick={() => {
                    navigate(
                      getRoutePath(RouteId.PARENT_NEWSFEED)
                        .replace(':districtId', newsfeed.districtId || '')
                        .replace(':id', newsfeed.id || ''),
                    );
                  }}
                >
                  <div className="date">{formattedDate}</div>
                  <div className="title">{newsfeed?.title}</div>
                  {hasMultipleDistricts && <div className="district">{districts[districtId]?.name}</div>}
                  <div className="content">{newsfeed.content}</div>
                </ListItem>
                {index !== newsfeeds.length - 1 && <Divider />}
              </React.Fragment>
            );
          })}
        </List>
      )}
    </div>
  );
};

export default ParentNewsfeedCard;
