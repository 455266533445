import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, FieldProps, FormikValues, FormikProps } from 'formik';
import * as yup from 'yup';
import { useAuth0 } from '@auth0/auth0-react';
import { CloudDownload } from '@material-ui/icons';
import { InputField, SelectField } from 'Components/Form/MaterialForm';
import * as Constants from 'Constants/Constants';
import { useCommonActions } from 'State/actions';
import * as CommonSelectors from 'State/selectors';
import { TokenClaims } from 'Auth/User';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import { CalloutCard } from 'Components/CalloutCard/CalloutCard';
import MultiActionCard from 'Components/MultiActionCard/MultiActionCard';

import './Synthesis.less';

const Synthesis = () => {
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);
  const districts = useSelector(CommonSelectors.getDistricts);
  const { updateDistrict } = useCommonActions();

  const [loading, setLoading] = useState(false);
  const district = districts[districtId];

  const validationSchema = yup.object().shape({
    synthesisSisBaseURL: yup.string().required('Field is required'),
    synthesisSisClientId: yup.string().required('Field is required'),
    synthesisSisClientSecret: yup.string().required('Field is required'),
  });

  const handleSubmit = async (values: FormikValues) => {
    setLoading(true);
    const synthesisAuthRecord = {
      synthesisConfig: {
        synthesisSisType: values.synthesisSisType,
        synthesisSisBaseURL: values.synthesisSisBaseURL,
        synthesisSisClientId: values.synthesisSisClientId,
        synthesisSisClientSecret: values.synthesisSisClientSecret,
      },
    };
    await updateDistrict(synthesisAuthRecord);
    setLoading(false);
  };

  const [synthesisSisOptions] = useMemo(() => {
    const mapIdAndName = ({ id: value, name: text }: { id: string; name: string }) => ({ value, text });
    return [Object.values(Constants.SYNTHESIS_SIS_LIST).map(mapIdAndName)];
  }, []);

  return (
    <div className="synthesis-container">
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{
          synthesisDistrictId: district?.synthesisConfig?.synthesisDistrictId || '',
          synthesisSisType:
            district?.synthesisConfig?.synthesisSisType || String(Object.values(Constants.SYNTHESIS_SIS_LIST)[0].id),
          synthesisSisBaseURL: district?.synthesisConfig?.synthesisSisBaseURL || '',
          synthesisSisClientId: '',
          synthesisSisClientSecret: '',
        }}
      >
        {({ resetForm, dirty }: FormikProps<{}>) => (
          <Form>
            <SubHeader title="Configure SyntheSIS Integration" />
            <div className="page-content-wrapper">
              <div className="oo-row">
                <div>
                  <CalloutCard
                    title="About SyntheSIS"
                    details="SyntheSIS is an integration that will pull student/contact data from Student Information Systems
                      into Online Ordering."
                    icon={<CloudDownload />}
                  />
                </div>
                <MultiActionCard
                  title={'SyntheSIS Configuration'}
                  description={'Please enter the needed SIS related information for SyntheSIS'}
                  cancelActionLabel={'Cancel'}
                  onCancelAction={resetForm}
                  cancelActionDisabled={!dirty}
                  cancelActionAriaLabel={'Cancel'}
                  loading={loading}
                  submitLabel={'Save'}
                  submitDisabled={loading}
                  submitAriaLabel={'Submit'}
                >
                  <div className="card-body">
                    <Field name="synthesisDistrictId">
                      {({ field }: FieldProps) => <input type="hidden" name={field.name} value={field.value} />}
                    </Field>
                    <Field name="synthesisSisType">
                      {({ field, meta }: FieldProps) => (
                        <SelectField
                          fieldLabel="District SIS Type"
                          id="synthesisSisType"
                          name="synthesisSisType"
                          value={field.value}
                          label="SIS Type"
                          options={synthesisSisOptions}
                          onChange={field.onChange}
                          autoComplete="off"
                          showOnlyTouchedError={true}
                          hideEmptyError={false}
                          touched={meta.touched}
                          error={!!meta.error}
                          errorMsg={meta.error}
                        />
                      )}
                    </Field>

                    <Field name="synthesisSisBaseURL">
                      {({ field, meta }: FieldProps) => (
                        <InputField
                          id="synthesisSisBaseURL"
                          name="synthesisSisBaseURL"
                          value={field.value}
                          label="SIS Base URL"
                          onChange={field.onChange}
                          autoComplete="off"
                          touched={meta.touched}
                          error={!!meta.error}
                          errorMsg={meta.error}
                        />
                      )}
                    </Field>

                    <Field name="synthesisSisClientId">
                      {({ field, meta }: FieldProps) => (
                        <InputField
                          id="synthesisSisClientId"
                          name="synthesisSisClientId"
                          value={field.value}
                          label="SIS Client Id"
                          onChange={field.onChange}
                          autoComplete="off"
                          type="password"
                          touched={meta.touched}
                          error={!!meta.error}
                          errorMsg={meta.error}
                        />
                      )}
                    </Field>

                    <Field name="synthesisSisClientSecret">
                      {({ field, meta }: FieldProps) => (
                        <InputField
                          id="synthesisSisClientSecret"
                          name="synthesisSisClientSecret"
                          value={field.value}
                          label="SIS Client Secret"
                          onChange={field.onChange}
                          autoComplete="off"
                          type="password"
                          touched={meta.touched}
                          error={!!meta.error}
                          errorMsg={meta.error}
                        />
                      )}
                    </Field>
                  </div>
                </MultiActionCard>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Synthesis;
