import { createStore, compose } from 'redux';
import { omit } from 'lodash';
import { persistStore, persistReducer, createTransform, createMigrate } from 'redux-persist';
import { MigrationManifest, PersistedState } from 'redux-persist/es/types';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
import { REPORT_SCOPE } from 'Constants/Enums';

import rootReducer from './rootReducer';

const isDev = process.env.NODE_ENV.trim() !== 'production';

const blocklistPaths = [
  'adminState',
  'addStudentModalState',
  'switchStudentCartModalState',
  'unlinkStudentModalState',
  'menuItemModalState.showMenuItemModal',
  'districtState.reimbursableMealPrices',
  'districtState.logs',
  'districtReportsState.report',
  'districtReportsState.reportStudents',
  'dateRangeNavigatorState',
  'commonState',
  'headerState',
  'teacherReportState.report',
  'parentState',
  'authState.refreshStatus',
  'disclaimerModalState',
  'districtReportsState.pickupLocationId',
  'districtReportsState.siteId',
  'districtReportsState.reportMenuTypeId',
];

const migrations: MigrationManifest = {
  // Clears the cart state to avoid stale data structure from rehydration being used
  0: (state: PersistedState) => {
    return {
      ...state,
      cartState: undefined,
      _persist: { version: 0, rehydrated: state?._persist?.rehydrated ?? false },
    };
  },
  1: (state: PersistedState) => {
    return {
      ...state,
      authState: undefined,
      _persist: { version: 1, rehydrated: state?._persist?.rehydrated ?? false },
    };
  },
  2: (state: PersistedState) => {
    return {
      ...state,
      teacherReportState: undefined,
      _persist: { version: 2, rehydrated: state?._persist?.rehydrated ?? false },
    };
  },
  3: (state: PersistedState) => {
    const appState = state as any;
    return {
      ...state,
      teacherReportState: { ...appState.teacherReportState, bulkCart: {} },
      _persist: { version: 3, rehydrated: state?._persist?.rehydrated ?? false },
    };
  },
  4: (state: PersistedState) => {
    const appState = state as any;
    return {
      ...state,
      teacherReportState: { ...appState.teacherReportState, cartHomerooms: {} },
      _persist: { version: 4, rehydrated: state?._persist?.rehydrated ?? false },
    };
  },
  5: (state: PersistedState) => {
    const appState = state as any;
    return {
      ...state,
      districtReportsState: {
        ...appState.districtReportsState,
        printLabelParameters: {
          [REPORT_SCOPE.LOCATION]: {
            range: {},
            day: {},
          },
          [REPORT_SCOPE.LOCATION_GLOBAL]: {
            range: {},
            day: {},
          },
          [REPORT_SCOPE.SITE]: {
            range: {},
            day: {},
          },
        },
      },
      _persist: { version: 5, rehydrated: state?._persist?.rehydrated ?? false },
    };
  },
};

const persistConfig = {
  key: 'root',
  storage,
  blacklist: blocklistPaths.filter((a) => !a.includes('.')),
  transforms: [
    createTransform((inbound: object, key: string | number) => {
      const blocklistPaths_forKey = blocklistPaths
        .filter((path) => path.startsWith(`${key}.`))
        .map((path) => path.substr(key.toString().length + 1));
      return omit(inbound, ...blocklistPaths_forKey);
    }),
  ],
  version: 5,
  migrate: createMigrate(migrations, { debug: isDev }),
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const ConfigureStore = (): any => {
  const store = isDev ? createStore(persistedReducer, composeWithDevTools()) : createStore(persistedReducer, compose());
  const persistor = persistStore(store);
  return { store, persistor };
};

export default ConfigureStore;
