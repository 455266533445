import { Reducer } from 'redux';
import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';

const initialState: Interfaces.ShopState = {
  studentId: null,
  cutoffWarningVisible: true,
  aLaCarteWarningVisible: true,
  reimbursableWarningVisible: true,
  errors: [],
  siteId: null,
  districtId: null,
};

const ShopReducer: Reducer<Interfaces.ShopState, Interfaces.Action> = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SHOP_SET_STUDENT_ID:
      return {
        ...state,
        studentId: payload,
      };

    case Types.SHOP_SET_SITE_ID:
      return { ...state, siteId: payload };

    case Types.SHOP_SET_DISTRICT_ID:
      return { ...state, districtId: payload };

    case Types.SHOW_CUTOFF_WARNING:
      return {
        ...state,
        cutoffWarningVisible: payload,
      };

    case Types.SHOW_ALACARTE_WARNING:
      return {
        ...state,
        aLaCarteWarningVisible: payload,
      };

    case Types.SHOW_REIMBURSABLE_WARNING:
      return {
        ...state,
        reimbursableWarningVisible: payload,
      };

    case Types.SHOP_SET_ERRORS:
      return {
        ...state,
        errors: payload,
      };

    default:
      return state;
  }
};

export default ShopReducer;
