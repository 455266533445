import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';

import * as Interfaces from 'Interfaces/Interfaces';
import { useApi } from 'Api/useApi';
import { UNASSOCIATED_MEAL_TYPE_ID } from 'Constants/Constants';

import * as Types from './types';

export const setMenuTypeId = (payload: string | undefined) => ({
  type: Types.MENU_TYPE_PICKER_SET_MEAL_TYPE_ID,
  payload,
});

export const setMenuTypes = (payload: { [key: string]: Interfaces.MenuType } | Interfaces.MenuType[]) => ({
  type: Types.MENU_TYPE_PICKER_SET_MEAL_TYPES,
  payload,
});

export const useMenuTypePickerActions = (offerOnlyALaCarteNoPublishedMenus = false) => {
  const api = useApi();
  const dispatch = useDispatch();
  const [actions] = useState({
    getMenuTypes: async (districtId: string, siteId: string) => {
      try {
        if (offerOnlyALaCarteNoPublishedMenus) {
          dispatch(setMenuTypeId(UNASSOCIATED_MEAL_TYPE_ID));
        } else if (districtId && siteId) {
          const results: AxiosResponse<Interfaces.CollectionResponse<Interfaces.MenuType>> = await api.get(
            `/districts/${districtId}/sites/${siteId}/menuTypes`,
          );
          dispatch(setMenuTypes(results?.data?.items));
        }
      } catch (err) {
        console.error(err);
      }
    },
    getDistrictMenuTypes: async (districtId: string) => {
      try {
        const results: AxiosResponse<Interfaces.CollectionResponse<Interfaces.MenuType>> = await api.get(
          `/districts/${districtId}/menuTypes`,
        );
        dispatch(setMenuTypes(results?.data?.items));
      } catch (err) {
        console.error(err);
      }
    },
  });

  return actions;
};
