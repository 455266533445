import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes as RoutesCore, Route, useLocation } from 'react-router-dom';

import { Admin, isAdminAuthorized, TokenClaims } from 'Auth/User';
import { getRouteProps, RouteId, Routes as RoutesMap } from './constants/Routes';
import { getShowMenuItemModal } from 'Components/Shop/MenuItemModal/state/selectors';
import { getShowCart, getShowPickup } from 'Components/Shop/Cart/state/selectors';
import { closeAllSlideoutsAndModals } from 'State/actions';
import { getMenuVisible } from 'Components/Header/state/selectors';
import { ProtectedRouteElement } from 'Components/ProtectedRouteElement/ProtectedRouteElement';
import RouteElement from 'Components/RouteElement/RouteElement';
import { useAuth0 } from '@auth0/auth0-react';
import DashboardByRole from 'Components/DashboardByRole/DashboardByRole';
import EditNewsfeed from 'Components/EditNewsfeed/EditNewsfeed';
import NewsFeedManagement from 'Components/NewsfeedManagement/NewsfeedManagement';
import AccountConfiguration from 'Pages/AccountConfiguration/AccountConfiguration';
import ALaCarte from 'Pages/ALaCarte/ALaCarte';
import DistrictDashboard from 'Pages/DistrictDashboard/DistrictDashboard';
import DistrictPrintLabels from 'Pages/DistrictPrintLabels/DistrictPrintLabels';
import DistrictPrintReport from 'Pages/DistrictPrintReport/DistrictPrintReport';
import DistrictReports from 'Pages/DistrictReports/DistrictReports';
import ErrorPage from 'Pages/ErrorPage/ErrorPage';
import ImportData from 'Pages/ImportData/ImportData';
import Login from 'Pages/Login/Login';
import Logout from 'Pages/Logout/Logout';
import OrderingRules from 'Pages/OrderingRules/OrderingRules';
import ParentDashboard from 'Pages/ParentDashboard/ParentDashboard';
import ParentNewsfeed from 'Pages/ParentNewsfeed/ParentNewsfeed';
import PaymentProvider from 'Pages/PaymentProvider/PaymentProvider';
import PickupLocations from 'Pages/PickupLocations/PickupLocations';
import PricingManagement from 'Pages/PricingManagement/PricingManagement';
import OrderConfirmation from 'Pages/Shop/OrderConfirmation';
import Shop from 'Pages/Shop/Shop';
import StudentTable from 'Pages/StudentTable/StudentTable';
import Synthesis from 'Pages/Synthesis/Synthesis';
import TeacherRosterOrdering from 'Pages/TeacherRosterOrdering/TeacherRosterOrdering';
import TeacherRosterOrderingPrint from 'Pages/TeacherRosterOrderingPrint/TeacherRosterOrderingPrint';
import UserManagement from 'Pages/UserManagement/UserManagement';
import { ERROR_TYPE } from 'Constants/Constants';
import TransactionHistory from 'Pages/TransactionHistory/TransactionHistory';
import SiteInformation from 'Pages/SiteInformation/SiteInformation';
import CloseSchoolYear from 'Pages/CloseSchoolYear/CloseSchoolYear';
import Register from 'Pages/Register/Register';
import LandingPage from 'Pages/LandingPage/LandingPage';
import Profile from 'Pages/Profile/Profile';
import PreviewMenus from 'Pages/PreviewMenus/PreviewMenus';
import ShopPreview from 'Pages/Shop/ShopPreview';

const RouteComponents: Record<string, any> = {
  [RouteId.ACCOUNT_CONFIGURATION]: AccountConfiguration,
  [RouteId.STUDENT_MANAGEMENT]: StudentTable,
  [RouteId.DISTRICT_DASHBOARD]: DistrictDashboard,
  [RouteId.DISTRICT_PRINT_LABELS]: DistrictPrintLabels,
  [RouteId.DISTRICT_PRINT_REPORT]: DistrictPrintReport,
  [RouteId.DISTRICT_REPORTS]: DistrictReports,
  [RouteId.IMPORT_DATA]: ImportData,
  [RouteId.A_LA_CARTE]: ALaCarte,
  [RouteId.USER_MANAGEMENT]: UserManagement,
  [RouteId.SYNTHESIS]: Synthesis,
  [RouteId.LOGOUT]: Logout,
  [RouteId.ORDERING_RULES]: OrderingRules,
  [RouteId.PARENT_DASHBOARD]: ParentDashboard,
  [RouteId.PAYMENT_PROVIDER]: PaymentProvider,
  [RouteId.PICKUP_LOCATIONS]: PickupLocations,
  [RouteId.PRICING_MANAGEMENT]: PricingManagement,
  [RouteId.ROOT]: DashboardByRole,
  [RouteId.SHOP]: Shop,
  [RouteId.TEACHER_DASHBOARD]: TeacherRosterOrdering,
  [RouteId.TEACHER_ROSTER_ORDERING_PRINT]: TeacherRosterOrderingPrint,
  [RouteId.ORDER_CONFIRMATION]: OrderConfirmation,
  [RouteId.ERROR_STANDARD]: ErrorPage,
  [RouteId.ERROR_PERMISSION_DENIED]: ErrorPage,
  [RouteId.ERROR_FAIL]: ErrorPage,
  [RouteId.LOGIN]: Login,
  [RouteId.EDIT_NEWS_FEED]: EditNewsfeed,
  [RouteId.NEWS_FEED_MANAGEMENT]: NewsFeedManagement,
  [RouteId.PARENT_NEWSFEED]: ParentNewsfeed,
  [RouteId.TRANSACTION_HISTORY]: TransactionHistory,
  [RouteId.SITE_INFORMATION]: SiteInformation,
  [RouteId.CLOSE_SCHOOL_YEAR]: CloseSchoolYear,
  [RouteId.REGISTER]: Register,
  [RouteId.LANDING_PAGE]: LandingPage,
  [RouteId.PROFILE]: Profile,
  [RouteId.DIRECTOR_PROFILE]: Profile,
  [RouteId.PREVIEW_MENUS]: PreviewMenus,
  [RouteId.SHOP_PREVIEW]: ShopPreview,
};

const Routes: FC<{}> = () => {
  const dispatch = useDispatch();
  const menuVisible = useSelector(getMenuVisible);
  const showMenuItemModal = useSelector(getShowMenuItemModal);
  const showCart = useSelector(getShowCart);
  const showPickUp = useSelector(getShowPickup);
  const location = useLocation();
  const { user } = useAuth0();
  const adminClaim = TokenClaims.getAdmin(user);
  const hasAdminAccess = isAdminAuthorized(adminClaim, [Admin.SUPER, Admin.DIRECTOR]);

  /**
   * Auto close any menu on path change.
   *
   * Toggles:
   * adminState -> showSidebar
   * headerState -> menuVisible
   */
  React.useEffect(() => {
    if (menuVisible || showMenuItemModal || showCart || showPickUp) {
      dispatch(closeAllSlideoutsAndModals());
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <RoutesCore>
      {Object.keys(RoutesMap).map((routeId) => {
        const { path, allowedRoles, adminSidebar, adminLevels, isPrintable, hideParentFooter, props } = getRouteProps(
          parseInt(routeId, 10) as RouteId,
        );
        const Component = RouteComponents[routeId];

        if (!Component) {
          throw new Error('Missing Component mapping for Route definition');
        }

        return path.map((p, pI) => {
          return (
            <Route
              key={pI}
              path={p}
              element={
                allowedRoles ? (
                  <ProtectedRouteElement
                    adminSidebar={hasAdminAccess && adminSidebar}
                    isPrintable={isPrintable}
                    allowedRoles={allowedRoles}
                    adminLevels={adminLevels}
                    component={Component}
                    hideParentFooter={hideParentFooter}
                    {...props}
                  ></ProtectedRouteElement>
                ) : (
                  <RouteElement
                    adminSidebar={hasAdminAccess && adminSidebar}
                    isPrintable={isPrintable}
                    hideParentFooter={hideParentFooter}
                  >
                    <Component {...props} />
                  </RouteElement>
                )
              }
            />
          );
        });
      })}
      <Route
        path="*"
        element={
          <RouteElement>
            <ErrorPage errorType={ERROR_TYPE.UNKNOWN} />
          </RouteElement>
        }
      />
    </RoutesCore>
  );
};

export default Routes;
