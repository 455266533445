import React from 'react';
import { useDispatch, batch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { FormControl, ListSubheader, MenuItem } from '@material-ui/core';
import { updatePickupLocations } from 'Components/Shop/Cart/state/actions';
import * as Constants from 'Constants/Constants';
import { SelectField } from 'Components/Form/MaterialForm';
import { sortAndGroupPickupLocations } from 'Helpers/group';
import { PickupLocation } from 'Interfaces/Interfaces';

export const PickupByDateItem = (props: any) => {
  const { items, mealType, existingLocations, options } = props;
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });

  function onSelectPickupLocation(pickupLocationId: string) {
    if (pickupLocationId !== 'undefined') {
      batch(() => {
        items.forEach((item: any) => dispatch(updatePickupLocations(item.id, pickupLocationId)));
      });
    }
  }

  const firstItem = items[0];
  const existingLocation = existingLocations[firstItem.id];
  const pickupLocationsByScope = sortAndGroupPickupLocations(options[firstItem.id]);

  return (
    <div className={classNames({ item: true, mobile: isMobile })} key={mealType}>
      <FormControl fullWidth>
        <SelectField
          name="pickupLocation"
          id={`pickupLocation-${mealType}`}
          value={existingLocation || ''}
          fieldLabel={`Pickup Location`}
          onChange={(e) => onSelectPickupLocation(String(e.target.value))}
          open={true}
        >
          {Object.entries(pickupLocationsByScope).map(([labelName, pls]: [string, PickupLocation[]]) => {
            if (!pls) {
              return null;
            }

            return [
              <ListSubheader value={pls[0]?.id}>{Constants.GROUP_LABELS[labelName]}</ListSubheader>,
              ...pls.map((item) => (
                <MenuItem className="select-field__menu-option" value={item.id}>
                  {item.name}
                </MenuItem>
              )),
            ];
          })}
        </SelectField>
      </FormControl>
    </div>
  );
};
