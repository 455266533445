import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from 'Api/useApi';
import { CollectionResponse, Newsfeed } from 'Interfaces/Interfaces';
import { AxiosResponse } from 'axios';
import { OutputFormat, DATE_FORMAT_YMD } from 'Constants/Constants';
import { sortBy } from 'lodash';
import moment from 'moment';

interface NewsfeedState {
  data: Newsfeed[];
  error: any;
  loading: boolean;
}

export const useParentNewsfeeds = (districtIds: string[]) => {
  const api = useApi();
  const dispatch = useDispatch();
  const [state, setState] = useState<NewsfeedState>({
    data: [],
    error: null,
    loading: false,
  });

  useEffect(() => {
    async function getNewsfeeds() {
      let newsfeeds: Newsfeed[] = [];
      let next: string | undefined;
      try {
        setState((currState) => ({ ...currState, loading: true }));
        const currentDate = moment().format(DATE_FORMAT_YMD);
        for (const districtId of districtIds) {
          do {
            const response: AxiosResponse<CollectionResponse<Newsfeed>> = await api.get(
              `/districts/${districtId}/newsfeeds`,
              {
                params: {
                  next,
                  limit: 100,
                  outputFormat: OutputFormat.Array,
                  dateFrom: currentDate,
                },
              },
            );
            newsfeeds = newsfeeds.concat(response.data.items);
            next = response.data.next;
          } while (next);
        }

        const sortedNewsfeeds = sortBy(newsfeeds, 'sortIndex');
        setState((currState) => ({ ...currState, data: sortedNewsfeeds, error: null }));
      } catch (err) {
        setState((currState) => ({ ...currState, error: err }));
      } finally {
        setState((currState) => ({ ...currState, loading: false }));
      }
    }

    if (districtIds.length > 0) {
      getNewsfeeds();
    }
  }, [api, dispatch, districtIds]);

  return state;
};
