import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from 'Api/useApi';

import {
  setReimbursableMealPrices,
  setReimbursableMealPricesByGrade,
  setReimbursableMealPricingModes,
} from './actions';

export const useReimbursableMealPricing = (districtId: string, menuTypeId: string) => {
  const api = useApi();
  const dispatch = useDispatch();

  useEffect(() => {
    async function getPricesBySite() {
      try {
        const params = new URLSearchParams({ menuTypeId });
        const results = (await api.get(`/districts/${districtId}/reimbursableMealPrices?${params}`)) as any;
        dispatch(setReimbursableMealPrices(results.data?.items));
      } catch (ex) {
        console.log(ex);
      }
    }
    async function getPricesBygrade() {
      try {
        const params = new URLSearchParams({ menuTypeId });
        const results = (await api.get(`/districts/${districtId}/reimbursableMealPricesByGrade?${params}`)) as any;
        dispatch(setReimbursableMealPricesByGrade(results.data?.items));
      } catch (ex) {
        console.log(ex);
      }
    }
    async function getPricingModes() {
      try {
        const params = new URLSearchParams({ menuTypeId });
        const results = (await api.get(`/districts/${districtId}/reimbursableMealPricingMode?${params}`)) as any;
        dispatch(setReimbursableMealPricingModes(results.data?.items));
      } catch (ex) {
        console.log(ex);
      }
    }
    if (menuTypeId) {
      getPricingModes();
      getPricesBySite();
      getPricesBygrade();
    }
  }, [dispatch, api, districtId, menuTypeId]);
};
