import { ShopError } from 'Interfaces/Interfaces';
import * as Types from './types';

export const setStudentId = (payload: string) => ({
  type: Types.SHOP_SET_STUDENT_ID,
  payload,
});

export const showCutoffWarning = (payload: boolean) => ({
  type: Types.SHOW_CUTOFF_WARNING,
  payload,
});

export const showALaCarteWarning = (payload: boolean) => ({
  type: Types.SHOW_ALACARTE_WARNING,
  payload,
});

export const setErrors = (payload: ShopError[] | null) => ({
  type: Types.SHOP_SET_ERRORS,
  payload,
});

export const showReimbursableWarning = (payload: boolean) => ({
  type: Types.SHOW_REIMBURSABLE_WARNING,
  payload,
});

export const setDistrictId = (payload: string) => ({
  type: Types.SHOP_SET_DISTRICT_ID,
  payload,
});

export const setSiteId = (payload: string) => ({
  type: Types.SHOP_SET_SITE_ID,
  payload,
});
