import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Checkbox } from '@material-ui/core';
import { get } from 'lodash';
import { useApi } from 'Api/useApi';
import { TokenClaims } from 'Auth/User';
import { createLabelReportQueryParams, downloadAsCsv } from 'Helpers/Helper';
import Button from 'Components/Button/Button';
import * as Constants from 'Constants/Constants';
import * as Interfaces from 'Interfaces/Interfaces';
import ReactTable from 'Components/ReactTable/ReactTable';
import { ButtonLink } from 'Components/ButtonLink/ButtonLink';
import { useTableCheckboxSelection } from 'Helpers/hooks';
import * as DistrictReportsActions from 'Pages/DistrictReports/state/actions';
import * as DistrictReportsSelectors from 'Pages/DistrictReports/state/selectors';
import { doesRowHaveOrderItems, sortItems } from 'Pages/DistrictReports/ReportUtil';
import * as DateRangeNavigatorSelectors from 'Components/DateRangeNavigator/state/selectors';
import PrintLabelsOptionsModal from '../PrintLabelsOptionsModal/PrintLabelsOptionsModal';

import './SiteReportTable.less';

const SiteReportTable = (props: Interfaces.SiteReportTableProps): ReactElement => {
  const { report, viewByDateRange, scope } = props;
  const dispatch = useDispatch();
  const api = useApi();
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);
  const dayRange = useSelector(DateRangeNavigatorSelectors.getDayRange);
  const startDate = useSelector(DateRangeNavigatorSelectors.getStartDate);
  const siteId = useSelector(DistrictReportsSelectors.getSiteId);
  const defaultMenuType = useSelector(DistrictReportsSelectors.getDefaultMenuType(districtId));
  const menuTypeId = useSelector(DistrictReportsSelectors.getReportMenuTypeId) || defaultMenuType.id;
  const products = get(report, 'products', {});
  const pickupLocations = get(report, 'pickupLocations', {});
  const pickupLocationIds = get(report, `sites.${siteId}.pickupLocationIds`, []);
  const totalsByPickupLocation = get(report, `totalsByPickupLocation`, {});
  const totalsByMenuType = get(report, 'totalsByMealType', {});
  const siteTotals = get(report, `totalsBySite.${siteId}`, {});
  const menuTypeTotals = totalsByMenuType[menuTypeId] ?? {};
  const [printLabelOptionsModalOpen, setPrintLabelsOptionsModalOpen] = React.useState(false);
  const printLabelScopeRangeParameters = useSelector(
    DistrictReportsSelectors.getPrintLabelScopeRangeParameters(scope, viewByDateRange),
  );

  const handlePickupLocationClick = (pickupLocationId: string) => {
    dispatch(DistrictReportsActions.setPickupLocationId(pickupLocationId));
  };

  const { selectedIds, handleAllCheckboxSelection, handleCheckboxSelection, setSelectedIds } =
    useTableCheckboxSelection();

  // All pickup locations should be initially checked
  React.useEffect(() => {
    const newSelectedIds: { [key: string]: any } = {};
    pickupLocationIds.forEach((id: any) => (newSelectedIds[id] = true));

    setSelectedIds(newSelectedIds);
  }, [report, handleAllCheckboxSelection, pickupLocationIds, setSelectedIds]);

  const disablePrintButtons = Object.values(selectedIds).length === 0;

  let dateFrom = startDate;
  let dateTo = startDate;

  if (viewByDateRange) {
    dateFrom = dayRange.startDate.clone();
    dateTo = dayRange.endDate.clone();
  }

  const productIds = Object.keys(get(report, `totalsBySite.${siteId}.${menuTypeId}`, {}));
  const menuTypeSiteTotals = siteTotals[menuTypeId] ?? {};

  const columns = [] as any;
  const productColumns = {} as any;

  sortItems(Object.keys(menuTypeTotals), (x) => products[x]).forEach((productId: any) => {
    const product = products[productId];
    const productTotal = menuTypeSiteTotals[productId];
    if (productTotal > 0) {
      productColumns[product.id] = product.name;
    }
  });

  Object.entries(productColumns)
    .reverse()
    .forEach(([productId, productName]: any) => {
      columns.unshift({
        Header: productName,
        accessor: productId,
        dataTestId: (row: any) => `${row.original.pickupLocationName}.${productName}.totalLbl`,
      });
    });

  columns.unshift({
    Header: 'Pickup Location',
    accessor: 'pickupLocationName',
    Cell: (props: any) => {
      return props.row.original.pickupLocationId;
    },
    width: 300,
  });

  columns.unshift({
    accessor: 'id',
    disableSortBy: true,
    Header: (rowProps: any) => {
      const rows = rowProps.data;
      const allChecked = rows.length > 0 && rows.every((row: any) => selectedIds[row.id]);

      return (
        <Checkbox
          checked={allChecked}
          onChange={(e) => {
            handleAllCheckboxSelection(!allChecked, rowProps.rows, 'id');
          }}
          color="primary"
          // @ts-ignore
          // For automation testing id.
          inputProps={{ 'data-test-id': 'selectAllCheckbox' }}
        />
      );
    },

    Cell: (rowProps: any) => {
      const pickupLocationId = rowProps.row.original.id;

      return (
        <Checkbox
          checked={selectedIds[pickupLocationId]}
          onClick={(e) => {
            handleCheckboxSelection(pickupLocationId, e.shiftKey, rowProps.sortedRows, 'id');
          }}
          color="primary"
          // @ts-ignore
          // For automation testing id.
          inputProps={{ 'data-test-id': 'selectPickupLocationCheckbox' }}
        />
      );
    },
  });

  const data = pickupLocationIds.reduce((acc: any, pickupLocationId: any) => {
    const pickupLocation = pickupLocations[pickupLocationId];

    if (productIds.length > 0) {
      const row = productIds.reduce((acc: any, productId: any) => {
        const pickupLocationTotal = totalsByPickupLocation[pickupLocation.id];
        let total = 0;
        total = get(pickupLocationTotal, `${menuTypeId}.${productId}`, 0);

        acc[productId] = total || '--';
        return acc;
      }, {} as { [key: string]: any });
      const hasOrderedItems = doesRowHaveOrderItems(row);

      if (hasOrderedItems) {
        row['pickupLocationName'] = pickupLocation.name;
        row['pickupLocationId'] = (
          <button
            className="btn-reset item link"
            onClick={() => handlePickupLocationClick(pickupLocation.id)}
            data-test-id={`${pickupLocation.name}.reportBtn`}
          >
            {pickupLocation.name}
          </button>
        );
        row['id'] = pickupLocationId;
        acc.push(row);
      }
    }
    return acc;
  }, [] as any);

  const onExportCSVClick = async () => {
    try {
      let from = startDate;
      let to = startDate;

      if (viewByDateRange) {
        from = dayRange.startDate;
        to = dayRange.endDate;
      }
      const results = (await api.get(`/utility/reports/districts/${districtId}/orders`, {
        params: {
          dateFrom: from.format(Constants.DATE_FORMAT_YMD),
          dateTo: to.format(Constants.DATE_FORMAT_YMD),
          globalPickupLocations: true,
          reportMode: Interfaces.ReportMode.Csv,
          siteId,
          menuTypeId,
        },
      })) as any;
      downloadAsCsv(results.data, 'site-report.csv');
    } catch (ex) {}
  };

  const queryParams = React.useMemo(() => {
    return createLabelReportQueryParams(
      menuTypeId,
      siteId,
      Object.keys(selectedIds).map((id) => id),
      dateFrom,
      dateTo,
      printLabelScopeRangeParameters.studentName,
      printLabelScopeRangeParameters.sortBy,
    );
  }, [menuTypeId, siteId, printLabelScopeRangeParameters, dateFrom, dateTo, selectedIds]);

  return (
    <div className="site-report-table-container">
      <ReactTable
        initialState={initialState}
        columns={columns}
        data={data}
        bottomLeftContainer={
          <div className="report-buttons">
            <ButtonLink
              href={`/districtprintreport?${queryParams}`}
              target="_blank"
              rel="noopener noreferrer"
              text="Print Report"
              data-test-id="printReportBtn"
              disabled={disablePrintButtons}
            />
            <Button
              variant="text"
              color="primary"
              onClick={() => setPrintLabelsOptionsModalOpen(true)}
              disabled={disablePrintButtons}
            >
              Print Labels
            </Button>
            <Button onClick={onExportCSVClick} variant="text" color="primary" data-test-id="siteExportCsvBtn">
              Site Export CSV
            </Button>
          </div>
        }
      />
      <PrintLabelsOptionsModal
        open={printLabelOptionsModalOpen}
        onClose={() => setPrintLabelsOptionsModalOpen(false)}
        onPrint={() => {
          window.open(`${window.origin}/districtprintlabels?${queryParams}`);
          setPrintLabelsOptionsModalOpen(false);
        }}
        scope={scope}
        viewByDateRange={viewByDateRange}
      />
    </div>
  );
};

const initialState = {
  sortBy: [{ id: 'pickupLocationName' }],
};

export default SiteReportTable;
