export const TEACHER_REPORT_SET_REPORT = 'TEACHER_REPORT_SET_REPORT';
export const TEACHER_REPORT_SET_ENTREE_SIDEBAR_OPEN = 'TEACHER_REPORT_SET_ENTREE_SIDEBAR_OPEN';
export const TEACHER_REPORT_SELECT_ENTREE = 'TEACHER_REPORT_SELECT_ENTREE';
export const TEACHER_REPORT_SET_SELECTED_SIDES = 'TEACHER_REPORT_SET_SELECTED_SIDES';
export const TEACHER_REPORT_SET_SHOW_CART = 'TEACHER_REPORT_SET_SHOW_CART';
export const TEACHER_REPORT_ADD_TO_CART = 'TEACHER_REPORT_ADD_TO_CART';
export const TEACHER_REPORT_REMOVE_FROM_CART = 'TEACHER_REPORT_REMOVE_FROM_CART';
export const TEACHER_REPORT_CLEAR_CART = 'TEACHER_REPORT_CLEAR_CART';
export const TEACHER_REPORT_CLEAR_BULK_CART = 'TEACHER_REPORT_CLEAR_BULK_CART';
export const TEACHER_REPORT_REMOVE_CART_DATE = 'TEACHER_REPORT_REMOVE_CART_DATE';
export const TEACHER_REPORT_DELETE_ORDER_ITEM = 'TEACHER_REPORT_DELETE_ORDER_ITEM';
export const TEACHER_REPORT_SET_REPORT_PRODUCTS = 'TEACHER_REPORT_SET_REPORT_PRODUCTS';
export const TEACHER_REPORT_SET_BULK_MODE = 'TEACHER_REPORT_SET_BULK_MODE';
export const TEACHER_REPORT_ADD_TO_BULK_CART = 'TEACHER_REPORT_ADD_TO_BULK_CART';
export const TEACHER_REPORT_REMOVE_BULK_ITEM = 'TEACHER_REPORT_REMOVE_BULK_ITEM';
export const TEACHER_REPORT_ADD_OR_SUBTRACT_BULK_ITEM_QUANTITY = 'TEACHER_REPORT_ADD_OR_SUBTRACT_BULK_ITEM_QUANTITY';
