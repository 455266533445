import { get, uniqBy } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import * as Interfaces from 'Interfaces/Interfaces';
import * as CommonSelectors from 'State/selectors';
import * as Constants from 'Constants/Constants';

const root = (state: Interfaces.AppState) => get(state, 'studentcardliststate');

export const getWeekOfYear = (state: Interfaces.AppState): number => get(root(state), 'weekOfYear', moment().week());

export const getDayRange = createSelector([getWeekOfYear], (weekOfYear) => {
  const today = moment().week(weekOfYear);
  const startDate = today.clone().startOf('week').add(1, 'd');
  const endDate = today.clone().endOf('week').subtract(1, 'd');
  const dates = [];
  let currentDate = startDate.clone();
  while (currentDate.isSameOrBefore(endDate)) {
    dates.push(currentDate);
    currentDate = currentDate.clone().add(1, 'd');
  }
  return { startDate, endDate, dates };
});

export const getStudentOrders = createSelector(
  [CommonSelectors.getOrderItems, (_: Interfaces.AppState, props: Interfaces.StudentCardProps) => props],
  (orders, props) => {
    const { student } = props;
    const studentOrders = Object.values(orders).filter(
      (o) => o.studentId === student.id && moment(o.date).isSame(new Date(), 'week'),
    );
    return studentOrders;
  },
);

export const getStudentEntreeOrders = createSelector(
  [CommonSelectors.getOrderItems, (_: Interfaces.AppState, student: Interfaces.Student) => student],
  (orders, student) => {
    const studentOrders = Object.values(orders).filter((o) => {
      return o.studentId === student.id && o.productCategoryId === Constants.ENTREE_KEY;
    });

    return uniqBy(studentOrders, 'date').sort((a: any, b: any) => +new Date(a.date) - +new Date(b.date));
  },
);

export const getStudentALaCarteOrders = createSelector(
  [CommonSelectors.getOrderItems, (_: Interfaces.AppState, student: Interfaces.Student) => student],
  (orders, student) => {
    const studentOrders = Object.values(orders).filter((o) => {
      return o.studentId === student.id && o.productCategoryId === Constants.ALACARTE_KEY;
    });

    return uniqBy(studentOrders, 'date').sort((a: any, b: any) => +new Date(a.date) - +new Date(b.date));
  },
);
