import React, { ChangeEvent, useMemo } from 'react';
import { TextField as InputComponent } from '@material-ui/core';
import AutocompleteCore, {
  AutocompleteCloseReason,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import '../MaterialForm.less';

interface AutocompleteOption {
  text: string;
  value: string;
}

export const Autocomplete = ({
  label,
  id,
  value,
  options,
  onChange,
  multipleOnChange,
  errorMsg,
  loading,
  className,
  disableClearable,
  touched,
  hideEmptyError,
  showOnlyTouchedError,
  hideError = false,
  multiple = false,
  ...inputProps
}: {
  label?: string;
  id?: string;
  value?: string | string[];
  options: AutocompleteOption[];
  onChange?: (e: React.ChangeEvent<{}>, value: AutocompleteOption | null) => void;
  multipleOnChange?: (e: React.ChangeEvent<{}>, value: AutocompleteOption[] | null) => void;
  errorMsg?: string;
  loading?: boolean;
  className?: string;
  disableClearable?: boolean;
  touched?: boolean;
  hideEmptyError?: boolean;
  showOnlyTouchedError?: boolean;
  hideError?: boolean;
  multiple?: boolean;
}) => {
  const selectedOption = useMemo(() => {
    if (multiple) {
      return options.filter((option) => value?.includes(option.value));
    }

    return (
      options.find((option) => option.value === value) || [
        {
          text: '',
          value: '',
        },
      ]
    );
  }, [options, value, multiple]);

  const isErrorHidden =
    hideError || (!errorMsg && hideEmptyError) || (errorMsg && showOnlyTouchedError && !touched) || !errorMsg;

  const onChangeWrapper = (
    e: React.ChangeEvent<{}>,
    autoCompleteValue: AutocompleteOption | AutocompleteOption[] | null,
  ) => {
    const isArray = Array.isArray(autoCompleteValue);

    if (multipleOnChange && (isArray || autoCompleteValue === null)) {
      multipleOnChange(e, autoCompleteValue);
    } else if (onChange && (!isArray || autoCompleteValue === null)) {
      onChange(e, autoCompleteValue);
    }
  };

  // Manually control open state to override close on select behavior when in multi select mode
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e: ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
    if (!multiple || reason !== 'select-option') {
      setOpen(false);
    }
  };

  return (
    <>
      <AutocompleteCore
        id={id}
        value={selectedOption}
        multiple={multiple}
        options={options}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        getOptionLabel={(option: AutocompleteOption) => option?.text ?? ''}
        onChange={onChangeWrapper}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <InputComponent
            {...params}
            label={label}
            variant="outlined"
            size="medium"
            helperText={!isErrorHidden && errorMsg}
            error={!isErrorHidden}
            InputLabelProps={{
              htmlFor: id,
            }}
            className="autocomplete-input"
            inputProps={{ ...params.inputProps, ...inputProps }}
          />
        )}
        loading={loading}
        className={className}
        disableClearable={disableClearable}
        classes={{
          listbox: 'autocomplete__list-box',
          noOptions: 'autocomplete__no-options-text',
        }}
      />
    </>
  );
};
