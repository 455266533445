import { Reducer } from 'react';

import * as Types from './types';

import * as Interfaces from 'Interfaces/Interfaces';
import { CLOSE_ALL_SLIDEOUTS_AND_MODALS } from 'State/types';

const initialSubState: Interfaces.CartState = {
  menuItems: {},
  childMenuItems: {},
  purchasedMenuItems: {},
  purchasedChildMenuItems: {},
  itemPickupLocations: {},
  pickupLocationOptionsByItem: {},
  purchasedPickupLocations: {},
  purchasedOrderId: '',
  showCart: false,
  showPickup: false,
  error: '',
};

const initialRootState = {
  accounts: {},
  accountId: null,
};

const UpdateMenuItem = (state: Interfaces.CartState, menuItem: Interfaces.CartItem, delta: number): any => {
  const newMenuItems = { ...state.menuItems };
  const id = menuItem.id;
  const updatedMenuItem = state.menuItems[id] || { ...menuItem };
  updatedMenuItem.count = updatedMenuItem.count + delta;

  if (updatedMenuItem.count === 0) {
    delete newMenuItems[id];
  } else {
    newMenuItems[id] = updatedMenuItem;
  }
  return newMenuItems;
};

const UpdateMenuItemComments = (state: Interfaces.CartState, menuItemId: string, comments: string): any => {
  const newMenuItems = { ...state.menuItems };
  const itemDate = newMenuItems[menuItemId].date;
  const itemMenuType = newMenuItems[menuItemId].mealTypeId;

  Object.keys(newMenuItems).forEach((itemId: string) => {
    const menuItem = newMenuItems[itemId];
    if (menuItem.date === itemDate && menuItem.mealTypeId === itemMenuType) {
      menuItem.comments = comments;
    }
  });

  return newMenuItems;
};
const UpdateChildMenuItemComments = (state: Interfaces.CartState, menuItemId: string, comments: string): any => {
  const newChildMenuItems = { ...state.childMenuItems };
  newChildMenuItems[menuItemId]?.forEach((childMenuItem) => {
    childMenuItem.comments = comments;
  });

  return newChildMenuItems;
};
const UpdateChildMenuItems = (state: Interfaces.CartState, menuItemId: string, childItem: Interfaces.CartItem): any => {
  const childMenuItems = { ...state.childMenuItems };
  const items: Interfaces.CartItem[] = childMenuItems[menuItemId] ?? [];

  if (!items.some((item) => item.id === childItem.id)) {
    items.push(childItem);
  }

  childMenuItems[menuItemId] = items;
  return childMenuItems;
};

const RemoveChildMenuItems = (state: Interfaces.CartState, childItem: Interfaces.CartItem): any => {
  const childMenuItems = { ...state.childMenuItems };
  delete childMenuItems[childItem.id];
  return childMenuItems;
};

const UpdatePickupLocations = (state: Interfaces.CartState, menuItemId: string, pickupLocationId: string): any => {
  const ipl = { ...state.itemPickupLocations };
  ipl[menuItemId] = pickupLocationId;
  return ipl;
};

const RemovePickupLocation = (state: Interfaces.CartState, menuItemId: string): any => {
  const ipl = { ...state.itemPickupLocations };
  delete ipl[menuItemId];
  return ipl;
};

const CartReducer: Reducer<Interfaces.CartRootState, Interfaces.Action> = (state = initialRootState, action) => {
  const { type, payload } = action;
  const accountId = state.accountId || '';

  if (!state.accounts) {
    state.accounts = {};
  }

  if (accountId && !state.accounts[accountId]) {
    state.accounts = { ...state.accounts, [accountId]: initialSubState };
  }

  switch (type) {
    case Types.CART_ADD_ITEM:
      const newPickupLocationOptionsByItem = { ...state.accounts[accountId].pickupLocationOptionsByItem };
      newPickupLocationOptionsByItem[payload.cartItem.id] = payload.pickupLocationOptions;

      state.accounts[accountId] = {
        ...state.accounts[accountId],
        menuItems: UpdateMenuItem(state.accounts[accountId], payload.cartItem, payload.delta),
        pickupLocationOptionsByItem: newPickupLocationOptionsByItem ?? {},
        purchasedMenuItems: {},
        purchasedChildMenuItems: {},
        purchasedOrderId: '',
      };
      return { ...state };

    case Types.CART_ADD_CHILD_ITEM:
      state.accounts[accountId] = {
        ...state.accounts[accountId],
        childMenuItems: UpdateChildMenuItems(state.accounts[accountId], payload.menuItemId, payload.childItem),
      };
      return { ...state };

    case Types.CART_CLEAR:
      state.accounts[accountId] = {
        ...state.accounts[accountId],
        menuItems: {},
        itemPickupLocations: {},
        childMenuItems: {},
      };
      return { ...state };

    case Types.CART_REMOVE_ITEM:
      state.accounts[accountId] = {
        ...state.accounts[accountId],
        menuItems: UpdateMenuItem(state.accounts[accountId], payload.cartItem, payload.delta),
        itemPickupLocations: RemovePickupLocation(state.accounts[accountId], payload),
        childMenuItems: RemoveChildMenuItems(state.accounts[accountId], payload.cartItem),
      };
      return { ...state };
    case Types.CART_SET_COMMENTS:
      state.accounts[accountId] = {
        ...state.accounts[accountId],
        menuItems: UpdateMenuItemComments(state.accounts[accountId], payload.cartItemId, payload.comments),
        childMenuItems: UpdateChildMenuItemComments(state.accounts[accountId], payload.cartItemId, payload.comments),
      };
      return { ...state };
    case Types.CART_TOGGLE_CHECKOUT:
      state.accounts[accountId] = {
        ...state.accounts[accountId],
        showCart: !state.accounts[accountId].showCart,
      };
      return { ...state };

    case Types.CART_TOGGLE_PICKUP:
      state.accounts[accountId] = {
        ...state.accounts[accountId],
        showPickup: !state.accounts[accountId].showPickup,
      };
      return { ...state };

    case Types.CART_SET_PICKUP_LOCATIONS:
      state.accounts[accountId] = {
        ...state.accounts[accountId],
        itemPickupLocations: UpdatePickupLocations(
          state.accounts[accountId],
          payload.menuItemId,
          payload.pickupLocationId,
        ),
      };
      return { ...state };

    case Types.CART_CLEAR_PICKUP_LOCATIONS:
      state.accounts[accountId] = {
        ...state.accounts[accountId],
        itemPickupLocations: {},
      };
      return { ...state };

    case Types.CART_SUBMIT_ORDER:
      state.accounts[accountId] = {
        ...state.accounts[accountId],
        purchasedMenuItems: state.accounts[accountId].menuItems,
        purchasedChildMenuItems: state.accounts[accountId].childMenuItems,
        purchasedPickupLocations: state.accounts[accountId].itemPickupLocations,
        menuItems: {},
        itemPickupLocations: {},
        childMenuItems: {},
      };
      return { ...state };

    case CLOSE_ALL_SLIDEOUTS_AND_MODALS:
      state.accounts[accountId] = {
        ...state.accounts[accountId],
        showCart: payload === 'showCart',
        showPickup: payload === 'showPickup',
      };
      return { ...state };

    case Types.CART_SET_ERROR:
      state.accounts[accountId] = {
        ...state.accounts[accountId],
        error: payload,
      };
      return { ...state };

    case Types.CART_SET_ORDER_ID:
      state.accounts[accountId] = { ...state.accounts[accountId], purchasedOrderId: payload };
      return { ...state };

    case Types.CART_SET_ACCOUNT_ID:
      return { ...state, accountId: payload };

    default:
      return state;
  }
};

export default CartReducer;
