import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'classnames';
import Button from 'Components/Button/Button';
import SpecialMealAccommodations from 'Components/SpecialMealAccommodations/SpecialMealAccommodations';
import { MOBILE_MEDIA_QUERY, PRODUCT_CATEGORIES } from 'Constants/Constants';
import { getSidesText } from 'Helpers/Helper';
import * as Interfaces from 'Interfaces/Interfaces';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import './EntreeList.less';

interface EntreeListProps {
  onEntreeClick: (entree: Interfaces.MenuItem) => void;
  selectedEntree: Interfaces.MenuItem | null;
  onClearClick: () => void;
  menuItemsGrouped: any;
  disabled: boolean;
  selectedSides: Record<string, Interfaces.MenuItem[]>;
}

const EntreeList = ({
  onEntreeClick,
  selectedEntree,
  onClearClick,
  menuItemsGrouped,
  disabled,
  selectedSides,
}: EntreeListProps) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY });
  const entrees = menuItemsGrouped[PRODUCT_CATEGORIES.ENTREE.id] || [];
  const sidesText = getSidesText(selectedSides);

  const handleOpenModalClick = React.useCallback(
    (entree) => {
      onEntreeClick(entree);
    },
    [onEntreeClick],
  );

  return (
    <section className="teacher-roster-ordering__entree-section">
      <h4>Entrees</h4>
      <div className="teacher-roster-ordering__entree-list">
        {!disabled &&
          entrees.length > 0 &&
          entrees.map((entree: Interfaces.MenuItem) => {
            const product = entree.product;

            if (!selectedEntree || (selectedEntree && selectedEntree.id === entree.id)) {
              return (
                <button
                  className={classNames('btn-reset', {
                    'menu-item-container': !isMobile,
                    'menu-item-container-mobile': isMobile,
                  })}
                  onClick={() => handleOpenModalClick(entree)}
                  key={entree.id}
                  data-test-id={`${product.name}.Btn`}
                >
                  <div
                    className="image"
                    style={{ backgroundImage: product.imageUrl ? `url(${product.imageUrl})` : undefined }}
                  />
                  <div className="body">
                    <div className="side-bar__product-name">{product.name}</div>
                    <div className="side-bar__sides">{sidesText}</div>
                    <SpecialMealAccommodations
                      specialMealAccommodationIds={product.specialMealAccommodationIds}
                      showSpecialIconsOnly={true}
                    />
                  </div>
                </button>
              );
            }
            return null;
          })}
        {(disabled || entrees.length === 0) && (
          <div className="entrees-unavailable">
            <WarningIcon />
            <div>Ordering not available</div>
          </div>
        )}
      </div>
      {entrees.length > 0 && (
        <footer>
          <Button
            variant="text"
            color="primary"
            aria-label="Deselect entree"
            onClick={onClearClick}
            disabled={!selectedEntree}
            data-test-id="entreeClearBtn"
          >
            Clear
          </Button>
        </footer>
      )}
    </section>
  );
};

export default EntreeList;
