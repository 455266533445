import React, { ReactElement } from 'react';
import numeral from 'numeral';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import * as Constants from 'Constants/Constants';

type CalendarWeekWarningProps = {
  amount: string | undefined;
  link: string | undefined;
  onCloseClick: () => void;
  orderTypeText: string;
};

const CalendarWeekWarning: React.FC<CalendarWeekWarningProps> = ({
  amount,
  link,
  onCloseClick,
  orderTypeText,
}): ReactElement | null => {
  return (
    <div className="ordering-rules">
      <span className="text">
        The minimum balance required to order {orderTypeText} is {numeral(amount).format(Constants.USD_FORMAT)}.
        {link && (
          <a href={link} target="_blank" rel="noopener noreferrer">
            {' '}
            Please add to balance.{' '}
          </a>
        )}
        If you have recently added to your balance, and you are not seeing the updated balance reflected in Online
        Ordering, please wait 24 hours for the data to update.
      </span>
      <IconButton onClick={onCloseClick} aria-label="Close cutoff time warning." className="close-icon">
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default CalendarWeekWarning;
