import { get } from 'lodash';
import { createSelector } from 'reselect';

import { AppState, MenuType } from 'Interfaces/Interfaces';
import { REPORT_SCOPE } from 'Constants/Enums';
import { UNASSOCIATED_MEAL_TYPE } from 'Constants/Constants';
import * as CommonStateSelectors from 'State/selectors';

const root = (state: AppState) => get(state, 'districtReportsState');

export const getPickupLocationId = (state: AppState) => get(root(state), 'pickupLocationId');
export const getSiteId = (state: AppState) => get(root(state), 'siteId');

export const getReport = (state: AppState) => get(root(state), 'report');
export const getReportStudents = (state: AppState) => get(root(state), 'reportStudents', {});
export const getReportMenuTypesList = (state: AppState) => get(getReport(state), 'menuTypes', []);
export const getReportMenuTypeId = (state: AppState) => get(root(state), 'reportMenuTypeId');
export const getPrintLabelParameters = (state: AppState) => get(root(state), 'printLabelParameters');
export const getPrintLabelScopeRangeParameters = (scope: REPORT_SCOPE, viewByDateRange: boolean) =>
  createSelector(getPrintLabelParameters, (printLabelParameters) => {
    const scopeParameters = printLabelParameters?.[scope];
    return viewByDateRange ? scopeParameters?.range : scopeParameters?.day;
  });
export const getBulkOrdersByProduct = (state: AppState) => get(getReportStudents(state), 'bulkOrdersByProduct');

export const getReportMenuTypes = createSelector(getReportMenuTypesList, (reportMenuTypes: MenuType[]) => {
  return reportMenuTypes.reduce((acc: { [key: string]: MenuType }, menuType) => {
    acc[menuType.id] = menuType;
    return acc;
  }, {});
});

export const getReportMenuTypeIds = createSelector(getReportMenuTypes, (reportMenuTypes) =>
  Object.keys(reportMenuTypes),
);

export const getDefaultMenuType = (districtId: string) =>
  createSelector([getReportMenuTypes, CommonStateSelectors.getDistrict(districtId)], (menuTypes, district) => {
    if (district?.orderingRules?.offerOnlyALaCarteNoPublishedMenus) {
      return UNASSOCIATED_MEAL_TYPE;
    }

    const menuType = Object.values(menuTypes).sort((a, b) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    })[0];

    if (menuType) {
      return menuTypes[menuType.id];
    }

    return { id: '', name: '' };
  });

export const getReportMenuTypeOptions = createSelector(getReportMenuTypesList, (reportMenuTypes: MenuType[]) => {
  return Object.values(reportMenuTypes || {})
    .sort((a, b) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    })
    .map((menuType: MenuType) => ({
      text: menuType.name,
      value: menuType.id,
    }));
});
