import _ from 'lodash';
import { PickupLocation } from 'Interfaces/Interfaces';
import { SiteScope } from 'Constants/Enums';

export const groupArrayByCounts = (counts: number[], arr: any[]) => {
  const groups: any[] = counts.map(() => []);
  let currentGroup: number = 0;
  arr.forEach((v) => {
    if (counts[currentGroup] && groups[currentGroup].length < counts[currentGroup]) {
      groups[currentGroup].push(v);

      if (groups[currentGroup].length === counts[currentGroup]) {
        currentGroup += 1;
      }
    }
  });

  return groups;
};

export const sortAndGroupPickupLocations = (pickupLocations: PickupLocation[]): { [key: string]: PickupLocation[] } => {
  const sorted = _.orderBy(pickupLocations, [(p: PickupLocation) => p.name?.toLowerCase()], ['asc']);
  const { [`true`]: defaultGroup, [`false`]: nonDefaultGroup } = _.groupBy(sorted, 'default');

  return {
    ..._.groupBy(nonDefaultGroup, 'scope'),
    DEFAULT: defaultGroup,
  } as { [key: string]: PickupLocation[] };
};

export const sortPickupLocations = (pickupLocations: { [key: string]: PickupLocation }) => {
  return Object.values(pickupLocations).sort((a, b) => {
    const aScope = a.scope || SiteScope.SITE;
    const bScope = b.scope || SiteScope.SITE;
    if (a.default) {
      return -1;
    }
    if (b.default) {
      return 1;
    }
    if (aScope < bScope) {
      return 1;
    }
    if (aScope > bScope) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return -1;
  });
};

const determineLocationType = (scope: string | undefined, isDefault: boolean | undefined = false) => {
  if (scope === 'SITE' && !isDefault) {
    return scope;
  } else if (scope === 'GLOBAL') {
    return scope;
  } else if (scope === 'SITE' && isDefault) {
    return 'DEFAULT';
  } else {
    return 'None';
  }
};

export const toPickupLocationOption = (pickupLocation: PickupLocation) => {
  return {
    default: pickupLocation.default,
    text: pickupLocation.name,
    value: pickupLocation.id,
    id: pickupLocation.id,
    name: pickupLocation.name,
    group: determineLocationType(pickupLocation.scope, pickupLocation.default),
    scope: determineLocationType(pickupLocation.scope, pickupLocation.default),
  };
};

export const filterLocationsBySiteAndHomeroom = (
  pickupLocations: { [key: string]: PickupLocation },
  siteId: string,
  homeroomId: string,
) => {
  return Object.values(pickupLocations).reduce((acc, pickupLocation) => {
    if (
      (pickupLocation.siteId === siteId &&
        (!pickupLocation.default || pickupLocation.id === `${siteId}.CLASSROOM${homeroomId}`)) ||
      pickupLocation.scope === SiteScope.GLOBAL
    ) {
      acc[pickupLocation.id] = pickupLocation;
    }
    return acc;
  }, {} as { [key: string]: PickupLocation });
};

export const filterLocationsBySite = (pickupLocations: { [key: string]: PickupLocation }, siteId: string) => {
  return Object.values(pickupLocations).reduce((acc, pickupLocation) => {
    if (pickupLocation.siteId === siteId || pickupLocation.scope === SiteScope.GLOBAL) {
      acc[pickupLocation.id] = pickupLocation;
    }
    return acc;
  }, {} as { [key: string]: PickupLocation });
};
