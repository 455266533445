import React, { FC, useCallback, useState } from 'react';
import { NotificationDialog } from 'Components/Dialogs/NotificationDialog';
import { useCommonActions } from 'State/actions';
import { PickupLocation } from 'Interfaces/Interfaces';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

export interface PickupLocationDeleteProps {
  id: string;
  name: string;
  siteId?: string;
}

export const PickupLocationDelete: FC<PickupLocationDeleteProps> = ({ id, name, siteId }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const { deletePickupLocation } = useCommonActions();

  const showConfirmationModal = useCallback(() => {
    setShowConfirmation(true);
  }, []);

  const closeConfirmationModal = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  const removePickupLocation = useCallback(async () => {
    setLoading(true);
    await deletePickupLocation({
      id: id,
      name: '',
      siteId: siteId,
    } as PickupLocation);
    setLoading(false);
    closeConfirmationModal();
  }, [id, siteId, deletePickupLocation, closeConfirmationModal]);

  return (
    <>
      <IconButton onClick={showConfirmationModal} data-test-id={`${name}.deleteBtn`}>
        <DeleteIcon />
      </IconButton>
      <NotificationDialog
        title={`Delete Pickup Location ${name?.toUpperCase()}`}
        message={`Are you sure you want to delete ${name?.toUpperCase()}?`}
        showDialog={showConfirmation}
        showCancel={true}
        cancelButtonText="CANCEL"
        okButtonText="DELETE"
        onOkClick={removePickupLocation}
        onCancelClick={closeConfirmationModal}
        dialogType="delete"
        okIcon="delete"
        loading={loading}
      />
    </>
  );
};
