import React, { FC } from 'react';
import type { Row } from 'react-table';

interface PreviewRowProps {
  row: Row;
}

export const PreviewRow: FC<PreviewRowProps> = ({ row }) => {
  const rowProps = row?.getRowProps
    ? { ...row.getRowProps({ className: 'drag-preview-row' }) }
    : { className: 'drag-preview-row' };

  return (
    <div {...rowProps}>
      {row.cells.map((cell: any) => {
        return (
          <div
            {...cell.getCellProps({
              className: cell.column?.className,
            })}
          >
            {cell.column.Header !== '' && cell.render('Cell')}
          </div>
        );
      })}
    </div>
  );
};
