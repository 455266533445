import { FC, useEffect } from 'react';

const AxeLoader: FC<{ environment: string | undefined }> = ({ environment }) => {
  useEffect(() => {
    const loadAxe = async () => {
      // @ts-ignore
      await import('axe-core/axe.min.js');
      // @ts-ignore
      await import('./axeLoaderUtilities');
    };
    if (environment && ['development', 'staging'].indexOf(environment) > -1 && !window.axe) {
      loadAxe();
    }
  }, [environment]);

  return null;
};

export default AxeLoader;
