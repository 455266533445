import { ActionCreator } from 'redux';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';

export const setPageLoaderActive: ActionCreator<Interfaces.Action> = (payload: boolean) => ({
  type: Types.LOADER_SET_PAGE_LOADER_ACTIVE,
  payload,
});

export const setModalOpen: ActionCreator<Interfaces.Action> = (payload: boolean) => ({
  type: Types.LOADER_MODAL_SET_OPEN,
  payload,
});

export const setComponentLoaderActive: ActionCreator<Interfaces.Action> = (payload: boolean) => ({
  type: Types.LOADER_SET_COMPONENT_LOADER_ACTIVE,
  payload,
});
