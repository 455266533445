import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import numeral from 'numeral';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import { groupBy } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import Grid from '@material-ui/core/Grid';
import { AttachMoney } from '@material-ui/icons';
import Button from 'Components/Button/Button';
import StudentBalance from 'Components/Student/StudentBalance/StudentBalance';
import ErrorPage from 'Pages/ErrorPage/ErrorPage';
import * as CartSelectors from 'Components/Shop/Cart/state/selectors';
import { useCartActions } from 'Components/Shop/Cart/state/actions';
import Card from 'Components/Card/Card';
import { CalloutCard } from 'Components/CalloutCard/CalloutCard';
import * as ShopSelectors from './state/selectors';
import { Role, isAuthorized, TokenClaims } from 'Auth/User';
import { getStudentDistricts } from '../ParentDashboard/state/selectors';
import { useStudentDistricts } from '../ParentDashboard/state/actions';
import * as Constants from 'Constants/Constants';
import * as Interfaces from 'Interfaces/Interfaces';
import './OrderConfirmation.less';
import { ButtonLink, ButtonLinkVariants } from 'Components/ButtonLink/ButtonLink';

const OrderConfirmation: FC<{}> = () => {
  const data = useSelector(CartSelectors.getOrderConfirmation);
  const orderSubmissionError = useSelector(CartSelectors.getError);
  const student = useSelector(ShopSelectors.getStudent);
  const districts = useSelector(getStudentDistricts);
  const [district, setDistrict] = useState(student && districts ? districts[student?.districtId] : null);
  const orderId = useSelector(CartSelectors.getOrderId);
  const districtIds = useMemo(() => [student?.districtId || ''], [student]);
  const { toggleCheckout } = useCartActions();

  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const email = user?.email;
  const isParent = isAuthorized(userRole, [Role.PARENT]);

  const keys = Object.keys(data.items).sort();
  const displayTotal = isParent ? `Total ${numeral(data.total).format(Constants.USD_FORMAT)}` : '';

  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const isSuspendedError = orderSubmissionError === 'Student is suspended and cannot place orders.';

  // Load the districts
  useStudentDistricts(districtIds);

  useEffect(() => {
    if (student && districts) {
      setDistrict(districts[student.districtId]);
    }
  }, [districts, student]);

  if (orderSubmissionError) {
    return (
      <ErrorPage
        errorType={
          isSuspendedError ? Constants.ERROR_TYPE.ORDER_STUDENT_SUSPENDED : Constants.ERROR_TYPE.ORDER_SUBMISSION
        }
        secondaryButton={
          !isSuspendedError && (
            <Button variant="contained" color="primary" onClick={() => toggleCheckout()} size="small" fullWidth>
              Resubmit Order
            </Button>
          )
        }
        showContactSupport={!isSuspendedError}
      />
    );
  }

  return (
    <div className="order-confirmation__container">
      <div className="order-confirmation__balance-sub-header">
        <h2>{`${student?.firstName} ${student?.lastName}`}</h2>
        <h3>
          <StudentBalance student={student} isParent={isParent} isMobile={isMobile} hideDivider />
        </h3>
      </div>
      <Grid
        container
        spacing={2}
        className={!isMobile ? 'order-confirmation__main-content' : 'order-confirmation__main-content-mobile'}
      >
        <Grid item xs={12} md={8}>
          <Card
            title={'Thank You'}
            description={`Your order was successfully submitted. ${
              isParent && `An email confirmation will be sent to ${email}.`
            }`}
          >
            <div>
              <div>
                <div className="order-confirmation__sub-title">
                  <h3>{`Order #: ${orderId}`}</h3>
                  <h3 className="order-Confirmation__total">{displayTotal}</h3>
                </div>
                <hr />
                <div className="order-confirmation__date-list">
                  {keys.map((entry, i) => {
                    const meals: any[] = data.items[entry];
                    return (
                      <DateElement
                        date={entry}
                        key={i}
                        items={meals}
                        last={i < keys.length - 1}
                        showPrices={isParent}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </Card>
        </Grid>
        {isParent && (
          <Grid item xs={12} md={4}>
            {district && district.paymentProviderLink && (
              <CalloutCard title="Keep your balance full" icon={<AttachMoney />}>
                <div>
                  Updates to your balance will be reflected tomorrow. If you would like to add funds to your account,
                  please select "add to balance" to be redirected to your payment center.
                  <div className="order-confirmation__add-to-balance">
                    <ButtonLink
                      href={district.paymentProviderLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      buttonVariant={ButtonLinkVariants.Contained}
                      fullWidth={true}
                      text="Add to Balance"
                      data-test-id="addToBalanceBtn"
                    />
                  </div>
                </div>
              </CalloutCard>
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const DateElement = ({
  date,
  items,
  last,
  showPrices,
}: {
  date: string;
  items: any[];
  last: boolean;
  showPrices: boolean;
}) => {
  const m = moment(date);
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const itemsByMenuType = groupBy(items, 'mealType');
  const groupedItemsByMenuType = Object.entries(itemsByMenuType).reduce((menuTypesAcc, [menuType, menuTypeItems]) => {
    const categoryItems = menuTypeItems.reduce((acc, item) => {
      if (!item.productName) {
        return acc;
      }
      if (!acc[item.productName]) {
        acc[item.productName] = {
          total: 0,
          price: 0,
          id: item.id,
          productName: item.productName,
          productCategoryId: item.productCategoryId,
          pickupLocation: item.pickupLocation,
          comments: item.comments,
        };
      }
      acc[item.productName].total += 1;
      acc[item.productName].price += item.price;
      return acc;
    }, {} as Record<string, Interfaces.CartItemAggregation>);
    menuTypesAcc[menuType] = categoryItems;
    return menuTypesAcc;
  }, {} as Record<string, Record<string, Interfaces.CartItemAggregation>>);
  return (
    <div key={m.format('X')}>
      <div className="day">
        <h3 className="title">{m.format(Constants.DATE_FORMAT_DMDDY)}</h3>
        {Object.entries(groupedItemsByMenuType).map(([menuType, data]) => {
          const cartItemAggregation = Object.values(data)[0];
          return (
            <React.Fragment key={menuType}>
              <div className={!isMobile ? 'items' : 'items-mobile'}>
                <div className="order-confirmation__menu-pickup-location-container">
                  <h4>{menuType !== 'undefined' ? menuType : ''}</h4>
                  <h6 className="linq-card__h6">{cartItemAggregation?.pickupLocation}</h6>
                </div>
                {Object.values(data).map((item, index) => (
                  <Item item={item} index={index} showPrices={showPrices} key={index} />
                ))}
                {cartItemAggregation?.comments && (
                  <>
                    <h6 className="linq-card__h6">Comments</h6>
                    <div>{cartItemAggregation?.comments}</div>
                  </>
                )}
              </div>
            </React.Fragment>
          );
        })}
        {last ? <hr /> : <React.Fragment />}
      </div>
    </div>
  );
};

const Item = ({ item, index, showPrices }: { item: any; index: number; showPrices: boolean }) => {
  const displayPrice = showPrices ? numeral(item.price).format(Constants.USD_FORMAT) : '';
  const displayName = item.total > 1 ? `${item.total}x ${item.productName}` : `${item.productName}`;
  return (
    <div className="row" key={`${item.id}.${index}`}>
      <div>
        <h6 className="linq-card__h6">{Constants.PRODUCT_CATEGORIES[item.productCategoryId]?.name}</h6>
        <div className="order-confirmation__name-price-container">
          <div>{displayName}</div>
          <div>{displayPrice}</div>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;
