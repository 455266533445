import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';

export interface ListItemLinkProps {
  icon?: React.ReactNode;
  primary: string;
  to: string;
}

export const ListItemLink: React.FC<ListItemLinkProps> = ({ icon, primary, to, ...listItemProps }) => {
  const renderLink = React.useMemo(
    () => React.forwardRef<HTMLAnchorElement>((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink} {...listItemProps}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={<span className="linq-list-item__text">{primary}</span>} />
      </ListItem>
    </li>
  );
};
