import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { FormikValues, useFormik } from 'formik';
import { useAuth0 } from '@auth0/auth0-react';
import DateFnsUtils from '@date-io/date-fns';
import { format, isValid, formatISO, parseISO, parse } from 'date-fns';
import { Paper, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { TokenClaims } from 'Auth/User';
import axios from 'axios';
import * as yup from 'yup';
import Button from 'Components/Button/Button';
import { InputField, KeyboardDatePickerField } from 'Components/Form/MaterialForm';
import FroalaEditor from 'Components/FroalaEditor/FroalaEditor';
import { PageLoaderNoRedux } from 'Components/Loaders/Loaders';
import PageFooter from 'Components/PageFooter/PageFooter';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import { DATE_FNS_DATE_FORMAT_USA, ERROR_TYPE, ToastConfig, ToastType } from 'Constants/Constants';
import { getRoutePath, RouteId } from 'Constants/Routes';
import { usePrompt } from 'Helpers/usePrompt';
import { isSameOrAfter, isSameOrBefore } from 'Helpers/DateHelpers';
import { Newsfeed } from 'Interfaces/Interfaces';
import ErrorPage from 'Pages/ErrorPage/ErrorPage';
import { useEditNewsfeedActions } from './state/actions';

import './EditNewsfeed.less';

const EditNewsfeed = () => {
  const { user } = useAuth0();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { id } = useParams<Record<string, string | undefined>>();
  const [initialValues, setInitialValues] = React.useState<Partial<Newsfeed>>({
    title: '',
    startDate: null,
    endDate: null,
    content: '',
  });
  const [isNotFoundError, setNotFoundError] = React.useState<boolean>(false);
  const { postNewsfeed, patchNewsfeed, getNewsfeed } = useEditNewsfeedActions();
  const navigate = useNavigate();
  const districtId = TokenClaims.getDistrictId(user);
  const isNew = !id;

  const returnToNewsfeedConfig = () => navigate(getRoutePath(RouteId.NEWS_FEED_MANAGEMENT));
  const formik = useFormik({
    initialValues,
    onSubmit: async (values: FormikValues) => {
      const newsfeed: Newsfeed = {
        id: values.id,
        title: values.title,
        content: values.content,
        startDate: formatISO(parse(values.startDate, DATE_FNS_DATE_FORMAT_USA, new Date())),
        endDate: values.endDate ? formatISO(parse(values.endDate, DATE_FNS_DATE_FORMAT_USA, new Date())) : '',
        sortIndex: values.sortIndex,
      };
      try {
        setLoading(true);
        if (isNew) {
          await postNewsfeed(districtId, newsfeed);
          toast.success('Created newsfeed successfully.', ToastConfig[ToastType.Success]);
          formik.resetForm();
          navigate(getRoutePath(RouteId.NEWS_FEED_MANAGEMENT));
        } else {
          await patchNewsfeed(districtId, newsfeed);
          formik.resetForm();
          navigate(getRoutePath(RouteId.NEWS_FEED_MANAGEMENT));
          toast.success('Updated newsfeed successfully.', ToastConfig[ToastType.Success]);
        }
      } catch {
      } finally {
        setLoading(false);
      }
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      title: yup.string().required('Field is required'),
      startDate: yup.string().required('Field is required'),
      content: yup.string().required('Field is required'),
    }),
    validate: (values: FormikValues) => {
      const errors: { [key: string]: string } = {};

      const startDate = parse(values.startDate, DATE_FNS_DATE_FORMAT_USA, new Date());
      const endDate = parse(values.endDate, DATE_FNS_DATE_FORMAT_USA, new Date());

      if (!values.startDate) {
        errors.startDate = 'Field is required';
      } else if (values.startDate && !isValid(startDate)) {
        errors.startDate = 'Invalid date';
      } else if (isValid(startDate) && isSameOrBefore(endDate, startDate)) {
        errors.startDate = 'Date should be before end date';
      }
      if (values.endDate && !isValid(endDate)) {
        errors.endDate = 'Invalid date';
      } else if (values.endDate && isValid(endDate) && isSameOrAfter(startDate, endDate)) {
        errors.endDate = 'Date should be after start date';
      }

      return errors;
    },
  });

  React.useEffect(() => {
    async function getExistingNewsfeed() {
      try {
        setLoading(true);
        if (id) {
          const res = await getNewsfeed(districtId, id);
          res.data.startDate = res.data.startDate
            ? format(parseISO(res.data.startDate), DATE_FNS_DATE_FORMAT_USA)
            : null;
          res.data.endDate = res.data.endDate ? format(parseISO(res.data.endDate), DATE_FNS_DATE_FORMAT_USA) : null;
          setInitialValues(res.data);
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const status: number | undefined = err.response?.status;

          if (status === 404) {
            setNotFoundError(true);
          }
        }
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      getExistingNewsfeed();
    }
  }, [districtId, id, getNewsfeed]);

  usePrompt(
    'There are unsaved changes, are you sure you want to continue and discard your current changes?',
    formik.dirty,
  );

  return (
    <React.Fragment>
      <div>
        <SubHeader title={isNew ? 'Add New Parent Newsfeed' : 'Edit Parent Newsfeed'} />
        <PageLoaderNoRedux loading={loading} />
        {!isNotFoundError && (
          <>
            <form onSubmit={formik.handleSubmit}>
              <Paper className="edit-newsfeed-container" variant="outlined">
                <Typography gutterBottom={true} variant="h4" className="linq-heading card-title">
                  Parent Newsfeed Information
                </Typography>
                <input name="id" hidden={true} value={formik.values.id} />
                <InputField
                  id="title"
                  label="Title"
                  name="title"
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  fullWidth={false}
                  className="title-field"
                  errorMsg={formik.errors.title}
                  showOnlyTouchedError={true}
                  touched={formik.touched.title}
                  inputProps={{
                    'data-test-id': 'titleInput',
                  }}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="date-range-field">
                    <KeyboardDatePickerField
                      autoOk={true}
                      label="Start Date"
                      showTodayButton={true}
                      value={formik.values.startDate}
                      format={DATE_FNS_DATE_FORMAT_USA}
                      onChange={async (date, dateString) => {
                        await formik.setFieldValue('startDate', dateString);
                        formik.setFieldTouched('startDate', true);
                      }}
                      allowKeyboardControl={true}
                      style={{ width: 225, marginRight: 32 }}
                      maxDate={formik.values.endDate || undefined}
                      clearable={true}
                      errorMsg={formik.errors.startDate}
                      invalidDateMessage=""
                      showOnlyTouchedError={true}
                      touched={formik.touched.startDate}
                      inputProps={{
                        'data-test-id': 'startDateInput',
                      }}
                    />
                    <KeyboardDatePickerField
                      autoOk={true}
                      label="End Date"
                      showTodayButton={true}
                      value={formik.values.endDate}
                      format={DATE_FNS_DATE_FORMAT_USA}
                      onChange={async (date, dateString) => {
                        await formik.setFieldValue('endDate', dateString);
                        formik.setFieldTouched('endDate', true);
                      }}
                      allowKeyboardControl={true}
                      style={{ width: 225 }}
                      minDate={formik.values.startDate || undefined}
                      clearable={true}
                      errorMsg={formik.errors.endDate}
                      invalidDateMessage=""
                      showOnlyTouchedError={true}
                      touched={formik.touched.endDate}
                      inputProps={{
                        'data-test-id': 'endDateInput',
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
                <FroalaEditor
                  onContentChange={async (content) => {
                    await formik.setFieldValue('content', content);
                    formik.setFieldTouched('content', true);
                  }}
                  value={formik.values.content}
                  errorMsg={formik.errors.content}
                  showOnlyTouchedError={true}
                  touched={formik.touched.content}
                />
              </Paper>
              <PageFooter
                leftContent={[
                  <Button
                    key="backBtn"
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={() => {
                      returnToNewsfeedConfig();
                    }}
                    data-test-id="backBtn"
                  >
                    Back
                  </Button>,
                ]}
                rightContent={[
                  <Button
                    key="saveBtn"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                    loading={loading}
                    data-test-id={isNew ? 'addBtn' : 'saveBtn'}
                  >
                    {isNew && 'Add'}
                    {!isNew && 'Save'}
                  </Button>,
                ]}
              />
            </form>
          </>
        )}
        {isNotFoundError && (
          <ErrorPage
            showContactSupport={false}
            errorType={ERROR_TYPE.EDIT_NEWSFEED_NOT_FOUND}
            secondaryButton={
              <Button variant="contained" color="primary" onClick={returnToNewsfeedConfig}>
                Back to Newsfeed Configuration
              </Button>
            }
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default EditNewsfeed;
