import * as Types from './types';
import { FoodGroupOptions, OrderItem } from 'Interfaces/Interfaces';

export const setDate = (payload: string) => ({
  type: Types.MENU_ITEM_MODAL_SET_DATE,
  payload,
});

export const setMenuItemId = (payload: string) => ({
  type: Types.MENU_ITEM_MODAL_SET_MENU_ITEM_ID,
  payload,
});

export const setExistingOrder = (payload: boolean) => ({
  type: Types.MENU_ITEM_MODAL_SET_EXISTING_ORDER,
  payload,
});

export const setProductId = (payload: string) => ({
  type: Types.MENU_ITEM_MODAL_SET_PRODUCT_ID,
  payload,
});

export const setMealTypeId = (payload: string) => ({
  type: Types.MENU_ITEM_MODAL_SET_MENU_TYPE_ID,
  payload,
});

export const setOrderItems = (payload: OrderItem[]) => ({
  type: Types.MENU_ITEM_MODAL_SET_ORDER_ITEMS,
  payload,
});

export const setShowMenuItemModal = (payload: boolean) => ({
  type: Types.MENU_ITEM_MODAL_SET_SHOW_MENU_ITEM_MODAL,
  payload,
});

export const setSelectedFoodGroupOptions = (payload: FoodGroupOptions) => ({
  type: Types.MENU_ITEM_MODAL_SET_SELECTED_FOOD_GROUP_OPTONS,
  payload,
});

export const setPickupLocation = (payload: string) => ({
  type: Types.MENU_ITEM_MODAL_SET_PICKUP_LOCATION,
  payload,
});
