export const InputMode = {
  Alphanumeric: 'Alphanumeric',
  Decimal: 'Decimal',
  Integer: 'Integer',
  String: 'String',
};

export const SiteScope = {
  GLOBAL: 'GLOBAL',
  SITE: 'SITE',
};

export enum REPORT_TIME_FRAME {
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  CUSTOM = 'CUSTOM',
}

export enum REPORT_SCOPE {
  DISTRICT = 'DISTRICT',
  SITE = 'SITE',
  LOCATION = 'LOCATION',
  LOCATION_GLOBAL = 'LOCATION_GLOBAL',
}

export enum SidebarLocations {
  NONE = 1,
  CART_SIDE_BAR = 2,
  A_LA_CARTE_SIDE_BAR = 3,
  A_LA_CARTE_RULES_SIDE_BAR = 4,
  ADMIN_SIDE_BAR = 5,
  REIMB_PRICING_SIDE_BAR = 6,
  REIMB_RULES_SIDE_BAR = 7,
  BLACKOUT_DATES_RULES_SIDE_BAR = 8,
  LABEL_RULES_SIDE_BAR = 9,
  PICKUP_LOCATIONS_RULES_SIDE_BAR = 10,
  ORDERING_RULES_SIDE_BAR = 11,
  USER_INVITE_SIDE_BAR = 12,
  PICKUP_SIDE_BAR = 13,
  MENU_ITEM_SIDE_BAR = 14,
  ENTREE_SIDE_BAR = 15,
  CART = 16,
  ORDER_CONFIRMATION_SIDE_BAR = 17,
  REACT_TABLE_FILTER_SIDE_BAR = 18,
  ORDERING_COMMENTS_RULE_SIDE_BAR = 19,
  MENU_ORDER_RULE_SIDE_BAR = 20,
  BULK_ORDER_MODE_RULE_SIDE_BAR = 21,
}

export enum NotificationTypes {
  Error = 0,
  Warning = 1,
}

export enum InactiveStatuses {
  Withdrawn = 'WITHDRAWN',
  Inactive = 'INACTIVE',
}

export enum PrintLabelsStudentNameDisplayOrder {
  FIRST_LAST = 'FIRST_LAST',
  LAST_FIRST = 'LAST_FIRST',
}
