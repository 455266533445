import React from 'react';
import { TextField } from '@material-ui/core';
import { FilterProps } from 'react-table';

export function TextFilter({ column: { filterValue, preFilteredRows, setFilter } }: FilterProps<object>) {
  return (
    <TextField
      className="linq-input linq-input__table-filter"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Use undefined to stop filtering
      }}
      label="Search"
      style={{ width: '100%' }}
      variant="outlined"
    />
  );
}
