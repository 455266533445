import { Reducer } from 'react';
import { set, cloneDeep } from 'lodash';
import * as Interfaces from 'Interfaces/Interfaces';
import { REPORT_SCOPE } from 'Constants/Enums';
import * as Types from './types';

const initialState: Interfaces.DistrictReportState = {
  pickupLocationId: '',
  siteId: '',
  report: {},
  reportStudents: {},
  reportMenuTypeId: '',
  printLabelParameters: {
    [REPORT_SCOPE.LOCATION]: {
      range: {},
      day: {},
    },
    [REPORT_SCOPE.LOCATION_GLOBAL]: {
      range: {},
      day: {},
    },
    [REPORT_SCOPE.SITE]: {
      range: {},
      day: {},
    },
  },
};

const DistrictReportReducer: Reducer<Interfaces.DistrictReportState, Interfaces.Action> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case Types.DISTRICT_REPORT_SET_PICKUP_LOCATION_ID:
      return {
        ...state,
        pickupLocationId: payload,
      };

    case Types.DISTRICT_REPORT_SET_SITE_ID:
      return {
        ...state,
        siteId: payload,
      };

    case Types.DISTRICT_REPORT_SET_REPORT:
      return {
        ...state,
        report: payload,
      };

    case Types.DISTRICT_REPORT_SET_REPORT_STUDENTS:
      return {
        ...state,
        reportStudents: payload,
      };

    case Types.DISTRICT_REPORT_PATCH_ORDER_ITEMS: {
      const newReportStudents = cloneDeep(state.reportStudents);
      payload.forEach((orderItem: Interfaces.OrderItem) => {
        set(
          newReportStudents,
          [
            'ordersByStudent',
            orderItem?.studentId || '',
            orderItem.mealTypeId,
            orderItem.date,
            orderItem.productId,
            orderItem.id,
            'received',
          ],
          orderItem.received,
        );
      });
      return {
        ...state,
        reportStudents: newReportStudents,
      };
    }

    case Types.DISTRICT_REPORT_SET_REPORT_MENU_TYPE_ID:
      return {
        ...state,
        reportMenuTypeId: payload,
      };

    case Types.DISTRICT_REPORT_CHANGE_BULK_ORDER_QUANTITY: {
      const {
        reportStudents: { bulkOrdersByProduct },
      } = state;
      const newBulkOrdersByProduct = cloneDeep(bulkOrdersByProduct);
      const { menuTypeId, date, quantity, productId } = payload;

      if (newBulkOrdersByProduct[productId]?.[menuTypeId]?.[date]) {
        newBulkOrdersByProduct[productId][menuTypeId][date].received = { quantity };
      } else {
        return state;
      }

      return { ...state, reportStudents: { ...state.reportStudents, bulkOrdersByProduct: newBulkOrdersByProduct } };
    }

    case Types.DISTRICT_REPORT_SET_PRINT_LABEL_SCOPE_PARAMETERS: {
      const newPrintLabelParameters: any = { ...state.printLabelParameters };
      newPrintLabelParameters[payload.scope][payload.timeFrame] = payload.parameters;

      return { ...state, printLabelParameters: newPrintLabelParameters };
    }

    default:
      return state;
  }
};

export default DistrictReportReducer;
