import { get, cloneDeep, sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { SiteScope } from 'Constants/Enums';
import { REIMBURSABLE_MEAL_TYPES } from 'Constants/Constants';
import * as Interfaces from 'Interfaces/Interfaces';
import {
  filterLocationsBySite,
  filterLocationsBySiteAndHomeroom,
  sortAndGroupPickupLocations,
  sortPickupLocations,
  toPickupLocationOption,
} from 'Helpers/group';
import { getFriendlyNameFromSnakeCase } from 'Helpers/Helper';

const root = (state: Interfaces.AppState) => get(state, 'commonState');

export const getPickupLocations = (state: Interfaces.AppState): { [key: string]: Interfaces.PickupLocation } =>
  get(root(state), 'pickupLocations', {});
export const getMenuItems = (state: Interfaces.AppState): { [key: string]: Interfaces.MenuItem } =>
  get(root(state), 'menuItems', {});
export const getLogs = (state: Interfaces.AppState): { [key: string]: Interfaces.Log } => get(root(state), 'logs', {});
export const getProducts = (state: Interfaces.AppState): { [key: string]: Interfaces.Product } =>
  get(root(state), 'products', {});
export const getProductCategories = (state: Interfaces.AppState): { [key: string]: Interfaces.ProductCategory } =>
  get(root(state), 'productCategories', {});
export const getReimbursableMealTypes = (
  state: Interfaces.AppState,
): { [key: string]: Interfaces.ReimbursableMealType } => get(root(state), 'reimbursableMealTypes', {});
export const getSites = (state: Interfaces.AppState): { [key: string]: Interfaces.Site } =>
  get(root(state), 'sites', {});
export const getHomerooms = (state: Interfaces.AppState): { [key: string]: Interfaces.Homeroom } =>
  get(root(state), 'homerooms', {});
export const getSiteGroupTypes = (state: Interfaces.AppState): { [key: string]: Interfaces.SiteGroupType } =>
  get(root(state), 'siteGroupTypes', {});

export const getStudents = createSelector([(state: Interfaces.AppState) => state.commonState.students], (students) => {
  return Object.values(students)
    .sort((a, b) => {
      if (a.lastName > b.lastName) {
        return 1;
      } else if (a.lastName < b.lastName) {
        return -1;
      }
      if (a.firstName > b.firstName) {
        return 1;
      } else if (a.firstName < b.firstName) {
        return -1;
      }
      return 0;
    })
    .reduce((acc, student) => {
      const newStudent = cloneDeep(student);
      newStudent.siteGroupTypeId = newStudent.siteGroup;
      newStudent.reimbursableMealTypeId = newStudent.status;
      acc[student.id] = newStudent;
      return acc;
    }, {} as { [key: string]: Interfaces.Student });
});

export const getStudentsList = createSelector(getStudents, getSites, (studentsObj, sites) => {
  return (
    Object.values(studentsObj).map((student) => {
      student.siteName = sites[student.siteId]?.name;
      return student;
    }) || []
  );
});

export const getStudentTotalCount = (state: Interfaces.AppState): number => get(root(state), 'studentsTotalCount', 0);
export const getStudentLastUpdatedAt = (state: Interfaces.AppState): string =>
  get(root(state), 'studentsLastUpdatedAt', '');

export const getSpecialMealAccommodations = (
  state: Interfaces.AppState,
): { [key: string]: Interfaces.SpecialMealAccommodation } => get(root(state), 'specialMealAccommodations', {});
export const getOrderItems = (state: Interfaces.AppState): { [key: string]: Interfaces.OrderItem } =>
  get(root(state), 'orderItems', {});
export const getDistricts = (state: Interfaces.AppState): { [key: string]: Interfaces.District } =>
  get(root(state), 'districts', {});
export const getDistrict = (districtId: string) =>
  createSelector([getDistricts], (districts) => {
    return districts[districtId];
  });

export const getPickupLocationsWithDefault = createSelector(
  [getPickupLocations, getStudents, (state) => state.shopState.studentId, (state) => state.shopState.siteId],
  (pickupLocations, students, studentId, siteId) => {
    const student = students[studentId as string];
    let newPickupLocations = cloneDeep(pickupLocations);
    if (student) {
      if (student.status === REIMBURSABLE_MEAL_TYPES.ADULT.id && siteId) {
        newPickupLocations = filterLocationsBySite(pickupLocations, siteId);
      } else {
        newPickupLocations = filterLocationsBySiteAndHomeroom(pickupLocations, student.siteId, student.homeroomId);
      }
    }

    return newPickupLocations;
  },
);

export const getPickupLocationOptions = createSelector([getPickupLocationsWithDefault], (pickupLocations) => {
  const pickupLocationsSorted: Interfaces.PickupLocation[] = sortPickupLocations(pickupLocations);

  return pickupLocationsSorted.map((pickupLocation) => toPickupLocationOption(pickupLocation));
});

export const getPickupLocationOptionsByScope = createSelector([getPickupLocationOptions], (pickupLocations) => {
  return sortAndGroupPickupLocations(pickupLocations);
});

export const getPickupLocationsWithDefaultForDistrict = createSelector(
  [getPickupLocations, (state) => state.districtReportsState.siteId],
  (pickupLocations, siteId) => {
    let newPickupLocations = cloneDeep(pickupLocations);
    newPickupLocations = Object.values(newPickupLocations).reduce((acc, pickupLocation) => {
      if (!siteId || pickupLocation.siteId === siteId) {
        acc[pickupLocation.id] = pickupLocation;
      }
      return acc;
    }, {} as { [key: string]: Interfaces.PickupLocation });

    return newPickupLocations;
  },
);

export const getGlobalPickupLocations = createSelector([getPickupLocations], (pickupLocations) => {
  return Object.values(pickupLocations).filter((location) => {
    return location.scope === SiteScope.GLOBAL;
  });
});

export const getOrderItemsByMenuKey = createSelector([getOrderItems], (orderItems) => {
  return Object.values(orderItems).reduce((acc, orderItem) => {
    const menuKey = `${orderItem.studentId}.${orderItem.date}.${orderItem.mealTypeId}.${orderItem.productId}`;
    acc[menuKey] = cloneDeep(orderItem);
    return acc;
  }, {} as { [key: string]: Interfaces.OrderItem });
});

export const getSiteOptions = createSelector(getSites, (sites) => {
  return sortBy(Object.values(sites), 'name').map((site: Interfaces.Site) => ({
    text: site?.name,
    value: site?.id,
  }));
});

export const getGradeOptions = (districtId: string, includeEmpty = false) =>
  createSelector(getDistrict(districtId), (district) => {
    const options = [];

    if (includeEmpty) {
      options.push({ text: '', value: '' });
    }

    if (district && Array.isArray(district.grades)) {
      options.push(
        ...district.grades?.map((grade: string) => ({
          text: grade,
          value: grade,
        })),
      );
    }

    return options;
  });

export const getMenuItemsFromDate = createSelector(
  [getMenuItems, (_: Interfaces.AppState, date: string) => date],
  (menuItems, date) => {
    return Object.values(menuItems || {}).filter((mi) => mi.date === date);
  },
);

export const getOpenSidebars = (state: Interfaces.AppState): number[] => get(root(state), 'openSidebars', []);
export const getSidebarOpen = createSelector(
  (state: Interfaces.AppState) => getOpenSidebars(state),
  (_: Interfaces.AppState, location: number) => location,
  (sideBars, location) => {
    return sideBars.includes(location);
  },
);

export const getSiteReports = (districtId: string) =>
  createSelector([getSites, getDistrict(districtId)], (sites, district) => {
    return sortBy(
      Object.keys(sites)
        ?.map((siteId: string) => {
          const siteReport = {
            ...sites[siteId],
            free: 0,
            reduced: 0,
            paid: 0,
            adult: 0,
            totalStudents: 0,
          };

          if (district && district.siteBreakdowns && district.siteBreakdowns[siteId]) {
            const siteBreakdown = district.siteBreakdowns[siteId];
            siteReport.free = siteBreakdown.free;
            siteReport.reduced = siteBreakdown.reduced;
            siteReport.paid = siteBreakdown.paid;
            siteReport.adult = siteBreakdown.adult ?? siteBreakdown.adults;
            siteReport.totalStudents =
              (siteBreakdown.adult ?? siteBreakdown.adults) +
              siteBreakdown.paid +
              siteBreakdown.reduced +
              siteBreakdown.free;
          }

          siteReport.siteGroupTypeId = getFriendlyNameFromSnakeCase(siteReport.siteGroupTypeId);

          return siteReport;
        })
        .filter((site: any) => site.siteGroupTypeId),
      'name',
    );
  });
