import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import ButtonGroupCore from '@material-ui/core/ButtonGroup';
import Button from 'Components/Button/Button';
import './ButtonGroup.less';

export interface ButtonGroupOption {
  label: string;
  value: any;
  icon?: JSX.Element;
}

export interface ButtonGroupProps {
  onChange: (value: ButtonGroupOption) => void;
  options: ButtonGroupOption[];
  defaultOption?: ButtonGroupOption;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ options, onChange, defaultOption }) => {
  const [current, setCurrent] = useState<ButtonGroupOption>(defaultOption || options[0]);

  const onClick = useCallback(
    (option: ButtonGroupOption) => {
      setCurrent(option);
      onChange(option);
    },
    [onChange],
  );

  return (
    <ButtonGroupCore className="linq-button-group">
      {options.map((option) => (
        <Button
          type="button"
          key={option.label}
          onClick={() => {
            onClick(option);
          }}
          className={classNames(
            {
              focused: current.value === option.value,
            },
            option.icon && 'button-group-icon',
          )}
          data-test-id={`${option.label}.Btn`}
        >
          {option.label}
          {option.icon}
        </Button>
      ))}
    </ButtonGroupCore>
  );
};

export default ButtonGroup;
