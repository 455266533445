import React, { useCallback } from 'react';
import axios from 'axios';
import { request } from 'Api/useApi';
import { NotificationMessage } from 'Interfaces/Interfaces';
import { datadogLogs } from '@datadog/browser-logs';
import { ApiError } from 'Constants/ApiErrorCodes';
import { useSelector } from 'react-redux';

import * as CommonStateSelectors from 'State/selectors';
import { SidebarLocations } from 'Constants/Enums';
import { processApiErrorCodes } from 'Helpers/Helper';

export const NotificationContext = React.createContext<NotificationState>({
  errors: [],
  showAllErrors: [],
  updateShowAllErrors: (location: number) => {
    /* Do nothing */
  },
  addError: () => {
    /* Do nothing */
  },
  addErrors: () => {
    /* Do nothing */
  },
  clearErrors: (location?: number) => {
    /* Do nothing */
  },
  removeError: (index: number) => {
    /* Do nothing */
  },
  warnings: [],
  clearWarnings: (location?: number) => {
    /* Do nothing */
  },
  addWarning: () => {
    /* Do nothing */
  },
  addWarnings: () => {
    /* Do nothing */
  },
  showAllWarnings: [],
  updateShowAllWarnings: (location: number) => {
    /* Do nothing */
  },
  removeWarning: (index: number) => {
    /* Do nothing */
  },
});

export interface NotificationState {
  errors: Array<NotificationMessage>;
  showAllErrors: number[];
  updateShowAllErrors: (location: number) => void;
  addError: (error: NotificationMessage) => void;
  addErrors: (errors: NotificationMessage[]) => void;
  clearErrors: (location?: number) => void;
  removeError: (index: number) => void;
  warnings: Array<NotificationMessage>;
  showAllWarnings: number[];
  updateShowAllWarnings: (location: number) => void;
  addWarning: (warning: NotificationMessage) => void;
  addWarnings: (warnings: NotificationMessage[]) => void;
  clearWarnings: (location?: number) => void;
  removeWarning: (index: number) => void;
}

const NotificationContextProvider: React.FC = ({ children }) => {
  const [errors, setErrors] = React.useState<NotificationMessage[]>([]);
  const [showAllErrors, setShowAllErrors] = React.useState<number[]>([]);
  const [warnings, setWarnings] = React.useState<NotificationMessage[]>([]);
  const [showAllWarnings, setShowAllWarnings] = React.useState<number[]>([]);

  const openSideBars = useSelector(CommonStateSelectors.getOpenSidebars);

  const getBannerLocation = useCallback((): number => {
    const firstOpenSideBarLocation = openSideBars[0];

    return firstOpenSideBarLocation ?? SidebarLocations.NONE;
  }, [openSideBars]);

  const addWarning = useCallback(
    (warning: NotificationMessage) => {
      warning.location = warning.location ?? getBannerLocation();

      setWarnings((prevWarnings: NotificationMessage[]) => [...prevWarnings, warning]);
    },
    [getBannerLocation],
  );

  const addWarnings = useCallback(
    (addedWarnings: NotificationMessage[]) => {
      const locationAssignedWarnings = addedWarnings.map((warning: NotificationMessage) => {
        warning.location = warning.location ? warning.location : getBannerLocation();
        return warning;
      });
      setErrors((prevWarnings: NotificationMessage[]) => [...prevWarnings, ...locationAssignedWarnings]);
    },
    [getBannerLocation],
  );

  const clearWarnings = React.useCallback(
    (location: number) => {
      if (location) {
        setWarnings(() => warnings.filter((warning: NotificationMessage) => warning.location !== location));
        setShowAllWarnings(() => showAllWarnings.filter((showAllLocation: number) => showAllLocation !== location));
      } else {
        setWarnings(() => []);
      }
    },
    [warnings, showAllWarnings],
  );

  const removeWarning = React.useCallback(
    (index: number) => {
      const updatedWarnings = [...warnings];
      const warningLocation = updatedWarnings[index]?.location;
      updatedWarnings.splice(index, 1);
      const remainingWarnings = updatedWarnings.filter(
        (notifcation: NotificationMessage) => notifcation.location === warningLocation,
      ).length;
      if (warningLocation && showAllWarnings.includes(warningLocation) && remainingWarnings <= 1) {
        setShowAllWarnings(() => showAllWarnings.filter((location: number) => warningLocation !== location));
      }
      setWarnings(() => updatedWarnings);
    },
    [warnings, showAllWarnings],
  );

  const updateShowAllWarnings = React.useCallback(
    (location: number) => {
      if (showAllWarnings.includes(location)) {
        setShowAllWarnings(() => showAllWarnings.filter((showAllLocation) => location !== showAllLocation));
      } else {
        setShowAllWarnings((prevShowAllWarnings) => [...prevShowAllWarnings, location]);
      }
    },
    [showAllWarnings],
  );

  const addError = React.useCallback(
    (error: NotificationMessage) => {
      error.location = error.location ?? getBannerLocation();
      setErrors((prevErrors: NotificationMessage[]) => [...prevErrors, error]);
    },
    [getBannerLocation],
  );

  const addErrors = React.useCallback(
    (addedErrors: NotificationMessage[]) => {
      const locationAssignedError = addedErrors.map((error: NotificationMessage) => {
        error.location = error.location ?? getBannerLocation();
        return error;
      });

      setErrors((prevErrors: NotificationMessage[]) => [...prevErrors, ...locationAssignedError]);
    },
    [getBannerLocation],
  );

  const clearErrors = React.useCallback(
    (location: number) => {
      if (location) {
        setErrors(() => errors.filter((error: NotificationMessage) => error.location !== location));
        setShowAllErrors(() => showAllErrors.filter((showAllLocation: number) => showAllLocation !== location));
      } else {
        setErrors(() => []);
        setShowAllErrors(() => []);
      }
    },
    [errors, showAllErrors],
  );

  const removeError = React.useCallback(
    (index: number) => {
      const updatedErrors = [...errors];
      const errorLocation = updatedErrors[index]?.location;
      updatedErrors.splice(index, 1);
      const remainingErrors = updatedErrors.filter(
        (notifcation: NotificationMessage) => notifcation.location === errorLocation,
      ).length;

      if (errorLocation && showAllErrors.includes(errorLocation) && remainingErrors <= 1) {
        setShowAllErrors(() => showAllErrors.filter((location: number) => errorLocation !== location));
      }

      setErrors(() => updatedErrors);
    },
    [errors, showAllErrors],
  );

  const updateShowAllErrors = React.useCallback(
    (location: number) => {
      if (showAllErrors.includes(location)) {
        setShowAllErrors(() => showAllErrors.filter((showAllLocation) => location !== showAllLocation));
      } else {
        setShowAllErrors((prevShowAllErrors) => [...prevShowAllErrors, location]);
      }
    },
    [showAllErrors],
  );

  React.useEffect(() => {
    const responseInterceptor = (response: any) => {
      return response;
    };
    const errorInterceptor = (error: any) => {
      if (axios.isCancel(error)) {
        return Promise.reject(error);
      }
      if (error.response && error.response.status !== undefined) {
        const requestConfig = error.response?.config;
        const errorResponse = error.response?.data?.message;
        const errorName = `${requestConfig?.method.toUpperCase()} - ${requestConfig?.url}` || '';

        if (errorName && errorResponse) {
          datadogLogs.logger.error(`${errorName} error`, {
            error: errorResponse,
            name: errorName,
          });
        }

        //Add error with appropriate location set based on open slideouts
        addErrors(getNotificationsFromError(error.response));
      }

      return Promise.reject(error);
    };

    const errorInterceptorId = request.instance.interceptors.response.use(responseInterceptor, errorInterceptor);

    return () => request.instance.interceptors.response.eject(errorInterceptorId);
  }, [getBannerLocation, addErrors]);

  return (
    <NotificationContext.Provider
      value={
        {
          errors,
          showAllErrors,
          updateShowAllErrors,
          addError,
          addErrors,
          clearErrors,
          removeError,
          warnings,
          showAllWarnings,
          updateShowAllWarnings,
          addWarning,
          addWarnings,
          clearWarnings,
          removeWarning,
        } as NotificationState
      }
    >
      {children}
    </NotificationContext.Provider>
  );
};

const getNotificationsFromError = (response: any): NotificationMessage[] => {
  switch (response?.status) {
    case +(response?.status / 100).toFixed() === 5:
      //Use generic error message for all 500 erors
      return [{ message: ApiError.SERVER_ERROR.message }];
    case 401:
      //Use generic error message for all unauthorized requests
      return [{ message: ApiError.UNAUTHRIZED_ERROR.message }];
    case 403:
      //Use generic error message for all unauthorized requests
      return [{ message: ApiError.UNAUTHRIZED_ERROR.message }];
    default:
      const apiErrorCodes = response?.data?.attributes?.errorCodes as [string];
      if (apiErrorCodes && Array.isArray(apiErrorCodes) && apiErrorCodes.length > 0) {
        return processApiErrorCodes(apiErrorCodes, response?.data?.message);
      } else if (response?.status === 404) {
        return [
          {
            message: ApiError.RESOURCE_NOT_FOUND.message,
          },
        ];
      } else {
        return [
          {
            message: ApiError.BAD_REQUEST.message,
          },
        ];
      }
  }
};

export default NotificationContextProvider;
