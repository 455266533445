import React, { FC, ChangeEvent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SelectField } from 'Components/Form/MaterialForm';
import * as CommonSelectors from 'State/selectors';
import { useCommonActions } from 'State/actions';

import { setSiteId } from 'Components/SitePicker/state/actions';
import { getSiteId } from 'Components/SitePicker/state/selectors';

import './SitePicker.less';
import { useAuth0 } from '@auth0/auth0-react';
import { TokenClaims } from 'Auth/User';

export interface SitePickerProps {
  onChange?: (siteId: string, prevSiteId: string | null) => void;
  defaultToFirst?: boolean;
}

const SitePicker: FC<SitePickerProps> = ({ onChange, defaultToFirst }) => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const { getSites } = useCommonActions();
  const districtId = TokenClaims.getDistrictId(user);
  const siteOptions = useSelector(CommonSelectors.getSiteOptions);
  const siteId = useSelector(getSiteId);

  useEffect(() => {
    getSites(districtId);
  }, [dispatch, districtId, getSites]);

  useEffect(() => {
    if (!siteId && siteOptions.length && defaultToFirst) {
      // set selected site to first option
      dispatch(setSiteId(siteOptions[0].value));
    }
  }, [siteOptions, siteId, defaultToFirst, dispatch]);

  const onSiteChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e.target.value, siteId);
    }
    dispatch(setSiteId(e.target.value));
  };

  return (
    <SelectField
      fieldLabel="School Site"
      name="siteId"
      id="siteId"
      value={siteId || ''}
      onChange={onSiteChange}
      options={siteOptions}
      className="site-picker-select"
    />
  );
};

export default SitePicker;
