import { Paper } from '@material-ui/core';
import axios from 'axios';
import Button from 'Components/Button/Button';
import { useEditNewsfeedActions } from 'Components/EditNewsfeed/state/actions';
import { ComponentLoaderNoRedux } from 'Components/Loaders/Loaders';
import PageFooter from 'Components/PageFooter/PageFooter';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import { DATE_FORMAT_USA, ERROR_TYPE } from 'Constants/Constants';
import { Newsfeed } from 'Interfaces/Interfaces';
import moment from 'moment';
import ErrorPage from 'Pages/ErrorPage/ErrorPage';
import React from 'react';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { useParams } from 'react-router';

import './ParentNewsfeed.less';

const ParentNewsfeed = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { id, districtId } = useParams<Record<string, string | undefined>>();
  const [isNotFoundError, setNotFoundError] = React.useState<boolean>(false);
  const { getNewsfeed } = useEditNewsfeedActions();
  const [newsfeed, setNewsfeed] = React.useState<Newsfeed | null>(null);

  React.useEffect(() => {
    async function getParentNewsfeed() {
      try {
        setLoading(true);
        if (id && districtId) {
          const res = await getNewsfeed(districtId, id);
          setNewsfeed(res.data);
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const status: number | undefined = err.response?.status;

          if (status === 404) {
            setNotFoundError(true);
          }
        }
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      getParentNewsfeed();
    }
  }, [districtId, id, getNewsfeed]);

  const date = newsfeed?.startDate ? moment(newsfeed?.startDate).format(DATE_FORMAT_USA) : '';

  return (
    <React.Fragment>
      <SubHeader title={newsfeed ? `${date} ${date && '-'} ${newsfeed?.title || ''}` : 'Parent News'} />
      <ComponentLoaderNoRedux loading={loading} />
      {!loading && !isNotFoundError && (
        <Paper className="parent-newsfeed-container" elevation={3}>
          <FroalaEditorView model={newsfeed?.content || ''} />
        </Paper>
      )}
      {isNotFoundError && <ErrorPage showContactSupport={false} errorType={ERROR_TYPE.EDIT_NEWSFEED_NOT_FOUND} />}
      <PageFooter>
        <Button
          aria-label="Return to dashboard"
          fullWidth={true}
          key="closeBtn"
          variant="contained"
          color="primary"
          href="/"
          className="parent-newsfeed__close-btn"
          data-test-id="closeBtn"
        >
          Close
        </Button>
      </PageFooter>
    </React.Fragment>
  );
};

export default ParentNewsfeed;
