import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Divider,
  FormControl,
  IconButton,
  ListSubheader,
  MenuItem,
} from '@material-ui/core';
import TrashIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import Button from 'Components/Button/Button';
import { NotificationDialog } from 'Components/Dialogs/NotificationDialog';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'Components/Drawer';
import IncrementalNumberInput from 'Components/Form/IncrementalNumberInput';
import { SelectField } from 'Components/Form/MaterialForm';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import { PageLoader } from 'Components/Loaders/Loaders';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';
import { isViolatingOrderingRules } from 'Components/Shop/ShopUtil';
import * as Constants from 'Constants/Constants';
import { SidebarLocations } from 'Constants/Enums';
import { getSidesText } from 'Helpers/Helper';
import * as Interfaces from 'Interfaces/Interfaces';
import { groupBy, uniq } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

interface CartSidebarProps {
  open: boolean;
  onClose: () => void;
  students: Record<string, Interfaces.Student>;
  pickupLocationsByScope: { [key: string]: Interfaces.PickupLocation[] };
  cart: Interfaces.TeacherOrderingCart;
  bulkCart: Interfaces.TeacherBulkOrderingCart;
  menuTypes: Record<string, Interfaces.MenuType>;
  deleteStudentOrder: (studentId: string, productId: string, menuTypeId: string, date: string) => void;
  onPlaceOrderClick: (pickupLocationId: string) => void;
  loading: boolean;
  district: Interfaces.District;
  isTeacher: boolean;
  onBulkItemQuantityChange: (date: string, productId: string, quantity: number, menuTypeId: string) => void;
  deleteBulkItem: (date: string, productId: string, menuTypeId: string) => void;
}

const CartSidebar = ({
  onClose,
  open,
  pickupLocationsByScope,
  cart,
  bulkCart,
  students,
  menuTypes,
  deleteStudentOrder,
  onPlaceOrderClick,
  loading,
  district,
  isTeacher,
  onBulkItemQuantityChange,
  deleteBulkItem,
}: CartSidebarProps) => {
  const [dialogState, setShowDialogState] = React.useState<{ show: boolean; onOkClick: null | (() => void) }>({
    show: false,
    onOkClick: null,
  });
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const [pickupLocationId, setPickupLocationId] = React.useState<string>('');
  const cartHasItems = Object.keys(cart).length > 0 || Object.keys(bulkCart).length > 0;

  const sortedCartDates = React.useMemo(
    () => uniq(Object.keys(bulkCart).concat(Object.keys(cart)).sort()),
    [cart, bulkCart],
  );
  return (
    <form>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        className={classNames('menu-item-modal-container cart-drawer-container cart-sidebar', {
          mobile: isMobile,
        })}
      >
        <PageLoader />
        <div className="linq-drawer-form-content menu-item-modal-content">
          <DrawerHeader aria-label="Header for the cart.">
            <h2>Cart</h2>
            <CloseIconButton onClick={onClose} />
          </DrawerHeader>
          <NotificationBanner location={SidebarLocations.CART_SIDE_BAR} />
          <DrawerContent id="menu-item-modal-content" className="menu-item-modal-content">
            {cartHasItems && (
              <div className="cart-sidebar__pickup-location-select">
                <FormControl fullWidth>
                  <SelectField
                    name="pickupLocation"
                    id="pickupLocation"
                    value={pickupLocationId}
                    fieldLabel="Pickup Location"
                    onChange={(e) => {
                      setPickupLocationId(e.target.value);
                    }}
                    open={true}
                  >
                    {Object.entries(pickupLocationsByScope).map(
                      ([scope, pls]: [string, Interfaces.PickupLocation[]]) => {
                        if (!pls) {
                          return null;
                        }

                        const id = pls[0]?.id || 'pickupLocationId';
                        return [
                          <ListSubheader key={scope} value={id}>
                            {Constants.GROUP_LABELS[scope]}
                          </ListSubheader>,
                          ...pls.map((item) => (
                            <MenuItem className="select-field__menu-option" key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          )),
                        ];
                      },
                    )}
                  </SelectField>
                </FormControl>
              </div>
            )}
            {sortedCartDates.map((date: string) => {
              const areItemsExpired = isViolatingOrderingRules(district, date, moment(), district?.timezone, isTeacher);

              const cartItemsByMenuType = cart[date] || {};
              const bulkItemsByMenuType = bulkCart[date] || {};

              return (
                <React.Fragment key={date}>
                  <Divider />
                  <Accordion className="oo-accordion" defaultExpanded={true}>
                    <AccordionSummary
                      className="date-toggle"
                      expandIcon={<ExpandMoreIcon className="date-toggle__icon" />}
                      aria-controls={`${date}-content`}
                    >
                      {moment(date).format(Constants.DATE_FORMAT_DMDDY)}
                      {areItemsExpired && <ErrorIcon />}
                    </AccordionSummary>
                    <AccordionDetails>
                      {Object.keys(cartItemsByMenuType).length > 0 && (
                        <>
                          <h3 className="accordion-title">Individual Orders</h3>
                          <Divider />
                          {Object.entries(cartItemsByMenuType).map(([menuTypeId, menuTypeItems]) => {
                            const menuType = menuTypes[menuTypeId];

                            if (!menuType) {
                              return null;
                            }

                            return (
                              <React.Fragment key={menuTypeId}>
                                <div className="menu-type">{menuType.name}</div>
                                {Object.entries(menuTypeItems).map(([cartId, cartItem]) => {
                                  const entreeName = cartItem?.entree?.product?.name;
                                  const menuTypeId = cartItem.entree?.mealTypeId;
                                  const sideItems = getSidesText(cartItem.sides);

                                  return (
                                    <React.Fragment key={cartId}>
                                      <div className="cart-sidebar__product-name">{entreeName}</div>
                                      <div className="cart-sidebar__sides">{sideItems}</div>
                                      {cartItem.studentIds.map((studentId: string) => {
                                        const student = students[studentId];

                                        if (!student) {
                                          return null;
                                        }

                                        const studentName = `${student.firstName} ${student.lastName}`;
                                        return (
                                          <div className="student-row" key={studentId}>
                                            {student.profilePicture && (
                                              <Avatar
                                                className="student-img"
                                                src={student.profilePicture}
                                                alt={studentName}
                                              />
                                            )}
                                            {!student.profilePicture && (
                                              <Avatar className="student-img" src="" alt={studentName} />
                                            )}

                                            <div className="student-name">{`${studentName} | ${student.sisId}`}</div>
                                            <div className="student-actions">
                                              <IconButton
                                                size="medium"
                                                aria-label="Remove student cart item"
                                                onClick={() =>
                                                  setShowDialogState({
                                                    show: true,
                                                    onOkClick: () =>
                                                      deleteStudentOrder(student.id, cartId, menuTypeId, date),
                                                  })
                                                }
                                                data-test-id={`${studentName}.${entreeName}.deleteBtn`}
                                              >
                                                <TrashIcon />
                                              </IconButton>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </React.Fragment>
                                  );
                                })}
                              </React.Fragment>
                            );
                          })}
                        </>
                      )}
                      {Object.keys(bulkItemsByMenuType).length > 0 && (
                        <>
                          <h3 className="accordion-title">Bulk</h3>
                          <Divider />
                          {Object.entries(bulkItemsByMenuType).map(([menuTypeId, bulkItems]) => {
                            const menuType = menuTypes[menuTypeId];

                            if (!menuType) {
                              return null;
                            }
                            const bulkItemsByCategory = groupBy(
                              bulkItems,
                              (bulkItem) => bulkItem.menuItem?.product?.productCategoryId,
                            );

                            return (
                              <React.Fragment key={menuTypeId}>
                                <div className="menu-type">{menuType.name}</div>
                                {Object.entries(bulkItemsByCategory).map(([category, categoryItems]) => {
                                  return (
                                    <div key={category}>
                                      <div className="cart-sidebar__bulk-product-category">{category}</div>
                                      {categoryItems.map((bulkItem) => (
                                        <React.Fragment key={bulkItem.menuItem.id}>
                                          <div className="cart-sidebar__bulk-row">
                                            <div className="cart-sidebar__product-name">
                                              {bulkItem.menuItem.product.name}
                                            </div>
                                            <IncrementalNumberInput
                                              className="cart-sidebar__number-input"
                                              min={0}
                                              max={Constants.BULK_ORDER_ITEM_LIMIT}
                                              onChange={(value) => {
                                                const quantity = Number(value);
                                                onBulkItemQuantityChange(
                                                  date,
                                                  bulkItem.menuItem.productId,
                                                  quantity,
                                                  menuTypeId,
                                                );
                                              }}
                                              onAddOrSubstract={(quantity) => {
                                                onBulkItemQuantityChange(
                                                  date,
                                                  bulkItem.menuItem.productId,
                                                  quantity,
                                                  menuTypeId,
                                                );
                                                if (quantity === 0) {
                                                  setShowDialogState({
                                                    show: true,
                                                    onOkClick: () =>
                                                      deleteBulkItem(date, bulkItem.menuItem.productId, menuTypeId),
                                                  });
                                                }
                                              }}
                                              value={bulkItem.quantity}
                                              inputHeight={8}
                                              onDeleteClick={() => {
                                                setShowDialogState({
                                                  show: true,
                                                  onOkClick: () =>
                                                    deleteBulkItem(date, bulkItem.menuItem.productId, menuTypeId),
                                                });
                                              }}
                                              onBlur={() => {
                                                if (bulkItem.quantity === 0) {
                                                  setShowDialogState({
                                                    show: true,
                                                    onOkClick: () =>
                                                      deleteBulkItem(date, bulkItem.menuItem.productId, menuTypeId),
                                                  });
                                                }
                                              }}
                                            />
                                          </div>
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  );
                                })}
                              </React.Fragment>
                            );
                          })}
                        </>
                      )}
                      {areItemsExpired && (
                        <div className="items-expired">
                          <ErrorIcon />
                          <span className="text">These items have expired and will be removed from your order.</span>
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </React.Fragment>
              );
            })}
          </DrawerContent>
          <DrawerFooter className="footer" aria-label="Footer for the cart">
            <Button
              variant="outlined"
              color="primary"
              disabled={loading}
              onClick={onClose}
              data-test-id="continueOrderingBtn"
            >
              Continue Ordering
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!pickupLocationId || loading || !cartHasItems}
              type="submit"
              onClick={() => {
                if (pickupLocationId) {
                  onPlaceOrderClick(pickupLocationId);
                }
              }}
              loading={loading}
              data-test-id="placeOrderBtn"
            >
              Place Order
            </Button>
          </DrawerFooter>
        </div>
      </Drawer>
      <NotificationDialog
        showDialog={dialogState.show}
        onOkClick={() => {
          setShowDialogState((state) => {
            if (state.onOkClick) {
              state.onOkClick();
            }
            return { show: false, onOkClick: null };
          });
        }}
        onCancelClick={() => {
          setShowDialogState({ show: false, onOkClick: null });
        }}
        cancelButtonText="Cancel"
        showCancel={true}
        title="Remove Item From Cart"
        message="Are you sure you want to remove this item from the cart?"
        okButtonText="Ok"
      />
    </form>
  );
};

export default CartSidebar;
