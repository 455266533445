import { useAuth0 } from '@auth0/auth0-react';
import {
  AccountBalanceWallet,
  Assignment,
  AttachMoney,
  Equalizer,
  Group,
  LocalDining,
  Place,
  Description,
  Tune,
  CloudUpload,
  Input,
  School,
  DateRange,
  MenuBook
} from '@material-ui/icons';
import { Card, CardActionArea } from '@material-ui/core';
import { isAuthorized, Role, TokenClaims } from 'Auth/User';
import classNames from 'classnames';
import { PageLoader } from 'Components/Loaders/Loaders';
import { MOBILE_MEDIA_QUERY } from 'Constants/Constants';
import { getRoutePath, RouteId } from 'Constants/Routes';
import { District } from 'Interfaces/Interfaces';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import * as CommonHooks from 'State/hooks';
import * as CommonSelectors from 'State/selectors';
import './DistrictDashboard.less';

interface DashboardTile {
  label: string;
  icon: any;
  link: string;
  id: string;
  allowedRoles: Role[];
  isAuthorized: (userRole: Role, allowedRoles: Role[], district: District) => boolean;
}

const tiles: DashboardTile[] = [
  {
    label: 'Meals Reporting',
    icon: <Equalizer />,
    link: getRoutePath(RouteId.DISTRICT_REPORTS),
    id: 'districtReportsButton',
    allowedRoles: [Role.DIRECTOR, Role.MANAGER],
    isAuthorized,
  },
  {
    label: 'Account Management',
    icon: <Assignment />,
    link: getRoutePath(RouteId.STUDENT_MANAGEMENT),
    id: 'datamanagementButton',
    allowedRoles: [Role.DIRECTOR],
    isAuthorized,
  },
  {
    label: 'User Management',
    icon: <Group />,
    link: getRoutePath(RouteId.USER_MANAGEMENT),
    id: 'usermanagementButton',
    allowedRoles: [Role.DIRECTOR],
    isAuthorized,
  },
  {
    label: 'Pricing Management',
    icon: <AttachMoney />,
    link: getRoutePath(RouteId.PRICING_MANAGEMENT),
    id: 'pricingManagementButton',
    allowedRoles: [Role.DIRECTOR],
    isAuthorized,
  },
  {
    label: 'Ordering Rules',
    icon: <Tune />,
    link: getRoutePath(RouteId.ORDERING_RULES),
    id: 'orderingrulesButton',
    allowedRoles: [Role.DIRECTOR],
    isAuthorized,
  },
  {
    label: 'A la Carte',
    icon: <LocalDining />,
    link: getRoutePath(RouteId.A_LA_CARTE),
    id: 'alacarteButton',
    allowedRoles: [Role.DIRECTOR],
    isAuthorized,
  },
  {
    label: 'Preview Menus',
    icon: <MenuBook />,
    link: getRoutePath(RouteId.PREVIEW_MENUS),
    id: 'previewMenus',
    allowedRoles: [Role.DIRECTOR],
    isAuthorized,
  },
  {
    label: 'Configure Pickup Locations',
    icon: <Place />,
    link: getRoutePath(RouteId.PICKUP_LOCATIONS),
    id: 'pickupLocationsButton',
    allowedRoles: [Role.DIRECTOR, Role.MANAGER],
    isAuthorized,
  },
  {
    label: 'Configure Payment Provider',
    icon: <AccountBalanceWallet />,
    link: getRoutePath(RouteId.PAYMENT_PROVIDER),
    id: 'paymentProviderButton',
    allowedRoles: [Role.DIRECTOR],
    isAuthorized,
  },
  {
    label: 'Configure Parent Newsfeed',
    icon: <Description />,
    link: getRoutePath(RouteId.NEWS_FEED_MANAGEMENT),
    id: 'newsfeedButton',
    allowedRoles: [Role.DIRECTOR],
    isAuthorized,
  },
  {
    label: 'Configure SyntheSIS',
    icon: <CloudUpload />,
    link: getRoutePath(RouteId.SYNTHESIS),
    id: 'synthesisButton',
    allowedRoles: [Role.DIRECTOR],
    isAuthorized: (userRole: Role, allowedRoles: Role[], district: District) =>
      isAuthorized(userRole, allowedRoles) && district?.synthesisIntegration === true,
  },
  {
    label: 'Import Data',
    icon: <Input />,
    link: getRoutePath(RouteId.IMPORT_DATA),
    id: 'importdataButton',
    allowedRoles: [Role.DIRECTOR],
    isAuthorized,
  },
  {
    label: 'Site Information',
    icon: <School />,
    link: getRoutePath(RouteId.SITE_INFORMATION),
    id: 'siteInformation',
    allowedRoles: [Role.DIRECTOR],
    isAuthorized,
  },
  {
    label: 'Close School Year',
    icon: <DateRange />,
    link: getRoutePath(RouteId.CLOSE_SCHOOL_YEAR),
    id: 'closeSchoolYear',
    allowedRoles: [Role.DIRECTOR],
    isAuthorized,
  },
];

const DistrictDashboard: FC<{}> = () => {
  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const userDistrictId = TokenClaims.getDistrictId(user);
  const districts = useSelector(CommonSelectors.getDistricts);
  const district = districts[userDistrictId];
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY });
  const EVEN_GRID_NUMBER = 9;
  const displaySpaceFillers = Role.DIRECTOR === userRole && tiles.length < EVEN_GRID_NUMBER;

  CommonHooks.useDistrictCommon(true);

  return (
    <React.Fragment>
      <PageLoader />
      <div
        className={classNames('district-dashboard-container', {
          'contains-limited-set': !displaySpaceFillers,
          'district-dashboard-container-mobile': isMobile,
        })}
      >
        {tiles.map(
          ({ label, icon, link, id, allowedRoles, isAuthorized }: DashboardTile) =>
            isAuthorized(userRole, allowedRoles, district) && (
              <Link className="district-dashboard__card-link" to={link} key={id}>
                <Card className="district-dashboard__card">
                  <CardActionArea className="district-dashboard__card-area" data-test-id={`${label}.Btn`}>
                    {icon}
                    <span className="district-dashboard__card-text">{label}</span>
                  </CardActionArea>
                </Card>
              </Link>
            ),
        )}
        {!displaySpaceFillers
          ? null
          : Array.from({ length: EVEN_GRID_NUMBER - tiles.length }, (_, i) => i + 1).map((i) => (
            <div className="filler-space" key={i} />
          ))}
      </div>
    </React.Fragment>
  );
};

export default DistrictDashboard;
