export interface ApiErrorDetails {
  useApiErrorMessage: boolean;
  message: string;
  hideError?: boolean;
}

export const ApiError: Record<string, ApiErrorDetails> = {
  //Generic Server Error message for 500 status codes
  SERVER_ERROR: {
    useApiErrorMessage: false,
    message: 'An error has occured while processing the request. If this issue persists, please contact support.',
  },

  //Generic Unauthorized Request message for 401 status codes
  UNAUTHRIZED_ERROR: {
    useApiErrorMessage: false,
    message: 'Your account does not have the proper permissions to access this resource.',
  },

  //Generic Bad Request message for 400 status codes
  BAD_REQUEST: {
    useApiErrorMessage: false,
    message: 'An error has occured while processing the request. If this issue persists, please contact support.',
  },

  //Generic Resource Not Found message for 404 status codes
  RESOURCE_NOT_FOUND: {
    useApiErrorMessage: false,
    message: 'Unable to find the specified resource. If this issue persists, please contact support',
  },

  //Specific Error Messages
  INVALID_FILE_TYPE_ERROR: {
    useApiErrorMessage: false,
    message:
      'Unsupported file type: Only files with the extensions .gif, .jpg, .jpeg, .png, .pdf, .txt, and .csv are accepted.',
  },
  GET_MENU_ITEMS_MENU_TYPIE_ID_REQUIRED_ERROR: {
    useApiErrorMessage: false,
    message: 'Could not retrieve menu items, menu id is a required field.',
  },
  GET_PICKUP_LOCATIONS_LIMIT_NOT_SUPPORTED_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to retrieve pickup locations.',
  },
  GET_REPORT_ORDERS_MENU_ID_REQUIRED_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to retrieve report, menu id is required.',
  },
  GET_REPORT_TEACHER_SITE_ID_HOMEROOM_ID_REQUIRED_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to retrieve report, Teacher and/or Homeroom Id are required.',
  },
  GET_USERS_DISTRICT_ID_REQUIRED_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to retrieve Users, District Id is required.',
  },
  POST_NEWSFEED_START_DATE_INVALID_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to create newsfeed item, the start date is invalid.',
  },
  POST_NEWSFEED_END_DATE_INVALID_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to create newsfeed item, the end date is invalid.',
  },
  POST_NEWSFEED_START_DATE_BEFORE_END_DATE_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to create newsfeed item, the start date must be before the end date.',
  },
  POST_NEWSFEED_END_DATE_AFTER_START_DATE_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to create newsfeed item, the end date must be after the start date.',
  },
  POST_NEWSFEED_MISSING_START_DATE_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to create newsfeed item, start date is required.',
  },
  POST_NEWSFEED_MISSING_CONTENT_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to create newsfeed item, content is required.',
  },
  POST_NEWSFEED_MISSING_TITLE_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to create newsfeed item, title is required.',
  },
  PATCH_NEWSFEED_START_DATE_INVALID_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to update newsfeed item, the start date is invalid.',
  },
  PATCH_NEWSFEED_END_DATE_INVALID_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to update newsfeed item, the start date is invalid.',
  },
  PATCH_NEWSFEED_START_DATE_BEFORE_END_DATE_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to update newsfeed item, the start date must be before the end date.',
  },
  PATCH_NEWSFEED_END_DATE_AFTER_START_DATE_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to update newsfeed item, the end date must be after the start date.',
  },
  PATCH_NEWSFEED_MISSING_START_DATE_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to update newsfeed item, start date is required.',
  },
  PATCH_NEWSFEED_MISSING_ID_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to update newsfeed item, id is required.',
  },
  PATCH_ORDER_ITEMS_MISSING_ORDER_ITEMS_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to update order items, no order items were provided.',
  },
  POST_CANCELLATIONS_MISSING_DISTRICT_ID_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to process cancellation, district id is required.',
  },
  POST_MIGRATE_MISSING_DISTRICT_ID: {
    useApiErrorMessage: false,
    message: 'Unable to process migration, district id is required.',
  },
  POST_PICKUP_LOCATIONS_INVALID_PICKUP_LOCATION_ID_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to create pickup locations, the pickup location is invalid.',
  },
  POST_PICKUP_LOCATIONS_INVALID_SITE_ID_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to create pickup locations, the site id is invalid.',
  },
  POST_SITES_MISSING_SITES_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to create sites, no sites were provided.',
  },
  POST_SYNC_CORRECT_DAILY_REPORT_MISSING_FROM_DATE_ERROR: { useApiErrorMessage: true, message: '' },
  SYNC_SYNTHESIS_PAGE_DISTRICT_NO_CONFIGURATION_ERROR: { useApiErrorMessage: true, message: '' },
  DISTRICT_MISSING_DISTRICT_ID_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to process order, district id is required.',
  },
  ORDER_ITEM_UNSUPPORTED_QUERY_ERROR: { useApiErrorMessage: false, message: 'Unable to retrieve order items.' },
  ORDER_ITEM_MISSING_FROM_DATE_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to retrieve order items, date from is required.',
  },
  ORDER_ITEM_MISSING_REQUIRED_FIELD: {
    useApiErrorMessage: false,
    message: 'Unable to retrieve order items, a required field is missing.',
  },
  ORDER_ITEM_STUDENT_SITE_PICKUP_LOCATION_MISMATCH_ERROR: {
    useApiErrorMessage: false,
    message: "Unable to place order, the student's site does not match the pickup location.",
  },
  ORDER_ITEM_STUDENT_SUSPENDED_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to place order, student is suspended and cannot place orders.',
  },
  ORDER_ITEM_ADULT_INACTIVE_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to place order, adult is inactive and cannot place orders.',
  },
  ORDER_ITEM_STUDENT_WITHDRAWN_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to place order, student is withdrawn and cannot place orders.',
  },
  ORDER_ITEM_UPDATE_ERROR: { useApiErrorMessage: false, message: 'Unable to place order, an error occured.' },
  ORDER_ITEM_ORDER_DISTRICT_ID_MISMATCH_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to process order, the order district does not match the current account district.',
  },
  ORDER_ITEM_NO_PICKUP_LOCATION_ERROR: { useApiErrorMessage: true, message: '' },

  ORDER_ITEM_CANNOT_CANCEL_RECEIVED_ITEM_ERROR: {
    useApiErrorMessage: true,
    message: 'Order item has already been received and can not be canceled.',
  },

  ORDER_ITEM_CANNOT_CANCEL_PAST_ITEM_ERROR: {
    useApiErrorMessage: true,
    message: 'Order item is in the past and cannot be canceled.',
  },
  ORDER_ITEM_ENTREE_ALREADY_ORDERED_ERROR: {
    useApiErrorMessage: true,
    message: 'Unable to place order. Entree meal already ordered.',
  },
  ORDER_ITEM_BULK_ORDERING_DISABLED: {
    useApiErrorMessage: false,
    message: 'Unable to place order. Bulk Ordering has been disabled.',
  },
  PRODUCT_MISSING_DISTRICT_ID_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to create product, district id is a required field and was not provided',
  },
  PRODUCT_MISSING_PRODUCT_ID_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to create product, product id is a required field and was not provided',
  },
  PRODUCT_MISSING_PRODUCT_NAME_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to create product, product name is a required field and was not provided ',
  },
  PRODUCT_PRICE_INVALID_ERROR: {
    useApiErrorMessage: true,
    message: '',
  },
  REIMB_MEAL_PRICE_MISSING_MENU_ID_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to retrieve reimbursable meal prices, menu id is a required field and was not provided.',
  },
  REIMB_MEAL_PRICE_MISSING_REQUIRED_FIELDS_ERROR: {
    useApiErrorMessage: true,
    message: 'Unable to set reimbursable meal price, all the required fields were not provided.',
  },
  REPORT_BUILDER_MISSING_DISTRICT_ID_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to retrieve report, district id is a required field and was not provided.',
  },
  REPORT_BUILDER_MISSING_FROM_DATE: {
    useApiErrorMessage: false,
    message: 'Unable to retrieve report, from date is a required field and was not provided.',
  },
  ORDER_REPORT_MISSING_DISTRICT_ID_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to retrieve order report, district id is a required field and was not provided.',
  },
  STUDENT_REPORT_MISSING_FROM_DATE_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to retrieve student report, from date is a required field and was not provided.',
  },
  STUDENT_REPORT_UNSUPPORT_REPORT_TYPE_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to retrieve student report, the report type is not supported.',
  },
  SHOP_MISSING_DISTRICT_ID_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to process request, district id is required and was not provided',
  },
  SHOP_MISSING_FROM_OR_TO_DATE_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to process request, from date and to date are both required.',
  },
  SHOP_STUDENT_NOT_FOUND_ERROR: {
    useApiErrorMessage: true,
    message: '',
  },
  STUDENT_UPDATE_MISSING_DISTRICT_ID_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to update student, district id is a required field and was not provided.',
  },
  STUDENT_UPDATE_MISSING_SIS_ID_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to update student, SIS id is a required field and was not provided.',
  },
  STUDENT_CREATE_MISSING_REQUIRED_FIELDS_ERROR: {
    useApiErrorMessage: true,
    message: 'Unable to create student, the required fields are missing.',
  },
  STUDENT_NAME_MISMATCH_ERROR: {
    useApiErrorMessage: true,
    message: 'Unable to process request, the student does not match the name in the SIS provider.',
  },
  STUDENT_NOT_FOUND_ERROR: {
    useApiErrorMessage: true,
    message: '',
  },
  TEACHER_REPORT_MISSING_FROM_DATE_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to retrieve teacher report, from date is required.',
  },
  TEACHER_REPORT_MISSING_TO_DATE_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to retrieve teacher report, to date is required.',
  },
  CUSTOMER_CANCELLATION_DISTRICT_ID_DOES_NOT_EXIST_ERROR: {
    useApiErrorMessage: true,
    message: 'Unable to process cancellation, district id does not exist.',
  },
  EMAIL_UNABLE_TO_LOAD_TEMPLATE_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to retrieve email template.',
  },
  SITE_NOT_FOUND_ERROR: {
    useApiErrorMessage: false,
    message: 'Site not found.',
    hideError: true,
  },
  INVALID_EMAIL_IN_USER_INVITES_ERROR: {
    useApiErrorMessage: true,
    message: '',
  },
  ADULT_INACTIVE_ERROR: {
    useApiErrorMessage: true,
    message: '',
  },
  STUDENT_WITHDRAWN_ERROR: {
    useApiErrorMessage: true,
    message: '',
  },
  VALIDATE_SELF_SIGN_UP_EMAIL_ADDRESS_EXISTS: {
    useApiErrorMessage: false,
    message: '',
    hideError: true,
  },
  SELF_SIGN_UP_ERROR: {
    useApiErrorMessage: true,
    message: '',
  },
  LINKED_USERS_MISSING_STUDENT_ID_ERROR: {
    useApiErrorMessage: false,
    message: 'Unable to retreive linked parents.',
  },
};
