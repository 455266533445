import React, { ReactElement } from 'react';
import { useBarcode } from '@createnextapp/react-barcode';

import * as Interfaces from 'Interfaces/Interfaces';

import './Barcode.less';

const Barcode = (props: Interfaces.BarcodeProps): ReactElement => {
  const { value, options } = props;
  //Removes any characters that cannot be encoded to CODE39
  const sanitizedValue = value.replace(/[^-0-9A-Z.$/+% ]/, '');

  const { inputRef } = useBarcode({
    value: sanitizedValue,
    options: {
      format: 'CODE39',
      displayValue: false,
      ...options,
    },
  });

  return <svg ref={inputRef} />;
};

export default Barcode;
