import * as _ from 'lodash';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import { eachDayOfInterval, isBefore, isSameDay, isWeekend } from 'date-fns';
import { isViolatingOrderingRules } from 'Components/Shop/ShopUtil';
import { ApiError } from 'Constants/ApiErrorCodes';
import {
  BULK_ORDER_ITEM_ID,
  BULK_ORDER_ITEM_LIMIT,
  DATE_FORMAT_YMD,
  PRODUCT_CATEGORIES_SORT_ORDER,
} from 'Constants/Constants';
import * as Enums from 'Constants/Enums';
import * as Interfaces from 'Interfaces/Interfaces';

export const getValueFromInputMode = ({
  mode,
  size,
  value,
  min,
  allowNegative = true,
}: Interfaces.InputValidationProps): string | number => {
  if (mode === Enums.InputMode.Decimal && size[1] === 0) {
    const error = {
      message: 'Use InputMode.Integer for 0 decimal places',
    };

    throw error;
  }

  const isDecimalOrIntegerMode = mode === Enums.InputMode.Decimal || mode === Enums.InputMode.Integer;

  if (!allowNegative && isDecimalOrIntegerMode) {
    value = value.replace('-', '');
  }

  //need to allow an extra character left of the decimal
  //if the negative sign is present
  if (value.indexOf('-') !== -1) {
    size[0] += 1;
  }

  switch (mode) {
    case Enums.InputMode.Integer:
      value = value.replace(/[^\d-]/g, '');

      if (min && parseInt(value, 10) < min) {
        return min;
      }

      if (value.length <= size[0] - (size[1] || 0)) {
        return value;
      }

      return value.substring(0, size[0]);
    case Enums.InputMode.Decimal:
      const newValue = value.replace(/[^\d.-]/g, '');

      switch ((newValue.match(/[.]/g) || []).length) {
        case 0:
          //no decimal points
          if (newValue.length <= size[0] - size[1]) {
            return newValue;
          }

          return newValue.substring(0, size[0] - size[1]);

        case 1:
          //1 decimal point
          const parts = newValue.split('.');

          if (parts[0].length > size[0] - size[1]) {
            parts[0] = parts[0].substring(0, parts[0].length - 1);
          }

          if (parts[1].length > size[1]) {
            parts[1] = parts[1].substring(0, parts[1].length - 1);
          }

          return parts.join('.');

        //too many decimal points
        default:
          return value.substring(0, value.length - 1);
      }

    case Enums.InputMode.String:
      return value.replace(/[^a-zA-z\s]/g, '');
    case Enums.InputMode.Alphanumeric:
      return value.replace(/[^a-z0-9]/gi, '');

    default:
      return value;
  }
};

export const createOrderDetails = (orderItems: any[]) => {
  return orderItems
    .reduce((acc: any, orderItem: any) => {
      if (orderItem.count > 1) {
        acc.push(`${orderItem.name} x${orderItem.count}`);
      } else {
        acc.push(orderItem.name);
      }

      return acc;
    }, [])
    .join(' | ');
};

export const validateFiles = (files: any, allowedExtensions: string[] = []) => {
  return files.every((file: any) => {
    const extension = file.name?.substring(file.name.lastIndexOf('.'), file.name.length).toLowerCase();
    return allowedExtensions.includes(extension);
  });
};

export const validateFileNames = (files: any[], validFilenames: string[], fileExtentions?: string[]) => {
  let filteredFiles = files;
  if (fileExtentions) {
    filteredFiles = filteredFiles.filter((file: any) => {
      const extension = file.name?.substring(file.name.lastIndexOf('.'), file.name.length).toLowerCase();
      return fileExtentions.includes(extension);
    });
  }

  return filteredFiles.every((file: any) => {
    return validFilenames.includes(file.name.toLowerCase());
  });
};

export const toTitleCase = (str: string) => {
  return str
    .replace('_', ' ')
    .toLowerCase()
    .replace(/\b[a-z]/g, (x) => x.toUpperCase());
};

export const formatAllergens = (allergens: string) => {
  if (!allergens) {
    return '';
  }
  // Add whitespace around |
  const tmp = allergens.replace(/\|/g, ' | ');

  // Remove excess whitespace
  return tmp.replace(/  +/g, ' ');
};

export const isEmpty = (str: string | number | null | undefined) => {
  return str === undefined || str === null || str === '';
};

export const export2csv = (filename?: string) => {
  let data = '';
  const tableData = [];
  const rows = document.querySelectorAll(`table tr`);
  for (const row of rows) {
    const rowData = [];
    for (const [index, column] of row.querySelectorAll('th, td').entries()) {
      // To retain the commas in the "Description" column, we can enclose those fields in quotation marks.
      if ((index + 1) % 3 === 0) {
        rowData.push('"' + column.textContent?.replace(/,/g, ' ') + '"');
      } else {
        rowData.push(column.textContent?.replace(/,/g, ' '));
      }
    }
    tableData.push(rowData.join(','));
  }
  data += tableData.join('\n');
  const a = document.createElement('a');
  a.href = URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
  a.setAttribute('download', `${filename}.csv`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const sortIsoDateString = (a: string, b: string) => {
  if (moment(a) > moment(b)) {
    return 1;
  }
  if (moment(a) < moment(b)) {
    return -1;
  }
  return 0;
};

export const createDayRange = ({
  startDate,
  endDate,
  unit,
}: {
  startDate: moment.Moment;
  unit?: moment.unitOfTime.Base;
  endDate?: moment.Moment;
}) => {
  let currentDate = startDate.clone();
  let end: moment.Moment;

  if (endDate) {
    end = endDate.clone();
  } else {
    end = startDate.clone();

    if (unit) {
      end.endOf(unit);
      currentDate.startOf(unit);
    }
  }

  const dates = [];
  while (currentDate.isSameOrBefore(end)) {
    dates.push(currentDate);
    currentDate = currentDate.clone().add(1, 'd');
  }
  return { startDate, endDate: end, dates };
};

export const excludeIdFromOrderSortId = (orderItemId: string) => {
  const orderIdTokens = orderItemId.split('.');
  orderIdTokens.pop();

  return orderIdTokens.join('.');
};

export const createTeacherOrderItem = ({
  menuItem,
  menuType,
  studentId,
  districtId,
  date,
  siteId,
  homeroomId,
  pickupLocationId,
}: {
  id: string;
  menuItem: Interfaces.MenuItem;
  menuType: Interfaces.MenuType;
  studentId: string;
  districtId: string;
  date: string;
  siteId: string;
  homeroomId: string;
  pickupLocationId: string;
}): Interfaces.OrderItem => {
  const product = menuItem.product;

  return {
    districtId,
    date,
    price: 0,
    id: '',
    studentId,
    mealTypeId: menuType.id,
    mealTypeName: menuType.name,
    productId: product.id,
    pickupLocationId: pickupLocationId,
    siteId,
    homeroomId,
    productCategoryId: product.productCategoryId,
  };
};

export const createOrderItemsFromTeacherCart = ({
  cart,
  menuTypes,
  pickupLocationId,
  students,
  homeroomId,
  district,
}: {
  cart: Interfaces.TeacherOrderingCart;
  menuTypes: Record<string, Interfaces.MenuType>;
  pickupLocationId: string;
  students: Record<string, Interfaces.Student>;
  homeroomId: string;
  district: Interfaces.District;
}): Record<string, Interfaces.OrderItem[]> => {
  const studentOrderItems: Record<string, Interfaces.OrderItem[]> = {};

  Object.entries(cart).forEach(([date, dateItems]) => {
    const shouldSkipDate = isViolatingOrderingRules(district, date, moment(), district?.timezone, true);

    if (shouldSkipDate) {
      return;
    }

    Object.entries(dateItems).forEach(([menuTypeId, menuTypeItems]) => {
      Object.entries(menuTypeItems).forEach(([cartId, cartItem]) => {
        const entree = cartItem.entree;
        const menuType = menuTypes[entree.mealTypeId];

        cartItem.studentIds.forEach((studentId) => {
          const orderItems: Interfaces.OrderItem[] = [];
          const student = students[studentId];
          const entreeOrderItem = createTeacherOrderItem({
            studentId,
            date,
            menuType,
            pickupLocationId,
            homeroomId,
            menuItem: entree,
            id: '',
            districtId: student.districtId,
            siteId: student.siteId,
          });

          orderItems.push(entreeOrderItem);

          Object.values(cartItem.sides).forEach((sideItems) => {
            sideItems.forEach((sideItem) => {
              const sideOrderItem = createTeacherOrderItem({
                studentId,
                date,
                menuType,
                pickupLocationId,
                menuItem: sideItem,
                id: '',
                districtId: student.districtId,
                siteId: student.siteId,
                homeroomId: student.homeroomId,
              });
              orderItems.push(sideOrderItem);
            });
          });

          if (studentOrderItems[studentId]) {
            studentOrderItems[studentId] = studentOrderItems[studentId].concat(orderItems);
          } else {
            studentOrderItems[studentId] = orderItems;
          }
        });
      });
    });
  });

  return studentOrderItems;
};

export const createBulkOrderItem = ({
  menuItem,
  menuType,
  districtId,
  date,
  siteId,
  homeroomId,
  pickupLocationId,
  quantity,
}: {
  menuItem: Interfaces.MenuItem;
  menuType: Interfaces.MenuType;
  districtId: string;
  date: string;
  siteId: string;
  homeroomId: string;
  pickupLocationId: string;
  quantity: number;
}): Interfaces.OrderItem => {
  const product = menuItem.product;

  return {
    districtId,
    date,
    siteId,
    homeroomId,
    quantity,
    price: 0,
    id: '',
    mealTypeId: menuType.id,
    mealTypeName: menuType.name,
    productId: product.id,
    pickupLocationId: pickupLocationId,
    productCategoryId: product.productCategoryId,
  };
};

export const convertCartToBulkOrderItems = ({
  bulkCart,
  menuTypes,
  pickupLocationId,
  homeroomId,
  district,
  siteId,
  districtId,
}: {
  bulkCart: Interfaces.TeacherBulkOrderingCart;
  menuTypes: Record<string, Interfaces.MenuType>;
  pickupLocationId: string;
  homeroomId: string;
  district: Interfaces.District;
  siteId: string;
  districtId: string;
}): Interfaces.OrderItem[] => {
  const orderItems: Interfaces.OrderItem[] = [];

  Object.entries(bulkCart).forEach(([date, dateItems]) => {
    const shouldSkipDate = isViolatingOrderingRules(district, date, moment(), district?.timezone, true);

    if (shouldSkipDate) {
      return;
    }

    Object.entries(dateItems).forEach(([menuTypeId, menuTypeItems]) => {
      Object.entries(menuTypeItems).forEach(([productId, cartItem]) => {
        const menuType = menuTypes[menuTypeId];

        const orderItem = createBulkOrderItem({
          menuItem: cartItem.menuItem,
          quantity: cartItem.quantity,
          menuType,
          districtId,
          homeroomId,
          pickupLocationId,
          siteId,
          date,
        });

        orderItems.push(orderItem);
      });
    });
  });

  return orderItems;
};

export const binOrderItems = (
  postOrderItemResult: Record<
    string,
    Interfaces.OrderItem[] | { error: { [key: string]: string }; orderItems: Interfaces.OrderItem[] }
  >,
  products: Record<string, Interfaces.Product>,
) => {
  const bin: {
    [key: string]: {
      errors: { [key: string]: any };
      [key: string]: Interfaces.OrderConfirmationBin | { [key: string]: any };
    };
  } = {};

  Object.entries(postOrderItemResult).forEach(([id, result]) => {
    if (id === BULK_ORDER_ITEM_ID) {
      return;
    }

    const error = !Array.isArray(result) && result.error;
    const orderItems = Array.isArray(result) ? result : result.orderItems;

    const orderItemsByDate = _.groupBy(orderItems, (orderItem) => orderItem.date);
    Object.entries(orderItemsByDate).forEach(([date, items]) => {
      const itemsByMenuType = _.groupBy(items, 'mealTypeName');

      Object.entries(itemsByMenuType).forEach(([menuTypeName, groupedItems]) => {
        const sortedItems = _.sortBy(groupedItems, (orderItem) => {
          const productCategoryId = orderItem.productCategoryId;

          if (productCategoryId) {
            return PRODUCT_CATEGORIES_SORT_ORDER[productCategoryId];
          }
          return PRODUCT_CATEGORIES_SORT_ORDER.UNKNOWN;
        });

        const productIds = sortedItems.map((orderItem) => orderItem.productId).join('.');
        if (!bin[date]) {
          bin[date] = { errors: {} };
        }
        if (error) {
          bin[date].errors[id] = error;
        } else {
          const [entreeOrderItem, ...sideOrderItems] = sortedItems;
          const product = products[entreeOrderItem.productId];
          const entreeName = product.name;
          const sides = sideOrderItems.map((sideOrderItem) => products[sideOrderItem.productId]?.name || '').join(', ');

          if (!bin[date][productIds]) {
            bin[date][productIds] = {
              total: 1,
              menuTypeName,
              productName: entreeName,
              sides,
              productCategoryId: product.productCategoryId,
            };
          } else {
            const productCombinationBin = bin[date][productIds] as Interfaces.OrderConfirmationBin;
            productCombinationBin.total += 1;
          }
        }
      });
    });
  });

  return bin;
};

export const binBulkOrderItems = (
  postOrderItemResult: Record<
    string,
    Interfaces.OrderItem[] | { error: { [key: string]: string }; orderItems: Interfaces.OrderItem[] }
  >,
  products: Record<string, Interfaces.Product>,
) => {
  const bin: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          total: number;
          productName: string;
        }[];
      };
    };
  } = {};

  const postBulkOrderResult = postOrderItemResult[BULK_ORDER_ITEM_ID] || { error: null, orderItems: [] };

  const orderItems = Array.isArray(postBulkOrderResult) ? postBulkOrderResult : postBulkOrderResult.orderItems;

  const orderItemsByDate = _.groupBy(orderItems, (orderItem) => orderItem.date);
  Object.entries(orderItemsByDate).forEach(([date, items]) => {
    const itemsByMenuType = _.groupBy(items, 'mealTypeName');

    Object.entries(itemsByMenuType).forEach(([menuTypeName, groupedItems]) => {
      const itemsByProductCategory = _.groupBy(groupedItems, 'productCategoryId');

      Object.entries(itemsByProductCategory).forEach(([productCategory, categoryItems]) => {
        if (!bin[date]) {
          bin[date] = {};
        }

        categoryItems.forEach((orderItem) => {
          const product = products[orderItem.productId];
          if (!bin[date][menuTypeName]) {
            bin[date][menuTypeName] = {};
          }
          if (!bin[date][menuTypeName][productCategory]) {
            bin[date][menuTypeName][productCategory] = [{ total: orderItem.quantity || 0, productName: product?.name }];
          } else {
            bin[date][menuTypeName][productCategory].push({
              total: orderItem.quantity || 0,
              productName: product?.name,
            });
          }
        });
      });
    });
  });

  return bin;
};

export const getSidesText = (sides: Record<string, Interfaces.MenuItem[]>) => {
  return _.flatten(Object.values(sides).map((menuItems) => menuItems.map((menuItem) => menuItem?.product?.name))).join(
    ', ',
  );
};

export const isDateBlackedOut = (orderingRules: Interfaces.OrderingRules, date: moment.Moment) => {
  return orderingRules?.blackoutDates?.some((bd) => {
    const blackoutDate = moment(bd, DATE_FORMAT_YMD);
    return blackoutDate.isSame(date, 'day');
  });
};

export const getTeacherCartProducts = (
  cart: Interfaces.TeacherOrderingCart,
  bulkCart: Interfaces.TeacherBulkOrderingCart,
) => {
  const products: Record<string, Interfaces.Product> = {};

  Object.values(cart).forEach((dateItems) => {
    Object.values(dateItems).forEach((menuTypeItems) => {
      Object.entries(menuTypeItems).forEach(([cartId, cartItem]) => {
        const entreeProduct = cartItem.entree.product;
        products[entreeProduct.id] = entreeProduct;

        Object.values(cartItem.sides).forEach((sideItems) => {
          sideItems.forEach((sideItem) => {
            const sideProduct = sideItem.product;
            products[sideProduct.id] = sideProduct;
          });
        });
      });
    });
  });

  Object.values(bulkCart).forEach((dateItems) => {
    Object.values(dateItems).forEach((menuTypeItems) => {
      Object.values(menuTypeItems).forEach((item) => {
        const product = item.menuItem.product;
        products[product.id] = product;
      });
    });
  });

  return products;
};

export const getItemsExceedingOrderLimit = (
  itemsToAddByMenuTypeId: { [key: string]: Interfaces.BulkOrderItems },
  existingItemsByMenuTypeId: { [key: string]: Interfaces.BulkOrderItems },
) => {
  const errorItems: Interfaces.MenuItem[] = [];

  Object.entries(itemsToAddByMenuTypeId).forEach(([menuTypeId, bulkItems]) => {
    Object.entries(bulkItems).forEach(([menuItemId, bulkItem]) => {
      const existingItem = existingItemsByMenuTypeId?.[menuTypeId]?.[menuItemId];
      if (existingItem && existingItem.quantity + bulkItem.quantity > BULK_ORDER_ITEM_LIMIT) {
        errorItems.push(bulkItem.menuItem);
      }
    });
  });

  return errorItems;
};

export const downloadAsCsv = (data: any, filename: string) => {
  const link = document.createElement('a');
  const url = window.URL.createObjectURL(new Blob([data]));
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

export const generateCartId = (menuItems: Interfaces.MenuItem[] | Interfaces.OrderItem[]) => {
  const sortedItems = _.sortBy(menuItems, (item) => {
    const menuProductCategoryId = (item as Interfaces.MenuItem)?.product?.productCategoryId;
    const orderItemProductCategoryId = (item as Interfaces.OrderItem).productCategoryId;

    const sortOrder =
      (menuProductCategoryId && PRODUCT_CATEGORIES_SORT_ORDER[menuProductCategoryId]) ||
      (orderItemProductCategoryId && PRODUCT_CATEGORIES_SORT_ORDER[orderItemProductCategoryId]) ||
      PRODUCT_CATEGORIES_SORT_ORDER.UNKNOWN;

    return sortOrder;
  }) as Interfaces.MenuItem[];

  const productIds = sortedItems.map((item) => item.productId);

  return productIds.join('.');
};

export const alphanumericSortComparator = (stringA: string, stringB: string) => {
  return stringA.localeCompare(stringB);
};

export const getDaysInInterval = (
  start: Date,
  end: Date,
  options: { excludeWeekends?: boolean; excludeWeekdays?: boolean },
) => {
  let days = eachDayOfInterval({ start, end });

  if (options.excludeWeekends) {
    days = days.filter((day) => !isWeekend(day));
  }

  if (options.excludeWeekdays) {
    days = days.filter((day) => isWeekend(day));
  }

  return days;
};

export const isDateCancelable = (date: Date) => {
  const currentDate = new Date();
  return isBefore(currentDate, date) || isSameDay(currentDate, date);
};

export const getFriendlyNameFromSnakeCase = (snakeCaseString?: string | null): string => {
  if (snakeCaseString) {
    const segments = snakeCaseString
      .split('_')
      .filter((segment) => segment)
      .map((segment: string) => {
        return `${segment[0].toUpperCase() + segment.substring(1).toLowerCase()}`;
      });
    return segments.join(' ');
  }

  return '';
};

export const sortMenuTypes = (menuTypes: Record<string, Interfaces.MenuType>, sortKey?: string[]) => {
  // sort by alpha first
  let menuTypeArray = _.sortBy(Object.values(menuTypes), 'name');

  if (sortKey && sortKey.length) {
    menuTypeArray = sortByKey(menuTypeArray, sortKey, 'id');
  }

  return menuTypeArray;
};

export function sortByKey<Type>(arr: Type[], sortKey: any[], sortField?: string): Type[] {
  const sortedItems: any[] = [...arr];
  const sortKeyMap: Record<any, number> = {};

  if (sortedItems.length) {
    // create map of sortKey: index
    sortKey.forEach((keyItem: any, index: number) => {
      sortKeyMap[keyItem] = index;
    });

    // sort array by sort key map
    sortedItems.sort((itemA: any, itemB: any) => {
      const indexA = sortKeyMap[sortField ? itemA[sortField] : itemA];
      const indexB = sortKeyMap[sortField ? itemB[sortField] : itemB];

      // both items appear in the sort key
      if (indexA !== undefined && indexB !== undefined) {
        return indexA - indexB;
      }

      // only one or neither of the items appear in the sort key
      return indexA ? -1 : (indexB ?? 0) * 1;
    });
  }

  return sortedItems;
}

export const getGradeTextFromStudent = (student: Interfaces.Student | Interfaces.ReportStudent) => {
  return student.grade ? ` | Grade ${student.grade}` : '';
};

export const createLabelReportQueryParams = (
  menuTypeId: string,
  siteId: string,
  pickupLocationIds: string[],
  dateFrom: Moment,
  dateTo: Moment,
  studentName?: string,
  sortBy?: string[],
) => {
  const queryParams = new URLSearchParams();
  queryParams.append('menuTypeId', menuTypeId);
  queryParams.append('siteId', siteId);
  queryParams.append('dateFrom', dateFrom.format(DATE_FORMAT_YMD));
  queryParams.append('dateTo', dateTo.format(DATE_FORMAT_YMD));
  pickupLocationIds.forEach((plId: string) => queryParams.append('pickupLocationId', plId));

  if (studentName) {
    queryParams.append('studentName', studentName);
  }

  if (sortBy) {
    sortBy.forEach((sort: string) => queryParams.append('sortBy', sort));
  }
  return queryParams;
};

export const compareGrade = (a: string, b: string) => {
  if (a === b) {
    return 0;
  }

  const convertedA = parseFloat(a);
  const convertedB = parseFloat(b);

  if (!isNaN(convertedA) && !isNaN(convertedB)) {
    return Math.min(convertedA, convertedB) === convertedA ? -1 : 1;
  } else if (isNaN(convertedA) && isNaN(convertedB)) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }

  return isNaN(convertedA) ? -1 : 1;
};

export const processApiErrorCodes = (apiErrorCodes: string[], message?: string) => {
  const notifications: Interfaces.NotificationMessage[] = [];
  apiErrorCodes.forEach((errorCode: string): Interfaces.NotificationMessage | undefined => {
    let notificationMessage = ApiError.BAD_REQUEST.message;

    const foundDefinedApiError = ApiError[errorCode];
    if (foundDefinedApiError) {
      if (foundDefinedApiError.hideError) {
        return;
      }

      notificationMessage = foundDefinedApiError.useApiErrorMessage && message ? message : foundDefinedApiError.message;
    }

    notifications.push({
      message: notificationMessage,
    });
  });
  return notifications;
};

export const isDirectorPortal = (): boolean => {
  return window.location.origin.includes('portal.onlineordering.linq.com');
};

export const isFamilyPortal = (): boolean => {
  return window.location.origin.includes('family.onlineordering.linq.com');
};
