import React, { ReactElement } from 'react';

import * as Constants from 'Constants/Constants';
import * as Interfaces from 'Interfaces/Interfaces';

export const buildCutOffWarningMessage = (
  orderingRules: Interfaces.OrderingRules,
  isTeacher = false,
): string | null => {
  let message = '';
  const orderRange = isTeacher ? orderingRules?.teacherOrderRange : orderingRules?.orderRange;
  const orderDueBy = isTeacher ? orderingRules?.teacherOrderDueBy : orderingRules?.orderDueBy;
  const orderDueByMonthly = isTeacher ? orderingRules?.teacherOrderDueByMonthly : orderingRules?.orderDueByMonthly;
  const cutoffTime = isTeacher ? orderingRules?.teacherCutoffTime : orderingRules?.cutoffTime;
  const cutoffDay = isTeacher ? orderingRules?.teacherCutoffDay : orderingRules?.cutoffDay;
  if (!orderRange || orderRange === Constants.ORDER_RANGE_OPTIONS.DAILY) {
    message = `Orders are due by ${cutoffTime} ${
      Constants.ORDER_DUE_BY_RULES[orderDueBy as Constants.ORDER_DUE_BY_RULE_OPTIONS]?.ruleSuffix
    }.`;
  } else if (orderRange === Constants.ORDER_RANGE_OPTIONS.WEEKLY) {
    message = `Orders are due by ${cutoffTime} ${
      Constants.ORDER_CUT_OFF_DAYS[cutoffDay as string]?.name
    } for orders next week.`;
  } else if (orderRange === Constants.ORDER_RANGE_OPTIONS.MONTHLY) {
    message = `Orders are due by ${cutoffTime} ${
      Constants.ORDER_DUE_BY_MONTH[orderDueByMonthly as Constants.ORDER_DUE_BY_MONTH_OPTIONS]?.name
    }.`;
  }

  if (message.indexOf('undefined') > -1) {
    return null;
  }
  return message;
};

export const buildMaxCutOffWarningMessage = (
  orderingRules: Interfaces.OrderingRules,
  isTeacher = false,
): string | null => {
  let message = '';
  const maxOrderRange = isTeacher ? orderingRules?.teacherMaxOrderRange : orderingRules?.maxOrderRange;
  const maxCutoffDays = isTeacher ? orderingRules?.teacherMaxCutoffDays : orderingRules?.maxCutoffDays;
  const maxCutoffWeeks = isTeacher ? orderingRules?.teacherMaxCutoffWeeks : orderingRules?.maxCutoffWeeks;
  const maxCutoffMonths = isTeacher ? orderingRules?.teacherMaxCutoffMonths : orderingRules?.maxCutoffMonths;

  if (!maxOrderRange || maxOrderRange === Constants.MAX_ORDER_RANGE_OPTIONS.DAYS) {
    message = `Orders may be placed up to ${
      Constants.MAX_ORDER_UP_TO_DAYS[maxCutoffDays as Constants.MAX_ORDER_UP_TO_DAYS_OPTIONS]?.name
    }.`;
  } else if (maxOrderRange === Constants.MAX_ORDER_RANGE_OPTIONS.WEEKS) {
    message = `Orders may be placed up to ${
      Constants.MAX_ORDER_UP_TO_WEEKS[maxCutoffWeeks as Constants.MAX_ORDER_UP_TO_WEEKS_OPTIONS]?.name
    }.`;
  } else if (maxOrderRange === Constants.MAX_ORDER_RANGE_OPTIONS.MONTHS) {
    message = `Orders may be placed up to ${
      Constants.MAX_ORDER_UP_TO_MONTHS[maxCutoffMonths as Constants.MAX_ORDER_UP_TO_MONTHS_OPTIONS]?.name
    }.`;
  }

  if (message.indexOf('undefined') > -1) {
    return null;
  }

  return message;
};

const CutOffWarningMessage: React.FC<{ isTeacher?: boolean; orderingRules: Interfaces.OrderingRules }> = ({
  isTeacher = false,
  orderingRules,
}): ReactElement | null => {
  const message = buildCutOffWarningMessage(orderingRules, isTeacher);
  const maxMessage = buildMaxCutOffWarningMessage(orderingRules, isTeacher);

  return (
    <div>
      <div className="ordering-rules">{message}</div> <div className="ordering-rules">{maxMessage}</div>
    </div>
  );
};

export default CutOffWarningMessage;
