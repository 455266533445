import { useAuth0 } from '@auth0/auth0-react';
import { TokenClaims } from 'Auth/User';
import classNames from 'classnames';
import Barcode from 'Components/Barcode/Barcode';
import { ComponentLoader } from 'Components/Loaders/Loaders';
import * as LoaderActions from 'Components/Loaders/state/actions';
import * as Constants from 'Constants/Constants';
import { PrintLabelsStudentNameDisplayOrder } from 'Constants/Enums';
import * as Helpers from 'Helpers/Helper';
import { useAutoPrintPage } from 'Helpers/useAutoPrintPage';
import * as Interfaces from 'Interfaces/Interfaces';
import { chunk, get, toNumber } from 'lodash';
import moment from 'moment-timezone';
import { useDistrictReportActions } from 'Pages/DistrictReports/state/actions';
import * as DistrictReportsSelectors from 'Pages/DistrictReports/state/selectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as CommonSelectors from 'State/selectors';

import './DistrictPrintLabels.less';

const barCodeOptions = {
  width: 1.5,
  height: 15,
  textAlign: 'center',
  background: 'transparent',
};

// Use inline style so this report's style doesn't affect the other ones
const pageStyle = `@page {
  margin-top: 38px;
}`;

export const sortLabelData = (data: Interfaces.PrintLabelData[], sortBy: string[]) => {
  let sorted = [...data];
  sorted = sorted.sort((a, b) => {
    for (const sortField of sortBy) {
      let compareResult = 0;

      const valueA = get(a, sortField);
      const valueB = get(b, sortField);

      compareResult =
        sortField === 'student.grade'
          ? Helpers.compareGrade(valueA ?? '', valueB ?? '')
          : valueA?.toLowerCase().localeCompare(valueB.toLowerCase());

      if (compareResult !== 0) {
        return compareResult;
      }
    }

    return 0;
  });

  return sorted;
};

const DistrictPrintLabels = () => {
  let { search } = useLocation();
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const { getReportStudents } = useDistrictReportActions();
  const districtId = TokenClaims.getDistrictId(user);
  const studentReport = useSelector(DistrictReportsSelectors.getReportStudents);
  const district = useSelector(CommonSelectors.getDistrict(districtId));
  const params = React.useMemo(() => {
    const query = new URLSearchParams(search);
    return {
      dateFrom: query.get('dateFrom'),
      dateTo: query.get('dateTo'),
      menuTypeId: query.get('menuTypeId'),
      siteId: query.get('siteId') ?? '',
      pickupLocationIds: query.getAll('pickupLocationId'),
      sortBy: query.getAll('sortBy'),
      studentNameDisplayOrder: query.get('studentName') ?? PrintLabelsStudentNameDisplayOrder.LAST_FIRST,
    };
  }, [search]);

  const showStudentId =
    district?.orderingRules?.labelStudentIdDisplayType === Interfaces.LabelStudentIdDisplayOptions.Show;
  const ordersForLabels = get(studentReport, 'ordersForLabels', {});
  const students = get(studentReport, 'students', {});
  const pickupLocations = get(studentReport, 'pickupLocations', {});
  const { dateFrom, dateTo, menuTypeId, siteId, pickupLocationIds, sortBy, studentNameDisplayOrder } = params;

  useEffect(() => {
    async function getReport() {
      if (dateFrom && dateTo) {
        dispatch(LoaderActions.setComponentLoaderActive(true));

        await getReportStudents(
          moment(dateFrom),
          moment(dateTo),
          pickupLocationIds,
          siteId,
          Interfaces.ReportMode.Label,
        );
        dispatch(LoaderActions.setComponentLoaderActive(false));
      }
    }
    getReport();
  }, [dateFrom, dateTo, dispatch, pickupLocationIds, siteId, getReportStudents]);

  useAutoPrintPage(ordersForLabels);

  const printLabelData = React.useMemo(() => {
    const data: Interfaces.PrintLabelData[] = [];

    Object.entries(ordersForLabels).forEach(([pickupLocationId, pickupLocationOrders]: any) => {
      const pickupLocation = pickupLocations[pickupLocationId];
      if (!pickupLocation) {
        return;
      }
      Object.entries(pickupLocationOrders).forEach(([studentId, menuTypes]: any) => {
        const student = students[studentId];
        if (!student) {
          return;
        }
        student.grade = student.grade ?? '';

        Object.entries(menuTypes).forEach(([orderMenuTypeId, dates]: any) => {
          if (menuTypeId && menuTypeId !== orderMenuTypeId) {
            return;
          }
          Object.entries(dates).forEach(([date, orderItems]: any) => {
            if (!Object.values(orderItems).length) {
              return;
            }
            data.push({
              student,
              orderItems: Object.values(orderItems),
              pickupLocation,
              menuTypeId,
              date,
            });
          });
        });
      });
    });

    return chunk(sortLabelData(data, sortBy), 2);
  }, [ordersForLabels]);

  return (
    <div className={'meal-labels-container'}>
      <style>{pageStyle}</style>
      <ComponentLoader />
      <table>
        <tbody>
          {printLabelData.map((row, i) => {
            return (
              <tr className="meal-labels__row" key={i}>
                {row.map((rowItem, j) => {
                  if (rowItem) {
                    return (
                      <td key={j}>
                        {
                          <PickupLocationLabel
                            student={rowItem.student}
                            orderItems={Object.values(rowItem.orderItems)}
                            key={`${rowItem.date}.studentId`}
                            pickupLocation={rowItem.pickupLocation}
                            menuTypeId={menuTypeId}
                            showStudentId={showStudentId}
                            studentNameDisplayOrder={studentNameDisplayOrder}
                            date={rowItem.date}
                          />
                        }
                      </td>
                    );
                  }
                  return null;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const PickupLocationLabel = (props: Interfaces.PickupLocationProps) => {
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);
  const { student, orderItems, pickupLocation, menuTypeId, showStudentId, date } = props;
  const district = useSelector(CommonSelectors.getDistrict(districtId));
  const menuTypes = useSelector(DistrictReportsSelectors.getReportMenuTypes);
  const menuItems = orderItems.filter((i: any) => i.productCategoryId !== Constants.ALACARTE_KEY);
  const aLaCarteItems = orderItems.filter((i: any) => i.productCategoryId === Constants.ALACARTE_KEY);
  const comments = orderItems[0].comments;

  const menuType = menuTypeId ? menuTypes[menuTypeId] : ({} as Interfaces.MenuType);
  let orderingRules = district?.orderingRules;
  let menuOrderDetails = Helpers.createOrderDetails(menuItems);
  let aLaCarteOrderDetails = Helpers.createOrderDetails(aLaCarteItems);
  const displayAllergens =
    !!orderingRules?.labelsAllergensDisplayType &&
    (orderingRules.labelsAllergensDisplayType as string) !== '' &&
    orderingRules.labelsAllergensDisplayType !== Interfaces.LabelAllergenOptions.None;
  const allergens =
    !student.allergens || student.allergens === '' ? Interfaces.LabelAllergenOptions.None : student.allergens;
  const studentName =
    props.studentNameDisplayOrder === PrintLabelsStudentNameDisplayOrder.FIRST_LAST
      ? `${student.firstName} ${student.lastName}`
      : `${student.lastName}, ${student.firstName}`;
  return (
    <div
      className={classNames('meal-label-container', {
        'contains-allergens': displayAllergens,
      })}
    >
      <div className="student-name">
        {studentName}
        {Helpers.getGradeTextFromStudent(student)}
      </div>
      {<div className="student-id">{showStudentId ? student.sisId : ''}</div>}
      {date && <div className="date">{moment(date).format('M/D/YY')}</div>}
      {displayAllergens && <div className="allergens">Allergens: {allergens}</div>}
      {menuOrderDetails?.length > 0 && (
        <div className="order-details">
          {menuType?.name} Order: {menuOrderDetails}
        </div>
      )}
      {aLaCarteItems.length > 0 && <div className="order-details">A la Carte: {aLaCarteOrderDetails}</div>}
      <div className="pickup-location">Pickup Location: {pickupLocation?.name}</div>
      {comments && (
        <div className="comments">
          <b>Comments: </b>
          {comments}
        </div>
      )}
      <div className="barcode">
        <Barcode value={student.sisId} options={barCodeOptions} />
      </div>
    </div>
  );
};

export default DistrictPrintLabels;
