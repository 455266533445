import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import './CalloutCard.less';

export interface CalloutCardProps {
  details?: string;
  icon?: JSX.Element;
  title: string;
}

export const CalloutCard: FC<CalloutCardProps> = ({ details, icon, title, children }) => {
  return (
    <Card className="linq-card">
      <CardHeader title={title} avatar={icon} className="callout-card__header" />
      <CardContent className="linq-card__space">
        {!!details && <p>{details}</p>}
        {children}
      </CardContent>
    </Card>
  );
};
