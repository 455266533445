import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { useAuth0 } from '@auth0/auth0-react';
import Button from 'Components/Button/Button';
import { InputField } from 'Components/Form/MaterialForm';
import { useCommonActions } from 'State/actions';
import * as CommonSelectors from 'State/selectors';
import * as Interfaces from 'Interfaces/Interfaces';
import Modal from 'Components/Modal/Modal';
import { Role, TokenClaims } from 'Auth/User';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';

const PickupLocationsModal = (props: Interfaces.PickupLocationModalProps) => {
  const { id, open, setModalOpen } = props;
  const pickupLocations = useSelector(CommonSelectors.getPickupLocations);
  const [loading, setLoading] = useState(false);
  const pickupLocation = pickupLocations[id];
  const { updatePickupLocations } = useCommonActions();
  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const siteId = TokenClaims.getSiteId(user);

  let pickupLocationsFilteredForRoleAndDupeChecking: any[] = [];
  if (userRole === Role.DIRECTOR) {
    pickupLocationsFilteredForRoleAndDupeChecking = Object.values(pickupLocations).filter((p) => p.scope === 'GLOBAL');
  } else if (userRole === Role.MANAGER) {
    pickupLocationsFilteredForRoleAndDupeChecking = Object.values(pickupLocations).filter((p) => p.siteId === siteId);
  }
  const pickupLocationNamesArray = pickupLocationsFilteredForRoleAndDupeChecking.map((p) => p.name);
  if (id && pickupLocation) {
    const currentEditIndex = pickupLocationNamesArray.indexOf(pickupLocation.name);
    if (currentEditIndex > -1 && id) {
      pickupLocationNamesArray.splice(currentEditIndex, 1);
    }
  }

  const formik = useFormik({
    initialValues: {
      pickupLocation: pickupLocation?.name,
      defaultLocation: pickupLocation?.default,
    },
    onSubmit: async (values) => {
      const exists = pickupLocationNamesArray.some((p) => p === values.pickupLocation);
      if (exists) {
        const errorContainer = document.getElementById('error-message');
        if (errorContainer) {
          errorContainer.innerHTML = 'Name already exists, please choose another name.';
        }
        return;
      }
      setLoading(true);
      const newPickupLocations: { [key: string]: Interfaces.PickupLocation } = {};

      const newPickupLocation = {
        siteId,
        id: id || uuidv4(),
        name: values['pickupLocation'],
      };

      newPickupLocations[newPickupLocation.id] = newPickupLocation;

      await updatePickupLocations(Object.values(newPickupLocations));

      setLoading(false);
      setModalOpen(false);
      formik.resetForm();
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      pickupLocation: yup.string().required('Required Field'),
    }),
  });

  const closeModal = useCallback(() => {
    formik.resetForm();
    setModalOpen(false);
  }, [formik, setModalOpen]);

  const title = id ? 'Edit' : 'Add';

  return (
    <>
      <Modal open={open} className="linq-dialog linq-dialog--lg" id="pickup-locations-modal-content" maxWidth="lg">
        <Modal.Title className="linq-dialog__header d-flex align-items-center" disableTypography={true}>
          <h3 className="linq-heading">{title} Pickup Location</h3>
          <CloseIconButton onClick={closeModal} />
        </Modal.Title>
        <Modal.Content className="linq-dialog__content">
          <form onSubmit={formik.handleSubmit}>
            <InputField
              id="pickupLocation"
              name="pickupLocation"
              value={formik.values['pickupLocation']}
              onChange={formik.handleChange}
              label="Pickup Location"
              inputProps={{ maxLength: 80, 'data-test-id': 'pickupLocationInput' }}
              errorMsg={formik.errors.pickupLocation}
              touched={formik.touched.pickupLocation}
              showOnlyTouchedError={true}
              hideEmptyError={false}
            />
          </form>
        </Modal.Content>
        <Modal.Actions className="actions">
          <div className="linq-dialog__actions__multi">
            <Button variant="outlined" color="primary" onClick={closeModal} data-test-id="cancelBtn">
              Cancel
            </Button>
          </div>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            loading={loading}
            onClick={formik.submitForm}
            data-test-id="submitBtn"
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default PickupLocationsModal;
