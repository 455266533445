import React from 'react';
import { useNavigate } from 'react-router-dom';
import { format, getYear, parse, parseISO, startOfMonth } from 'date-fns';
import { get } from 'lodash';
import * as Constants from 'Constants/Constants';
import { RouteId, getRoutePath } from 'Constants/Routes';
import ReactTableV2 from 'Components/ReactTable/ReactTableV2/ReactTableV2';
import { ButtonLink } from 'Components/ButtonLink/ButtonLink';
import { getFriendlyNameFromSnakeCase } from 'Helpers/Helper';
import { convertToDBISODate, sortDateStrings } from 'Helpers/DateHelpers';
import { SchoolMenu } from 'Interfaces/Interfaces';

import './SchoolMenuList.less';

export interface SchoolMenuTableData {
  siteId: string;
  siteName: string;
  name: string;
  menuTypeId: string;
  siteGroupTypeId: string;
  month: string;
  year: number;
  date: Date;
}

export interface SchoolMenuListProps {
  schoolMenuData: SchoolMenu[];
  loading: boolean;
}

const compareSiteGroup = (siteGroupA: string, siteGroupB: string) => {
  const sortPriorityA = Constants.SITE_GROUP_SORT_ORDER[siteGroupA.toUpperCase().replace(' ', '_')];
  const sortPriorityB = Constants.SITE_GROUP_SORT_ORDER[siteGroupB.toUpperCase().replace(' ', '_')];

  return sortPriorityA - sortPriorityB;
};

export const defaultDataSort = (data: SchoolMenuTableData[]) => {
  const sorted = [...data];

  sorted.sort((a, b) => {
    const dateA = get(a, 'date');
    const dateb = get(b, 'date');

    const siteGroupTypeIdA = get(a, 'siteGroupTypeId');
    const siteGroupTypeIdB = get(b, 'siteGroupTypeId');

    const siteNameA = get(a, 'siteName');
    const siteNameB = get(b, 'siteName');

    const menuNameA = get(a, 'name');
    const menuNameB = get(b, 'name');

    return (
      sortDateStrings(convertToDBISODate(dateA), convertToDBISODate(dateb)) ||
      compareSiteGroup(siteGroupTypeIdA, siteGroupTypeIdB) ||
      siteNameA.localeCompare(siteNameB) ||
      menuNameA.localeCompare(menuNameB)
    );
  });

  return sorted;
};

export const SchoolMenuTable = ({ schoolMenuData, loading }: SchoolMenuListProps) => {
  const navigate = useNavigate();

  const processedMenuData = React.useMemo(() => {
    const data: SchoolMenuTableData[] = schoolMenuData.map((schoolMenu) => {
      const publishedMonthDate = parseISO(schoolMenu.publishedMonth);

      return {
        siteId: schoolMenu.siteId,
        siteName: schoolMenu.siteName,
        name: schoolMenu.name,
        menuTypeId: schoolMenu.menuTypeId,
        siteGroupTypeId: getFriendlyNameFromSnakeCase(schoolMenu.siteGroupTypeId),
        month: format(publishedMonthDate, 'LLLL'),
        year: getYear(publishedMonthDate),
        date: startOfMonth(publishedMonthDate),
      };
    });

    return defaultDataSort(data.filter((publishedMenu) => publishedMenu.siteGroupTypeId));
  }, [schoolMenuData]);

  const columns = React.useMemo(
    () => [
      {
        accessor: 'year',
        Header: 'Year',
        dataTestId: (row: any): string => `${row.original.year}.${row.original.month}.${row.original.siteId}.${row.original.menuTypeId}.yearLbl`,
        width: 100,
      },
      {
        accessor: 'date',
        Header: 'Month',
        Cell: (props: any) => props.row.original.month,
        dataTestId: (row: any): string => `${row.original.year}.${row.original.month}.${row.original.siteId}.${row.original.menuTypeId}.monthLbl`,
        width: 200,
        filterField: {
          fieldName: 'Date',
          fieldType: Constants.FilterFieldType.dateRange,
          dateRangeOptions: { view: 'month', disablePast: true },
          ordinal: 1,
        },
      },
      {
        accessor: 'siteGroupTypeId',
        Header: 'Group',
        filterField: {
          fieldName: 'Group',
          fieldType: Constants.FilterFieldType.multiSelect,
          ordinal: 3,
        },
        dataTestId: (row: any): string => `${row.original.year}.${row.original.month}.${row.original.siteId}.${row.original.menuTypeId}.siteGroupLbl`,
        width: 200,
        
      },
      {
        accessor: 'siteName',
        Header: 'Site',
        filterField: {
          fieldName: 'School',
          fieldType: Constants.FilterFieldType.multiSelect,
          ordinal: 2,
        },
        dataTestId: (row: any): string => `${row.original.year}.${row.original.month}.${row.original.siteId}.${row.original.menuTypeId}.siteNameLbl`,
        width: 400,
      },
      {
        accessor: 'name',
        Header: 'Menu Type',
        width: 500,
        filterField: {
          fieldName: 'Menu',
          fieldType: Constants.FilterFieldType.multiSelect,
          ordinal: 4,
        },
        dataTestId: (row: any): string => `${row.original.year}.${row.original.month}.${row.original.siteId}.${row.original.menuTypeId}.menuNameLbl`,
      },

      {
        accessor: 'menuTypeId',
        Header: 'Link',
        
        Cell: (props: any) => {
          return (
            <ButtonLink
              text={'Preview Menu'}
              onClick={() => {
                const date = parse(
                  `${props.row.original.month}-01-${props.row.original.year}`,
                  'LLLL-dd-yyyy',
                  new Date(),
                );

                const queryParams = new URLSearchParams();
                queryParams.append('siteId', props.row.original.siteId);
                queryParams.append('siteName', props.row.original.siteName);
                queryParams.append('siteGroupTypeId', props.row.original.siteGroupTypeId);
                queryParams.append('menuTypeId', props.row.original.menuTypeId);
                queryParams.append('menuName', props.row.original.name);
                queryParams.append('month', format(date, Constants.DATE_FNS_DATE_FORMAT_YMD));

                navigate(`${getRoutePath(RouteId.SHOP_PREVIEW)}?${queryParams}`);
              }}
              data-test-id ={`${props.row.original.year}.${props.row.original.month}.${props.row.original.siteId}.${props.row.original.menuTypeId}.previewMenuBtn`}
            />
          );
        },
        disableSortBy: true,
        width: 200,
        dataTestId: (row: any): string => `${row.original.year}.${row.original.month}.${row.original.siteId}.${row.original.menuTypeId}.previewMenuLbl`,
      },
    ],
    [navigate],
  );

  return <ReactTableV2 data={processedMenuData} columns={columns} loading={loading} />;
};
