import { Reducer } from 'react';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';

const initialState: Interfaces.CancelOrderModalState = {
  open: false,
  orderItems: []
};

const CancelOrderModalReducer: Reducer<Interfaces.CancelOrderModalState, Interfaces.Action> = (state = initialState, action) => {

  const { type, payload } = action;

  switch (type) {
    case Types.CANCEL_ORDER_MODAL_OPEN:
      return {
        ...state,
        open: payload.open,
        orderItems: payload.orderItems
      };

    default:
      return state;
  }
};

export default CancelOrderModalReducer;
