import React, { FC, useState, MouseEvent } from 'react';
import { makeStyles, Menu } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import classNames from 'classnames';

export interface ActionColumnProps {
  id: string;
  render?: (props: { handleClose: () => void }) => React.ReactNode;
}

export const ActionColumn: FC<ActionColumnProps> = ({ children, id, render }) => {
  const useStyles = makeStyles({
    root: {
      padding: '0',
    },
  });
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="action-column-container">
      <IconButton aria-describedby={id} className="kebab-menu" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
        <div className={classNames(classes.root, 'column-actions')}>
          {children}
          {render && render({ handleClose })}
        </div>
      </Menu>
    </div>
  );
};

export const actionColumnDef = {
  Header: '',
  accessor: 'actions',
  disableSortBy: true,
  width: 50,
  className: 'react-table-action-column',
};
