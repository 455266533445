import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useAuth0 } from '@auth0/auth0-react';
import { TokenClaims } from 'Auth/User';
import classNames from 'classnames';
import { get } from 'lodash';
import * as DateRangeNavigatorSelectors from 'Components/DateRangeNavigator/state/selectors';
import * as MenuTypePickerSelectors from 'Components/MenuTypePicker/state/selectors';
import ALaCarteWarning from 'Components/Shop/ALaCarteWarning/ALaCarteWarning';
import CalendarDay from 'Components/Shop/CalendarDay/CalendarDay';
import ReimbursableWarning from 'Components/Shop/ReimbursableWarning/ReimbursableWarning';
import WeekDayPicker from 'Components/Shop/WeekDayPicker/WeekDayPicker';
import * as Constants from 'Constants/Constants';
import { ApiError } from 'Constants/ApiErrorCodes';
import * as Interfaces from 'Interfaces/Interfaces';
import ErrorPage from 'Pages/ErrorPage/ErrorPage';
import * as ShopSelectors from 'Pages/Shop/state/selectors';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import './CalendarWeek.less';

const CalendarWeek = (): ReactElement => {
  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const hasMenuItems = useSelector(ShopSelectors.hasMenuItems);
  const hasALaCarteMenuItems = useSelector(ShopSelectors.hasALaCarteMenuItems);
  const showALaCarteWarning = useSelector(ShopSelectors.showALaCarteWarning(userRole));
  const showReimbursableWarning = useSelector(ShopSelectors.showReimbursableWarning(userRole));
  const shopErrors = useSelector(ShopSelectors.getErrors);
  const selectedDate = useSelector(DateRangeNavigatorSelectors.getDate);
  const dayRange: Interfaces.DayRange = useSelector(DateRangeNavigatorSelectors.getDayRangeNoWeekends);
  const dates = dayRange.dates;
  const offerOnlyAlaCarte = useSelector(ShopSelectors.getOfferOnlyALaCarte);
  const menuTypeId = useSelector(MenuTypePickerSelectors.getMenuTypeId);

  const isALaCarteOnlyMenuTypeId = menuTypeId === Constants.UNASSOCIATED_MEAL_TYPE_ID;

  const error = shopErrors?.find((shopError: Interfaces.ShopError) => shopError.message || shopError.errorCode);

  if (error) {
    let errorPage = <ErrorPage showContactSupport={true} />;
    const siteNotFoundError = shopErrors?.find(
      (shopError: Interfaces.ShopError) =>
        get(ApiError, shopError.errorCode)?.message === ApiError.SITE_NOT_FOUND_ERROR.message,
    );
    if (siteNotFoundError) {
      errorPage = (
        <ErrorPage errorType={Constants.ERROR_TYPE.SITE_NOT_ENABLED_FOR_ORDERING} showContactSupport={false} />
      );
    }
    return errorPage;
  }

  return (
    <div className={classNames({ 'calendar-week-container': true, mobile: isMobile })}>
      {showALaCarteWarning && <ALaCarteWarning />}
      {showReimbursableWarning && <ReimbursableWarning />}
      <Table className="calendar-week__table">
        <TableHead>
          {!isMobile && (
            <TableRow>
              {dates.map((date) => {
                return <TableCell key={date.format('X')}>{date.format(Constants.DATE_FORMAT_DD)}</TableCell>;
              })}
            </TableRow>
          )}
          {isMobile && (
            <TableRow>
              <TableCell key={selectedDate.format('X')}>
                <WeekDayPicker />
              </TableCell>
            </TableRow>
          )}
        </TableHead>
        {(!hasMenuItems || (offerOnlyAlaCarte && !hasALaCarteMenuItems)) && (
          <TableBody>
            <TableRow>
              <TableCell className="no-items" colSpan={5}>
                Menus have not been published for this week. Please check back regularly for menu updates.
              </TableCell>
            </TableRow>
          </TableBody>
        )}
        {hasMenuItems && (
          <TableBody>
            {!isMobile && !offerOnlyAlaCarte && !isALaCarteOnlyMenuTypeId && (
              <TableRow>
                {dates.map((date) => {
                  return (
                    <TableCell className="group" key={date.format('X')}>
                      {date.format('d') === '3' && Constants.ENTREES}
                    </TableCell>
                  );
                })}
              </TableRow>
            )}
            {isMobile && !offerOnlyAlaCarte && !isALaCarteOnlyMenuTypeId && (
              <TableRow>
                <TableCell className="group" key={selectedDate.format('X')}>
                  ENTREES
                </TableCell>
              </TableRow>
            )}
            {!isMobile && !offerOnlyAlaCarte && !isALaCarteOnlyMenuTypeId && (
              <TableRow>
                {dates.map((date) => {
                  return (
                    <TableCell className="content" key={date.format('X')}>
                      <CalendarDay
                        date={date}
                        selector="product.productCategoryId"
                        productCategoryId={Constants.PRODUCT_CATEGORIES.ENTREE.id}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            )}
            {isMobile && !offerOnlyAlaCarte && !isALaCarteOnlyMenuTypeId && (
              <TableRow>
                <TableCell className="content" key={selectedDate.format('X')}>
                  <CalendarDay
                    date={selectedDate}
                    selector="product.productCategoryId"
                    productCategoryId={Constants.PRODUCT_CATEGORIES.ENTREE.id}
                  />
                </TableCell>
              </TableRow>
            )}
            {hasALaCarteMenuItems && (
              <>
                {!isMobile && (
                  <TableRow>
                    {dates.map((date) => {
                      return (
                        <TableCell className="group" key={date.format('X')}>
                          {date.format('d') === '3' && Constants.ALACARTE}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )}
                {isMobile && (
                  <TableRow>
                    <TableCell className="group" key={selectedDate.format('X')}>
                      A LA CARTE
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}

            {!isMobile && hasALaCarteMenuItems && (
              <TableRow>
                {dates.map((date) => {
                  return (
                    <TableCell className="content" key={date.format('X')}>
                      <CalendarDay
                        date={date}
                        selector="product.productCategoryId"
                        productCategoryId={Constants.PRODUCT_CATEGORIES.ALACARTE.id}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            )}
            {isMobile && hasALaCarteMenuItems && (
              <TableRow>
                <TableCell className="content" key={selectedDate.format('X')}>
                  <CalendarDay
                    date={selectedDate}
                    selector="product.productCategoryId"
                    productCategoryId={Constants.PRODUCT_CATEGORIES.ALACARTE.id}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </div>
  );
};

export default CalendarWeek;
