import { User } from '@auth0/auth0-react';

export enum Role {
  PARENT = 'parent',
  TEACHER = 'teacher',
  MANAGER = 'manager',
  DIRECTOR = 'director',
  SFTP = 'sftp',
}

export enum Admin {
  SUPER = 'super',
  DIRECTOR = 'director',
  BASIC = 'basic',
}

export enum OldUserClaims {
  Role = `https://onlineordering.linq.com/role`,
  Admin = `https://onlineordering.linq.com/admin`,
  DistrictId = `https://onlineordering.linq.com/districtId`,
  SiteId = `https://onlineordering.linq.com/siteId`,
  HomeroomId = `https://onlineordering.linq.com/homeroomId`,
  Id = 'sub',
}

export enum UserClaims {
  Role = `https://accounts.linq.com/role`,
  Admin = `https://accounts.linq.com/admin`,
  DistrictId = `https://accounts.linq.com/districtId`,
  SiteId = `https://accounts.linq.com/siteId`,
  HomeroomId = `https://accounts.linq.com/homeroomId`,
  Id = 'sub',
}

export const isAuthorized = (userRole: Role, allowedRoles: Role[]): boolean => {
  return allowedRoles.includes(userRole);
};

export const isAdminAuthorized = (userAdmin: Admin, allowedAdminLevels: Admin[]): boolean => {
  return allowedAdminLevels.includes(userAdmin);
};

export class TokenClaims {
  static getRole(user?: User): Role {
    const role = user?.[UserClaims.Role] ?? user?.[OldUserClaims.Role];
    return role as Role;
  }

  static getAdmin(user?: User): Admin {
    const adminRole = user?.[UserClaims.Admin] ?? user?.[OldUserClaims.Admin];
    return adminRole as Admin;
  }

  static getPrincipalId(user?: User): string {
    return user?.sub?.split('|').pop() ?? '';
  }

  static getUserId(user?: User): string {
    return user?.[UserClaims.Id] ?? '';
  }

  static getDistrictId(user?: User): string {
    return user?.[UserClaims.DistrictId] ?? user?.[OldUserClaims.DistrictId] ?? '';
  }

  static getSiteId(user?: User): string {
    return user?.[UserClaims.SiteId] ?? user?.[OldUserClaims.SiteId] ?? '';
  }

  static getHomeroomId(user?: User): string {
    return user?.[UserClaims.HomeroomId] ?? user?.[OldUserClaims.HomeroomId] ?? '';
  }
}
