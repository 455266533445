import { Chip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import classnames from 'classnames';
import Button from 'Components/Button/Button';
import NutritionalFactsPopup from 'Components/NutritionFacts/NutritionalFactsPopup/NutritionalFactsPopup';
import * as Constants from 'Constants/Constants';
import * as Interfaces from 'Interfaces/Interfaces';
import React, { FC, useState } from 'react';
import './FoodGroupSelector.less';

export const FoodGroupSelector: FC<Interfaces.FoodGroupSelectorProps> = ({
  productCategoryId,
  options,
  allowNoneOption,
  ordered,
  onChange,
  foodGroupName,
  products,
  selectedOptions,
}) => {
  const [showNutritionInfo, setShowNutritionInfo] = useState<string | undefined>();
  // Special use case for Milk - might need to expand to other types later
  const isNoneSelected =
    selectedOptions &&
    selectedOptions[Constants.MILK_KEY] &&
    (selectedOptions[Constants.MILK_KEY].length === 0 || (!selectedOptions[Constants.MILK_KEY] && ordered));

  // Hide non-selected items if the order has been placed already.
  const isNoneDisplay = (ordered && isNoneSelected) || !ordered;

  const sortedOptions = options.sort((a, b) => a.sortPriority - b.sortPriority);

  const foodGroupOptions = selectedOptions[productCategoryId] || [];

  if (ordered && !foodGroupOptions.length) {
    return null;
  }

  return (
    <div className="name">
      {foodGroupName}
      <div className="form-container" role="form" aria-label={`Meal options for the food group ${foodGroupName}`}>
        {sortedOptions.map((option) => {
          let optionId = option.id;

          if (ordered) {
            optionId = option.productId;
          }

          const isSelected = Object.values(selectedOptions).some((menuItems) =>
            menuItems.some((menuItem) => (ordered ? menuItem.productId === optionId : menuItem.id === optionId)),
          );

          // Hide non-selected items if the order has been placed already.
          const display = (ordered && isSelected) || !ordered;

          return (
            display && (
              <span className="food-group-wrapper" key={option.id}>
                <Chip
                  label={option.product.name}
                  onClick={() => {
                    if (!ordered) {
                      onChange(productCategoryId, option);
                    }
                  }}
                  color={isSelected ? 'primary' : 'secondary'}
                  clickable={!ordered}
                  className="linq-chip"
                  id={optionId}
                  data-test-id={`${foodGroupName}.${option.product.name}.Btn`}
                />
                <IconButton
                  type="button"
                  className="btn-reset"
                  onClick={() => {
                    setShowNutritionInfo(option.productId);
                  }}
                  data-test-id={`${foodGroupName}.${option.product.name}.infoBtn`}
                >
                  <InfoIcon />
                </IconButton>
              </span>
            )
          );
        })}
        <NutritionalFactsPopup
          open={!!showNutritionInfo}
          product={showNutritionInfo ? products[showNutritionInfo] : undefined}
          onClose={() => setShowNutritionInfo(undefined)}
        />
        {allowNoneOption && isNoneDisplay && (
          <Button
            type="button"
            className={classnames('btn-rest food-group', {
              selected: isNoneSelected,
            })}
            id={'none'}
            onClick={() => {
              onChange(productCategoryId, null);
            }}
          >
            None
          </Button>
        )}
      </div>
    </div>
  );
};
