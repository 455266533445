import React, { FC, Fragment } from 'react';
import { Chip, IconButton, Input, MenuItem, Select } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { isBefore, isAfter, endOfDay, isSameDay, format, isValid } from 'date-fns';
import TuneIcon from '@material-ui/icons/Tune';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import classNames from 'classnames';
import {
  Column,
  FilterTypes,
  FilterValue,
  HeaderGroup,
  Row,
  TableOptions,
  TableRowProps,
  useAsyncDebounce,
  useBlockLayout,
  useFilters,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';
import { AppState } from 'Interfaces/Interfaces';
import * as CommonStateSelectors from 'State/selectors';
import * as CommonStateActions from 'State/actions';
import { TextField } from 'Components/Form/MaterialForm/TextField';
import Button from 'Components/Button/Button';
import { DATE_FORMAT_DATE_AND_TIME } from 'Constants/Constants';
import * as Interfaces from 'Interfaces/Interfaces';
import * as Constants from 'Constants/Constants';
import { SidebarLocations } from 'Constants/Enums';
import { ComponentLoaderNoRedux } from 'Components/Loaders/Loaders';
import Filter from './Filter';
import { TextFilter } from '../Filters';

import './ReactTableV2.less';

const filterTypes: FilterTypes<FilterValue> = {
  textStartsWith: (rows: Array<Row>, id: string, filterValue: FilterValue) => {
    return rows.filter((row: Row) => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
        : true;
    });
  },
  dateAndTimeText: (rows: Array<Row>, id: string, filterValue: FilterValue) => {
    return rows.filter((row: Row) => {
      const rowValue = row.values[id];
      const date = new Date(rowValue);
      const formattedDate = format(date, DATE_FORMAT_DATE_AND_TIME);

      return isValid(date) ? formattedDate.startsWith(String(filterValue).toLowerCase()) : true;
    });
  },
};

export const getFilterChips = (
  tablefilterState: Interfaces.TableFilterState,
  filterFields: Interfaces.FilterField[],
  onCheckboxChange: (fieldName: string, option: string) => void,
  onDateRangeChipDelete: (fieldName: string) => void,
  onSelectRadioFieldChipDelete: (fieldName: string) => void,
) => {
  const filterChipClassName = 'linq-chip filter-chip';
  return Object.keys(tablefilterState).map((key) => {
    const fieldType = filterFields.find((x) => x.fieldName === key)?.fieldType;
    switch (fieldType) {
      case Constants.FilterFieldType.checkbox:
      case Constants.FilterFieldType.multiSelect:
        return tablefilterState[key]?.value?.map((filterOption: string) => {
          return (
            <Chip
              className={filterChipClassName}
              label={filterOption}
              key={`FilterSidebar-${key}: ${filterOption}`}
              onDelete={tablefilterState[key]?.alwaysSet ? undefined : () => onCheckboxChange(key, filterOption)}
            />
          );
        });
      case Constants.FilterFieldType.radio:
      case Constants.FilterFieldType.select:
        if (tablefilterState[key]?.value) {
          return (
            <Chip
              className={filterChipClassName}
              label={tablefilterState[key].text}
              key={`FilterSidebar-${key}`}
              onDelete={tablefilterState[key]?.alwaysSet ? undefined : () => onSelectRadioFieldChipDelete(key)}
            />
          );
        } else {
          return null;
        }

      case Constants.FilterFieldType.dateRange:
        if (isValid(tablefilterState[key]?.value?.fromDate) && isValid(tablefilterState[key]?.value?.toDate)) {
          return (
            <Chip
              className={filterChipClassName}
              key={`Table-${key}`}
              label={`${format(
                new Date(tablefilterState[key].value.fromDate),
                Constants.DATE_FNS_DATE_FORMAT_USA,
              )} - ${format(new Date(tablefilterState[key].value.toDate), Constants.DATE_FNS_DATE_FORMAT_USA)}`}
              onDelete={tablefilterState[key]?.alwaysSet ? undefined : () => onDateRangeChipDelete(key)}
            />
          );
        } else {
          return null;
        }

      default:
        return null;
    }
  });
};

export const getInitialFilterFieldState = (filterFields: Interfaces.FilterField[]) => {
  const initialFieldState: Interfaces.TableFilterState = {};

  filterFields.forEach((filterField) => {
    switch (filterField.fieldType) {
      case Constants.FilterFieldType.checkbox:
      case Constants.FilterFieldType.multiSelect:
        initialFieldState[`${filterField.fieldName}`] = {
          id: `${filterField.fieldName}`,
          columnAccessor: filterField.columnAccessor,
          value: filterField.initialValue ? filterField.initialValue : [],
          alwaysSet: filterField.alwaysSet,
          hasValue: (fieldState: any): boolean => {
            return fieldState.value.length > 0;
          },
        };
        break;
      case Constants.FilterFieldType.radio:
      case Constants.FilterFieldType.select:
        initialFieldState[`${filterField.fieldName}`] = {
          id: `${filterField.fieldName}`,
          columnAccessor: filterField.columnAccessor,
          value: filterField.initialValue?.value ?? '',
          text: filterField.initialValue?.text ?? '',
          alwaysSet: filterField.alwaysSet,
          hasValue: (fieldState: any): boolean => {
            return fieldState.value;
          },
        };
        break;
      case Constants.FilterFieldType.dateRange:
        initialFieldState[`${filterField.fieldName}`] = {
          id: `${filterField.fieldName}`,
          columnAccessor: filterField.columnAccessor,
          value: filterField.initialValue ? { ...filterField.initialValue } : { fromDate: null, toDate: null },
          alwaysSet: filterField.alwaysSet,
          hasValue: (fieldState: any): boolean => {
            return fieldState.value.fromDate && fieldState.value.toDate;
          },
        };
        break;
    }
  });
  return initialFieldState;
};

export const createFilterFieldsFromColumnObject = (columns: any[]): Interfaces.FilterField[] => {
  const columnsWithFilter = columns.filter(
    (column: any) => column.filterField !== null && column.filterField !== undefined,
  );

  const unSortedfields = columnsWithFilter.map<Interfaces.FilterField>((column) => {
    switch (column.filterField?.fieldType) {
      case Constants.FilterFieldType.radio:
      case Constants.FilterFieldType.select:
        return {
          fieldName: column.filterField.fieldName,
          fieldType: column.filterField.fieldType,
          ordinal: column.filterField.ordinal,
          options: [],
          staticOptions: column.filterField.staticOptions,
          aggregateStringSeparator: column.filterField.aggregateStringSeparator,
          initialValue: column.filterField.initialValue,
          alwaysSet: column.filterField.alwaysSet,
          columnAccessor: column.accessor,
          valueFieldAccessor: column.filterField.valueFieldAccessor,
        };
      case Constants.FilterFieldType.checkbox:
        return {
          fieldName: column.filterField.fieldName,
          fieldType: column.filterField.fieldType,
          ordinal: column.filterField.ordinal,
          checkboxOptions: [],
          staticOptions: column.filterField.staticOptions,
          initialValue: column.filterField.initialValue,
          alwaysSet: column.filterField.alwaysSet,
          booleanField: column.filterField.booleanField,
          booleanValueMap: column.filterField.booleanValueMap,
          columnAccessor: column.accessor,
          valueFieldAccessor: column.filterField.valueFieldAccesssor,
        };
      case Constants.FilterFieldType.dateRange:
        return {
          fieldName: column.filterField.fieldName,
          fieldType: column.filterField.fieldType,
          ordinal: column.filterField.ordinal,
          initialValue: column.filterField.initialValue,
          alwaysSet: column.filterField.alwaysSet,
          dateRangeOptions: column.filterField.dateRangeOptions,
          columnAccessor: column.accessor,
          valueFieldAccessor: column.filterField.valueFieldAccessor,
        };
      case Constants.FilterFieldType.multiSelect:
        return {
          fieldName: column.filterField.fieldName,
          fieldType: column.filterField.fieldType,
          ordinal: column.filterField.ordinal,
          options: [],
          staticOptions: column.filterField.staticOptions,
          aggregateStringSeparator: column.filterField.aggregateStringSeparator,
          initialValue: column.filterField.initialValue,
          alwaysSet: column.filterField.alwaysSet,
          columnAccessor: column.accessor,
          valueFieldAccessor: column.filterField.valueFieldAccesssor,
        };
      default:
        return {
          fieldName: column.filterField.fieldName,
          fieldType: column.filterField.fieldType,
          ordinal: column.filterField.ordinal,
          alwaysSet: column.filterField.alwaysSet,
          valueFieldAccessor: column.filterField.valueFieldAccesssor,
          columnAccessor: column.accessor,
        };
    }
  });

  //Sort by ordinal to allow field display order to be customized
  return _.sortBy(unSortedfields, 'ordinal');
};

export const createSelectRadioOptions = (filterField: any, data: any[]): any => {
  const allOptions: any[] = [];

  data.forEach((item: any) => {
    const fieldValue = item[filterField.columnAccessor];
    if (fieldValue) {
      if (filterField.aggregateStringSeparator) {
        // split the fieldValue into substrings based on the specified string seperator for the column
        const splitOptions = fieldValue.split(filterField.aggregateStringSeparator).map((value: any) => {
          return { value: value.trim(), text: value.trim() };
        });

        allOptions.push(...splitOptions);
      } else {
        allOptions.push({
          value: filterField.valueFieldAccessor ? item[filterField.valueFieldAccessor] : fieldValue,
          text: fieldValue,
        });
      }
    }
  });

  const uniqueOptions = _.uniqBy(allOptions, 'value');

  //Add static options that may be passed in the column filter field property
  if (filterField.staticOptions) {
    filterField.staticOptions.forEach((option: any) => {
      if (!uniqueOptions.find((existingOption) => existingOption.value && existingOption.value === option.value)) {
        uniqueOptions.push(option);
      }
    });
  }
  return uniqueOptions;
};

export const createCheckboxOptions = (filterField: any, data: any[], booleanField = false): any => {
  const allValues = data.map((x: any) => x[filterField.columnAccessor]);
  const uniqueValues = _.uniq(allValues);

  let options: any[] = [];
  if (!booleanField) {
    options = [...uniqueValues];
  }
  if (filterField?.staticOptions) {
    filterField.staticOptions.forEach((option: any) => {
      if (!uniqueValues.find((existingOption) => existingOption === option)) {
        options.push(option);
      }
    });
  }

  return options;
};

export const isDateWithinRange = (date: Date, fromDate: Date, toDate: Date) =>
  (isAfter(date, fromDate) && isBefore(date, new Date(endOfDay(toDate)))) ||
  isSameDay(date, fromDate) ||
  isSameDay(date, toDate);

export const getFilteredData = (
  searchValue: string,
  columns: any,
  data: any,
  activeFilters: Interfaces.TableFilterState,
  filterFields: Interfaces.FilterField[],
) => {
  if (searchValue) {
    let searchFilteredTableData = applyFilters(data, activeFilters, filterFields);
    // split search value into multiple searches based on whitespace
    const searchTerms = searchValue.split(' ');

    // perform a search for each search term
    searchTerms.forEach((searchTerm) => {
      searchFilteredTableData = searchFilteredTableData.filter((item) => {
        return Object.keys(item).find((key) => {
          const value = String(item[key]).toLowerCase().replace(/[$%]/, '');
          const isMatch = value.split(' ').some((x) => x.startsWith(searchTerm.replace(/[$%]/, '')));
          const viewableColumns = columns.map((column: any) => column.accessor);

          return isMatch && viewableColumns.includes(key);
        });
      });
    });
    return searchFilteredTableData;
  } else {
    const filteredTableData = applyFilters(data, activeFilters, filterFields);
    return filteredTableData;
  }
};

export const applyFilters = (
  unfilteredData: any,
  activeFilters: Interfaces.TableFilterState,
  filterFields: Interfaces.FilterField[],
) => {
  let filteredTableData = [...unfilteredData];
  Object.keys(activeFilters).forEach((key) => {
    const activeFilter = activeFilters[key];
    const filterField = filterFields.find((x) => x.fieldName === key);

    if (!filterField) {
      return;
    }

    const fieldType = filterField.fieldType;
    const columnAccessor = filterField.valueFieldAccessor ? filterField.valueFieldAccessor : filterField.columnAccessor;

    switch (fieldType) {
      case Constants.FilterFieldType.checkbox:
        filteredTableData = applyCheckboxFilter(
          filteredTableData,
          activeFilter,
          columnAccessor,
          filterField.booleanField,
          filterField.booleanValueMap,
        );

        break;
      case Constants.FilterFieldType.radio:
      case Constants.FilterFieldType.select:
        filteredTableData = applySelectFilter(filteredTableData, activeFilter, columnAccessor);
        break;
      case Constants.FilterFieldType.dateRange:
        filteredTableData = applyDateRangeFilter(filteredTableData, activeFilter, columnAccessor);
        break;
      case Constants.FilterFieldType.multiSelect:
        filteredTableData = applyMultiSelectFilter(
          filteredTableData,
          activeFilter,
          columnAccessor,
          filterField.aggregateStringSeparator,
        );
        break;
    }
  });

  return filteredTableData;
};

export const getClearedFilterState = (
  filterFieldState: Interfaces.TableFilterState,
  filterFields: Interfaces.FilterField[],
) => {
  const newFieldState: Interfaces.TableFilterState = _.cloneDeep(filterFieldState);

  Object.keys(newFieldState).forEach((key) => {
    if (newFieldState[key].alwaysSet) {
      newFieldState[key].value = filterFields.find((x) => x.fieldName === key)?.initialValue;
    } else {
      const fieldType = filterFields.find((x) => x.fieldName === key)?.fieldType;
      switch (fieldType) {
        case Constants.FilterFieldType.checkbox:
        case Constants.FilterFieldType.multiSelect:
          newFieldState[key].value = [];
          break;
        case Constants.FilterFieldType.radio:
        case Constants.FilterFieldType.select:
          newFieldState[key].value = '';
          break;
        case Constants.FilterFieldType.dateRange:
          newFieldState[key].value = { toDate: null, fromDate: null };
      }
    }
  });
  return newFieldState;
};

const applySelectFilter = (data: any[], activeFilter: Interfaces.TableFilterState, columnAccessor: string): any[] => {
  if (activeFilter?.value) {
    return data.filter((item) => item[columnAccessor]?.toLowerCase() === activeFilter.value?.toLowerCase());
  }

  return data;
};

const applyMultiSelectFilter = (
  data: any[],
  activeFilter: Interfaces.TableFilterState,
  columnAccessor: string,
  aggregateStringSeparator?: string,
): any[] => {
  const lowerCaseFilterValues = activeFilter.value.map((option: string) => option.toLowerCase().trim());
  if (lowerCaseFilterValues.length === 0) {
    return data;
  }
  return data.filter((item) => {
    if (aggregateStringSeparator) {
      const separatedItemsValues = item[columnAccessor]?.split(aggregateStringSeparator);
      return separatedItemsValues.some((value: any) => lowerCaseFilterValues.includes(value.toLowerCase().trim()));
    }

    return lowerCaseFilterValues.includes(item[columnAccessor]?.toLowerCase());
  });
};

const applyCheckboxFilter = (
  data: any[],
  activeFilter: Interfaces.TableFilterState,
  columnAccessor: string,
  booleanField = false,
  booleanValueMap?: Interfaces.BooleanValueMap,
): any[] => {
  if (activeFilter.value?.length === 0 || (booleanField && activeFilter.value?.length > 1)) {
    return data;
  }

  if (booleanField) {
    return data.filter((item: any) => {
      return activeFilter.value[0] === booleanValueMap?.truthyValue ? item[columnAccessor] : !item[columnAccessor];
    });
  }

  const lowerCaseFilterValues = activeFilter.value.map((option: string) => option.toLowerCase());
  return data.filter((item: any) => {
    return lowerCaseFilterValues.includes(item[columnAccessor]?.toLowerCase());
  });
};

const applyDateRangeFilter = (
  data: any[],
  activeFilter: Interfaces.TableFilterState,
  columnAccessor: string,
): any[] => {
  if (isValid(activeFilter?.value?.fromDate) && isValid(activeFilter?.value?.toDate)) {
    return data.filter((item: any) => {
      return isDateWithinRange(
        new Date(item[columnAccessor]),
        new Date(activeFilter.value.fromDate),
        new Date(activeFilter.value.toDate),
      );
    });
  }

  return data;
};

const defaultColumn: Partial<Column> = {
  Filter: TextFilter,
  disableFilters: true,
};
const ReactTableV2: FC<Interfaces.ReactTableProps> = ({
  columns,
  data,
  onFilterChange,
  autoResetPage = false,
  showFooter = true,
  initialState = {},
  fetchData,
  manualPageCount,
  noDataText = 'No data available.',
  unitLabel,
  manualPageSize,
  className,
  disableSortRemove = false,
  loading,
  bottomLeftContainer,
  stickyRightColumn: stickyActionColumn,
  manualSearch,
  totalRows,
  disableSearch,
  disableFilter,
  getTrProps = (rowProps: TableRowProps, original: object) => rowProps,
}) => {
  const sidebarOpen = useSelector((state: AppState) =>
    CommonStateSelectors.getSidebarOpen(state, SidebarLocations.REACT_TABLE_FILTER_SIDE_BAR),
  );
  const dispatch = useDispatch();

  const setSidebarOpen = (isOpen: boolean) => {
    dispatch(CommonStateActions.setSidebarOpen(isOpen, SidebarLocations.REACT_TABLE_FILTER_SIDE_BAR));
  };

  const fetchDataDebounced = useAsyncDebounce(fetchData, 300);

  //Generate the list of fields descriptors that will be passed to the filter sidebar
  const filterFields = React.useMemo(
    () => {
      return createFilterFieldsFromColumnObject(columns);
    },
    // eslint-disable-next-line
    [],
  );

  //Separate the sideBar filter state (tableFilterState) and the table filter state (activeFilters)
  const [tableFilterState, setTableFilterState] = React.useState<Interfaces.TableFilterState>(
    getInitialFilterFieldState(filterFields),
  );
  const [activeFilters, setActiveFilters] = React.useState<Interfaces.TableFilterState>(
    getInitialFilterFieldState(filterFields),
  );

  //Manually manages the filtering of the data, keeps the filtered data separate from the actual data
  const [filteredData, setFilteredData] = React.useState<any>([]);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [searchInputValue, setSearchInputValue] = React.useState<string>('');
  const [pageNumber, setPageNumber] = React.useState<number | undefined>(1);

  //Apply the filters when the data prop changes
  React.useEffect(() => {
    if (!fetchData) {
      const newFilteredData = getFilteredData(searchValue, columns, data, activeFilters, filterFields);
      setFilteredData(newFilteredData);
    } else {
      setFilteredData(data);
    }
  }, [data, columns, activeFilters, filterFields, fetchData, searchValue]);

  //Update Options for dropdowns/checkboxes on change to data prop
  React.useEffect(() => {
    filterFields.forEach((filterField) => {
      if (
        filterField.fieldType === Constants.FilterFieldType.select ||
        filterField.fieldType === Constants.FilterFieldType.multiSelect
      ) {
        filterField.options = _.sortBy(createSelectRadioOptions(filterField, data), [
          (o) => {
            const num = Number.parseFloat(o.text);
            if (Number.isNaN(num)) {
              return o.text?.toLowerCase();
            }
            return num;
          },
        ]);
      } else if (filterField.fieldType === Constants.FilterFieldType.checkbox) {
        filterField.checkboxOptions = createCheckboxOptions(filterField, data, filterField.booleanField);
      }
    });
  }, [data, columns, filterFields]);

  const tableOptions: TableOptions<object> = {
    columns,
    data: filteredData,
    autoResetPage,
    initialState,
    manualPagination: !!fetchData,
    manualSortBy: !!fetchData,
    defaultColumn,
    filterTypes,
    disableSortRemove,
  };

  if (manualPageCount) {
    tableOptions.pageCount = manualPageCount;
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    rows,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(tableOptions, useFilters, useSortBy, usePagination, useBlockLayout, useResizeColumns);

  const activeSort = sortBy[0];
  const filters = React.useMemo(() => {
    const filtersWithValues: Interfaces.TableFilterState[] = [];

    Object.keys(activeFilters).forEach((x) => {
      if (activeFilters[x].hasValue(activeFilters[x])) {
        filtersWithValues.push(activeFilters[x]);
      }
    });

    return filtersWithValues;
  }, [activeFilters]);

  React.useEffect(() => {
    // reset page to first page on sort or page size change
    gotoPage(0);
    setPageNumber(1);
  }, [sortBy, pageSize, gotoPage, setPageNumber]);

  React.useEffect(() => {
    if (fetchData) {
      fetchDataDebounced({ pageIndex, pageSize }, activeSort, filters, searchValue);
    }
  }, [fetchDataDebounced, fetchData, pageIndex, pageSize, activeSort, filters, searchValue]);

  React.useEffect(() => {
    if (manualPageSize) {
      setPageSize(manualPageSize);
    }
  }, [setPageSize, manualPageSize]);

  const onSelectRadioChipDelete = (fieldName: string) => {
    const newFieldState: Interfaces.TableFilterState = { ...tableFilterState };

    newFieldState[fieldName].value = '';

    if (onFilterChange) {
      onFilterChange(activeFilters, { ...newFieldState });
    }
    setTableFilterState(newFieldState);
    setActiveFilters(_.cloneDeep(newFieldState));
  };

  const onDateRangeChipDelete = (fieldName: string) => {
    const newFieldState: Interfaces.TableFilterState = { ...tableFilterState };

    newFieldState[fieldName].value = { toDate: null, fromDate: null };

    if (onFilterChange) {
      onFilterChange(activeFilters, { ...newFieldState });
    }
    setTableFilterState(newFieldState);
    setActiveFilters(_.cloneDeep(newFieldState));
  };

  const onCheckboxChipDelete = (fieldName: string, option: string) => {
    const newFieldState: Interfaces.TableFilterState = { ...tableFilterState };

    if (newFieldState[fieldName].value.includes(option)) {
      newFieldState[fieldName].value.splice(newFieldState[fieldName].value.indexOf(option), 1);
    } else {
      newFieldState[fieldName].value.push(option);
    }

    if (onFilterChange) {
      onFilterChange(activeFilters, { ...newFieldState });
    }
    setTableFilterState({ ...newFieldState });
    setActiveFilters(_.cloneDeep(newFieldState));
  };

  const handleFilterSidebarSubmit = () => {
    const dateRangeFields = filterFields.filter(
      (filterField: Interfaces.FilterField) => filterField.fieldType === Constants.FilterFieldType.dateRange,
    );

    const newActiveTableState = _.cloneDeep(tableFilterState);

    //If a value has been selected for one date range but not the other, then clear both and do not add to active filters
    dateRangeFields.forEach((dateRangeField) => {
      const stateValue = newActiveTableState[dateRangeField.fieldName].value;
      if (!(stateValue.toDate && stateValue.fromDate)) {
        newActiveTableState[dateRangeField.fieldName].value.fromDate = dateRangeField.initialValue
          ? dateRangeField.initialValue.fromDate
          : '';
        newActiveTableState[dateRangeField.fieldName].value.toDate = dateRangeField.initialValue
          ? dateRangeField.initialValue.toDate
          : '';
      }
    });

    // go to first page if filters change
    gotoPage(0);
    setPageNumber(1);

    if (onFilterChange) {
      onFilterChange(activeFilters, newActiveTableState);
    }

    setActiveFilters(newActiveTableState);
    setSidebarOpen(false);
  };

  const handleFilterCancelClick = () => {
    setTableFilterState(_.cloneDeep(activeFilters));
  };

  const onClearFiltersClick = () => {
    const newFieldState = getClearedFilterState(activeFilters, filterFields);

    //set slideout filter state
    setTableFilterState(newFieldState);
    //set table filter state
    setActiveFilters(_.cloneDeep(newFieldState));

    // go to first page if filters are cleared
    gotoPage(0);
    setPageNumber(1);
  };

  const numberOfRows = totalRows ?? rows.length;
  const pageEnd = pageSize * (pageIndex + 1);

  const pageEndIndex = pageEnd > numberOfRows ? numberOfRows : pageEnd;
  const pageStartIndex = pageSize * (pageIndex + 1) - (pageSize - 1);

  return (
    <div className="linq-table-container">
      <div className="table-header">
        {!disableSearch && (
          <div className="table-filters">
            <TextField
              className="linq-table__search-input"
              id="table-search"
              placeholder="Search"
              value={searchInputValue}
              onKeyPress={(event) => {
                if (event.key === Constants.EVENTS.ENTER) {
                  setSearchValue(searchInputValue);
                  setPageNumber(1);
                  gotoPage(0);
                }
              }}
              onQuickClear={() => {
                setSearchValue('');
                setSearchInputValue('');
              }}
              disabled={loading}
              onChange={(e) => {
                setSearchInputValue(e.target.value);
                const value = String(e.target.value).trimEnd().toLowerCase();
                if (!manualSearch) {
                  setTimeout(() => {
                    setSearchValue(value);
                    setPageNumber(1);
                    gotoPage(0);
                  }, 600);
                }
              }}
            />
            {manualSearch && (
              <Button
                className="search-button"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setSearchValue(searchInputValue);
                  setPageNumber(1);
                  gotoPage(0);
                }}
              >
                Search
              </Button>
            )}
          </div>
        )}
        {!disableFilter && (
          <div className="table-actions table-actions">
            <IconButton
              aria-label="Filter"
              disabled={loading}
              onClick={() => {
                setSidebarOpen(true);
              }}
              data-test-id="filterBtn"
            >
              <TuneIcon />
            </IconButton>
          </div>
        )}

        {unitLabel && !loading && (
          <div className="react-table-v2-unit-label">
            Viewing {!!fetchData ? totalRows : rows.length} {unitLabel}
          </div>
        )}
      </div>

      {filters.length > 0 && (
        <div className="table-filter-chips">
          {getFilterChips(
            activeFilters,
            filterFields,
            onCheckboxChipDelete,
            onDateRangeChipDelete,
            onSelectRadioChipDelete,
          )}

          <div className="table-filter-chips__clear">
            <Chip className="linq-chip" onClick={onClearFiltersClick} label={'Clear All'} key={'clearAll'} />
          </div>
        </div>
      )}
      <div className="table-overflow">
        {<ComponentLoaderNoRedux loading={loading} />}
        {filteredData.length === 0 && !loading && <div className="table-no-data">{noDataText}</div>}
        {filteredData.length > 0 && (
          <table
            {...getTableProps()}
            className={classNames(
              'linq-table linq-table--auto',
              stickyActionColumn && 'react-table-v2-sticky-action-column',
              loading && 'table-loading',
            )}
          >
            <thead>
              {headerGroups.map((headerGroup: HeaderGroup, i: number) => {
                return (
                  <Fragment key={`${headerGroup.id}${i}`}>
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                      {headerGroup.headers.map((column) => {
                        return (
                          <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            style={{
                              maxWidth: `${column.width}px`,
                              minWidth: `${column.width}px`,
                              width: `${column.width}`,
                              cursor: !column.disableSortBy ? 'pointer' : 'default',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            key={column.id}
                          >
                            {column.render('Header')}
                            {column.isSorted && column.isSortedDesc && (
                              <ArrowDownwardIcon className="linq-table__sort-icon" />
                            )}
                            {column.isSorted && !column.isSortedDesc && (
                              <ArrowUpwardIcon className="linq-table__sort-icon" />
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  </Fragment>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...getTrProps(row.getRowProps(), row.original)} key={row.index}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps({
                            className: classNames((cell.column as any).className, 'table-cell__text-content'),
                          })}
                          style={{
                            maxWidth: `${cell.column.width}px`,
                            minWidth: `${cell.column.width}px`,
                            width: `${cell.column.width}`,
                          }}
                          data-test-id={
                            (cell.column as any).dataTestId ? (cell.column as any).dataTestId(row) : undefined
                          }
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              {filteredData.length === 0 && <tr className="no-data-row" />}
            </tbody>
          </table>
        )}
      </div>
      {showFooter && (
        <footer className="table-footer">
          <div className="table-footer__actions">{bottomLeftContainer ? bottomLeftContainer : <div></div>}</div>
          <div className="table-footer__pagination">
            <div className="pagination__outer-container">
              <div className="paginator__container">
                <div className="paginator__page-size">
                  <div className="paginator__page-size-label">Items Per Page:</div>
                  <Select
                    type="select"
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                    disabled={rows.length === 0 || loading}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <div className="paginator__range-actions">
              <div className="paginator__range-label">
                {numberOfRows > 0 && (
                  <>
                    {pageStartIndex} - {pageEndIndex} of {numberOfRows}
                  </>
                )}
              </div>
              <IconButton
                onClick={() => {
                  setPageNumber(pageIndex);
                  previousPage();
                }}
                aria-label="Go to previous page of results"
                disabled={!canPreviousPage || loading}
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setPageNumber(pageIndex + 2);
                  nextPage();
                }}
                aria-label="Go to next page of results"
                disabled={!canNextPage || loading}
              >
                <ChevronRightIcon />
              </IconButton>
            </div>
            <div className="paginator__page-jump">
              <label htmlFor="page-input">Page:</label>
              <Input
                className="paginator__page-input"
                value={pageNumber}
                disabled={loading}
                onBlur={() => {
                  if (!pageNumber) {
                    setPageNumber(pageIndex + 1);
                  }
                }}
                onChange={(e) => {
                  if (!e.target.value) {
                    setPageNumber(undefined);
                  }
                  const value = Number(e.target.value);

                  if (isNaN(value)) {
                    return;
                  }
                  const pageNumber = value - 1;

                  if (pageNumber >= 0 && pageNumber <= pageOptions.length - 1) {
                    setPageNumber(value);
                    gotoPage(pageNumber);
                  }
                }}
              />
            </div>
          </div>
        </footer>
      )}
      <Filter
        open={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        filterFields={filterFields}
        setTableFilterState={setTableFilterState}
        tablefilterState={tableFilterState}
        handleCancelClick={handleFilterCancelClick}
        handleSubmit={handleFilterSidebarSubmit}
      />
    </div>
  );
};

export default ReactTableV2;
