import React from 'react';

import './PageFooter.less';

export interface PageFooterProps {
  leftContent?: JSX.Element[];
  rightContent?: JSX.Element[];
}

const PageFooter: React.FC<PageFooterProps> = ({ leftContent, rightContent, children }) => {
  const hasLeftContent = !!leftContent;
  const hasRightContent = !!rightContent;

  return (
    <footer className="page-footer">
      {hasLeftContent && <div className="left-content">{leftContent}</div>}
      {children}
      {hasRightContent && <div className="right-content">{rightContent}</div>}
    </footer>
  );
};

export default PageFooter;
