import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FormikValues } from 'formik';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { Role, TokenClaims } from 'Auth/User';
import { PROFILE_INCOMPLETE_WARNING, ToastConfig, ToastType } from 'Constants/Constants';
import { RouteId, getRoutePath } from 'Constants/Routes';
import { useAuthActions } from 'Auth/actions';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import ProfileForm from 'Components/ProfileForm/ProfileForm';
import NotificationSettingsForm from 'Components/NotificationSettingsForm/NotificationSettingsForm';
import { NotificationContext } from '../../NotificationContextProvider';

const Profile = () => {
  const { user } = useAuth0();
  const authActions = useAuthActions();
  const [loading, setLoading] = React.useState(false);
  const [notificationSettingsLoading, setNotificationSettingsLoading] = React.useState(false);
  const { addWarning, clearWarnings } = React.useContext(NotificationContext);
  const navigate = useNavigate();
  const { search } = useLocation();

  const isDirectorManagerUser = TokenClaims.getAdmin(user) || TokenClaims.getRole(user) === Role.MANAGER;

  const isIncomplete = React.useMemo(() => {
    return search.includes('incomplete');
  }, [search]);

  React.useEffect(() => {
    if (isIncomplete) {
      addWarning({ message: PROFILE_INCOMPLETE_WARNING });
    }
  }, [isIncomplete, addWarning]);

  React.useEffect(() => {
    return () => {
      clearWarnings();
    };
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (formValues: FormikValues) => {
    try {
      setLoading(true);
      await authActions.patchUser({
        givenName: formValues.firstName,
        familyName: formValues.lastName,
        email: formValues.email,
        password: formValues.password,
      });

      if (isIncomplete) {
        navigate(getRoutePath(RouteId.ROOT));
      } else if (user) {
        toast.success('Profile information updated successfully.', ToastConfig[ToastType.Success]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleNotificationFormSubmit = async (notificationSubscriptions: any) => {
    try {
      setNotificationSettingsLoading(true);
      await authActions.patchUser({ notificationSubscriptions });

      toast.success('Notification settings updated successfully.', ToastConfig[ToastType.Success]);
    } catch (err) {
      console.log(err);
    } finally {
      setNotificationSettingsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-layout">
        <SubHeader title="Profile" className="sub-header-family" />
        <ProfileForm loading={loading} handleSubmit={handleSubmit} user={user} />
        {isDirectorManagerUser && (
          <NotificationSettingsForm
            loading={notificationSettingsLoading}
            handleSubmit={handleNotificationFormSubmit}
            user={user}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Profile;
