import classNames from 'classnames';
import { addWeeks, format, formatISO, isSaturday, isSunday, previousSaturday, previousSunday } from 'date-fns';
import { getDaysInInterval } from 'Helpers/Helper';
import { chunk } from 'lodash';
import React from 'react';
import './CalendarBlock.less';

interface CalendarBlockProps {
  numberOfWeeks: number;
  date: Date;
  showWeekends?: boolean;
  orderedDates: string[];
  blackoutDates: string[];
  title: string;
  className?: string;
}

const CalendarBlock: React.FC<CalendarBlockProps> = ({
  showWeekends,
  numberOfWeeks,
  date,
  orderedDates,
  blackoutDates,
  title,
  className,
}) => {
  const orderedDatesSet = React.useMemo(() => {
    return new Set(orderedDates);
  }, [orderedDates]);
  const blackoutDatesSet = React.useMemo(() => {
    return new Set(blackoutDates);
  }, [blackoutDates]);

  const daysInInterval = showWeekends ? 7 : 5;

  const calendarWeeks = React.useMemo(() => {
    let startDate: Date;

    if (showWeekends) {
      startDate = isSunday(date) ? date : previousSunday(date);
    } else {
      startDate = isSaturday(date) ? date : previousSaturday(date);
    }

    const endDate = addWeeks(startDate, numberOfWeeks);
    const dates = getDaysInInterval(startDate, endDate, { excludeWeekends: !showWeekends }).slice(
      0,
      numberOfWeeks * daysInInterval,
    );

    return chunk(dates, daysInInterval);
  }, [date, numberOfWeeks, showWeekends, daysInInterval]);

  return (
    <table className={classNames('calendar-block', className)}>
      <thead>
        <tr>
          <td className="calendar-block__table-title" colSpan={daysInInterval}>
            {title}
          </td>
        </tr>
        <tr className="calendar-block__header-row">
          {showWeekends && <td className="calendar-block__item">S</td>}
          <td className="calendar-block__item">M</td>
          <td className="calendar-block__item">T</td>
          <td className="calendar-block__item">W</td>
          <td className="calendar-block__item">R</td>
          <td className="calendar-block__item">F</td>
          {showWeekends && <td className="calendar-block__item">S</td>}
        </tr>
      </thead>
      <tbody className="calendar-block__body">
        {calendarWeeks.map((weekDates, i) => {
          return (
            <tr className="calendar-block__row" key={i}>
              {weekDates.map((wd, j) => {
                return (
                  <td
                    className={classNames('calendar-block__item', {
                      ordered: orderedDatesSet.has(formatISO(wd, { representation: 'date' })),
                      'blacked-out': blackoutDatesSet.has(formatISO(wd, { representation: 'date' })),
                    })}
                    key={j}
                  >
                    {format(wd, 'd')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CalendarBlock;
