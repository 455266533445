import { Reducer } from 'react';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';

const initialState: Interfaces.DisclaimerModal = {
  open: false,
  showCloseButton: false,
};

const DisclaimerModal: Reducer<Interfaces.DisclaimerModal, Interfaces.Action> = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.DISCLAIMER_MODAL_SET_OPEN:
      return {
        ...state,
        open: payload,
      };
    case Types.DISCLAIMER_MODAL_SHOW_CLOSE_BUTTON:
      return {
        ...state,
        showCloseButton: payload,
      };

    default:
      return state;
  }
};

export default DisclaimerModal;
