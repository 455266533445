import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useApi } from 'Api/useApi';

export const useAuthActions = () => {
  const api = useApi();
  const { user: userObj, getAccessTokenSilently } = useAuth0();

  const [actions] = useState({
    patchUser: async (user: any) => {
      const id = userObj?.sub;

      if (!id) {
        return;
      }

      try {
        // BUG 70242 - Encode the id since Firefox throws a CORS error when ids are of the form '{PROVIDER}|...'
        const encodedId = encodeURIComponent(id);
        await api.patch(`/utility/users/${encodedId}`, user);
        await getAccessTokenSilently({ ignoreCache: true });
      } catch (err) {}
    },
  });

  return actions;
};
