import { useAuth0 } from '@auth0/auth0-react';
import { Role, TokenClaims } from 'Auth/User';
import { ERROR_TYPE } from 'Constants/Constants';
import { getRoutePath, RouteId } from 'Constants/Routes';
import { isFamilyPortal } from 'Helpers/Helper';
import ErrorPage from 'Pages/ErrorPage/ErrorPage';
import LandingPage from 'Pages/LandingPage/LandingPage';
import React from 'react';
import { Navigate } from 'react-router-dom';

const DashboardByRole: React.FC<{}> = () => {
  const { user } = useAuth0();

  const role = TokenClaims.getRole(user);
  const homeroomId = TokenClaims.getHomeroomId(user);

  switch (role) {
    case Role.PARENT:
      return <Navigate to={getRoutePath(RouteId.PARENT_DASHBOARD)} />;
    case Role.TEACHER:
      if (!homeroomId) {
        return <Navigate to={getRoutePath(RouteId.ACCOUNT_CONFIGURATION)} />;
      }
      return <Navigate to={getRoutePath(RouteId.TEACHER_DASHBOARD)} />;
    case Role.DIRECTOR:
    case Role.MANAGER:
      return <Navigate to={getRoutePath(RouteId.DISTRICT_DASHBOARD)} />;
    case Role.SFTP:
      return <ErrorPage errorType={ERROR_TYPE.SFTP} />;
    default:
      if (isFamilyPortal()) {
        return <LandingPage />;
      }

      return null;
  }
};

export default DashboardByRole;
