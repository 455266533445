import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Drawer, DrawerHeader, DrawerContent, DrawerFooter } from 'Components/Drawer';
import Button from 'Components/Button/Button';
import { FormikValues, FormikErrors, Formik, FormikProps, Form, Field, FieldProps } from 'formik';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { sortBy } from 'lodash';
import { User } from '@auth0/auth0-react';
import { Role } from 'Auth/User';
import { useApi } from 'Api/useApi';
import { InputField, SelectField } from 'Components/Form/MaterialForm';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';
import { SidebarLocations } from 'Constants/Enums';
import { useCommonActions } from 'State/actions';
import * as CommonSelectors from 'State/selectors';

import './UserInvite.less';

interface UserInviteFormFields {
  email: string;
  firstName: string;
  homeroomId?: string;
  lastName: string;
  role: Role;
  siteId?: string;
}

const UserInvite = (props: any) => {
  const {
    inviteUserModalOpen,
    setInviteUserModalOpen,
    loading,
    setLoading,
    districtId,
    districtName,
    users,
    getInvites,
  } = props;
  const api = useApi();

  const dispatch = useDispatch();
  const { getHomerooms, getSites } = useCommonActions();
  const [loadingHomerooms, setLoadingHomerooms] = useState(false);
  const sites = useSelector(CommonSelectors.getSites);
  const homerooms = useSelector(CommonSelectors.getHomerooms);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('A valid email address is required.')
      .test('accountExists', 'User already has an active account.', function (value) {
        return !!!users.find((u: User) => u.email === value);
      })
      .required('Email is a required field.'),
    firstName: yup.string().required('First Name is a required field.'),
    lastName: yup.string().required('Last Name is a required field.'),
  });

  useEffect(() => {
    getSites(districtId);
  }, [dispatch, districtId, getSites]);

  const inviteUserSave = useCallback(
    async (values: any) => {
      const invite = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        homeroomId: values.homeroomId,
        siteId: values.siteId,
        districtName: districtName,
        role: values.role,
      };
      const invitations = [invite];

      try {
        setLoading(true);
        const results: any = await api.post(`/utility/districts/${districtId}/invites`, invitations);

        if (results.status === 202) {
          toast.info(`Invite has been queued for ${invite.email}`, {
            position: 'bottom-right',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
          });
          setInviteUserModalOpen(false);
          getInvites(users);
        }
      } catch {
      } finally {
        setLoading(false);
      }
    },
    [api, districtId, districtName, getInvites, setInviteUserModalOpen, setLoading, users],
  );

  const handleSubmit = useCallback(
    (values) => {
      inviteUserSave(values);
    },
    [inviteUserSave],
  );

  const siteOptions = useMemo(
    () =>
      sortBy(Object.values(sites), 'name').map((site) => ({
        text: site?.name,
        value: site?.id,
      })),
    [sites],
  );

  const homeroomOptions = useMemo(
    () =>
      sortBy(Object.values(homerooms), 'name').map((homeroom) => ({
        text: homeroom.id,
        value: homeroom.id,
      })),
    [homerooms],
  );

  const handleChangeSiteId = useCallback(
    async (siteId: string) => {
      setLoadingHomerooms(true);
      await getHomerooms(siteId);
      setLoadingHomerooms(false);
    },
    [getHomerooms],
  );

  const handleValidation = (values: FormikValues): FormikErrors<{ siteId?: string; homeroomId?: string }> => {
    const errors: FormikErrors<{ siteId?: string; homeroomId?: string }> = {};
    if (values.role === Role.TEACHER) {
      if (!values.siteId) {
        errors.siteId = 'Site is Required';
      }
      if (!values.homeroomId) {
        errors.homeroomId = 'Homeroom is Required';
      }
    }

    return errors;
  };

  const initialValues: UserInviteFormFields = {
    email: '',
    firstName: '',
    homeroomId: '',
    lastName: '',
    role: Role.TEACHER,
    siteId: '',
  };

  return (
    <Drawer anchor="right" open={inviteUserModalOpen} id="invite-modal-content">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validate={handleValidation}
      >
        {({ errors, resetForm, values }: FormikProps<UserInviteFormFields>) => {
          return (
            <Form className="linq-drawer-form-content oo-drawer-form">
              <DrawerHeader>
                <h3 className="linq-heading">Invite User</h3>
              </DrawerHeader>
              <NotificationBanner location={SidebarLocations.USER_INVITE_SIDE_BAR} />
              <DrawerContent>
                <div className="invite-form">
                  <Field name="role">
                    {({ field, meta }: FieldProps) => (
                      <SelectField
                        fieldLabel="Role"
                        name={field.name}
                        id={field.name}
                        value={field.value}
                        defaultValue={Role.TEACHER}
                        error={!!meta.error}
                        errorMsg={meta.error}
                        touched={meta.touched}
                        onChange={field.onChange}
                        options={[
                          {
                            text: 'Teacher',
                            value: Role.TEACHER,
                          },
                          {
                            text: 'SFTP',
                            value: Role.SFTP,
                          },
                          { text: 'Director', value: Role.DIRECTOR },
                          { text: 'Manager', value: Role.MANAGER },
                        ]}
                      />
                    )}
                  </Field>
                  <Field name="email">
                    {({ field, meta }: FieldProps) => (
                      <InputField
                        name={field.name}
                        id={field.name}
                        error={!!meta.error}
                        errorMsg={meta.error}
                        touched={meta.touched}
                        label="Email"
                        onChange={field.onChange}
                        value={field.value}
                        inputProps={{
                          'data-test-id': 'emailInput',
                        }}
                      />
                    )}
                  </Field>
                  <Field name="firstName">
                    {({ field, meta }: FieldProps) => (
                      <InputField
                        name={field.name}
                        id={field.name}
                        error={!!meta.error}
                        errorMsg={meta.error}
                        touched={meta.touched}
                        label="First Name"
                        onChange={field.onChange}
                        value={field.value}
                        inputProps={{
                          'data-test-id': 'firstNameInput',
                        }}
                      />
                    )}
                  </Field>
                  <Field name="lastName">
                    {({ field, meta }: FieldProps) => (
                      <InputField
                        label="Last Name"
                        name={field.name}
                        id={field.name}
                        error={!!meta.error}
                        errorMsg={meta.error}
                        touched={meta.touched}
                        value={field.value}
                        onChange={field.onChange}
                        inputProps={{
                          'data-test-id': 'lastNameInput',
                        }}
                      />
                    )}
                  </Field>
                  {(values.role === Role.TEACHER || values.role === Role.MANAGER) && (
                    <>
                      <Field name="siteId">
                        {({ field, meta }: FieldProps) => (
                          <SelectField
                            fieldLabel="School Site"
                            name={field.name}
                            id={field.name}
                            error={!!meta.error && meta.touched}
                            errorMsg={meta.error}
                            touched={meta.touched}
                            value={field.value}
                            onChange={(e) => {
                              handleChangeSiteId(e.target.value);
                              field.onChange(e);
                            }}
                            options={siteOptions}
                          />
                        )}
                      </Field>
                    </>
                  )}
                  {values.role === Role.TEACHER && (
                    <>
                      <Field name="homeroomId">
                        {({ field, meta }: FieldProps) => (
                          <SelectField
                            fieldLabel="Homeroom"
                            name={field.name}
                            id={field.name}
                            error={!!meta.error && meta.touched}
                            errorMsg={meta.error}
                            touched={meta.touched}
                            value={field.value}
                            onChange={field.onChange}
                            readOnly={loadingHomerooms}
                            options={homeroomOptions}
                          />
                        )}
                      </Field>
                    </>
                  )}
                </div>
              </DrawerContent>
              <DrawerFooter>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    resetForm();
                    setInviteUserModalOpen(false);
                  }}
                  data-test-id="cancelBtn"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  variant="contained"
                  color="primary"
                  data-test-id="saveBtn"
                >
                  Save
                </Button>
              </DrawerFooter>
            </Form>
          );
        }}
      </Formik>
    </Drawer>
  );
};

export default UserInvite;
