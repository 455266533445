import { Reducer } from 'react';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';

const initialState: Interfaces.SwitchStudentCartModalState = {
  newStudentId: "",
  open: false,
};

const SwitchStudentCardModalReducer: Reducer<Interfaces.SwitchStudentCartModalState, Interfaces.Action> = (
  state = initialState, action) => {

  const { type, payload } = action;

  switch (type) {
    case Types.SWITCH_STUDENT_CART_MODAL_OPEN:
      return {
        ...state,
        open: payload.open,
        newStudentId: payload.newStudentId
      };

    default:
      return state;
  }
};

export default SwitchStudentCardModalReducer;
