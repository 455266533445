import { IconButton } from '@material-ui/core';
import TrashIcon from '@material-ui/icons/Delete';
import { getMomentUnit } from 'Components/DateRangeNavigator/DateRangeNavigator';
import { setDateRange, setStartDate } from 'Components/DateRangeNavigator/state/actions';
import { PageLoader } from 'Components/Loaders/Loaders';
import { setMenuTypeId } from 'Components/MenuTypePicker/state/actions';
import ReactTableV2 from 'Components/ReactTable/ReactTableV2/ReactTableV2';
import { sortDateColumn, sortMoneyColumn } from 'Components/ReactTable/Sorts';
import { DateRangeMode, FilterFieldType, PRODUCT_CATEGORIES } from 'Constants/Constants';
import * as LoaderSelectors from 'Components/Loaders/state/selectors';
import { getRoutePath, RouteId } from 'Constants/Routes';
import { formatISO, endOfDay, addDays } from 'date-fns';
import { createDayRange, isDateCancelable } from 'Helpers/Helper';
import { convertToDBISODate } from 'Helpers/DateHelpers';
import { TableFilterState } from 'Interfaces/Interfaces';
import moment from 'moment-timezone';
import { setDistrictId, setSiteId, setStudentId } from 'Pages/Shop/state/actions';
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Row } from 'react-table';
import { useParentCommonState } from 'State/hooks';
import * as CommonSelectors from 'State/selectors';
import { useTransactionHistoryActions } from './state/actions';
import { getOrderHistory } from './state/selectors';
import './TransactionHistory.less';

const DATE_RANGE_FIELD_NAME = 'Date Range';

const sortOrderActionDate = (rowA: Row<any>, rowB: Row<any>, columnId: string) => {
  const nameDateA = rowA.original[columnId].split(' ').slice(-2).join(' ');
  const nameDateB = rowB.original[columnId].split(' ').slice(-2).join(' ');

  if (nameDateA && !nameDateB) {
    return -1;
  }
  if (!nameDateA && nameDateB) {
    return 1;
  }
  if (!nameDateA && !nameDateB) {
    return 1;
  }
  const dateA = formatISO(new Date(nameDateA), { representation: 'date' });
  const dateB = formatISO(new Date(nameDateB), { representation: 'date' });
  return dateA.localeCompare(dateB) < 0 ? -1 : 1;
};

const createInitialDates = () => {
  const currentDay = new Date();
  const fromDate = addDays(currentDay, -45);
  const toDate = addDays(currentDay, 45);

  return { fromDate: fromDate, toDate: toDate };
};

const TransactionHistory = () => {
  const [transactionHistoryLoading, setTransactionHistoryLoading] = React.useState(false);
  const pageLoading = useSelector(LoaderSelectors.getPageLoaderActive);
  const transactionHistoryActions = useTransactionHistoryActions();
  const students = useSelector(CommonSelectors.getStudents);
  const orderHistory = useSelector(getOrderHistory);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sId = searchParams.get('studentId');

  const loadTransactionHistory = React.useCallback(
    async function loadTransactionHistory(fromDate: string, toDate: string) {
      setTransactionHistoryLoading(true);

      await transactionHistoryActions.getTransactionHistory(fromDate, toDate);
      setTransactionHistoryLoading(false);
    },
    [transactionHistoryActions],
  );

  useParentCommonState();
  React.useEffect(() => {
    //Initially Load data from 2 weeks prior to the start of the week and 2 weeks ahead of the start of the week
    const { fromDate, toDate } = createInitialDates();
    const convertedFromDate = convertToDBISODate(fromDate);
    const convertedToDate = convertToDBISODate(toDate);

    loadTransactionHistory(convertedFromDate, convertedToDate);
    // eslint-disable-next-line
  }, []);

  const handleFilterChange = (previousTableFilterState: TableFilterState, tableFilterState: TableFilterState) => {
    const previousFromDateISO = formatISO(new Date(previousTableFilterState[DATE_RANGE_FIELD_NAME].value.fromDate));
    const currentFromDateISO = formatISO(new Date(tableFilterState[DATE_RANGE_FIELD_NAME].value.fromDate));

    const previousToDateISO = formatISO(new Date(previousTableFilterState[DATE_RANGE_FIELD_NAME].value.toDate));
    const currentToDateISO = formatISO(new Date(tableFilterState[DATE_RANGE_FIELD_NAME].value.toDate));

    if (previousFromDateISO !== currentFromDateISO || previousToDateISO !== currentToDateISO) {
      const convertedFromDate = convertToDBISODate(new Date(tableFilterState[DATE_RANGE_FIELD_NAME].value.fromDate));
      const convertedToDate = convertToDBISODate(
        new Date(formatISO(endOfDay(tableFilterState[DATE_RANGE_FIELD_NAME].value.toDate))),
      );

      loadTransactionHistory(convertedFromDate, convertedToDate);
    }
  };

  const columns = [
    {
      Header: 'Serve Date',
      accessor: 'date',
      width: 100,
      sortType: sortDateColumn,
      filterField: {
        fieldName: DATE_RANGE_FIELD_NAME,
        fieldType: FilterFieldType.dateRange,
        ordinal: 2,
        alwaysSet: true,
        initialValue: createInitialDates(),
        dateRangeOptions: {
          fromDateLabel: 'From',
          toDateLabel: 'To',
          maxDayRange: 90,
        },
      },
      dataTestId: (row: any) =>
        `${row.original.date}.${row.original.menuTypeName}.${row.original.account}.serveDateLbl`,
    },
    {
      Header: 'Account',
      accessor: 'account',
      width: 200,
      filterField: {
        fieldName: 'Account',
        fieldType: FilterFieldType.select,
        valueFieldAccessor: 'studentId',
        initialValue:
          sId && students[sId] ? { value: sId, text: `${students[sId].firstName} ${students[sId].lastName}` } : null,
        staticOptions: Object.keys(students).map((id: any) => ({
          value: id,
          text: `${students[id].firstName} ${students[id].lastName}`,
        })),
        ordinal: 1,
      },
      dataTestId: (row: any) => `${row.original.date}.${row.original.menuTypeName}.${row.original.account}.accountLbl`,
    },
    {
      Header: 'Menu',
      accessor: 'menuTypeName',
      width: 300,
      filterField: { fieldName: 'Menu', fieldType: FilterFieldType.select, ordinal: 3 },
      dataTestId: (row: any) => `${row.original.date}.${row.original.menuTypeName}.${row.original.account}.menuTypeLbl`,
    },
    {
      Header: 'Type',
      accessor: 'type',
      width: 100,
      filterField: {
        fieldName: 'Type',
        fieldType: FilterFieldType.checkbox,
        ordinal: 5,
        staticOptions: ['Meal', 'A La Carte'],
      },
      dataTestId: (row: any) => `${row.original.date}.${row.original.menuTypeName}.${row.original.account}.typeLbl`,
    },
    {
      Header: 'Items',
      accessor: 'items',
      width: 400,
      resizable: true,
      className: 'transaction-history__items',
      dataTestId: (row: any) => `${row.original.date}.${row.original.menuTypeName}.${row.original.account}.itemsLbl`,
    },
    {
      Header: 'Pickup Location',
      accessor: 'pickupLocationName',
      width: 300,
      resizable: true,
      filterField: {
        fieldName: 'Pickup Location',
        fieldType: FilterFieldType.select,
        ordinal: 4,
      },
      dataTestId: (row: any) =>
        `${row.original.date}.${row.original.menuTypeName}.${row.original.account}.pickupLocationLbl`,
    },
    {
      Header: 'Total',
      accessor: 'total',
      sortType: sortMoneyColumn,
      dataTestId: (row: any) => `${row.original.date}.${row.original.menuTypeName}.${row.original.account}.totalLbl`,
    },
    {
      Header: 'Ordered By',
      accessor: 'orderedBy',
      width: 300,
      resizable: true,
      sortType: sortOrderActionDate,
      dataTestId: (row: any) =>
        `${row.original.date}.${row.original.menuTypeName}.${row.original.account}.orderedByLbl`,
    },
    {
      Header: 'Canceled By',
      accessor: 'canceledBy',
      width: 300,
      resizable: true,
      sortType: sortOrderActionDate,
      dataTestId: (row: any) =>
        `${row.original.date}.${row.original.menuTypeName}.${row.original.account}.canceledByLbl`,
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 100,
      Cell: (props: any) => {
        const {
          row: {
            original: { status, date, menuTypeId, siteId, districtId, studentId, productCategoryId },
          },
        } = props;
        const orderDate = new Date(date);
        if (
          status !== 'Canceled' &&
          isDateCancelable(orderDate) &&
          productCategoryId !== PRODUCT_CATEGORIES.ALACARTE.id
        ) {
          return (
            <IconButton
              className="transaction-history__cancel-btn"
              onClick={() => {
                const startOfWeek = moment(orderDate).startOf('week');
                batch(() => {
                  dispatch(setStartDate(moment(orderDate)));
                  dispatch(
                    setDateRange(createDayRange({ startDate: startOfWeek, unit: getMomentUnit(DateRangeMode.WEEK) })),
                  );
                  dispatch(setMenuTypeId(menuTypeId));
                  dispatch(setStudentId(studentId));
                  dispatch(setDistrictId(districtId));
                  dispatch(setSiteId(siteId));
                });
                navigate(getRoutePath(RouteId.SHOP));
              }}
              aria-label="Go to cancel order page"
              data-test-id={`${props.row.original.date}.${props.row.original.menuTypeName}.${props.row.original.account}.cancelOrderBtn`}
            >
              <TrashIcon />
            </IconButton>
          );
        }

        return status;
      },
      dataTestId: (row: any) => `${row.original.date}.${row.original.menuTypeName}.${row.original.account}.statusLbl`,
    },
  ];

  return (
    <>
      <div className="transaction-history">
        <PageLoader text={'Loading'} />
        {!pageLoading && (
          <ReactTableV2
            initialState={{ disableSortRemove: true }}
            data={orderHistory}
            columns={columns}
            loading={transactionHistoryLoading}
            onFilterChange={handleFilterChange}
          />
        )}
      </div>
    </>
  );
};

export default TransactionHistory;
