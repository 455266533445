export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_CLEAR = 'CART_CLEAR';
export const CART_ADD_CHILD_ITEM = 'CART_ADD_CHILD_ITEM';
export const CART_TOGGLE_CHECKOUT = 'CART_TOGGLE_CHECKOUT';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_TOGGLE_PICKUP = 'CART_TOGGLE_PICKUP';
export const CART_SET_PICKUP_LOCATIONS = 'CART_SET_PICKUP_LOCATIONS';
export const CART_CLEAR_PICKUP_LOCATIONS = 'CART_CLEAR_PICKUP_LOCATIONS';
export const CART_SUBMIT_ORDER = 'CART_SUBMIT_ORDER';
export const CART_SET_ERROR = 'CART_SET_ERROR';
export const CART_SET_ORDER_ID = 'CART_SET_ORDER_ID';
export const CART_SET_ACCOUNT_ID = 'CART_SET_ACCOUNT_ID';
export const CART_SET_COMMENTS = 'CART_SET_COMMENTS';
