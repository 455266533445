import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import classNames from 'classnames';
import { ConditionalWrapper } from 'Helpers/ConditionalWrapper';
import * as Interfaces from 'Interfaces/Interfaces';
import React, { FC, Fragment } from 'react';
import { Column, HeaderGroup, SortingRule, TableOptions, useFlexLayout, useSortBy, useTable } from 'react-table';
import './ScrollingReactTable.less';

const defaultColumn: Partial<Column> = {};

interface ScrollingReactTableProps extends Interfaces.ReactTableProps {
  footer?: React.ReactNode;
  ref?: any;
}

const ScrollingReactTable: FC<ScrollingReactTableProps> = (
  {
    columns,
    data,
    initialState = {},
    noDataText = 'No data available.',
    className,
    footer,
    disableSortRemove = true,
    autoResetSortBy = true,
  },
  ref,
) => {
  const tableOptions: TableOptions<object> = {
    columns,
    data,
    initialState,
    defaultColumn,
    disableSortRemove,
    autoResetSortBy,
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, setSortBy } = useTable(
    tableOptions,
    useSortBy,
    useFlexLayout,
  );

  React.useImperativeHandle(ref, () => ({
    resetSort: (sortBy: SortingRule<object>[]) => {
      setSortBy(sortBy);
    },
  }));

  // 32 from row padding style
  const rowWidth = React.useMemo(() => columns.reduce((acc, column) => acc + column.width || 0, 0) + 32, [columns]);

  return (
    <div className={classNames('scrolling-table-container', className)}>
      <div {...getTableProps()} className="scrolling-table__table">
        <div {...getTableBodyProps()} className="tbody">
          <div className="scrolling-table__thead" style={{ minWidth: rowWidth }}>
            {headerGroups.map((headerGroup: HeaderGroup, i: number) => {
              return (
                <div {...headerGroup.getHeaderGroupProps()} key={`${headerGroup.id}${i}`} className="tr">
                  {headerGroup.headers.map((column) => {
                    return (
                      <div
                        className={classNames((column as any).className, 'th')}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        key={column.id}
                      >
                        <ConditionalWrapper
                          condition={!column.disableSortBy}
                          wrapper={(children: React.ReactNode) => (
                            <button className="scrolling-table__header-btn btn-reset header no-highlight">
                              {children}
                            </button>
                          )}
                        >
                          {column.render('Header')}
                          {!column.disableSortBy && (
                            <div className="arrows">
                              {!column.isSorted && (
                                <>
                                  <ArrowDropUp className={classNames('disabled', 'sort-up')} />
                                  <ArrowDropDown className={classNames('disabled', 'sort-down')} />
                                </>
                              )}

                              {column.isSorted && (
                                <>
                                  <ArrowDropUp
                                    className={classNames({ disabled: column.isSortedDesc, 'sort-up': true })}
                                  />
                                  <ArrowDropDown
                                    className={classNames({ disabled: !column.isSortedDesc, 'sort-down': true })}
                                  />
                                </>
                              )}
                            </div>
                          )}
                        </ConditionalWrapper>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          {rows.map((row) => {
            prepareRow(row);
            const { style, ...rest } = row.getRowProps();
            return (
              <div {...rest} className="tr" style={{ ...style, minWidth: rowWidth }}>
                {row.cells.map((cell) => {
                  return (
                    <div
                      {...cell.getCellProps({
                        className: classNames((cell.column as any).className, 'td'),
                      })}
                      data-test-id={(cell.column as any).dataTestId ? (cell.column as any).dataTestId(row) : undefined}
                    >
                      {cell.render('Cell')}
                    </div>
                  );
                })}
              </div>
            );
          })}
          {data.length > 0 && <div style={{ minWidth: rowWidth }}>{footer}</div>}
          {data.length === 0 && <div className="no-data-msg">{noDataText}</div>}
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef<FC<React.ReactNode>, ScrollingReactTableProps>(ScrollingReactTable as any);
