import { useAuth0 } from '@auth0/auth0-react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { isAuthorized, Role, TokenClaims } from 'Auth/User';
import classNames from 'classnames';
import ButtonGroup, { ButtonGroupOption } from 'Components/ButtonGroup/ButtonGroup';
import DateRangeNavigator from 'Components/DateRangeNavigator/DateRangeNavigator';
import * as DateRangeNavigatorSelectors from 'Components/DateRangeNavigator/state/selectors';
import { NotificationDialog } from 'Components/Dialogs/NotificationDialog';
import Breadcrumbs from 'Components/District/Breadcrumbs/Breadcrumbs';
import DistrictReportTable from 'Components/District/DistrictReportTable/DistrictReportTable';
import PickupLocationReportTable from 'Components/District/PickupLocationReportTable/PickupLocationReportTable';
import SiteReportTable from 'Components/District/SiteReportTable/SiteReportTable';
import { ComponentLoaderNoRedux } from 'Components/Loaders/Loaders';
import { useMenuTypePickerActions } from 'Components/MenuTypePicker/state/actions';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import { DateRangeMode, MOBILE_MEDIA_QUERY } from 'Constants/Constants';
import { REPORT_SCOPE, REPORT_TIME_FRAME } from 'Constants/Enums';
import { OrderItem, Student } from 'Interfaces/Interfaces';
import { flatten, get } from 'lodash';
import { Moment } from 'moment-timezone';
import { useDistrictReportActions } from 'Pages/DistrictReports/state/actions';
import * as DistrictReportsSelectors from 'Pages/DistrictReports/state/selectors';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useCommonActions } from 'State/actions';
import * as CommonSelectors from 'State/selectors';
import ReportMenuTypePicker from './ReportMenuTypePicker';
import { hasActiveOrders } from './hasActiveOrders';
import Title from './Title';
import Totals from './Totals';
import { CustomDateModal } from './CustomDateModal';

import './DistrictReports.less';

function getDeleteOrderMessage(student: Student | undefined | null, timeFrame: REPORT_TIME_FRAME) {
  const timeUnit = `${timeFrame === REPORT_TIME_FRAME.MONTHLY ? 'month' : 'week'}`;
  if (student) {
    let message = `Are you sure you want to cancel all orders for this ${timeUnit} for`;

    if (timeFrame === REPORT_TIME_FRAME.DAILY) {
      message = 'Are you sure you want to cancel this order for';
    }
    return `${message} ${student.firstName} ${student.lastName}?`;
  } else {
    if (timeFrame === REPORT_TIME_FRAME.DAILY) {
      return 'Are you sure you want to cancel this bulk order item?';
    }
    return `Are you sure want to cancel all bulk order items for this ${timeUnit}?`;
  }
}

interface RefreshDataOptions {
  currentDate: Moment;
  dayRange: { startDate: Moment; endDate: Moment };
  pickupLocationId: string;
  siteId: string;
  timeFrame: REPORT_TIME_FRAME;
  userRole: Role;
  userSiteId: string;
}

const getDateRangeMode = (timeFrame: REPORT_TIME_FRAME) => {
  switch (timeFrame) {
    case REPORT_TIME_FRAME.WEEKLY:
      return DateRangeMode.WEEK;
    case REPORT_TIME_FRAME.MONTHLY:
      return DateRangeMode.MONTH;
    case REPORT_TIME_FRAME.CUSTOM:
      return DateRangeMode.CUSTOM;
    default:
      return DateRangeMode.DAY;
  }
};

const DistrictReports: FC<{}> = () => {
  const [scope, setScope] = useState<REPORT_SCOPE>(REPORT_SCOPE.DISTRICT);
  const [timeFrame, setTimeFrame] = useState<REPORT_TIME_FRAME>(REPORT_TIME_FRAME.DAILY);
  const [ordersToDelete, setOrdersToDelete] = useState<{ student: Student | undefined | null; orders: OrderItem[] }>();
  const [isDeletingOrders, setDeletingOrders] = useState<boolean>(false);
  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const districtId = TokenClaims.getDistrictId(user);
  const userSiteId = TokenClaims.getSiteId(user);
  const report = useSelector(DistrictReportsSelectors.getReport);
  const studentReport = useSelector(DistrictReportsSelectors.getReportStudents);
  const pickupLocationId = useSelector(DistrictReportsSelectors.getPickupLocationId);
  const siteId = useSelector(DistrictReportsSelectors.getSiteId);
  const districts = useSelector(CommonSelectors.getDistricts);
  const startDate = useSelector(DateRangeNavigatorSelectors.getStartDate);
  const dayRange = useSelector(DateRangeNavigatorSelectors.getDayRange);
  const menuTypes = useSelector(DistrictReportsSelectors.getReportMenuTypes);
  const defaultMenuType = useSelector(DistrictReportsSelectors.getDefaultMenuType(districtId));
  const menuTypeId = useSelector(DistrictReportsSelectors.getReportMenuTypeId) || defaultMenuType.id;
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY });
  const sites = get(report, 'sites', {});
  const pickupLocations = get(report, 'pickupLocations', {});
  const menuTypePickerActions = useMenuTypePickerActions();
  const { deleteOrderItems } = useCommonActions();
  const { getReportStudents, getReportOrders } = useDistrictReportActions();
  const [loading, setLoading] = useState<boolean>(true);
  const [customDateModalOpen, setCustomDateModalOpen] = React.useState<boolean>(false);
  const [customFromDate, setCustomFromDate] = React.useState<Date | undefined>(new Date());
  const [customEndDate, setCustomEndDate] = React.useState<Date | undefined>(new Date());

  // Load the menu types
  useEffect(() => {
    menuTypePickerActions.getDistrictMenuTypes(districtId);
  }, [districtId, menuTypePickerActions]);
  // Set the report scope which is used to clean up siteId and pickupLocationId checks
  useEffect(() => {
    if (siteId && pickupLocationId) {
      setScope(REPORT_SCOPE.LOCATION);
    } else if (siteId) {
      setScope(REPORT_SCOPE.SITE);
    } else if (pickupLocationId) {
      setScope(REPORT_SCOPE.LOCATION_GLOBAL);
    } else {
      setScope(REPORT_SCOPE.DISTRICT);
    }
  }, [siteId, pickupLocationId]);

  const refreshData = useCallback(
    async ({
      currentDate,
      timeFrame,
      dayRange,
      pickupLocationId,
      siteId,
      userRole,
      userSiteId,
    }: RefreshDataOptions) => {
      setLoading(true);
      let dateFrom = currentDate;
      let dateTo = currentDate;

      if (
        timeFrame === REPORT_TIME_FRAME.WEEKLY ||
        timeFrame === REPORT_TIME_FRAME.MONTHLY ||
        timeFrame === REPORT_TIME_FRAME.CUSTOM
      ) {
        dateFrom = dayRange.startDate;
        dateTo = dayRange.endDate;
      }

      const requests = [];
      let reportSiteId = siteId;
      let includeGlobalPickupLocations = false;

      if (scope === REPORT_SCOPE.DISTRICT || scope === REPORT_SCOPE.LOCATION_GLOBAL) {
        includeGlobalPickupLocations = true;
      }

      if (pickupLocationId) {
        requests.push(getReportStudents(dateFrom, dateTo, [pickupLocationId], siteId));
      }
      if (isAuthorized(userRole, [Role.MANAGER])) {
        reportSiteId = userSiteId;
      }
      requests.push(getReportOrders(dateFrom, dateTo, includeGlobalPickupLocations, reportSiteId));
      await Promise.all(requests);

      setLoading(false);
    },
    [getReportOrders, getReportStudents, scope],
  );

  // Complicate logic for calculating if orders are active
  const mealsOrdered = useMemo(
    () =>
      hasActiveOrders({
        scope,
        timeFrame,
        report,
        menuTypeId,
        studentReport,
        siteId,
      }),
    [menuTypeId, report, scope, siteId, studentReport, timeFrame],
  );

  // Delete a list of orderMenuItems and refresh the report
  const deleteOrders = useCallback(async () => {
    setDeletingOrders(true);
    const orders = ordersToDelete?.orders || [];
    if (orders?.length) {
      if (timeFrame === REPORT_TIME_FRAME.DAILY) {
        await deleteOrderItems(orders);
      } else {
        const ordersByDay = !ordersToDelete?.student ? orders : orders.map((ordersInDay) => Object.values(ordersInDay));
        const weeklyOrders = flatten(ordersByDay);

        await deleteOrderItems(weeklyOrders);
      }
    }
    setDeletingOrders(false);
    setOrdersToDelete(undefined);
    await refreshData({
      currentDate: startDate,
      dayRange,
      pickupLocationId,
      siteId,
      userRole,
      userSiteId,
      timeFrame,
    });
  }, [
    startDate,
    dayRange,
    ordersToDelete,
    pickupLocationId,
    siteId,
    timeFrame,
    userRole,
    userSiteId,
    deleteOrderItems,
    refreshData,
  ]);

  useEffect(() => {
    refreshData({
      currentDate: startDate,
      dayRange,
      pickupLocationId,
      siteId,
      userRole,
      userSiteId,
      timeFrame,
    });
  }, [startDate, dayRange, pickupLocationId, siteId, userRole, userSiteId, timeFrame, refreshData]);

  const handleCustomDateModalSubmit = (newStartDate: Date | null, newEndDate: Date | null) => {
    if (newStartDate && newEndDate) {
      setCustomFromDate(newStartDate);
      setCustomEndDate(newEndDate);
    }
    setCustomDateModalOpen(false);
  };

  return (
    <div className={classNames('district-reports-container', { 'district-reports-container-mobile': isMobile })}>
      <SubHeader
        leftComponent={
          <Title
            scope={scope}
            site={sites[siteId]}
            district={districts[districtId]}
            pickupLocation={pickupLocations[pickupLocationId]}
          />
        }
        rightComponent={
          <>
            <span className="report-day-week-toggle">
              <ButtonGroup
                options={[
                  { label: 'Day', value: REPORT_TIME_FRAME.DAILY },
                  { label: 'Week', value: REPORT_TIME_FRAME.WEEKLY },
                  { label: 'Month', value: REPORT_TIME_FRAME.MONTHLY },
                  { label: 'Custom', value: REPORT_TIME_FRAME.CUSTOM, icon: <DateRangeIcon /> },
                ]}
                onChange={(option: ButtonGroupOption) => {
                  if (option.value === REPORT_TIME_FRAME.CUSTOM) {
                    setCustomDateModalOpen(true);
                  }
                  setTimeFrame(option.value as REPORT_TIME_FRAME);
                }}
              />
            </span>
          </>
        }
      />
      <div className="report-body">
        <Breadcrumbs siteId={siteId} pickupLocationId={pickupLocationId} report={report} />
        <div className="oo-row edges">
          <ReportMenuTypePicker />
          <DateRangeNavigator
            mode={getDateRangeMode(timeFrame)}
            customFromDate={customFromDate}
            customEndDate={customEndDate}
            datePicker={true}
          />
        </div>
        <ComponentLoaderNoRedux loading={loading} />
        {!loading && (
          <>
            <div className="product-totals">
              {report && (
                <Totals
                  mealsOrdered={mealsOrdered}
                  menuTypes={menuTypes}
                  menuTypeId={menuTypeId}
                  pickupLocationId={pickupLocationId}
                  report={report}
                  scope={scope}
                  timeFrame={timeFrame}
                  siteId={siteId}
                />
              )}
            </div>
            {!mealsOrdered && (
              <div className="no-meals-message">
                <Card>
                  <CardContent>
                    <Typography variant="h5">No meals ordered.</Typography>
                  </CardContent>
                </Card>
              </div>
            )}
            {mealsOrdered && (
              <div className="grid">
                {[REPORT_SCOPE.LOCATION, REPORT_SCOPE.LOCATION_GLOBAL].indexOf(scope) > -1 && (
                  <PickupLocationReportTable
                    report={report}
                    viewByDateRange={
                      timeFrame === REPORT_TIME_FRAME.WEEKLY ||
                      timeFrame === REPORT_TIME_FRAME.MONTHLY ||
                      timeFrame === REPORT_TIME_FRAME.CUSTOM
                    }
                    scope={scope}
                    setOrdersToDelete={setOrdersToDelete}
                  />
                )}
                {scope === REPORT_SCOPE.SITE && (
                  <SiteReportTable
                    report={report}
                    viewByDateRange={
                      timeFrame === REPORT_TIME_FRAME.WEEKLY ||
                      timeFrame === REPORT_TIME_FRAME.MONTHLY ||
                      timeFrame === REPORT_TIME_FRAME.CUSTOM
                    }
                    scope={scope}
                  />
                )}
                {scope === REPORT_SCOPE.DISTRICT && (
                  <DistrictReportTable
                    report={report}
                    viewByDateRange={
                      timeFrame === REPORT_TIME_FRAME.WEEKLY ||
                      timeFrame === REPORT_TIME_FRAME.MONTHLY ||
                      timeFrame === REPORT_TIME_FRAME.CUSTOM
                    }
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
      {!!ordersToDelete && (
        <NotificationDialog
          title={`Cancel Order`}
          message={getDeleteOrderMessage(ordersToDelete.student, timeFrame)}
          showDialog={true}
          showCancel={true}
          cancelButtonText="CANCEL"
          okButtonText="OK"
          onOkClick={deleteOrders}
          onCancelClick={() => {
            setOrdersToDelete(undefined);
          }}
          dialogType="delete"
          okIcon="checkmark"
          loading={isDeletingOrders}
        />
      )}
      <CustomDateModal
        open={customDateModalOpen}
        setOpen={setCustomDateModalOpen}
        handleSubmit={handleCustomDateModalSubmit}
      />
    </div>
  );
};

export default DistrictReports;
