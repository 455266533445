import React, { FC } from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import { NotificationTypes } from '../../constants/Enums';

interface NotificationItemProps {
  notification: string;
  onCloseClick: () => void;
  totalNotifications: number;
  isFirst: boolean;
  showAllNotifications: boolean;
  onViewAllClick: () => void;
  onClearAllClick: () => void;
  type: number;
}

const NotificationItem: FC<NotificationItemProps> = ({
  notification,
  onCloseClick,
  totalNotifications,
  isFirst,
  showAllNotifications,
  onViewAllClick,
  onClearAllClick,
  type,
}) => {
  const getIcon = (notificationType: number) => {
    switch (notificationType) {
      case NotificationTypes.Error:
        return <ErrorIcon className="linq-banner__icon" />;
      case NotificationTypes.Warning:
        return <WarningIcon className="linq-banner__icon" />;
      default:
        return null;
    }
  };

  const getNotificationTypeName = (notificationType: number) => {
    switch (notificationType) {
      case NotificationTypes.Error:
        return 'errors';
      case NotificationTypes.Warning:
        return 'warnings';
      default:
        return '';
    }
  };

  return (
    <div className="linq-banner__item">
      {getIcon(type)}
      <div className="linq-banner__message" aria-live="polite" role="alert">
        {notification}
      </div>
      <div className="linq-banner__actions">
        {isFirst && totalNotifications > 1 && !showAllNotifications && (
          <button
            className="linq-banner__action"
            aria-label={`View all ${getNotificationTypeName(type)} (${totalNotifications})`}
            onClick={onViewAllClick}
          >
            {`View all ${getNotificationTypeName(type)} (${totalNotifications})`}
          </button>
        )}
        {isFirst && totalNotifications > 1 && (
          <button
            className="linq-banner__action"
            aria-label={`Clear all ${getNotificationTypeName(type)} notifications`}
            onClick={onClearAllClick}
          >
            Clear All
          </button>
        )}
        <IconButton className="linq-banner__action linq-banner__action-clear linq-button--icon" onClick={onCloseClick}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default NotificationItem;
