import React from 'react';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import DrawerCore, { DrawerProps as DrawerPropsCore } from '@material-ui/core/Drawer';
import { MOBILE_MEDIA_QUERY } from 'Constants/Constants';

interface DrawerProps extends DrawerPropsCore {}

const Drawer: React.FC<DrawerProps> = ({ className = '', children, ...props }) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY });

  return (
    <DrawerCore
      className={classNames(`linq-drawer linq-drawer-lg oo-drawer`, className, { 'oo-drawer-mobile': isMobile })}
      {...props}
    >
      {children}
    </DrawerCore>
  );
};

export default Drawer;
