//@ts-nocheck
export const hideResourceCenterOffClick = (e: any) => {
  let tgt = e.target;
  let path = e.composedPath();
  let resourceCenterDescendant = false;
  for (let i = 0; i < path.length; i++) {
    if (path[i].id === 'pendo-resource-center-container') {
      resourceCenterDescendant = true;
    }
  }
  if (
    window.pendo.dom('#pendo-resource-center-container').length &&
    !resourceCenterDescendant &&
    !pendo
      .dom(tgt)
      .closest(pendo.BuildingBlocks.BuildingBlockResourceCenter.getResourceCenter().steps[0].elementPathRule).length
  ) {
    window.pendo.BuildingBlocks.BuildingBlockResourceCenter.dismissResourceCenter();
  }
};
