import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, IconButton, Checkbox } from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import Barcode from 'Components/Barcode/Barcode';
import { actionColumnDef } from 'Components/ReactTable/ActionColumn';
import ReactTable from 'Components/ReactTable/ReactTable';
import * as DateRangeNavigatorSelectors from 'Components/DateRangeNavigator/state/selectors';
import * as Constants from 'Constants/Constants';
import * as Helpers from 'Helpers/Helper';
import * as Interfaces from 'Interfaces/Interfaces';
import { PickupLocationColumn, Product } from 'Interfaces/Interfaces';
import { flatten, get } from 'lodash';
import moment from 'moment';
import { sortItems } from 'Pages/DistrictReports/ReportUtil';
import { useDistrictReportActions, changeBulkOrderQuantity } from 'Pages/DistrictReports/state/actions';
import * as DistrictReportsSelectors from 'Pages/DistrictReports/state/selectors';
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import { useApi } from 'Api/useApi';
import { TokenClaims } from 'Auth/User';
import IncrementalNumberInput from 'Components/Form/IncrementalNumberInput';
import Button from 'Components/Button/Button';
import { ButtonLink } from 'Components/ButtonLink/ButtonLink';
import PrintLabelsOptionsModal from '../PrintLabelsOptionsModal/PrintLabelsOptionsModal';
import { sortOrderedByColumn, sortReceivedColumn, sortStudentColumn, sortSubmittedoOnColumn } from './SortUtil';

import './PickupLocationReportTable.less';

const getStudentProductsByTypeAndDay = (menuTypeIds: string[], studentId: string, ordersByStudent: any): any => {
  // First group the orders by menuTypeId
  return menuTypeIds.reduce((acc: { [menuTypeId: string]: any }, menuTypeId: string) => {
    const ordersByMenuType = get(ordersByStudent, `${studentId}.${menuTypeId}`, {});
    return {
      ...acc,
      // Next group the orders by date and sort the orderMenuItems for the date
      [menuTypeId]: Object.entries(ordersByMenuType).reduce((acc: any, [date, orders]: [any, any]) => {
        return {
          ...acc,
          // Finally convert the order into a array with totals
          [date]: sortItems(Object.values(orders), (x) => x as Product).map((order: any) => {
            return {
              name: `${order.name}${+order?.total > 1 ? ` x${order.total}` : ''}`,
              productCategoryId: order.productCategoryId,
              orderedAt: order.orderedAt,
              orderedBy: order.orderedBy,
              comments: order.comments,
            };
          }),
        };
      }, {}),
    };
  }, {});
};

export enum RowType {
  Student = 'Student',
  BulkOrder = 'BulkOrder',
}

const PickupLocationReportTable: FC<Interfaces.PickupLocationReportTableProps> = ({
  viewByDateRange,
  setOrdersToDelete,
  scope,
}) => {
  const dispatch = useDispatch();
  const api = useApi();
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);
  const { patchOrderItems } = useDistrictReportActions();
  const studentReport = useSelector(DistrictReportsSelectors.getReportStudents);
  const bulkOrdersByProduct = useSelector(DistrictReportsSelectors.getBulkOrdersByProduct);
  const students = get(studentReport, 'students', {});
  const ordersByStudent = get(studentReport, 'ordersByStudent', {});
  const menuTypes = useSelector(DistrictReportsSelectors.getReportMenuTypes);
  const defaultMenuType = useSelector(DistrictReportsSelectors.getDefaultMenuType(districtId));
  const menuTypeIds = useSelector(DistrictReportsSelectors.getReportMenuTypeIds);
  const menuTypeId = useSelector(DistrictReportsSelectors.getReportMenuTypeId) || defaultMenuType.id;
  const startDate = useSelector(DateRangeNavigatorSelectors.getStartDate);
  const dayRange = useSelector(DateRangeNavigatorSelectors.getDayRange);
  const siteId = useSelector(DistrictReportsSelectors.getSiteId);
  const pickupLocationId = useSelector(DistrictReportsSelectors.getPickupLocationId);
  const printLabelScopeRangeParameters = useSelector(
    DistrictReportsSelectors.getPrintLabelScopeRangeParameters(scope, viewByDateRange),
  );
  const [printLabelOptionsModalOpen, setPrintLabelsOptionsModalOpen] = React.useState(false);

  const subject = user?.sub ?? '';

  const A_SORT = 'a';
  const B_SORT = 'b';

  let dateFrom = startDate;
  let dateTo = startDate;

  if (viewByDateRange) {
    dateFrom = dayRange.startDate;
    dateTo = dayRange.endDate;
  }

  const handleReceivedChecked = useCallback(
    async (e: React.SyntheticEvent<HTMLInputElement>, data) => {
      const studentId = data.studentId;
      const studentOrders = get(ordersByStudent, `${studentId}.${menuTypeId}`, {});

      // List containing list of order items based on date
      const updatedOrderItems: Interfaces.PatchOrderItem[] = [];
      const received: Interfaces.Received = {
        received: data.checked,
        timestamp: moment().format('X'),
        changedUser: subject,
      };
      Object.values(studentOrders).forEach((dates: any) => {
        Object.values(dates).forEach((order: any) => {
          if (order.total && order.total > 1) {
            const idMatcher = Helpers.excludeIdFromOrderSortId(order.id);

            Object.keys(order).forEach((key) => {
              if (key.startsWith(idMatcher)) {
                updatedOrderItems.push({
                  districtId: order.districtId,
                  id: key,
                  received,
                });
              }
            });
          } else {
            updatedOrderItems.push({
              districtId: order.districtId,
              id: order.id,
              received,
            });
          }
        });
      });
      await patchOrderItems(updatedOrderItems);
    },
    [menuTypeId, ordersByStudent, subject, patchOrderItems],
  );

  const onExportCSVClick = async () => {
    try {
      let from = startDate;
      let to = startDate;

      if (viewByDateRange) {
        from = dayRange.startDate;
        to = dayRange.endDate;
      }
      const results = (await api.get(`/utility/reports/districts/${districtId}/students`, {
        params: {
          dateFrom: from.format(Constants.DATE_FORMAT_YMD),
          dateTo: to.format(Constants.DATE_FORMAT_YMD),
          reportMode: Interfaces.ReportMode.Csv,
          siteId,
          menuTypeId,
          pickupLocationId,
        },
      })) as any;
      Helpers.downloadAsCsv(results.data, 'pickup-location-report.csv');
    } catch (ex) {}
  };

  const studentData = React.useMemo(() => {
    const studentRows = Object.entries(students).reduce(
      (acc: Interfaces.PickupLocationTableRow[], [studentId, student]: any) => {
        const row: Partial<Interfaces.PickupLocationTableRow> = {};
        row.firstName = student.firstName;
        row.lastName = student.lastName;
        row.allergens = Helpers.formatAllergens(student.allergens);
        const grouped = getStudentProductsByTypeAndDay(menuTypeIds, studentId, ordersByStudent);
        let studentOrderItems: Interfaces.OrderItem[] = [];
        if (!viewByDateRange) {
          const orders = get(
            ordersByStudent,
            `${studentId}.${menuTypeId}.${startDate.format(Constants.DATE_FORMAT_YMD)}`,
            {},
          );
          studentOrderItems = Object.values(orders);
        } else {
          const studentOrders = get(ordersByStudent, `${studentId}.${menuTypeId}`, {});

          studentOrderItems = Object.entries(studentOrders).map(([date, order]: any) => order);
        }

        if (!studentOrderItems.length) {
          return acc;
        }
        let orderReceived = false;

        if (viewByDateRange) {
          orderReceived = studentOrderItems.every((order) => {
            return Object.values(order).every((o) => o[o?.id]?.received?.received);
          });
        } else {
          orderReceived = studentOrderItems.some(
            (order: Record<string, any>) => order[order?.id]?.received?.received as boolean,
          );
        }
        const studentName = `${student.firstName} ${student.lastName}`;

        row.receivedSort = orderReceived ? A_SORT : B_SORT;

        row.studentId = (
          <div className="item" student-id={student.id}>
            <div className="student-name-and-id">
              {student.profilePicture && (
                <Avatar className="student-img" src={student.profilePicture} alt={studentName} />
              )}
              {!student.profilePicture && <Avatar className="student-img" src="" alt={studentName} />}
              <span className="student-name">{studentName}</span> | {student.sisId}
              {Helpers.getGradeTextFromStudent(student)}
            </div>
            {viewByDateRange && (
              <div className="barcode-weekly-table">
                <Barcode value={student.sisId} options={{ height: 40 }} />
              </div>
            )}
          </div>
        );

        const aLaCarteProducts = Object.values(grouped[menuTypeId] ?? {}).map((orders: any) => {
          return orders
            .filter((order: any) => order.productCategoryId === Constants.ALACARTE_KEY)
            .map((order: any) => order.name);
        });

        if (!viewByDateRange && grouped[menuTypeId]) {
          const standardProducts = Object.values(grouped[menuTypeId]).map((orders: any) => {
            return orders
              .filter((order: any) => order.productCategoryId !== Constants.ALACARTE_KEY)
              .map((order: any) => order.name);
          });
          const orderMetadata = flatten(
            Object.values(grouped[menuTypeId]).map((products: any) =>
              products.map((order: any) => ({
                orderedAt: order.orderedAt,
                orderedBy: order.orderedBy,
                comments: order.comments,
              })),
            ),
          )[0];

          const orderedAt = moment(orderMetadata?.orderedAt);
          row.orderedAt = (
            <div className="item">
              {orderMetadata?.orderedAt ? orderedAt.format(Constants.DATE_FORMAT_DATE_AND_TIME) : ''}
            </div>
          );
          row.orderedAtTimestamp = orderedAt.valueOf();

          row.orderedBy = (
            <div className="item">{orderMetadata?.orderedBy?.name || orderMetadata?.orderedBy?.email}</div>
          );
          row.orderedByName = orderMetadata?.orderedBy?.name || orderMetadata?.orderedBy?.email || '';

          row.order = (
            <div className="item">
              {standardProducts.map((entry, key) => (
                <span key={key}>{(entry as string[]).join(' | ')}</span>
              ))}
            </div>
          );

          row.aLaCarte = (
            <div className="item">
              {aLaCarteProducts.map((entry, key) => (
                <span key={key}>{(entry as string[]).join(' | ')}</span>
              ))}
            </div>
          );

          row.comments = <div className="item">{orderMetadata?.comments}</div>;
        } else {
          menuTypeIds.forEach((id) => {
            row[id] = (
              <div className="item">
                {Object.values(grouped[id]).map((entry, key) => (
                  <div className="weekly-order" key={key}>
                    {(entry as { name: string; productCategoryId: string }[])
                      .filter((e) => e.productCategoryId !== Constants.ALACARTE_KEY)
                      .map((e) => e.name)
                      .join(' | ')}
                  </div>
                ))}
              </div>
            );
          });

          row.aLaCarteOrder = (
            <div className="item">
              {aLaCarteProducts.map((products, key) => (
                <div className="weekly-order" key={key}>
                  {products.join(' | ')}
                </div>
              ))}
            </div>
          );
        }

        row.status = (
          <div className="item pickup-location-report__received">
            <Checkbox
              key={`${startDate.format('x')}-${menuTypeId}-${studentId}`}
              id={`${startDate.format('x')}-${menuTypeId}-${studentId}`}
              onChange={(e: React.SyntheticEvent<HTMLInputElement>) =>
                handleReceivedChecked(e, { studentId, checked: !orderReceived })
              }
              checked={orderReceived}
              data-test-id={`${studentName}.receivedCheckbox`}
            />
          </div>
        );
        row.barcode = <Barcode value={student.sisId} options={{ height: 40 }} />;
        row.quantityOrdered = '';

        row.actions = (
          <IconButton
            aria-label={viewByDateRange ? 'Click to cancel meals' : 'Click to cancel meal'}
            onClick={() => {
              setOrdersToDelete({
                student,
                orders: studentOrderItems,
              });
            }}
            data-test-id={`${studentName}.cancelMealBtn`}
          >
            <DeleteIcon fontSize="large" />
          </IconButton>
        );

        row.type = RowType.Student;

        acc.push(row as Interfaces.PickupLocationTableRow);
        return acc;
      },
      [] as Interfaces.PickupLocationTableRow[],
    );

    return studentRows;
  }, [
    handleReceivedChecked,
    menuTypeId,
    menuTypeIds,
    ordersByStudent,
    setOrdersToDelete,
    startDate,
    students,
    viewByDateRange,
  ]);

  const bulkOrderData = React.useMemo(() => {
    const bulkOrderRows: Interfaces.PickupLocationTableRow[] = [];

    Object.entries(bulkOrdersByProduct).forEach(([productId, itemsByMenuTypeId]: [string, any]) => {
      Object.entries(itemsByMenuTypeId).forEach(([mtId, itemsByDate]: [string, any]) => {
        if (menuTypeId !== mtId) {
          return;
        }
        const row: Partial<Interfaces.PickupLocationTableRow> = {};
        const bulkOrderItems: Interfaces.OrderItem[] = Object.values(itemsByDate);
        const totalQuantity = bulkOrderItems.reduce((sum: number, orderItem: any) => orderItem.quantity + sum, 0);
        const bulkOrderItem: any = bulkOrderItems[0];
        const totalReceived = bulkOrderItems.reduce((sum, orderItem) => {
          return sum + (orderItem.received?.quantity || 0);
        }, 0);

        if (!bulkOrderItem) {
          return;
        }

        const orderedByNames: string = bulkOrderItems
          .map((orderItem: any) => orderItem.orderedBy?.name || '')
          .filter((name) => name)
          .join('\n');
        row.name = bulkOrderItem.name;
        row.studentId = <div className="item">Bulk Order</div>;
        row.type = RowType.BulkOrder;
        row.barcode = null;
        row.receivedSort = bulkOrderItem?.received?.received ? A_SORT : B_SORT;

        const onBulkOrderQuantityChange = async (value: number | string) => {
          const parsedValue = Number(value);
          const quantity = parsedValue > totalQuantity ? totalQuantity : parsedValue || 0;
          dispatch(
            changeBulkOrderQuantity({
              productId,
              menuTypeId,
              quantity,
              date: bulkOrderItem.date,
            }),
          );
          const received: Interfaces.Received = {
            received: quantity > 0,
            timestamp: moment().format('X'),
            changedUser: subject,
            quantity,
          };
          await patchOrderItems([
            { districtId: bulkOrderItem.districtId, id: bulkOrderItem.id, received, date: bulkOrderItem.date },
          ]);
        };
        row.status = viewByDateRange ? (
          <div className="item pickup-location-report__received">{totalReceived}</div>
        ) : (
          <div className="pickup-location-report__number-input">
            <IncrementalNumberInput
              max={totalQuantity}
              min={0}
              onAddOrSubstract={onBulkOrderQuantityChange}
              onBlur={(e, { value }) => onBulkOrderQuantityChange(value)}
              inputHeight={12}
              initialValue={totalReceived}
            />
          </div>
        );

        row.quantityOrdered = <div className="item">{totalQuantity}</div>;
        const orderedAt = moment(bulkOrderItem?.orderedAt);
        row.orderedAt = (
          <div className="item">
            {bulkOrderItem?.orderedAt ? orderedAt.format(Constants.DATE_FORMAT_DATE_AND_TIME) : ''}
          </div>
        );
        row.orderedAtTimestamp = orderedAt.valueOf();
        // Don't display ordered by if there are multiple sub-orders. Should be displayed in collapsed rows
        row.orderedBy = bulkOrderItem.subOrders?.length < 2 ? <div className="item">{orderedByNames}</div> : null;
        row.orderedByName = orderedByNames;
        row[viewByDateRange ? menuTypeId : 'order'] = <div className="item">{bulkOrderItem.name}</div>;
        row.aLaCarteOrder = '';
        row.actions = (
          <IconButton
            aria-label={viewByDateRange ? 'Click to cancel bulk orders' : 'Click to bulk order'}
            onClick={() => {
              setOrdersToDelete({
                student: null,
                orders: bulkOrderItems,
              });
            }}
            data-test-id={`${bulkOrderItem.name}.cancelBulkBtn`}
          >
            <DeleteIcon fontSize="large" />
          </IconButton>
        );

        // do not show additional detail rows when viewing by date range
        if (!viewByDateRange) {
          const aggregatedSubRows: Partial<Interfaces.PickupLocationTableRow>[] = [];
          bulkOrderItems.forEach((dateBulkOrder: Interfaces.OrderItem) => {
            const dateSubRows = dateBulkOrder.subOrders?.map((subOrder: Interfaces.OrderItem) => {
              const subRow: Partial<Interfaces.PickupLocationTableRow> = {};

              subRow.name = bulkOrderItem.name;
              subRow[viewByDateRange ? menuTypeId : 'order'] = <div className="item">{bulkOrderItem.name}</div>;
              subRow.studentId = <div className="item">Bulk Order</div>;
              subRow.type = RowType.BulkOrder;
              subRow.orderedBy = <div className="item">{subOrder.orderedBy?.name}</div>;
              subRow.orderedByName = subOrder.orderedBy?.name;
              subRow.quantityOrdered = <div className="item">{subOrder.quantity}</div>;
              subRow.comments = subOrder.comments;

              const subOrderAt = moment(subOrder?.orderedAt);
              subRow.orderedAtTimestamp = subOrderAt.valueOf();
              subRow.orderedAt = (
                <div className="item">
                  {subOrder?.orderedAt ? subOrderAt.format(Constants.DATE_FORMAT_DATE_AND_TIME) : ''}
                </div>
              );

              // blank cells
              subRow.status = '';
              subRow.actions = '';
              subRow.allergens = '';

              return subRow;
            });
            aggregatedSubRows.push(...(dateSubRows ?? []));
          });

          row.subRows = aggregatedSubRows;
        }

        bulkOrderRows.push(row as Interfaces.PickupLocationTableRow);
      });
    });
    return bulkOrderRows;
  }, [bulkOrdersByProduct, dispatch, menuTypeId, patchOrderItems, setOrdersToDelete, subject, viewByDateRange]);

  const data = React.useMemo(() => studentData.concat(bulkOrderData), [studentData, bulkOrderData]);

  const queryParams = React.useMemo(() => {
    return Helpers.createLabelReportQueryParams(
      menuTypeId,
      siteId,
      [pickupLocationId],
      dateFrom,
      dateTo,
      printLabelScopeRangeParameters.studentName,
      printLabelScopeRangeParameters.sortBy,
    );
  }, [menuTypeId, siteId, printLabelScopeRangeParameters, dateFrom, dateTo, pickupLocationId]);

  let reportColumns: PickupLocationColumn[] = [...commonColumns];
  if (menuTypes[menuTypeId]?.name !== Constants.UNASSOCIATED_MEAL_TYPE_NAME) {
    reportColumns = [
      ...reportColumns,
      {
        Header: menuTypes[menuTypeId]?.name || 'Meal',
        accessor: viewByDateRange ? menuTypeId : 'order',
        width: 400,
        disableSortBy: true,
        dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.orderLbl`,
      },
    ];
  }

  if (viewByDateRange) {
    reportColumns = [
      ...reportColumns,
      {
        Header: 'A la Carte',
        accessor: 'aLaCarteOrder',
        width: 400,
        disableSortBy: true,
        dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.aLaCarteLbl`,
      },
      {
        Header: 'Ordered',
        accessor: 'quantityOrdered',
        width: 100,
        disableSortBy: true,
        dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.quantityOrderedLbl`,
      },
      {
        Header: 'Received',
        accessor: 'receivedSort',
        Cell: (props: any) => {
          return props.row.original.status;
        },
        width: 200,
        sortType: sortReceivedColumn,
        dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.receivedChk`,
      },
      { ...actionColumnDef, dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.actions` },
    ];
  } else {
    reportColumns = [
      ...reportColumns,
      {
        Header: 'A la Carte',
        accessor: 'aLaCarte',
        width: 400,
        disableSortBy: true,
        dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.aLaCarteLbl`,
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        width: 280,
        disableSortBy: true,
        dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.comments`,
      },
      {
        Header: 'Submitted On',
        accessor: 'orderedAt',
        width: 280,
        sortType: sortSubmittedoOnColumn,
        dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.submittedOnLbl`,
      },
      {
        Header: 'Ordered By',
        accessor: 'orderedBy',
        width: 250,
        sortType: sortOrderedByColumn,
        dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.orderedByLbl`,
      },
      {
        Header: 'Ordered',
        accessor: 'quantityOrdered',
        width: 100,
        disableSortBy: true,
        dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.orderedLbl`,
      },
      {
        Header: 'Received',
        accessor: 'receivedSort',
        Cell: (props: any) => {
          return props.row.original.status;
        },
        width: 200,
        sortType: sortReceivedColumn,
        dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.receivedLbl`,
      },
      {
        Header: 'Barcode',
        accessor: 'barcode',
        width: 350,
        disableSortBy: true,
        className: 'barcode',
        dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.barcode`,
      },
      { ...actionColumnDef, dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.actions` },
    ];
  }

  return (
    <div className="pickup-location-report-table-container">
      <ReactTable
        initialState={initialState}
        columns={reportColumns}
        data={data}
        bottomLeftContainer={
          <div className="report-buttons">
            <ButtonLink
              href={`/districtprintreport?${queryParams}`}
              target="_blank"
              rel="noopener noreferrer"
              text="Print Report"
              data-test-id="printReportBtn"
            />
            <Button variant="text" color="primary" onClick={() => setPrintLabelsOptionsModalOpen(true)}>
              Print Labels
            </Button>

            <Button variant="text" color="primary" onClick={onExportCSVClick} data-test-id="locationExportCsvBtn">
              Location Export CSV
            </Button>
          </div>
        }
      />
      <PrintLabelsOptionsModal
        open={printLabelOptionsModalOpen}
        onClose={() => setPrintLabelsOptionsModalOpen(false)}
        onPrint={() => {
          window.open(`${window.origin}/districtprintlabels?${queryParams}`);
          setPrintLabelsOptionsModalOpen(false);
        }}
        scope={scope}
        viewByDateRange={viewByDateRange}
      />
    </div>
  );
};

export default PickupLocationReportTable;

const commonColumns: PickupLocationColumn[] = [
  {
    Header: 'Student',
    accessor: 'lastName',
    Cell: ({ row }) => {
      return row.canExpand && row?.subRows?.length > 1 ? (
        <span
          {...row.getToggleRowExpandedProps({
            style: {
              display: 'flex',
            },
          })}
        >
          {row.isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          {row.original.studentId}
        </span>
      ) : (
        <span style={{ paddingLeft: '1.5rem' }}>{row.original.studentId}</span>
      );
    },
    width: 450,
    sortType: sortStudentColumn,
    dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.lbl`,
  },
  {
    Header: 'Allergens',
    accessor: 'allergens',
    width: 150,
    disableSortBy: true,
    Cell: (props: any) => {
      return <div className="item">{props.row.original.allergens}</div>;
    },
    dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.allergens.lbl`,
  },
];

const initialState = {
  sortBy: [{ id: 'lastName' }],
};
