import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import SpecialMealAccommodations from 'Components/SpecialMealAccommodations/SpecialMealAccommodations';
import * as Constants from 'Constants/Constants';
import * as Interfaces from 'Interfaces/Interfaces';
import numeral from 'numeral';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import './NutritionalFactsPopup.less';

const NutritionalFactsPopup: FC<Interfaces.NutritionFactPopupProps> = ({ product, open, onClose }) => {
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });

  if (!product) {
    return <div />;
  }

  const { nutritionFacts } = product;

  return (
    <Dialog
      className="nutritional-click-popup nutrition-facts-popup linq-dialog"
      open={open}
      maxWidth="sm"
      id="nurtritional-popup-mobile"
    >
      <DialogTitle className="linq-dialog__header d-flex align-items-center" disableTypography={true}>
        <h3 className="linq-heading">{product.name}</h3>
        <CloseIconButton onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <div className="description">{product.description}</div>
        <div className="product-ingredients">Ingredients</div>
        <div className="description">{product.ingredients}</div>
        <SpecialMealAccommodations
          specialMealAccommodationIds={product.specialMealAccommodationIds}
          customSpecialMealAccommodations={product.customSpecialMealAccommodations}
        />
        <div className="nutrition-facts__additional-info">
          The nutrition information provided in this analysis should not be used for people with a medical condition or
          food allergy. Ingredients and menu items are subject to change or substitution without notice. In planning and
          treating allergies and medical conditions, please contact the district regarding their individual policy for
          accommodating special dietary needs.
        </div>
        {isMobile && product?.productFormulationPDFUrl && (
          <a
            href={product?.productFormulationPDFUrl}
            className="nutritional-link"
            rel="noopener noreferrer"
            target="_blank"
          >
            Product Formulation (PDF)
          </a>
        )}
        <TableContainer className="table-wrapper">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="no-border">
                  <div className="nutrients-header">Nutrients</div>
                </TableCell>
                <TableCell className="value no-border"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="no-border">Serving Size</TableCell>
                <TableCell className="value no-border">
                  {nutritionFacts.servingSize} {nutritionFacts.servingSizeUnitMeasure}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="label thick-border">
                  Calories<p>Amount Per Serving</p>
                </TableCell>
                <TableCell className="value thick-border calories bolded">
                  {numeral(nutritionFacts.calories).format(Constants.NUMBER)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="label thick-border">Total Fat</TableCell>
                <TableCell className="value thick-border bolded">
                  {numeral(nutritionFacts.totalFat).format(Constants.NUMBER_WITH_DECIMAL)}g
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="label secondary">Saturated Fat</TableCell>
                <TableCell className="value bolded">
                  {numeral(nutritionFacts.saturatedFat).format(Constants.NUMBER_WITH_DECIMAL)}g
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="label secondary">
                  <span className="trans-fat-label bolded">Trans</span> Fat
                </TableCell>
                <TableCell className="value bolded">
                  {numeral(nutritionFacts.transFat).format(Constants.NUMBER_WITH_DECIMAL)}g
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="label">Cholesterol</TableCell>
                <TableCell className="value bolded">
                  {numeral(nutritionFacts.cholesterol).format(Constants.NUMBER_WITH_DECIMAL)}mg
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="label">Sodium</TableCell>
                <TableCell className="value bolded">
                  {numeral(nutritionFacts.sodium).format(Constants.NUMBER_WITH_DECIMAL)}mg
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="label">Carbohydrates</TableCell>
                <TableCell className="value bolded">
                  {numeral(nutritionFacts.carbohydrates).format(Constants.NUMBER_WITH_DECIMAL)}g
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="label secondary">Dietary Fiber</TableCell>
                <TableCell className="value bolded">
                  {numeral(nutritionFacts.dietaryFiber).format(Constants.NUMBER_WITH_DECIMAL)}g
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="label secondary">Total Sugars</TableCell>
                <TableCell className="value bolded">
                  {numeral(nutritionFacts.sugar).format(Constants.NUMBER_WITH_DECIMAL)}g
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="label">Protein</TableCell>
                <TableCell className="value bolded">
                  {numeral(nutritionFacts.protein).format(Constants.NUMBER_WITH_DECIMAL)}g
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="label thick-border">Vitamin D</TableCell>
                <TableCell className="value thick-border bolded">
                  {numeral(nutritionFacts.vitaminA).format(Constants.NUMBER_WITH_DECIMAL)}mcg
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="label">Calcium</TableCell>
                <TableCell className="value bolded">
                  {numeral(nutritionFacts.calcium).format(Constants.NUMBER_WITH_DECIMAL)}mg
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="label">Iron</TableCell>
                <TableCell className="value bolded">
                  {numeral(nutritionFacts.iron).format(Constants.NUMBER_WITH_DECIMAL)}mg
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="label thick-border-bottom">Potassium</TableCell>
                <TableCell className="value bolded thick-border-bottom">
                  {numeral(nutritionFacts.vitaminC).format(Constants.NUMBER_WITH_DECIMAL)}mg
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {product?.productFormulationPDFUrl && (
          <a
            href={product?.productFormulationPDFUrl}
            className="nutritional-link"
            rel="noopener noreferrer"
            target="_blank"
          >
            Product Formulation (PDF)
          </a>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default NutritionalFactsPopup;
