import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from 'Api/useApi';
import { CollectionResponse, Newsfeed } from 'Interfaces/Interfaces';
import { AxiosResponse } from 'axios';
import { OutputFormat } from 'Constants/Constants';

interface NewsfeedState {
  data: Newsfeed[];
  error: any;
  loading: boolean;
}

export const useNewsfeeds = (districtId: string) => {
  const api = useApi();
  const dispatch = useDispatch();
  const [state, setState] = useState<NewsfeedState>({
    data: [],
    error: null,
    loading: false,
  });

  useEffect(() => {
    async function getNewsfeeds() {
      let newsfeeds: Newsfeed[] = [];
      let next: string | undefined;
      try {
        setState((state) => ({ ...state, loading: true }));
        do {
          const response: AxiosResponse<CollectionResponse<Newsfeed>> = await api.get(
            `/districts/${districtId}/newsfeeds`,
            {
              params: {
                next,
                limit: 100,
                outputFormat: OutputFormat.Array,
              },
            },
          );
          newsfeeds = newsfeeds.concat(response.data.items);
          next = response.data.next;
        } while (next);
        setState((state) => ({ ...state, data: newsfeeds, error: null }));
      } catch (err) {
        setState((state) => ({ ...state, error: err }));
      } finally {
        setState((state) => ({ ...state, loading: false }));
      }
    }

    if (districtId) {
      getNewsfeeds();
    }
  }, [api, dispatch, districtId]);

  return state;
};
