import React, { FC } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';
import { PreviewRow } from './PreviewRow';

import './DragAndDropReactTable.less';

const getRowPreviewStyle = (currentOffset: XYCoord | null) => {
  //Offest the x and y value so that the preview appears down and to the right of the cursor
  const x = currentOffset?.x ? currentOffset.x + 45 : null;
  const y = currentOffset?.y ? currentOffset.y + 40 : null;
  const transform = `translate(${x}px, ${y}px)`;

  return { transform, WebKitTransform: transform };
};

export interface DragAndDropReactTableDragLayerProps {
  acceptedType: string;
}

export const DragLayer: FC<DragAndDropReactTableDragLayerProps> = ({ acceptedType }) => {
  const { item, itemType, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
  }));

  if (!item || itemType !== acceptedType) {
    return null;
  }

  return (
    <div className={'table-drag-layer'}>
      <div style={getRowPreviewStyle(currentOffset)}>
        <PreviewRow row={item.row} />
      </div>
    </div>
  );
};
