import { KeyboardDatePicker as KeyboardDatePickerCore } from '@material-ui/pickers';
import classNames from 'classnames';
import * as Interfaces from 'Interfaces/Interfaces';
import React from 'react';
import '../MaterialForm.less';

export const KeyboardDatePicker = (props: Interfaces.KeyboardDatePickerFieldProps) => {
  const { errorMsg, showOnlyTouchedError, touched, ...rest } = props;

  const isErrorHidden = (errorMsg && showOnlyTouchedError && !touched) || !errorMsg;

  return (
    <div>
      <KeyboardDatePickerCore
        {...rest}
        InputProps={{ error: !isErrorHidden }}
        inputVariant="outlined"
        InputLabelProps={{
          shrink: Boolean(props.value),
          className: classNames({ 'keyboard-date-picker__error-text': !isErrorHidden }),
        }}
        helperText={!isErrorHidden && errorMsg}
        error={!isErrorHidden && !!errorMsg}
        inputProps={{ 'data-test-id': `${props.label}.dateInput` }}
      />
    </div>
  );
};
