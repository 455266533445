import React from 'react';
import { LinkProps } from '@material-ui/core/Link';
import { Role, isAuthorized } from 'Auth/User';
import { District } from 'Interfaces/Interfaces';
import { ListItemLink } from '../ListItemLink/ListItemLink';

export interface AuthorizedLinkProps extends LinkProps {
  allowedRoles: Role[];
  role: Role;
  district?: District;
  primary: string;
  to: string;
}

const AuthorizedLink: React.FC<AuthorizedLinkProps> = ({ allowedRoles, role, district, ...props }) => {
  if (!isAuthorized(role, allowedRoles) || district?.synthesisIntegration === false) {
    return null;
  }
  return <ListItemLink {...props} />;
};

export default AuthorizedLink;
