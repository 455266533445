import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { TokenClaims } from 'Auth/User';
import { useAuth0 } from '@auth0/auth0-react';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'Components/Drawer';
import { ComponentLoaderNoRedux } from 'Components/Loaders/Loaders';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import Button from 'Components/Button/Button';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';
import DragAndDropReactTable from 'Components/ReactTable/DragAndDropReactTable/DragAndDropReactTable';
import * as MenuTypePickerSelectors from 'Components/MenuTypePicker/state/selectors';
import { useMenuTypePickerActions } from 'Components/MenuTypePicker/state/actions';
import { DRAG_ITEM_TYPES } from 'Constants/Constants';
import { SidebarLocations } from 'Constants/Enums';
import { MenuType, OrderingRules } from 'Interfaces/Interfaces';
import { useCommonActions } from 'State/actions';
import SitePicker from 'Components/SitePicker/SitePicker';
import { getSiteId } from 'Components/SitePicker/state/selectors';
import { sortMenuTypes } from 'Helpers/Helper';

import './MenuOrderRulesSidebar.less';

export interface OrderCommentsSidebarProps {
  open: boolean;
  orderingRules: OrderingRules;
  setSidebarOpen: (open: boolean) => void;
}

const MenuOrderRuleSidebar: FC<OrderCommentsSidebarProps> = ({ open = false, orderingRules, setSidebarOpen }) => {
  const { updateDistrict } = useCommonActions();
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);
  const siteId = useSelector(getSiteId);
  const menuTypes = useSelector(MenuTypePickerSelectors.getMenuTypes);
  const { getMenuTypes } = useMenuTypePickerActions();
  const [siteMenuOrderState, setSiteMenuOrderState] = React.useState<Record<string, string[]>>({});
  const [menuTypeLoading, setMenuTypeLoading] = React.useState(false);
  const tableDataRef = React.useRef<MenuType[] | null>(null);

  React.useEffect(() => {
    const fetchMenuTypes = async () => {
      if (districtId && siteId) {
        try {
          setMenuTypeLoading(true);
          await getMenuTypes(districtId, siteId);
        } catch (error) {
        } finally {
          setMenuTypeLoading(false);
        }
      }
    };

    fetchMenuTypes();
  }, [siteId, districtId, getMenuTypes]);

  const handleSiteChange = (currentSiteId: string, prevSiteId: string | null) => {
    // push current menu order into component state so that order changes aren't lost on site change
    const orderedMenuTypeIds = tableDataRef.current?.map((menuType: any) => menuType.id);
    const newSiteMenuOrder = { ...siteMenuOrderState };

    if (prevSiteId) {
      newSiteMenuOrder[prevSiteId] = orderedMenuTypeIds ?? [];
      setSiteMenuOrderState(newSiteMenuOrder);
    }
  };

  const handleSubmit = async () => {
    const siteMenuDisplayOrder = siteMenuOrderState;
    if (siteId && tableDataRef.current) {
      siteMenuDisplayOrder[siteId] = tableDataRef.current.map((menuType: MenuType) => menuType.id);
    }

    const updatedDistrict = {
      orderingRules: {
        ...orderingRules,
        siteMenuDisplayOrder,
      },
    };

    try {
      setLoading(true);
      await updateDistrict(updatedDistrict);
    } catch (error) {
    } finally {
      setLoading(false);
      setSidebarOpen(false);
    }
  };

  const handleCloseClick = () => {
    // clear state and close sidebar
    setSiteMenuOrderState({});
    setSidebarOpen(false);
  };

  const menuTypesData = React.useMemo(() => {
    if (siteId) {
      let sortKey = undefined;

      if (siteMenuOrderState[siteId]) {
        sortKey = siteMenuOrderState[siteId];
      } else if (orderingRules?.siteMenuDisplayOrder) {
        sortKey = orderingRules.siteMenuDisplayOrder[siteId];
      }

      return sortMenuTypes(menuTypes, sortKey);
    }

    return [];
  }, [menuTypes, orderingRules?.siteMenuDisplayOrder, siteId, siteMenuOrderState]);

  return (
    <Drawer
      open={open}
      id="online-ordering-card-labels"
      anchor="right"
      className="ordering-rules-sidebar-container menu-order-sidebar"
    >
      <DrawerHeader>
        <h2>Edit Menu Display Order</h2>
        <CloseIconButton onClick={handleCloseClick} />
      </DrawerHeader>
      <NotificationBanner location={SidebarLocations.MENU_ORDER_RULE_SIDE_BAR} />
      <DrawerContent>
        <section>
          <SitePicker onChange={handleSiteChange} defaultToFirst />
          <ComponentLoaderNoRedux loading={menuTypeLoading} />
          {!menuTypeLoading && (
            <DragAndDropReactTable
              className="menu-order-table"
              dataRef={tableDataRef}
              data={menuTypesData}
              columns={[{ accessor: 'name', width: 350 }]}
              dragItemType={DRAG_ITEM_TYPES.MENU_TYPE_ROW}
            />
          )}
        </section>
      </DrawerContent>
      <DrawerFooter>
        <Button
          type="button"
          color="primary"
          variant="outlined"
          disabled={loading}
          onClick={handleCloseClick}
          data-test-id="cancelBtn"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          aria-label="Submit"
          onClick={handleSubmit}
          disabled={loading}
          loading={loading}
          data-test-id="submitBtn"
        >
          Save
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default MenuOrderRuleSidebar;
