import { ChangeEvent } from 'react';
import { RouteProps as RouteCoreProps } from 'react-router-dom';
import { TextFieldProps as TextFieldPropsCore } from '@material-ui/core';
import { DatePickerView, KeyboardDatePickerProps } from '@material-ui/pickers';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { DateRangeErrorType, FilterFieldType, LogStatus, SIS_TYPE } from 'Constants/Constants';
import { RowType } from 'Components/District/PickupLocationReportTable/PickupLocationReportTable';
import { Row, TableRowProps } from 'react-table';
import moment from 'moment-timezone';
import { Admin, Role } from 'Auth/User';
import { REPORT_SCOPE } from 'Constants/Enums';

// root level types
type IdAndName = { id: string; name: string };

// state
export interface AppState {
  studentState: StudentState;
  cartState: CartRootState;
  commonState: CommonState;
  mealTypePickerState: MenuTypePickerState;
  menuItemModal: MenuItemModal;
  shopState: ShopState;
  dateRangeNavigatorState: DateRangeNavigatorState;
  districtReportsState: DistrictReportState;
  adminState: AdminState;
  parentState: ParentState;
  sitePickerState: SitePickerState;
}

export interface AdminState {
  showSidebar: boolean;
}

export interface AddStudentModalState {
  open: boolean;
  error: '';
}

export interface ALaCarteState {
  products?: { [key: string]: Product };
}

export interface SwitchStudentCartModalState {
  newStudentId: string;
  open: boolean;
}

export interface UnlinkStudentModalState {
  open: boolean;
  studentId: string | null;
}

export interface StaleCartItemState {
  open: boolean;
}

export interface StudentState {
  switchStudentWarningVisible: boolean;
}

export interface CartState {
  menuItems: { [key: string]: CartItem };
  childMenuItems: { [key: string]: CartItem[] };
  purchasedMenuItems: { [key: string]: CartItem };
  purchasedChildMenuItems: { [key: string]: CartItem[] };
  itemPickupLocations: { [key: string]: string };
  pickupLocationOptionsByItem: { [key: string]: PickupLocation[] };
  purchasedPickupLocations: { [key: string]: string };
  purchasedOrderId: string;
  showCart: boolean;
  showPickup: boolean;
  error: string;
}

export interface CartRootState {
  accountId: string | null;
  accounts: {
    [key: string]: CartState;
  };
}

export interface CommonState {
  pickupLocations: { [key: string]: PickupLocation };
  homerooms: { [key: string]: Homeroom };
  menuTypes: { [key: string]: MenuType };
  menuItems: { [key: string]: MenuItem };
  products: { [key: string]: Product };
  productCategories: { [key: string]: ProductCategory };
  reimbursableMealTypes: { [key: string]: ReimbursableMealType };
  siteGroupTypes: { [key: string]: SiteGroupType };
  specialMealAccommodations: { [key: string]: SpecialMealAccommodation };
  students: { [key: string]: Student };
  studentsTotalCount: number;
  studentsLastUpdatedAt: string;
  orderItems: { [key: string]: OrderItem };
  districts: { [key: string]: District };
  sites: { [key: string]: Site };
  openSidebars: number[];
}

export interface ParentState {
  studentDistricts: { [key: string]: District };
  studentSites: { [key: string]: Site };
}

export interface DisclaimerModal {
  open: boolean;
  showCloseButton: boolean;
}

export interface DistrictReportState {
  pickupLocationId: string;
  siteId: string;
  report: any;
  reportStudents: any;
  reportMenuTypeId: string;
  printLabelParameters: Record<string, { range: PrintLabelPrameters; day: PrintLabelPrameters }>;
}

export interface PrintLabelPrameters {
  sortBy?: string[];
  studentName?: string;
}

export type ProductTotals = { [productId: string]: number };
export type MealTypeTotal = { [mealTypeId: string]: ProductTotals };

export interface Report {
  globalPickupLocationIds: string[];
  pickupLocationIds: string[];
  pickupLocations: { [pickupLocationId: string]: PickupLocation };
  productIds: string[];
  products: { [productId: string]: Product };
  siteIds: string[];
  sites: { [siteId: string]: Site };
  totals: ProductTotals;
  totalsByMealType: MealTypeTotal;
  totalsByPickupLocation: { [pickupLocationId: string]: MealTypeTotal };
  totalsBySite: { [sideId: string]: MealTypeTotal };
}

export interface DistrictState {
  reimbursableMealPrices: { [key: string]: ReimbursableMealPrice };
  reimbursableMealPricesByGrade: { [key: string]: ReimbursableMealPriceByGrade };
  reimbursableMealPricingModes: { [key: string]: ReimbursableMealPricingMode };
  logs?: { [key: string]: Log };
}

export interface FormState {
  dirty: { [key: string]: boolean };
}

export interface LoadersState {
  pageLoaderActive: boolean;
  modalOpen: boolean;
  componentLoaderActive: boolean;
}

export interface MenuTypePickerState {
  menuTypeId: string | null;
  menuTypes: Record<string, MenuType>;
}

export interface SitePickerState {
  siteId: string | null;
}

export interface HeaderState {
  menuVisible: boolean;
}

export interface ShopError {
  message: string;
  errorCode: string;
}

export interface ShopState {
  studentId: string | null;
  cutoffWarningVisible: boolean;
  aLaCarteWarningVisible: boolean;
  reimbursableWarningVisible: boolean;
  errors: ShopError[] | null;
  siteId: string | null;
  districtId: string | null;
}

export interface StarRatingState {
  starRatings: { [key: string]: StarRating };
}

export interface TeacherReportState {
  report: any;
  entreeSidebarOpen: boolean;
  entree: MenuItem | null;
  selectedSides: Record<string, MenuItem[]>;
  showCart: boolean;
  // Account Ids mapped to Cart
  cart: { [key: string]: TeacherOrderingCart };
  bulkCart: { [key: string]: TeacherBulkOrderingCart };
  // Product Id mapped to Product
  products: { [key: string]: Product };
  isBulkMode: boolean;
  cartHomerooms: { [key: string]: string };
}

export interface DayRange {
  startDate: moment.Moment;
  endDate: moment.Moment;
  dates: moment.Moment[];
}

export interface DateRangeNavigatorState {
  weekOfYear: number;
  dayOfYear: moment.Moment;
  startDate: moment.Moment;
  dayRange: DayRange;
}

export interface MenuItemModal {
  menuItemId: string;
  productId: string;
  menuTypeId: string;
  showMenuItemModal: boolean;
  date: string;
  pickupLocationId: string;
  selectedFoodGroupOptions: FoodGroupOptions;
  orderItems: OrderItem[];
  isExistingOrder: boolean;
}

export interface CancelOrderModalState {
  open: boolean;
  orderItems: OrderItem[];
}

// action

export interface Action {
  type: string;
  payload: any;
  accountId?: string;
}

// props

export interface AcceptedTerms {
  parentId: string;
  timestamp: string;
}

export interface Received {
  received: boolean;
  changedUser: string;
  timestamp: string;
  quantity?: number;
}

export interface BreadcrumbProps {
  siteId: string;
  pickupLocationId: string;
  report: any;
}

export interface CalendarDayProps {
  date: moment.Moment;
  selector: string;
  productCategoryId: string;
}

export interface CalendarWeekProps {
  loading: boolean;
}

export interface FoodGroupSelectorProps {
  productCategoryId: string;
  options: Array<MenuItem>;
  allowNoneOption: boolean;
  ordered?: boolean;
  foodGroupName: string;
  onChange: (productCategoryId: string, menuItem: MenuItem | null) => void;
  products: Record<string, Product>;
  selectedOptions: Record<string, MenuItem[]>;
}

export type TextFieldProps = TextFieldPropsCore & {
  id: string;
  errorMsg?: string;
  isCurrency?: boolean;
  hideError?: boolean;
  hideErrorMsg?: boolean;
  hideEmptyError?: boolean;
  readOnly?: boolean;
  showOnlyTouchedError?: boolean;
  touched?: boolean;
  mode?: string;
  numericSize?: Array<number>;
  max?: number;
  min?: number;
  step?: number;
  allowNegative?: boolean;
  onQuickClear?: () => void;
};

export interface MaterialInputFieldProps {
  autoComplete?: string;
  autoFocus?: boolean;
  defaultValue?: any;
  disabled?: boolean;
  error?: boolean;
  multiline?: boolean;
  id: string;
  name: string;
  value: any;
  errorMsg?: string;
  touched?: boolean;
  label?: string;
  fieldLabel?: any;
  required?: boolean;
  type?: string;
  onBlur?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  allowNegative?: boolean;
  compact?: boolean;
  hideError?: boolean;
  hideEmptyError?: boolean;
  isCurrency?: boolean;
  mode?: string;
  numericSize?: Array<number>;
  readOnly?: boolean;
  showOnlyTouchedError?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  size?: 'medium' | 'small';
  color?: 'primary' | 'secondary';
  margin?: 'dense' | 'none' | 'normal';
  rows?: number | string;
  rowsMax?: number | string;
  helperText?: any;
  inputProps?: any;
  valueIsDuplicate?: boolean;
}

export interface MaterialSelectFieldProps {
  autoWidth?: boolean;
  defaultValue?: any;
  displayEmpty?: boolean;
  id: string;
  multiple?: boolean;
  native?: boolean;
  open?: boolean;
  value: any;
  options?: MaterialSelectOption[];
  errorMsg?: string;
  touched?: boolean;
  label?: string;
  labelWidth?: number;
  required?: boolean;
  type?: string;
  fieldLabel?: any;
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange?: ((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void) | undefined;
  allowNegative?: boolean;
  compact?: boolean;
  loading?: boolean;
  hideError?: boolean;
  hideEmptyError?: boolean;
  isCurrency?: boolean;
  mode?: string;
  numericSize?: Array<number>;
  readOnly?: boolean;
  showOnlyTouchedError?: boolean;
  placeholder?: string;
  name: string;
  fullWidth?: boolean;
  error?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  rowsMax?: number;
  margin?: 'dense' | 'none';
  color?: 'primary' | 'secondary';
  inputLabelProps?: { className?: string };
  children?: React.ReactNode;
  className?: string;
}

export interface MaterialSelectOption {
  value: string;
  text: string;
  group?: string;
}

export interface SwitchFieldProps {
  id: string;
  value: any;
  errorMsg?: string;
  fieldLabel?: any;
  onBlur?: (event: React.FocusEvent<HTMLButtonElement | HTMLTextAreaElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  readOnly?: boolean;
  name: string;
  error?: boolean;
  disabled?: boolean;
  color?: 'primary' | 'secondary';
  className?: string;
}

export interface FormMeta {
  touched?: boolean;
  error?: string;
}

export interface NutritionFactProps {
  product?: Product;
}

export interface NutritionFactPopupProps extends NutritionFactProps {
  open: boolean;
  onClose: () => void;
}

export interface PickupLocationProps {
  student: Student;
  orderItems: OrderItem[];
  pickupLocation: PickupLocation;
  menuTypeId: string | null;
  menuTypeName?: string;
  showStudentId?: boolean;
  studentNameDisplayOrder: string;
  date?: string;
}

export interface PickupLocationModalProps {
  id: string;
  open: boolean;
  setModalOpen: any;
}

export interface ProductTotalProps {
  product: any;
  productId?: string;
  total: number;
}

export interface DistrictReportTableProps {
  report: any;
  viewByDateRange: boolean;
}

export interface SiteReportTableProps {
  report: any;
  scope: REPORT_SCOPE;
  viewByDateRange: boolean;
}

export interface PickupLocationReportTableProps {
  report: any;
  scope: REPORT_SCOPE;
  viewByDateRange: boolean;
  setOrdersToDelete: (arg: { student: Student | null | undefined; orders: OrderItem[] }) => void;
}

export interface ReactTableProps {
  columns: Array<any>;
  data: any;
  bottomLeftContainer?: any;
  autoResetPage?: boolean;
  autoResetSortBy?: boolean;
  showFooter?: boolean;
  initialState?: any;
  fetchData?: any;
  manualPageCount?: number;
  noDataText?: string;
  unitLabel?: string;
  manualPageSize?: number;
  className?: string;
  disableSortRemove?: boolean;
  loading?: boolean;
  stickyRightColumn?: boolean;
  onFilterChange?: (previousTableFilterState: TableFilterState, tableFilterState: TableFilterState) => void;
  getTrProps?: (tableRowProps: TableRowProps, original: object) => TableRowProps;
  manualSearch?: boolean;
  totalRows?: number;
  disableSearch?: boolean;
  disableFilter?: boolean;
}

export interface DragAndDropReactTableProps extends ReactTableProps {
  onOrderChange?: (orderedData: any[]) => void;
  dataRef: React.MutableRefObject<any[] | null>;
  dragItemType: string;
}

export interface ReimbursableMealPricingItemProps {
  index: number;
  reimbursableMealType: ReimbursableMealType;
  onEdit: () => void;
}

export interface SpecialMealAccommodationsProps {
  specialMealAccommodationIds?: Array<string>;
  customSpecialMealAccommodations?: Array<string>;
  showSpecialIconsOnly?: boolean;
  hideLabel?: boolean;
}

export interface StarRatingProps {
  productId: string | null;
}

export interface StudentCardProps {
  student: Student;
  district: District;
  site: Site;
}

export interface BarcodeProps {
  value: string;
  options?: object;
}

export interface OrderedMealProps {
  date: string;
  productId: string;
  mealTypeId: string;
  productName?: string;
  menuItemId?: string;
  pickupLocationId: string;
  selectedFoodGroupOptions: FoodGroupOptions;
  orderItems: OrderItem[];
  studentId: string;
}

// forms

export interface FoodGroupOptions {
  [key: string]: MenuItem[] | null;
}

// components
export interface CartItem {
  count: number;
  productId: string;
  mealTypeId: string;
  date: string;
  id: string;
  productName: string;
  productCategoryId: string;
  price: number;
  siteId: string;
  comments?: string;
}

export interface Homeroom {
  id: string;
  name: string;
  siteId: string;
}

export interface DayRange {
  startDate: moment.Moment;
  endDate: moment.Moment;
  dates: Array<moment.Moment>;
}

export enum PricingMode {
  PRICING_BY_SITE = 'PRICING_BY_SITE',
  PRICING_BY_GRADE = 'PRICING_BY_GRADE',
}

export interface ReimbursableMealPricingMode {
  id?: string;
  reimbursableMealTypeId: string;
  mealTypeId?: string;
  pricingMode: PricingMode;
}

export interface ReimbursableMealPrice {
  id?: string;
  reimbursableMealTypeId: string;
  mealTypeId?: string;
  siteGroupTypeId: string;
  price?: number;
}

export interface ReimbursableMealPriceByGrade {
  id?: string;
  reimbursableMealTypeId: string;
  mealTypeId?: string;
  grade: string;
  groupId?: string;
  price?: number;
}

export interface ReimbursableMealPriceByGradeGroup {
  reimbursableMealTypeId?: string;
  mealTypeId?: string;
  grades?: string[];
  price?: number;
  groupId?: string;
}

export interface MenuType {
  id: string;
  name: string;
}

export interface PickupLocation {
  id: string;
  name: string;
  siteId?: string;
  default?: boolean;
  scope?: string;
}

export interface MenuItem {
  id: string;
  mealTypeId: string;
  productId: string;
  siteGroupTypeId?: string;
  siteGroupTypeIds?: string[];
  siteIds?: string;
  product: Product;
  date: string;
  sortPriority: number;
}

export interface PublishedMenu {
  menuTypeId: string;
  name: string;
  siteId: string;
  publishedMonth: string;
}

export interface SchoolMenu {
  siteId: string;
  siteName: string;
  name: string;
  menuTypeId: string;
  siteGroupTypeId: string;
  publishedMonth: string;
}

export interface Log {
  id: string;
  districtId: string;
  siteId: string;
  user: string;
  date: string;
  importCount: number;
  recordDelta: number;
  recordCount: number;
  success: boolean;
  filename: string;
}

export interface OrderingRules {
  aLaCarteMinimum?: string;
  reimbursableMinimum?: string;
  cutoffDay?: string;
  cutoffTime?: string;
  maxCutoffTime?: string;
  labelsAllergensDisplayType?: LabelAllergenOptions;
  labelStudentIdDisplayType?: LabelStudentIdDisplayOptions;
  orderRange?: string;
  orderDueBy?: string;
  orderDueByMonthly?: string;
  maxOrderRange?: string;
  maxCutoffDays?: string;
  maxCutoffWeeks?: string;
  maxCutoffMonths?: string;
  pickupSelectionType?: string;
  blackoutDates?: string[];
  offerOnlyAlaCarte?: boolean;
  aLaCarteStartDate?: string;
  aLaCarteEndDate?: string;
  teacherOrderRange?: string;
  teacherOrderDueBy?: string;
  teacherOrderDueByMonthly?: string;
  teacherCutoffDay?: string;
  teacherCutoffTime?: string;
  teacherMaxOrderRange?: string;
  teacherMaxCutoffDays?: string;
  teacherMaxCutoffWeeks?: string;
  teacherMaxCutoffMonths?: string;
  teacherMaxCutoffTime?: string;
  allowOrderComments?: boolean;
  siteMenuDisplayOrder?: Record<string, string[]>;
  bulkOrderModeEnabled?: boolean;
  offerOnlyALaCarteNoPublishedMenus?: boolean;
}

export enum LabelAllergenOptions {
  None = 'None',
  PlainText = 'PlainText',
  Circles = 'Circles',
}

export enum LabelStudentIdDisplayOptions {
  Show = 'Show',
  Hide = 'Hide',
}

export interface Product {
  id: string;
  name: string;
  description: string;
  foodGroupId: string;
  productCategoryId: string;
  specialMealAccommodationIds: Array<string>;
  customSpecialMealAccommodations: Array<string>;
  nutritionFacts: NutritionFact;
  ingredients: string;
  imageUrl: string;
  productFormulationPDFUrl: string;
  prices?: any;
  specialMealNotes?: string;
  mealTypeIds?: string[];
  siteGroupTypeIds?: SiteGroupTypes[];
  updatedAt?: Date;
  offeredWeekdays?: string[];
}

export interface ProductPrice {
  price: number;
  siteGroupTypeId: string;
}

export interface NutritionFact {
  servingSize: string;
  servingSizeUnitMeasure: string;
  gramPerServing: number;
  calories: number;
  totalFat: number;
  saturatedFat: number;
  transFat: number;
  cholesterol: number;
  sodium: number;
  carbohydrates: number;
  dietaryFiber: number;
  protein: number;
  iron: number;
  calcium: number;
  sugar: number;
  vitaminA: number;
  vitaminAIU: number;
  vitaminC: number;
  vitaminD: number;
  potassium: number;
}

export interface ProductCategory extends IdAndName {}

export interface ReimbursableMealType extends IdAndName {}

export interface Site {
  id: string;
  name: string;
  siteGroupTypeId: string;
  cep: boolean;
  disableDefaultPickupLocations?: boolean;
}

export interface SiteGroupType extends IdAndName {}

export enum SITE_GROUP_TYPE_IDS {
  EARLY_COLLEGE = 'EARLY_COLLEGE',
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  JUNIOR_HIGH = 'JUNIOR_HIGH',
  MIDDLE_SCHOOL = 'MIDDLE_SCHOOL',
  INTERMEDIATE_SCHOOL = 'INTERMEDIATE_SCHOOL',
  ELEMENTARY_SCHOOL = 'ELEMENTARY_SCHOOL',
  PRESCHOOL = 'PRESCHOOL',
  CHARTER = 'CHARTER',
  ACADEMY = 'ACADEMY',
  K12 = 'K12',
  K8 = 'K8',
}

type SITE_GROUP_TYPE_IDS_KEYS = keyof typeof SITE_GROUP_TYPE_IDS;
export type SiteGroupFields = {
  [key in SITE_GROUP_TYPE_IDS_KEYS]: { id: string; name: string };
};

// Required for enum string as key in interface
export interface SiteGroupTypes extends SiteGroupFields {}

export interface SpecialMealAccommodation {
  id: string;
  name: string;
  icon: string;
}

export interface StarRating {
  id: string;
  productId: string | null;
  rating: number;
}

export interface Student {
  id: string;
  districtId: string;
  sisId: string;
  firstName: string;
  lastName: string;
  siteName: string;
  grade: string;
  homeroom: string;
  homeroomId: string;
  status: string;
  balance?: number;
  profilePicture: string;
  siteId: string;
  reimbursableMealTypeId: string;
  siteGroup: string;
  birthDate: string;
  siteGroupTypeId: string;
  acceptedTerms: AcceptedTerms[];
  allergens: string;
  suspended?: boolean;
  updatedAt: string;
}

export interface CreateStudent {
  sisId: string;
  lastName: string;
}

export interface OrderItem {
  id: string;
  districtId: string;
  studentId?: string;
  mealTypeId: string;
  mealTypeName: string;
  productId: string;
  date: string;
  pickupLocationId: string;
  price: number;
  homeroomId: string;
  siteId: string;
  menuKey?: string;
  received?: Received;
  productCategoryId?: string;
  canceled?: boolean;
  total?: number;
  orderId?: string;
  quantity?: number;
  productName?: string;
  pickupLocationName?: string;
  orderedAt?: number;
  orderedBy?: OrderedBy;
  canceledBy?: CanceledBy;
  canceledAt?: number;
  comments?: string;
  subOrders?: OrderItem[];
}

export interface PatchOrderItem {
  id: string;
  districtId: string;
  received?: Received;
  canceled?: boolean;
  date?: string;
  quantity?: number;
}

export interface District {
  id: string;
  name: string;
  paymentProviderLink: string;
  orderingRules: OrderingRules;
  synthesisConfig: SynthesisConfig;
  synthesisIntegration: boolean;
  timezone: string;
  inactive?: boolean;
  studentCount: number;
  siteBreakdowns?: Record<string, SiteBreakdown>;
  grades?: string[];
}

export interface SiteBreakdown {
  free: number;
  reduced: number;
  paid: number;
  adult?: number;
  // account for typo, temporary until data has self corrected
  adults: number;
}

export interface SynthesisConfig {
  synthesisDistrictId: string;
  synthesisSisType: SIS_TYPE;
  synthesisSisBaseURL: string;
  synthesisSisClientId: string;
  synthesisSisClientSecret: string;
}

// Routing
export interface RouteProps extends RouteCoreProps {
  adminSidebar?: boolean;
  isPrintable?: boolean;
  hideParentFooter?: boolean;
  children?: React.ReactNode;
}

export interface PricingGroup {
  reimbursableMealType: ReimbursableMealType;
}

export enum ReportMode {
  Display = 'Display',
  Print = 'Print',
  Label = 'Label',
  Csv = 'Csv',
}

export enum Env {
  Local = 'local',
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export interface MenuType extends IdAndName {}

export interface PickupLocationColumn {
  Header: string;
  accessor: string;
  Cell?: (props: any) => any;
  width: number;
  disableSortBy?: boolean;
  sortType?: (
    rowA: Row<PickupLocationTableRow>,
    rowB: Row<PickupLocationTableRow>,
    columnId: string,
    desc: boolean,
  ) => number;
  disableSortRemove?: boolean;
  dataTestId?: (row: any) => string;
  className?: string;
}

export interface ErrorMessageProps {
  errorMsg?: string | null;
  isErrorHidden?: boolean;
}

export declare type KeyboardDatePickerFieldProps = ErrorMessageProps &
  KeyboardDatePickerProps & {
    touched?: boolean;
    showOnlyTouchedError?: boolean;
  };

export interface Api {
  get: <T, R = AxiosResponse<T>>(path: string, config?: AxiosRequestConfig | undefined) => Promise<R>;
  delete: <T, R = AxiosResponse<T>>(path: string) => Promise<R>;
  patch: <T, B, R = AxiosResponse<T>>(
    path: string,
    data?: B | undefined,
    config?: AxiosRequestConfig | undefined,
  ) => Promise<R>;
  post: <T, B, R = AxiosResponse<T>>(
    path: string,
    data?: B | undefined,
    config?: AxiosRequestConfig | undefined,
  ) => Promise<R>;
  put: <T, B, R = AxiosResponse<T>>(
    path: string,
    data?: B | undefined,
    config?: AxiosRequestConfig | undefined,
  ) => Promise<R>;
}

export interface CollectionResponse<T, ItemType = T[]> {
  totalCount?: number;
  from?: number;
  next?: string;
  limit?: number;
  items: ItemType extends Array<T>
    ? Array<T>
    : {
        [key: string]: T;
      };
}

export interface GetDistrictStudentState {
  loading: boolean;
  totalLoaded: number;
}

export interface CartItemAggregation {
  id: string;
  total: number;
  productName: string;
  productCategoryId: string;
  price: number;
  pickupLocation: string;
  comments: string;
}

export interface Newsfeed {
  id?: string;
  title: string;
  content: string;
  startDate: string | null;
  endDate: string | null;
  districtId?: string;
  sortIndex: number;
}

export interface PickupLocationOption {
  default: boolean | undefined;
  text: string;
  value: string;
  id: string;
  name: string;
  group: string;
  scope: string;
}

export interface TeacherOrderingCart {
  // Date i.e. 2020-09-30
  [key: string]: {
    // Menu Type Id
    [key: string]: {
      // Cart Id composed from entree and side item product ids
      [key: string]: {
        entree: MenuItem;
        sides: Record<string, MenuItem[]>;
        studentIds: string[];
      };
    };
  };
}
export interface BulkOrderItems {
  // Product Id
  [key: string]: {
    menuItem: MenuItem;
    quantity: number;
  };
}

export interface TeacherBulkOrderingCart {
  // Date
  [key: string]: {
    // Menu Type Id
    [key: string]: BulkOrderItems;
  };
}

export interface OrderConfirmationBin {
  menuTypeName: string;
  productName: string;
  sides: string;
  total: number;
}

export interface TeacherReportCellModel {
  entree: string;
  sides: string;
}

export interface PickupLocationTableRow {
  firstName: string;
  lastName: string;
  allergens: string;
  receivedSort: string;
  studentId: React.ReactNode;
  orderedAt: React.ReactNode;
  orderedBy: React.ReactNode;
  order: React.ReactNode;
  aLaCarte: React.ReactNode;
  aLaCarteOrder: React.ReactNode;
  comments: React.ReactNode;
  status: React.ReactNode;
  barcode: React.ReactNode;
  quantityOrdered: React.ReactNode;
  actions: React.ReactNode;
  type: RowType;
  name?: string;
  orderedByName: string;
  orderedAtTimestamp: number;
  [menuTypeId: string]: React.ReactNode;
}

export interface ReportStudent {
  allergens: string;
  firstName: string;
  id: string;
  lastName: string;
  sisId: string;
  status: string;
  grade?: string;
}

export interface OrderedBy {
  principalId: string;
  role?: string;
  name?: string;
  email?: string;
}

export interface CanceledBy {
  principalId: string;
  role?: string;
  name?: string;
  email?: string;
}

export interface OrdersForPrint {
  [date: string]: {
    [pickupLocationId: string]: {
      [studentId: string]: {
        [menuTypeId: string]: {
          [productId: string]: {
            count: number;
            orderedAt: number;
            orderedBy: OrderedBy;
            name?: string;
            received?: Received;
          };
        };
      };
    };
  };
}

export interface BulkOrderEntry {
  name: string;
  orderedAt: number;
  orderedBy: OrderedBy;
  quantity: number;
  received?: Received;
}

export interface BulkOrdersForPrint {
  [date: string]: {
    [pickupLocationId: string]: {
      [menuTypeId: string]: {
        [productId: string]: BulkOrderEntry;
      };
    };
  };
}

export interface ReportPickupLocation {
  id: string;
  name?: string;
  scope: string;
  siteId?: string;
}

export interface ReportProduct {
  id: string;
  categoryId?: string;
  name?: string;
}

export interface DateOrder {
  date: string;
  orders: {
    menuTypeName: string;
    entreeSummary: string;
    alacarteSummary: string;
    entreeTotal: number;
    alacarteTotal: number;
    pickupLocationName: string;
    isIndividualMeal: boolean;
    comments?: string;
  }[];
}

export interface DateBulkOrder {
  date: string;
  orders: {
    menuTypeName: string;
    pickupLocationName: string;
    totalsByCategory: { category: string; categoryBreakdown: { productName: string; total: number }[] }[];
  }[];
}

export interface Route {
  path: string;
  alias?: string[];
  allowedRoles?: Role[];
  Component: any;
  adminSidebar?: boolean;
  isPrintable?: boolean;
  hideParentFooter?: boolean;
  props?: any;
  adminLevels?: Admin[];
}

export interface RouteWithFallback extends Omit<Route, 'alias' | 'path'> {
  path: string[];
}

export interface TransactionHistoryState {
  orderItems: OrderItem[];
}

export interface TransactionHistoryItem {
  date: string;
  account: string;
  menuTypeName: string;
  type: string;
  items: string;
  pickupLocationName: string;
  total: string;
  orderedBy: string;
  canceledBy: string;
  status: string;
  menuTypeId: string;
  siteId: string;
  districtId?: string;
  studentId: string;
}

export interface FilterField {
  fieldName: string;
  fieldType: FilterFieldType;
  columnAccessor: string;
  initialValue?: any;
  valueFieldAccessor?: string;
  options?: MaterialSelectOption[];
  checkboxOptions?: string[];
  fetchData?: any;
  alwaysSet: boolean;
  ordinal: number;
  dateRangeOptions?: DateRangeFilterOptions;
  booleanField?: boolean;
  booleanValueMap?: BooleanValueMap;
  aggregateStringSeparator?: string;
}

export interface BooleanValueMap {
  truthyValue: string;
  falsyValue: string;
}

export interface DateRangeFilterOptions {
  fromDateLabel: string;
  toDateLabel: string;
  maxDayRange: number;
  invalidDateRangeMessage?: string;
  view?: DatePickerView;
  disablePast?: boolean;
}

export interface TableFilterState {
  [id: string]: any;
}

export interface DateRangeError {
  fieldName: string;
  error: string;
  errorType: DateRangeErrorType;
}

export interface InputValidationProps {
  mode: string | undefined;
  size: Array<number>;
  value: string;
  min?: number;
  allowNegative?: boolean;
}

export interface PrintLabelsOptionsModalProps {
  open: boolean;
  scope: REPORT_SCOPE;
  viewByDateRange: boolean;
  onPrint: () => void;
  onClose: () => void;
}

export interface PrintLabelData {
  student: any;
  orderItems: OrderItem[];
  pickupLocation: PickupLocation;
  menuTypeId: string | null;
  date: string;
}

export interface NotificationMessage {
  message: string;
  location?: number;
}

export interface CloseSchoolYearLog {
  id: string;
  districtId: string;
  user: string;
  date: string;
  status: LogStatus;
  message: string;
  reportFileName?: string;
  withdrawnDeleted?: number;
  graduatingDeleted?: number;
}
