import React, { ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as ShopActions from 'Pages/Shop/state/actions';
import * as ShopSelectors from 'Pages/Shop/state/selectors';

import CalendarWeekWarning from 'Components/Shop/CalendarWeekWarning/CalendarWeekWarning';

const ALaCarteWarning: React.FC<{}> = (): ReactElement | null => {
  const dispatch = useDispatch();
  const district = useSelector(ShopSelectors.getStudentDistrict);

  function onCloseWarningClick() {
    dispatch(ShopActions.showALaCarteWarning(false));
  }

  return (
    <CalendarWeekWarning
      onCloseClick={onCloseWarningClick}
      orderTypeText="a la carte"
      link={district?.paymentProviderLink}
      amount={district?.orderingRules.aLaCarteMinimum}
    />
  );
};

export default ALaCarteWarning;
