import React, { ReactElement, useCallback, useMemo } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import classNames from 'classnames';
import numeral from 'numeral';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { get, set } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { Tooltip } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import * as Interfaces from 'Interfaces/Interfaces';
import * as Constants from 'Constants/Constants';
import { SidebarLocations } from 'Constants/Enums';

import * as CartSelectors from 'Components/Shop/Cart/state/selectors';
import * as CommonActions from 'State/actions';
import * as CommonSelectors from 'State/selectors';
import * as MenuItemModalActions from 'Components/Shop/MenuItemModal/state/actions';
import * as ShopSelectors from 'Pages/Shop/state/selectors';

import { Role, isAuthorized, TokenClaims } from 'Auth/User';
import { getProductPrice } from 'Components/Shop/ShopUtil';

import './MenuItem.less';

interface MenuItemProps {
  id: string;
  disabled?: boolean;
  unavailable?: boolean;
  siteGroupTypeId?: string;
  alreadyOrdered?: boolean;
}

const MenuItem = ({
  id,
  disabled = false,
  unavailable = false,
  siteGroupTypeId,
  alreadyOrdered,
}: MenuItemProps): ReactElement => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const cartItemIds = useSelector(CartSelectors.getMenuItemIds);
  const menuItems = useSelector(CommonSelectors.getMenuItems);
  const products = useSelector(CommonSelectors.getProducts);
  const orderItems = useSelector(CommonSelectors.getOrderItems);
  const student = useSelector(ShopSelectors.getStudent);
  const menuItem = menuItems[id] as Interfaces.MenuItem;
  const product = products[menuItem?.productId];
  const price = getProductPrice(product, siteGroupTypeId, useSelector(ShopSelectors.getProductPrice(userRole)));
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const isParent = isAuthorized(userRole, [Role.PARENT]);
  const isTeacher = isAuthorized(userRole, [Role.TEACHER]);
  const cartItemCount = useSelector(CartSelectors.getCartItemCountFactory(id));
  const isAlaCarte = product.productCategoryId === Constants.PRODUCT_CATEGORIES.ALACARTE.id;

  const orderCount = useMemo(() => {
    const orderIdExp = new RegExp(`${student?.id}\\.${menuItem.date}.*\\.${menuItem.mealTypeId}\\.${product.id}.*`);

    return Object.keys(orderItems).filter((key) => {
      return orderIdExp.test(key);
    }).length;
  }, [orderItems, student, menuItem, product]);
  const totalCount = useMemo(() => cartItemCount + orderCount, [cartItemCount, orderCount]);

  let displayPrice = '';
  if (isParent) {
    displayPrice = price !== undefined ? numeral(price).format(Constants.USD_FORMAT) : 'Price not set';
  } else if (isTeacher) {
    displayPrice = price !== undefined ? '' : 'Price not set';
  }

  const allOrderedItems = Object.values(orderItems).filter(
    (x) => x.date === menuItem.date && x.mealTypeId === menuItem.mealTypeId && x.studentId === student?.id,
  );

  const selectedFoodGroups = useMemo(() => ({}), []);

  allOrderedItems.forEach((orderItem) => {
    const product = products[orderItem.productId];
    const foodGroup = get(selectedFoodGroups, product?.productCategoryId, []);
    foodGroup.push(orderItem.productId);
    set(selectedFoodGroups, product?.productCategoryId, foodGroup);
  });

  const handleOpenModalClick = useCallback(() => {
    if (isParent && price === undefined) {
      return;
    }
    batch(() => {
      dispatch(MenuItemModalActions.setDate(moment(menuItem.date).format(Constants.DATE_FORMAT_YMD)));
      dispatch(MenuItemModalActions.setMenuItemId(id));
      dispatch(MenuItemModalActions.setExistingOrder(alreadyOrdered ?? false));
      dispatch(MenuItemModalActions.setSelectedFoodGroupOptions(selectedFoodGroups));
      dispatch(MenuItemModalActions.setOrderItems(allOrderedItems));
      dispatch(CommonActions.setSidebarOpen(true, SidebarLocations.MENU_ITEM_SIDE_BAR));
    });
  }, [dispatch, id, isParent, menuItem.date, price, alreadyOrdered, allOrderedItems, selectedFoodGroups]);

  const inCart = cartItemIds[id];

  if (!product) {
    return <div></div>;
  }

  return (
    <button
      disabled={disabled || unavailable}
      data-menu-id={id}
      className={classNames('btn-reset', { 'menu-item-container': !isMobile, 'menu-item-container-mobile': isMobile })}
      onClick={handleOpenModalClick}
      data-test-id={!alreadyOrdered ? `${product.name}.Btn` : `${product.name}.editBtn`}
    >
      {!unavailable && (
        <>
          <div
            className="image"
            style={{ backgroundImage: product.imageUrl ? `url(${product.imageUrl})` : undefined }}
          />
          <div className="body">
            <div className="name">{product.name}</div>
            <div className="oo-row edges">
              <div className="price">{displayPrice}</div>
              <div className="open">
                {((!isAlaCarte && !alreadyOrdered && orderCount === 0) || totalCount === 0) &&
                  (inCart ? <CheckCircleIcon /> : <ArrowForwardIcon />)}
                {isAlaCarte && totalCount > 0 && (
                  <Tooltip placement="top" title={<span style={{ fontSize: 14 }}>Click to Edit</span>}>
                    <div className="cart-item-count">{cartItemCount + orderCount}</div>
                  </Tooltip>
                )}
                {!isAlaCarte && alreadyOrdered && orderCount > 0 && <div className="cart-item-count">{orderCount}</div>}
              </div>
            </div>
          </div>
        </>
      )}
    </button>
  );
};

export default MenuItem;
