import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import Button from 'Components/Button/Button';
import { PrintLabelsStudentNameDisplayOrder, REPORT_SCOPE } from 'Constants/Enums';
import {
  PRINT_LABEL_SORT_OPTIONS,
  PRINT_LABEL_SORT_DEFAULT,
  PRINT_LABEL_STUDENT_NAME_OPTIONS,
} from 'Constants/Constants';
import { PrintLabelsOptionsModalProps } from 'Interfaces/Interfaces';
import { SelectField } from 'Components/Form/MaterialForm';
import Modal from 'Components/Modal/Modal';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import { getPrintLabelScopeRangeParameters as getPrintLabelScopeRangeParameters } from 'Pages/DistrictReports/state/selectors';
import { setPrintLabelsScopeRangeParameters } from 'Pages/DistrictReports/state/actions';

const PrintLabelsOptionsModal: React.FC<PrintLabelsOptionsModalProps> = ({
  open,
  scope,
  viewByDateRange,
  onPrint,
  onClose,
}) => {
  const printLabelScopeRangeParameters = useSelector(getPrintLabelScopeRangeParameters(scope, viewByDateRange));

  const dispatch = useDispatch();

  const { sortByOptions, defaultSortBy } = React.useMemo(() => {
    let options = PRINT_LABEL_SORT_OPTIONS;
    let defaultValue = PRINT_LABEL_SORT_DEFAULT;
    if (scope !== REPORT_SCOPE.SITE) {
      options = options.filter((x) => x.value !== 'pickupLocation.id');
      defaultValue = defaultValue.filter((x) => x !== 'pickupLocation.id');
    }
    if (!viewByDateRange) {
      options = options.filter((x) => x.value !== 'date');
      defaultValue = defaultValue.filter((x) => x !== 'date');
    }

    return { sortByOptions: options, defaultSortBy: defaultValue };
  }, [scope, viewByDateRange]);

  const timeFrame = React.useMemo(() => (viewByDateRange ? 'range' : 'day'), [viewByDateRange]);

  React.useEffect(() => {
    // Push dynamically determined default sortBy and default studentName parameters into store if store is empty
    if (isEmpty(printLabelScopeRangeParameters)) {
      dispatch(
        setPrintLabelsScopeRangeParameters({
          scope,
          timeFrame,
          parameters: { sortBy: defaultSortBy, studentName: PrintLabelsStudentNameDisplayOrder.LAST_FIRST },
        }),
      );
    }
  }, [printLabelScopeRangeParameters, viewByDateRange]);

  return (
    <Modal open={open} className="linq-dialog linq-dialog--lg" maxWidth="md" onClose={onClose}>
      <Modal.Title className="linq-dialog__header d-flex align-items-center" disableTypography={true}>
        <h3 className="linq-heading">Select Label Print Options</h3>
        <CloseIconButton onClick={onClose} />
      </Modal.Title>
      <Modal.Content className="linq-dialog__content">
        <SelectField
          options={sortByOptions}
          name={'SortBy'}
          id={'SortBy'}
          fieldLabel={'Sort By'}
          onChange={(e) => {
            dispatch(
              setPrintLabelsScopeRangeParameters({
                scope,
                timeFrame,
                parameters: {
                  sortBy: e.target.value,
                  studentName: printLabelScopeRangeParameters.studentName,
                },
              }),
            );
          }}
          error={!printLabelScopeRangeParameters.sortBy?.length}
          errorMsg={!printLabelScopeRangeParameters.sortBy?.length ? 'Sort By is Required' : undefined}
          value={printLabelScopeRangeParameters.sortBy}
          multiple
        />
        <SelectField
          options={PRINT_LABEL_STUDENT_NAME_OPTIONS}
          name={'DisplayStudentName'}
          id={'DisplayStudentName'}
          fieldLabel={'Display Student Name'}
          onChange={(e) => {
            dispatch(
              setPrintLabelsScopeRangeParameters({
                scope,
                timeFrame,
                parameters: {
                  sortBy: printLabelScopeRangeParameters.sortBy,
                  studentName: e.target.value,
                },
              }),
            );
          }}
          value={printLabelScopeRangeParameters.studentName}
          error={!printLabelScopeRangeParameters.studentName}
          errorMsg={!printLabelScopeRangeParameters.studentName ? 'Student Dispaly Name is Required' : undefined}
        />
      </Modal.Content>
      <Modal.Actions className="actions">
        <div>
          <Button
            variant="outlined"
            color="primary"
            aria-label="reset default parameters"
            onClick={() => {
              dispatch(
                setPrintLabelsScopeRangeParameters({
                  scope,
                  timeFrame,
                  parameters: { sortBy: defaultSortBy, studentName: PrintLabelsStudentNameDisplayOrder.LAST_FIRST },
                }),
              );
            }}
            disabled={
              isEqual(printLabelScopeRangeParameters.sortBy, defaultSortBy) &&
              printLabelScopeRangeParameters.studentName === PrintLabelsStudentNameDisplayOrder.LAST_FIRST
            }
            data-test-id={'cancelBtn'}
          >
            Reset Default
          </Button>
          <Button
            variant="outlined"
            color="primary"
            aria-label="Clear"
            onClick={() => {
              dispatch(
                setPrintLabelsScopeRangeParameters({
                  scope,
                  timeFrame,
                  parameters: {
                    sortBy: [],
                    studentName: '',
                  },
                }),
              );
            }}
            data-test-id={'cancelBtn'}
            disabled={!printLabelScopeRangeParameters.sortBy?.length && !printLabelScopeRangeParameters.studentName}
          >
            Clear
          </Button>
        </div>

        <Button
          variant="contained"
          color="primary"
          aria-label="Print Labels"
          onClick={onPrint}
          data-test-id={'printBtn'}
        >
          print
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PrintLabelsOptionsModal;
