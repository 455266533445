import { useAuth0 } from '@auth0/auth0-react';
import List from '@material-ui/core/List';
import { Role, TokenClaims } from 'Auth/User';
import classNames from 'classnames';
import AuthorizedLink from 'Components/AuthorizedLink/AuthorizedLink';
import { Drawer } from 'Components/Drawer';
import * as HeaderSelectors from 'Components/Header/state/selectors';
import * as CartSelectors from 'Components/Shop/Cart/state/selectors';
import * as StudentCartSwitchModalActions from 'Components/Student/SwitchStudentCartModal/state/actions';
import SwitchStudentCartModal from 'Components/Student/SwitchStudentCartModal/SwitchStudentCartModal';
import { MOBILE_MEDIA_QUERY } from 'Constants/Constants';
import { getRoutePath, RouteId, Routes } from 'Constants/Routes';
import { District } from 'Interfaces/Interfaces';
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import * as CommonSelectors from 'State/selectors';

import './HeaderMenu.less';

const HeaderMenu = (): ReactElement => {
  const dispatch = useDispatch();
  const location = useLocation();
  const checkoutData = useSelector(CartSelectors.getCheckout);
  const menuVisible = useSelector(HeaderSelectors.getMenuVisible);
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY });

  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const userDistrictId = TokenClaims.getDistrictId(user);
  const districts = useSelector(CommonSelectors.getDistricts);
  const district = districts[userDistrictId];

  return (
    <Drawer
      open={menuVisible}
      className={classNames('header-menu-container', { 'header-menu-container-mobile': isMobile })}
    >
      <div className="linq-global-menu">
        <List component="nav" className="menu-items">
          <AuthorizedLink
            className={classNames('menu-item', {
              'menu-item-active': location.pathname === getRoutePath(RouteId.PARENT_DASHBOARD),
            })}
            to={getRoutePath(RouteId.PARENT_DASHBOARD)}
            data-test-id="parentDashboard"
            id="parentdashboard"
            role={userRole}
            allowedRoles={[Role.PARENT]}
            primary={'Dashboard'}
          />
          <AuthorizedLink
            className={classNames('menu-item')}
            to={getRoutePath(RouteId.TRANSACTION_HISTORY)}
            data-test-id="transactionHistory"
            id="nav-link-transaction-history"
            role={userRole}
            allowedRoles={[Role.PARENT]}
            primary={'Transaction History'}
          />
          <AuthorizedLink
            className={classNames('menu-item', {
              'menu-item-active': location.pathname === getRoutePath(RouteId.TEACHER_DASHBOARD),
            })}
            to={getRoutePath(RouteId.TEACHER_DASHBOARD)}
            data-test-id="teacherDashboard"
            id="teacherdashboard"
            role={userRole}
            allowedRoles={[Role.TEACHER]}
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              if (checkoutData.count) {
                dispatch(StudentCartSwitchModalActions.setOpen(true));
                e.preventDefault();
              }
            }}
            primary={'Teacher Dashboard'}
          />
          <AuthorizedLink
            className={classNames('menu-item', {
              'menu-item-active': location.pathname === getRoutePath(RouteId.DISTRICT_DASHBOARD),
            })}
            to={getRoutePath(RouteId.DISTRICT_DASHBOARD)}
            data-test-id="districtDashboard"
            id="districtdashboard"
            role={userRole}
            allowedRoles={[Role.MANAGER, Role.DIRECTOR]}
            primary={'District Dashboard'}
          />
          <AuthorizedLink
            className={classNames('menu-item', {
              'menu-item-active': location.pathname === getRoutePath(RouteId.DISTRICT_REPORTS),
            })}
            to={getRoutePath(RouteId.DISTRICT_REPORTS)}
            data-test-id="mealsReporting"
            id="nav-link-meals-reporting"
            role={userRole}
            allowedRoles={[Role.MANAGER, Role.DIRECTOR]}
            primary={'Meals Reporting'}
          />
          <AuthorizedLink
            className={classNames('menu-item', {
              'menu-item-active': location.pathname === getRoutePath(RouteId.STUDENT_MANAGEMENT),
            })}
            to={getRoutePath(RouteId.STUDENT_MANAGEMENT)}
            data-test-id="studentManagement"
            id="nav-link-data-management"
            role={userRole}
            allowedRoles={[Role.DIRECTOR]}
            primary={'Account Management'}
          />
          <AuthorizedLink
            className={classNames('menu-item', {
              'menu-item-active': location.pathname === getRoutePath(RouteId.USER_MANAGEMENT),
            })}
            to={getRoutePath(RouteId.USER_MANAGEMENT)}
            data-test-id="userManagement"
            id="nav-link-user-management"
            role={userRole}
            allowedRoles={[Role.DIRECTOR]}
            primary={'User Management'}
          />
          <AuthorizedLink
            className={classNames('menu-item', {
              'menu-item-active': location.pathname === getRoutePath(RouteId.PRICING_MANAGEMENT),
            })}
            to={getRoutePath(RouteId.PRICING_MANAGEMENT)}
            data-test-id="pricingManagement"
            id="nav-link-priving-management"
            role={userRole}
            allowedRoles={[Role.DIRECTOR]}
            primary={'Pricing Management'}
          />
          <AuthorizedLink
            className={classNames('menu-item', {
              'menu-item-active': location.pathname === getRoutePath(RouteId.ORDERING_RULES),
            })}
            to={getRoutePath(RouteId.ORDERING_RULES)}
            data-test-id="orderingRules"
            id="nav-link-ordering-rules"
            role={userRole}
            allowedRoles={[Role.DIRECTOR]}
            primary={'Ordering Rules'}
          />
          <AuthorizedLink
            className={classNames('menu-item', {
              'menu-item-active': location.pathname === getRoutePath(RouteId.A_LA_CARTE),
            })}
            to={getRoutePath(RouteId.A_LA_CARTE)}
            data-test-id="aLaCarte"
            id="nav-link-a-la-carte"
            role={userRole}
            allowedRoles={[Role.DIRECTOR]}
            primary={'A la Carte'}
          />
          <AuthorizedLink
            className={classNames('menu-item', {
              'menu-item-active': location.pathname === getRoutePath(RouteId.PREVIEW_MENUS),
            })}
            to={getRoutePath(RouteId.PREVIEW_MENUS)}
            data-test-id="previewMenus"
            id="nav-link-preview-menus"
            role={userRole}
            allowedRoles={[Role.DIRECTOR]}
            primary={'Preview Menus'}
          />
          <AuthorizedLink
            className={classNames('menu-item', {
              'menu-item-active': location.pathname === getRoutePath(RouteId.PICKUP_LOCATIONS),
            })}
            to={getRoutePath(RouteId.PICKUP_LOCATIONS)}
            data-test-id="pickupLocations"
            id="nav-link-config-pickup-locations"
            role={userRole}
            allowedRoles={[Role.MANAGER, Role.DIRECTOR]}
            primary={'Configure Pickup Locations'}
          />
          <AuthorizedLink
            className={classNames('menu-item', {
              'menu-item-active': location.pathname === getRoutePath(RouteId.PAYMENT_PROVIDER),
            })}
            to={getRoutePath(RouteId.PAYMENT_PROVIDER)}
            data-test-id="paymentProvider"
            id="nav-link-config-payment-provider"
            role={userRole}
            allowedRoles={[Role.DIRECTOR]}
            primary={'Configure Payment Provider'}
          />
          <AuthorizedLink
            className={classNames('menu-item', {
              'menu-item-active': location.pathname === getRoutePath(RouteId.NEWS_FEED_MANAGEMENT),
            })}
            to={getRoutePath(RouteId.NEWS_FEED_MANAGEMENT)}
            data-test-id="parentNewsfeed"
            id="nav-link-newsfeed-management"
            role={userRole}
            allowedRoles={[Role.DIRECTOR]}
            primary={'Configure Parent Newsfeed'}
          />
          <AuthorizedLink
            className={classNames('menu-item', {
              'menu-item-active': location.pathname === getRoutePath(RouteId.IMPORT_DATA),
            })}
            to={getRoutePath(RouteId.IMPORT_DATA)}
            data-test-id="importData"
            id="nav-link-import-data"
            role={userRole}
            allowedRoles={[Role.DIRECTOR]}
            primary={'Import Data'}
          />
          <AuthorizedLink
            className={classNames('menu-item', {
              'menu-item-active': location.pathname === getRoutePath(RouteId.SITE_INFORMATION),
            })}
            to={getRoutePath(RouteId.SITE_INFORMATION)}
            data-test-id="siteInformation"
            id="nav-link-site-information"
            role={userRole}
            allowedRoles={[Role.DIRECTOR]}
            primary={'Site Information'}
          />
          <AuthorizedLink
            className={classNames('menu-item', {
              'menu-item-active': location.pathname === getRoutePath(RouteId.CLOSE_SCHOOL_YEAR),
            })}
            to={getRoutePath(RouteId.CLOSE_SCHOOL_YEAR)}
            data-test-id="closeSchoolYear"
            id="nav-link-close-school-year"
            role={userRole}
            allowedRoles={[Role.DIRECTOR]}
            primary={'Close School Year'}
          />
          {district?.synthesisIntegration === true && (
            <AuthorizedLink
              className={classNames('menu-item', {
                'menu-item-active': location.pathname === getRoutePath(RouteId.SYNTHESIS),
              })}
              to={getRoutePath(RouteId.SYNTHESIS)}
              data-test-id="synthesis"
              id="nav-link-synthesis"
              role={userRole}
              allowedRoles={[Role.DIRECTOR]}
              district={district as District}
              primary={'SyntheSIS'}
            />
          )}
        </List>
      </div>
      {userRole === Role.TEACHER && <SwitchStudentCartModal route={Routes[RouteId.TEACHER_DASHBOARD]?.path} />}
    </Drawer>
  );
};

export default HeaderMenu;
