import { Reducer } from 'react';
import moment from 'moment-timezone';

import * as Types from './types';
import { Action, DateRangeNavigatorState } from 'Interfaces/Interfaces';
import { createDayRange } from 'Helpers/Helper';

const initialState: DateRangeNavigatorState = {
  weekOfYear: moment().week(),
  dayOfYear: moment(),
  startDate: moment(),
  dayRange: createDayRange({ startDate: moment().startOf('week'), unit: 'w' }),
};

const DateRangeNavigatorReducer: Reducer<DateRangeNavigatorState, Action> = (
  state: DateRangeNavigatorState = initialState,
  action: Action,
) => {
  const { type, payload } = action;
  switch (type) {
    case Types.DATE_RANGE_NAVIGATOR_SET_WEEK_OF_YEAR:
      return {
        ...state,
        weekOfYear: payload,
      };
    case Types.DATE_RANGE_NAVIGATOR_SET_DAY:
      return {
        ...state,
        dayOfYear: payload,
      };
    case Types.DATE_RANGE_NAVIGATOR_SET_START_DATE:
      return {
        ...state,
        startDate: payload,
      };
    case Types.DATE_RANGE_NAVIGATOR_SET_DATE_RANGE:
      return { ...state, dayRange: payload };
    default:
      return state;
  }
};

export default DateRangeNavigatorReducer;
