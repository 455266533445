import { useState } from 'react';
import { cloneDeep, set } from 'lodash';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Request, RequestConfig } from '@linq/api-util';
import { datadogLogs } from '@datadog/browser-logs';
import { Api } from 'Interfaces/Interfaces';
import { globalConfig } from '../config';

const BASE_CONFIG: RequestConfig = {
  axios: {
    headers: {
      Accept: 'application/json',
      'content-Type': 'application/json; charset=utf-8',
    },
  },
};

export const request = new Request({ ...BASE_CONFIG });

const createConfig = (accessToken: string, config?: AxiosRequestConfig) => {
  const baseConfig = cloneDeep(BASE_CONFIG);

  if (accessToken) {
    set(baseConfig, 'axios.headers.Authorization', `Bearer ${accessToken}`);
  }
  return { ...baseConfig.axios, ...config };
};

export const useApi = (): Api => {
  const { getAccessTokenSilently } = useAuth0();
  const appConfig = globalConfig.getPortalConfig();
  request.instance.defaults.baseURL = appConfig.baseUrl;

  async function getAccessToken() {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: appConfig.audience,
        scope: appConfig.scope,
      });

      return accessToken;
    } catch (error) {
      datadogLogs.logger.error(`getAccessToken error`, { error, name: 'getAccessToken' });
    }
    return '';
  }

  const [methods] = useState({
    get: async <T, R = AxiosResponse<T>>(path: string, config?: AxiosRequestConfig): Promise<R> => {
      const accessToken = await getAccessToken();
      const updatedConfig = createConfig(accessToken, config);
      return request.get(path, updatedConfig);
    },
    delete: async <T, R = AxiosResponse<T>>(path: string): Promise<R> => {
      const accessToken = await getAccessToken();
      const config = createConfig(accessToken);
      return request.delete(path, undefined, config);
    },
    patch: async <T, B, R = AxiosResponse<T>>(path: string, data?: B, config?: AxiosRequestConfig): Promise<R> => {
      const accessToken = await getAccessToken();
      const updatedConfig = createConfig(accessToken, config);
      return request.patch(path, data, updatedConfig);
    },
    post: async <T, B, R = AxiosResponse<T>>(path: string, data?: B, config?: AxiosRequestConfig): Promise<R> => {
      const accessToken = await getAccessToken();
      const updatedConfig = createConfig(accessToken, config);
      return request.post(path, data, updatedConfig);
    },
    put: async <T, B, R = AxiosResponse<T>>(path: string, data?: B, config?: AxiosRequestConfig): Promise<R> => {
      const accessToken = await getAccessToken();
      const updatedConfig = createConfig(accessToken, config);
      return request.put(path, data, updatedConfig);
    },
  });
  return methods;
};
