import React, { FC, Fragment, useState, useEffect } from 'react';
import * as _ from 'lodash';
import { Paper } from '@material-ui/core';
import classNames from 'classnames';
import {
  Column,
  HeaderGroup,
  TableOptions,
  useBlockLayout,
  useFilters,
  usePagination,
  useResizeColumns,
  useTable,
} from 'react-table';
import * as Interfaces from 'Interfaces/Interfaces';
import { DraggableRow } from './DraggableRow';
import { DragLayer } from './DragLayer';

import './DragAndDropReactTable.less';

const defaultColumn: Partial<Column> = {
  disableFilters: true,
};

const DragAndDropReactTable: FC<Interfaces.DragAndDropReactTableProps> = ({
  columns,
  data,
  autoResetPage = false,
  initialState = {},
  fetchData,
  noDataText = 'No data available.',
  unitLabel,
  className,
  onOrderChange,
  dragItemType,
  dataRef,
}) => {
  const [orderedData, setOrderedData] = useState<any[]>([]);

  useEffect(() => {
    setOrderedData(data);
    dataRef.current = data;
  }, [data, dataRef]);

  const onHover = (dragIndex: number, hoverIndex: any) => {
    setOrderedData((prevOrderedData: any[]) => {
      const newOrderedData = _.cloneDeep(prevOrderedData);

      newOrderedData.splice(dragIndex, 1);
      newOrderedData.splice(hoverIndex, 0, prevOrderedData[dragIndex]);

      dataRef.current = newOrderedData;
      if (onOrderChange) {
        onOrderChange(orderedData);
      }

      return [...newOrderedData];
    });
  };

  const tableOptions: TableOptions<object> = {
    columns,
    data: orderedData,
    autoResetPage,
    initialState,
    manualPagination: !!fetchData,
    defaultColumn,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: { pageIndex, pageSize },
  } = useTable(tableOptions, useFilters, usePagination, useBlockLayout, useResizeColumns);

  React.useEffect(() => {
    if (fetchData) {
      fetchData({ pageIndex, pageSize });
    }
  }, [fetchData, pageIndex, pageSize]);

  return (
    <>
      {unitLabel && (
        <div className="react-table-unit-label">
          Viewing {rows.length} {unitLabel}
        </div>
      )}

      <DragLayer acceptedType={dragItemType} />
      <Paper className={classNames('react-table-container', className)}>
        <div className="react-table-scroll-wrapper" style={{ overflowX: 'auto' }}>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: HeaderGroup, i: number) => {
                return (
                  <Fragment key={`${headerGroup.id}${i}`}>
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                      {headerGroup.headers.map((column) => {
                        return (
                          <th
                            className={(column as any).className}
                            style={{
                              maxWidth: `${column.width}px`,
                              minWidth: `${column.width}px`,
                              width: `${column.width}`,
                            }}
                            key={column.id}
                          >
                            {column.render('Header')}
                          </th>
                        );
                      })}
                    </tr>
                  </Fragment>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return <DraggableRow row={row} onHover={onHover} index={i} key={i} type={dragItemType} />;
              })}
              {data.length === 0 && <tr className="no-data-row" />}
            </tbody>
          </table>
          {data.length === 0 && <div className="no-data-msg">{noDataText}</div>}
        </div>
      </Paper>
    </>
  );
};

export default React.forwardRef<FC<React.ReactNode>, Interfaces.DragAndDropReactTableProps>(
  DragAndDropReactTable as any,
);
