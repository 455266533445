import Button from 'Components/Button/Button';
import { InputField } from 'Components/Form/MaterialForm';
import { Autocomplete } from 'Components/Form/MaterialForm/AutoComplete';
import Modal from 'Components/Modal/Modal';
import { InputMode } from 'Constants/Enums';
import { Field, FieldProps, Form, Formik } from 'formik';
import React from 'react';

export interface GradeGroupPricingModalProps {
  isOpen: boolean;
  loading: boolean;
  gradeOptions: { text: string; value: string }[];
  initialValues?: { grades: string[]; price: string };
  handleClose: () => void;
  handleSubmit: (submitValues: { grades: string[]; price: string }) => void;
}

const GradeGroupPricingModal = ({
  isOpen,
  loading,
  handleSubmit,
  handleClose,
  gradeOptions,
  initialValues,
}: GradeGroupPricingModalProps) => {
  const handleValidation = (values: { grades: string[]; price: string }) => {
    const errors: any = {};

    if (!values.grades.length) {
      errors['grades'] = 'At least one grade must be selected.';
    }

    if (!values.price) {
      errors['price'] = 'Price is required.';
    }

    return errors;
  };
  return (
    <Modal open={isOpen} maxWidth="md" onClose={handleClose} className="linq-dialog add-grade-group-modal">
      <Formik
        initialValues={initialValues ?? { grades: [], price: '' }}
        validate={handleValidation}
        onSubmit={handleSubmit}
      >
        <Form>
          <Modal.Header className="linq-dialog__header d-flex align-items-center">
            <h3 className="linq-heading">Add Grade Group Pricing</h3>
          </Modal.Header>
          <Modal.Content className="linq-dialog__content">
            <Field name={'grades'} key={'grades'}>
              {({ field, form, meta }: FieldProps) => (
                <Autocomplete
                  options={gradeOptions}
                  label={'Grades'}
                  id={'grades'}
                  key={'grades'}
                  multiple
                  loading
                  value={field.value}
                  errorMsg={meta.error}
                  touched={meta.touched}
                  multipleOnChange={(e: any, value: any) => {
                    form.setFieldValue(
                      'grades',
                      value.map((x: any) => x.value),
                    );
                  }}
                  data-test-id="gradeDropDown"
                />
              )}
            </Field>

            <Field name={'price'} key={'price'}>
              {({ field, meta }: FieldProps) => (
                <InputField
                  name={'price'}
                  key={'price'}
                  id={'price'}
                  label={'Price'}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  isCurrency
                  mode={InputMode.Decimal}
                  numericSize={[7, 2]}
                  allowNegative={false}
                  value={field.value}
                  error={!!meta.error}
                  errorMsg={meta.error}
                  touched={meta.touched}
                  disabled={loading}
                  inputProps={{
                    'data-test-id': 'priceInput',
                  }}
                />
              )}
            </Field>
          </Modal.Content>
          <Modal.Actions className="linq-dialog__actions">
            <Button variant="outlined" color="primary" onClick={handleClose} data-test-id="cancelGradeGroupBtn">
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit" data-test-id="submitGradeGroupBtn">
              {initialValues ? 'Update' : 'Add'}
            </Button>
          </Modal.Actions>
        </Form>
      </Formik>
    </Modal>
  );
};

export default GradeGroupPricingModal;
