import { useApi } from 'Api/useApi';
import { OrderItem } from 'Interfaces/Interfaces';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as types from './types';

export const setTransactionHistory = (orderItems: OrderItem[]) => ({
  type: types.TRANSACTION_HISTORY_SET_HISTORY,
  payload: orderItems,
});

export const useTransactionHistoryActions = () => {
  const api = useApi();
  const dispatch = useDispatch();

  const [actions] = useState({
    getTransactionHistory: async (dateFrom: string, dateTo: string) => {
      const query = new URLSearchParams({
        dateFrom: dateFrom,
        dateTo: dateTo,
      });

      try {
        const results = await api.get(`/transactionHistory?${query}`);
        dispatch(setTransactionHistory((results.data as any).data as OrderItem[]));
      } catch (err) {}
    },
  });

  return actions;
};
