import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState, OrderingRules } from 'Interfaces/Interfaces';
import Card from 'Components/Card/Card';
import * as CommonStateSelectors from 'State/selectors';
import * as CommonStateActions from 'State/actions';
import BlackoutDatesRulesSidebar from '../Sidebars/BlackoutDatesRulesSidebar';
import { SidebarLocations } from 'Constants/Enums';

export const RuleBlackoutDates: FC<{ orderingRules: OrderingRules }> = ({ orderingRules = {} }) => {
  const sidebarOpen = useSelector((state: AppState) =>
    CommonStateSelectors.getSidebarOpen(state, SidebarLocations.BLACKOUT_DATES_RULES_SIDE_BAR),
  );
  const dispatch = useDispatch();

  const setSidebarOpen = (isOpen: boolean) => {
    dispatch(CommonStateActions.setSidebarOpen(isOpen, SidebarLocations.BLACKOUT_DATES_RULES_SIDE_BAR));
  };

  const length = orderingRules.blackoutDates?.length;

  return (
    <>
      <Card
        title="Blackout Dates"
        description="Set blackout dates"
        onEditClick={() => setSidebarOpen(true)}
        editAriaLabel="Edit Blackout Dates"
      >
        <div className="ordering-rules__rules">
          <div className="ordering-rules__setting">
            <h6 className="linq-card__h6">Blackout Dates</h6>
            <div className="setting-value" data-test-id={'blackoutDatesLbl'}>
              {length ?? 0} {length === 1 ? 'Date' : 'Dates'}
            </div>
          </div>
        </div>
      </Card>
      <BlackoutDatesRulesSidebar open={sidebarOpen} setSidebarOpen={setSidebarOpen} orderingRules={orderingRules} />
    </>
  );
};
