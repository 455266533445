import { Accordion, AccordionDetails, AccordionSummary, Divider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import Button from 'Components/Button/Button';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'Components/Drawer';
import { FoodGroupSelector } from 'Components/Form/FoodGroupSelector';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import { PageLoader } from 'Components/Loaders/Loaders';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';
import NutritionFacts from 'Components/NutritionFacts/NutritionFacts';
import { canSubmitForm } from 'Components/Shop/MenuItemModal/menuItemUtilities';
import SpecialMealAccommodations from 'Components/SpecialMealAccommodations/SpecialMealAccommodations';
import * as Constants from 'Constants/Constants';
import { SidebarLocations } from 'Constants/Enums';
import * as Interfaces from 'Interfaces/Interfaces';
import { cloneDeep } from 'lodash';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

interface EntreeSidebarProps {
  open: boolean;
  onClose: () => void;
  entree: Interfaces.MenuItem | null;
  foodGroupOptions: Record<string, Interfaces.ProductCategory>;
  menuItemsByProductCategory: Record<string, Interfaces.MenuItem[]>;
  menuType: Interfaces.MenuType;
  products: Record<string, Interfaces.Product>;
  selectedSides: Record<string, Interfaces.MenuItem[]>;
  onSelectionConfirmed?: (entree: Interfaces.MenuItem, sides: Record<string, Interfaces.MenuItem[]>) => void;
  cancelBtnText?: string;
  okBtnText?: string;
  drawerTitle?: string;
  hideFoodGroups?: boolean;
  readOnly?: boolean;
  product?: Interfaces.Product;
}

const EntreeSidebar = ({
  open,
  onClose,
  entree,
  foodGroupOptions,
  menuItemsByProductCategory,
  menuType,
  products,
  selectedSides,
  onSelectionConfirmed,
  cancelBtnText = 'Cancel',
  okBtnText = 'Select',
  drawerTitle = 'Entree Side Selections',
  hideFoodGroups,
  readOnly,
  product,
}: EntreeSidebarProps) => {
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const [currentSides, setCurrentSides] = React.useState<Record<string, Interfaces.MenuItem[]>>({});

  const handleSideSelection = React.useCallback(
    (productCategoryId, menuItem) => {
      const options = cloneDeep(currentSides);
      if (!options[productCategoryId] || menuItem === null) {
        options[productCategoryId] = [];
      }
      // Toggle menuItemIds for each Product Category and check allowed multiple categories
      if (Constants.FOOD_GROUPS_MULTIPLE_ALLOWED.includes(productCategoryId)) {
        if (options[productCategoryId].some((item) => item.id === menuItem.id)) {
          options[productCategoryId] = options[productCategoryId].filter((item) => item.id !== menuItem.id);
        } else {
          options[productCategoryId].push(menuItem);
        }
      } else if (Constants.FOOD_GROUPS_MULTIPLE_NOT_ALLOWED.includes(productCategoryId)) {
        if (Constants.FOOD_GROUPS_REQUIRED.includes(productCategoryId)) {
          options[productCategoryId] = [menuItem];
        } else if (Constants.FOOD_GROUPS_OPTIONAL.includes(productCategoryId)) {
          if (options[productCategoryId].some((item) => item.id === menuItem.id)) {
            options[productCategoryId] = options[productCategoryId].filter((item) => item.id !== menuItem.id);
          } else {
            options[productCategoryId] = [menuItem];
          }
        }
      }
      setCurrentSides(options);
    },
    [currentSides],
  );

  React.useEffect(() => {
    setCurrentSides(selectedSides);
  }, [selectedSides]);

  const onDrawerClose = () => {
    onClose();
    setCurrentSides(selectedSides);
  };

  if (!product) {
    return null;
  }

  return (
    <form>
      <Drawer
        anchor="right"
        open={open}
        onClose={onDrawerClose}
        className={classNames('menu-item-modal-container entree-sidebar', { mobile: isMobile })}
      >
        <PageLoader />
        <div className="linq-drawer-form-content menu-item-modal-content">
          <DrawerHeader aria-label="Header for the current menu item.">
            <h2>{drawerTitle}</h2>
            <CloseIconButton onClick={onClose} aria-label="Close Drawer" />
          </DrawerHeader>
          <NotificationBanner location={SidebarLocations.ENTREE_SIDE_BAR} />
          <DrawerContent id="menu-item-modal-content" className="menu-item-modal-content">
            <div className="entree-sidebar-img-container section-grouping">
              <img className="entree-sidebar__product-image" src={product.imageUrl} alt={product.name} />
            </div>
            {product && (
              <>
                <div className="name-section">
                  <div className="product-category">{`${menuType?.name || ''} ${
                    Constants.PRODUCT_CATEGORIES[product.productCategoryId]?.name
                  }`}</div>
                  <div className="name">{product.name}</div>
                  <div className="description">{product.description}</div>
                </div>
                <Divider />
                <Accordion className="oo-accordion accordion-section">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h4>View Ingredients</h4>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="ingredients">{product.ingredients}</div>
                  </AccordionDetails>
                </Accordion>
                <Divider />
                <Accordion className="oo-accordion accordion-section">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h4>View Nutritional Information</h4>
                  </AccordionSummary>
                  <AccordionDetails>
                    <NutritionFacts product={product} />
                  </AccordionDetails>
                </Accordion>
                <Divider />
                <div className="section-grouping special-meal-accomodations">
                  <SpecialMealAccommodations
                    specialMealAccommodationIds={product.specialMealAccommodationIds}
                    customSpecialMealAccommodations={product.customSpecialMealAccommodations}
                  />
                </div>
                {!hideFoodGroups && (
                  <div className="food-groups section-grouping">
                    {Object.values(foodGroupOptions).map((foodGroup) => {
                      const options = menuItemsByProductCategory[foodGroup.id];

                      if (!options || !options.length) {
                        return null;
                      }

                      return (
                        <FoodGroupSelector
                          products={products}
                          productCategoryId={foodGroup.id}
                          options={options}
                          onChange={handleSideSelection}
                          foodGroupName={foodGroup.name}
                          allowNoneOption={false}
                          selectedOptions={currentSides}
                          key={foodGroup.id}
                        />
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </DrawerContent>
          <DrawerFooter className="footer" aria-label="Footer for the Entree Side Selection Form">
            {!readOnly && (
              <>
                <Button onClick={onDrawerClose} variant="outlined" color="primary" data-test-id="cancelBtn">
                  {cancelBtnText}
                </Button>
                <Button
                  onClick={(e) => {
                    if (onSelectionConfirmed && entree) {
                      onSelectionConfirmed(entree, currentSides);
                    }
                  }}
                  variant="contained"
                  color="primary"
                  disabled={
                    !canSubmitForm(product, currentSides, Object.keys(foodGroupOptions) as Constants.FOOD_GROUP_TYPES[])
                  }
                  type="submit"
                  data-test-id="selectBtn"
                >
                  {okBtnText}
                </Button>
              </>
            )}
            {readOnly && (
              <>
                <div />
                <Button onClick={onDrawerClose} variant="contained" color="primary" data-test-id="closeBtn">
                  Close
                </Button>
              </>
            )}
          </DrawerFooter>
        </div>
      </Drawer>
    </form>
  );
};

export default EntreeSidebar;
