import { Reducer } from 'react';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';

const initialState: Interfaces.UnlinkStudentModalState = {
  open: false,
  studentId: null
};

const UnlinkStudentModalReducer: Reducer<Interfaces.UnlinkStudentModalState, Interfaces.Action> = (state = initialState, action) => {

  const { type, payload } = action;

  switch (type) {
    case Types.UNLINK_STUDENT_MODAL_OPEN:
      return {
        ...state,
        open: payload.open,
        studentId: payload.studentId
      };

    default:
      return state;
  }
};

export default UnlinkStudentModalReducer;
