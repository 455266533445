import React, { FC, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikProps, Form, FormikValues, Field, FieldProps, FastField } from 'formik';
import { InputField } from 'Components/Form/MaterialForm';
import Button from 'Components/Button/Button';
import { toast } from 'react-toastify';
import { FormControlLabel, Checkbox, makeStyles, FormHelperText, FormGroup, FormControl } from '@material-ui/core';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import * as MenuTypePickerSelectors from 'Components/MenuTypePicker/state/selectors';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';
import { useALaCarteActions } from 'Pages/ALaCarte/state/actions';
import { SITE_GROUP_TYPES, ACCEPTED_IMAGE_TYPES, WEEKDAYS } from 'Constants/Constants';
import { SidebarLocations } from 'Constants/Enums';

import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'Components/Drawer';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import { InputUpload } from 'Components/InputUpload/InputUpload';
import { NotificationDialog } from 'Components/Dialogs/NotificationDialog';
import * as CommonSelectors from 'State/selectors';

import './ALaCarteSidebar.less';

type NutritionFactKey = { label: string; name: string; type: string; full?: boolean };

const nutritionFactKeys: NutritionFactKey[] = [
  { label: 'Serving Size', name: 'servingSize', type: 'text', full: true },
  { label: 'Calories Per Serving', name: 'calories', type: 'number', full: true },
  { label: 'Total Fat (g)', name: 'totalFat', type: 'number' },
  { label: 'Protein (g)', name: 'protein', type: 'number' },
  { label: 'Saturated Fat (g)', name: 'saturatedFat', type: 'number' },
  { label: 'Trans Fat (g)', name: 'transFat', type: 'number' },
  { label: 'Vitamin D (mcg)', name: 'vitaminD', type: 'number' },
  { label: 'Cholesterol (mg)', name: 'cholesterol', type: 'number' },
  { label: 'Calcium (mg)', name: 'calcium', type: 'number' },
  { label: 'Sodium (mg)', name: 'sodium', type: 'number' },
  { label: 'Iron (mg)', name: 'iron', type: 'number' },
  { label: 'Total Carbohydrates (g)', name: 'carbohydrates', type: 'number' },
  { label: 'Potassium (mg)', name: 'potassium', type: 'number' },
  { label: 'Dietary Fibers (g)', name: 'dietaryFiber', type: 'number' },
  { label: 'Water (g)', name: 'water', type: 'number' },
  { label: 'Total Sugars (g)', name: 'sugar', type: 'number' },
  { label: 'Ash (g)', name: 'ash', type: 'number' },
  { label: 'Added Sugars (g)', name: 'addedSugars', type: 'number' },
];

const blankItem = {
  id: uuidv4(),
  name: '',
  description: '',
  imageUrl: '',
  productFormulationPDFUrl: '',
  nutritionFacts: Object.values(nutritionFactKeys).reduce(
    (acc: { [fieldName: string]: string }, fact: NutritionFactKey) => {
      acc[fact.name] = '';
      return acc;
    },
    {},
  ),
  specialMealNotes: '',
  mealTypeIds: [],
  siteGroupTypeIds: [],
  prices: {},
};

const customStyles = {
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
};

const aLaCarteSchema = yup.object().shape({
  name: yup.string().test('TitleRequired', 'Title Required', (value) => !!value?.trim()),
});

export interface ALaCarteSidebarProps {
  districtId: string;
  item?: any; // Replace with ALaCarteInterface
  open: boolean;
  setSidebarOpen: (open: boolean) => void;
}

/**
 * Map the form values from their object syntax into arrays based on the a la carte data model
 *
 * @param values All for values
 */
export const mapValuesToModel = ({ prices = [], mealTypeIds = [], offeredWeekdays = [], ...values }: FormikValues) => {
  const selectedMealTypeIds = Object.entries(mealTypeIds).reduce((acc, [mealTypeId, selected]) => {
    if (selected) {
      acc.push(mealTypeId);
    }
    return acc;
  }, [] as string[]);

  const selectedOfferedDays = Object.keys(offeredWeekdays)
    .filter((key: string) => offeredWeekdays[key])
    .map((key: string) => key);

  return {
    ...blankItem,
    ...values,
    mealTypeIds: selectedMealTypeIds,
    offeredWeekdays: selectedOfferedDays,
    nutritionFacts: {
      ...blankItem.nutritionFacts,
      ...values.nutritionFacts,
    },
    siteGroupTypeIds: prices
      .map((price: { siteGroupTypeId?: string } = {}) => price.siteGroupTypeId)
      .filter((i: string | undefined) => i),
    // Filter out invalid selections
    prices: prices.filter(
      (price: { siteGroupTypeId?: string; price?: string } = {}) => price.siteGroupTypeId && price.price !== '',
    ),
  };
};

/**
 * Map the model to the form syntax.
 * @param param0 ALaCarteProduct
 */
export const mapModelToValues = (
  { prices = [], mealTypeIds = [], offeredWeekdays = [], ...item }: any,
  initialMenuTypes: { [key: string]: boolean },
) => {
  return {
    ...item,
    prices: Object.keys(SITE_GROUP_TYPES).map((siteGroupTypeId: string) => {
      const currentPrice = !prices
        ? undefined
        : prices.find((p: { siteGroupTypeId: string; price?: string }) => p.siteGroupTypeId === siteGroupTypeId);
      if (currentPrice && currentPrice.price !== '') {
        return {
          siteGroupTypeId: currentPrice.siteGroupTypeId,
          price: currentPrice.price,
        };
      }
      return {};
    }),
    mealTypeIds: {
      ...initialMenuTypes,
      ...mealTypeIds.reduce((acc: { [key: string]: boolean }, mealTypeId: string) => {
        acc[mealTypeId] = true;
        return acc;
      }, {}),
    },
    offeredWeekdays: WEEKDAYS.reduce((acc: { [key: string]: boolean }, weekday: string) => {
      acc[weekday] = offeredWeekdays.includes(weekday);
      return acc;
    }, {}),
  };
};

const ALaCarteSidebar: FC<ALaCarteSidebarProps> = ({ districtId, item, open, setSidebarOpen }) => {
  const district = useSelector(CommonSelectors.getDistrict(districtId));
  const isEditing = !!item;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const menuTypes = useSelector(MenuTypePickerSelectors.getMenuTypes);
  const menuTypeIds = useSelector(MenuTypePickerSelectors.getMenuTypeIds);
  const aLaCarteActions = useALaCarteActions();
  const offerOnlyALaCarteNoPublishedMenus = district?.orderingRules?.offerOnlyALaCarteNoPublishedMenus;

  const initialValues = useMemo(() => {
    let values = {
      districtId,
      prices: [],
      mealTypeIds: menuTypeIds.reduce((acc: { [key: string]: boolean }, mealTypeId: string) => {
        acc[mealTypeId] = false;
        return acc;
      }, {}),
      name: '',
      offeredWeekdays: WEEKDAYS.reduce((acc: { [key: string]: boolean }, weekday: string) => {
        acc[weekday] = false;
        return acc;
      }, {}),
    };

    if (item) {
      values = {
        ...values,
        ...mapModelToValues(item, values.mealTypeIds),
      };
    }
    return values;
  }, [districtId, item, menuTypeIds]);

  const handleSubmit = async (values: FormikValues) => {
    setIsLoading(true);
    const data = mapValuesToModel(values);
    await aLaCarteActions.createOrPatchALaCarteItem(districtId, data);
    aLaCarteActions.getProducts(districtId);
    setIsLoading(false);
    blankItem.id = uuidv4();
    setSidebarOpen(false);
  };

  const handleValidation = ({ prices = [], ...values }: FormikValues) => {
    const errors: any = {};

    // Validate menu type selection
    if (
      Object.values(menuTypes).length > 0 &&
      (!values.mealTypeIds ||
        (Object.values(values.mealTypeIds).every((x) => x === false) && !offerOnlyALaCarteNoPublishedMenus))
    ) {
      errors.mealTypeIds = 'Select at least one menu';
    }

    // Validate optional pricing groups
    if (prices.length) {
      prices.forEach((p: { siteGroupTypeId?: string; price?: string } = {}, index: number) => {
        const { siteGroupTypeId, price } = p;
        if (siteGroupTypeId && !price) {
          if (!errors.prices) {
            errors.prices = [];
          }

          errors.prices[index] = {};
          errors.prices[index].price = 'If the item is available at a site group, the price must be set.';
        }
      });
    }

    // Validate at least one day of the week is selected
    if (Object.values(values.offeredWeekdays).every((x) => x === false)) {
      errors.offeredWeekdays = 'Select at least one day';
    }

    return errors;
  };

  const deleteALaCarteItem = async (data: any) => {
    setIsLoading(true);
    await aLaCarteActions.deleteProduct(districtId, item.id);
    setIsLoading(false);
    setShowConfirmation(false);
    setSidebarOpen(false);
    dispatch(aLaCarteActions.getProducts(districtId));
  };
  return (
    <Drawer open={open} anchor={'right'} id="a-la-carte-sidebar-slideout">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={aLaCarteSchema}
        validate={handleValidation}
      >
        {({
          errors,
          values,
          setFieldValue,
          setFieldTouched,
        }: FormikProps<{ mealTypeIds: any; offeredWeekdays: any }>) => {
          return (
            <Form className="linq-drawer-form-content oo-drawer-form">
              <DrawerHeader>
                <h2>{isEditing ? 'Edit' : 'Add New'} A la Carte Item</h2>
                <CloseIconButton
                  onClick={() => {
                    setSidebarOpen(false);
                  }}
                />
              </DrawerHeader>
              <NotificationBanner location={SidebarLocations.A_LA_CARTE_SIDE_BAR} />
              <DrawerContent>
                <FastField name="district">
                  {({ field }: FieldProps) => <input type="hidden" name={field.name} value={field.value} />}
                </FastField>
                <fieldset className="input-group">
                  <legend className="caption">A la Carte Information</legend>
                  <FastField name="name">
                    {({ field, meta }: FieldProps) => (
                      <InputField
                        id={`field=${field.name}`}
                        name={field.name}
                        label="Title"
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        error={!!meta.error}
                        touched={meta.touched}
                        showOnlyTouchedError
                        errorMsg={meta.error}
                        inputProps={{
                          maxLength: '128',
                          'data-test-id': 'titleInput',
                        }}
                        spellCheck
                        value={field.value}
                      />
                    )}
                  </FastField>
                  <div className="description-container">
                    <FastField name="description">
                      {({ field }: FieldProps) => (
                        <React.Fragment>
                          <div className="textarea-length" data-test-id="descCharCountLbl">{`${
                            field?.value?.length ?? 0
                          }/255`}</div>
                          <InputField
                            id={`field=${field.name}`}
                            name={field.name}
                            onChange={field.onChange}
                            label="Description"
                            rows={4}
                            rowsMax={8}
                            spellCheck
                            multiline={true}
                            variant="outlined"
                            inputProps={{ maxLength: 255, 'data-test-id': 'descriptionInput' }}
                            value={field.value}
                          />
                        </React.Fragment>
                      )}
                    </FastField>
                  </div>
                  <Field name="imageUrl">
                    {(fieldProps: FieldProps) => {
                      return (
                        <>
                          <InputUpload
                            {...fieldProps}
                            setLoading={(loading: boolean) => {
                              setIsLoading(loading);
                            }}
                            dropZoneOptions={{
                              accept: ACCEPTED_IMAGE_TYPES,
                            }}
                            label="Product Image"
                            placeholder="Product Image"
                            onSuccess={() => {
                              toast.success(`File was uploaded successfully!`, {
                                position: 'bottom-right',
                                autoClose: 2000,
                                hideProgressBar: true,
                                closeOnClick: true,
                              });
                            }}
                            preUploadProcessor={(data) => {
                              const fileExtensionWithDot = data.name.substring(data.name.lastIndexOf('.'));
                              data.id = item && item.id ? item.id : blankItem.id;
                              data.name = data.id ? data.id + fileExtensionWithDot : data.name;
                              return data;
                            }}
                          />
                          {fieldProps.field.value && (
                            <div className="a-la-carte__img-container">
                              <img
                                className="a-la-carte__product-image"
                                src={fieldProps.field.value}
                                alt="Product Img"
                              />
                            </div>
                          )}
                        </>
                      );
                    }}
                  </Field>
                  <Field name="productFormulationPDFUrl">
                    {(fieldProps: FieldProps) => {
                      return (
                        <InputUpload
                          {...fieldProps}
                          setLoading={(loading: boolean) => {
                            setIsLoading(loading);
                          }}
                          placeholder="Product Formulation PDF"
                          label="Product Formulation PDF"
                          dropZoneOptions={{
                            accept: ['.pdf'],
                          }}
                          onSuccess={() => {
                            toast.success(`File was uploaded successfully!`, {
                              position: 'bottom-right',
                              autoClose: 2000,
                              hideProgressBar: true,
                              closeOnClick: true,
                            });
                          }}
                          preUploadProcessor={(data) => {
                            const fileExtensionWithDot = data.name.substring(data.name.lastIndexOf('.'));
                            data.id = item && item.id ? item.id : blankItem.id;
                            data.name = data.id ? data.id + fileExtensionWithDot : data.name;
                            return data;
                          }}
                        />
                      );
                    }}
                  </Field>
                  {Object.keys(menuTypes).length > 0 && (
                    <>
                      <legend className="caption">School Menus</legend>
                      <Field name="mealTypeIds" key={'menuTypeIds'}>
                        {({ form, field }: FieldProps) => {
                          return (
                            <FormControl
                              component="fieldset"
                              error={!!errors.mealTypeIds && !!form.touched?.mealTypeIds}
                            >
                              <FormGroup>
                                {Object.values(menuTypes).map((menuType) => {
                                  return (
                                    <FormControlLabel
                                      style={{ width: '100%' }}
                                      key={menuType.id}
                                      control={
                                        <Checkbox
                                          className={classes.root}
                                          disableRipple
                                          checkedIcon={
                                            <span className={classNames(classes.icon, classes.checkedIcon)} />
                                          }
                                          onChange={() => {
                                            setFieldTouched('mealTypeIds', true);
                                            const newFormValue = { ...values.mealTypeIds };
                                            newFormValue[menuType.id] = !newFormValue[menuType.id];
                                            setFieldValue('mealTypeIds', newFormValue, true);
                                          }}
                                          icon={
                                            <span
                                              className={classes.icon}
                                              data-test-id={`${menuType.name}.availableForBtn`}
                                            />
                                          }
                                          checked={values?.mealTypeIds[menuType.id]}
                                          name={field.name}
                                          color="default"
                                        />
                                      }
                                      label={`Offered With ${menuType.name}`}
                                    />
                                  );
                                })}
                              </FormGroup>
                              {!offerOnlyALaCarteNoPublishedMenus && (
                                <FormHelperText>{errors.mealTypeIds ?? 'Select at least one menu'}</FormHelperText>
                              )}
                            </FormControl>
                          );
                        }}
                      </Field>
                    </>
                  )}
                </fieldset>
                <FastField name="ingredients">
                  {({ field }: FieldProps) => (
                    <InputField
                      id={`field=${field.name}`}
                      name={field.name}
                      label="Ingredients"
                      onChange={field.onChange}
                      inputProps={{
                        maxLength: '255',
                        'data-test-id': `${field.name}.Input`,
                      }}
                      spellCheck
                      value={field.value}
                    />
                  )}
                </FastField>
                <fieldset className="nutritional-information-wrapper input-group">
                  <legend className="caption">Nutritional Information</legend>
                  <div className="fields">
                    {nutritionFactKeys.map((n: NutritionFactKey) => (
                      <FastField name={`nutritionFacts.${n.name}`} key={n.name}>
                        {({ field }: FieldProps) => (
                          <span className={n.full ? 'input-full' : ''}>
                            <InputField
                              id={n.name}
                              name={field.name}
                              label={n.label}
                              type={n.type}
                              inputProps={{ min: '0', step: '.01', 'data-test-id': `${n.name}.Input` }}
                              onChange={field.onChange}
                              value={field.value}
                            />
                          </span>
                        )}
                      </FastField>
                    ))}
                  </div>
                </fieldset>
                <FastField name="specialMealNotes">
                  {({ field }: FieldProps) => (
                    <InputField
                      id={`field=${field.name}`}
                      name={field.name}
                      label="Special Meal Accommodations"
                      onChange={field.onChange}
                      inputProps={{
                        maxLength: '255',
                        'data-test-id': 'specialMealAccommodationsInput',
                      }}
                      spellCheck
                      value={field.value}
                    />
                  )}
                </FastField>
                <fieldset className="input-group">
                  <legend className="caption">Pricing Information</legend>
                  {Object.values(SITE_GROUP_TYPES).map((siteGroup: any, index) => {
                    return (
                      <div key={siteGroup.id}>
                        <Field name={`prices[${index}].siteGroupTypeId`}>
                          {({ form }: FieldProps) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  className={classes.root}
                                  disableRipple
                                  checkedIcon={<span className={classNames(classes.icon, classes.checkedIcon)} />}
                                  icon={
                                    <span
                                      className={classes.icon}
                                      data-test-id={`${siteGroup.name}.availableAtSiteBtn`}
                                    />
                                  }
                                  checked={
                                    !!form.values?.prices.find((p: any) => p && p.siteGroupTypeId === siteGroup.id)
                                  }
                                  name={`siteGroupTypeIds.${siteGroup.id}`}
                                  color="default"
                                  value={siteGroup.id}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      form.setFieldValue(`prices[${index}].siteGroupTypeId`, siteGroup.id);
                                    } else {
                                      form.setFieldValue(`prices[${index}].siteGroupTypeId`, undefined);
                                    }
                                  }}
                                />
                              }
                              label={`Available at ${siteGroup.name} Sites`}
                            />
                          )}
                        </Field>
                        <Field name={`prices[${index}].price`}>
                          {({ field, form, meta }: FieldProps) => (
                            <InputField
                              id={field.name}
                              name={field.name}
                              label={`Pricing ${siteGroup.name}`}
                              type="number"
                              onChange={field.onChange}
                              disabled={!form.values?.prices.find((p: any) => p && p.siteGroupTypeId === siteGroup.id)}
                              error={!!meta.error}
                              errorMsg={meta.error}
                              inputProps={{ min: '0', step: '.01', 'data-test-id': `${siteGroup.name}.priceInput` }}
                              value={field.value || ''}
                            />
                          )}
                        </Field>
                      </div>
                    );
                  })}
                </fieldset>
                <>
                  <fieldset className="input-group">
                    <legend className="caption">Days of The Week Information</legend>
                    <Field name="offeredWeekdays" key={'offeredWeekdays'}>
                      {({ form, field }: FieldProps) => {
                        return (
                          <FormControl
                            component="fieldset"
                            error={!!errors.offeredWeekdays && !!form.touched?.offeredWeekdays}
                          >
                            <FormGroup>
                              {WEEKDAYS.filter((weekday) => weekday !== 'Saturday' && weekday !== 'Sunday').map(
                                (weekday) => {
                                  return (
                                    <FormControlLabel
                                      style={{ width: '100%' }}
                                      key={weekday}
                                      control={
                                        <Checkbox
                                          className={classes.root}
                                          disableRipple
                                          checkedIcon={
                                            <span className={classNames(classes.icon, classes.checkedIcon)} />
                                          }
                                          onChange={() => {
                                            setFieldTouched('offeredWeekdays', true);
                                            const newFormValue = { ...values.offeredWeekdays };
                                            newFormValue[weekday] = !newFormValue[weekday];
                                            setFieldValue('offeredWeekdays', newFormValue, true);
                                          }}
                                          icon={
                                            <span
                                              className={classes.icon}
                                              data-test-id={`${weekday}.availableForBtn`}
                                            />
                                          }
                                          checked={values?.offeredWeekdays[weekday]}
                                          name={field.name}
                                          color="default"
                                        />
                                      }
                                      label={`Offered on ${weekday}`}
                                    />
                                  );
                                },
                              )}
                            </FormGroup>
                            <FormHelperText>{errors.offeredWeekdays ?? 'Select at least one day'}</FormHelperText>
                          </FormControl>
                        );
                      }}
                    </Field>
                  </fieldset>
                </>
              </DrawerContent>
              <DrawerFooter className={isEditing ? 'a-la-carte-editing' : ''}>
                {isEditing && (
                  <div>
                    <Button
                      variant="outlined"
                      color="primary"
                      className="cancel-button"
                      type="button"
                      onClick={() => {
                        setSidebarOpen(false);
                      }}
                      disabled={isLoading}
                      loading={isLoading}
                      data-test-id="cancelBtn"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      className="linq-button--alert-stroked delete-button"
                      type="button"
                      onClick={() => {
                        setShowConfirmation(true);
                      }}
                      data-test-id="deleteBtn"
                    >
                      Delete
                    </Button>
                  </div>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  className="add-button"
                  type="submit"
                  disabled={isLoading}
                  loading={isLoading}
                  data-test-id="submitBtn"
                >
                  {isEditing && 'Save'}
                  {!isEditing && 'Add'}
                </Button>
              </DrawerFooter>
              <NotificationDialog
                title={`Delete A La Carte item ${item?.name.toUpperCase()}`}
                message={`Are you sure you want to delete ${item?.name.toUpperCase()}?`}
                showDialog={showConfirmation}
                showCancel={true}
                cancelButtonText="CANCEL"
                okButtonText="DELETE"
                onOkClick={deleteALaCarteItem}
                onCancelClick={() => {
                  setShowConfirmation(false);
                }}
                dialogType="delete"
                loading={isLoading}
              />
            </Form>
          );
        }}
      </Formik>
    </Drawer>
  );
};

export default ALaCarteSidebar;
