import * as Types from './types';

export const setOpen = (payload: boolean) => ({
  type: Types.ADD_STUDENT_MODAL_SET_OPEN,
  payload,
});

export const setError = (payload: string) => ({
  type: Types.ADD_STUDENT_MODAL_SET_ERROR,
  payload,
});
