import Barcode from 'Components/Barcode/Barcode';
import { ComponentLoader } from 'Components/Loaders/Loaders';
import * as LoaderActions from 'Components/Loaders/state/actions';
import * as Constants from 'Constants/Constants';
import * as Helpers from 'Helpers/Helper';
import { useAutoPrintPage } from 'Helpers/useAutoPrintPage';
import {
  BulkOrdersForPrint,
  MenuType,
  OrdersForPrint,
  Product,
  ReportProduct,
  ReportMode,
  ReportPickupLocation,
  ReportStudent,
  BulkOrderEntry,
} from 'Interfaces/Interfaces';
import { get, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { sortItems } from 'Pages/DistrictReports/ReportUtil';
import { useDistrictReportActions } from 'Pages/DistrictReports/state/actions';
import * as DistrictReportsSelectors from 'Pages/DistrictReports/state/selectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import './DistrictPrintReport.less';

type Products = { [productId: string]: ReportProduct };

const getALaCarteProducts = (studentReport: any): Products => {
  if (!studentReport || !studentReport.products) {
    return {};
  }

  return Object.entries(studentReport.products as Products).reduce((acc, [productId, product]) => {
    if (product.categoryId === Constants.ALACARTE_KEY) {
      acc[productId] = product;
    }

    return acc;
  }, {} as Products);
};

const filterALaCarteProducts = (products: Products, aLaCarteProducts: Products, method: 'include' | 'exclude') => {
  const aLaCarteKeys = Object.keys(aLaCarteProducts);
  return Object.entries(products).reduce((acc, [productId, product]) => {
    product.id = productId;
    if (method === 'include' && aLaCarteKeys.indexOf(productId) > -1) {
      acc[productId] = product;
    } else if (method === 'exclude' && aLaCarteKeys.indexOf(productId) === -1) {
      acc[productId] = product;
    }
    return acc;
  }, {} as Products);
};

const barCodeOptions = {
  width: 0.8,
  height: 15,
  textAlign: 'center',
  background: 'transparent',
  margin: 0,
};

const DistrictPrintReport = () => {
  let { search } = useLocation();
  const { getReportStudents } = useDistrictReportActions();
  const dispatch = useDispatch();

  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const dateFrom = query.get('dateFrom');
  const dateTo = query.get('dateTo');
  const menuTypeId = query.get('menuTypeId') ?? '';
  const siteId = query.get('siteId') ?? '';
  const pickupLocationIds = React.useMemo(() => query.getAll('pickupLocationId'), [query]);

  const studentReport = useSelector(DistrictReportsSelectors.getReportStudents);
  const menuTypes = get(studentReport, 'menuTypes', []).reduce((acc: { [key: string]: string }, menuType: MenuType) => {
    acc[menuType.id] = menuType.name;
    return acc;
  }, {});
  const ordersForPrint = get(studentReport, 'ordersForPrint', {}) as OrdersForPrint;
  const bulkOrdersForPrint = get(studentReport, 'bulkOrdersForPrint', {}) as BulkOrdersForPrint;
  const students = get(studentReport, 'students', {}) as Record<string, ReportStudent>;
  const pickupLocations = get(studentReport, 'pickupLocations', {}) as Record<string, ReportPickupLocation>;
  const products = get(studentReport, 'products', {});
  const aLaCarteProducts = getALaCarteProducts(studentReport);
  const menuTypeIds = Object.keys(menuTypes);
  const menuType = menuTypes[menuTypeId];
  const dates = [...new Set([...Object.keys(ordersForPrint), ...Object.keys(bulkOrdersForPrint)])].sort(
    Helpers.alphanumericSortComparator,
  );

  const sortOrders = (orders: Product[]) => sortItems(orders, (x) => products[x.id]);

  useEffect(() => {
    async function getReport() {
      dispatch(LoaderActions.setComponentLoaderActive(true));
      await getReportStudents(moment(dateFrom), moment(dateTo), pickupLocationIds, siteId, ReportMode.Print);
      dispatch(LoaderActions.setComponentLoaderActive(false));
    }
    getReport();
  }, [dispatch, dateFrom, dateTo, pickupLocationIds, siteId, getReportStudents]);

  useAutoPrintPage(studentReport);

  return (
    <>
      <div className="district-report-container">
        <ComponentLoader />
        {dates.map((date: string) => {
          const dateOrders = ordersForPrint[date];
          const bulkOrders = bulkOrdersForPrint[date];

          if (!dateOrders && !bulkOrders) {
            return null;
          }
          const plIds = [...new Set([...Object.keys(dateOrders || {}), ...Object.keys(bulkOrders || {})])].sort(
            Helpers.alphanumericSortComparator,
          );

          return plIds.map((pickupLocationId) => {
            const pickupLocation = pickupLocations?.[pickupLocationId];
            const pickupLocationOrdersByStudent = dateOrders?.[pickupLocationId] || {};
            const hasOrdersForMenuType = Object.keys(pickupLocationOrdersByStudent).some(
              (studentId) => Object.keys(pickupLocationOrdersByStudent[studentId]?.[menuTypeId] || {}).length > 0,
            );
            const pickupLocationBulkOrders = bulkOrders?.[pickupLocationId] || {};

            const hasBulkOrdersForMenuType = !isEmpty(pickupLocationBulkOrders[menuTypeId]);
            const hasStudentOrdersForDate = !isEmpty(pickupLocationOrdersByStudent);

            if (!pickupLocation || !(hasOrdersForMenuType || hasBulkOrdersForMenuType)) {
              return null;
            }

            return (
              <div key={pickupLocationId} className="pickup-location-container">
                {hasStudentOrdersForDate && (
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={4} className="pickup-location-name">
                          {pickupLocation.name}
                        </th>
                        <th colSpan={4} className="date">
                          {moment(date).format(Constants.DATE_FORMAT_USA)}
                        </th>
                      </tr>
                      <tr className="print-report__header-row">
                        <th className="student-name">Student</th>
                        <th className="allergens">Allergens</th>
                        {menuType !== Constants.UNASSOCIATED_MEAL_TYPE_NAME && (
                          <th className="order-details">{menuType || 'Meal'}</th>
                        )}
                        <th className="order-details">A La Carte</th>
                        <th className="order-details">Submitted On</th>
                        <th className="order-details">Ordered By</th>
                        <th className="status">Received</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(pickupLocationOrdersByStudent)
                        .map(([studentId, studentOrdersByMenuType]: [string, any]) => {
                          const student = students[studentId];
                          if (!student) {
                            return null;
                          }
                          let orderItems: any[] = [];
                          let entreeOrders: any[] = [];
                          let aLaCarteOrders: any[] = [];
                          let entreeMeals: any[] = [];

                          if (menuTypeId) {
                            const orderItemsByMealType = get(studentOrdersByMenuType, menuTypeId, {});
                            const standardMenuItems = filterALaCarteProducts(
                              orderItemsByMealType,
                              aLaCarteProducts,
                              'exclude',
                            );
                            aLaCarteOrders = Object.values(
                              filterALaCarteProducts(orderItemsByMealType, aLaCarteProducts, 'include'),
                            );
                            orderItems = Object.values(standardMenuItems);
                            if (!Object.values(orderItems).length && !Object.values(aLaCarteOrders).length) {
                              return null;
                            }
                          } else {
                            entreeMeals = menuTypeIds.map((id) =>
                              filterALaCarteProducts(get(menuTypes, id, {}), aLaCarteProducts, 'exclude'),
                            );
                            const aLaCarte = menuTypeIds.reduce((acc, id) => {
                              acc = {
                                ...acc,
                                ...filterALaCarteProducts(get(menuTypes, id, {}), aLaCarteProducts, 'include'),
                              };
                              return acc;
                            }, {} as Record<string, any>);
                            aLaCarteOrders = Object.values(aLaCarte);
                          }

                          const mealsOrderDetails = Object.values(entreeMeals).map((productsForMeal) =>
                            Helpers.createOrderDetails(sortOrders(Object.values(productsForMeal))),
                          );
                          const orderDetails = [
                            ...mealsOrderDetails,
                            ...Helpers.createOrderDetails(sortOrders(orderItems)),
                          ];

                          const aLaCarteDetails = Helpers.createOrderDetails(sortOrders(aLaCarteOrders));
                          const received = orderItems.some((orderItem: any) => orderItem.received?.received);
                          const orderMetadata = orderItems
                            .concat(entreeOrders)
                            .concat(aLaCarteOrders)
                            .map((order: any) => ({
                              orderedAt: order.orderedAt,
                              orderedBy: order.orderedBy,
                              comments: order.comments,
                            }))[0];

                          return (
                            <>
                              <tr
                                className={classNames(orderMetadata.comments && 'print-report__row_comment')}
                                key={studentId}
                              >
                                <td className="student-name">
                                  <div>
                                    {student.firstName} {student.lastName}
                                  </div>
                                  <div className="student-id">
                                    {student.sisId}
                                    {Helpers.getGradeTextFromStudent(student)}
                                  </div>
                                  <Barcode value={student.sisId} options={barCodeOptions} />
                                </td>
                                <td className="allergens">{Helpers.formatAllergens(student.allergens)}</td>
                                {menuType !== Constants.UNASSOCIATED_MEAL_TYPE_NAME && (
                                  <td className="order-details">{orderDetails}</td>
                                )}
                                <td className="order-details">{aLaCarteDetails}</td>
                                <td className="order-details">
                                  {orderMetadata?.orderedAt
                                    ? moment(orderMetadata?.orderedAt).format(Constants.DATE_FORMAT_DATE_AND_TIME)
                                    : ''}
                                </td>
                                <td className="order-details">
                                  {orderMetadata?.orderedBy?.name || orderMetadata?.orderedBy?.email}
                                </td>
                                <td className="status">
                                  {!received ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon />}
                                </td>
                              </tr>
                              <tr className="print-report-comments-row">
                                {orderMetadata.comments && (
                                  <div className="print-report-comments">
                                    <b>Comments:</b> {orderMetadata.comments}
                                  </div>
                                )}
                              </tr>
                            </>
                          );
                        })
                        .filter((a) => a)
                        .sort((a: any, b: any) => {
                          const studentA = students[a.props.children[0].key];
                          const studentB = students[b.props.children[0].key];
                          if (studentA.lastName === studentB.lastName) {
                            return studentA.firstName > studentB.firstName ? 1 : -1;
                          }
                          return studentA.lastName > studentB.lastName ? 1 : -1;
                        })}
                    </tbody>
                  </table>
                )}
                {hasBulkOrdersForMenuType && (
                  <table className="print-report__bulk-order-table">
                    <thead>
                      {/* Add pickup location and date to header if student order table not rendered */}
                      {!hasStudentOrdersForDate && (
                        <tr>
                          <th colSpan={3} className="pickup-location-name">
                            {pickupLocation.name}
                          </th>
                          <th colSpan={3} className="date">
                            {moment(date).format(Constants.DATE_FORMAT_USA)}
                          </th>
                        </tr>
                      )}
                      <tr className="print-report__header-row">
                        <th className="bulk-order">Bulk Order</th>
                        <th className="menu-type">{menuType || 'Meal'}</th>
                        <th className="ordered">Ordered</th>
                        <th className="order-details">Submitted On</th>
                        <th className="order-details">Ordered By</th>
                        <th className="status">Received</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(pickupLocationBulkOrders).map(([bulkOrderMenuTypeId, menuTypeBulkOrders]) => {
                        if (!menuTypeId || menuTypeId === bulkOrderMenuTypeId) {
                          const sortedBulkOrders: BulkOrderEntry[] = Object.values(menuTypeBulkOrders).sort(
                            (bo1, bo2) => Helpers.alphanumericSortComparator(bo1.name, bo2.name),
                          );
                          return sortedBulkOrders.map((bulkOrder) => (
                            <tr className="print-report__row" key={bulkOrder.name}>
                              <td className="student-name">
                                <div>Bulk Order</div>
                              </td>
                              <td className="order-details">{bulkOrder.name}</td>
                              <td className="status">{bulkOrder.quantity}</td>
                              <td className="order-details">
                                {bulkOrder?.orderedAt
                                  ? moment(bulkOrder?.orderedAt).format(Constants.DATE_FORMAT_DATE_AND_TIME)
                                  : ''}
                              </td>
                              <td className="order-details">{bulkOrder.orderedBy.name}</td>
                              <td className="status">{bulkOrder?.received?.quantity || 0}</td>
                            </tr>
                          ));
                        }
                        return null;
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            );
          });
        })}
      </div>
    </>
  );
};

export default DistrictPrintReport;
