import { useApi } from 'Api/useApi';
import * as Types from './types';

export const useImportIsiteData = () => {
  const api = useApi();
  return async (data: any) => {
    try {
      await api.post(`/utility/isite/sync`, data);
    } catch (err) {}
  };
};

export const showSidebar = (payload: boolean) => ({
  type: Types.ADMIN_SHOW_SIDEBAR,
  payload,
});
