import { useAuth0 } from '@auth0/auth0-react';
import { Public, School } from '@material-ui/icons';
import { useApi } from 'Api/useApi';
import { TokenClaims } from 'Auth/User';
import ReactTable from 'Components/ReactTable/ReactTable';
import { DATE_FORMAT_YMD } from 'Constants/Constants';
import * as Interfaces from 'Interfaces/Interfaces';
import { get } from 'lodash';
import { doesRowHaveOrderItems, sortItems } from 'Pages/DistrictReports/ReportUtil';
import * as DistrictReportsActions from 'Pages/DistrictReports/state/actions';
import * as DistrictReportsSelectors from 'Pages/DistrictReports/state/selectors';
import * as DateRangeNavigatorSelectors from 'Components/DateRangeNavigator/state/selectors';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './DistrictReportTable.less';
import { downloadAsCsv } from 'Helpers/Helper';
import Button from 'Components/Button/Button';

const DistrictReportTable: FC<Interfaces.DistrictReportTableProps> = ({ report, viewByDateRange }) => {
  const dispatch = useDispatch();
  const api = useApi();
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);
  const defaultMenuType = useSelector(DistrictReportsSelectors.getDefaultMenuType(districtId));
  const dayRange = useSelector(DateRangeNavigatorSelectors.getDayRange);
  const menuTypeId = useSelector(DistrictReportsSelectors.getReportMenuTypeId) || defaultMenuType.id;
  const startDate = useSelector(DateRangeNavigatorSelectors.getStartDate);
  const products = get(report, 'products', {});
  const sites = get(report, 'sites', {});
  const pickupLocations = get(report, 'pickupLocations', {});
  const productIds = get(report, 'productIds', []);
  const globalPickupLocationIds = get(report, 'globalPickupLocationIds', []);
  const totalsBySite = get(report, 'totalsBySite', {});
  const totalsByMenuType = get(report, 'totalsByMealType', {});
  const totalsByPickupLocation = get(report, 'totalsByPickupLocation', {});
  const menuTypeTotals = totalsByMenuType[menuTypeId] ?? {};

  const handleSiteClick = (siteId: string) => {
    dispatch(DistrictReportsActions.setSiteId(siteId));
  };

  const handlePickupLocationClick = (pickupLocationId: string) => {
    dispatch(DistrictReportsActions.setPickupLocationId(pickupLocationId));
  };

  const onExportCSVClick = async () => {
    try {
      let dateFrom = startDate;
      let dateTo = startDate;

      if (viewByDateRange) {
        dateFrom = dayRange.startDate;
        dateTo = dayRange.endDate;
      }
      const results = (await api.get(`/utility/reports/districts/${districtId}/orders`, {
        params: {
          dateFrom: dateFrom.format(DATE_FORMAT_YMD),
          dateTo: dateTo.format(DATE_FORMAT_YMD),
          globalPickupLocations: true,
          siteId: '',
          reportMode: Interfaces.ReportMode.Csv,
          menuTypeId,
        },
      })) as any;
      downloadAsCsv(results.data, 'district-report.csv');
    } catch (ex) {}
  };

  // columns
  const columns = [] as any;
  const productColumns = {} as any;
  sortItems(Object.keys(menuTypeTotals), (x) => products[x]).forEach((productId: any) => {
    const product = products[productId];
    if (product && menuTypeTotals[productId]) {
      productColumns[product.id] = product.name;
    }
  });

  Object.entries(productColumns)
    .reverse()
    .forEach(([productId, productName]: any) => {
      columns.unshift({
        Header: productName || '',
        accessor: productId,
        className: 'react-table-center-column',
        dataTestId: (row: any) => {
          return `${row.original.sortName}.${productName}.totalLbl`;
        },
      });
    });

  columns.unshift({
    Header: 'School / Global Pickup Location',
    accessor: 'sortName',
    Cell: (props: any) => {
      return props.row.original.siteId;
    },
    width: 300,
    className: 'react-table-sticky-column',
    filter: 'textStartsWith',
    disableFilters: false,
  });

  // site data
  const sitesData = Object.values(sites).reduce((acc: any[], site: any) => {
    const siteLocationMenuTypeTotals = get(totalsBySite, [site.id, menuTypeId]);

    if (siteLocationMenuTypeTotals) {
      const row = productIds.reduce((acc: any, productId: any) => {
        let total = 0;
        total = get(siteLocationMenuTypeTotals, [productId], 0);
        acc[productId] = total || '--';
        return acc;
      }, {} as { [key: string]: any });
      const hasOrderItems = doesRowHaveOrderItems(row);

      if (hasOrderItems) {
        row['sortName'] = site.name;
        row['siteId'] = (
          <button
            className="btn-reset item link"
            onClick={() => handleSiteClick(site.id)}
            data-test-id={`${site.name}.reportBtn`}
          >
            <School /> {site.name}
          </button>
        );
        acc.push(row);
      }
    }

    return acc;
  }, [] as any[]);

  // pickupLocation data
  const pickuplocationData = globalPickupLocationIds.reduce((acc: any[], pickupLocationId: any) => {
    const pickupLocation = pickupLocations[pickupLocationId];
    const pickupLocationMenuTypeTotals = get(totalsByPickupLocation, [pickupLocation.id, menuTypeId]);

    if (pickupLocationMenuTypeTotals) {
      const row = productIds.reduce((acc: any, productId: any) => {
        let total = 0;
        total = get(pickupLocationMenuTypeTotals, [productId], 0);
        acc[productId] = total || '--';
        return acc;
      }, {} as { [key: string]: any });

      const hasOrderItems = doesRowHaveOrderItems(row);

      if (hasOrderItems) {
        row['sortName'] = pickupLocation.name;
        row['siteId'] = (
          <button
            className="btn-reset item link"
            onClick={() => handlePickupLocationClick(pickupLocation.id)}
            data-test-id={`${pickupLocation.name}.reportBtn`}
          >
            <Public /> {pickupLocation.name}
          </button>
        );
        acc.push(row);
      }
    }
    return acc;
  }, [] as any);

  // combine site locations and global pickup locations
  const data = [...sitesData, ...pickuplocationData];

  return (
    <div className="district-report-table-container">
      <ReactTable
        initialState={initialState}
        columns={columns}
        data={data}
        bottomLeftContainer={
          <div className="report-buttons">
            <Button variant="text" onClick={onExportCSVClick} color="primary" data-test-id="districtExportCsvBtn">
              District Export CSV
            </Button>
          </div>
        }
      />
    </div>
  );
};

const initialState = {
  sortBy: [{ id: 'sortName' }],
};

export default DistrictReportTable;
