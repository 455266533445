import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Constants from 'Constants/Constants';

import { useApi } from 'Api/useApi';
import * as Types from './types';

export const setProducts = (payload: { [key: string]: any }) => ({
  type: Types.ALACARTE_SET_PRODUCTS,
  payload,
});

export const useALaCarteActions = () => {
  const api = useApi();
  const dispatch = useDispatch();

  const [actions] = useState({
    createOrPatchALaCarteItem: async (districtId: string, data: any) => {
      try {
        const results = (await api.post(`/districts/${districtId}/products`, data)) as any;
        if (results.status === 200) {
          toast.success(`Item has been saved successfully.`, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        }
      } catch (err) {
        console.error('err: ', err.message);
      }
    },
    getProducts: async (districtId: string) => {
      try {
        const results = (await api.get(`/districts/${districtId}/products`, {
          params: { productType: Constants.ALACARTE_KEY },
        })) as any;
        if (results && results.data) {
          dispatch(
            setProducts(
              Object.values(results?.data?.items).sort((a: any, b: any) => {
                return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
              }),
            ),
          );
        }
      } catch (err) {
        console.error(err);
      }
    },
    deleteProduct: async (districtId: string, productId: string) => {
      try {
        const results = (await api.delete(`/districts/${districtId}/products/${productId}`)) as any;
        if (results.status === 204) {
          toast.info(`Item has been deleted successfully.`, {
            position: 'bottom-right',
            autoClose: 8000,
            hideProgressBar: true,
            closeOnClick: true,
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });

  return actions;
};
