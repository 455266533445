import { get } from 'lodash';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import { DATE_FORMAT_YMD } from 'Constants/Constants';

import { AppState } from 'Interfaces/Interfaces';

const root = (state: AppState) => get(state, 'dateRangeNavigatorState');

export const getWeekOfYear = (state: AppState): number => get(root(state), 'weekOfYear', moment().week());
export const getDate = (state: AppState): moment.Moment => get(root(state), 'dayOfYear', moment());
export const getStartDate = (state: AppState): moment.Moment => get(root(state), 'startDate', moment());
export const getDayRange = (state: AppState) => get(root(state), 'dayRange');

export const getDateYMD = createSelector(getDate, (date) => date.format(DATE_FORMAT_YMD));

export const getDayRangeNoWeekends = createSelector([getDayRange], (dayRange) => {
  const dates = dayRange.dates.filter((d) => {
    return moment(d).isoWeekday() < 6;
  });

  return {
    startDate: moment(dayRange.startDate).add(1, 'day'),
    endDate: moment(dayRange.endDate).subtract(1, 'day'),
    dates,
  };
});

export const getDayRangeReverse = createSelector([getDayRange], (dayRange) => {
  const dates = [...dayRange.dates].reverse();

  return { ...dayRange, dates };
});

export const getDayRangeReverseNoWeekends = createSelector([getDayRangeNoWeekends], (dayRange) => {
  const dates = [...dayRange.dates].reverse();

  return { ...dayRange, dates };
});
