import { Env } from 'Interfaces/Interfaces';
import { globalConfig } from '../../config';

/**
 * Pull from Mozilla site with Typescript changes.
 */
function polyfillClosestIE11() {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      (Element.prototype as { msMatchesSelector?: any }).msMatchesSelector || Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function (s: any) {
      var el = this;

      do {
        if (Element.prototype.matches.call(el, s)) return el;
        // @ts-ignore
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }
}

// Load the polyfill for IE11 only. Fills `.closest()` selector.
polyfillClosestIE11();

const buttonSelector = '.button.place-order, .product-name .btn-reset';
let currentButtons: NodeList | undefined;

/**
 * Strange bug with a `position: sticky` element overflowing a focused element.
 * https://dev.azure.com/ThinkLinq/LINQ%20Online%20Ordering/_sprints/taskboard/LINQ%20Online%20Ordering%20Team/LINQ%20Online%20Ordering/Sprint%204?workitem=13582
 * @param parentNode
 */
export const watchForFocusStickyIssue = (parentNode: HTMLTableElement) => {
  return [
    cleanUp,
    async () => {
      cleanUp();
      try {
        const buttons = (await pollForButtons(parentNode)) as NodeList;
        currentButtons = buttons;

        buttons.forEach((button) => {
          button.addEventListener('focus', checkForStickyOverflow);
        });

        return () => {
          cleanUp();
        };
      } catch (e) {
        if (globalConfig.getPortalConfig().env === Env.Local) {
          console.error(e);
        }
      }
    },
  ];
};

function cleanUp() {
  // Clean up previous listeners.
  if (currentButtons) {
    currentButtons.forEach((button) => {
      button.removeEventListener('focus', checkForStickyOverflow);
    });
  }
}

/**
 * On focus of a button, check to see if it's at the beginning
 * of the table list. Always scroll to see it.
 * @param event
 */
function checkForStickyOverflow(event: Event) {
  const td = (event.target as HTMLButtonElement).closest('td');
  const tr = (event.target as HTMLButtonElement).closest('tr');
  const scrollWrapper = document.querySelector('.react-table-scroll-wrapper');

  if (tr && td && scrollWrapper) {
    // Using a set value of 3 is _very_ fragile!
    const firstButton = tr.querySelector('td:nth-child(3)');

    if (firstButton?.isEqualNode(td)) {
      scrollWrapper.scroll({ left: 0 });
    }
  }
}

/**
 * React controls the DOM. Because we are trying to track an nth number of buttons
 * using refs would not be performant. So we use the parentNode which contains the
 * nth number of buttons. Start polling till the buttons are registered inside
 * the DOM. Use maxInterval counter as an escape hatch to not bog down the DOM.
 *
 * @param parentNode
 * @param maxInterval
 */
function pollForButtons(parentNode: HTMLTableElement, maxInterval = 500) {
  return new Promise((resolve, reject) => {
    let intervalCount = 0;
    const buttons = parentNode.querySelectorAll(buttonSelector);

    if (buttons.length) {
      resolve(buttons);
    }

    const intervalId = setInterval(() => {
      intervalCount += 1;
      const buttons = parentNode.querySelectorAll(buttonSelector);

      if (buttons.length) {
        resolve(buttons);
        clearInterval(intervalId);
      }

      if (intervalCount > maxInterval) {
        reject('pollForButtons: Buttons not found! intervalCount > maxInternal');
        clearInterval(intervalId);
      }
    }, 300);
  });
}
