import React, { useCallback } from 'react';
import { useDispatch, batch } from 'react-redux';
import { Role, TokenClaims } from 'Auth/User';
import { useAuth0 } from '@auth0/auth0-react';

import DisclaimerModal from 'Components/Shop/DisclaimerModal/DisclaimerModal';
import * as DisclaimerModalActions from 'Components/Shop/DisclaimerModal/state/actions';
import { TermsOfUseModal } from 'Components/TermsOfUseModal/TermsOfUseModal';

import './Footer.less';

export default () => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const [isTermsModalOpen, setIsTermsModalOpen] = React.useState(false);

  const handleClick = useCallback(() => {
    batch(() => {
      dispatch(DisclaimerModalActions.showCloseButton(true));
      dispatch(DisclaimerModalActions.setOpen(true));
    });
  }, [dispatch]);

  return (
    <footer className="main-footer" id="footer" aria-label="Main footer for the site.">
      {userRole === Role.PARENT && (
        <div id="footer-container">
          This institution is an equal opportunity provider <span className="divider">|</span>{' '}
          <button className="btn-reset" id="ingredientsDisclaimer" onClick={handleClick}>
            Ingredients and Special Diets Disclaimer
          </button>
          <span className="divider">|</span>{' '}
          <button className="btn-reset" id="ingredientsDisclaimer" onClick={() => setIsTermsModalOpen(true)}>
            Terms of Use
          </button>
        </div>
      )}
      <DisclaimerModal />
      <TermsOfUseModal isOpen={isTermsModalOpen} handleClose={() => setIsTermsModalOpen(false)} />
    </footer>
  );
};
