import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import ReimbursableMealPricingItem from 'Components/District/ReimbursableMealPricingItem/ReimbursableMealPricingItem';
import ReimbursableMealPricingItemSidebar, {
  Fields,
} from '../ReimbursableMealPricingItem/ReimbursableMealPricingItemSidebar';
import * as DistrictHooks from 'Components/District/state/hooks';
import * as Constants from 'Constants/Constants';
import * as Interfaces from 'Interfaces/Interfaces';
import * as MenuTypePickerSelectors from 'Components/MenuTypePicker/state/selectors';
import * as CommonStateSelectors from 'State/selectors';
import * as CommonStateActions from 'State/actions';
import { TokenClaims } from 'Auth/User';
import { SidebarLocations } from 'Constants/Enums';

import './ReimbursableMealPricing.less';

const PriceFields = Object.values(Constants.SITE_GROUP_TYPES);

export const pricingGroups = [
  {
    reimbursableMealType: Constants.REIMBURSABLE_MEAL_TYPES.PAID,
  },
  {
    reimbursableMealType: Constants.REIMBURSABLE_MEAL_TYPES.REDUCED,
  },
  {
    reimbursableMealType: Constants.REIMBURSABLE_MEAL_TYPES.ADULT,
  },
];

const ReimbursableMealPricing = () => {
  const [editPricing, setEditPricing] = useState<
    | {
        reimbursableMealType: Interfaces.ReimbursableMealType;
      }
    | undefined
  >();

  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);
  const menuTypeId = useSelector(MenuTypePickerSelectors.getMenuTypeId) || '';
  const sideBarOpen = useSelector((state: Interfaces.AppState) =>
    CommonStateSelectors.getSidebarOpen(state, SidebarLocations.REIMB_PRICING_SIDE_BAR),
  );
  const dispatch = useDispatch();

  DistrictHooks.useReimbursableMealPricing(districtId, menuTypeId);

  return (
    <div className="reimbursable-meal-pricing-container">
      {pricingGroups.map(({ reimbursableMealType }: Interfaces.PricingGroup, i) => (
        <ReimbursableMealPricingItem
          key={`${reimbursableMealType.id}${i}`}
          index={i}
          reimbursableMealType={reimbursableMealType}
          onEdit={() => {
            setEditPricing({
              reimbursableMealType,
            });
            dispatch(CommonStateActions.setSidebarOpen(true, SidebarLocations.REIMB_PRICING_SIDE_BAR));
          }}
        />
      ))}
      <ReimbursableMealPricingItemSidebar
        open={sideBarOpen}
        onClose={() => {
          dispatch(CommonStateActions.setSidebarOpen(false, SidebarLocations.REIMB_PRICING_SIDE_BAR));
        }}
        fields={PriceFields as Fields[]}
        {...editPricing}
      />
    </div>
  );
};

export default ReimbursableMealPricing;
