import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MAX_ORDER_RANGES,
  MAX_ORDER_RANGE_OPTIONS,
  MAX_ORDER_UP_TO_DAYS,
  MAX_ORDER_UP_TO_DAYS_OPTIONS,
  MAX_ORDER_UP_TO_MONTHS,
  MAX_ORDER_UP_TO_MONTHS_OPTIONS,
  MAX_ORDER_UP_TO_WEEKS,
  MAX_ORDER_UP_TO_WEEKS_OPTIONS,
  ORDER_CUT_OFF_DAYS,
  ORDER_DUE_BY_MONTH,
  ORDER_DUE_BY_MONTH_OPTIONS,
  ORDER_DUE_BY_RULES,
  ORDER_DUE_BY_RULE_OPTIONS,
  ORDER_RANGES,
  ORDER_RANGE_OPTIONS,
} from 'Constants/Constants';
import { AppState, OrderingRules } from 'Interfaces/Interfaces';
import Card from 'Components/Card/Card';
import * as CommonStateActions from 'State/actions';
import * as CommonStateSelectors from 'State/selectors';
import OrderRulesSidebar from '../Sidebars/OrderingRulesSidebar';
import { SidebarLocations } from 'Constants/Enums';

export const RuleOrderTime: FC<{ orderingRules: OrderingRules }> = ({ orderingRules }) => {
  const sidebarOpen = useSelector((state: AppState) =>
    CommonStateSelectors.getSidebarOpen(state, SidebarLocations.ORDERING_RULES_SIDE_BAR),
  );
  const dispatch = useDispatch();

  const setSidebarOpen = (isOpen: boolean) => {
    dispatch(CommonStateActions.setSidebarOpen(isOpen, SidebarLocations.ORDERING_RULES_SIDE_BAR));
  };

  const orderRangeText = orderingRules?.orderRange
    ? ORDER_RANGES[orderingRules?.orderRange as ORDER_RANGE_OPTIONS].name
    : '-';
  const orderDueByText = orderingRules?.orderDueBy
    ? ORDER_DUE_BY_RULES[orderingRules?.orderDueBy as ORDER_DUE_BY_RULE_OPTIONS]?.name
    : '-';

  const orderDueByMonthlyText = orderingRules?.orderDueByMonthly
    ? ORDER_DUE_BY_MONTH[orderingRules?.orderDueByMonthly as ORDER_DUE_BY_MONTH_OPTIONS]?.name
    : '-';
  const maxOrderRangeText = orderingRules?.maxOrderRange
    ? MAX_ORDER_RANGES[orderingRules?.maxOrderRange as MAX_ORDER_RANGE_OPTIONS]?.name
    : '-';
  const maxOrderDayText =
    MAX_ORDER_UP_TO_DAYS[orderingRules?.maxCutoffDays as MAX_ORDER_UP_TO_DAYS_OPTIONS]?.name ?? '-';
  const maxOrderWeekText =
    MAX_ORDER_UP_TO_WEEKS[orderingRules?.maxCutoffWeeks as MAX_ORDER_UP_TO_WEEKS_OPTIONS]?.name ?? '-';
  const maxOrderMonthText =
    MAX_ORDER_UP_TO_MONTHS[orderingRules?.maxCutoffMonths as MAX_ORDER_UP_TO_MONTHS_OPTIONS]?.name ?? '-';

  const orderCutoffDayText = orderingRules?.cutoffDay ? ORDER_CUT_OFF_DAYS[orderingRules?.cutoffDay]?.name : '-';
  const teacherOrderRangeText = orderingRules?.teacherOrderRange
    ? ORDER_RANGES[orderingRules?.teacherOrderRange as ORDER_RANGE_OPTIONS]?.name
    : '-';
  const teacherOrderDueByText = orderingRules?.teacherOrderDueBy
    ? ORDER_DUE_BY_RULES[orderingRules?.teacherOrderDueBy as ORDER_DUE_BY_RULE_OPTIONS]?.name
    : '-';
  const teacherOrderDueByMonthlyText = orderingRules?.teacherOrderDueByMonthly
    ? ORDER_DUE_BY_MONTH[orderingRules?.teacherOrderDueByMonthly as ORDER_DUE_BY_MONTH_OPTIONS]?.name
    : '-';
  const teacherOrderCutoffDayText = orderingRules?.teacherCutoffDay
    ? ORDER_CUT_OFF_DAYS[orderingRules?.teacherCutoffDay].name
    : '-';

  const teacherMaxOrderRangeText = orderingRules?.teacherMaxOrderRange
    ? MAX_ORDER_RANGES[orderingRules?.teacherMaxOrderRange as MAX_ORDER_RANGE_OPTIONS]?.name
    : '-';
  const teacherMaxOrderDayText =
    MAX_ORDER_UP_TO_DAYS[orderingRules?.teacherMaxCutoffDays as MAX_ORDER_UP_TO_DAYS_OPTIONS]?.name ?? '-';
  const teacherMaxOrderWeekText =
    MAX_ORDER_UP_TO_WEEKS[orderingRules?.teacherMaxCutoffWeeks as MAX_ORDER_UP_TO_WEEKS_OPTIONS]?.name ?? '-';
  const teacherMaxOrderMonthText =
    MAX_ORDER_UP_TO_MONTHS[orderingRules?.teacherMaxCutoffMonths as MAX_ORDER_UP_TO_MONTHS_OPTIONS]?.name ?? '-';

  return (
    <>
      <Card
        title="Order Cutoff and Max Range"
        onEditClick={() => setSidebarOpen(true)}
        editAriaLabel="Edit Ordering Rules"
        description="Set ordering rules"
      >
        <h4>Parent Order Cutoff</h4>
        <div className="ordering-rules__rules">
          <div className="ordering-rules__setting">
            <h6 className="linq-card__h6">Order Range</h6>
            <div className="setting-value" data-test-id={'parentOrderRangeLbl'}>
              {orderRangeText}
            </div>
          </div>
          <div className="ordering-rules__setting">
            <h6 className="linq-card__h6">Cutoff Time</h6>
            <div className="setting-value" data-test-id={'parentCutOffTimeLbl'}>
              {orderingRules?.cutoffTime || '-'}
            </div>
          </div>
          <div className="ordering-rules__setting">
            {orderingRules?.orderRange === ORDER_RANGE_OPTIONS.DAILY && (
              <>
                <h6 className="linq-card__h6">Must Place Order</h6>
                <div className="setting-value" data-test-id={'parentMustPlaceOrderLbl'}>
                  {orderDueByText}
                </div>
              </>
            )}
            {orderingRules?.orderRange === ORDER_RANGE_OPTIONS.WEEKLY && (
              <>
                <h6 className="linq-card__h6">Cut Off Day</h6>
                <div className="setting-value" data-test-id={'parentCutOffDayLbl'}>
                  {orderCutoffDayText}
                </div>
              </>
            )}
            {orderingRules?.orderRange === ORDER_RANGE_OPTIONS.MONTHLY && (
              <>
                <h6 className="linq-card__h6">Must Place Order</h6>
                <div className="setting-value" data-test-id={'parentMustPlaceOrderLbl'}>
                  {orderDueByMonthlyText}
                </div>
              </>
            )}
          </div>
        </div>
        <h4 className="order-range">Parent Max Order Range</h4>
        <div className="ordering-rules__rules">
          <div className="ordering-rules__setting">
            <h6 className="linq-card__h6"> Order Range</h6>
            <div className="setting-value" data-test-id={'parentMaxOrderRangeLbl'}>
              {maxOrderRangeText}
            </div>
          </div>
          <div className="ordering-rules__setting">
            <h6 className="linq-card__h6">Orders may be placed up to</h6>
            {!orderingRules?.maxOrderRange && (
              <div className="setting-value" data-test-id={'parentMayPlaceUpToLbl'}>
                -
              </div>
            )}
            {orderingRules?.maxOrderRange === MAX_ORDER_RANGE_OPTIONS.DAYS && (
              <div className="setting-value" data-test-id={'parentMayPlaceUpToLbl'}>
                {maxOrderDayText}
              </div>
            )}
            {orderingRules?.maxOrderRange === MAX_ORDER_RANGE_OPTIONS.WEEKS && (
              <div className="setting-value" data-test-id={'parentMayPlaceUpToLbl'}>
                {maxOrderWeekText}
              </div>
            )}
            {orderingRules?.maxOrderRange === MAX_ORDER_RANGE_OPTIONS.MONTHS && (
              <div className="setting-value" data-test-id={'parentMayPlaceUpToLbl'}>
                {maxOrderMonthText}
              </div>
            )}
          </div>
          <div className="ordering-rules__setting"></div>
        </div>

        <h4 style={{ paddingTop: '1.33rem' }}>Teacher Order Cutoff</h4>
        <div className="ordering-rules__rules">
          <div className="ordering-rules__setting">
            <h6 className="linq-card__h6">Order Range</h6>
            <div className="setting-value" data-test-id={'teacherOrderRangeLbl'}>
              {teacherOrderRangeText}
            </div>
          </div>
          <div className="ordering-rules__setting">
            <h6 className="linq-card__h6">Cutoff Time</h6>
            <div className="setting-value" data-test-id={'teacherCutOffTimeLbl'}>
              {orderingRules?.teacherCutoffTime || '-'}
            </div>
          </div>
          <div className="ordering-rules__setting">
            {orderingRules?.teacherOrderRange === ORDER_RANGE_OPTIONS.DAILY && (
              <>
                <h6 className="linq-card__h6">Must Place Order</h6>
                <div className="setting-value" data-test-id={'teacherMustPlaceOrderLbl'}>
                  {teacherOrderDueByText}
                </div>
              </>
            )}
            {orderingRules?.teacherOrderRange === ORDER_RANGE_OPTIONS.WEEKLY && (
              <>
                <h6 className="linq-card__h6">Cut Off Day</h6>
                <div className="setting-value" data-test-id={'teacherCutOffDayLbl'}>
                  {teacherOrderCutoffDayText}
                </div>
              </>
            )}
            {orderingRules?.teacherOrderRange === ORDER_RANGE_OPTIONS.MONTHLY && (
              <>
                <h6 className="linq-card__h6">Must Place Order</h6>
                <div className="setting-value" data-test-id={'teacherMustPlaceOrderLbl'}>
                  {teacherOrderDueByMonthlyText}
                </div>
              </>
            )}
          </div>
        </div>
        <h4 style={{ paddingTop: '1.33rem' }}>Teacher Max Order Range</h4>
        <div className="ordering-rules__rules">
          <div className="ordering-rules__setting">
            <h6 className="linq-card__h6">Order Range</h6>
            <div className="setting-value" data-test-id={'teacherMaxOrderRangeLbl'}>
              {teacherMaxOrderRangeText}
            </div>
          </div>
          <div className="ordering-rules__setting">
            <h6 className="linq-card__h6">Orders may be placed up to</h6>
            {!orderingRules?.teacherMaxOrderRange && (
              <div className="setting-value" data-test-id={'teacherMayPlaceUpToLbl'}>
                -
              </div>
            )}
            {orderingRules?.teacherMaxOrderRange === MAX_ORDER_RANGE_OPTIONS.DAYS && (
              <div className="setting-value" data-test-id={'teacherMayPlaceUpToLbl'}>
                {teacherMaxOrderDayText}
              </div>
            )}
            {orderingRules?.teacherMaxOrderRange === MAX_ORDER_RANGE_OPTIONS.WEEKS && (
              <div className="setting-value" data-test-id={'teacherMayPlaceUpToLbl'}>
                {teacherMaxOrderWeekText}
              </div>
            )}
            {orderingRules?.teacherMaxOrderRange === MAX_ORDER_RANGE_OPTIONS.MONTHS && (
              <div className="setting-value" data-test-id={'teacherMayPlaceUpToLbl'}>
                {teacherMaxOrderMonthText}
              </div>
            )}
          </div>
          <div className="ordering-rules__setting"></div>
        </div>
      </Card>
      <OrderRulesSidebar open={sidebarOpen} setSidebarOpen={setSidebarOpen} orderingRules={orderingRules} />
    </>
  );
};
