import { useEffect } from 'react';

// This is done to prevent unnecessary scroll bars and to prevent confusion when scrolling
// (Some times scrolling in the side bar would scroll the body)
export const useBodyOverflow = (active: boolean) => {
  useEffect(() => {
    if (active) {
      document.body.classList.add('dialog-overflow');
    } else {
      document.body.classList.remove('dialog-overflow');
    }
  }, [active]);
};
