import { Reducer } from 'react';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';
import { cloneDeep, flatten, get, set } from 'lodash';
import { generateCartId } from 'Helpers/Helper';

const initialState: Interfaces.TeacherReportState = {
  report: {},
  entreeSidebarOpen: false,
  entree: null,
  selectedSides: {},
  showCart: false,
  cart: {},
  bulkCart: {},
  products: {},
  isBulkMode: false,
  cartHomerooms: {},
};

const TeacherReportReducer: Reducer<Interfaces.TeacherReportState, Interfaces.Action> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case Types.TEACHER_REPORT_SET_REPORT:
      return {
        ...state,
        report: payload,
      };

    case Types.TEACHER_REPORT_SET_ENTREE_SIDEBAR_OPEN:
      return { ...state, entreeSidebarOpen: payload };

    case Types.TEACHER_REPORT_SELECT_ENTREE:
      return { ...state, entree: payload };

    case Types.TEACHER_REPORT_SET_SELECTED_SIDES:
      return { ...state, selectedSides: payload };

    case Types.TEACHER_REPORT_SET_SHOW_CART:
      return { ...state, showCart: payload };

    case Types.TEACHER_REPORT_ADD_TO_CART: {
      const { selectedStudents, date, menuTypeId, accountId, homeroomId } = payload;
      const cart = cloneDeep(state.cart);
      const newCartHomerooms = cloneDeep(state.cartHomerooms) || {};
      const accountCart = cart[accountId] || {};

      if (state.entree) {
        Object.entries(selectedStudents).forEach(([studentId, selected]) => {
          if (selected) {
            const entree = { ...state.entree } as Interfaces.MenuItem;
            const sideItems = flatten(Object.values(state.selectedSides));
            const cartId = generateCartId([entree, ...sideItems]);

            if (!accountCart[date]) {
              accountCart[date] = {};
            }
            if (!accountCart[date][menuTypeId]) {
              accountCart[date][menuTypeId] = {};
            }
            if (!accountCart[date][menuTypeId][cartId]) {
              accountCart[date][menuTypeId][cartId] = {
                entree,
                sides: { ...state.selectedSides },
                studentIds: [],
              };
            }

            accountCart[date][menuTypeId][cartId] = {
              ...accountCart[date][menuTypeId][cartId],
              studentIds: [...accountCart[date][menuTypeId][cartId].studentIds, studentId],
            };
          }
        });

        cart[accountId] = accountCart;
        newCartHomerooms[accountId] = homeroomId;
      }

      return { ...state, cart, entree: null, selectedSides: {}, cartHomerooms: newCartHomerooms };
    }

    case Types.TEACHER_REPORT_REMOVE_FROM_CART: {
      const cart = cloneDeep(state.cart);
      const { studentId, date, cartId, menuTypeId, accountId } = payload;
      const accountCart = cart[accountId];
      const newCartHomerooms = cloneDeep(state.cartHomerooms);

      if (accountCart[date][menuTypeId]) {
        const cartItem = accountCart[date][menuTypeId][cartId];

        if (cartItem) {
          accountCart[date][menuTypeId][cartId].studentIds = accountCart[date][menuTypeId][cartId].studentIds.filter(
            (sId) => sId !== studentId,
          );

          if (accountCart[date][menuTypeId][cartId].studentIds.length === 0) {
            delete accountCart[date][menuTypeId][cartId];
          }

          if (Object.keys(accountCart[date][menuTypeId]).length === 0) {
            delete accountCart[date][menuTypeId];
          }

          if (Object.keys(accountCart[date]).length === 0) {
            delete accountCart[date];
          }
        }

        if (Object.keys(accountCart).length === 0) {
          delete cart[accountId];
          delete newCartHomerooms[accountId];
        } else {
          cart[accountId] = accountCart;
        }
      }

      return { ...state, cart, cartHomerooms: newCartHomerooms };
    }

    case Types.TEACHER_REPORT_REMOVE_CART_DATE: {
      const { date, accountId } = payload;

      const cart = cloneDeep(state.cart);
      const accountCart = cart[accountId];

      const newCartHomerooms = cloneDeep(state.cartHomerooms);

      if (accountCart) {
        delete accountCart[date];

        if (Object.keys(accountCart).length === 0) {
          delete cart[accountId];
        } else {
          cart[accountId] = accountCart;
        }
      }

      const bulkCart = cloneDeep(state.bulkCart);

      const accountBulkCart = bulkCart[accountId];

      if (accountBulkCart) {
        delete accountBulkCart[date];

        if (Object.keys(accountBulkCart).length === 0) {
          delete accountBulkCart[accountId];
          delete newCartHomerooms[accountId];
        } else {
          bulkCart[accountId] = accountBulkCart;
        }
      }

      return { ...state, cart, bulkCart, cartHomerooms: newCartHomerooms };
    }

    case Types.TEACHER_REPORT_DELETE_ORDER_ITEM: {
      const orderItem = payload as Interfaces.OrderItem;
      const report = cloneDeep(state.report);

      const studentsList = report[orderItem.mealTypeId];
      const student = studentsList.find((studentOrder: any) => studentOrder.studentId === orderItem.studentId);
      const order = get(student, ['orders', orderItem.date]);

      if (order) {
        order.orderItems = order.orderItems.filter((item: Partial<Interfaces.OrderItem>) => item.id !== orderItem.id);

        if (order.orderItems.length === 0) {
          delete student.orders[orderItem.date];
        }
      }

      return { ...state, report };
    }

    case Types.TEACHER_REPORT_SET_REPORT_PRODUCTS:
      return { ...state, products: payload };

    case Types.TEACHER_REPORT_SET_BULK_MODE:
      return { ...state, isBulkMode: payload };

    case Types.TEACHER_REPORT_ADD_TO_BULK_CART: {
      return addToBulkCart(state, payload);
    }

    case Types.TEACHER_REPORT_REMOVE_BULK_ITEM: {
      const {
        date,
        menuTypeId,
        productId,
        accountId,
      }: { date: string; menuTypeId: string; productId: string; accountId: string } = payload;
      const newBulkCart = cloneDeep(state.bulkCart);
      const newCartHomerooms = cloneDeep(state.cartHomerooms);

      if (newBulkCart?.[accountId]?.[date]?.[menuTypeId]?.[productId]) {
        delete newBulkCart[accountId][date][menuTypeId][productId];

        if (Object.keys(newBulkCart[accountId][date][menuTypeId]).length === 0) {
          delete newBulkCart[accountId][date][menuTypeId];
        }

        if (Object.keys(newBulkCart[accountId][date]).length === 0) {
          delete newBulkCart[accountId][date];
          delete newCartHomerooms[accountId];
        }
      }

      return { ...state, bulkCart: newBulkCart, cartHomerooms: newCartHomerooms };
    }

    case Types.TEACHER_REPORT_ADD_OR_SUBTRACT_BULK_ITEM_QUANTITY: {
      const {
        date,
        productId,
        quantity,
        accountId,
        menuTypeId,
      }: { date: string; productId: string; quantity: number; accountId: string; menuTypeId: string } = payload;
      const newBulkCart = cloneDeep(state.bulkCart);

      if (newBulkCart?.[accountId]?.[date]?.[menuTypeId]?.[productId]) {
        newBulkCart[accountId][date][menuTypeId][productId].quantity = quantity;
      }

      return { ...state, bulkCart: newBulkCart };
    }

    case Types.TEACHER_REPORT_CLEAR_CART: {
      const accountId = payload;
      const cart = cloneDeep(state.cart);
      const bulkCart = cloneDeep(state.bulkCart);
      const newCartHomerooms = cloneDeep(state.cartHomerooms);

      delete cart[accountId];
      delete bulkCart[accountId];
      newCartHomerooms && delete newCartHomerooms[accountId];

      return { ...state, bulkCart, cart, cartHomerooms: newCartHomerooms };
    }

    case Types.TEACHER_REPORT_CLEAR_BULK_CART: {
      const accountId = payload;
      const bulkCart = cloneDeep(state.bulkCart);

      delete bulkCart[accountId];

      return { ...state, bulkCart };
    }

    default:
      return state;
  }
};

const addToBulkCart = (state: Interfaces.TeacherReportState, payload: any) => {
  const bulkOrderCart = payload.bulkOrderCart as Interfaces.TeacherBulkOrderingCart;
  const accountId = payload.accountId as string;
  const homeroomId = payload.homeroomId as string;
  const newBulkCart = cloneDeep(state.bulkCart);
  const newCartHomerooms = cloneDeep(state.cartHomerooms) || {};

  Object.entries(bulkOrderCart).forEach(([date, menuTypeItems]) => {
    Object.entries(menuTypeItems).forEach(([menuTypeId, bulkOrderItems]) => {
      if (!newBulkCart[accountId] || !newBulkCart[accountId][date] || !newBulkCart[accountId][date][menuTypeId]) {
        set(newBulkCart, [accountId, date, menuTypeId], {});
      }

      Object.entries(bulkOrderItems).forEach(([menuItemId, bulkOrderItem]) => {
        if (!newBulkCart[accountId][date][menuTypeId][menuItemId]) {
          newBulkCart[accountId][date][menuTypeId][menuItemId] = bulkOrderItem;
        } else if (newBulkCart[accountId][date][menuTypeId][menuItemId]) {
          newBulkCart[accountId][date][menuTypeId][menuItemId].quantity =
            newBulkCart[accountId][date][menuTypeId][menuItemId].quantity + bulkOrderItem.quantity;
        }
      });
    });
  });

  newCartHomerooms[accountId] = homeroomId;

  return { ...state, bulkCart: newBulkCart, cartHomerooms: newCartHomerooms };
};

export default TeacherReportReducer;
