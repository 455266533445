import React, { ReactElement } from 'react';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import ReimbursableMealPricing from 'Components/District/ReimbursableMealPricing/ReimbursableMealPricing';
import MenuTypePicker from 'Components/MenuTypePicker/MenuTypePicker';

import './PricingManagement.less';

const PricingManagement = (): ReactElement => {
  return (
    <div className="pricing-management-container">
      <SubHeader title="Pricing Management" />
      <div className="body">
        <div className="picker-container">
          <MenuTypePicker shouldGetDistrictMenuTypes={true} />
        </div>
        <ReimbursableMealPricing />
      </div>
    </div>
  );
};

export default PricingManagement;
