import { Product } from 'Interfaces/Interfaces';

enum SortPriorities {
  ENTREE = 0,
  GRAIN,
  VEGETABLE,
  FRUIT,
  MILK,
  ALACARTE,
}

export const sortItems = <T>(items: T[], productSelector: { (item: T): Product }): T[] => {
  return items.sort((a, b) => {
    const prodA = productSelector(a);
    const prodB = productSelector(b);

    const orderA = SortPriorities[prodA?.productCategoryId as keyof typeof SortPriorities];
    const orderB = SortPriorities[prodB?.productCategoryId as keyof typeof SortPriorities];

    if (orderA !== orderB) {
      return orderA - orderB;
    }
    return prodA?.name?.localeCompare(prodB?.name);
  });
};

export const doesRowHaveOrderItems = (row: { [key: string]: number }) => {
  return Object.keys(row).some((key: string) => row[key] > 0);
};
