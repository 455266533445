import React from 'react';
import { useSelector } from 'react-redux';
import { Field, FieldProps, Form, Formik, FormikProps, FormikValues } from 'formik';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import classNames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import { Cancel, CheckCircle, DateRange } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { TokenClaims } from 'Auth/User';
import { useApi } from 'Api/useApi';
import { DATE_FNS_DATE_AND_TIME_FORMAT_USA, ToastConfig, ToastType } from 'Constants/Constants';
import { SwitchField } from 'Components/Form/MaterialForm';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import { CalloutCard } from 'Components/CalloutCard/CalloutCard';
import MultiActionCard from 'Components/MultiActionCard/MultiActionCard';
import ReactTableV2 from 'Components/ReactTable/ReactTableV2/ReactTableV2';
import { ButtonLink } from 'Components/ButtonLink/ButtonLink';
import { Autocomplete } from 'Components/Form/MaterialForm/AutoComplete';
import { NotificationDialog } from 'Components/Dialogs/NotificationDialog';
import * as CommonSelectors from 'State/selectors';
import { useDistrict } from 'State/hooks';
import { CloseSchoolYearLog } from 'Interfaces/Interfaces';
import { downloadAsCsv } from 'Helpers/Helper';

import './CloseSchoolYear.less';

const Synthesis = () => {
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);
  const districtLoading = useDistrict(districtId);
  const gradeOptions = useSelector(CommonSelectors.getGradeOptions(districtId, true));
  const [showHistory, setShowHistory] = React.useState(false);
  const [historyLogs, setHistoryLogs] = React.useState<CloseSchoolYearLog[] | null>(null);
  const [polling, setPolling] = React.useState(false);
  const [pollCount, setPollCount] = React.useState(0);
  const [getReportLoading, setGetReportLoading] = React.useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);
  const pollInterval = 10000; // 10 seconds in milliseconds
  const maxPollCount = 18; // 18 x 5 seconds for a max poll time of around 3 minutes

  const api = useApi();

  const stopPolling = React.useCallback(() => {
    setPollCount(0);
    setPolling(false);
  }, []);

  const poll = React.useCallback(() => {
    setPollCount(pollCount + 1);
  }, [pollCount]);

  const getReport = React.useCallback(
    (fileName: string) => {
      const fetchReport = async () => {
        try {
          setGetReportLoading(true);
          const results = (await api.get(`/utility/districts/${districtId}/csyReport/${fileName}`)) as any;
          downloadAsCsv(results.data, `${fileName}`);
        } catch (err) {
          console.log(err);
        } finally {
          setGetReportLoading(false);
        }
      };
      fetchReport();
    },
    [api, districtId],
  );

  // Get initial history on page load
  React.useEffect(() => {
    const fetchInitialHistory = async () => {
      const response: AxiosResponse = await api.get(`utility/districts/${districtId}/csyLogs`);

      setHistoryLogs(response.data.items ? Object.values(response.data.items) : []);
    };

    fetchInitialHistory();
    // eslint-disable-next-line
  }, []);

  // Poll effect. Poll for history when pole count is incremented
  React.useEffect(() => {
    const getHistory = async () => {
      try {
        const response: AxiosResponse = await api.get(`utility/districts/${districtId}/csyLogs`);
        const fetchedHistoryLogs: CloseSchoolYearLog[] = response.data.items ? Object.values(response.data.items) : [];

        if (polling && historyLogs && historyLogs.length < fetchedHistoryLogs.length) {
          stopPolling();
          toast.success(
            'Close school year complete. Please see history tab for results.',
            ToastConfig[ToastType.Success],
          );
        } else if (polling) {
          poll();
        }

        setHistoryLogs(fetchedHistoryLogs);
      } catch (err) {
        console.log(err);
      }
    };

    if (pollCount && pollCount < maxPollCount) {
      setTimeout(() => {
        getHistory();
      }, pollInterval);
    }
  }, [pollCount, districtId, api, polling, historyLogs, stopPolling, poll]);

  const initialHistoryTableState = {
    disableSortRemove: false,
    disableMultiRemove: true,
    sortBy: [{ id: 'date', desc: true }],
  };

  const handleSubmit = async (formValues: { graduatingGrade?: string; deleteWithdrawn?: boolean }) => {
    setPolling(true);
    toast.info('Close school year job has been enqueued', ToastConfig[ToastType.Success]);
    setShowConfirmationDialog(false);
    await api.post(`utility/districts/${districtId}/closeSchoolYear`, {
      ...formValues,
    });
    poll();
  };

  const handleValidation = ({ graduatingGrade, deleteWithdrawn }: FormikValues) => {
    const errors: any = {};

    if (!graduatingGrade && !deleteWithdrawn) {
      const selectAtLeastOneMessage = 'At least one option must be selected.';
      errors.graduatingGrade = selectAtLeastOneMessage;
      errors.deleteWithdrawn = selectAtLeastOneMessage;
    }

    return errors;
  };

  return (
    <React.Fragment>
      <SubHeader title="Close School Year" />
      <div className="view-picker-container">
        <button
          onClick={() => setShowHistory(false)}
          className={classNames('btn-reset', { selected: !showHistory })}
          id="close-school-year-tab-selector"
          data-test-id="usersTabBtn"
        >
          Close School Year
        </button>
        <button
          onClick={() => setShowHistory(true)}
          className={classNames('btn-reset', { selected: showHistory })}
          id="history-tab-selector"
          data-test-id="invitesTabBtn"
        >
          History
        </button>
      </div>

      <Formik
        enableReinitialize
        onSubmit={() => {
          setShowConfirmationDialog(true);
        }}
        initialValues={{ graduatingGrade: '', deleteWithdrawn: false }}
        validate={handleValidation}
      >
        {({
          setFieldValue,
          handleReset,
          values,
        }: FormikProps<{ graduatingGrade: string; deleteWithdrawn: boolean }>) => (
          <Form>
            {!showHistory && (
              <div className="page-content-wrapper">
                <div className="card-container">
                  <MultiActionCard
                    cardClassname="close-school-year-card"
                    title={'Close School Year Options'}
                    description={'Please select the desired close school year options'}
                    cancelActionLabel={'clear'}
                    onCancelAction={handleReset}
                    cancelActionAriaLabel={'clear'}
                    loading={polling || districtLoading}
                    submitLabel={'Close School Year'}
                    submitDisabled={districtLoading}
                    submitAriaLabel={'Submit'}
                  >
                    <Field name={'graduatingGrade'}>
                      {({ field, meta }: FieldProps) => (
                        <Autocomplete
                          options={gradeOptions}
                          id={'graduatingGrade'}
                          label={'Graduating Grade'}
                          value={field.value}
                          errorMsg={meta.error}
                          touched={meta.touched}
                          loading={districtLoading}
                          onChange={(e, selectedOption) => {
                            setFieldValue('graduatingGrade', selectedOption?.value);
                          }}
                        />
                      )}
                    </Field>
                    <Field type="checkbox" name="deleteWithdrawn">
                      {({ field, meta }: FieldProps) => (
                        <SwitchField
                          id={'deleteWithdrawn'}
                          className="offer-only-a-la-carte"
                          value={field.value || false}
                          onChange={field.onChange}
                          name="deleteWithdrawn"
                          color="primary"
                          fieldLabel={'Delete Withdrawn Students'}
                          error={!!meta.error}
                          errorMsg={meta.error}
                        />
                      )}
                    </Field>
                  </MultiActionCard>
                  <div>
                    <CalloutCard
                      title="About Close School Year"
                      details={
                        "Directors will have the ability to 'Close School Year' to clean up student data and prepare for the next school year.  The process removes 'Graduating' students and/or 'Withdrawn' students from Online Ordering based on selected options. Students are removed from the Parent Order screen, the Teacher Order screen and Account Management screen.  All imports are disabled during the Close School Year run.  A report with results and a confirmation email are provided after the Close School Year is complete."
                      }
                      icon={<DateRange />}
                    />
                  </div>
                </div>
              </div>
            )}
            <NotificationDialog
              title={'Close School Year?'}
              message={`Are you sure you want to close the school year?${
                values.graduatingGrade ? ' All Graduating students will be deleted.' : ''
              }${values.deleteWithdrawn ? ' All Withdrawn students will be deleted.' : ''}`}
              showDialog={showConfirmationDialog}
              showCancel={true}
              cancelButtonText="CANCEL"
              okButtonText="Close School Year"
              onOkClick={() => {
                handleSubmit(values);
              }}
              onCancelClick={() => setShowConfirmationDialog(false)}
              dialogType="info"
              okIcon="Close School Year"
            />
          </Form>
        )}
      </Formik>

      {showHistory && (
        <div className="table-container">
          <ReactTableV2
            data={historyLogs || []}
            loading={getReportLoading}
            initialState={initialHistoryTableState}
            disableSearch={true}
            disableFilter={true}
            columns={[
              {
                Header: 'Date',
                accessor: 'date',
                width: 300,
                Cell: (props: any) => {
                  return <span>{format(new Date(props.row.original.date), DATE_FNS_DATE_AND_TIME_FORMAT_USA)}</span>;
                },
              },
              { Header: 'Graduated Students Deleted', accessor: 'graduatingDeleted', disableSortBy: true, width: 250 },
              { Header: 'Withdrawn Students Deleted', accessor: 'withdrawnDeleted', disableSortBy: true, width: 250 },
              { Header: 'User', accessor: 'user', width: 250 },
              {
                Header: 'Report',
                id: 'report',
                disableSortBy: true,
                Cell: (props: any) => {
                  const fileName = props.row.original.reportFileName;
                  return (
                    <ButtonLink
                      text={fileName}
                      onClick={() => {
                        getReport(fileName);
                      }}
                    />
                  );
                },
                width: 300,
              },
              {
                Header: 'Status',
                accessor: 'status',
                Cell: (props: any) => {
                  if (props.row.original.status === 'Success') {
                    return (
                      <>
                        <CheckCircle className="check-icon" />
                        &nbsp;SUCCESS
                      </>
                    );
                  }
                  return (
                    <>
                      <Cancel className="error-icon" />
                      &nbsp;ERROR
                    </>
                  );
                },
                width: 200,
              },
              {
                Header: 'MESSAGE',
                accessor: 'message',
                width: 300,
                Cell: (props: any) => {
                  return props.row.original.message || '';
                },
                disableSortBy: true,
              },
            ]}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default Synthesis;
