import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import * as Constants from 'Constants/Constants';
import * as Interfaces from 'Interfaces/Interfaces';
import numeral from 'numeral';
import React, { ReactElement } from 'react';
import './NutritionFacts.less';

const NutritionFacts = ({ product }: Interfaces.NutritionFactProps): ReactElement => {
  if (!product) {
    return <div></div>;
  }

  const { nutritionFacts } = product;

  return (
    <TableContainer className="nutrition-facts-container">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="no-border">Serving Size</TableCell>
            <TableCell className="value no-border">
              {nutritionFacts.servingSize} {nutritionFacts.servingSizeUnitMeasure}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="label thick-border">
              Calories<p>Amount Per Serving</p>
            </TableCell>
            <TableCell className="value thick-border calories bolded">
              {numeral(nutritionFacts.calories).format(Constants.NUMBER)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="label thick-border">Total Fat</TableCell>
            <TableCell className="value thick-border bolded">
              {numeral(nutritionFacts.totalFat).format(Constants.NUMBER_WITH_DECIMAL)}g
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="label secondary">Saturated Fat</TableCell>
            <TableCell className="value bolded">
              {numeral(nutritionFacts.saturatedFat).format(Constants.NUMBER_WITH_DECIMAL)}g
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="label secondary">
              <span className="trans-fat-label bolded">Trans</span> Fat
            </TableCell>
            <TableCell className="value bolded">
              {numeral(nutritionFacts.transFat).format(Constants.NUMBER_WITH_DECIMAL)}g
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="label">Cholesterol</TableCell>
            <TableCell className="value bolded">
              {numeral(nutritionFacts.cholesterol).format(Constants.NUMBER_WITH_DECIMAL)}mg
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="label">Sodium</TableCell>
            <TableCell className="value bolded">
              {numeral(nutritionFacts.sodium).format(Constants.NUMBER_WITH_DECIMAL)}mg
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="label">Carbohydrates</TableCell>
            <TableCell className="value bolded">
              {numeral(nutritionFacts.carbohydrates).format(Constants.NUMBER_WITH_DECIMAL)}g
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="label secondary">Dietary Fiber</TableCell>
            <TableCell className="value bolded">
              {numeral(nutritionFacts.dietaryFiber).format(Constants.NUMBER_WITH_DECIMAL)}g
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="label secondary">Total Sugars</TableCell>
            <TableCell className="value bolded">
              {numeral(nutritionFacts.sugar).format(Constants.NUMBER_WITH_DECIMAL)}g
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="label">Protein</TableCell>
            <TableCell className="value bolded">
              {numeral(nutritionFacts.protein).format(Constants.NUMBER_WITH_DECIMAL)}g
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="label thick-border">Vitamin D</TableCell>
            <TableCell className="value thick-border bolded">
              {numeral(nutritionFacts.vitaminD).format(Constants.NUMBER_WITH_DECIMAL)}mcg
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="label">Calcium</TableCell>
            <TableCell className="value bolded">
              {numeral(nutritionFacts.calcium).format(Constants.NUMBER_WITH_DECIMAL)}mg
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="label">Iron</TableCell>
            <TableCell className="value bolded">
              {numeral(nutritionFacts.iron).format(Constants.NUMBER_WITH_DECIMAL)}mg
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="label thick-border-bottom">Potassium</TableCell>
            <TableCell className="value bolded thick-border-bottom">
              {numeral(nutritionFacts.potassium).format(Constants.NUMBER_WITH_DECIMAL)}mg
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {product && product.productFormulationPDFUrl && (
        <a
          href={product?.productFormulationPDFUrl}
          className="nutritional-link"
          rel="noopener noreferrer"
          target={'_blank'}
        >
          Product Formulation (PDF)
        </a>
      )}
      <div className="additional-info">
        The nutrition information provided in this analysis should not be used for people with a medical condition or
        food allergy. Ingredients and menu items are subject to change or substitution without notice. In planning and
        treating allergies and medical conditions, please contact the district regarding their individual policy for
        accommodating special dietary needs.
      </div>
    </TableContainer>
  );
};

export default NutritionFacts;
