import React, { FC, useState } from 'react';
import { FormControlLabel, FormControl, RadioGroup, Radio } from '@material-ui/core';
import { Formik, Form, FormikValues, Field, FieldProps, FormikProps } from 'formik';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'Components/Drawer';
import { SidebarLocations } from 'Constants/Enums';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import Button from 'Components/Button/Button';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';
import { useCommonActions } from 'State/actions';
import { OrderingRules } from 'Interfaces/Interfaces';
import { AllowOrderCommentsLabel, DoNotAllowOrderCommentsLabel } from 'Constants/Constants';

export interface OrderCommentsSidebarProps {
  open: boolean;
  orderingRules: OrderingRules;
  setSidebarOpen: (open: boolean) => void;
}

const OrderCommentsSidebar: FC<OrderCommentsSidebarProps> = ({ open = false, orderingRules, setSidebarOpen }) => {
  const { updateDistrict } = useCommonActions();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async ({ allowOrderComments }: FormikValues) => {
    setLoading(true);
    const updatedDistrict = {
      orderingRules: {
        ...orderingRules,
        allowOrderComments: JSON.parse(allowOrderComments),
      },
    };
    await updateDistrict(updatedDistrict);
    setLoading(false);
    setSidebarOpen(false);
  };

  const initialValues = React.useMemo(() => {
    return {
      allowOrderComments: orderingRules?.allowOrderComments?.toString() ?? 'false',
    };
  }, [orderingRules]);

  return (
    <Drawer open={open} id="online-ordering-card-labels" anchor="right" className="ordering-rules-sidebar-container">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleReset }: FormikProps<any>) => {
          return (
            <Form className="linq-drawer-form-content oo-drawer-form">
              <DrawerHeader>
                <h2>Edit Order Comments Rule</h2>
                <CloseIconButton
                  onClick={() => {
                    setSidebarOpen(false);
                  }}
                />
              </DrawerHeader>
              <NotificationBanner location={SidebarLocations.ORDERING_COMMENTS_RULE_SIDE_BAR} />
              <DrawerContent>
                <section>
                  <p>Do you want to allow comments to be added to orders?</p>
                  <Field name="allowOrderComments">
                    {({ field }: FieldProps) => (
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="Do you want to allow comments to be added to orders?"
                          name="allowOrderComments"
                          onChange={field.onChange}
                          value={field.value}
                        >
                          <FormControlLabel
                            value={'true'}
                            control={
                              <Radio aria-label={AllowOrderCommentsLabel} data-test-id="includeAllergensOnLabelBtn" />
                            }
                            label={AllowOrderCommentsLabel}
                          />
                          <FormControlLabel
                            value={'false'}
                            control={
                              <Radio
                                aria-label={DoNotAllowOrderCommentsLabel}
                                data-test-id="dontIncludeAllergensOnLabelBtn"
                              />
                            }
                            label={DoNotAllowOrderCommentsLabel}
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Field>
                </section>
              </DrawerContent>
              <DrawerFooter>
                <Button
                  type="button"
                  color="primary"
                  variant="outlined"
                  disabled={loading}
                  onClick={() => {
                    setSidebarOpen(false);
                    handleReset();
                  }}
                  data-test-id="cancelBtn"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  aria-label="Submit"
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  data-test-id="submitBtn"
                >
                  Save
                </Button>
              </DrawerFooter>
            </Form>
          );
        }}
      </Formik>
    </Drawer>
  );
};

export default OrderCommentsSidebar;
