import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { FormControl } from '@material-ui/core';
import { useAuthActions } from 'Auth/actions';
import { TokenClaims } from 'Auth/User';
import { AutocompleteDropdown, SelectField } from 'Components/Form/MaterialForm';
import MultiActionCard from 'Components/MultiActionCard/MultiActionCard';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import Modal from 'Components/Modal/Modal';
import Button from 'Components/Button/Button';
import * as Constants from 'Constants/Constants';
import { useFormik } from 'formik';
import * as Interfaces from 'Interfaces/Interfaces';
import { sortBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCommonActions } from 'State/actions';
import * as CommonSelectors from 'State/selectors';
import * as TeacherRosterOrderingActions from '../TeacherRosterOrdering/state/actions';
import * as TeacherRosterOrderingSelectors from '../TeacherRosterOrdering/state/selectors';
import * as yup from 'yup';

import './AccountConfiguration.less';

const AccountConfiguration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { getHomerooms, getSites } = useCommonActions();

  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const userId = TokenClaims.getUserId(user);
  const homerooms = useSelector(CommonSelectors.getHomerooms);
  const districtId = TokenClaims.getDistrictId(user);
  const siteOptions = useSelector(CommonSelectors.getSiteOptions);
  const authActions = useAuthActions();
  const cartHomeroomId = useSelector(TeacherRosterOrderingSelectors.getAccountCartHoomroom(userId));
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);

  const userData = useMemo(
    () =>
      ({
        siteId: TokenClaims.getSiteId(user),
        homeroomId: TokenClaims.getHomeroomId(user),
      } as { [key: string]: string }),
    [user],
  );

  useEffect(() => {
    getSites(districtId);
  }, [dispatch, districtId, getSites]);

  const requiredAttributes = Constants.USER_REQUIRED_ATTRIBUTES[userRole];

  const { initialValues, validateValues } = useMemo(() => {
    const initialValues: { [key: string]: string } = {};
    const validateValues: { [key: string]: any } = {};
    requiredAttributes?.forEach((attribute) => {
      initialValues[attribute] = userData[attribute];
      validateValues[attribute] = yup.string().required('Required Field');
    });
    return { initialValues, validateValues };
  }, [requiredAttributes, userData]);

  const handleContinue = async () => {
    dispatch(TeacherRosterOrderingActions.clearCart(userId));
    setIsConfirmModalOpen(false);

    await handleSubmit(formik.values);
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    await authActions.patchUser(values);
    setLoading(false);
    navigate('/');
  };

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object().shape(validateValues),
    onSubmit: async (values) => {
      if (cartHomeroomId && values.homeroomId !== cartHomeroomId) {
        setIsConfirmModalOpen(true);
        return;
      }

      await handleSubmit(values);
    },
    enableReinitialize: true,
  });

  const homeroomOptions = useMemo(
    () =>
      sortBy(Object.values(homerooms), 'name').reduce((acc, homeroom) => {
        acc.push({
          text: homeroom.id,
          value: homeroom.id,
        });
        return acc;
      }, [] as Interfaces.MaterialSelectOption[]),
    [homerooms],
  );

  const handleChangeSiteId = useCallback(
    async (e) => {
      if (requiredAttributes?.includes('homeroomId')) {
        await formik.setFieldValue('homeroomId', '');
      }
      formik.handleChange(e);
      setLoading(true);
      await getHomerooms(e.target.value);
      setLoading(false);
    },
    [formik, requiredAttributes, getHomerooms],
  );

  const handleCancelClick = async () => {
    const userSiteId = TokenClaims.getSiteId(user);
    if (formik.values.siteId !== userSiteId) {
      await getHomerooms(userSiteId);
    }

    navigate('/');
  };

  useEffect(() => {
    getHomerooms(userData.siteId);
  }, [dispatch, userData.siteId, getHomerooms]);

  return (
    <div className="account-configuration-container">
      <SubHeader title="Account Configuration" />
      <div className="body">
        <form onSubmit={formik.handleSubmit}>
          <div className="oo-row">
            <MultiActionCard
              cardClassname="account-configuration-card"
              title="Update Your Account"
              description="Please update your account by selecting the following options."
              submitLabel="Save"
              submitAriaLabel="Update Account"
              loading={loading}
              submitDisabled={loading}
              testIds={{
                submit: 'submitBtn',
              }}
            >
              {requiredAttributes?.includes('siteId') && (
                <FormControl fullWidth>
                  <SelectField
                    fieldLabel="School Site"
                    name="siteId"
                    id="siteId"
                    value={formik.values.siteId}
                    errorMsg={formik.errors.siteId}
                    touched={formik.touched.siteId}
                    onChange={handleChangeSiteId}
                    options={siteOptions}
                  />
                </FormControl>
              )}
              {requiredAttributes?.includes('homeroomId') && (
                <FormControl fullWidth>
                  <AutocompleteDropdown
                    label="Homeroom"
                    id="homeroomId"
                    value={formik.values.homeroomId}
                    errorMsg={formik.errors.homeroomId}
                    options={homeroomOptions}
                    onChange={(e, selectedOption) => {
                      formik.setFieldValue('homeroomId', selectedOption?.value);
                    }}
                    data-test-id="homeroomDropDown"
                  />
                </FormControl>
              )}
            </MultiActionCard>
          </div>
        </form>
        <Modal open={isConfirmModalOpen} maxWidth="md" onClose={() => setIsConfirmModalOpen(false)}>
          <Modal.Content>
            <div className="switch-homeroom-cart-content">
              There is an order in progress for Homeroom <b>{cartHomeroomId}</b>. Are you sure you want to clear the
              order and continue?
            </div>
          </Modal.Content>
          <Modal.Actions className="switch-homeroom-cart-actions">
            <Button variant="outlined" color="primary" onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleContinue}>
              Continue
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    </div>
  );
};

export default AccountConfiguration;
