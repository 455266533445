import moment, { Moment } from 'moment-timezone';

import { District, OrderingRules, Product } from 'Interfaces/Interfaces';
import * as Constants from 'Constants/Constants';

const isAfterMinimumCutOff = (orderingRules: OrderingRules, isTeacher: boolean, date: Moment | string, now: Moment) => {
  let cutoffTime: string;
  let orderRange: string | undefined;
  let orderDueBy: string | undefined;
  let cutoffDay: string | undefined;

  if (isTeacher) {
    cutoffTime = orderingRules?.teacherCutoffTime || '00:00:00';
    orderRange = orderingRules?.teacherOrderRange;
    orderDueBy = orderingRules?.teacherOrderDueBy;
    cutoffDay = orderingRules?.teacherCutoffDay;
  } else {
    cutoffTime = orderingRules?.cutoffTime || '00:00:00';
    orderRange = orderingRules?.orderRange;
    orderDueBy = orderingRules?.orderDueBy;
    cutoffDay = orderingRules?.cutoffDay;
  }

  if (orderRange === Constants.ORDER_RANGE_OPTIONS.DAILY) {
    const cutoffDays = orderDueBy
      ? Constants.ORDER_DUE_BY_RULES[orderDueBy as Constants.ORDER_DUE_BY_RULE_OPTIONS]?.cutoffDays
      : 0;

    const dailyCutoffYMD = moment(date).subtract(cutoffDays, 'day').format(Constants.DATE_FORMAT_YMD);
    const dailyCutoffTime = moment(`${dailyCutoffYMD} ${cutoffTime}`, Constants.DATE_FORMAT_24_HOUR);

    return now.isAfter(dailyCutoffTime);
  } else if (orderRange === Constants.ORDER_RANGE_OPTIONS.WEEKLY) {
    const cutOffDayOfWeek = parseInt(cutoffDay as string);
    const weeklyDateCutoffYMD = moment(date).subtract(1, 'week').day(cutOffDayOfWeek).format(Constants.DATE_FORMAT_YMD);
    const weeklyCutoffTime = moment(`${weeklyDateCutoffYMD} ${cutoffTime}`, Constants.DATE_FORMAT_24_HOUR);

    return now.isAfter(weeklyCutoffTime);
  } else if (orderRange === Constants.ORDER_RANGE_OPTIONS.MONTHLY) {
    const monthlyCutoffYMD = moment(now).endOf('month').format(Constants.DATE_FORMAT_YMD);
    const monthlyCutoffTime = moment(`${monthlyCutoffYMD} ${cutoffTime}`, Constants.DATE_FORMAT_24_HOUR);

    return moment(date).isBefore(monthlyCutoffTime);
  }

  return false;
};

const isAfterMaximumCutOff = (orderingRules: OrderingRules, isTeacher: boolean, date: Moment | string, now: Moment) => {
  let orderRange: string | undefined;
  let maxCutoffDays: string | undefined;
  let maxCutoffWeeks: string | undefined;
  let maxCutoffMonths: string | undefined;

  if (isTeacher) {
    orderRange = orderingRules?.teacherMaxOrderRange;
    maxCutoffDays = orderingRules?.teacherMaxCutoffDays;
    maxCutoffWeeks = orderingRules?.teacherMaxCutoffWeeks;
    maxCutoffMonths = orderingRules?.teacherMaxCutoffMonths;
  } else {
    orderRange = orderingRules?.maxOrderRange;
    maxCutoffDays = orderingRules?.maxCutoffDays;
    maxCutoffWeeks = orderingRules?.maxCutoffWeeks;
    maxCutoffMonths = orderingRules?.maxCutoffMonths;
  }

  if (orderRange === Constants.MAX_ORDER_RANGE_OPTIONS.DAYS) {
    const daysBetween = moment(date).diff(now, 'day');
    const daysAllowed = Constants.MAX_ORDER_UP_TO_DAYS[maxCutoffDays as Constants.MAX_ORDER_UP_TO_DAYS_OPTIONS].days;

    return daysBetween >= daysAllowed;
  }

  if (orderRange === Constants.MAX_ORDER_RANGE_OPTIONS.WEEKS) {
    const weeksBetween = moment(date).diff(now, 'week');
    const weeksAllowed =
      Constants.MAX_ORDER_UP_TO_WEEKS[maxCutoffWeeks as Constants.MAX_ORDER_UP_TO_WEEKS_OPTIONS].weeks;

    return weeksBetween >= weeksAllowed;
  }

  if (orderRange === Constants.MAX_ORDER_RANGE_OPTIONS.MONTHS) {
    const monthsBetween = moment(date).diff(now, 'month');
    const monthsAllowed =
      Constants.MAX_ORDER_UP_TO_MONTHS[maxCutoffMonths as Constants.MAX_ORDER_UP_TO_MONTHS_OPTIONS].months;

    return monthsBetween >= monthsAllowed;
  }

  return false;
};

/**
 * Checks if a date violates the ordering rules.
 * @param district - district config
 * @param orderDate - order date
 * @param currentDate - the current date
 * @param timezone - timezone string
 * @param isTeacher - boolean to indicate which ordering rules to use
 * @returns {Boolean} true if violates, false otherwise
 */
export const isViolatingOrderingRules = (
  district: District,
  orderDate: moment.Moment | string,
  currentDate: moment.Moment,
  timezone: string,
  isTeacher = false,
): boolean => {
  const orderingRules = district?.orderingRules;
  if (!orderingRules) {
    return false;
  }
  let date = orderDate;

  if (typeof orderDate === 'string') {
    date = moment.tz(orderDate, timezone);
  }

  const now = moment.tz(currentDate, timezone);

  return (
    isAfterMinimumCutOff(orderingRules, isTeacher, date, now) ||
    isAfterMaximumCutOff(orderingRules, isTeacher, date, now)
  );
};

export const getProductPrice = (
  product: Product,
  siteGroupTypeId: string | undefined,
  price: number | undefined,
): number | undefined => {
  if (!product || !siteGroupTypeId || product.productCategoryId !== Constants.ALACARTE_KEY || !product.prices) {
    return price;
  }

  return product.prices.find((p: any) => p.siteGroupTypeId === siteGroupTypeId)?.price;
};
