import React from 'react';
import * as yup from 'yup';
import classNames from 'classnames';
import { User } from '@auth0/auth0-react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useApi } from 'Api/useApi';
import { Field, FieldProps, Form, Formik, FormikProps, FormikValues } from 'formik';
import { Role, TokenClaims } from 'Auth/User';
import { InputField } from 'Components/Form/MaterialForm';
import MultiActionCard from 'Components/MultiActionCard/MultiActionCard';
import { PasswordStrengthMeter } from 'Components/PasswordStrengthMeter/PasswordStrengthMeter';
import { TermsOfUseModal } from 'Components/TermsOfUseModal/TermsOfUseModal';
import { ButtonLink } from 'Components/ButtonLink/ButtonLink';
import { NotificationDialog } from 'Components/Dialogs/NotificationDialog';

import './ProfileForm.less';
// Regex used by auth0 in their Lock widget to validate email address structure:
// https://github.com/auth0/lock/blob/4aaefa4a9ed00423e665e0743a7b0a952c6a267d/src/field/email.js#L7
const emailRegEx = new RegExp("^[a-zA-Z0-9.!#$%&amp;'^_`{}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$");
const hasLowerCase = new RegExp('([a-z]{1,})');
const hasUpperCase = new RegExp('([A-Z]{1,})');
const hasNumber = new RegExp('([0-9]{1,})');
const hasSpecialCharacter = new RegExp('([#?!@$%^&*-]{1,})');
const requiredText = 'required field';

const validatePassword = (password: string) => {
  if (!password) {
    return undefined;
  }

  if (password.length < 8) {
    return 'Password must be at least 8 characters in length';
  }

  let score = 0;

  score += hasLowerCase.test(password) ? 1 : 0;
  score += hasUpperCase.test(password) ? 1 : 0;
  score += hasNumber.test(password) ? 1 : 0;
  score += hasSpecialCharacter.test(password) ? 1 : 0;

  if (score < 3) {
    return 'Password must contain at least 3 of the following: lower case letters (i.e. a-z), upper case letters (i.e. A-Z), numbers (i.e. 0-9), special characters (e.g. -+_!@#$%^&*.,?)';
  }

  return undefined;
};

interface ProfileFormProps {
  handleSubmit: (values: FormikValues) => void;
  loading: boolean;
  user?: User;
}

const ProfileForm = ({ handleSubmit, loading, user }: ProfileFormProps) => {
  const api = useApi();
  const [termsOfUseModalOpen, setTermsOfUseModalOpen] = React.useState(false);
  const [validatedEmail, setvalidatedEmail] = React.useState('');
  const [validateEmailLoading, setValidateEmailLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(!user);
  const [showEmail, setShowEmail] = React.useState(!user);
  const [emailError, setEmailError] = React.useState('');
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const isDirectorManagerUser = TokenClaims.getAdmin(user) || TokenClaims.getRole(user) === Role.MANAGER;

  const isEmailIsAlreadyUsed = async (email: string) => {
    const isEmailStructureValid = emailRegEx.test(email.toLowerCase().trim());

    if (!email || email === validatedEmail || !isEmailStructureValid) {
      return false;
    }

    try {
      setValidateEmailLoading(true);
      await api.post(`/utility/users/verifySignUpEmail`, {
        email,
      });

      return false;
    } catch (err: any) {
      if (err?.response?.data?.attributes?.errorCodes?.includes('VALIDATE_SELF_SIGN_UP_EMAIL_ADDRESS_EXISTS')) {
        return true;
      }

      return false;
    } finally {
      setValidateEmailLoading(false);
      setvalidatedEmail(email);
    }
  };

  const handleValidation = async ({ email, password, confirmPassword }: FormikValues) => {
    const errors: any = {};

    if (password && confirmPassword && password !== confirmPassword) {
      errors.confirmPassword = 'Passwords must match';
      errors.password = 'Passwords must match';
    }

    const passwordValidationError = validatePassword(password);
    if (passwordValidationError) {
      errors.password = passwordValidationError;
    }

    if (email && !emailRegEx.test(email.toLowerCase().trim())) {
      errors.email = 'Invalid email format. Ex. yourname@gmail.com';
    }

    if (emailError) {
      errors.email = emailError;
    }

    return errors;
  };

  const handleFormSubmit = async (values: FormikValues, actions: any) => {
    if (values.email) {
      if (values.email === user?.email) {
        actions.setFieldError('email', 'Email Must Be Different From Current Email');
        return;
      }

      const isTaken = await isEmailIsAlreadyUsed(values.email);
      if (isTaken) {
        actions.setFieldError('email', 'Email Already Exists');
        return;
      }
    }

    if ((values.password || values.email) && user) {
      setShowConfirmation(true);
      return;
    }

    handleSubmit(values);
  };

  const initialErrors = React.useMemo(() => {
    const errors: any = {};
    if (user && !user?.family_name) {
      errors.lastName = requiredText;
    }
    if (user && !user?.given_name) {
      errors.firstName = requiredText;
    }
    return errors;
  }, [user]);

  const validationSchema = React.useMemo(() => {
    const schema: any = {
      firstName: yup.string().required(requiredText),
      lastName: yup.string().required(requiredText),
    };

    if (!user) {
      schema.acceptTerms = yup.boolean().required(requiredText).oneOf([true], requiredText);
    }

    if (!user || showEmail) {
      schema.email = yup.string().required(requiredText);
    }

    if (!user || showPassword) {
      schema.password = yup.string().required(requiredText);
      schema.confirmPassword = yup.string().required(requiredText);
    }
    return schema;
  }, [user, showPassword, showEmail]);

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        onSubmit={handleFormSubmit}
        initialValues={{
          firstName: user?.given_name,
          lastName: user?.family_name,
          acceptedTerms: false,
        }}
        validate={handleValidation}
        isInitialValid={false}
        initialErrors={initialErrors}
        validationSchema={yup.object().shape(validationSchema)}
      >
        {({
          values,
          errors,
          isValid,
          setFieldValue,
          setErrors,
          setFieldTouched,
        }: FormikProps<{
          firstName: string;
          lastName: string;
          email: string;
          password: string;
          confirmPassword: string;
          showEmail: boolean;
          showPassword: boolean;
          showAcceptTerms: boolean;
        }>) => {
          return (
            <Form>
              <div className="register-card-container">
                <MultiActionCard
                  cardClassname="register-card"
                  title={user ? 'General' : 'Register'}
                  loading={loading || validateEmailLoading}
                  submitLabel={user ? 'Update' : 'Submit'}
                  submitButtonVarient="contained"
                  submitAriaLabel={user ? 'Update' : 'Submit'}
                  submitDisabled={!isValid || loading || validateEmailLoading}
                >
                  <Field name="firstName">
                    {({ field, meta }: FieldProps) => (
                      <InputField
                        name={'firstName'}
                        key={'firstName'}
                        id={'firstName'}
                        label={'First Name'}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        value={field.value || ''}
                        error={meta.error !== undefined}
                        errorMsg={meta.error}
                        touched={meta.touched || !!initialErrors.firstName}
                        showOnlyTouchedError={true}
                        hideEmptyError={false}
                        disabled={loading}
                        required={true}
                        hideErrorMsg={meta.error?.toLocaleLowerCase().includes(requiredText)}
                        inputProps={{
                          'data-test-id': 'firstNameInput',
                        }}
                      />
                    )}
                  </Field>

                  <Field name="lastName">
                    {({ field, meta }: FieldProps) => (
                      <InputField
                        name={'lastName'}
                        key={'lastName'}
                        id={'lastName'}
                        label={'Last Name'}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        value={field.value || ''}
                        error={meta.error !== undefined}
                        errorMsg={meta.error}
                        hideEmptyError={false}
                        hideErrorMsg={meta.error?.toLocaleLowerCase().includes(requiredText)}
                        touched={meta.touched || !!initialErrors.lastName}
                        showOnlyTouchedError={true}
                        disabled={loading}
                        required={true}
                        inputProps={{
                          'data-test-id': 'lastNameInput',
                        }}
                      />
                    )}
                  </Field>
                  {user && !isDirectorManagerUser && (
                    <div className="change-email__container">
                      <span className="change-email">Email: {user?.email}</span>
                      <span className="change-email__helper-text">
                        Click the link below to update your email or password
                      </span>
                      <button
                        className={classNames('update-email-btn', (validateEmailLoading || loading) && 'disabled-btn')}
                        type="button"
                        disabled={validateEmailLoading || loading}
                        onClick={() => {
                          // reset field if user hides the update email field
                          if (showEmail) {
                            setFieldValue('email', undefined);
                            setFieldTouched('email', false);
                            setErrors({ ...errors, email: undefined });
                          }
                          setShowEmail(!showEmail);
                          setShowPassword(false);
                        }}
                        data-test-id={'updateEmailBtn'}
                      >
                        Update Email
                      </button>
                      <button
                        className={classNames(
                          'update-password-btn',
                          (validateEmailLoading || loading) && 'disabled-btn',
                        )}
                        type="button"
                        disabled={loading || validateEmailLoading}
                        onClick={() => {
                          // reset fields if user hides the update password fields
                          if (showPassword) {
                            setFieldValue('password', undefined);
                            setFieldValue('confirmPassword', undefined);
                            setFieldTouched('password', false);
                            setFieldTouched('confirmPassword', false);
                            setErrors({ ...errors, password: undefined, confirmPassword: undefined });
                          }
                          setShowEmail(false);
                          setShowPassword(!showPassword);
                        }}
                        data-test-id={'updatePasswordBtn'}
                      >
                        Update Password
                      </button>
                    </div>
                  )}
                  {showEmail && (
                    <Field name="email">
                      {({ field, form, meta }: FieldProps) => (
                        <InputField
                          name={'email'}
                          key={'email'}
                          id={'email'}
                          label={'Email'}
                          onChange={field.onChange}
                          onBlur={async (e) => {
                            field.onBlur(e);
                            // handle email in use validation manually on blur to prevent additional requests
                            if (!user) {
                              if (await isEmailIsAlreadyUsed(e.target.value)) {
                                const error = 'Email Already Exists';
                                form.setErrors({ ...form.errors, email: error });
                                setEmailError(error);
                              } else {
                                setEmailError('');
                                form.setErrors({ ...form.errors, email: undefined });
                              }
                            }
                          }}
                          value={field.value || ''}
                          error={meta.error !== undefined}
                          errorMsg={meta.error}
                          hideEmptyError={false}
                          touched={meta.touched}
                          showOnlyTouchedError={true}
                          disabled={loading || validateEmailLoading}
                          required={true}
                          hideErrorMsg={meta.error?.toLocaleLowerCase().includes(requiredText)}
                          inputProps={{
                            'data-test-id': 'emailInput',
                          }}
                        />
                      )}
                    </Field>
                  )}
                  {showPassword && (
                    <>
                      <PasswordStrengthMeter password={values.password} />
                      <Field name="password">
                        {({ field, meta }: FieldProps) => (
                          <InputField
                            name={'password'}
                            key={'password'}
                            id={'password'}
                            label={'Password'}
                            // Prevents overlapping when error message is multi line
                            className={classNames((meta.error?.length ?? 0) > 50 && 'multi-line-error')}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            value={field.value || ''}
                            error={meta.error !== undefined}
                            errorMsg={meta.error}
                            hideEmptyError={false}
                            hideErrorMsg={meta.error?.toLocaleLowerCase().includes(requiredText)}
                            touched={meta.touched}
                            showOnlyTouchedError={true}
                            type={'password'}
                            disabled={loading}
                            required={true}
                            inputProps={{
                              'data-test-id': 'passwordInput',
                            }}
                          />
                        )}
                      </Field>
                      <Field name="confirmPassword">
                        {({ field, meta }: FieldProps) => (
                          <InputField
                            name={'confirmPassword'}
                            key={'confirmPassword'}
                            id={'confirmPassword'}
                            label={'Confirm Password'}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            value={field.value || ''}
                            error={meta.error !== undefined}
                            errorMsg={meta.error}
                            hideEmptyError={false}
                            hideErrorMsg={meta.error?.toLocaleLowerCase().includes(requiredText)}
                            touched={meta.touched}
                            showOnlyTouchedError={true}
                            type={'password'}
                            disabled={loading}
                            required={true}
                            inputProps={{
                              'data-test-id': 'confirmPasswordInput',
                            }}
                          />
                        )}
                      </Field>
                      {!user && (
                        <Field name={'acceptTerms'}>
                          {({ field }: FieldProps) => {
                            return (
                              <>
                                <FormControlLabel
                                  className="agree-to-terms"
                                  control={
                                    <Checkbox
                                      disableRipple
                                      name={'acceptTerms'}
                                      color="default"
                                      checked={field.value || false}
                                      onChange={field.onChange}
                                      required={true}
                                      // @ts-ignore
                                      // For automation testing id.
                                      inputProps={{ 'data-test-id': 'termsOfUseCheckbox' }}
                                    />
                                  }
                                  label={
                                    <>
                                      <span className={'agree-label'}>I agree to the </span>
                                      <ButtonLink
                                        className="terms-of-use"
                                        text={'Terms of Use'}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                          setTermsOfUseModalOpen(true);
                                        }}
                                        data-test-id={'viewTermsOfUseBtn'}
                                      />
                                    </>
                                  }
                                />
                              </>
                            );
                          }}
                        </Field>
                      )}
                    </>
                  )}
                </MultiActionCard>
              </div>
              <NotificationDialog
                title={showEmail ? 'Update Email?' : 'Update Password?'}
                message={`Changing your ${
                  showEmail ? 'email address' : 'password'
                } will require you to log in again. Do you wish to continue?`}
                showDialog={showConfirmation}
                onOkClick={() => {
                  setShowConfirmation(false);
                  handleSubmit(values);
                }}
                showCancel={true}
                onCancelClick={() => setShowConfirmation(false)}
                okButtonText={'Update'}
                cancelButtonText={'Cancel'}
                dialogType={'info'}
              />
            </Form>
          );
        }}
      </Formik>

      <TermsOfUseModal
        isOpen={termsOfUseModalOpen}
        handleClose={() => {
          setTermsOfUseModalOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export default ProfileForm;
