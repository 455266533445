import React from 'react';
import { RouteProps } from 'Interfaces/Interfaces';
import Header from 'Components/Header/Header';
import Footer from 'Components/Footer/Footer';
import AdminSidebar from 'Components/Admin/AdminSidebar/AdminSidebar';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';
import classNames from 'classnames';
import { SidebarLocations } from 'Constants/Enums';

/**
 * Wrapper for elements passed to react-router-dom element prop
 * `isPrintable` prop will not render Header or AdminSidebar
 * `adminSidebar` prop will render the AdminSidebar. `isPrintable` overrides `adminSidebar`
 * `hideParentFooter` prop will not render Footer
 */
const RouteElement = ({
  adminSidebar = false,
  isPrintable = false,
  hideParentFooter,
  children,
}: RouteProps): JSX.Element => {
  if (children && isPrintable) {
    return <>{children}</>;
  }

  return (
    <>
      <Header adminSidebar={adminSidebar} />
      <NotificationBanner location={SidebarLocations.NONE} />
      <main className={classNames('main-content')} id="content">
        {children}
        {adminSidebar && <AdminSidebar />}
      </main>
      {!hideParentFooter && <Footer />}
    </>
  );
};

export default RouteElement;
