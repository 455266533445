import { useState } from 'react';
import { ActionCreator } from 'redux';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

import * as CommonActions from 'State/actions';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';
import { TokenClaims } from 'Auth/User';
import { useApi } from 'Api/useApi';

const CancelToken = axios.CancelToken;
let cancel: any = {};

export const useTeacherRosterOrderingActions = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);

  const [actions] = useState({
    getTeacherReport: async (params: any) => {
      try {
        if (cancel.teacherReport) {
          cancel.teacherReport();
        }

        const results = (await api.get(`/utility/reports/districts/${districtId}/teacher`, {
          params,
          cancelToken: new CancelToken(function executor(c) {
            cancel.teacherReport = c;
          }),
        })) as any;
        dispatch(CommonActions.setHomerooms(results?.data?.homerooms));
        dispatch(CommonActions.setStudents(results?.data?.students));
        dispatch(setTeacherReport(results?.data?.report));
        dispatch(setReportProducts(results?.data?.products));
      } catch (ex) {}
    },
    placeOrder: async (orderItemsById: Record<string, Interfaces.OrderItem[]>) => {
      return api.post(`/districts/${districtId}/orderItems`, { orderItemsById });
    },
  });

  return actions;
};

export const setTeacherReport: ActionCreator<Interfaces.Action> = (payload: any) => ({
  type: Types.TEACHER_REPORT_SET_REPORT,
  payload,
});

export const setEntreeSidebarOpen: ActionCreator<Interfaces.Action> = (payload: boolean) => ({
  type: Types.TEACHER_REPORT_SET_ENTREE_SIDEBAR_OPEN,
  payload,
});

export const setSelectedEntree: ActionCreator<Interfaces.Action> = (payload: Interfaces.MenuItem) => ({
  type: Types.TEACHER_REPORT_SELECT_ENTREE,
  payload,
});

export const setSelectedSides: ActionCreator<Interfaces.Action> = (payload: Record<string, Interfaces.MenuItem[]>) => ({
  type: Types.TEACHER_REPORT_SET_SELECTED_SIDES,
  payload,
});

export const setShowCart: ActionCreator<Interfaces.Action> = (payload: boolean) => ({
  type: Types.TEACHER_REPORT_SET_SHOW_CART,
  payload,
});

export const addToCart: ActionCreator<Interfaces.Action> = (payload: {
  selectedStudents: Record<string, boolean>;
  date: string;
  menuTypeId: string;
  accountId: string;
  homeroomId: string;
}) => ({
  type: Types.TEACHER_REPORT_ADD_TO_CART,
  payload,
});

export const removeCartItem: ActionCreator<Interfaces.Action> = (payload: {
  studentId: string;
  cartId: string;
  date: string;
  menuTypeId: string;
  accountId: string;
}) => ({
  type: Types.TEACHER_REPORT_REMOVE_FROM_CART,
  payload,
});

export const removeCartDate = (payload: { date: string; accountId: string }) => ({
  type: Types.TEACHER_REPORT_REMOVE_CART_DATE,
  payload,
});

export const deleteOrderItem = (payload: Interfaces.OrderItem) => ({
  type: Types.TEACHER_REPORT_DELETE_ORDER_ITEM,
  payload,
});

export const setReportProducts = (payload: { [key: string]: Interfaces.Product }) => ({
  type: Types.TEACHER_REPORT_SET_REPORT_PRODUCTS,
  payload,
});

export const setBulkMode = (payload: boolean) => ({
  type: Types.TEACHER_REPORT_SET_BULK_MODE,
  payload,
});

export const addToBulkCart = (payload: {
  accountId: string;
  bulkOrderCart: Interfaces.TeacherBulkOrderingCart;
  homeroomId: string;
}) => ({
  type: Types.TEACHER_REPORT_ADD_TO_BULK_CART,
  payload,
});

export const removeBulkItem = (payload: {
  date: string;
  menuTypeId: string;
  productId: string;
  accountId: string;
}) => ({
  type: Types.TEACHER_REPORT_REMOVE_BULK_ITEM,
  payload,
});

export const addOrSubtractBulkItemQuantity = (payload: {
  date: string;
  productId: string;
  quantity: number;
  accountId: string;
  menuTypeId: string;
}) => ({
  type: Types.TEACHER_REPORT_ADD_OR_SUBTRACT_BULK_ITEM_QUANTITY,
  payload,
});

export const clearCart = (payload: string) => ({ type: Types.TEACHER_REPORT_CLEAR_CART, payload });

export const clearBulkCart = (payload: string) => ({ type: Types.TEACHER_REPORT_CLEAR_BULK_CART, payload });
