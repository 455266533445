import React from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'react-table';
import { useAuth0 } from '@auth0/auth0-react';
import { TokenClaims } from 'Auth/User';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import { PageLoaderNoRedux } from 'Components/Loaders/Loaders';
import ReactTableV2 from 'Components/ReactTable/ReactTableV2/ReactTableV2';
import * as CommonSelectors from 'State/selectors';
import { FilterFieldType } from 'Constants/Constants';
import { useDistrict, useSites } from 'State/hooks';

import './SiteInformation.less';

const SiteInformation = () => {
  const { user } = useAuth0();

  const districtId = TokenClaims.getDistrictId(user);
  const siteReports = useSelector(CommonSelectors.getSiteReports(districtId));
  const districtLoading = useDistrict(districtId);
  const sitesLoading = useSites(districtId);

  return (
    <React.Fragment>
      <div>
        <SubHeader title="Site Information" />
        <PageLoaderNoRedux loading={sitesLoading || districtLoading} />
        <div className="site-information">
          <ReactTableV2 columns={columns} data={siteReports} autoResetPage={true} unitLabel="Sites" />
        </div>
      </div>
    </React.Fragment>
  );
};

const columns = [
  {
    Header: 'Site ID',
    accessor: 'sisId',
    filterField: { fieldName: 'Site ID', fieldType: FilterFieldType.multiSelect, ordinal: 1 },
    dataTestId: (row: any): string => `${row.original.id} ${row.original.name}.siteIdLbl`,
  },
  {
    Header: 'ISITE Site ID',
    accessor: 'id',
    filterField: { fieldName: 'ISITE Site ID', fieldType: FilterFieldType.multiSelect, ordinal: 2 },
    dataTestId: (row: any): string => `${row.original.id} ${row.original.name}.isiteSiteIdLbl`,
  },
  {
    Header: 'Site Name',
    accessor: 'name',
    width: 275,
    filterField: { fieldName: 'Site Name', fieldType: FilterFieldType.multiSelect, ordinal: 3 },
    dataTestId: (row: any): string => `${row.original.id} ${row.original.name}.nameLbl`,
  },
  {
    Header: 'Site Group',
    accessor: 'siteGroupTypeId',
    width: 250,
    filterField: { fieldName: 'Site Group', fieldType: FilterFieldType.multiSelect, ordinal: 4 },
    dataTestId: (row: any): string => `${row.original.id} ${row.original.name}.siteGroupLbl`,
  },
  {
    Header: 'CEP Status',
    accessor: 'cep',
    sortType: (rowA: Row<any>, rowB: Row<any>, columnId: string) => {
      const valueA = rowA.original[columnId];
      const valueB = rowB.original[columnId];

      if (valueA === valueB) {
        return 0;
      }

      return valueA ? -1 : 1;
    },
    width: 70,
    Cell: (props: any) => {
      return props.row.original.cep ? 'CEP' : '';
    },
    filterField: {
      fieldName: 'CEP Status',
      fieldType: FilterFieldType.checkbox,
      ordinal: 5,
      staticOptions: ['Non-CEP', 'CEP'],
      booleanField: true,
      booleanValueMap: { truthyValue: 'CEP', falsyValue: 'Non-CEP' },
    },
    dataTestId: (row: any): string => `${row.original.id} ${row.original.name}.CEPLbl`,
  },
  {
    Header: '# Students/Adults',
    accessor: 'totalStudents',
    dataTestId: (row: any): string => `${row.original.id} ${row.original.name}.totalStudentsLbl`,
  },
  {
    Header: '# Free',
    accessor: 'free',
    width: 75,
    dataTestId: (row: any): string => `${row.original.id} ${row.original.name}.freeStudentsLbl`,
  },
  {
    Header: '# Reduced',
    accessor: 'reduced',
    width: 75,
    dataTestId: (row: any): string => `${row.original.id} ${row.original.name}.reducedStudentsLbl`,
  },
  {
    Header: '# Paid',
    accessor: 'paid',
    width: 75,
    dataTestId: (row: any): string => `${row.original.id} ${row.original.name}.paidStudentsLbl`,
  },
  {
    Header: '# Adult',
    accessor: 'adult',
    width: 75,
    dataTestId: (row: any): string => `${row.original.id} ${row.original.name}.adultsLbl`,
  },
];

export default SiteInformation;
