import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React, { useCallback } from 'react';
import { get } from 'lodash';
import Button from 'Components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import * as Interfaces from 'Interfaces/Interfaces';
import * as DistrictReportsActions from 'Pages/DistrictReports/state/actions';
import * as CommonSelectors from 'State/selectors';
import { TokenClaims } from 'Auth/User';
import './Breadcrumbs.less';

const Breadcrumbs = (props: Interfaces.BreadcrumbProps) => {
  const { siteId, pickupLocationId, report } = props;
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);
  const districts = useSelector(CommonSelectors.getDistricts);
  const district = districts[districtId];
  const [site, setSite] = React.useState<any>(undefined);
  const [pickupLocation, setPickupLocation] = React.useState<any>(undefined);

  React.useEffect(() => {
    const reportSite = get(report, `sites.${siteId}`);

    if (reportSite) {
      setSite(reportSite);
    }
  }, [report, siteId]);

  React.useEffect(() => {
    const pickupLocations = get(report, `pickupLocations`, {});
    const reportPickupLocation = pickupLocations[pickupLocationId];

    if (reportPickupLocation) {
      setPickupLocation(reportPickupLocation);
    }
  }, [pickupLocationId, report]);

  const handleDistrictClick = useCallback(() => {
    dispatch(DistrictReportsActions.setSiteId(''));
    dispatch(DistrictReportsActions.setPickupLocationId(''));
  }, [dispatch]);

  const handleSiteClick = useCallback(() => {
    dispatch(DistrictReportsActions.setPickupLocationId(''));
  }, [dispatch]);

  const visible = !!((siteId && site) || (pickupLocationId && pickupLocation));

  if (!visible) {
    return null;
  }

  return (
    <div className="breadcrumb-container">
      {(siteId || pickupLocationId) && (
        <Button color="primary" onClick={handleDistrictClick} data-test-id={`${district?.name}.breadcrumbBtn`}>
          {district?.name}
        </Button>
      )}
      {siteId && site && (
        <>
          <ChevronLeft />
          <Button
            color={pickupLocationId ? 'primary' : undefined}
            onClick={handleSiteClick}
            data-test-id={`${site?.name}.breadcrumbBtn`}
          >
            {site?.name}
          </Button>
        </>
      )}
      {pickupLocationId && pickupLocation && (
        <>
          <ChevronRight />
          <div className="item">{pickupLocation?.name}</div>
        </>
      )}
    </div>
  );
};

export default Breadcrumbs;
