import React, { FC, useCallback, useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { format, formatISO, isValid, parseISO } from 'date-fns';
import { Chip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { cloneDeep } from 'lodash';
import { Field, Form, Formik, FormikProps } from 'formik';
import Button from 'Components/Button/Button';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'Components/Drawer';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';
import * as Constants from 'Constants/Constants';
import { SidebarLocations } from 'Constants/Enums';
import { OrderingRules } from 'Interfaces/Interfaces';
import { useCommonActions } from 'State/actions';
import { sortDateStrings } from 'Helpers/DateHelpers';

import './BlackoutDatesRulesSidebar.less';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export interface BlackoutDatesRulesSidebarProps {
  open: boolean;
  orderingRules: OrderingRules;
  setSidebarOpen: (open: boolean) => void;
}

const BlackoutDatesRulesSidebar: FC<BlackoutDatesRulesSidebarProps> = ({
  open = false,
  orderingRules,
  setSidebarOpen,
}) => {
  const { updateDistrict } = useCommonActions();
  const [loading, setLoading] = useState<boolean>(false);
  const [newDate, setNewDate] = useState<Date | null>(null);
  const [localOrderingRules, setLocalOrderingRules] = useState<OrderingRules>();

  useEffect(() => {
    setLocalOrderingRules(cloneDeep(orderingRules));
  }, [orderingRules, open]);

  const handleAddDate = useCallback(
    (date: MaterialUiPickersDate) => {
      const newOrderingRules: OrderingRules = {
        ...localOrderingRules,
      };
      const blackoutDates = newOrderingRules?.blackoutDates ?? [];
      const dateStr = formatISO(date as Date);
      if (dateStr && !blackoutDates.find((x) => x === dateStr)) {
        blackoutDates.push(dateStr);
      }
      newOrderingRules.blackoutDates = blackoutDates;
      setLocalOrderingRules(newOrderingRules);
      setNewDate(null);
    },
    [localOrderingRules],
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === Constants.EVENTS.ENTER) {
        if (newDate && isValid(newDate)) {
          handleAddDate(newDate);
        }
        e.preventDefault();
      }
    },
    [handleAddDate, newDate],
  );

  const handleRemoveDate = useCallback(
    (removeDate: string) => {
      const newOrderingRules: OrderingRules = { ...localOrderingRules };
      const blackoutDates = newOrderingRules?.blackoutDates?.filter((x) => x !== removeDate);
      newOrderingRules.blackoutDates = blackoutDates;
      setLocalOrderingRules(newOrderingRules);
    },
    [localOrderingRules],
  );

  const handleSubmit = async () => {
    setLoading(true);
    const updatedDistrict = {
      orderingRules: localOrderingRules,
    };
    await updateDistrict(updatedDistrict);
    setNewDate(null);
    setLoading(false);
    setSidebarOpen(false);
  };

  return (
    <Drawer open={open} id="online-ordering-card-labels" anchor="right" className="ordering-rules-sidebar-container">
      <Formik initialValues={{}} onSubmit={handleSubmit} enableReinitialize>
        {({ handleReset }: FormikProps<{}>) => (
          <Form className="linq-drawer-form-content oo-drawer-form">
            <DrawerHeader>
              <h2>Edit Blackout Dates</h2>
              <CloseIconButton
                onClick={() => {
                  setSidebarOpen(false);
                }}
              />
            </DrawerHeader>
            <NotificationBanner location={SidebarLocations.BLACKOUT_DATES_RULES_SIDE_BAR} />
            <DrawerContent>
              <section>
                <p></p>
                <Field name="newDate">
                  {() => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk={true}
                        label="Add Blackout Date"
                        invalidDateMessage="Please input date in MM/DD/YYYY format"
                        showTodayButton={true}
                        value={newDate}
                        format={Constants.DATE_FNS_DATE_FORMAT_USA}
                        onChange={(date: MaterialUiPickersDate) => {
                          setNewDate(date);
                        }}
                        onAccept={handleAddDate}
                        onKeyDown={handleKeyDown}
                        allowKeyboardControl={true}
                        style={{ width: '100%' }}
                        InputProps={{ className: 'linq-input' }}
                        inputVariant="outlined"
                        InputLabelProps={{ className: 'floating-label' }}
                        inputProps={{ 'data-test-id': 'blackoutDateInput' }}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                </Field>
              </section>
              <section className="datesList">
                {localOrderingRules?.blackoutDates?.sort(sortDateStrings).map((bd, index) => (
                  <Chip
                    className="linq-chip blackout-date-rules__date"
                    color="primary"
                    deleteIcon={<CloseIcon data-test-id={`${bd}.deleteDate`} />}
                    onDelete={() => handleRemoveDate(bd)}
                    aria-label="Click to remove date."
                    label={format(parseISO(bd), Constants.DATE_FNS_DATE_FORMAT_USA)}
                    key={bd}
                  />
                ))}
              </section>
            </DrawerContent>
            <DrawerFooter>
              <Button
                type="button"
                color="primary"
                variant="outlined"
                disabled={loading}
                onClick={() => {
                  setNewDate(null);
                  setLocalOrderingRules(cloneDeep(orderingRules));
                  setSidebarOpen(false);
                }}
                data-test-id="cancelBtn"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                aria-label="Submit"
                type="submit"
                disabled={loading}
                loading={loading}
                data-test-id="submitBtn"
              >
                Save
              </Button>
            </DrawerFooter>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default BlackoutDatesRulesSidebar;
