import { useAuth0 } from '@auth0/auth0-react';
import { Warning } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import TrashIcon from '@material-ui/icons/Delete';
import { isAuthorized, Role, TokenClaims } from 'Auth/User';
import classNames from 'classnames';
import Button from 'Components/Button/Button';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'Components/Drawer';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import * as CartActions from 'Components/Shop/Cart/state/actions';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';
import { SidebarLocations } from 'Constants/Enums';
import * as CartSelectors from 'Components/Shop/Cart/state/selectors';
import { isViolatingOrderingRules } from 'Components/Shop/ShopUtil';
import { InputField } from 'Components/Form/MaterialForm';
import { RouteId, Routes } from 'Constants/Routes';
import { useBodyOverflow } from 'Helpers/useBodyOverflow';
import { CartItem } from 'Interfaces/Interfaces';
import * as Constants from 'Constants/Constants';
import { groupBy, orderBy, sortBy } from 'lodash';
import moment from 'moment-timezone';
import numeral from 'numeral';
import * as ShopSelectors from 'Pages/Shop/state/selectors';
import React, { Fragment, ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import * as CommonSelectors from 'State/selectors';
import './Cart.less';

const Cart = (): ReactElement => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const showCart = useSelector(CartSelectors.getShowCart);
  const data = useSelector(CartSelectors.getCheckout);
  const student = useSelector(ShopSelectors.getStudent);
  const timezone = useSelector(ShopSelectors.getDistrictTimezone);
  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const userId = TokenClaims.getUserId(user);

  const hasALaCarteViolation = useSelector(ShopSelectors.hasALaCarteViolation(userRole));
  const navigate = useNavigate();

  const isParent = isAuthorized(userRole, [Role.PARENT]);
  const showPrices = isParent;

  const districts = useSelector(CommonSelectors.getDistricts);
  const district = districts[student?.districtId];
  const { toggleCheckout, togglePickup } = CartActions.useCartActions();

  let studentLabel = '';

  if (student) {
    studentLabel = student.firstName + "'s ";
  }

  function removeStaleItems() {
    Object.values(data.items).forEach((items) => {
      (items as any[]).forEach((item) => {
        const date = moment(item.date, Constants.DATE_FORMAT_YMD);
        if (
          isParent &&
          (isViolatingOrderingRules(district, date, moment(), timezone) ||
            (item.productCategoryId === Constants.PRODUCT_CATEGORIES.ALACARTE.id && hasALaCarteViolation))
        ) {
          dispatch(CartActions.removeItem(item, -1));
        }
      });
    });
  }

  function onCloseClick() {
    toggleCheckout();
  }

  function onCheckoutClick() {
    removeStaleItems();
    toggleCheckout();
    togglePickup();
  }

  function onClickContinueOrdering() {
    toggleCheckout();
    navigate(Routes[RouteId.SHOP].path);
  }

  useBodyOverflow(showCart);

  useEffect(
    function setCartAccountId() {
      dispatch(CartActions.setAccountId(userId));
    },
    [dispatch, userId],
  );

  return (
    <Drawer
      id={'online-ordering-card'}
      anchor="right"
      open={showCart}
      data-id="cart-drawer-container"
      className={classNames('menu-item-modal-container cart-drawer-container', { mobile: isMobile })}
    >
      <DrawerHeader>
        <h2>
          {studentLabel}Cart | {data.count} item{data.count > 1 ? 's' : ''}
          {showPrices && <small className="total">Total: {numeral(data.total).format(Constants.USD_FORMAT)}</small>}
        </h2>
        <CloseIconButton onClick={onCloseClick} />
      </DrawerHeader>
      <NotificationBanner location={SidebarLocations.CART} />
      <DrawerContent className="cart-content">
        {Object.keys(data.items)
          .sort()
          .map((entry) => {
            const meals: any[] = data.items[entry];
            return (
              <DateElement
                date={entry}
                items={meals}
                key={entry}
                showPrices={showPrices}
                allowOrderComments={district?.orderingRules?.allowOrderComments}
              />
            );
          })}
      </DrawerContent>
      <DrawerFooter>
        <Button
          variant="outlined"
          color="primary"
          disabled={!student}
          onClick={onClickContinueOrdering}
          data-test-id="continueOrderingBtn"
        >
          CONTINUE ORDERING
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!data || !data.count}
          onClick={onCheckoutClick}
          data-test-id="checkoutBtn"
        >
          CHECKOUT
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

const DateElement = (props: any) => {
  const { date, items, showPrices, allowOrderComments } = props;
  const m = moment(date);
  // TODO Rename to menuType when API is ready
  const grouped = groupBy(items, 'mealType');
  return (
    <div key={m.format('X')}>
      <div className="day">
        <div className="title">{m.format(Constants.DATE_FORMAT_DMDDY)}</div>
        {Object.keys(grouped).map((menuType) => (
          <MenuTypeGroup
            menuType={menuType}
            items={grouped[menuType]}
            showPrices={showPrices}
            key={menuType}
            allowOrderComments={allowOrderComments}
          />
        ))}
        <hr />
      </div>
    </div>
  );
};

const MenuTypeGroup = (props: any) => {
  const { menuType, items, showPrices, allowOrderComments } = props;
  const itemsByCategory = groupBy(items, 'productCategoryId');
  const dispatch = useDispatch();

  function updateComments(id: string, comments: string) {
    dispatch(CartActions.setItemComments(id, comments));
  }
  const categories = useMemo(
    () =>
      sortBy(Object.keys(itemsByCategory), (categoryId: string) => {
        return Constants.PRODUCT_CATEGORIES_SORT_ORDER[categoryId];
      }),
    [itemsByCategory],
  );

  // entree or the first ALC item if there are no reimb meals
  const cartItem = itemsByCategory[categories[0]][0];

  return (
    <div className="menu-type-group">
      <div className="type">{menuType !== 'undefined' ? menuType : ''}</div>
      {categories.map((categoryId: string) => (
        <Fragment key={categoryId}>
          <div className="product-category">{Constants.PRODUCT_CATEGORIES[categoryId].name}</div>
          {itemsByCategory[categoryId].map((item: any, index: number) => (
            <Item item={item} key={`${item.id}.${index}`} showPrices={showPrices} />
          ))}
        </Fragment>
      ))}
      {allowOrderComments && (
        <InputField
          id={`${cartItem.id}-comments`}
          label="Comments"
          className="cart-comments"
          value={cartItem.comments ?? ''}
          inputProps={{ maxLength: 30 }}
          onChange={(e) => {
            updateComments(cartItem.id, e.target.value);
          }}
        />
      )}
    </div>
  );
};

const Item = (props: any) => {
  const { item, showPrices } = props;
  const dispatch = useDispatch();
  const student = useSelector(ShopSelectors.getStudent);
  const timezone = useSelector(ShopSelectors.getDistrictTimezone);
  const districts = useSelector(CommonSelectors.getDistricts);
  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const hasALaCarteViolation = useSelector(ShopSelectors.hasALaCarteViolation(userRole));
  const isParent = isAuthorized(userRole, [Role.PARENT]);
  const district = districts[student?.districtId];

  const date = moment(item.date, Constants.DATE_FORMAT_YMD);

  const orderingRuleViolation = useMemo(
    () => isParent && isViolatingOrderingRules(district, date, moment(), timezone),
    [date, district, isParent, timezone],
  );

  const aLaCarteOrderingRuleViolation = useMemo(
    () => isParent && item.productCategoryId === Constants.PRODUCT_CATEGORIES.ALACARTE.id && hasALaCarteViolation,
    [hasALaCarteViolation, isParent, item.productCategoryId],
  );

  const childItems = useMemo(() => orderBy(item.items, 'productCategoryId'), [item]);

  function removeItem(item: CartItem) {
    dispatch(CartActions.removeItem(item, -1));
  }

  const displayPrice = showPrices ? numeral(item.price).format(Constants.USD_FORMAT) : '';

  return (
    <div className="item" key={item.id}>
      <div className="details">
        <span className="name">{item.productName}</span>
        <span className="right">
          <span className="price">{displayPrice}</span>
          <IconButton
            aria-label={`Remove item from cart: ${item.mealType} - ${item.productName} ${displayPrice}`}
            className={'btn-reset'}
            onClick={() => removeItem(item)}
            data-test-id={`${item.mealType}.${item.productName}.deleteBtn`}
          >
            <TrashIcon />
          </IconButton>
        </span>
      </div>
      {childItems.length > 0 &&
        childItems.map((childItem: any) => (
          <div className="child-detail" key={childItem.id}>
            <span className="product-category">{Constants.PRODUCT_CATEGORIES[childItem.productCategoryId].name}</span>
            <span className="name">{childItem.productName}</span>
          </div>
        ))}
      {orderingRuleViolation && (
        <div className="ordering-rules">
          <div>
            <Warning className="warning" name="warning" />
            <span className="text">This item has expired and will be removed from your order</span>
          </div>
        </div>
      )}
      {aLaCarteOrderingRuleViolation && (
        <div className="ordering-rules">
          <Warning className="warning" name="warning" />
          <span className="text">This item exceeds your balance and will be removed from your order</span>
        </div>
      )}
    </div>
  );
};

export default Cart;
