import { IconButton, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import TrashIcon from '@material-ui/icons/Delete';

import classNames from 'classnames';
import { InputMode } from 'Constants/Enums';
import { getValueFromInputMode } from 'Helpers/Helper';
import React from 'react';
import './IncrementalNumberInput.less';

interface IncrementalNumberInputProps {
  className?: string;
  value?: number | string;
  // Must be provided if not using the initialValue prop
  onChange?: (value: number | string) => void;
  min: number;
  max: number;
  inputHeight?: number;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>, { value }: { value: number }) => void;
  onAddOrSubstract: (value: number) => void;
  onDeleteClick?: () => void;

  //  Delegates input state control to the input component instead of relying on the parent
  //  Useful for react-table editable fields for preserving input focus which is lost when updating a table's data object
  initialValue?: number;
}

const IncrementalNumberInput = ({
  value,
  onChange,
  min,
  max,
  className,
  inputHeight,
  onBlur,
  onAddOrSubstract,
  onDeleteClick,
  initialValue,
}: IncrementalNumberInputProps) => {
  const [localValue, setLocalValue] = React.useState<string | number | undefined>(initialValue);

  React.useEffect(() => {
    setLocalValue(initialValue);
  }, [initialValue]);
  const inputValue = initialValue !== undefined ? localValue : value;
  const number = parseInt(String(inputValue), 10);

  return (
    <div className="incremental-number-input">
      {onDeleteClick && (
        <IconButton
          color="primary"
          aria-label="delete bulk item button"
          onClick={() => {
            onDeleteClick();
          }}
          className="incremental-number-input__icon-btn"
          data-test-id="deleteBulkItemBtn"
        >
          <TrashIcon />
        </IconButton>
      )}
      <IconButton
        color="primary"
        aria-label="decrement button"
        onClick={() => {
          if (number - 1 >= min) {
            onAddOrSubstract(number - 1);
          }
        }}
        disabled={number === min || inputValue === ''}
        className="incremental-number-input__icon-btn"
        data-test-id="decrementBtn"
      >
        <RemoveIcon className="incremental-number-input__minus" />
      </IconButton>
      <TextField
        className={classNames('incremental-number-input__text-field', className)}
        variant="outlined"
        value={initialValue !== undefined ? localValue : inputValue}
        onChange={(e) => {
          const newValue = getValueFromInputMode({
            mode: InputMode.Integer,
            size: [4, 0],
            value: e.target.value,
            allowNegative: false,
          }) as string;

          if (!newValue) {
            if (initialValue === undefined && onChange) {
              onChange('');
              return;
            }
            setLocalValue('');
            return;
          }

          const newNumber = parseInt(newValue, 10);
          if (newNumber >= min && newNumber <= max) {
            if (initialValue === undefined && onChange) {
              onChange(newNumber);
              return;
            }
            setLocalValue(newNumber);
          }
        }}
        inputProps={{
          style: inputHeight ? { height: inputHeight } : undefined,
          'data-test-id': 'quantityInput',
        }}
        onBlur={(e) => {
          if (inputValue === '') {
            if (onChange) {
              onChange(0);
            } else {
              setLocalValue(0);
            }
          } else {
            if (onBlur) {
              onBlur(e, { value: inputValue as number });
            }
          }
        }}
      />
      <IconButton
        color="primary"
        aria-label="increment count"
        onClick={() => {
          if (!inputValue) {
            onAddOrSubstract(1);
            return;
          }
          if (number + 1 <= max) {
            onAddOrSubstract(number + 1);
          }
        }}
        disabled={number === max}
        className="incremental-number-input__icon-btn"
        data-test-id="incrementBtn"
      >
        <AddIcon className="incremental-number-input__add" />
      </IconButton>
    </div>
  );
};

export default IncrementalNumberInput;
