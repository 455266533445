import { useAuth0 } from '@auth0/auth0-react';
import { CheckCircle, Warning, Cancel, Image, InsertDriveFile, Sync, Settings } from '@material-ui/icons';
import { Card, CardContent } from '@material-ui/core';
import { TokenClaims } from 'Auth/User';
import Button from 'Components/Button/Button';
import { ButtonLink } from 'Components/ButtonLink/ButtonLink';
import * as DistrictActions from 'Components/District/state/actions';
import * as DistrictSelectors from 'Components/District/state/selectors';
import FileDropzone from 'Components/FileDropzone/FileDropzone';
import ReactTable from 'Components/ReactTable/ReactTable';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import { NotificationDialog } from 'Components/Dialogs/NotificationDialog';
import { validateFiles, validateFileNames } from 'Helpers/Helper';
import { useInterval } from 'Helpers/useInterval';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NotificationContext } from '../../NotificationContextProvider';

import './ImportData.less';

const studentTemplate = [
  ['id', 'firstName', 'lastName', 'siteId', 'grade', 'homeroomId', 'status', 'balance', 'birthdate', 'allergens'],
];
const adultTemplate = [['id', 'firstName', 'lastName', 'siteId', 'homeroomId', 'status', 'balance', 'allergens']];
const teacherTemplate = [['email', 'firstName', 'lastName', 'homeroomId', 'siteId']];
const adminTemplate = [['email', 'firstName', 'lastName', 'siteId', 'role']];
const encoding = 'data:text/csv;charset=utf-8,';
const validImportFileNames = [
  'students.csv',
  'teachers.csv',
  'admins.csv',
  'adults.csv',
  'titan.csv',
  'nutrikids.txt',
  'pos.csv',
];

const csvContentStudent = encoding + studentTemplate.map((e) => e.join(',')).join('\n');
var encodedUriStudent = encodeURI(csvContentStudent);

const csvContentTeacher = encoding + teacherTemplate.map((e) => e.join(',')).join('\n');
var encodedUriTeacher = encodeURI(csvContentTeacher);

const csvContentAdmin = encoding + adminTemplate.map((e) => e.join(',')).join('\n');
var encodedUriAdmin = encodeURI(csvContentAdmin);

const csvContentAdult = encoding + adultTemplate.map((e) => e.join(',')).join('\n');
var encodedUriAdult = encodeURI(csvContentAdult);

const ImportData = () => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [showAdminImportConfirmOpen, setShowAdminImportConfirmOpen] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState(null);
  const { addError } = React.useContext(NotificationContext);
  const { getLogs, importData } = DistrictActions.useDistrictActions();

  const [pollTimeoutCount, setPollTimeoutCount] = useState(0);
  const [pollInterval] = useState(15 * 1000);
  const numberOfLogPings = 12;
  const pollMaxTimeout = pollInterval * numberOfLogPings;
  const [isPolling, setIsPolling] = useState(false);

  const { user } = useAuth0();

  const districtId = TokenClaims.getDistrictId(user);
  const logs = useSelector(DistrictSelectors.getLogsSelector);
  const [logCount, setLogCount] = useState(logs.length);

  const fetchData = useCallback(async () => {
    await getLogs(districtId);
    setPageLoading(false);
  }, [districtId, getLogs]);

  const maybeStopPolling = useCallback(() => {
    if (logs.length > logCount || pollTimeoutCount >= pollMaxTimeout) {
      setIsPolling(false);
      toast.info(`Last updated at ${moment(new Date()).format('LTS')}.`, {
        position: 'bottom-right',
        autoClose: 5000,
        closeOnClick: true,
      });
    }
  }, [logCount, logs.length, pollMaxTimeout, pollTimeoutCount]);

  useEffect(() => {
    fetchData();
    if (isPolling) {
      maybeStopPolling();
    }
  }, [fetchData, isPolling, logs.length, maybeStopPolling]);

  useInterval(
    () => {
      getLogs(districtId);
      setPollTimeoutCount(pollTimeoutCount + pollInterval);
      maybeStopPolling();
    },
    isPolling ? pollInterval : null,
  );

  const enableRealTimeUpdates = () => {
    setPollTimeoutCount(0);
    setLogCount(logs.length);
    setIsPolling(true);
    getLogs(districtId);
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const validExtention = validateFiles(acceptedFiles, ['.csv', '.txt', '.gif', '.jpg', '.jpeg', '.png']);
      const validFileName = validateFileNames(acceptedFiles, validImportFileNames, ['.csv', '.txt']);
      if (!validExtention) {
        addError({ message: 'Invalid file extension.' });
      } else if (!validFileName) {
        addError({
          message: `Inavlid file name. Only files named ${validImportFileNames.join(', ').trimEnd()} can be imported.`,
        });
      } else if (acceptedFiles[0]?.name === 'admins.csv') {
        setShowAdminImportConfirmOpen(true);
        setAcceptedFiles(acceptedFiles);
      } else {
        setLoading(true);
        setLogCount(logs.length);
        await importData(acceptedFiles);
        setLoading(false);
        setIsPolling(true);
      }
    },
    [logs, importData, addError],
  );

  const handleOkClick = async () => {
    setShowAdminImportConfirmOpen(false);
    setLoading(true);
    setLogCount(logs.length);
    await importData(acceptedFiles);
    setLoading(false);
    setIsPolling(true);
  };

  return (
    <div className="import-data-container">
      <SubHeader title="Import Data" />
      <div className="body page-content-wrapper">
        <div className="import-header">
          <div>
            {`Upload students.csv to create and update students. Upload adults.csv to create and update adults. Upload admins.csv to create and invite director or manager users. Upload
            teachers.csv to create and invite teachers.`}
          </div>
          <div>
            <ButtonLink
              href={encodedUriStudent}
              download="students.csv"
              text="STUDENT CSV TEMPLATE"
              data-test-id="studentCsvTemplateBtn"
            />

            <ButtonLink
              href={encodedUriAdult}
              download="adults.csv"
              text="ADULT CSV TEMPLATE"
              data-test-id="adultCsvTemplateBtn"
            />

            <ButtonLink
              href={encodedUriAdmin}
              download="admins.csv"
              text="ADMIN CSV TEMPLATE"
              data-test-id="adminCsvTemplateBtn"
            />

            <ButtonLink
              href={encodedUriTeacher}
              download="teachers.csv"
              text="TEACHER CSV TEMPLATE"
              data-test-id="teacherCsvTemplateBtn"
            />
          </div>
        </div>
        <div className="oo-row">
          <div className="import-data-card">
            <FileDropzone onDrop={onDrop} loading={loading} />
          </div>
        </div>
        <div className="oo-row import-log-container">
          <Card className="import-log-card import-log-card-updates">
            <CardContent>
              {isPolling && !loading ? (
                <>
                  <Settings className="spin" /> Checking for log updates...
                  <Button onClick={() => setIsPolling(false)} aria-label="Cancel checking for updates">
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={enableRealTimeUpdates}
                  aria-label="Turn on real-time updates"
                  data-test-id="enableRealTimeLogUpdatesBtn"
                >
                  Enable real-time log updates
                </Button>
              )}
            </CardContent>
          </Card>
          {logs.length > 0 && <UploadLogs uploadLogs={logs} />}
          {!pageLoading && logs.length === 0 && (
            <Card className="import-log-card import-log-card-empty">
              <CardContent>There are currently no logs for your district.</CardContent>
            </Card>
          )}
        </div>
      </div>
      <NotificationDialog
        title={'Create Online Ordering Only Admins?'}
        message={'Admin users created through this import will not be able to log in to ISITE.'}
        showDialog={showAdminImportConfirmOpen}
        showCancel={true}
        cancelButtonText="CANCEL"
        okButtonText="Import"
        onOkClick={handleOkClick}
        onCancelClick={() => setShowAdminImportConfirmOpen(false)}
        dialogType="info"
        okIcon="Import"
      />
    </div>
  );
};

const UploadLogs = (props: any) => {
  let columns = [
    {
      Header: 'TYPE',
      accessor: 'filetype',
      width: '70',
      Cell: (props: any) => {
        const filetypeIcon: any = {
          csv: <InsertDriveFile className="filetype-icon" />,
          txt: <InsertDriveFile className="filetype-icon" />,
          png: <Image className="filetype-icon" />,
          jpg: <Image className="filetype-icon" />,
          jpeg: <Image className="filetype-icon" />,
          gif: <Image className="filetype-icon" />,
        };
        const filetype: string = props.row.original.filename
          ? props.row.original.filename.substring(props.row.original.filename.lastIndexOf('.') + 1)
          : '';
        const icon = filetypeIcon[filetype.toLowerCase()] || <Sync className="filetype-icon" />;
        return icon || '';
      },
      disableSortBy: true,
    },
    {
      Header: 'FILE NAME',
      accessor: 'filename',
      width: '100%',
      Cell: (props: any) => {
        return props.row.original.filename || '';
      },
      disableSortBy: false,
    },
    {
      Header: '# IMPORTED',
      accessor: 'importCount',
      width: '100%',
      Cell: (props: any) => {
        return props.row.original.importCount || 0;
      },
      disableSortBy: false,
    },
    {
      Header: 'TOTAL #',
      accessor: 'recordCount',
      width: '100%',
      Cell: (props: any) => {
        return props.row.original.recordCount || 0;
      },
      disableSortBy: false,
    },
    {
      Header: '# UPDATED',
      accessor: 'recordDelta',
      width: '100%',
      Cell: (props: any) => {
        return props.row.original.recordDelta || 0;
      },
      disableSortBy: false,
    },
    {
      Header: 'DATE UPLOADED',
      accessor: 'date',
      width: '200',
      Cell: (props: any) => {
        return moment(props.row.original.date).format('MM/DD/YYYY h:mm:ss A') || '';
      },
      disableSortBy: false,
    },
    {
      Header: 'AUTHOR',
      accessor: 'username',
      width: '100%',
      Cell: (props: any) => {
        return props.row.original.username || props.row.original.email;
      },
      disableSortBy: false,
    },
    {
      Header: 'MESSAGE',
      accessor: 'message',
      width: '100%',
      Cell: (props: any) => {
        return props.row.original.message || '';
      },
      disableSortBy: true,
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      width: '100%',
      Cell: (props: any) => {
        let statusRow;
        if (props.row.original.status === 'Success') {
          statusRow = (
            <>
              <CheckCircle className="check-icon" />
              &nbsp;SUCCESS
            </>
          );
        } else if (props.row.original.status === 'Warning') {
          statusRow = (
            <>
              <Warning className="warning-icon" />
              &nbsp;WARNING
            </>
          );
        } else if (props.row.original.status === 'Failure') {
          statusRow = (
            <>
              <Cancel className="error-icon" />
              &nbsp;ERROR
            </>
          );
        }
        return statusRow || '';
      },
      disableSortBy: false,
    },
  ];
  const initialState = {
    sortBy: [{ id: 'date', desc: true }],
  };
  return <ReactTable initialState={initialState} columns={columns} data={props.uploadLogs} showFooter={true} />;
};

export default ImportData;
