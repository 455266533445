import React, { FC } from 'react';
import { get } from 'lodash';
import { REPORT_SCOPE } from 'Constants/Enums';
import { District, PickupLocation, Site } from 'Interfaces/Interfaces';

export interface TitleProps {
  district: District;
  pickupLocation?: PickupLocation;
  scope: REPORT_SCOPE;
  site?: Site;
}

const Title: FC<TitleProps> = ({ scope, site, district, pickupLocation }) => {
  let headerText = '';

  switch (scope) {
    case REPORT_SCOPE.DISTRICT:
      headerText = get(district, 'name', '');
      break;
    case REPORT_SCOPE.SITE:
      headerText = get(site, 'name', '');
      break;
    case REPORT_SCOPE.LOCATION:
    case REPORT_SCOPE.LOCATION_GLOBAL:
      headerText = get(pickupLocation, 'name', '');
      break;
    default:
      headerText = '';
      break;
  }

  return <h2>Reporting: {headerText}</h2>;
};

export default Title;
