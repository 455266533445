import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PICKUP_LOCATION_RULES } from 'Constants/Constants';
import { AppState, OrderingRules } from 'Interfaces/Interfaces';
import Card from 'Components/Card/Card';
import * as CommonStateSelectors from 'State/selectors';
import * as CommonStateActions from 'State/actions';
import PickupLocationsRulesSidebar from '../Sidebars/PickupLocationsRulesSidebar';
import { SidebarLocations } from 'Constants/Enums';

export const RulePickupLocations: FC<{ orderingRules: OrderingRules }> = ({ orderingRules }) => {
  const sidebarOpen = useSelector((state: AppState) =>
    CommonStateSelectors.getSidebarOpen(state, SidebarLocations.PICKUP_LOCATIONS_RULES_SIDE_BAR),
  );
  const dispatch = useDispatch();

  const setSidebarOpen = (isOpen: boolean) => {
    dispatch(CommonStateActions.setSidebarOpen(isOpen, SidebarLocations.PICKUP_LOCATIONS_RULES_SIDE_BAR));
  };

  const pickupLocationTypeText = orderingRules?.pickupSelectionType
    ? PICKUP_LOCATION_RULES[orderingRules?.pickupSelectionType].name
    : PICKUP_LOCATION_RULES['IndividualMeal'].name;

  return (
    <>
      <Card
        title="Pickup Location"
        description="Set pickup location per meal or order"
        onEditClick={() => setSidebarOpen(true)}
        editAriaLabel="Edit Pickup Location Rules"
      >
        <div className="ordering-rules__rules">
          <div className="ordering-rules__setting">
            <h6 className="linq-card__h6">Pickup Location</h6>
            <div className="setting-value" data-test-id={'pickupLocationLbl'}>
              {pickupLocationTypeText}
            </div>
          </div>
        </div>
      </Card>
      <PickupLocationsRulesSidebar open={sidebarOpen} setSidebarOpen={setSidebarOpen} orderingRules={orderingRules} />
    </>
  );
};
