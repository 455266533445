import Button from 'Components/Button/Button';
import Modal from 'Components/Modal/Modal';
import * as CartActions from 'Components/Shop/Cart/state/actions';
import * as StudentCartSwitchModalActions from 'Components/Student/SwitchStudentCartModal/state/actions';
import { RouteId, Routes } from 'Constants/Routes';
import * as ShopActions from 'Pages/Shop/state/actions';
import * as ShopSelectors from 'Pages/Shop/state/selectors';
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as SwitchStudentCartSelector from './state/selectors';
import './SwitchStudentCartModal.less';

export interface SwitchStudentCarModalProps {
  route?: string;
}

const SwitchStudentCartModal = ({ route }: SwitchStudentCarModalProps): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOpen = useSelector(SwitchStudentCartSelector.getOpen);
  const newStudentId = useSelector(SwitchStudentCartSelector.getNewStudentId);
  const currentStudent = useSelector(ShopSelectors.getStudent);
  const currentStudentName = currentStudent?.firstName ?? '';

  const closeModal = () => {
    dispatch(StudentCartSwitchModalActions.setOpen(false, ''));
  };

  const switchStudent = () => {
    dispatch(ShopActions.setStudentId(newStudentId));
    dispatch(CartActions.clearCart());
    dispatch(StudentCartSwitchModalActions.setOpen(false, ''));
    let newRoute = route || (Routes[RouteId.SHOP]?.path as string);
    navigate(newRoute);
  };

  return (
    <Modal open={isOpen} maxWidth="md" onClose={closeModal}>
      <Modal.Content>
        <div className="switch-student-cart-content">
          There is an order in progress for <b>{currentStudentName}</b>. Are you sure you want to clear the order and
          continue?
        </div>
      </Modal.Content>
      <Modal.Actions className="switch-student-cart-actions">
        <Button variant="outlined" color="primary" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={switchStudent}>
          Continue
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default SwitchStudentCartModal;
