import { useAuth0 } from '@auth0/auth0-react';
import { useApi } from 'Api/useApi';
import { TokenClaims } from 'Auth/User';
import * as AddStudentModalActions from 'Components/Student/AddStudentModal/state/actions';
import * as Constants from 'Constants/Constants';
import { InactiveStatuses } from 'Constants/Enums';
import { excludeIdFromOrderSortId } from 'Helpers/Helper';
import * as Interfaces from 'Interfaces/Interfaces';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { deleteOrderItem as deleteTeacherOrderItem } from 'Pages/TeacherRosterOrdering/state/actions';
import { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ActionCreator } from 'redux';
import * as CommonSelectors from 'State/selectors';
import * as Types from './types';

export const useCommonActions = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const districts = useSelector(CommonSelectors.getDistricts);
  const districtId = TokenClaims.getDistrictId(user);
  const userSiteId = TokenClaims.getSiteId(user);

  const [actions] = useState({
    getDistricts: async (filter: string = 'name') => {
      try {
        const results = (await api.get(`/districts`, {
          params: { filter },
        })) as any;
        dispatch(setDistricts(results?.data.items));
      } catch (err) {}
    },
    updateDistrict: async (data: any) => {
      try {
        const results = (await api.patch(`/districts/${districtId}`, data)) as any;
        const newDistricts = cloneDeep(districts);
        newDistricts[results.data?.id] = results.data;
        dispatch(setDistricts(newDistricts));
      } catch (err) {}
    },
    updateSite: async (data: any, sites: Record<string, Interfaces.Site>) => {
      try {
        const results = (await api.patch(`/districts/${districtId}/sites/${userSiteId}`, data)) as any;
        const newSites = cloneDeep(sites);
        newSites[results.data?.id] = results.data;
        dispatch(setSites(newSites));
      } catch (err) {}
    },
    getProducts: async (districtId: string) => {
      try {
        const results = (await api.get(`/districts/${districtId}/products`)) as any;
        dispatch(setProducts(results?.data?.items));
      } catch (err) {}
    },
    getMenuItems: async (districtId: string) => {
      try {
        const results = (await api.get(`/districts/${districtId}/menuItems`)) as any;
        dispatch(setMenuItems(results?.data?.items));
      } catch (err) {}
    },
    getOrderItems: async (params?: any) => {
      try {
        const results = (await api.get(`/orderItems`, {
          params: {
            dateFrom: moment().subtract(1, 'w').format(Constants.DATE_FORMAT_YMD),
            dateTo: moment().add(1, 'w').format(Constants.DATE_FORMAT_YMD),
            ...params,
          },
        })) as any;
        dispatch(setOrderItems(results?.data?.items));
      } catch (err) {}
    },
    getDistrictOrderItems: async (params?: any) => {
      try {
        const results = (await api.get(`/districts/${districtId}/orderItems`, {
          params: {
            dateFrom: moment().subtract(1, 'w').format(Constants.DATE_FORMAT_YMD),
            dateTo: moment().add(1, 'w').format(Constants.DATE_FORMAT_YMD),
            ...params,
          },
        })) as any;
        dispatch(setOrderItems(results?.data?.items));
      } catch (err) {}
    },
    getSites: async (districtId: string) => {
      try {
        const results = (await api.get(`/districts/${districtId}/sites`)) as any;
        dispatch(setSites(results?.data?.items));
      } catch (err) {}
    },
    getHomerooms: async (siteId?: string) => {
      try {
        const results = (await api.get(`/districts/${districtId}/sites/${siteId || userSiteId}/homerooms`)) as any;
        dispatch(setHomerooms(results?.data?.items));
      } catch (err) {}
    },
    // District Only Action
    getPickupLocations: async (districtId: string, siteId?: string) => {
      try {
        const results = (await api.get(`/districts/${districtId}/pickupLocations`, {
          params: {
            siteId,
          },
        })) as any;
        dispatch(setPickupLocations(results?.data?.items));
      } catch (err) {}
    },
    // District Only Action
    updatePickupLocations: async (data: any) => {
      try {
        const results = (await api.post(`/districts/${districtId}/pickupLocations`, data)) as any;
        if ([200, 201].includes(results.status)) {
          dispatch(resetPickupLocation(results?.data?.items));
        }
      } catch (err) {}
    },
    // District Only Action
    deletePickupLocation: async (pickupLocation: Interfaces.PickupLocation) => {
      try {
        const result = (await api.delete(
          `/districts/${districtId}/sites/${pickupLocation.siteId}/pickupLocations/${pickupLocation.id}`,
        )) as any;
        if (result.status === 204) {
          dispatch(pickupLocationDeleted(pickupLocation));
        }
      } catch (err) {}
    },
    attachStudent: async (data: Interfaces.CreateStudent, districtId: string) => {
      try {
        const results = (await api.post(`/districts/${districtId}/students`, data)) as any;
        if (results.status === 201) {
          dispatch(addStudent(results?.data));
          dispatch(AddStudentModalActions.setOpen(false));
        }
      } catch (err) {}
    },
    updateStudent: async (student: Interfaces.Student) => {
      try {
        const results = await api.patch<Interfaces.Student, Partial<Interfaces.Student>>(
          `/districts/${student.districtId}/students/${student.id}`,
          student,
        );
        if (results.status === 200) {
          dispatch(addStudent(results?.data));
        }
      } catch (err) {}
    },
    unlinkStudent: async (studentId: string) => {
      try {
        const results = await api.post<{ resourceId: string; principalId: string }, Partial<Interfaces.Student>>(
          '/utility/user/unlink/student',
          {
            id: studentId,
          },
        );
        if (results.status === 200) {
          dispatch(removeStudent(results?.data?.resourceId));
        }
      } catch (err) {}
    },
    deleteOrderItems: async (orderItems: Partial<Interfaces.OrderItem>[] = []) => {
      try {
        const orderDistrictId = orderItems[0].districtId;
        const patchItems: Interfaces.PatchOrderItem[] = [];
        orderItems.forEach((orderItem: Partial<Interfaces.OrderItem>) => {
          // Multiple orders of an a la carte item will have their ids nested in the OrderItem
          if (orderItem.id && orderDistrictId) {
            if (orderItem.total && orderItem.total > 1) {
              const idMatcher = excludeIdFromOrderSortId(orderItem.id);

              Object.keys(orderItem).forEach((key) => {
                if (key.startsWith(idMatcher)) {
                  patchItems.push({
                    districtId: orderDistrictId,
                    id: key,
                    canceled: true,
                    date: orderItem.date,
                  });
                }
              });
            } else {
              patchItems.push({ districtId: orderDistrictId, id: orderItem.id, canceled: true, date: orderItem.date });
            }
          }
        });

        const patchResponse = await api.patch<Interfaces.OrderItem[], Partial<Interfaces.OrderItem>[]>(
          `/districts/${orderDistrictId}/orderItems`,
          patchItems,
        );

        batch(() => {
          patchResponse.data.forEach((orderItem) => {
            dispatch(orderItemDeleted(orderItem.id));
            dispatch(deleteTeacherOrderItem(orderItem));
          });
        });
      } catch (ex) {}
    },
    // District Only Action
    patchOrderItem: async (patchOrderItem: Interfaces.PatchOrderItem) => {
      try {
        const result = await api.patch<Interfaces.OrderItem, Partial<Interfaces.PatchOrderItem>>(
          `/districts/${patchOrderItem.districtId}/orderItems/${patchOrderItem.id}`,
          patchOrderItem,
        );
        if (result.status === 200) {
          dispatch(orderItemPatched(result?.data));
        }
      } catch (err) {}
    },
    suspendStudent: async (districtId: string, sisId: string, suspended: boolean) => {
      try {
        const result = (await api.patch(`/districts/${districtId}/students/${sisId}`, {
          districtId,
          sisId,
          suspended,
        })) as any;
        const student = result.data;
        dispatch(updateStudent(student));
      } catch (err) {}
    },
    withdrawStudent: async (sisId: string) => {
      try {
        const result = (await api.patch(`/districts/${districtId}/students/${sisId}`, {
          districtId,
          sisId,
          status: InactiveStatuses.Withdrawn,
        })) as any;
        const student = result.data;
        dispatch(updateStudent(student));
      } catch (err) {}
    },
    deactivateAdult: async (sisId: string) => {
      try {
        const result = (await api.patch(`/districts/${districtId}/students/${sisId}`, {
          districtId,
          sisId,
          status: InactiveStatuses.Inactive,
        })) as any;
        const student = result.data;
        dispatch(updateStudent(student));
      } catch (err) {}
    },
  });

  return actions;
};

export const setPickupLocations: ActionCreator<Interfaces.Action> = (payload: {
  [key: string]: Interfaces.PickupLocation;
}) => ({
  type: Types.SET_PICKUP_LOCATIONS,
  payload,
});

export const resetPickupLocation: ActionCreator<Interfaces.Action> = (payload: {
  [key: string]: Interfaces.PickupLocation;
}) => ({
  type: Types.UPDATE_PICKUP_LOCATIONS,
  payload,
});
export const pickupLocationDeleted: ActionCreator<Interfaces.Action> = (payload: Interfaces.PickupLocation) => ({
  type: Types.PICKUP_LOCATION_DELETED,
  payload,
});

export const setHomerooms: ActionCreator<Interfaces.Action> = (payload: { [key: string]: Interfaces.Homeroom }) => ({
  type: Types.SET_HOME_ROOMS,
  payload,
});

export const setMenuTypes: ActionCreator<Interfaces.Action> = (payload: { [key: string]: Interfaces.MenuType }) => ({
  type: Types.SET_MENU_TYPES,
  payload,
});

export const setMenuItems: ActionCreator<Interfaces.Action> = (payload: { [key: string]: Interfaces.MenuItem }) => ({
  type: Types.SET_MENU_ITEMS,
  payload,
});

export const setProducts: ActionCreator<Interfaces.Action> = (payload: { [key: string]: Interfaces.Product }) => ({
  type: Types.SET_PRODUCTS,
  payload,
});

export const addStudent: ActionCreator<Interfaces.Action> = (payload: { [key: string]: Interfaces.Student }) => ({
  type: Types.ADD_STUDENT,
  payload,
});

export const orderItemDeleted: ActionCreator<Interfaces.Action> = (payload: Interfaces.OrderItem) => ({
  type: Types.ORDER_ITEM_DELETED,
  payload,
});

export const orderItemPatched: ActionCreator<Interfaces.Action> = (payload: Interfaces.OrderItem) => ({
  type: Types.ORDER_ITEM_PATCHED,
  payload,
});

export const setProductCategories: ActionCreator<Interfaces.Action> = (payload: {
  [key: string]: Interfaces.ProductCategory;
}) => ({
  type: Types.SET_PRODUCT_CATEGORIES,
  payload,
});

export const setReimbursableMealTypes: ActionCreator<Interfaces.Action> = (payload: {
  [key: string]: Interfaces.ReimbursableMealType;
}) => ({
  type: Types.SET_REIMBURSABLE_MEAL_TYPES,
  payload,
});

export const setSiteGroupTypes: ActionCreator<Interfaces.Action> = (payload: {
  [key: string]: Interfaces.SiteGroupType;
}) => ({
  type: Types.SET_SITE_GROUP_TYPES,
  payload,
});

export const setSpecialMealAccommodations: ActionCreator<Interfaces.Action> = (payload: {
  [key: string]: Interfaces.SpecialMealAccommodation;
}) => ({
  type: Types.SET_SPECIAL_MEAL_ACCOMMODATIONS,
  payload,
});

export const setStudents: ActionCreator<Interfaces.Action> = (
  students: { [key: string]: Interfaces.Student },
  totalCount: number,
  lastUpdatedAt?: Date,
) => ({
  type: Types.SET_STUDENTS,
  payload: {
    students,
    totalCount,
    lastUpdatedAt,
  },
});

export const removeStudent: ActionCreator<Interfaces.Action> = (payload: string) => ({
  type: Types.REMOVE_STUDENT,
  payload,
});

export const setOrderItems: ActionCreator<Interfaces.Action> = (payload: { [key: string]: Interfaces.OrderItem }) => ({
  type: Types.SET_ORDERS,
  payload,
});

export const setDistricts: ActionCreator<Interfaces.Action> = (payload: { [key: string]: Interfaces.District }) => ({
  type: Types.SET_DISTRICTS,
  payload,
});

export const setDistrict: ActionCreator<Interfaces.Action> = (payload: Interfaces.District) => ({
  type: Types.SET_DISTRICT,
  payload,
});

export const setSites: ActionCreator<Interfaces.Action> = (payload: { [key: string]: Interfaces.Site }) => ({
  type: Types.SET_SITES,
  payload,
});

export const closeAllSlideoutsAndModals: ActionCreator<Interfaces.Action> = (payload?: { [key: string]: string }) => ({
  type: Types.CLOSE_ALL_SLIDEOUTS_AND_MODALS,
  payload,
});

export const appendMenuItems: ActionCreator<Interfaces.Action> = (payload: { [key: string]: Interfaces.MenuItem }) => ({
  type: Types.APPEND_MENU_ITEMS,
  payload,
});

export const appendOrderItems: ActionCreator<Interfaces.Action> = (payload: {
  [key: string]: Interfaces.OrderItem;
}) => ({
  type: Types.APPEND_ORDER_ITEMS,
  payload,
});

export const appendProducts: ActionCreator<Interfaces.Action> = (payload: { [key: string]: Interfaces.Product }) => ({
  type: Types.APPEND_PRODUCTS,
  payload,
});

export const updateStudent: ActionCreator<Interfaces.Action> = (payload: Interfaces.Student) => ({
  type: Types.UPDATE_STUDENT,
  payload,
});

export const setSidebarOpen: ActionCreator<Interfaces.Action> = (isOpen: boolean, location: number) => ({
  type: Types.SET_SIDEBAR_OPEN,
  payload: { location: location, isOpen: isOpen },
});

export const toggleSidebarOpen: ActionCreator<Interfaces.Action> = (location: number) => ({
  type: Types.TOGGLE_SIDEBAR_OPEN,
  payload: { location: location },
});
