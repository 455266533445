import classNames from 'classnames';
import Button from 'Components/Button/Button';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'Components/Drawer';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import { PageLoader } from 'Components/Loaders/Loaders';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';
import { clearPickupLocations, useCartActions } from 'Components/Shop/Cart/state/actions';
import * as CartSelectors from 'Components/Shop/Cart/state/selectors';
import * as Constants from 'Constants/Constants';
import { SidebarLocations } from 'Constants/Enums';
import { RouteId, Routes } from 'Constants/Routes';
import { useBodyOverflow } from 'Helpers/useBodyOverflow';
import { groupBy } from 'lodash';
import { getStudent, getStudentDistrict } from 'Pages/Shop/state/selectors';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import * as CommonSelectors from 'State/selectors';
import { PickupByDate } from '../PickupByDate/PickupByDate';
import './Pickup.less';
import { PickupByOrder } from './PickupByOrder';

const groupByMealType = (items: any): object => {
  const grouped: any = {};

  Object.keys(items).forEach((date: any) => {
    const meals: any[] = items[date];
    grouped[date] = groupBy(meals, 'mealType');
  });

  return grouped;
};

const Pickup = (): ReactElement => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const navigate = useNavigate();
  const showPickup = useSelector(CartSelectors.getShowPickup);
  const showCart = useSelector(CartSelectors.getShowCart);
  const data = useSelector(CartSelectors.getCheckout);
  const pickupLocationOptionsByItem = useSelector(CartSelectors.getPickupLocationOptionsByItem);
  const pickupLocations = useSelector(CommonSelectors.getPickupLocationsWithDefault);
  const allPickupLocationsSelected = useSelector(CartSelectors.areAllPickupLocationsSelected);
  const existingLocations = useSelector(CartSelectors.getItemPickupLocations);
  const orderItems = useSelector(CartSelectors.getOrderItems);
  const district = useSelector(getStudentDistrict);
  const pickupSelectionType = district?.orderingRules?.pickupSelectionType;
  const student = useSelector(getStudent);
  const isAdult = student?.reimbursableMealTypeId === Constants.REIMBURSABLE_MEAL_TYPES.ADULT.id;
  const existingOrderItems = useSelector(CommonSelectors.getOrderItems);
  const headerText =
    pickupSelectionType === Constants.PICKUP_LOCATION_RULES['IndividualMeal'].id
      ? 'Choose a pickup location for each order'
      : 'Choose a pickup location for your order';
  const [submitting, setSubmitting] = useState<boolean>(false);
  const options = useSelector(CommonSelectors.getPickupLocationOptions);

  const groupedItems: any = groupByMealType(data.items);
  const { submitOrder, toggleCheckout, togglePickup } = useCartActions();

  useEffect(() => {
    dispatch(clearPickupLocations());
  }, [dispatch, showPickup]);

  function onCloseClick() {
    togglePickup();
  }

  function onBackToOrderClick() {
    togglePickup();
    toggleCheckout();
  }

  async function onSubmitOrder() {
    setSubmitting(true);
    await submitOrder(student, orderItems, existingOrderItems);
    setSubmitting(false);
    navigate(Routes[RouteId.ORDER_CONFIRMATION].path);
  }

  useBodyOverflow(showPickup || showCart);

  return (
    <Drawer
      anchor="right"
      open={showPickup}
      className={classNames('menu-item-modal-container', 'pickup-container', { mobile: isMobile })}
    >
      <PageLoader />
      <DrawerHeader>
        <h2>
          Pickup Locations <br />
          <small>{headerText}</small>
        </h2>
        <CloseIconButton onClick={onCloseClick} />
      </DrawerHeader>
      <NotificationBanner location={SidebarLocations.PICKUP_SIDE_BAR} />
      <DrawerContent>
        {(pickupSelectionType !== Constants.PICKUP_LOCATION_RULES['ByOrder'].id || isAdult) && (
          <>
            {Object.keys(groupedItems)
              .sort()
              .map((entry) => {
                const mealsByType: any[] = groupedItems[entry];
                return (
                  <PickupByDate
                    date={entry}
                    mealsByType={mealsByType}
                    pickupLocations={pickupLocations}
                    existingLocations={existingLocations}
                    key={entry}
                    options={pickupLocationOptionsByItem}
                  />
                );
              })}
          </>
        )}

        {pickupSelectionType === Constants.PICKUP_LOCATION_RULES['ByOrder'].id && !isAdult && (
          <PickupByOrder
            items={data.items}
            pickupLocations={pickupLocations}
            existingLocations={existingLocations}
            options={options}
          />
        )}
      </DrawerContent>
      <DrawerFooter>
        <Button color="primary" variant="outlined" onClick={onBackToOrderClick} data-test-id="backToOrderBtn">
          BACK TO ORDER
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onSubmitOrder}
          disabled={!allPickupLocationsSelected || !data || !data.count || data.count === 0 || submitting}
          loading={submitting}
          data-test-id="placeOrderBtn"
        >
          PLACE ORDER
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default Pickup;
