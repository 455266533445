import React from 'react';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { Divider, Accordion, AccordionDetails, AccordionSummary, Avatar } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from 'Components/Button/Button';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import { Drawer, DrawerFooter, DrawerHeader, DrawerContent } from 'Components/Drawer';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';
import * as Constants from 'Constants/Constants';
import { SidebarLocations } from 'Constants/Enums';
import * as Interfaces from 'Interfaces/Interfaces';
import { PageLoader } from 'Components/Loaders/Loaders';

import './OrderConfirmationSidebar.less';
import { binOrderItems, binBulkOrderItems, sortIsoDateString } from 'Helpers/Helper';
import moment from 'moment';
import { groupBy, uniq } from 'lodash';

interface OrderConfirmationSidebarProps {
  open: boolean;
  onClose: () => void;
  orderItemsById: Record<
    string,
    Interfaces.OrderItem[] | { error: { [key: string]: string }; orderItems: Interfaces.OrderItem[] }
  >;
  pickupLocations: Record<string, Interfaces.PickupLocation>;
  pickupLocationId: string;
  products: Record<string, Interfaces.Product>;
  students: Record<string, Interfaces.Student>;
}

const getErrorMessage = ({
  message,
  statusCode,
}: {
  message: string;
  name: string;
  occurredAt: string;
  statusCode: number;
  type: string;
}) => {
  if (
    (statusCode === 404 && message.includes('specified reimbursable meal price resource')) ||
    (statusCode === 400 && message.includes('meal price not set'))
  ) {
    return 'Pricing group not configured, unable to place order. Contact Nutrition Services Dept.';
  }

  const siteIdMismatchPattern = /pick up location site id [0-9]+ does not match student site id [0-9]+./g;
  if (statusCode === 400 && siteIdMismatchPattern.test(message)) {
    return 'Pickup location site does not match student site. Unable to place order.';
  }

  if (statusCode === 422) {
    return message;
  }

  return 'Unable to place order, contact Nutrition Services Dept.';
};

const OrderConfirmationSidebar = ({
  open,
  onClose,
  pickupLocations,
  pickupLocationId,
  orderItemsById,
  products,
  students,
}: OrderConfirmationSidebarProps) => {
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const pickupLocation = pickupLocations[pickupLocationId];

  const binnedOrderItemsByDate = React.useMemo(
    () => binOrderItems(orderItemsById, products),
    [orderItemsById, products],
  );

  const binnedBulkOrderItemsByDate = React.useMemo(
    () => binBulkOrderItems(orderItemsById, products),
    [orderItemsById, products],
  );

  const orderDates = React.useMemo(
    () =>
      uniq(Object.keys(binnedOrderItemsByDate).concat(Object.keys(binnedBulkOrderItemsByDate))).sort(sortIsoDateString),
    [binnedOrderItemsByDate, binnedBulkOrderItemsByDate],
  );

  const orderId = React.useMemo(() => {
    const keys = Object.keys(orderItemsById);
    const index = keys.findIndex((key) => Array.isArray(orderItemsById[key]));

    if (index > -1) {
      const orderItems = orderItemsById[keys[index]] as Interfaces.OrderItem[];
      const orderId = orderItems[0]?.orderId;
      return orderId || null;
    }
    return null;
  }, [orderItemsById]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      className={classNames('menu-item-modal-container order-confirmation-sidebar', { mobile: isMobile })}
    >
      <PageLoader />
      <div className="linq-drawer-form-content menu-item-modal-content">
        <DrawerHeader aria-label="Header for the current menu item.">
          <h2>Order Submitted</h2>
          <CloseIconButton onClick={onClose} />
        </DrawerHeader>
        <NotificationBanner location={SidebarLocations.ORDER_CONFIRMATION_SIDE_BAR} />
        <DrawerContent id="menu-item-modal-content" className="menu-item-modal-content">
          <div className="order-id">{orderId}</div>
          <div className="pickup">Pickup: {pickupLocation?.name}</div>
          {orderDates.map((date: string) => {
            const binnedOrderItems = binnedOrderItemsByDate[date] || {};
            const binnedBulkOrderItems = binnedBulkOrderItemsByDate[date] || {};
            const binItemsByMenuType = groupBy(Object.values(binnedOrderItems), 'menuTypeName');
            const failedOrders = binnedOrderItems?.errors || {};
            return (
              <React.Fragment key={date}>
                <Divider />
                <Accordion className="accordion-container oo-accordion" defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="date-toggle__icon" />}
                    aria-controls={`${date}-content`}
                  >
                    {moment(date).format(Constants.DATE_FORMAT_DMDDY)}
                  </AccordionSummary>
                  <AccordionDetails>
                    {Object.keys(binnedOrderItems).length > 1 && (
                      <>
                        <h3 className="accordion-title">Individual Orders</h3>
                        <Divider />
                      </>
                    )}
                    {Object.entries(binItemsByMenuType).map(([menuTypeName, binItems]) => {
                      if (menuTypeName !== 'undefined') {
                        return (
                          <React.Fragment key={menuTypeName}>
                            <div className="menu-type">{menuTypeName}</div>
                            {binItems.map((bin) => {
                              if (!Array.isArray(bin)) {
                                return (
                                  <React.Fragment key={`${bin.productName}${bin.sides}`}>
                                    <div className="side-bar__entree order-confirmation__entree">
                                      {bin.productName}
                                      <div className="side-bar__total" data-test-id={`${bin.productName}.indvTotalLbl`}>
                                        {bin.total}
                                      </div>
                                    </div>
                                    <div className="side-bar__sides">{bin.sides}</div>
                                  </React.Fragment>
                                );
                              }
                              return null;
                            })}
                          </React.Fragment>
                        );
                      }
                      return null;
                    })}
                    {Object.keys(failedOrders).length > 0 && (
                      <>
                        <h3 className="accordion-title">Failed Orders</h3>
                        <Divider />
                      </>
                    )}
                    {Object.entries(failedOrders).map(([studentId, error]) => {
                      const student = students[studentId];

                      if (!student) {
                        return null;
                      }

                      const studentName = `${student.firstName} ${student.lastName}`;
                      return (
                        <React.Fragment key={studentId}>
                          <div className="student-row" key={studentId}>
                            {student.profilePicture && (
                              <Avatar className="student-img" src={student.profilePicture} alt={studentName} />
                            )}
                            {!student.profilePicture && <Avatar className="student-img" src="" alt={studentName} />}

                            <div className="student-name">{`${studentName} | ${student.sisId}`}</div>
                          </div>
                          <div className="failed-message">{getErrorMessage(error)}</div>
                        </React.Fragment>
                      );
                    })}
                    {Object.entries(binnedBulkOrderItems).map(([menuTypeName, totalsByCategory]) => {
                      return (
                        <React.Fragment key={menuTypeName}>
                          <h3 className="accordion-title">Bulk Orders</h3>
                          <Divider />
                          <div className="menu-type">{menuTypeName}</div>
                          {Object.entries(totalsByCategory).map(([category, categoryTotals]) => {
                            return (
                              <React.Fragment key={category}>
                                <div className="cart-sidebar__bulk-product-category">{category}</div>
                                {categoryTotals.map((categoryTotal) => {
                                  return (
                                    <div className="cart-sidebar__bulk-row" key={categoryTotal.productName}>
                                      <div className="cart-sidebar__product-name">{categoryTotal.productName}</div>
                                      <div
                                        className="sidebar__total"
                                        data-test-id={`${categoryTotal.productName}.bulkTotalLbl`}
                                      >
                                        {categoryTotal.total}
                                      </div>
                                    </div>
                                  );
                                })}
                              </React.Fragment>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
                <Divider />
              </React.Fragment>
            );
          })}
        </DrawerContent>
        <DrawerFooter className="footer" aria-label="Footer for the Entree Side Selection Form">
          <Button onClick={onClose} variant="contained" color="primary" data-test-id="returnToDashboardBtn">
            Return to Dashboard
          </Button>
        </DrawerFooter>
      </div>
    </Drawer>
  );
};

export default OrderConfirmationSidebar;
