import { Reducer } from 'react';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';

const initialState: Interfaces.AddStudentModalState = {
  open: false,
  error: '',
};

const AddStudentModalReducer: Reducer<Interfaces.AddStudentModalState, Interfaces.Action> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case Types.ADD_STUDENT_MODAL_SET_OPEN:
      return {
        ...state,
        open: payload,
      };

    case Types.ADD_STUDENT_MODAL_SET_ERROR:
      return {
        ...state,
        error: payload,
      };

    default:
      return state;
  }
};

export default AddStudentModalReducer;
