import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useApi } from 'Api/useApi';
import * as CommonSelectors from 'State/selectors';
import { PublishedMenu, SchoolMenu } from 'Interfaces/Interfaces';

const getStartOfMonthParam = (date: moment.Moment) => {
  return date?.startOf('month').toISOString().substring(0, 10);
};

const getEndOfMonthParam = (date: moment.Moment) => {
  return date?.endOf('month').add(-1, 'day').toISOString().substring(0, 10);
};

const getYearFromMoment = (date: moment.Moment) => {
  return date.add(1, 'year').endOf('month').toISOString().substring(0, 10);
};

export const usePublishedMenus = (districtId: string, startDate?: moment.Moment) => {
  const sites = useSelector(CommonSelectors.getSites);
  const api = useApi();

  const [loading, setLoading] = React.useState(false);
  const [schoolMenuData, setSchoolMenuData] = React.useState<SchoolMenu[]>([]);
  const [data, setData] = React.useState<PublishedMenu[]>([]);

  React.useEffect(() => {
    const getMenuTypeDataByMonth = async () => {
      if (!startDate) {
        return;
      }

      const dateFrom = getStartOfMonthParam(startDate);
      const dateTo = getEndOfMonthParam(startDate);

      setLoading(true);

      try {
        let next: string | undefined;
        const processedData: PublishedMenu[] = [];
        do {
          const response = (await api.get(`/districts/${districtId}/publishedMenus`, {
            params: { dateFrom, dateTo },
          })) as any;

          if (response.data?.items) {
            processedData.push(
              ...Object.values(response.data.items).map((publishedMenu: any) => {
                return {
                  siteId: publishedMenu.siteId,
                  name: publishedMenu.name,
                  menuTypeId: publishedMenu.menuTypeId,
                  publishedMonth: publishedMenu.publishedMonth,
                };
              }),
            );

            setData(processedData ?? []);
          }
        } while (next);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    const getMenuTypeData = async () => {
      setLoading(true);

      // get only data from current month + 1 year
      const dateFrom = getStartOfMonthParam(moment());
      const dateTo = getYearFromMoment(moment());

      let next: string | undefined;
      const processedData: PublishedMenu[] = [];
      try {
        do {
          const response = (await api.get(`/districts/${districtId}/publishedMenus`, {
            params: { dateFrom, dateTo, limit: 1000, next },
          })) as any;

          if (response.data?.items) {
            processedData.push(
              ...Object.values(response.data.items).map((publishedMenu: any) => {
                return {
                  siteId: publishedMenu.siteId,
                  name: publishedMenu.name,
                  menuTypeId: publishedMenu.menuTypeId,
                  publishedMonth: publishedMenu.publishedMonth,
                };
              }),
            );
          }
          next = response.data.next;
        } while (next);

        setData(processedData ?? []);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    if (startDate) {
      getMenuTypeDataByMonth();
    } else {
      getMenuTypeData();
    }
  }, [startDate, api, districtId]);

  React.useEffect(() => {
    const schoolMenus = Object.values(data).map((publishedMenu: any) => {
      const site = sites[publishedMenu.siteId];
      return {
        siteId: publishedMenu.siteId,
        siteName: site?.name ?? '',
        name: publishedMenu.name,
        menuTypeId: publishedMenu.menuTypeId,
        siteGroupTypeId: site?.siteGroupTypeId ?? '',
        publishedMonth: publishedMenu.publishedMonth,
      };
    });

    setSchoolMenuData(schoolMenus);
  }, [sites, data]);

  return { publisehdMenuData: schoolMenuData, loading };
};
