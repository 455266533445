import Button from 'Components/Button/Button';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import Modal from 'Components/Modal/Modal';
import React from 'react';
import './Dialogs.less';

interface NotificationDialogProps {
  title: string;
  message: string | JSX.Element;
  showDialog: boolean;
  onOkClick: (data: any) => void | Promise<void>;
  showCancel?: boolean;
  onCancelClick: () => void;
  okButtonText: string;
  cancelButtonText?: string;
  dialogType?: 'info' | 'delete';
  loading?: boolean;
  okAriaLabel?: string;
  cancelAriaLabel?: string;
}

const NotificationDialog = (props: NotificationDialogProps) => {
  const {
    title,
    message,
    showDialog,
    onOkClick,
    showCancel,
    onCancelClick,
    okButtonText,
    cancelButtonText,
    dialogType,
    loading,
    okAriaLabel = 'Confirm',
    cancelAriaLabel = 'Cancel',
  } = props;
  return (
    <Modal maxWidth="md" open={showDialog} className={'linq-dialog notification-dialog-container'}>
      <Modal.Title className="linq-dialog__header d-flex align-items-center" disableTypography={true}>
        <h3 className="linq-heading">{title}</h3>
        <CloseIconButton onClick={onCancelClick} />
      </Modal.Title>
      <Modal.Content className="linq-dialog__content_scroll">
        {/* message may be element. If so, do not nest within <p> tags */}
        {typeof message === 'string' ? <p>{message}</p> : <>{message}</>}
      </Modal.Content>
      <Modal.Actions className="linq-dialog__actions">
        {showCancel && (
          <Button
            variant="outlined"
            color="primary"
            id="cancelButton"
            onClick={onCancelClick}
            aria-label={cancelAriaLabel}
            data-test-id="notificationDialogCancelBtn"
          >
            <strong>{cancelButtonText}</strong>
          </Button>
        )}
        {!showCancel && <div />}
        <Button
          id="okButton"
          className={dialogType}
          onClick={onOkClick}
          disabled={loading}
          loading={loading}
          aria-label={okAriaLabel}
          variant="contained"
          color="primary"
          data-test-id="notificationDialogConfirmBtn"
        >
          {okButtonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export { NotificationDialog };
