import { useState } from 'react';
import { ActionCreator } from 'redux';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';
import moment from 'moment';
import { TokenClaims } from 'Auth/User';
import { useApi } from 'Api/useApi';

export const useDistrictActions = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);

  const [actions] = useState({
    importData: async (files: any) => {
      try {
        for (const file of files) {
          const data = {
            name: file.name,
          };
          const results = (await api.post(`/utility/import`, data)) as any;
          const uploadUrl = results?.data?.upload.url;
          await axios.put(uploadUrl, file);
        }
      } catch (ex) {
        console.error('ex: ', ex);
      }
    },
    updateReimbursableMealPrices: async (data: Interfaces.ReimbursableMealPrice[], params: URLSearchParams) => {
      try {
        const results = (await api.post(`/districts/${districtId}/reimbursableMealPrices?${params}`, data)) as any;
        dispatch(updateStorePrices(results?.data?.items));
      } catch (ex) {}
    },
    updateReimbursableMealPricesByGrade: async (
      data: Interfaces.ReimbursableMealPriceByGradeGroup[],
      params: URLSearchParams,
      reimbursableMealTypeId: string,
    ) => {
      try {
        const results = (await api.put(
          `/districts/${districtId}/reimbursableMealPricesByGrade?${params}`,
          data,
        )) as any;

        dispatch(updateStorePricesByGrade({ pricesByGrade: results?.data?.items, reimbursableMealTypeId }));
      } catch (ex) {}
    },
    updateReimbursableMealPricingMode: async (
      mealTypeId: string,
      reimbursableMealTypeId: string,
      pricingMode: Interfaces.PricingMode,
    ) => {
      try {
        const results = (await api.post(`/districts/${districtId}/reimbursableMealPricingMode`, {
          mealTypeId,
          pricingMode,
          districtId,
          reimbursableMealTypeId,
        })) as any;

        dispatch(updateStorePricingMode(results?.data));
      } catch (ex) {}
    },
    getLogs: async (districtId: string) => {
      const oneMonthAgo = moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD');
      const results = (await api.get(`/districts/${districtId}/logs?dateFrom=${oneMonthAgo}`)) as any;
      dispatch(setLogs(results?.data?.items));
    },
  });

  return actions;
};

export const updateStorePrices: ActionCreator<Interfaces.Action> = (payload: Interfaces.ReimbursableMealPrice) => ({
  type: Types.DISTRICT_UPDATE_REIMBURSABLE_MEAL_PRICES,
  payload,
});

export const updateStorePricesByGrade: ActionCreator<Interfaces.Action> = (payload: {
  pricesByGrade: Interfaces.ReimbursableMealPriceByGrade;
  reimbursableMealTypeId: string;
}) => ({
  type: Types.DISTRICT_UPDATE_REIMBURSABLE_MEAL_PRICES_BY_GRADE,
  payload,
});

export const setReimbursableMealPrices: ActionCreator<Interfaces.Action> = (
  payload: Interfaces.ReimbursableMealPrice,
) => ({
  type: Types.DISTRICT_SET_REIMBURSABLE_MEAL_PRICES,
  payload,
});

export const setReimbursableMealPricesByGrade: ActionCreator<Interfaces.Action> = (
  payload: Interfaces.ReimbursableMealPrice,
) => ({
  type: Types.DISTRICT_SET_REIMBURSABLE_MEAL_PRICES_BY_GRADE,
  payload,
});

export const setReimbursableMealPricingModes: ActionCreator<Interfaces.Action> = (
  payload: Interfaces.ReimbursableMealPrice,
) => ({
  type: Types.DISTRICT_SET_MENU_TYPE_PRICING_MODES,
  payload,
});

export const updateStorePricingMode: ActionCreator<Interfaces.Action> = (
  payload: Interfaces.ReimbursableMealPrice,
) => ({
  type: Types.DISTRICT_UPDATE_MENU_TYPE_PRICING_MODES,
  payload,
});

export const setLogs: ActionCreator<Interfaces.Action> = (payload: { [key: string]: Interfaces.Log }) => ({
  type: Types.SET_LOGS,
  payload,
});
