import { get, groupBy } from 'lodash';
import { createSelector } from 'reselect';
import * as Interfaces from 'Interfaces/Interfaces';
import { compareGrade } from 'Helpers/Helper';

const root = (state: Interfaces.AppState) => get(state, 'districtState');

export const getReimbursableMealPricingModes = (
  state: Interfaces.AppState,
): { [key: string]: Interfaces.ReimbursableMealPricingMode } => get(root(state), 'reimbursableMealPricingModes', {});

export const getReimbursableMealPrices = (
  state: Interfaces.AppState,
): { [key: string]: Interfaces.ReimbursableMealPrice } => get(root(state), 'reimbursableMealPrices', {});

export const getReimbursableMealPricesByGrade = (
  state: Interfaces.AppState,
): { [key: string]: Interfaces.ReimbursableMealPriceByGrade } => get(root(state), 'reimbursableMealPricesByGrade', {});

export const getLogs = (state: Interfaces.AppState): { [key: string]: Interfaces.Log } => get(root(state), 'logs', {});

export const getLogsSelector = createSelector([getLogs], (logs) => {
  const getLogsArray = Object.values(logs);
  return getLogsArray.sort((a: any, b: any) => +new Date(b.date) - +new Date(a.date));
});

export const getAllPricesSetForMealType = createSelector([getReimbursableMealPrices], (reimbursableMealPrices) => {
  let mealPricesSet = false;

  if (reimbursableMealPrices) {
    const siteGroupIds = Object.keys(Interfaces.SITE_GROUP_TYPE_IDS);

    mealPricesSet = siteGroupIds.every((id: string) => {
      const pricingGroupKey = `PAID.${id}`;
      const price = reimbursableMealPrices[pricingGroupKey]?.price || null;
      return price && price >= 0;
    });

    mealPricesSet = siteGroupIds.every((id: string) => {
      const pricingGroupKey = `REDUCED.${id}`;
      const price = reimbursableMealPrices[pricingGroupKey]?.price || null;
      return price && price >= 0;
    });
  }

  return mealPricesSet;
});

export const getReimbursableMealPricesFiltered = createSelector(
  [getReimbursableMealPrices, (_: any, props: any) => props],
  (reimbursableMealPrices, props) => {
    return Object.values(reimbursableMealPrices).find((p) => {
      return p.mealTypeId === props.mealTypeId;
    });
  },
);

export const getReimbursableMealPricesByGradeGroups = (reimbursableMealTypeId?: string) =>
  createSelector([getReimbursableMealPricesByGrade], (reimbursableMealPricesByGrade) => {
    const gradePricingGroups =
      Object.values(groupBy(Object.values(reimbursableMealPricesByGrade), 'groupId')).map((group) => {
        const gradePricingGroup: Interfaces.ReimbursableMealPriceByGradeGroup = {
          grades: group.map((priceByGrade) => priceByGrade.grade),
          reimbursableMealTypeId: group[0]?.reimbursableMealTypeId,
          price: group[0]?.price,
          mealTypeId: group[0]?.mealTypeId,
        };

        gradePricingGroup.grades?.sort(compareGrade);

        return gradePricingGroup;
      }) ?? [];

    gradePricingGroups.sort((gradePricingGroupA, gradePricingGroupB) => {
      return compareGrade(gradePricingGroupA?.grades?.[0] ?? '', gradePricingGroupB?.grades?.[0] ?? '');
    });

    if (reimbursableMealTypeId) {
      return gradePricingGroups.filter((group) => group.reimbursableMealTypeId === reimbursableMealTypeId);
    }

    return gradePricingGroups;
  });
