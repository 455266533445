import { createMuiTheme } from '@material-ui/core/styles';
import { blueberryMuffin } from '@linq/snackpaq';

const fontFamily = 'proxima-nova, sans-serif';

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: blueberryMuffin.color.primary[100],
      main: blueberryMuffin.color.primary[600],
      dark: blueberryMuffin.color.primary[700],
    },
    secondary: {
      light: blueberryMuffin.color.secondary[100],
      main: blueberryMuffin.color.secondary[500],
      dark: blueberryMuffin.color.secondary[700],
    },
  },
  typography: {
    h1: {
      fontFamily,
      fontWeight: 600,
      textTransform: 'none',
      fontSize: blueberryMuffin.font.size[17],
      lineHeight: 2.5,
      color: blueberryMuffin.color.primary[700],
    },
    h2: {
      fontFamily,
      fontWeight: 600,
      textTransform: 'none',
      fontSize: blueberryMuffin.font.size[15],
      lineHeight: 2.25,
      color: blueberryMuffin.color.primary[700],
    },
    h3: {
      fontFamily,
      fontWeight: 600,
      textTransform: 'none',
      fontSize: blueberryMuffin.font.size[13],
      lineHeight: 2,
      color: blueberryMuffin.color.primary[600],
    },
    h4: {
      fontFamily,
      fontWeight: 600,
      textTransform: 'none',
      fontSize: blueberryMuffin.font.size[9],
      lineHeight: 1.5,
      color: blueberryMuffin.color.primary[600],
    },
    h5: {
      fontFamily,
      fontWeight: 600,
      textTransform: 'none',
      fontSize: blueberryMuffin.font.size[9],
      lineHeight: 1.5,
      color: blueberryMuffin.color.secondary[500],
    },
    h6: {
      fontFamily,
      fontWeight: 600,
      textTransform: 'none',
      fontSize: blueberryMuffin.font.size[5],
      lineHeight: 1,
      color: blueberryMuffin.color.secondary[500],
    },
  },
});
