import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { List, ListItem, Divider } from '@material-ui/core';
import { Place } from '@material-ui/icons';
import { useAuth0 } from '@auth0/auth0-react';
import PickupLocationsModal from './PickupLocationsModal';
import { useCommonActions } from 'State/actions';
import * as CommonSelectors from 'State/selectors';
import * as LoaderActions from 'Components/Loaders/state/actions';
import * as LoaderSelectors from 'Components/Loaders/state/selectors';
import { PickupLocation } from 'Interfaces/Interfaces';
import { Role, isAuthorized, TokenClaims } from 'Auth/User';
import { GROUP_LABELS } from 'Constants/Constants';
import { SiteScope } from 'Constants/Enums';
import { ComponentLoader } from 'Components/Loaders/Loaders';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import { sortAndGroupPickupLocations } from 'Helpers/group';
import { ClassroomToggle } from './ClassroomToggle';
import Button from 'Components/Button/Button';
import { CalloutCard } from 'Components/CalloutCard/CalloutCard';
import Card from 'Components/Card/Card';
import { PickupLocationDelete } from './PickupLocationDelete';

import './PickupLocations.less';

export const isLocationVisible = ({
  isDirector,
  isManager,
  pickupLocation,
  userSiteId,
}: {
  isDirector: boolean;
  isManager: boolean;
  pickupLocation: PickupLocation;
  userSiteId: string;
}) => {
  const displayGlobal = (isDirector || isManager) && pickupLocation.scope === SiteScope.GLOBAL;
  const displayManager =
    (isManager && pickupLocation.default && pickupLocation.siteId === userSiteId) ||
    (pickupLocation.scope === SiteScope.SITE && pickupLocation.siteId === userSiteId);
  return displayGlobal || displayManager;
};

const PickupLocations = () => {
  const dispatch = useDispatch();
  const pageLoading = useSelector(LoaderSelectors.getComponentLoaderActive);
  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const userSiteId = TokenClaims.getSiteId(user);
  const districtId = TokenClaims.getDistrictId(user);
  const pickupLocations = useSelector(CommonSelectors.getPickupLocations);
  const sites = useSelector(CommonSelectors.getSites);
  const site = sites[userSiteId];
  const [pickupLocationId, setPickupLocationId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const { updateSite, getPickupLocations, getSites } = useCommonActions();

  const isDirector = useMemo(() => isAuthorized(userRole, [Role.DIRECTOR]), [userRole]);
  const isManager = useMemo(() => isAuthorized(userRole, [Role.MANAGER]), [userRole]);

  const classroomPickupDisabled = useMemo(() => site?.disableDefaultPickupLocations, [site]);

  const pickupLocationsByScope = useMemo(() => {
    // Filter out the values which can't be shown to the current user
    const visibleLocations = Object.values(pickupLocations).filter((pickupLocation: PickupLocation) =>
      isLocationVisible({
        isDirector,
        isManager,
        pickupLocation,
        userSiteId,
      }),
    );
    // Then group the values based on a default flag or scope property.
    const sortedAndGrouped = sortAndGroupPickupLocations(visibleLocations);

    // Set UI order
    return {
      [SiteScope.SITE]: sortedAndGrouped[SiteScope.SITE],
      [SiteScope.GLOBAL]: sortedAndGrouped[SiteScope.GLOBAL],
      [`DEFAULT`]: sortedAndGrouped[`DEFAULT`],
    };
  }, [isDirector, isManager, pickupLocations, userSiteId]);

  useEffect(() => {
    batch(async () => {
      dispatch(LoaderActions.setComponentLoaderActive(true));
      await getPickupLocations(districtId);
      await getSites(districtId);
      dispatch(LoaderActions.setComponentLoaderActive(false));
    });
  }, [dispatch, districtId, getPickupLocations, getSites]);

  const toggleClassroomPickup = useCallback(async () => {
    await updateSite({ disableDefaultPickupLocations: !classroomPickupDisabled }, sites);
  }, [classroomPickupDisabled, updateSite, sites]);

  return (
    <div className="pickup-locations-container">
      <SubHeader
        title="Pickup Location Configuration"
        rightComponent={
          <Button
            color="primary"
            className="add-pickup-location sub-header__action"
            aria-label="Add new pickup location"
            onClick={() => {
              setPickupLocationId('');
              setModalOpen(true);
            }}
            data-test-id="addPickupLocationBtn"
          >
            + Add Pickup Location
          </Button>
        }
      />
      <div className="page-content-wrapper">
        <ComponentLoader />
        {!pageLoading && (
          <div className="oo-row">
            <Card title={'Pickup Locations'} description={'Please choose your pickup locations.'}>
              <div className="pickup-locations-card">
                <div className="pickup-locations">
                  {Object.entries(pickupLocationsByScope).map(([labelName, pls]: [string, PickupLocation[]]) => {
                    if (!shouldShowGroup(pls, isManager)) {
                      return null;
                    }

                    return (
                      <React.Fragment key={labelName}>
                        <div>
                          <h4 className="group-label group-label-custom">{GROUP_LABELS[labelName]}</h4>
                          {shouldShowClassroomToggle(labelName, isManager) && (
                            <ClassroomToggle
                              toggleDisabled={toggleClassroomPickup}
                              isDisabled={!!classroomPickupDisabled}
                            />
                          )}
                          <List className="linq-list">
                            {pls?.map((pickupLocation: PickupLocation, index) => {
                              if (classroomPickupDisabled && pickupLocation.default) return null;
                              return (
                                <React.Fragment>
                                  <ListItem
                                    key={`${pickupLocation}${index}`}
                                    className={`pickup-location ${pickupLocation.scope?.toLowerCase()} ${
                                      pickupLocation.default ? 'default' : ''
                                    }`}
                                  >
                                    <span>{pickupLocation.name}</span>
                                    {((pickupLocation.scope === SiteScope.GLOBAL && isDirector) ||
                                      (pickupLocation.scope !== SiteScope.GLOBAL &&
                                        !pickupLocation.default &&
                                        isManager)) && (
                                      <div className="pickup-locations__icons">
                                        <IconButton
                                          aria-label={`Edit ${pickupLocation.name}`}
                                          onClick={() => {
                                            setPickupLocationId(pickupLocation.id);
                                            setModalOpen(true);
                                          }}
                                          data-test-id={`${pickupLocation.name}.editBtn`}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                        {pickupLocation.id && !pickupLocation.default && (
                                          <PickupLocationDelete
                                            id={pickupLocation.id}
                                            name={pickupLocation.name}
                                            siteId={pickupLocation.siteId}
                                          />
                                        )}
                                      </div>
                                    )}
                                  </ListItem>
                                  {index !== pls.length - 1 && <Divider />}
                                </React.Fragment>
                              );
                            })}
                          </List>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </Card>
            <div>
              <CalloutCard title="About Pickup Locations" icon={<Place />}>
                <p>
                  Parents/students will be asked to choose a pickup location for the meals they order. The pickup
                  locations added to this list will populate the dropdown that parents/students use to select a pickup
                  location. Global pickup locations will be configured by Nutrition Directors and will be available to
                  every student in the district. Custom pickup location will be configured by Nutrition Managers and
                  will only be available to students at their school site. Default pickup locations are homerooms and
                  can be disabled at the site level by Nutrition Managers.
                </p>
              </CalloutCard>
            </div>
          </div>
        )}
      </div>
      <PickupLocationsModal open={modalOpen} setModalOpen={setModalOpen} id={pickupLocationId} />
    </div>
  );
};

export default PickupLocations;

const shouldShowGroup = (pls: PickupLocation[], isManager: boolean) => {
  return pls?.length > 0 || isManager;
};

const shouldShowClassroomToggle = (labelName: string, isManager: boolean) => {
  return isManager && labelName === 'DEFAULT';
};
