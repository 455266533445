import { PickupLocationTableRow } from 'Interfaces/Interfaces';
import { Row } from 'react-table';
import { RowType } from './PickupLocationReportTable';

const areBothStudentRows = (rowA: Row<PickupLocationTableRow>, rowB: Row<PickupLocationTableRow>) => {
  const {
    original: { type: rowAType },
  } = rowA;
  const {
    original: { type: rowBType },
  } = rowB;

  return rowAType === RowType.Student && rowBType === RowType.Student;
};

const areBothBulkOrders = (rowA: Row<PickupLocationTableRow>, rowB: Row<PickupLocationTableRow>) => {
  const {
    original: { type: rowAType },
  } = rowA;
  const {
    original: { type: rowBType },
  } = rowB;

  return rowAType === RowType.BulkOrder && rowBType === RowType.BulkOrder;
};

const compareStudentToBulkRow = (
  rowA: Row<PickupLocationTableRow>,
  rowB: Row<PickupLocationTableRow>,
  desc: boolean,
) => {
  const {
    original: { type: rowAType },
  } = rowA;
  const {
    original: { type: rowBType },
  } = rowB;

  if (rowAType === RowType.Student && rowBType === RowType.BulkOrder) {
    return desc ? 1 : -1;
  }
  return desc ? -1 : 1;
};

const compareBulkOrderByName = (
  rowA: Row<PickupLocationTableRow>,
  rowB: Row<PickupLocationTableRow>,
  desc: boolean,
) => {
  const {
    original: { name: nameA },
  } = rowA;
  const {
    original: { name: nameB },
  } = rowB;

  if (!nameA && !nameB) {
    return 0;
  }
  if (nameA && nameB) {
    const upperNameA = nameA.toUpperCase();
    const upperNameB = nameB.toUpperCase();
    const compareResult = upperNameA.localeCompare(upperNameB);

    return desc ? compareResult * -1 : compareResult;
  }
  return 0;
};

export const sortStudentColumn = (
  rowA: Row<PickupLocationTableRow>,
  rowB: Row<PickupLocationTableRow>,
  columnId: string,
  desc: boolean,
) => {
  if (areBothBulkOrders(rowA, rowB)) {
    return compareBulkOrderByName(rowA, rowB, desc);
  }

  if (!areBothStudentRows(rowA, rowB)) {
    return compareStudentToBulkRow(rowA, rowB, desc);
  }

  const lastNameA = rowA.original.lastName.toUpperCase();
  const lastNameB = rowB.original.lastName.toUpperCase();
  const firstNameA = rowA.original.firstName.toUpperCase();
  const firstNameB = rowB.original.firstName.toUpperCase();
  const lastNameCompareResult = lastNameA.localeCompare(lastNameB);

  if (lastNameCompareResult !== 0) {
    return lastNameCompareResult;
  }
  const firstNameCompareResult = firstNameA.localeCompare(firstNameB);
  return firstNameCompareResult;
};

const compareOrderedBy = (rowA: Row<PickupLocationTableRow>, rowB: Row<PickupLocationTableRow>, desc: boolean) => {
  const orderedByNameA = rowA.original.orderedByName.toUpperCase();
  const orderedByNameB = rowB.original.orderedByName.toUpperCase();

  return orderedByNameA.localeCompare(orderedByNameB);
};

export const sortOrderedByColumn = (
  rowA: Row<PickupLocationTableRow>,
  rowB: Row<PickupLocationTableRow>,
  columnId: string,
  desc: boolean,
) => {
  if (areBothBulkOrders(rowA, rowB)) {
    return compareBulkOrderByName(rowA, rowB, desc);
  }

  if (!areBothStudentRows(rowA, rowB)) {
    return compareStudentToBulkRow(rowA, rowB, desc);
  }

  return compareOrderedBy(rowA, rowB, desc);
};

const compareSubmittedOn = (rowA: Row<PickupLocationTableRow>, rowB: Row<PickupLocationTableRow>, desc: boolean) => {
  const orderedAtA = rowA.original.orderedAtTimestamp;
  const orderedAtB = rowB.original.orderedAtTimestamp;

  if (orderedAtA < orderedAtB) {
    return -1;
  }
  if (orderedAtA > orderedAtB) {
    return 1;
  }
  return 0;
};

export const sortSubmittedoOnColumn = (
  rowA: Row<PickupLocationTableRow>,
  rowB: Row<PickupLocationTableRow>,
  columnId: string,
  desc: boolean,
) => {
  if (areBothBulkOrders(rowA, rowB)) {
    return compareBulkOrderByName(rowA, rowB, desc);
  }

  if (!areBothStudentRows(rowA, rowB)) {
    return compareStudentToBulkRow(rowA, rowB, desc);
  }

  return compareSubmittedOn(rowA, rowB, desc);
};

const compareReceived = (rowA: Row<PickupLocationTableRow>, rowB: Row<PickupLocationTableRow>, desc: boolean) => {
  const receivedSortA = rowA.original.receivedSort;
  const receivedSortB = rowB.original.receivedSort;

  return receivedSortA.localeCompare(receivedSortB);
};

export const sortReceivedColumn = (
  rowA: Row<PickupLocationTableRow>,
  rowB: Row<PickupLocationTableRow>,
  columnId: string,
  desc: boolean,
) => {
  if (areBothBulkOrders(rowA, rowB)) {
    return compareBulkOrderByName(rowA, rowB, desc);
  }

  if (!areBothStudentRows(rowA, rowB)) {
    return compareStudentToBulkRow(rowA, rowB, desc);
  }

  return compareReceived(rowA, rowB, desc);
};
