import { groupBy, sortBy } from 'lodash';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Cell } from 'react-table';
import * as CommonStateSelectors from 'State/selectors';
import * as CommonStateActions from 'State/actions';
import { ScrollingReactTable } from 'Components/ReactTable';
import { PRODUCT_CATEGORIES } from 'Constants/Constants';
import * as Interfaces from 'Interfaces/Interfaces';
import { BulkOrderCard, EntreeSidebar } from '..';
import { SidebarLocations } from 'Constants/Enums';

interface BulkOrderTableProps {
  footer?: React.ReactNode;
  menuType: Interfaces.MenuType;
  menuItems: Interfaces.MenuItem[];
  bulkOrderItems: Interfaces.BulkOrderItems;
  addOrRemoveBulkOrderItem: (menuItem: Interfaces.MenuItem, quantity: number) => void;
  isOrderingDisabled?: boolean;
}

const COLUMN_WIDTH = 416;

interface RowData {
  entree?: Interfaces.MenuItem;
  vegetable?: Interfaces.MenuItem;
  fruit?: Interfaces.MenuItem;
  grain?: Interfaces.MenuItem;
  milk?: Interfaces.MenuItem;
  condiment?: Interfaces.MenuItem;
}

const createTableData = (menuItems: Interfaces.MenuItem[]) => {
  const groupedProducts = groupBy(menuItems, 'product.productCategoryId');

  Object.keys(groupedProducts).forEach((productCategory) => {
    groupedProducts[productCategory] = sortBy(groupedProducts[productCategory], (mi) => {
      return mi.sortPriority;
    });
  });

  const data: RowData[] = [];

  Object.keys(groupedProducts).forEach((productCategoryId) => {
    let i = 0;
    const items = groupedProducts[productCategoryId];
    items.forEach((mi) => {
      let rowItem: RowData;

      if (!data[i]) {
        rowItem = {} as RowData;
        data.push(rowItem);
      } else {
        rowItem = data[i];
      }

      switch (productCategoryId) {
        case PRODUCT_CATEGORIES.ENTREE.id:
          rowItem.entree = mi;
          i++;
          break;
        case PRODUCT_CATEGORIES.VEGETABLE.id:
          rowItem.vegetable = mi;
          i++;
          break;
        case PRODUCT_CATEGORIES.FRUIT.id:
          rowItem.fruit = mi;
          i++;
          break;
        case PRODUCT_CATEGORIES.GRAIN.id:
          rowItem.grain = mi;
          i++;
          break;
        case PRODUCT_CATEGORIES.MILK.id:
          rowItem.milk = mi;
          i++;
          break;
        case PRODUCT_CATEGORIES.CONDIMENT.id:
          rowItem.condiment = mi;
          i++;
          break;
        default:
          break;
      }
    });
  });

  return data;
};

const BulkOrderTable = ({
  footer,
  menuType,
  menuItems,
  addOrRemoveBulkOrderItem,
  bulkOrderItems,
  isOrderingDisabled,
}: BulkOrderTableProps) => {
  const tableData = React.useMemo(() => createTableData(menuItems), [menuItems]);
  const [sidebarProduct, setSidebarProduct] = React.useState<Interfaces.Product | null>(null);

  const sidebarOpen = useSelector((state) =>
    CommonStateSelectors.getSidebarOpen(state as Interfaces.AppState, SidebarLocations.ENTREE_SIDE_BAR),
  );
  const dispatch = useDispatch();

  const setSidebarOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(CommonStateActions.setSidebarOpen(isOpen, SidebarLocations.ENTREE_SIDE_BAR));
    },
    [dispatch],
  );

  const handleOpenClick = useCallback(
    (menuItem: Interfaces.MenuItem | undefined) => {
      setSidebarProduct(menuItem?.product || null);
      setSidebarOpen(true);
    },
    [setSidebarOpen],
  );

  const columns = React.useMemo(
    () => [
      {
        accessor: 'entree',
        Header: 'Entree',
        disableSortBy: true,
        Cell: (props: Cell<RowData>) => {
          const menuItem = props.row.original.entree;
          const productId = menuItem?.productId || '';
          return (
            <BulkOrderCard
              menuItem={menuItem}
              onOpenClick={() => {
                handleOpenClick(menuItem);
              }}
              onQuantityChange={addOrRemoveBulkOrderItem}
              quantity={bulkOrderItems[productId]?.quantity || 0}
            />
          );
        },
        width: COLUMN_WIDTH,
      },
      {
        accessor: 'fruit',
        Header: 'Fruit',
        disableSortBy: true,
        Cell: (props: Cell<RowData>) => {
          const menuItem = props.row.original.fruit;
          const productId = menuItem?.productId || '';
          return (
            <BulkOrderCard
              menuItem={menuItem}
              onOpenClick={() => {
                handleOpenClick(menuItem);
              }}
              onQuantityChange={addOrRemoveBulkOrderItem}
              quantity={bulkOrderItems[productId]?.quantity || 0}
            />
          );
        },
        width: COLUMN_WIDTH,
      },
      {
        accessor: 'vegetable',
        Header: 'Vegetable',
        disableSortBy: true,
        Cell: (props: Cell<RowData>) => {
          const menuItem = props.row.original.vegetable;
          const productId = menuItem?.productId || '';
          return (
            <BulkOrderCard
              menuItem={menuItem}
              onOpenClick={() => {
                handleOpenClick(menuItem);
              }}
              onQuantityChange={addOrRemoveBulkOrderItem}
              quantity={bulkOrderItems[productId]?.quantity || 0}
            />
          );
        },
        width: COLUMN_WIDTH,
      },
      {
        accessor: 'grain',
        Header: 'Grain',
        disableSortBy: true,
        Cell: (props: Cell<RowData>) => {
          const menuItem = props.row.original.grain;
          const productId = menuItem?.productId || '';
          return (
            <BulkOrderCard
              menuItem={menuItem}
              onOpenClick={() => {
                handleOpenClick(menuItem);
              }}
              onQuantityChange={addOrRemoveBulkOrderItem}
              quantity={bulkOrderItems[productId]?.quantity || 0}
            />
          );
        },
        width: COLUMN_WIDTH,
      },
      {
        accessor: 'milk',
        Header: 'Milk',
        disableSortBy: true,
        Cell: (props: Cell<RowData>) => {
          const menuItem = props.row.original.milk;
          const productId = menuItem?.productId || '';
          return (
            <BulkOrderCard
              menuItem={menuItem}
              onOpenClick={() => {
                handleOpenClick(menuItem);
              }}
              onQuantityChange={addOrRemoveBulkOrderItem}
              quantity={bulkOrderItems[productId]?.quantity || 0}
            />
          );
        },
        width: COLUMN_WIDTH,
      },
      {
        accessor: 'condiment',
        Header: 'Condiment',
        disableSortBy: true,
        Cell: (props: Cell<RowData>) => {
          const menuItem = props.row.original.condiment;
          const productId = menuItem?.productId || '';
          return (
            <BulkOrderCard
              menuItem={menuItem}
              onOpenClick={() => {
                handleOpenClick(menuItem);
              }}
              onQuantityChange={addOrRemoveBulkOrderItem}
              quantity={bulkOrderItems[productId]?.quantity || 0}
            />
          );
        },
        width: COLUMN_WIDTH,
      },
    ],
    [addOrRemoveBulkOrderItem, bulkOrderItems, handleOpenClick],
  );

  return (
    <>
      <ScrollingReactTable
        className="bulk-order-table"
        columns={columns}
        data={isOrderingDisabled ? [] : tableData}
        footer={footer}
        noDataText={isOrderingDisabled ? 'Ordering not available' : undefined}
      />
      <EntreeSidebar
        entree={null}
        onClose={() => setSidebarProduct(null)}
        open={sidebarOpen}
        foodGroupOptions={{}}
        menuItemsByProductCategory={{}}
        selectedSides={{}}
        products={{}}
        menuType={menuType}
        readOnly={true}
        product={sidebarProduct || undefined}
        drawerTitle="Bulk Item Information"
      />
    </>
  );
};

export default BulkOrderTable;
