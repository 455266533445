export const SET_PICKUP_LOCATIONS = 'SET_PICKUP_LOCATIONS';
export const UPDATE_PICKUP_LOCATIONS = 'UPDATE_PICKUP_LOCATIONS';
export const PICKUP_LOCATION_DELETED = 'PICKUP_LOCATION_DELETED';
export const SET_HOME_ROOMS = 'SET_HOME_ROOMS';
export const SET_MENU_TYPES = 'SET_MENU_TYPES';
export const SET_MENU_ITEMS = 'SET_MENU_ITEMS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRODUCT_CATEGORIES = 'SET_PRODUCT_CATEGORIES';
export const SET_REIMBURSABLE_MEAL_TYPES = 'SET_REIMBURSABLE_MEAL_TYPES';
export const SET_SITE_GROUP_TYPES = 'SET_SITE_GROUP_TYPES';
export const SET_SPECIAL_MEAL_ACCOMMODATIONS = 'SET_SPECIAL_MEAL_ACCOMMODATIONS';
export const SET_STUDENTS = 'SET_STUDENTS';
export const REMOVE_STUDENT = 'REMOVE_STUDENT';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_DISTRICTS = 'SET_DISTRICTS';
export const SET_DISTRICT = 'SET_DISTRICT';
export const SET_SITES = 'SET_SITES';
export const ADD_STUDENT = 'ADD_STUDENT';
export const ORDER_ITEM_DELETED = 'ORDER_ITEM_DELETED';
export const ORDER_ITEM_PATCHED = 'ORDER_ITEM_PATCHED';
export const REPORT_ORDER_ITEM_PATCHED = 'REPORT_ORDER_ITEM_PATCHED';
export const CLOSE_ALL_SLIDEOUTS_AND_MODALS = 'CLOSE_ALL_SLIDEOUTS_AND_MODALS';
export const APPEND_MENU_ITEMS = 'APPEND_MENU_ITEMS';
export const APPEND_ORDER_ITEMS = 'APPEND_ORDER_ITEMS';
export const APPEND_PRODUCTS = 'APPEND_PRODUCTS';
export const UPDATE_STUDENT = 'UPDATE_STUDENT';

//Set Sidebar Types
export const SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN';
export const TOGGLE_SIDEBAR_OPEN = 'TOGGLE_SIDEBAR_OPEN';
