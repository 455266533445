import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import { TokenClaims } from 'Auth/User';
import classNames from 'classnames';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import * as CommonSelectors from 'State/selectors';
import Card from 'Components/Card/Card';
import DateRangeNavigator from 'Components/DateRangeNavigator/DateRangeNavigator';
import * as DateRangeNavigatorSelectors from 'Components/DateRangeNavigator/state/selectors';
import * as DateRangeNavigatorActions from 'Components/DateRangeNavigator/state/actions';
import { DateRangeMode, MOBILE_MEDIA_QUERY } from 'Constants/Constants';
import { useCommonActions } from 'State/actions';

import { SchoolMenuList } from './SchoolMenuList';
import { SchoolMenuTable } from './SchoolMenuTable';
import { usePublishedMenus } from './Hooks';

import './PreviewMenus.less';

const PreviewMenus = () => {
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);

  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY });
  const dispatch = useDispatch();
  const { getSites, getDistricts } = useCommonActions();
  const districts = useSelector(CommonSelectors.getDistricts);
  const startDate = useSelector(DateRangeNavigatorSelectors.getStartDate);

  const [showTable, setShowTable] = React.useState(false);
  const { publisehdMenuData, loading } = usePublishedMenus(districtId, !showTable ? startDate : undefined);

  React.useEffect(() => {
    // reset date range navigator date to current month on page load
    dispatch(DateRangeNavigatorActions.setStartDate(moment().startOf('month')));
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getSites(districtId);
    getDistricts();
  }, [districtId, getSites, getDistricts]);

  return (
    <>
      <SubHeader title={`Preview Menus - ${districts[districtId]?.name ?? ''}`} />
      <div className={classNames('preview-menus-container', isMobile && 'mobile')}>
        <div className="view-picker-container">
          <button
            onClick={() => {
              setShowTable(false);
            }}
            className={classNames('btn-reset', { selected: !showTable })}
            id="close-school-year-tab-selector"
            data-test-id="listTabBtn"
          >
            List View
          </button>
          <button
            onClick={() => {
              setShowTable(true);
            }}
            className={classNames('btn-reset', { selected: showTable })}
            id="history-tab-selector"
            data-test-id="tableTabBtn"
          >
            Table View
          </button>
        </div>
        {!showTable && (
          <Card title="School Menus">
            <DateRangeNavigator mode={DateRangeMode.MONTH} monthOnly={true} disablePast={true} />
            <SchoolMenuList publisehdMenuData={publisehdMenuData} loading={loading} />
          </Card>
        )}
        {showTable && <SchoolMenuTable schoolMenuData={publisehdMenuData} loading={loading} />}
      </div>
    </>
  );
};

export default PreviewMenus;
