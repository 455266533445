import React from 'react';
import { CalloutCard } from 'Components/CalloutCard/CalloutCard';
import { SubHeader } from 'Components/SubHeader/SubHeader';

import './LandingPage.less';

const LandingPage = () => {
  return (
    <React.Fragment>
      <div className="page-layout">
        <SubHeader title="Welcome to LINQ Online Ordering!" className="landing-sub-header" />
        <div className="welcome-card-container">
          <CalloutCard
            title={'Sign Up For LINQ Online Ordering'}
            details={
              'Parents, students and adults are able to conveniently view menus, order meals and add money to a student account.'
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default LandingPage;
