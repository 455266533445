import { get, sortBy } from 'lodash';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import { groupBy, uniqBy } from 'lodash';

import * as CommonSelectors from 'State/selectors';
import * as MenuTypePickerSelectors from 'Components/MenuTypePicker/state/selectors';
import * as DistrictSelectors from 'Components/District/state/selectors';
import * as DateRangeNavigatorSelectors from 'Components/DateRangeNavigator/state/selectors';
import { isAuthorized, Role } from 'Auth/User';
import * as Constants from 'Constants/Constants';
import * as Interfaces from 'Interfaces/Interfaces';

const root = (state: Interfaces.AppState) => get(state, 'shopState');

export const getErrors = (state: Interfaces.AppState): Interfaces.ShopError[] | null => get(root(state), 'errors');
export const getStudentId = (state: Interfaces.AppState): string | null => get(root(state), 'studentId');
export const getDistrictId = (state: Interfaces.AppState): string | null => get(root(state), 'districtId', '');
export const getSiteId = (state: Interfaces.AppState): string | null => get(root(state), 'siteId', '');

export const getStudent = createSelector([CommonSelectors.getStudents, getStudentId], (students, studentId) => {
  const student = students[studentId as string] as Interfaces.Student;
  if (student && !student.acceptedTerms) {
    student.acceptedTerms = [];
  }
  return student;
});

export const getStudentDistrict = createSelector([CommonSelectors.getDistricts, getStudent], (districts, student) => {
  const district = districts[student?.districtId];
  return district;
});

export const getDistrictTimezone = createSelector(getStudentDistrict, (district) => district?.timezone);

export const getMenuItemsFiltered = createSelector(
  [
    CommonSelectors.getMenuItems,
    MenuTypePickerSelectors.getMenuTypeId,
    (_: Interfaces.AppState, props: Interfaces.CalendarDayProps) => props,
  ],
  (menuItems, menuTypeId, props) => {
    const date = props.date.format(Constants.DATE_FORMAT_YMD);
    const newMenuItems = sortBy(
      Object.values({ ...menuItems }).filter((menuItem) => {
        return menuTypeId ? menuItem.date === date && menuItem.mealTypeId === menuTypeId : menuItem.date === date;
      }),
      'sortPriority',
    );
    return newMenuItems;
  },
);

export const getMenuItemsGrouped = createSelector(
  [getMenuItemsFiltered, CommonSelectors.getProducts, (_, props) => props],
  (menuItems, products, props) => {
    const { selector } = props;
    const newMenuItems = [...menuItems].map((menuItem) => {
      const newMenuItem = { ...menuItem };
      newMenuItem.product = products[menuItem.productId];
      return newMenuItem;
    });
    return groupBy(uniqBy(newMenuItems, 'productId'), selector);
  },
);

export const getProductPrice = (userRole: Role) =>
  createSelector(
    [
      CommonSelectors.getStudents,
      getStudentId,
      DistrictSelectors.getReimbursableMealPricingModes,
      DistrictSelectors.getReimbursableMealPrices,
      DistrictSelectors.getReimbursableMealPricesByGrade,
      CommonSelectors.getSites,
      DistrictSelectors.getAllPricesSetForMealType,
      getSiteId,
    ],
    (
      students,
      studentId,
      reimbursableMealPricingModes,
      reimbursableMealPrices,
      reimbursableMealPricesByGrade,
      sites,
      allPricesSet,
      selectedSite,
    ): number | undefined => {
      const student = students[studentId as string] as Interfaces.Student;
      if (!student) {
        return;
      }

      const isAdult = student.reimbursableMealTypeId === Constants.REIMBURSABLE_MEAL_TYPES.ADULT.id;
      if (student) {
        const studentSite = sites[student.siteId];
        if (studentSite?.cep && !isAdult) {
          return 0;
        }

        if (isAuthorized(userRole, [Role.PARENT])) {
          if (student.reimbursableMealTypeId === Constants.REIMBURSABLE_MEAL_TYPES.FREE.id) {
            return 0;
          }

          if (isAdult && selectedSite) {
            const selectedSiteGroupTypeId = sites[selectedSite]?.siteGroupTypeId;
            return get(reimbursableMealPrices, [
              `${student.reimbursableMealTypeId}.${selectedSiteGroupTypeId}`,
              'price',
            ]);
          }

          const foundPricingMode = Object.values(reimbursableMealPricingModes).find(
            (reimbMealPricingMode) => reimbMealPricingMode.reimbursableMealTypeId === student.reimbursableMealTypeId,
          );

          const pricingMode = foundPricingMode ? foundPricingMode.pricingMode : Interfaces.PricingMode.PRICING_BY_SITE;

          if (pricingMode === Interfaces.PricingMode.PRICING_BY_GRADE) {
            return get(reimbursableMealPricesByGrade, [`${student.reimbursableMealTypeId}.${student.grade}`, 'price']);
          }

          return get(reimbursableMealPrices, [`${student.reimbursableMealTypeId}.${student.siteGroupTypeId}`, 'price']);
        } else if (isAuthorized(userRole, [Role.TEACHER])) {
          if (allPricesSet) {
            return 0;
          }
        }
      }
      return 0;
    },
  );

export const hasMenuItems = createSelector(
  [CommonSelectors.getMenuItems, DateRangeNavigatorSelectors.getDayRange],
  (menuItems, dayRange) => {
    return Object.values(menuItems).some((menuItem) => {
      return (
        moment(menuItem.date).isSameOrAfter(dayRange.startDate) &&
        moment(menuItem.date).isSameOrBefore(dayRange.endDate)
      );
    });
  },
);

export const hasALaCarteMenuItems = createSelector(
  [CommonSelectors.getMenuItems, CommonSelectors.getProducts, DateRangeNavigatorSelectors.getDayRange],
  (menuItems, products, dayRange) => {
    return Object.values(menuItems)
      .filter((menuItem) => {
        const product = products[menuItem.productId];
        return product?.productCategoryId === Constants.PRODUCT_CATEGORIES.ALACARTE.id;
      })
      .some((menuItem) => {
        return (
          moment(menuItem.date).isSameOrAfter(dayRange.startDate) &&
          moment(menuItem.date).isSameOrBefore(dayRange.endDate)
        );
      });
  },
);

export const hasParentAcceptedTerms = (principalId: string | undefined) =>
  createSelector([getStudent], (student) => {
    return student?.acceptedTerms?.some((acceptedTerms) => acceptedTerms.parentId?.split('|').pop() === principalId);
  });

export const getOrderingRules = createSelector([getStudentDistrict], (district) => district?.orderingRules);

export const getOfferOnlyALaCarte = createSelector(getOrderingRules, (orderingRules) => {
  return orderingRules?.offerOnlyAlaCarte || false;
});

export const getAlaCarteStartDate = createSelector(
  getOrderingRules,
  (orderingRules) => orderingRules?.aLaCarteStartDate || '',
);

export const getAlaCarteEndDate = createSelector(
  getOrderingRules,
  (orderingRules) => orderingRules?.aLaCarteEndDate || '',
);

export const cutoffWarningVisible = (state: Interfaces.AppState): boolean => get(root(state), 'cutoffWarningVisible');
export const isCutoffWarningVisible = (userRole: Role) =>
  createSelector([cutoffWarningVisible, getOrderingRules], (cutoffWarningVisible, orderingRules) => {
    return (
      userRole === Role.PARENT &&
      cutoffWarningVisible &&
      ((orderingRules?.orderRange && (orderingRules?.cutoffDay || orderingRules?.orderDueBy)) ||
        (orderingRules?.maxOrderRange &&
          (orderingRules?.maxCutoffDays || orderingRules?.maxCutoffWeeks || orderingRules?.maxCutoffMonths)))
    );
  });

const computeMenuItemsTotalFactory = (productCategoryId?: string) =>
  createSelector(
    [
      (state: Interfaces.AppState): Interfaces.CartRootState => get(state, 'cartState'),
      CommonSelectors.getMenuItems,
      CommonSelectors.getProducts,
      getStudent,
    ],
    (cartState: Interfaces.CartRootState, menuItems, products) => {
      if (cartState.accountId) {
        const cartMenuItems = cartState.accounts[cartState.accountId]?.menuItems;
        return !cartMenuItems
          ? 0
          : Object.keys(cartMenuItems).reduce((acc, key) => {
              const menuItem = menuItems[key];
              const product = products[menuItem?.productId];
              const total = cartMenuItems[key].count;

              if (
                !product ||
                !cartMenuItems[key] ||
                !menuItem ||
                (productCategoryId && product.productCategoryId !== productCategoryId)
              ) {
                return acc;
              }

              return acc + cartMenuItems[key].price * total;
            }, 0);
      }

      return 0;
    },
  );

export const getALaCarteTotal = computeMenuItemsTotalFactory(Constants.PRODUCT_CATEGORIES.ALACARTE.id);

export const getCartTotal = computeMenuItemsTotalFactory();

export const aLaCarteWarningVisible = (state: Interfaces.AppState): boolean =>
  get(root(state), 'aLaCarteWarningVisible');

export const hasALaCarteViolation = (userRole: Role) =>
  createSelector([getOrderingRules, getStudent, getCartTotal], (orderingRules, student, aLaCarteTotal) => {
    return (
      userRole === Role.PARENT &&
      !!orderingRules?.aLaCarteMinimum &&
      Number(student?.balance) - Number(aLaCarteTotal) < Number(orderingRules?.aLaCarteMinimum)
    );
  });

export const showALaCarteWarning = (userRole: Role) =>
  createSelector(
    [hasALaCarteViolation(userRole), aLaCarteWarningVisible],
    (hasALaCarteViolation, aLaCarteWarningVisible) => hasALaCarteViolation && aLaCarteWarningVisible,
  );

export const reimbursableWarningVisible = (state: Interfaces.AppState): boolean =>
  get(root(state), 'reimbursableWarningVisible');

export const getReimbursableTotal = computeMenuItemsTotalFactory(Constants.PRODUCT_CATEGORIES.ENTREE.id);

export const hasReimbursableViolation = (userRole: Role) =>
  createSelector([getOrderingRules, getStudent, getCartTotal], (orderingRules, student, reimbursableTotal) => {
    return (
      userRole === Role.PARENT &&
      !!orderingRules?.reimbursableMinimum &&
      Number(student?.balance) - reimbursableTotal < Number(orderingRules?.reimbursableMinimum)
    );
  });

export const showReimbursableWarning = (userRole: Role) =>
  createSelector(
    [hasReimbursableViolation(userRole), reimbursableWarningVisible],
    (hasReimbursableViolation, reimbursableWarningVisible) => hasReimbursableViolation && reimbursableWarningVisible,
  );
