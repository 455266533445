import React from 'react';
import { IconButton, InputAdornment, TextField as InputComponent, TextFieldProps } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import classNames from 'classnames';
import * as Helper from 'Helpers/Helper';
import * as Interfaces from 'Interfaces/Interfaces';
import '../MaterialForm.less';

const TextField = ({
  errorMsg,
  touched,
  isCurrency = false,
  hideError = false,
  hideErrorMsg = false,
  hideEmptyError = true,
  showOnlyTouchedError = false,
  error,
  mode,
  numericSize,
  onChange,
  min,
  type,
  className,
  allowNegative,
  value,
  label,
  placeholder,
  onQuickClear,
  ...materialUITextInputProps
}: Interfaces.TextFieldProps) => {
  const props = { ...materialUITextInputProps } as TextFieldProps;
  props.onChange = (e: any) => {
    e.target.value = e.target.value.indexOf('.') === 0 ? e.target.value.replace('.', '0.') : e.target.value;
    e.target.value = Helper.getValueFromInputMode({
      mode,
      size: numericSize || [12, 2],
      value: e.target.value,
      min,
      allowNegative,
    });

    if (onChange) {
      onChange(e);
    }
  };

  props.InputProps = {
    ...materialUITextInputProps.InputProps,
  };

  props.InputLabelProps = {
    ...materialUITextInputProps.InputLabelProps,
    htmlFor: materialUITextInputProps.id,
  };

  const isErrorHidden =
    hideError || (!errorMsg && hideEmptyError) || (errorMsg && showOnlyTouchedError && !touched) || !errorMsg;

  return (
    <InputComponent
      className={classNames(className, { currency: isCurrency })}
      type={type}
      {...props}
      helperText={!isErrorHidden && !hideErrorMsg && errorMsg}
      error={!isErrorHidden}
      value={value}
      label={label}
      placeholder={placeholder}
      InputProps={{
        endAdornment:
          onQuickClear && value ? (
            <InputAdornment position="end">
              <IconButton
                className="clear-input"
                edge="end"
                onClick={onQuickClear}
                aria-label={`Clear ${label ?? placeholder}`}
              >
                <Clear />
              </IconButton>
            </InputAdornment>
          ) : undefined,
      }}
    />
  );
};

TextField.defaultProps = {
  autoComplete: 'no',
  autoFocus: false,
  disabled: false,
  error: false,
  multiline: false,
  isCurrency: false,
  hideError: false,
  hideEmptyError: true,
  showOnlyTouchedError: false,
  fullWidth: true,
  type: 'input',
  variant: 'outlined',
};

export { TextField };
