import { useAuth0 } from '@auth0/auth0-react';
import { MenuItem } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useApi } from 'Api/useApi';
import { Admin, TokenClaims } from 'Auth/User';
import classNames from 'classnames';
import Button from 'Components/Button/Button';
import { NotificationDialog } from 'Components/Dialogs/NotificationDialog';
import { PageLoaderNoRedux } from 'Components/Loaders/Loaders';
import { ActionColumn } from 'Components/ReactTable/ActionColumn';
import ReactTableV2 from 'Components/ReactTable/ReactTableV2/ReactTableV2';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import LinkedParentsModal from 'Components/LinkedParentsModal/LinkedParentsModal';
import * as Constants from 'Constants/Constants';
import { downloadAsCsv } from 'Helpers/Helper';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useCommonActions } from 'State/actions';
import { useDistrictStudents, useSites } from 'State/hooks';
import * as CommonSelectors from 'State/selectors';

import './StudentTable.less';

interface DialogState {
  open: boolean;
  studentName: string;
  districtId: string;
  sisId: string;
  loading: boolean;
}

interface LinkedParentsModalState {
  open: boolean;
  studentName: string;
  districtId: string;
  studentId: string;
}

const StudentTable = () => {
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const { user } = useAuth0();
  const userDistrictId = TokenClaims.getDistrictId(user);
  const userAdminLevel = TokenClaims.getAdmin(user);
  const { suspendStudent, withdrawStudent, deactivateAdult } = useCommonActions();
  const [suspendDialogState, setSuspendDialogState] = React.useState<DialogState>({
    open: false,
    studentName: '',
    districtId: '',
    sisId: '',
    loading: false,
  });
  const [reEnableDialogState, setReEnableDialogState] = React.useState<DialogState>({
    open: false,
    studentName: '',
    districtId: '',
    sisId: '',
    loading: false,
  });
  const [withdrawDialogState, setWithdrawDialogState] = React.useState<DialogState>({
    open: false,
    studentName: '',
    districtId: '',
    sisId: '',
    loading: false,
  });
  const [deactivateDialogState, setDeactivateDialogState] = React.useState<DialogState>({
    open: false,
    studentName: '',
    districtId: '',
    sisId: '',
    loading: false,
  });

  const [linkedParentsModalState, setLinkedParentsModalState] = React.useState<LinkedParentsModalState>({
    open: false,
    studentName: '',
    districtId: '',
    studentId: '',
  });

  const district = useSelector(CommonSelectors.getDistrict(userDistrictId));
  const { totalLoaded, loading } = useDistrictStudents(userDistrictId, true);
  const siteLoading = useSites(userDistrictId);
  const studentCount = district?.studentCount || 0;
  const data = useSelector(CommonSelectors.getStudentsList);
  const percentLoaded = studentCount === 0 ? 0 : (totalLoaded / studentCount) * 100;
  const [exportStudentsLoading, setExportStudentsLoading] = React.useState(false);
  const [exportAdultsLoading, setExportAdultsLoading] = React.useState(false);
  const api = useApi();

  const onStudentExportCsv = async () => {
    try {
      setExportStudentsLoading(true);
      const results = (await api.get(`/districts/${userDistrictId}/exportStudents`, {
        params: { studentExportType: 'students' },
      })) as any;
      downloadAsCsv(results.data, 'students.csv');
    } catch (error) {
    } finally {
      setExportStudentsLoading(false);
    }
  };

  const onAdultExportCsv = async () => {
    try {
      setExportAdultsLoading(true);
      const results = (await api.get(`/districts/${userDistrictId}/exportStudents`, {
        params: { studentExportType: 'adults' },
      })) as any;
      downloadAsCsv(results.data, 'adults.csv');
    } catch (error) {
    } finally {
      setExportAdultsLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'sisId',
        width: 175,
        disableFilters: false,
        dataTestId: (row: any): string => `${row.original.firstName} ${row.original.lastName}.idLbl`,
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
        width: 150,
        disableFilters: false,
        dataTestId: (row: any): string => `${row.original.firstName} ${row.original.lastName}.firstNameLbl`,
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        width: 150,
        disableFilters: false,
        dataTestId: (row: any): string => `${row.original.firstName} ${row.original.lastName}.lastNameLbl`,
      },
      {
        Header: 'Site ID',
        accessor: 'sisSiteId',
        width: 100,
        disableFilters: false,
        filterField: {
          fieldName: 'Site ID',
          fieldType: Constants.FilterFieldType.multiSelect,
          ordinal: 1,
        },
        dataTestId: (row: any): string => `${row.original.firstName} ${row.original.lastName}.siteIdLbl`,
      },
      {
        Header: 'Site Name',
        accessor: 'siteName',
        width: 225,
        disableFilters: false,
        filterField: {
          fieldName: 'Site Name',
          fieldType: Constants.FilterFieldType.multiSelect,
          ordinal: 2,
        },
        dataTestId: (row: any): string => `${row.original.firstName} ${row.original.lastName}.siteIdLbl`,
      },
      {
        Header: 'ISITE Site ID',
        accessor: 'siteId',
        width: 120,
        disableFilters: false,
        filterField: {
          fieldName: 'ISITE Site ID',
          fieldType: Constants.FilterFieldType.multiSelect,
          ordinal: 3,
        },
        dataTestId: (row: any): string => `${row.original.firstName} ${row.original.lastName}.isiteSiteIdLbl`,
      },
      {
        Header: 'Grade',
        accessor: 'grade',
        width: 100,
        disableFilters: false,
        filterField: {
          fieldName: 'Grade',
          fieldType: Constants.FilterFieldType.multiSelect,
          ordinal: 4,
        },
        dataTestId: (row: any): string => `${row.original.firstName} ${row.original.lastName}.gradeLbl`,
      },
      {
        Header: 'Homeroom ID',
        accessor: 'homeroomId',
        width: 100,
        disableFilters: false,
        filterField: {
          fieldName: 'Homeroom ID',
          fieldType: Constants.FilterFieldType.multiSelect,
          ordinal: 5,
        },
        dataTestId: (row: any): string => `${row.original.firstName} ${row.original.lastName}.homeroomIdLbl`,
      },
      {
        Header: 'Site Group',
        accessor: 'siteGroup',
        width: 250,
        disableFilters: false,
        filterField: {
          fieldName: 'Site Group',
          fieldType: Constants.FilterFieldType.multiSelect,
          ordinal: 6,
        },
        dataTestId: (row: any): string => `${row.original.firstName} ${row.original.lastName}.siteGroupLbl`,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 150,
        disableFilters: false,
        filterField: {
          fieldName: 'Status',
          fieldType: Constants.FilterFieldType.multiSelect,
          ordinal: 7,
        },
        dataTestId: (row: any): string => `${row.original.firstName} ${row.original.lastName}.statusLbl`,
      },
      {
        Header: 'Balance',
        accessor: 'balance',
        width: 100,
        disableFilters: false,
        dataTestId: (row: any): string => `${row.original.firstName} ${row.original.lastName}.balanceLbl`,
      },
      {
        Header: 'Allergens',
        accessor: 'allergens',
        width: 150,
        disableFilters: false,
        filterField: {
          fieldName: 'Allergens',
          fieldType: Constants.FilterFieldType.multiSelect,
          aggregateStringSeparator: '|',
          ordinal: 8,
        },
        dataTestId: (row: any): string => `${row.original.firstName} ${row.original.lastName}.allergensLbl`,
      },
      {
        Header: 'Ordering Status',
        accessor: (student: any) => {
          return student.suspended ? 'Suspended' : '';
        },
        width: 150,
        disableFilters: false,
        filter: 'displayTextStartsWith',
        filterField: {
          fieldName: 'Ordering Status',
          fieldType: Constants.FilterFieldType.checkbox,
          ordinal: 10,
          valueFieldAccesssor: 'suspended',
          staticOptions: ['Suspended', 'Active'],
          booleanField: true,
          booleanValueMap: { truthyValue: 'Suspended', falsyValue: 'Active' },
        },
        dataTestId: (row: any): string => `${row.original.firstName} ${row.original.lastName}.orderingStatusLbl`,
      },
      {
        Header: 'Last Modified',
        accessor: 'updatedAt',
        Cell: (props: any): string => {
          return moment(props.row.original.updatedAt).format(Constants.DATE_FORMAT_DATE_AND_TIME);
        },
        width: 145,
        disableFilters: false,
        filterField: {
          fieldName: 'Last Modified',
          fieldType: Constants.FilterFieldType.dateRange,
          ordinal: 9,
          dateRangeOptions: {
            fromDateLabel: 'From',
            toDateLabel: 'To',
            maxDayRange: 365,
          },
        },
        dataTestId: (row: any): string => `${row.original.firstName} ${row.original.lastName}.lastModifiedLbl`,
      },
      {
        Header: '',
        accessor: 'id',
        width: 35,
        disableSortBy: true,
        dataTestId: (row: any): string => `${row.original.firstName} ${row.original.lastName}.actionsMenuBtn`,
        Cell: (data: any) => {
          const {
            row: {
              original: { districtId, sisId, id, suspended, firstName, lastName, status },
            },
          } = data;
          const studentName = `${firstName ?? ''} ${lastName ?? ''}`;
          const isActiveAccount = !Constants.INACTIVE_STATUSES.includes(status);

          if (!Constants.INACTIVE_STATUSES.includes(status)) {
            return (
              <ActionColumn
                id={`actions-for-${data.row.original.id}`}
                render={({ handleClose }) => (
                  <>
                    {suspended ? (
                      <MenuItem
                        className="select-field__menu-option"
                        onClick={() => {
                          handleClose();
                          setReEnableDialogState({ open: true, studentName, districtId, sisId, loading: false });
                        }}
                        data-test-id={`${data.row.original.firstName} ${data.row.original.lastName}.reEnableOrderingBtn`}
                      >
                        Re-Enable Ordering
                      </MenuItem>
                    ) : (
                      <MenuItem
                        className="select-field__menu-option"
                        onClick={() => {
                          handleClose();
                          setSuspendDialogState({ open: true, studentName, districtId, sisId, loading: false });
                        }}
                        data-test-id={`${data.row.original.firstName} ${data.row.original.lastName}.suspendOrderingBtn`}
                      >
                        Suspend Ordering
                      </MenuItem>
                    )}
                    {isActiveAccount && status !== 'ADULT' && (
                      <MenuItem
                        className="select-field__menu-option"
                        onClick={() => {
                          handleClose();
                          setWithdrawDialogState({ open: true, studentName, districtId, sisId, loading: false });
                        }}
                        data-test-id={`${data.row.original.firstName} ${data.row.original.lastName}.withdrawStudentButton`}
                      >
                        Withdraw Student
                      </MenuItem>
                    )}
                    {isActiveAccount && status === 'ADULT' && (
                      <MenuItem
                        className="select-field__menu-option"
                        onClick={() => {
                          handleClose();
                          setDeactivateDialogState({ open: true, studentName, districtId, sisId, loading: false });
                        }}
                        data-test-id={`${data.row.original.firstName} ${data.row.original.lastName}.withdrawStudentButton`}
                      >
                        Deactivate Adult
                      </MenuItem>
                    )}
                    {userAdminLevel === Admin.SUPER && (
                      <MenuItem
                        className="select-field__menu-option"
                        onClick={() => {
                          handleClose();
                          setLinkedParentsModalState({
                            open: true,
                            studentName,
                            districtId,
                            studentId: id,
                          });
                        }}
                        data-test-id={`${data.row.original.firstName} ${data.row.original.lastName}.viewLinkedParentsButton`}
                      >
                        View Linked Parents
                      </MenuItem>
                    )}
                  </>
                )}
              />
            );
          }
          return null;
        },
      },
    ],
    [],
  );

  const onSuspendCancel = React.useCallback(
    () => setSuspendDialogState({ ...suspendDialogState, open: false }),
    [suspendDialogState],
  );
  const onSuspendOk = React.useCallback(async () => {
    setSuspendDialogState({ ...suspendDialogState, loading: true });
    await suspendStudent(suspendDialogState.districtId, suspendDialogState.sisId, true);
    setSuspendDialogState({ ...suspendDialogState, loading: false, open: false });
  }, [suspendDialogState, suspendStudent]);
  const onReEnableCancel = React.useCallback(
    () => setReEnableDialogState({ ...reEnableDialogState, open: false }),
    [reEnableDialogState],
  );
  const onReEnableOk = React.useCallback(async () => {
    setReEnableDialogState({ ...reEnableDialogState, loading: true });
    await suspendStudent(reEnableDialogState.districtId, reEnableDialogState.sisId, false);
    setReEnableDialogState({ ...reEnableDialogState, loading: false, open: false });
  }, [reEnableDialogState, suspendStudent]);

  const onWithdrawClick = React.useCallback(async () => {
    setWithdrawDialogState({ ...withdrawDialogState, loading: true });
    await withdrawStudent(withdrawDialogState.sisId);
    setWithdrawDialogState({ ...withdrawDialogState, loading: false, open: false });
  }, [withdrawDialogState, withdrawStudent]);

  const onDeactivate = React.useCallback(async () => {
    setDeactivateDialogState({ ...deactivateDialogState, loading: true });
    await deactivateAdult(deactivateDialogState.sisId);
    setDeactivateDialogState({ ...deactivateDialogState, loading: false, open: false });
  }, [deactivateDialogState, deactivateAdult]);
  const onWithdrawCancel = React.useCallback(
    () => setWithdrawDialogState({ ...withdrawDialogState, open: false }),
    [withdrawDialogState],
  );
  const onDeactivateCancel = React.useCallback(
    () => setDeactivateDialogState({ ...deactivateDialogState, open: false }),
    [deactivateDialogState],
  );
  return (
    <React.Fragment>
      <div className={classNames('student-table-container', { '.student-table-container-mobile': isMobile })}>
        <SubHeader
          title="Account Management"
          rightComponent={
            loading &&
            siteLoading && (
              <Box display="flex" alignItems="center">
                <Box minWidth={300} mr={1}>
                  <LinearProgress variant="determinate" value={Math.round(percentLoaded)} />
                </Box>
                <Box minWidth={35}>{Math.round(percentLoaded)}%</Box>
              </Box>
            )
          }
        />
        <PageLoaderNoRedux loading={loading} />
        <div className="student-table">
          <ReactTableV2
            columns={columns}
            data={data}
            autoResetPage={true}
            unitLabel="accounts"
            stickyRightColumn={true}
            getTrProps={(rowProps: any, original: any) => {
              if (Constants.INACTIVE_STATUSES.includes(original.status)) {
                rowProps.className = 'student-table-inactive-row';
              }

              return rowProps;
            }}
            bottomLeftContainer={
              <div className="report-buttons">
                {data.length && (
                  <>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={onStudentExportCsv}
                      data-test-id="studentExportCsv"
                      loading={exportStudentsLoading}
                      disabled={exportAdultsLoading || exportStudentsLoading}
                    >
                      Export Students CSV
                    </Button>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={onAdultExportCsv}
                      data-test-id="adultsExportCsv"
                      loading={exportAdultsLoading}
                      disabled={exportAdultsLoading || exportStudentsLoading}
                    >
                      Export Adults CSV
                    </Button>
                  </>
                )}
              </div>
            }
          />
        </div>
      </div>
      <NotificationDialog
        showDialog={suspendDialogState.open}
        title={`Suspend Ordering for ${suspendDialogState.studentName}`}
        message={`Are you sure you want to suspend ordering for ${suspendDialogState.studentName}?`}
        showCancel={true}
        cancelButtonText="Cancel"
        onCancelClick={onSuspendCancel}
        okButtonText="Suspend"
        onOkClick={onSuspendOk}
        loading={suspendDialogState.loading}
      />
      <NotificationDialog
        showDialog={reEnableDialogState.open}
        title={`Re-Enable Ordering for ${reEnableDialogState.studentName}`}
        message={`Are you sure you want to re-enable ordering for ${reEnableDialogState.studentName}?`}
        showCancel={true}
        cancelButtonText="Cancel"
        onCancelClick={onReEnableCancel}
        okButtonText="Re-Enable"
        onOkClick={onReEnableOk}
        loading={reEnableDialogState.loading}
      />
      <NotificationDialog
        showDialog={withdrawDialogState.open}
        title={`Withdraw ${withdrawDialogState.studentName}`}
        message={`Are you sure you want to withdraw ${withdrawDialogState.studentName}? This action can only be reversed by reimporting the student with a new status.`}
        showCancel={true}
        cancelButtonText="Cancel"
        onCancelClick={onWithdrawCancel}
        okButtonText="Withdraw"
        onOkClick={onWithdrawClick}
        loading={withdrawDialogState.loading}
      />
      <NotificationDialog
        showDialog={deactivateDialogState.open}
        title={`Deactivate ${deactivateDialogState.studentName}`}
        message={`Are you sure you want to deactivate ${deactivateDialogState.studentName}? This action can only be reversed by reimporting the adult.`}
        showCancel={true}
        cancelButtonText="Cancel"
        onCancelClick={onDeactivateCancel}
        okButtonText="Deactivate"
        onOkClick={onDeactivate}
        loading={deactivateDialogState.loading}
      />
      <LinkedParentsModal
        open={linkedParentsModalState.open}
        studentId={linkedParentsModalState.studentId}
        districtId={linkedParentsModalState.districtId}
        studentName={linkedParentsModalState.studentName}
        onClose={() => {
          setLinkedParentsModalState({ open: false, studentId: '', districtId: '', studentName: '' });
        }}
      />
    </React.Fragment>
  );
};

export default StudentTable;
