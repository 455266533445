import { useAuth0 } from '@auth0/auth0-react';
import { PageLoader } from 'Components/Loaders/Loaders';
import StudentCardList from 'Components/Student/StudentCardList/StudentCardList';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import { Student } from 'Interfaces/Interfaces';
import { uniq } from 'lodash';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as CommonHooks from 'State/hooks';
import * as CommonSelectors from 'State/selectors';
import { useStudentDistricts, useStudentSites } from './state/actions';
import { getStudentDistricts, getStudentSites } from './state/selectors';

const ParentDashboard: FC<{}> = () => {
  const students = useSelector(CommonSelectors.getStudents);
  const { user } = useAuth0();
  const districts = useSelector(getStudentDistricts);
  const sites = useSelector(getStudentSites);

  // Load the students
  CommonHooks.useParentCommonState();

  // Load the districts
  const districtIds = useMemo(() => uniq(Object.values(students).map((s: Student) => s.districtId)), [students]);
  useStudentDistricts(districtIds);
  useStudentSites(districtIds);

  return (
    <div className="parent-dashboard-container">
      <PageLoader />
      <SubHeader title={`Welcome ${user?.given_name || ''}`} />
      <StudentCardList students={students} districts={districts} sites={sites} districtIds={districtIds} />
    </div>
  );
};

export default ParentDashboard;
