import React from 'react';
import { TextField as InputComponent, MenuItem, Chip } from '@material-ui/core';
import classNames from 'classnames';
import * as Interfaces from 'Interfaces/Interfaces';
import '../MaterialForm.less';

export const Select = ({
  id,
  name,
  type = 'select',
  onChange,
  value,
  errorMsg,
  touched,
  label,
  required,
  onBlur,
  isCurrency = false,
  hideError = false,
  hideEmptyError = true,
  readOnly,
  showOnlyTouchedError = false,
  fullWidth = true,
  placeholder = '',
  disabled = false,
  error = false,
  multiline = false,
  rows = 0,
  rowsMax = 0,
  margin,
  color,
  fieldLabel,
  options,
  inputLabelProps = {},
  children,
  className,
  multiple,
}: Interfaces.MaterialSelectFieldProps) => {
  const selectProps = {
    disabled,
    error,
    fullWidth,
    id,
    multiline,
    required,
    rows,
    rowsMax,
    select: true,
    type,
    placeholder,
    onChange,
    onBlur,
    name,
    value,
    margin,
    color,
    InputProps: { id: `${id}-input` },
    InputLabelProps: { htmlFor: `${id}-input`, ...inputLabelProps },
    multiple,
  };

  const isErrorHidden =
    hideError || (!errorMsg && hideEmptyError) || (errorMsg && showOnlyTouchedError && !touched) || !errorMsg;

  const renderChipValue = React.useCallback(
    (selected: any) => {
      return selected.map((selectedValue: any) => {
        const selectedOption = options?.find((option) => {
          return option.value === selectedValue;
        });
        return <Chip label={selectedOption?.text ?? selectedValue} key={selectedValue} className="linq-chip" />;
      });
    },
    [options],
  );

  return (
    <InputComponent
      label={fieldLabel}
      variant="outlined"
      className={classNames(className, {
        'read-only': readOnly,
        currency: isCurrency,
        'select-input': true,
      })}
      SelectProps={{
        MenuProps: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          className: 'select-field__menu',
          getContentAnchorEl: null,
        },
        renderValue: multiple ? renderChipValue : undefined,
        multiple,
        // For automation testing id.
        // @ts-ignore
        SelectDisplayProps: { 'data-test-id': `${id}.DropDown` },
      }}
      helperText={errorMsg}
      {...selectProps}
      error={!isErrorHidden}
      InputLabelProps={{
        htmlFor: id,
      }}
    >
      {options &&
        options.map((item) => (
          <MenuItem className="select-field__menu-option" key={`${item.value}-${item.text}`} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      {children}
    </InputComponent>
  );
};
