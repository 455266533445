import React from 'react';
import { Button } from '@material-ui/core';
import { format } from 'date-fns';
import { sortBy } from 'lodash';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import Modal from 'Components/Modal/Modal';
import { useApi } from 'Api/useApi';
import * as Constants from 'Constants/Constants';
import ReactTableV2 from 'Components/ReactTable/ReactTableV2/ReactTableV2';

import './LinkedParentsModal.less';

export interface LinkedParentsModalProps {
  open: boolean;
  studentId: string;
  studentName: string;
  districtId: string;
  onClose: () => void;
}

const LinkedParentsModal: React.FC<LinkedParentsModalProps> = ({
  open,
  studentName,
  districtId,
  studentId,
  onClose,
}) => {
  const [linkedParents, setLinkedParents] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);
  const api = useApi();

  React.useEffect(() => {
    const fetchLinkedParents = async () => {
      setLoading(true);
      try {
        const response = await api.get(`districts/${districtId}/linkedUsers/${studentId}`);
        setLinkedParents(sortBy(response?.data ?? [], 'lastName'));
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    if (studentId) {
      fetchLinkedParents();
    }
  }, [studentId, api]);

  const handleClose = () => {
    setLinkedParents([]);
    onClose();
  };

  return (
    <Modal open={open} className="linq-dialog linq-dialog--xl linked-parents-modal" maxWidth="xl" onClose={handleClose}>
      <Modal.Title className="linq-dialog__header d-flex align-items-center" disableTypography={true}>
        <h3 className="linq-heading">Linked Parents for {studentName}</h3>
        <CloseIconButton onClick={onClose} />
      </Modal.Title>
      <Modal.Content className="linq-dialog__content">
        <ReactTableV2
          columns={[
            {
              Header: 'Last Name',
              accessor: 'lastName',
              width: 150,
              disableSortBy: true,
              dataTestId: (row: any) => {
                return `${row.original.email}.lastNameLbl`;
              },
            },
            {
              Header: 'First Name',
              accessor: 'firstName',
              width: 150,
              disableSortBy: true,
              dataTestId: (row: any) => {
                return `${row.original.email}.firstNameLbl`;
              },
            },
            {
              Header: 'Email',
              accessor: 'email',
              width: 300,
              disableSortBy: true,
              dataTestId: (row: any) => {
                return `${row.original.email}.emailLbl`;
              },
            },
            {
              Header: 'Last Login',
              accessor: 'lastLogin',
              Cell: (props: any) => {
                return format(new Date(props?.row?.original?.lastLogin), Constants.DATE_FNS_DATE_AND_TIME_FORMAT_USA);
              },
              width: 150,
              disableSortBy: true,
              dataTestId: (row: any) => {
                return `${row.original.email}.lastLoginLbl`;
              },
            },
            {
              Header: 'Registration Method',
              accessor: 'registrationMethod',
              width: 100,
              disableSortBy: true,
              dataTestId: (row: any) => {
                return `${row.original.email}.registrationMethodLbl`;
              },
            },
          ]}
          data={linkedParents}
          loading={loading}
          showFooter={false}
          manualPageSize={100}
          disableFilter={true}
          disableSearch={true}
        />
      </Modal.Content>
      <Modal.Actions className="actions">
        <Button
          variant="outlined"
          color="primary"
          aria-label="Cancel Print"
          onClick={handleClose}
          data-test-id={'closeBtn'}
        >
          close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default LinkedParentsModal;
