import { Reducer } from 'react';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';

const initialState: Interfaces.LoadersState = {
  pageLoaderActive: false,
  modalOpen: false,
  componentLoaderActive: false,
};

const LoadersReducer: Reducer<Interfaces.LoadersState, Interfaces.Action> = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.LOADER_SET_PAGE_LOADER_ACTIVE:
      return {
        ...state,
        pageLoaderActive: payload,
      };
    case Types.LOADER_MODAL_SET_OPEN:
      return {
        ...state,
        modalOpen: payload,
      };
    case Types.LOADER_SET_COMPONENT_LOADER_ACTIVE:
      return {
        ...state,
        componentLoaderActive: payload,
      };
    default:
      return state;
  }
};

export default LoadersReducer;
