import React, { FC } from 'react';
import { Product } from 'Interfaces/Interfaces';
import ReactTable from 'Components/ReactTable/ReactTable';
import { sortItems } from 'Pages/DistrictReports/ReportUtil';
import { Paper } from '@material-ui/core';

type Total = { [productId: string]: number };
type Products = { [productId: string]: Product };
type ProductTotal = { total: number } & Product;

export const mapTotalsToProducts = (totals: Total, products: Products): ProductTotal[] =>
  !totals
    ? []
    : sortItems(Object.values(products), (x) => x)
        .filter((product: Product) => totals[product.id])
        .map((product: Product) => ({
          ...product,
          total: totals[product.id],
        }));

const TotalsTable: FC<{ label: string; name: string; totals: Total; products: Products; showEmpty: boolean }> = ({
  label,
  name,
  totals,
  products,
  showEmpty,
}) => {
  const productTotals = mapTotalsToProducts(totals, products);
  const overAllTotal = productTotals.map((product: ProductTotal) => product.total).reduce((i, j) => i + j, 0);

  const columns = [
    {
      Header: label,
      accessor: 'name', // accessor is the "key" in the data,
      width: '85%',
      disableSortBy: true,
      dataTestId: (row: any) => {
        return `${row.original.name}.nameLbl`;
      },
    },
    {
      Header: '',
      accessor: 'total',
      width: '15%',
      disableSortBy: false,
      dataTestId: (row: any) => {
        return `${row.original.name}.totalLbl`;
      },
    },
  ];

  if (overAllTotal < 1) {
    return !showEmpty ? null : (
      <Paper className="totals-empty">
        <span>No orders for {name}</span>
      </Paper>
    );
  }

  return <ReactTable columns={columns} data={productTotals} showFooter={false} manualPageSize={productTotals.length} />;
};

export default TotalsTable;
