import { useCallback, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { useApi } from 'Api/useApi';
import * as LoaderActions from 'Components/Loaders/state/actions';
import * as CommonSelectors from 'State/selectors';
import * as CommonActions from 'State/actions';
import { setErrors } from 'Pages/Shop/state/actions';
import {
  setReimbursableMealPricingModes,
  setReimbursableMealPrices,
  setReimbursableMealPricesByGrade,
} from 'Components/District/state/actions';
import { getDayRange } from 'Components/DateRangeNavigator/state/selectors';
import * as Constants from 'Constants/Constants';
import { ShopError } from 'Interfaces/Interfaces';

const CancelToken = axios.CancelToken;
let cancel: any = {};

export const useShopData = (
  studentId: string | undefined,
  studentDistrictId: string,
  menuTypeId?: string,
  siteId?: string,
  overrideProductsAndMenuItems = false,
) => {
  const api = useApi();
  const dispatch = useDispatch();
  const districts = useSelector(CommonSelectors.getDistricts);
  const district = districts[studentDistrictId];
  const dayRange = useSelector(getDayRange);
  const { rangeStartDate, rangeEndDate } = useMemo(() => {
    return {
      rangeStartDate: dayRange.startDate.format(Constants.DATE_FORMAT_YMD),
      rangeEndDate: dayRange.endDate.format(Constants.DATE_FORMAT_YMD),
    };
  }, [dayRange]);
  const orderingRules = district?.orderingRules;
  const offerOnlyAlaCarte =
    orderingRules?.offerOnlyAlaCarte || orderingRules?.offerOnlyALaCarteNoPublishedMenus || false;
  const aLaCarteStartDate = orderingRules?.offerOnlyALaCarteNoPublishedMenus
    ? rangeStartDate
    : orderingRules?.aLaCarteStartDate;
  const aLaCarteEndDate = orderingRules?.offerOnlyALaCarteNoPublishedMenus
    ? rangeEndDate
    : orderingRules?.aLaCarteEndDate;

  const getShopData = useCallback(async () => {
    const startDate = offerOnlyAlaCarte ? aLaCarteStartDate : rangeStartDate;
    const endDate = offerOnlyAlaCarte ? aLaCarteEndDate : rangeEndDate;

    try {
      if (studentDistrictId) {
        dispatch(LoaderActions.setComponentLoaderActive(true));
        dispatch(setErrors([]));
        if (cancel.shop) {
          cancel.shop();
        }
        const results = (await api.get(`/utility/shop/${studentDistrictId}`, {
          params: {
            studentId,
            menuTypeId,
            dateFrom: rangeStartDate,
            dateTo: rangeEndDate,
            aLaCarteStart: startDate,
            aLaCarteEnd: endDate,
            aLaCarteRenderIndependent: offerOnlyAlaCarte,
            siteId,
          },
          cancelToken: new CancelToken(function executor(c) {
            cancel.shop = c;
          }),
        })) as any;

        batch(() => {
          dispatch(CommonActions.setSites(results?.data?.sites));
          dispatch(CommonActions.setOrderItems(results?.data?.orderItems));
          dispatch(CommonActions.setPickupLocations(results?.data?.pickupLocations));

          if (overrideProductsAndMenuItems) {
            dispatch(CommonActions.setMenuItems(results?.data?.menuItems));
            dispatch(CommonActions.setProducts(results?.data?.products));
          } else {
            dispatch(CommonActions.appendMenuItems(results?.data?.menuItems));
            dispatch(CommonActions.appendProducts(results?.data?.products));
          }
          dispatch(setReimbursableMealPricingModes(results?.data?.reimbursableMealPricingMode));
          dispatch(setReimbursableMealPricesByGrade(results?.data?.reimbursableMealPricesByGrade));
          dispatch(setReimbursableMealPrices(results?.data?.reimbursableMealPrices));
        });
      }
    } catch (error: any) {
      if (error.response?.data?.attributes?.errorCodes.length > 0) {
        const shopErrors: ShopError[] = [];
        error.response?.data?.attributes?.errorCodes.forEach((errorCode: string) =>
          shopErrors.push({ message: error.message, errorCode: errorCode }),
        );
        dispatch(setErrors(shopErrors));
      } else if (!axios.isCancel(error)) {
        dispatch(setErrors([{ message: error.message, errorCode: '' }]));
      }
    } finally {
      dispatch(LoaderActions.setComponentLoaderActive(false));
    }
  }, [
    api,
    dispatch,
    studentDistrictId,
    aLaCarteEndDate,
    aLaCarteStartDate,
    rangeEndDate,
    rangeStartDate,
    menuTypeId,
    studentId,
    offerOnlyAlaCarte,
    siteId,
    overrideProductsAndMenuItems,
  ]);

  return { getShopData };
};
