import React from 'react';

export interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: React.ReactNode) => any;
  children: React.ReactNode;
}

/**
 * Ability to conditionally wrap jsx elements.
 *
 * ```
 * <ConditionalWrapper
 *   condition={link}
 *   wrapper={children => <a href={link}>{children}</a>}
 * >
 * ```
 * https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
 */
export const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps) =>
  condition && wrapper ? wrapper(children) : children;
