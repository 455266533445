import React, { FC } from 'react';

import * as LoadersSelectors from './state/selectors';

import './Loaders.less';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

interface LoaderProps {
  loading?: boolean;
  inline?: boolean | 'centered';
  text?: string;
}
const PLoader = ({ text = 'Loading' }: { text?: string }) => {
  return (
    <div className="page-loader-container">
      <CircularProgress />
      <div className="loader__loader-text">{text}</div>
    </div>
  );
};

export const PageLoaderNoRedux: FC<LoaderProps> = ({ loading, text }) => {
  if (!loading) {
    return null;
  }

  return <PLoader text={text} />;
};

export const PageLoader: FC<{ text?: string }> = ({ text }) => {
  const pageLoaderActive = useSelector(LoadersSelectors.getPageLoaderActive);

  if (!pageLoaderActive) {
    return null;
  }

  return <PLoader text={text} />;
};

const CLoader = ({ text = 'Loading' }: { text?: string }) => {
  return (
    <div className="component-loader-container">
      <CircularProgress />
      <div className="loader__loader-text">{text}</div>
    </div>
  );
};

export const ComponentLoader: FC<{ text?: string }> = ({ text }) => {
  const componentLoaderActive = useSelector(LoadersSelectors.getComponentLoaderActive);

  if (!componentLoaderActive) {
    return null;
  }

  return <CLoader text={text} />;
};

export const ComponentLoaderNoRedux: FC<LoaderProps> = ({ loading, text }) => {
  if (!loading) {
    return null;
  }

  return <CLoader text={text} />;
};

export const InlineLoader: FC<LoaderProps> = ({ loading, text = 'Loading' }) => {
  if (loading) {
    return (
      <div className="loader__inline">
        <CircularProgress />
        <div className="loader__loader-text">{text}</div>
      </div>
    );
  }
  return null;
};
