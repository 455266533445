import React, { ChangeEvent, FC } from 'react';
import { isBefore, isValid, startOfMonth, endOfMonth, differenceInDays } from 'date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FormControlLabel, Radio, RadioGroup, Checkbox } from '@material-ui/core';
import Button from 'Components/Button/Button';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'Components/Drawer';
import { SelectField } from 'Components/Form/MaterialForm';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import { DateRangeError, FilterField, MaterialSelectOption, TableFilterState } from 'Interfaces/Interfaces';
import { KeyboardDateRangePickerField } from 'Components/Form/MaterialForm/KeyboardDateRangePicker';
import { DateRangeErrorType, DATE_FNS_DATE_FORMAT_USA, FilterFieldType } from 'Constants/Constants';
import { getClearedFilterState, getFilterChips } from './ReactTableV2';

import './Filter.less';

export interface FilterSidebarProps {
  filterFields: FilterField[];
  tablefilterState: TableFilterState;
  setTableFilterState: React.Dispatch<React.SetStateAction<TableFilterState>>;
  handleCancelClick: () => void;
  handleSubmit: () => void;
  open: boolean;
  setSidebarOpen: (open: boolean) => void;
}

const FilterSidebar: FC<FilterSidebarProps> = ({
  filterFields = [],
  open = false,
  setSidebarOpen,
  setTableFilterState,
  tablefilterState,
  handleCancelClick,
  handleSubmit,
}) => {
  const [dateRangeErrors, setDateRangeErrors] = React.useState<DateRangeError[]>([]);

  const isDateRangeValidLength = (fromDate: Date, toDate: Date, maxDayRange: number): boolean => {
    if (differenceInDays(toDate, fromDate) > maxDayRange) {
      return false;
    }

    return true;
  };

  const updateDateRangeErrorState = (fieldName: string) => {
    const newDateRangeErrorsState: DateRangeError[] = [];

    const filterField = filterFields.find((x) => x.fieldName === fieldName);

    //Overcheck that field is a dateRange type field
    if (filterField && filterField.fieldType === FilterFieldType.dateRange) {
      const fromDateValue = tablefilterState[filterField.fieldName]?.value?.fromDate;
      const toDateValue = tablefilterState[filterField.fieldName]?.value?.toDate;

      if (fromDateValue && toDateValue && isValid(fromDateValue) && isValid(toDateValue)) {
        //validate max date range
        if (
          filterField.dateRangeOptions?.maxDayRange &&
          !isDateRangeValidLength(fromDateValue, toDateValue, filterField.dateRangeOptions?.maxDayRange)
        ) {
          const errorMessage = filterField.dateRangeOptions?.invalidDateRangeMessage
            ? filterField.dateRangeOptions.invalidDateRangeMessage
            : `The date range may not exceed ${filterField.dateRangeOptions?.maxDayRange}`;

          newDateRangeErrorsState.push({
            fieldName: filterField.fieldName,
            error: errorMessage,
            errorType: DateRangeErrorType.maxDateRange,
          });
        }

        //Validate to date is after from date
        if (isBefore(toDateValue, fromDateValue)) {
          newDateRangeErrorsState.push({
            fieldName: filterField.fieldName,
            error: `${filterField.dateRangeOptions?.toDateLabel || 'End'} date cannot be before ${
              filterField.dateRangeOptions?.fromDateLabel || 'Start'
            }`,
            errorType: DateRangeErrorType.toDatePriorToFromDate,
          });
        }
      } else if ((!fromDateValue || !toDateValue) && filterField.alwaysSet) {
        newDateRangeErrorsState.push({
          fieldName: filterField.fieldName,
          error: 'A date range must be set',
          errorType: DateRangeErrorType.noDateRange,
        });
      } else if (!isValid(fromDateValue) || !isValid(toDateValue)) {
        newDateRangeErrorsState.push({
          fieldName: filterField.fieldName,
          error: 'The date range contains an invalid date',
          errorType: DateRangeErrorType.invalidDate,
        });
      }
      setDateRangeErrors(newDateRangeErrorsState);
    }
  };

  const clearFilterFieldState = () => {
    const newFieldState = getClearedFilterState(tablefilterState, filterFields);

    setDateRangeErrors([]);
    setTableFilterState(newFieldState);
  };

  const onSelectRadioFieldChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const fieldName = event.target.name;
    const newFieldState: TableFilterState = { ...tablefilterState };

    newFieldState[fieldName].value = event.target.value;
    newFieldState[fieldName].text = filterFields
      .find((filterField) => filterField.fieldName === fieldName)
      ?.options?.find((option) => option.value === event.target.value)?.text;
    setTableFilterState(newFieldState);
  };

  const onMultiSelectChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const fieldName = event.target.name;
    const newFieldState: TableFilterState = { ...tablefilterState };

    newFieldState[fieldName].value = event.target.value;

    setTableFilterState(newFieldState);
  };

  const onSelectRadioFieldChipDelete = (fieldName: string) => {
    const newFieldState: TableFilterState = { ...tablefilterState };

    newFieldState[fieldName].value = '';
    newFieldState[fieldName].text = '';
    setTableFilterState(newFieldState);
  };

  const onCheckboxChange = (fieldName: string, option: string) => {
    const newFieldState: TableFilterState = { ...tablefilterState };

    if (newFieldState[fieldName].value.includes(option)) {
      newFieldState[fieldName].value.splice(newFieldState[fieldName].value.indexOf(option), 1);
    } else {
      newFieldState[fieldName].value.push(option);
    }

    setTableFilterState(newFieldState);
  };

  const onFromDateRangeChange = (fieldName: string, date: MaterialUiPickersDate) => {
    const newFieldState: TableFilterState = { ...tablefilterState };

    newFieldState[fieldName].value.fromDate = date;

    setTableFilterState(newFieldState);
    updateDateRangeErrorState(fieldName);
  };

  const onToDateRangeChange = (fieldName: string, date: MaterialUiPickersDate) => {
    const newFieldState: TableFilterState = { ...tablefilterState };

    newFieldState[fieldName].value.toDate = date;

    setTableFilterState(newFieldState);
    updateDateRangeErrorState(fieldName);
  };

  const onDateRangeChipDelete = (fieldName: string) => {
    const newFieldState: TableFilterState = { ...tablefilterState };

    newFieldState[fieldName].value = { toDate: null, fromDate: null };

    setTableFilterState(newFieldState);
    updateDateRangeErrorState(fieldName);
  };

  return (
    <Drawer open={open} anchor="right">
      <DrawerHeader>
        <h2>Filter Options</h2>
        <CloseIconButton
          onClick={() => {
            handleCancelClick();
            setDateRangeErrors([]);
            setSidebarOpen(false);
          }}
        />
      </DrawerHeader>
      <DrawerContent className="filter-container">
        <div className="selected-filters">
          {getFilterChips(
            tablefilterState,
            filterFields,
            onCheckboxChange,
            onDateRangeChipDelete,
            onSelectRadioFieldChipDelete,
          )}
        </div>
        {filterFields.map((filterField) => {
          switch (filterField.fieldType) {
            case FilterFieldType.select:
              return (
                <SelectField
                  options={filterField.options}
                  name={filterField.fieldName}
                  fieldLabel={filterField.fieldName}
                  id={`${filterField.fieldName}${filterField.fieldType}`}
                  onChange={onSelectRadioFieldChange}
                  value={tablefilterState[filterField.fieldName]?.value}
                />
              );
            case FilterFieldType.radio:
              return (
                <>
                  <h6>{filterField.fieldName}</h6>
                  <RadioGroup
                    aria-label="Choose a pickup location for your order"
                    name={filterField.fieldName}
                    value={tablefilterState[filterField.fieldName]?.value}
                    onChange={onSelectRadioFieldChange}
                  >
                    {filterField.options?.map((option: MaterialSelectOption) => (
                      <FormControlLabel
                        value={option.value}
                        control={<Radio />}
                        label={option.text}
                        key={option.value}
                      />
                    ))}
                  </RadioGroup>
                </>
              );
            case FilterFieldType.checkbox:
              return (
                <div className="checkbox-container">
                  <h6>{filterField.fieldName}</h6>
                  <div>
                    {filterField.checkboxOptions?.map((option: string) => (
                      <FormControlLabel
                        style={{ width: '100%' }}
                        name={`${filterField.fieldName}-${option}`}
                        control={
                          <Checkbox
                            checked={tablefilterState[`${filterField.fieldName}`]?.value.includes(option)}
                            value={option}
                            onChange={() => onCheckboxChange(`${filterField.fieldName}`, option)}
                            data-test-id={`${option}.checkbox`}
                          />
                        }
                        label={option}
                        key={`${filterField.fieldName}${option}`}
                      />
                    ))}
                  </div>
                </div>
              );
            case FilterFieldType.dateRange:
              const errorMessage = dateRangeErrors.find(
                (x) => x.fieldName === filterField.fieldName && x.errorType !== DateRangeErrorType.invalidDate,
              )?.error;
              const fromDateValue = tablefilterState[filterField.fieldName]?.value?.fromDate;
              const toDateValue = tablefilterState[filterField.fieldName]?.value?.toDate;

              return (
                <React.Fragment key={`date-range-field-container-${filterField.fieldName}`}>
                  <h6 className="dateRange-header">{filterField.fieldName}</h6>
                  <KeyboardDateRangePickerField
                    key={`date-range-field-${filterField.fieldName}`}
                    fromDateProps={{
                      value: fromDateValue,
                      onChange: (date) =>
                        onFromDateRangeChange(
                          filterField.fieldName,
                          filterField.dateRangeOptions?.view === 'month' ? startOfMonth(date as Date) : date,
                        ),
                      format: DATE_FNS_DATE_FORMAT_USA,
                      label: filterField.dateRangeOptions?.fromDateLabel || 'Start',
                      errorMsg: fromDateValue && !isValid(fromDateValue) ? 'Invalid Date' : errorMessage,
                      showOnlyTouchedError: false,
                      views: [filterField.dateRangeOptions?.view ?? 'date'],
                      disablePast: filterField.dateRangeOptions?.disablePast,
                    }}
                    toDateProps={{
                      value: toDateValue,
                      onChange: (date) =>
                        onToDateRangeChange(
                          filterField.fieldName,
                          filterField.dateRangeOptions?.view === 'month' ? endOfMonth(date as Date) : date,
                        ),
                      format: DATE_FNS_DATE_FORMAT_USA,
                      label: filterField.dateRangeOptions?.toDateLabel || 'End',
                      errorMsg: toDateValue && !isValid(toDateValue) ? 'Invalid Date' : errorMessage,
                      views: [filterField.dateRangeOptions?.view ?? 'date'],
                      disablePast: filterField.dateRangeOptions?.disablePast,
                    }}
                  />
                </React.Fragment>
              );

            case FilterFieldType.multiSelect:
              return (
                <SelectField
                  options={filterField.options}
                  name={filterField.fieldName}
                  fieldLabel={filterField.fieldName}
                  id={`${filterField.fieldName}${filterField.fieldType}`}
                  key={`${filterField.fieldName}${filterField.fieldType}`}
                  onChange={onMultiSelectChange}
                  value={tablefilterState[filterField.fieldName]?.value}
                  multiple
                />
              );
            default:
              return null;
          }
        })}
      </DrawerContent>
      <DrawerFooter>
        <Button
          type="button"
          color="primary"
          variant="outlined"
          aria-label="Clear"
          onClick={() => {
            clearFilterFieldState();
          }}
          data-test-id="clearBtn"
        >
          Clear
        </Button>
        <Button
          color="primary"
          variant="contained"
          aria-label="Submit"
          onClick={() => handleSubmit()}
          disabled={dateRangeErrors.length > 0}
          data-test-id="saveBtn"
        >
          Save
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default FilterSidebar;
