import React from 'react';
import { useSelector } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import { User } from '@auth0/auth0-react';
import { FormControlLabel } from '@material-ui/core';
import { NotificationType } from 'Constants/Constants';
import { Field, FieldProps, Form, Formik, FormikProps, FormikValues } from 'formik';
import { TokenClaims } from 'Auth/User';
import { AutocompleteDropdown } from 'Components/Form/MaterialForm';
import MultiActionCard from 'Components/MultiActionCard/MultiActionCard';
import * as CommonSelectors from 'State/selectors';
import { useSites } from 'State/hooks';

import './NotificationSettingsForm.less';

interface ProfileFormProps {
  handleSubmit: (values: FormikValues) => void;
  loading: boolean;
  user?: User;
}

const NotificationSettingsForm = ({ handleSubmit, loading, user }: ProfileFormProps) => {
  const districtId = TokenClaims.getDistrictId(user);
  const notificationSubscriptions = user?.notificationSubscriptions ?? {};

  const siteLoading = useSites(districtId);

  const sites = useSelector(CommonSelectors.getSites);

  const siteOptions = React.useMemo(() => {
    return Object.values(sites ?? {})
      .map((site) => {
        return { text: site.name, value: site.id };
      })
      .sort((siteA, siteB) => {
        return siteA.text.localeCompare(siteB.text);
      });
  }, [sites]);

  const handleFormSubmit = async (values: FormikValues) => {
    const newNotificationSubscriptions = {
      // preserve any notification subscriptions not managed by OO
      ...notificationSubscriptions,
      [NotificationType.orderCancellationNotification]: {
        subscribed: values.enableCancellationEmails,
        siteFilter: values.cancellationOrderSiteFilter,
      },
      [NotificationType.missingPriceNotification]: {
        subscribed: values.enableMissingPriceEmails,
        siteFilter: values.missingPriceOrderSiteFilter,
      },
    };

    handleSubmit(newNotificationSubscriptions);
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        onSubmit={handleFormSubmit}
        initialValues={{
          enableCancellationEmails:
            notificationSubscriptions[NotificationType.orderCancellationNotification]?.subscribed ?? true,
          cancellationOrderSiteFilter:
            notificationSubscriptions[NotificationType.orderCancellationNotification]?.siteFilter ?? [],
          enableMissingPriceEmails: notificationSubscriptions[NotificationType.missingPriceNotification]?.subscribed,
          missingPriceOrderSiteFilter:
            notificationSubscriptions[NotificationType.missingPriceNotification]?.siteFilter ?? [],
        }}
      >
        {({
          dirty,
          values,
        }: FormikProps<{
          enableCancellationEmails: boolean;
          cancellationOrderSiteFilter: string[] | undefined;
          enableMissingPriceEmails: boolean;
          missingPriceOrderSiteFilter: string[] | undefined;
        }>) => {
          return (
            <Form>
              <div className="register-card-container">
                <MultiActionCard
                  cardClassname="register-card"
                  title={'Notification Settings'}
                  loading={loading}
                  submitLabel={user ? 'Update' : 'Submit'}
                  submitButtonVarient="contained"
                  submitAriaLabel={user ? 'Update' : 'Submit'}
                  submitDisabled={!dirty || loading}
                >
                  <h4>Parent/Teacher Order Cancellation Emails</h4>
                  <div className="cancellation-email-container">
                    <p>Enable this option to receive emails when a parent or teacher cancels an order.</p>
                    <Field name="enableCancellationEmails">
                      {({ field }: FieldProps) => (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={field.value}
                              onChange={field.onChange}
                              name={'enableCancellationEmails'}
                              color="primary"
                              data-test-id="enableCancellationEmailsBtn"
                            />
                          }
                          label={<span>Enable Parent/Teacher Order Cancellation Email Notifications</span>}
                        />
                      )}
                    </Field>

                    {values.enableCancellationEmails && (
                      <div>
                        <p>
                          Please select sites from which to receive order cancellation email notifications. Leaving this
                          dropdown empty will mean email notification will be received from all sites.
                        </p>
                        <Field name="cancellationOrderSiteFilter">
                          {({ field, form }: FieldProps) => {
                            return (
                              <AutocompleteDropdown
                                id={'cancellationOrderSiteFilter'}
                                value={field.value ?? []}
                                multiple
                                label={'Order Site Filter'}
                                multipleOnChange={(e: any, value: any) => {
                                  form.setFieldValue(
                                    'cancellationOrderSiteFilter',
                                    value.map((x: any) => x.value),
                                  );
                                }}
                                options={siteOptions}
                                loading={siteLoading}
                                data-test-id="cancellationOrderSiteFilterDropDown"
                              />
                            );
                          }}
                        </Field>
                      </div>
                    )}
                  </div>

                  <h4>Missing Reimbursable Meal Price Emails</h4>
                  <div style={{ paddingLeft: '24px' }}>
                    <p>
                      Enable this option to receive email notifications when a teacher order fails to process due to a
                      missing reimbursable meal price.
                    </p>
                    <Field name="enableMissingPriceEmails">
                      {({ field }: FieldProps) => (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={field.value}
                              onChange={field.onChange}
                              name={'enableMissingPriceEmails'}
                              color="primary"
                              data-test-id="enableMissingPriceEmailsBtn"
                            />
                          }
                          label={<span>Enable Missing Reimbursable Meal Price Email Notifications</span>}
                          style={{ marginBottom: '24px' }}
                        />
                      )}
                    </Field>
                    {values.enableMissingPriceEmails && (
                      <div>
                        <p>
                          Please select sites from which to receive order cancellation email notifications. Leaving this
                          dropdown empty will mean email notification will be received from all sites.
                        </p>
                        <Field name="missingPriceOrderSiteFilter" key={'missingPriceOrderSiteFilter'}>
                          {({ field, form }: FieldProps) => (
                            <AutocompleteDropdown
                              id={'missingPriceOrderSiteFilter'}
                              value={field.value ?? []}
                              multiple
                              multipleOnChange={(e: any, value: any) => {
                                form.setFieldValue(
                                  'missingPriceOrderSiteFilter',
                                  value.map((x: any) => x.value),
                                );
                              }}
                              label={'Order Site Filter'}
                              options={siteOptions}
                              loading={siteLoading}
                              data-test-id="missingPriceOrderSiteFilterDropDown"
                            />
                          )}
                        </Field>
                      </div>
                    )}
                  </div>
                </MultiActionCard>
              </div>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default NotificationSettingsForm;
