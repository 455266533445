import React, { FC, useState, useMemo, useCallback } from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';
import { useEffect } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, addDays, parse, isValid } from 'date-fns';
import { Formik, Form, FormikValues, Field, FieldProps, FormikProps } from 'formik';
import { InputField, KeyboardDatePickerField } from 'Components/Form/MaterialForm';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'Components/Drawer';
import { InputMode, SidebarLocations } from 'Constants/Enums';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import Button from 'Components/Button/Button';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';
import { useCommonActions } from 'State/actions';
import { OrderingRules } from 'Interfaces/Interfaces';
import { DATE_FNS_DATE_FORMAT_USA } from 'Constants/Constants';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export interface ALaCarteRulesSidebarProps {
  open: boolean;
  orderingRules: OrderingRules;
  setSidebarOpen: (open: boolean) => void;
}

const ALaCarteRulesSidebar: FC<ALaCarteRulesSidebarProps> = ({ open = false, orderingRules, setSidebarOpen }) => {
  const { updateDistrict } = useCommonActions();
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string | null>(orderingRules.aLaCarteStartDate || null);
  const [endDate, setEndDate] = useState<string | null>(orderingRules.aLaCarteEndDate || null);
  const minDate = useMemo(
    () =>
      startDate && isValid(new Date(startDate))
        ? format(addDays(parse(startDate, DATE_FNS_DATE_FORMAT_USA, new Date()), 1), DATE_FNS_DATE_FORMAT_USA)
        : undefined,
    [startDate],
  );
  const maxDate = useMemo(
    () =>
      endDate && isValid(new Date(endDate))
        ? format(addDays(parse(endDate, DATE_FNS_DATE_FORMAT_USA, new Date()), -1), DATE_FNS_DATE_FORMAT_USA)
        : undefined,
    [endDate],
  );

  useEffect(() => {
    setStartDate(orderingRules.aLaCarteStartDate || null);
    setEndDate(orderingRules.aLaCarteEndDate || null);
  }, [orderingRules.aLaCarteStartDate, orderingRules.aLaCarteEndDate]);

  const handleSubmit = async ({
    aLaCarteMinimum,
    offerOnlyAlaCarte,
    offerOnlyALaCarteNoPublishedMenus,
  }: FormikValues) => {
    setLoading(true);
    const updatedDistrict = {
      orderingRules: {
        ...orderingRules,
        aLaCarteMinimum: !aLaCarteMinimum ? '' : aLaCarteMinimum,
        offerOnlyAlaCarte,
        aLaCarteStartDate: offerOnlyAlaCarte ? startDate : '',
        aLaCarteEndDate: offerOnlyAlaCarte ? endDate : '',
        offerOnlyALaCarteNoPublishedMenus,
      },
    };
    await updateDistrict(updatedDistrict);
    setLoading(false);
    setSidebarOpen(false);
  };

  const handleStartDateChange = useCallback(
    (newDate: MaterialUiPickersDate, newDateString: string | null | undefined) => {
      if (!newDate || !isValid(newDate)) {
        setStartDate(newDateString || '');
      } else {
        setStartDate(format(newDate, DATE_FNS_DATE_FORMAT_USA));
      }
    },
    [],
  );

  const handleEndDateChange = useCallback(
    (newDate: MaterialUiPickersDate, newDateString: string | null | undefined) => {
      if (!newDate || !isValid(newDate)) {
        setEndDate(newDateString || '');
      } else {
        setEndDate(format(newDate, DATE_FNS_DATE_FORMAT_USA));
      }
    },
    [],
  );

  const handleValidation = ({ offerOnlyAlaCarte }: FormikValues) => {
    const errors: any = {};

    if (offerOnlyAlaCarte) {
      if (!startDate) {
        errors.startDate = 'Field is required.';
      } else if (!isValid(parse(startDate, DATE_FNS_DATE_FORMAT_USA, new Date()))) {
        errors.startDate = 'Invalid Date.';
      }

      if (!endDate) {
        errors.endDate = 'Field is required.';
      } else if (!isValid(parse(endDate, DATE_FNS_DATE_FORMAT_USA, new Date()))) {
        errors.endDate = 'Invalid Date.';
      }
    }

    return errors;
  };
  return (
    <Drawer open={open} id="online-ordering-card-labels" anchor="right" className="ordering-rules-sidebar-container">
      <Formik initialValues={orderingRules} onSubmit={handleSubmit} validate={handleValidation}>
        {({ handleReset, values, errors }: FormikProps<any>) => {
          return (
            <Form className="linq-drawer-form-content oo-drawer-form">
              <DrawerHeader>
                <h2>Edit A la Carte Rules</h2>
                <CloseIconButton
                  onClick={() => {
                    setSidebarOpen(false);
                  }}
                />
              </DrawerHeader>
              <NotificationBanner location={SidebarLocations.A_LA_CARTE_RULES_SIDE_BAR} />
              <DrawerContent>
                <section>
                  <p>Set minimum required meals balance to order a la carte</p>
                  <Field name="aLaCarteMinimum">
                    {({ field, form }: FieldProps) => (
                      <InputField
                        id={'aLaCarteMinimum'}
                        name={'aLaCarteMinimum'}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        defaultValue={field.value || ''}
                        error={!!form.errors.aLaCarteMinimum}
                        helperText={form.errors.aLaCarteMinimum as string}
                        touched={!!form.errors.aLaCarteMinimum}
                        isCurrency
                        mode={InputMode.Decimal}
                        numericSize={[7, 2]}
                        label={'A la Carte Minimum'}
                        value={field.value}
                        inputProps={{
                          'data-test-id': 'aLaCarteMinimumInput',
                        }}
                      />
                    )}
                  </Field>
                  <Field type="checkbox" name="offerOnlyAlaCarte">
                    {({ field, form }: FieldProps) => (
                      <FormControlLabel
                        className="offer-only-a-la-carte"
                        control={
                          <Switch
                            checked={field.value || false}
                            onChange={field.onChange}
                            name="offerOnlyAlaCarte"
                            color="primary"
                            data-test-id="offerOnlyAlaCarteBtn"
                            disabled={form.values.offerOnlyALaCarteNoPublishedMenus}
                          />
                        }
                        label={
                          <span>
                            Offer A La Carte menu items{' '}
                            <em>
                              <strong>ONLY</strong>
                            </em>
                          </span>
                        }
                      />
                    )}
                  </Field>
                  {values.offerOnlyAlaCarte && (
                    <>
                      <div className="date-picker-container">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePickerField
                            autoOk={true}
                            label="Start Date"
                            invalidDateMessage="Please input date in MM/DD/YYYY format"
                            showTodayButton={true}
                            value={startDate}
                            format={DATE_FNS_DATE_FORMAT_USA}
                            onChange={handleStartDateChange}
                            allowKeyboardControl={true}
                            style={{ width: 225, marginRight: 32 }}
                            maxDate={maxDate}
                            clearable={true}
                            errorMsg={errors.startDate as string}
                            inputProps={{
                              'data-test-id': 'startDateInput',
                            }}
                          />
                          <KeyboardDatePickerField
                            autoOk={true}
                            label="End Date"
                            invalidDateMessage="Please input date in MM/DD/YYYY format"
                            showTodayButton={true}
                            value={endDate}
                            format={DATE_FNS_DATE_FORMAT_USA}
                            onChange={handleEndDateChange}
                            allowKeyboardControl={true}
                            style={{ width: 225 }}
                            minDate={minDate}
                            clearable={true}
                            errorMsg={errors.endDate as string}
                            inputProps={{
                              'data-test-id': 'endDateInput',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </>
                  )}
                </section>
                <Field type="checkbox" name="offerOnlyALaCarteNoPublishedMenus">
                  {({ field, form }: FieldProps) => (
                    <FormControlLabel
                      className="offer-only-a-la-carte"
                      control={
                        <Switch
                          checked={field.value || false}
                          onChange={field.onChange}
                          name="offerOnlyALaCarteNoPublishedMenus"
                          color="primary"
                          data-test-id="offerOnlyALaCarteNoPublishedMenusBtn"
                          disabled={form.values.offerOnlyAlaCarte}
                        />
                      }
                      label={
                        <span>
                          Offer A La Carte{' '}
                          <em>
                            <strong>WITHOUT</strong>
                          </em>{' '}
                          Published Menus
                        </span>
                      }
                    />
                  )}
                </Field>
              </DrawerContent>
              <DrawerFooter>
                <Button
                  type="button"
                  color="primary"
                  variant="outlined"
                  disabled={loading}
                  onClick={() => {
                    setSidebarOpen(false);
                    handleReset();
                  }}
                  data-test-id="cancelBtn"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  aria-label="Submit"
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  data-test-id="submitBtn"
                >
                  Save
                </Button>
              </DrawerFooter>
            </Form>
          );
        }}
      </Formik>
    </Drawer>
  );
};

export default ALaCarteRulesSidebar;
