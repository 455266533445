import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Checkbox, Switch } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TrashIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import { isAuthorized, Role, TokenClaims } from 'Auth/User';
import Button from 'Components/Button/Button';
import { ButtonLink } from 'Components/ButtonLink/ButtonLink';
import DateRangeNavigator from 'Components/DateRangeNavigator/DateRangeNavigator';
import { setDayOfYear, setStartDate } from 'Components/DateRangeNavigator/state/actions';
import * as DateRangeNavigatorSelectors from 'Components/DateRangeNavigator/state/selectors';
import { NotificationDialog } from 'Components/Dialogs/NotificationDialog';
import { ComponentLoader } from 'Components/Loaders/Loaders';
import { setComponentLoaderActive } from 'Components/Loaders/state/actions';
import { getComponentLoaderActive } from 'Components/Loaders/state/selectors';
import MenuTypePicker from 'Components/MenuTypePicker/MenuTypePicker';
import * as MenuTypePickerActions from 'Components/MenuTypePicker/state/actions';
import * as MenuTypePickerSelectors from 'Components/MenuTypePicker/state/selectors';
import PageFooter from 'Components/PageFooter/PageFooter';
import { ScrollingReactTable } from 'Components/ReactTable';
import CutOffWarningMessage from 'Components/Shop/CutOffWarning/CutOffWarning';
import { isViolatingOrderingRules } from 'Components/Shop/ShopUtil';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import {
  BulkOrderTable,
  CartSidebar,
  EntreeList,
  EntreeSidebar,
  OrderConfirmationSidebar,
} from 'Components/TeacherOrdering';
import * as Constants from 'Constants/Constants';
import { SidebarLocations } from 'Constants/Enums';
import { RouteId, Routes } from 'Constants/Routes';
import {
  convertCartToBulkOrderItems,
  createOrderItemsFromTeacherCart,
  generateCartId,
  getItemsExceedingOrderLimit,
  getTeacherCartProducts,
  isDateBlackedOut,
} from 'Helpers/Helper';
import * as Interfaces from 'Interfaces/Interfaces';
import { cloneDeep, get, groupBy, isEmpty, partition, set, uniq } from 'lodash';
import moment from 'moment-timezone';
import { useShopData } from 'Pages/Shop/state/hooks';
import * as ShopSelectors from 'Pages/Shop/state/selectors';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-table';
import { useCommonActions, setSidebarOpen } from 'State/actions';
import * as CommonSelectors from 'State/selectors';
import { useTableCheckboxSelection } from 'Helpers/hooks';
import * as TeacherRosterOrderingActions from './state/actions';
import * as TeacherRosterOrderingSelectors from './state/selectors';
import { watchForFocusStickyIssue } from './watchForFocusStickyIssue';

import './TeacherRosterOrdering.less';

interface OrderConfirmationState {
  orderItemsById: Record<string, Interfaces.OrderItem[]>;
  pickupLocationId: string;
  products: Record<string, Interfaces.Product>;
}

enum DialogTypes {
  CANCEL_ORDER,
  EDIT_CART_ITEM,
}

interface DialogState {
  orderItems?: Interfaces.OrderItem[];
  loading: boolean;
  show: boolean;
  type: DialogTypes | null;
  entree?: Interfaces.MenuItem;
  sides?: Record<string, Interfaces.MenuItem[]>;
}

const sortStudentColumn = (rowA: Row<any>, rowB: Row<any>, columnId: string) => {
  const studentNameA = `${rowA.original.lastName} ${rowA.original.firstName} `;
  const studentNameB = `${rowB.original.lastName} ${rowB.original.firstName}`;
  return studentNameA.localeCompare(studentNameB) < 0 ? -1 : 1;
};

const sortStatusColumn = (rowA: Row<any>, rowB: Row<any>, columnId: string, desc: boolean) => {
  const suspendedA = rowA.original.suspended;
  const suspendedB = rowB.original.suspended;
  const inCartA = rowA.original.isInCart;
  const inCartB = rowB.original.isInCart;
  const hasOrderA = rowA.original.hasOrder;
  const hasOrderB = rowB.original.hasOrder;
  const statusA = rowA.original[columnId];
  const statusB = rowB.original[columnId];
  const studentNameA = `${rowA.original.lastName} ${rowA.original.firstName} `;
  const studentNameB = `${rowB.original.lastName} ${rowB.original.firstName}`;

  if (suspendedA && !suspendedB) {
    return 1;
  }

  if (!suspendedA && suspendedB) {
    return -1;
  }

  if (suspendedA && suspendedB) {
    return studentNameA.localeCompare(studentNameB) < 0 ? -1 : 1;
  }

  if (!hasOrderA && hasOrderB) {
    return -1;
  }

  if (hasOrderA && !hasOrderB) {
    return 1;
  }

  if (!inCartA && inCartB) {
    return -1;
  }

  if (inCartA && !inCartB) {
    return 1;
  }

  if ((hasOrderA && hasOrderB) || (statusA && statusB) || (inCartA && inCartB)) {
    return studentNameA.localeCompare(studentNameB) < 0 ? -1 : 1;
  }

  return studentNameA.localeCompare(studentNameB) < 0 ? -1 : 1;
};

const getDialogContent = (type: DialogTypes | null): { message: string; title: string; okButtonText: string } => {
  switch (type) {
    case DialogTypes.CANCEL_ORDER:
      return { title: 'Cancel Order', message: 'Cancel selected order?', okButtonText: 'Cancel Order' };
    case DialogTypes.EDIT_CART_ITEM:
      return { title: 'Edit Order', message: 'Edit selected order?', okButtonText: 'Confirm Changes' };
    default:
      return { title: '', message: '', okButtonText: 'Submit' };
  }
};

interface EditCartItemState {
  studentId: string;
  cartId: string;
  prevEntree: Interfaces.MenuItem | null;
  prevSides: Record<string, Interfaces.MenuItem[]>;
  entree: Interfaces.MenuItem | null;
  sides: Record<string, Interfaces.MenuItem[]>;
}

const TeacherRosterOrdering = (): ReactElement => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const { deleteOrderItems } = useCommonActions();

  const siteId = TokenClaims.getSiteId(user);
  const homeroomId = TokenClaims.getHomeroomId(user);
  const districtId = TokenClaims.getDistrictId(user);
  const userRole = TokenClaims.getRole(user);
  const isTeacher = isAuthorized(userRole, [Role.TEACHER]);
  const userId = TokenClaims.getUserId(user);
  const students = useSelector(CommonSelectors.getStudents);
  const products = useSelector(CommonSelectors.getProducts);
  const report = useSelector(TeacherRosterOrderingSelectors.getTeacherReport);
  const reportProducts = useSelector(TeacherRosterOrderingSelectors.getReportProducts);
  const tableRef = useRef<HTMLTableElement>(null);
  const defaultMenuType = useSelector(MenuTypePickerSelectors.getDefaultMenuType);
  const menuTypes = useSelector(MenuTypePickerSelectors.getMenuTypes);
  const menuTypeId = useSelector(MenuTypePickerSelectors.getMenuTypeId) || defaultMenuType.id;
  const currentDate = useSelector(DateRangeNavigatorSelectors.getDate);
  const currentDateYMD = currentDate.format(Constants.DATE_FORMAT_YMD);
  const { getTeacherReport, placeOrder } = TeacherRosterOrderingActions.useTeacherRosterOrderingActions();
  const componentLoading = useSelector(getComponentLoaderActive);
  const { selectedIds, handleAllCheckboxSelection, handleCheckboxSelection, clearSelectedIds } =
    useTableCheckboxSelection();
  const [dialogState, setDialogState] = useState<DialogState>({
    orderItems: [],
    show: false,
    loading: false,
    type: null,
  });
  const [showAddErrorDialog, setShowAddErrorDialog] = useState(false);
  const [addErrorMessage, setAddErrorMessage] = useState('');
  const isBulkMode = useSelector(TeacherRosterOrderingSelectors.getIsBulkMode);
  const [localBulkCart, setBulkCart] = useState<Interfaces.TeacherBulkOrderingCart>({});
  const [editCartItemState, setEditCartItemState] = useState<EditCartItemState | null>(null);
  const entreeSidebarOpen = useSelector((state: Interfaces.AppState) =>
    CommonSelectors.getSidebarOpen(state, SidebarLocations.ENTREE_SIDE_BAR),
  );
  const orderConfirmationSidebarOpen = useSelector((state: Interfaces.AppState) =>
    CommonSelectors.getSidebarOpen(state, SidebarLocations.ORDER_CONFIRMATION_SIDE_BAR),
  );
  const selectedEntree = useSelector(TeacherRosterOrderingSelectors.getSelectedEntree);
  const menuItemsByProductCategory = useSelector(TeacherRosterOrderingSelectors.getMenuItemsByProductCategory);
  const selectedSides = useSelector(TeacherRosterOrderingSelectors.getSelectedSideOptions);
  const canAddToCart = useMemo(() => {
    if (isBulkMode) {
      const itemsInDay = localBulkCart[currentDateYMD] || {};
      return Object.keys(itemsInDay).length > 0;
    }
    const isStudentSelected = Object.values(selectedIds).some((selected) => selected);

    return isStudentSelected && selectedEntree;
  }, [selectedIds, selectedEntree, isBulkMode, localBulkCart, currentDateYMD]);
  const showCart = useSelector((state: Interfaces.AppState) =>
    CommonSelectors.getSidebarOpen(state, SidebarLocations.CART_SIDE_BAR),
  );
  const pickupLocations = useSelector(CommonSelectors.getPickupLocations);
  const pickupLocationsByScope = useSelector((state) =>
    TeacherRosterOrderingSelectors.getTeacherPickupLocationOptions(state as Interfaces.AppState, homeroomId, siteId),
  );
  const teacherCart = useSelector(TeacherRosterOrderingSelectors.getCart(userId));
  const bulkCart = useSelector(TeacherRosterOrderingSelectors.getBulkCart(userId));
  const studentIdsByCartDate = useSelector(TeacherRosterOrderingSelectors.getStudentIdsInCart(userId));
  const [entreeToConfigure, setEntreeToConfigure] = React.useState<Interfaces.MenuItem | null>(null);
  const [placingOrder, setPlacingOrder] = React.useState<boolean>(false);
  const [orderConfirmationState, setOrderConfState] = React.useState<OrderConfirmationState>({
    orderItemsById: {},
    pickupLocationId: '',
    products: {},
  });
  const district = useSelector(CommonSelectors.getDistrict(districtId));
  const menuItemsGrouped = useSelector((state) =>
    ShopSelectors.getMenuItemsGrouped(state as Interfaces.AppState, {
      selector: 'product.productCategoryId',
      productCategoryId: Constants.PRODUCT_CATEGORIES.ENTREE.id,
      date: currentDate,
    }),
  );
  const menuItems = useSelector((state) =>
    CommonSelectors.getMenuItemsFromDate(state as Interfaces.AppState, currentDateYMD),
  );
  const isCutoffWarningVisible = useSelector(
    TeacherRosterOrderingSelectors.isCutoffWarningVisible(district?.orderingRules, isTeacher),
  );
  const reactTableRef = React.useRef<any>();
  const isOrderingDisabled =
    isViolatingOrderingRules(district, currentDateYMD, moment(), district?.timezone, isTeacher) || false;
  const isBlackedOut = React.useMemo(
    () => isDateBlackedOut(district?.orderingRules, currentDate) || false,
    [district, currentDate],
  );

  const selectedIdsLength = useMemo(() => Object.values(selectedIds).length, [selectedIds]);

  // Get menu items
  const { getShopData } = useShopData(undefined, districtId, menuTypeId, siteId, true);

  useEffect(
    function defaultToCurrentDay() {
      const now = moment();
      dispatch(setStartDate(now));
      dispatch(setDayOfYear(now));
    },
    [dispatch],
  );

  const hasStudentSelected = React.useMemo(() => {
    return Object.values(selectedIds).some((selected) => selected);
  }, [selectedIds]);
  const reportByMealTypeId = useMemo(() => report[menuTypeId] || [], [report, menuTypeId]);
  const foodGroupOptions = useMemo(() => {
    return Object.values(Constants.FOOD_GROUPS).reduce((acc, foodGroup) => {
      if (menuItemsByProductCategory[foodGroup.id]) {
        acc[foodGroup.id] = foodGroup;
      }
      return acc;
    }, {} as Record<string, Interfaces.ProductCategory>);
  }, [menuItemsByProductCategory]);

  useEffect(() => {
    batch(() => {
      dispatch(MenuTypePickerActions.setMenuTypeId(defaultMenuType.id));
    });
  }, [defaultMenuType.id, dispatch]);

  useEffect(
    function resetSelections() {
      dispatch(TeacherRosterOrderingActions.setSelectedSides({}));
      dispatch(TeacherRosterOrderingActions.setSelectedEntree(null));
      getShopData();
      clearSelectedIds();
    },
    [dispatch, currentDateYMD, menuTypeId, getShopData, clearSelectedIds],
  );

  const bulkOrderModeEnabled = district?.orderingRules?.bulkOrderModeEnabled !== false;

  useEffect(() => {
    /* If bulk order mode is disabled while a user is in bulk mode, then turn off
      bulk mode and remove bulk items from cart
    */
    if (!bulkOrderModeEnabled) {
      if (isBulkMode) {
        dispatch(TeacherRosterOrderingActions.setBulkMode(false));
      }

      if (!isEmpty(bulkCart)) {
        dispatch(TeacherRosterOrderingActions.clearBulkCart(userId));
        setBulkCart({});
      }
    }
  }, [dispatch, bulkOrderModeEnabled, isBulkMode, bulkCart, userId]);

  const getReport = useCallback(() => {
    async function fetchReport() {
      dispatch(setComponentLoaderActive(true));
      await getTeacherReport({
        siteId,
        homeroomId,
        dateFrom: currentDateYMD,
        dateTo: currentDateYMD,
        menuTypeId,
      });
      dispatch(setComponentLoaderActive(false));
    }

    if (menuTypeId) {
      fetchReport();
    }
  }, [dispatch, currentDateYMD, homeroomId, siteId, menuTypeId, getTeacherReport]);

  useEffect(() => {
    getReport();
  }, [getReport]);

  const removeCurrentBulkOrderDay = () => {
    const newCart = { ...localBulkCart };
    delete newCart[currentDateYMD];

    setBulkCart(newCart);
  };

  const onAddToCartClick = () => {
    if (isBulkMode) {
      const localCartItems = localBulkCart[currentDateYMD];
      const items = getItemsExceedingOrderLimit(localCartItems, bulkCart[currentDateYMD] || {});
      if (items.length > 0) {
        const message = `The maximum quantity is 99. The following items will exceed the limit: 
        ${items.map((item) => item.product.description).join(',')}`;
        setAddErrorMessage(message);
        setShowAddErrorDialog(true);
      } else {
        const dayBulkCart: Interfaces.TeacherBulkOrderingCart = { [currentDateYMD]: localCartItems };
        dispatch(
          TeacherRosterOrderingActions.addToBulkCart({ bulkOrderCart: dayBulkCart, accountId: userId, homeroomId }),
        );
        removeCurrentBulkOrderDay();
      }
    } else {
      dispatch(
        TeacherRosterOrderingActions.addToCart({
          selectedStudents: selectedIds,
          date: currentDateYMD,
          menuTypeId,
          accountId: userId,
          homeroomId,
        }),
      );
      clearSelectedIds();
      setEntreeToConfigure(null);

      if (reactTableRef.current) {
        reactTableRef.current.scrollTo(0);
      }
    }
  };

  const removeFromCart = React.useCallback(
    (studentId: string, cartId: string, menuTypeId: string, date: string) => {
      dispatch(
        TeacherRosterOrderingActions.removeCartItem({
          studentId,
          date,
          cartId,
          menuTypeId,
          accountId: userId,
        }),
      );
    },
    [dispatch, userId],
  );

  const columns = React.useMemo(
    () => [
      {
        accessor: 'studentId',
        id: 'checkbox',
        Header: (props: any) => {
          const rows = props.data;
          const filteredRows = rows.filter((row: any) => !row.hasOrder && !row.isInCart && !row.suspended);
          const allChecked = filteredRows.length > 0 && filteredRows.every((row: any) => selectedIds[row.studentId]);

          return (
            <div className="item">
              <Checkbox
                checked={allChecked}
                onChange={(e) => {
                  handleAllCheckboxSelection(!allChecked, props.rows, 'studentId');
                }}
                disabled={isOrderingDisabled || filteredRows.length === 0 || rows.length === 0 || isBlackedOut}
                color="primary"
                // @ts-ignore
                // For automation testing id.
                inputProps={{ 'data-test-id': 'selectAllCheckbox' }}
              />
            </div>
          );
        },
        Cell: (props: any) => {
          const studentId = props.row.original.studentId;
          const hasOrder = props.row.original.hasOrder;
          const isInCart = props.row.original.isInCart;
          const suspended = props.row.original.suspended;
          return (
            <div className="item">
              <Checkbox
                checked={selectedIds[studentId]}
                onClick={(e) => {
                  handleCheckboxSelection(studentId, e.shiftKey, props.sortedRows, 'studentId');
                }}
                disabled={hasOrder || isInCart || isOrderingDisabled || suspended || isBlackedOut}
                color="primary"
                // @ts-ignore
                // For automation testing id.
                inputProps={{ 'data-test-id': 'selectStudentCheckbox' }}
              />
            </div>
          );
        },
        width: 60,
        disableSortBy: true,
      },
      {
        Header: 'Student',
        accessor: 'studentColumn',
        width: 400,
        sortType: sortStudentColumn,
        dataTestId: (row: any) => `${row.original.firstName}.${row.original.lastName}.studentLbl`,
      },
      {
        Header: 'Allergens',
        accessor: 'allergensName',
        Cell: (props: any) => {
          return props.row.original.allergens;
        },
        width: 200,
        dataTestId: (row: any) => `${row.original.firstName}.${row.original.lastName}.allergensLbl`,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (props: any) => {
          return <div className="item">{props.value}</div>;
        },
        width: 100,
        sortType: sortStatusColumn,
        dataTestId: (row: any) => `${row.original.firstName}.${row.original.lastName}.statusLbl`,
      },
      {
        Header: 'Order',
        accessor: `productName${currentDateYMD}`,
        Cell: (props: any) => {
          return props.row.original[`${currentDateYMD}`];
        },
        width: 250,
        dataTestId: (row: any) => `${row.original.firstName}.${row.original.lastName}.orderLbl`,
      },
      {
        Header: '',
        id: 'actions',
        width: 96,
        disableSortBy: true,
        Cell: (props: any) => {
          return props.row.original.actions;
        },
        dataTestId: (row: any) => `${row.original.firstName}.${row.original.lastName}.actionsBtn`,
      },
    ],
    [
      currentDateYMD,
      selectedIds,
      isOrderingDisabled,
      isBlackedOut,
      handleAllCheckboxSelection,
      handleCheckboxSelection,
    ],
  );

  const data = React.useMemo(() => {
    return reportByMealTypeId.reduce((acc: any, orderEntry: any) => {
      const row = {} as { [key: string]: any };
      const student = students[orderEntry.studentId] as Interfaces.Student;

      if (!student) {
        return acc;
      }
      const studentName = `${student.firstName} ${student.lastName}`;
      row['firstName'] = student.firstName;
      row['lastName'] = student.lastName;
      const studentId = student.id;
      row['studentId'] = studentId;
      row['studentColumn'] = (
        <div className="item" student-id={studentId}>
          {student.profilePicture && <Avatar className="student-img" src={student.profilePicture} alt={studentName} />}
          {!student.profilePicture && <Avatar className="student-img" src="" alt={studentName} />}
          <span className="student-name">{studentName}</span>| {student.sisId}
        </div>
      );

      row['allergensName'] = student.allergens;
      row['allergens'] = <div className="item">{student.allergens}</div>;
      const cartId = get(studentIdsByCartDate, [currentDateYMD, menuTypeId, student.id]);
      const order = orderEntry.orders[currentDateYMD];
      let productId = order?.productId;
      let sideItemIds: string[] = Object.entries(order?.selectedFoodGroups || {}).reduce(
        (ids: any, [category, pIds]: any) => {
          if (category === Constants.PRODUCT_CATEGORIES.ENTREE.id) {
            return ids;
          }
          return ids.concat(pIds);
        },
        [],
      );

      if (cartId) {
        const cartProductIds = cartId?.split('.');
        const [entreeIdPartition, sideIds] = partition(cartProductIds, (cpId) => {
          const cartProduct = products[cpId] || reportProducts[cpId];
          return cartProduct?.productCategoryId === Constants.PRODUCT_CATEGORIES.ENTREE.id;
        });
        sideItemIds = sideIds;
        productId = entreeIdPartition[0] || '';
      }

      const product = products[productId] || reportProducts[productId];
      const sideItemNames = sideItemIds
        .map((sideItemId: string) => {
          const product = products[sideItemId] || reportProducts[sideItemId];

          return product?.name || '';
        })
        .join(', ');

      if (order) {
        row.status = 'Ordered';
      } else if (cartId) {
        row.status = 'In Cart';
      } else {
        row.status = '';
      }

      row.hasOrder = !!order;
      row.isInCart = !!cartId;
      row.suspended = student.suspended;

      row[`productName${currentDateYMD}`] = product?.name ?? '';
      row[`${currentDateYMD}`] = (
        <div className="order-items">
          <div>{product?.name}</div>
          <div className="side-items">{sideItemNames}</div>
        </div>
      );

      row['actions'] = (
        <div className="actions">
          {student.suspended && (
            <Tooltip
              title={
                <span style={{ fontSize: 16 }}>Unable to place a student order, contact Nutrition Services Dept.</span>
              }
              aria-label="Student Ordering Status"
            >
              <InfoIcon className="ordering-status" />
            </Tooltip>
          )}
          {!student.suspended && cartId && (
            <IconButton
              aria-label="Edit cart item"
              onClick={() => {
                const entree = teacherCart?.[currentDateYMD]?.[menuTypeId]?.[cartId].entree;
                const sides = teacherCart?.[currentDateYMD]?.[menuTypeId]?.[cartId].sides;

                if (entree) {
                  setEditCartItemState({
                    studentId,
                    cartId,
                    prevEntree: entreeToConfigure,
                    prevSides: selectedSides,
                    entree,
                    sides,
                  });
                  dispatch(setSidebarOpen(true, SidebarLocations.ENTREE_SIDE_BAR));
                }
              }}
              disabled={isOrderingDisabled || isBlackedOut}
              data-test-id={`${studentName}.editCartItemBtn`}
            >
              <EditIcon />
            </IconButton>
          )}
          {!student.suspended && (order || cartId) && (
            <IconButton
              aria-label="Remove order item"
              onClick={() => {
                if (order) {
                  setDialogState({
                    show: true,
                    orderItems: order.orderItems,
                    loading: false,
                    type: DialogTypes.CANCEL_ORDER,
                  });
                } else if (cartId) {
                  removeFromCart(studentId, cartId, menuTypeId, currentDateYMD);
                }
              }}
              disabled={isOrderingDisabled || isBlackedOut}
              data-test-id={`${studentName}.removeOrderBtn`}
            >
              <TrashIcon />
            </IconButton>
          )}
        </div>
      );

      acc.push(row);
      return acc;
    }, [] as any);
  }, [
    reportByMealTypeId,
    students,
    products,
    currentDateYMD,
    studentIdsByCartDate,
    removeFromCart,
    menuTypeId,
    isOrderingDisabled,
    entreeToConfigure,
    selectedSides,
    teacherCart,
    reportProducts,
    isBlackedOut,
    dispatch,
  ]);
  const totalOrders = React.useMemo(() => data.filter((row: any) => row.hasOrder || row.isInCart).length, [data]);

  // Fix issue with button focus and scrolling to the correct viewport.
  useEffect(() => {
    if (tableRef.current) {
      const [cleanUpListeners, startWatcher] = watchForFocusStickyIssue(tableRef.current);
      startWatcher();

      if (cleanUpListeners) {
        return cleanUpListeners;
      }
    }
  }, [tableRef, data]);

  const onEntreeClick = React.useCallback(
    (entree: Interfaces.MenuItem) => {
      dispatch(setSidebarOpen(true, SidebarLocations.ENTREE_SIDE_BAR));
      setEntreeToConfigure(entree);
    },
    [dispatch],
  );

  const restorePreviousSelections = (
    entree: Interfaces.MenuItem | null,
    sides: Record<string, Interfaces.MenuItem[]>,
  ) => {
    dispatch(TeacherRosterOrderingActions.setSelectedEntree(entree));
    dispatch(TeacherRosterOrderingActions.setSelectedSides(sides));
    setEntreeToConfigure(entree);
  };

  const onSidebarClose = () => {
    if (editCartItemState) {
      setEditCartItemState((state) => {
        if (state) {
          const { prevEntree, prevSides } = state;
          restorePreviousSelections(prevEntree, prevSides);
        }
        return null;
      });
    } else if (!selectedEntree) {
      setEntreeToConfigure(null);
    }

    dispatch(setSidebarOpen(false, SidebarLocations.ENTREE_SIDE_BAR));
  };

  const onClearClick = () => {
    setEntreeToConfigure(null);
    dispatch(TeacherRosterOrderingActions.setSelectedSides({}));
    dispatch(TeacherRosterOrderingActions.setSelectedEntree(null));
  };

  const onEntreeSelected = (entree: Interfaces.MenuItem, sides: Record<string, Interfaces.MenuItem[]>) => {
    if (editCartItemState) {
      setDialogState((state) => ({ ...state, show: true, type: DialogTypes.EDIT_CART_ITEM, entree, sides }));
    } else {
      batch(() => {
        dispatch(TeacherRosterOrderingActions.setSelectedEntree(entree));
        dispatch(TeacherRosterOrderingActions.setSelectedSides(sides));
        dispatch(setSidebarOpen(false, SidebarLocations.ENTREE_SIDE_BAR));
      });
    }
  };

  const removeOldCartItems = () => {
    const cartDates = uniq([...Object.keys(teacherCart), ...Object.keys(bulkCart)]);
    cartDates.forEach((date) => {
      const shouldRemove = isViolatingOrderingRules(district, date, moment(), district?.timezone, isTeacher);

      if (shouldRemove) {
        dispatch(TeacherRosterOrderingActions.removeCartDate({ date, accountId: userId }));
      }
    });
  };

  const addOrRemoveBulkOrderItem = (menuItem: Interfaces.MenuItem, quantity: number) => {
    const newBulkCart = cloneDeep(localBulkCart);
    const addingToCart = quantity > 0;
    const removingFromCart = newBulkCart?.[currentDateYMD]?.[menuTypeId]?.[menuItem.productId] && quantity === 0;

    if (addingToCart) {
      if (!newBulkCart[currentDateYMD]) {
        set(newBulkCart, [currentDateYMD, menuTypeId], {});
      }

      if (!newBulkCart[currentDateYMD][menuTypeId]) {
        set(newBulkCart, [currentDateYMD, menuTypeId], {});
      }

      newBulkCart[currentDateYMD][menuTypeId][menuItem.productId] = { menuItem, quantity };
    }

    if (removingFromCart) {
      delete newBulkCart[currentDateYMD][menuTypeId][menuItem.productId];

      if (Object.keys(newBulkCart[currentDateYMD][menuTypeId]).length === 0) {
        delete newBulkCart[currentDateYMD][menuTypeId];
      }

      if (Object.keys(newBulkCart[currentDateYMD]).length === 0) {
        delete newBulkCart[currentDateYMD];
      }
    }

    setBulkCart(newBulkCart);
  };

  const onAddOrRemoveBulkOrderQuantity = (date: string, productId: string, quantity: number, mtId: string) => {
    dispatch(
      TeacherRosterOrderingActions.addOrSubtractBulkItemQuantity({
        date,
        productId,
        quantity,
        menuTypeId: mtId,
        accountId: userId,
      }),
    );
  };

  const removeBulkItem = (date: string, productId: string, mtId: string) => {
    dispatch(
      TeacherRosterOrderingActions.removeBulkItem({
        date,
        productId,
        menuTypeId: mtId,
        accountId: userId,
      }),
    );
  };

  const submitOrder = async (pickupLocationId: string) => {
    try {
      setPlacingOrder(true);
      const newOrderItems = createOrderItemsFromTeacherCart({
        cart: teacherCart,
        menuTypes,
        pickupLocationId,
        students,
        homeroomId,
        district,
      });
      const bulkOrderItems = convertCartToBulkOrderItems({
        bulkCart,
        menuTypes,
        pickupLocationId,
        homeroomId,
        district,
        siteId,
        districtId,
      });

      if (bulkOrderItems.length > 0) {
        newOrderItems['BULK'] = bulkOrderItems;
      }

      removeOldCartItems();
      if (Object.keys(newOrderItems).length > 0) {
        const teacherCartProducts = getTeacherCartProducts(teacherCart, bulkCart);
        const orderItemsById = (await placeOrder(newOrderItems)).data as { [key: string]: any };

        // Remove order items successfully posted
        Object.entries(orderItemsById).forEach(
          ([itemId, orderItems]: [string, Interfaces.OrderItem[] | { [key: string]: string }]) => {
            if (!Array.isArray(orderItems)) {
              return;
            }
            const itemsByDate = groupBy(orderItems, (orderItem) => orderItem.date);

            Object.entries(itemsByDate).forEach(([date, items]) => {
              const itemsByMenuType = groupBy(items, 'mealTypeId');
              Object.entries(itemsByMenuType).forEach(([menuTypeId, menuTypeItems]) => {
                const cartId = generateCartId(menuTypeItems);

                if (itemId === Constants.BULK_ORDER_ITEM_ID) {
                  items.forEach((item) => {
                    removeBulkItem(date, item.productId, menuTypeId);
                  });
                } else {
                  dispatch(
                    TeacherRosterOrderingActions.removeCartItem({
                      studentId: itemId,
                      cartId,
                      date,
                      menuTypeId,
                      accountId: userId,
                    }),
                  );
                }
              });
            });
          },
        );

        dispatch(setSidebarOpen(false, SidebarLocations.CART_SIDE_BAR));
        getReport();
        setOrderConfState({ orderItemsById, pickupLocationId, products: teacherCartProducts });
        dispatch(setSidebarOpen(true, SidebarLocations.ORDER_CONFIRMATION_SIDE_BAR));
      }
    } catch (err) {
    } finally {
      setPlacingOrder(false);
    }
  };

  const onDialogConfirm = async () => {
    const { orderItems, type, entree, sides } = dialogState;

    if (type === DialogTypes.CANCEL_ORDER && orderItems) {
      const orderItemsToCancel: Partial<Interfaces.OrderItem>[] = orderItems.map((oi) => ({
        districtId: oi.districtId,
        id: oi.id,
        canceled: true,
      }));

      setDialogState((state) => ({ ...state, loading: true }));

      await deleteOrderItems(orderItemsToCancel);
      setDialogState((state) => ({ ...state, loading: false, orderItems: [], show: false, type: null }));
    } else if (type === DialogTypes.EDIT_CART_ITEM) {
      setEditCartItemState((state) => {
        if (state) {
          const { studentId, cartId, prevEntree, prevSides } = state;

          batch(() => {
            dispatch(
              TeacherRosterOrderingActions.removeCartItem({
                studentId,
                cartId,
                date: currentDateYMD,
                menuTypeId,
                accountId: userId,
              }),
            );
            dispatch(TeacherRosterOrderingActions.setSelectedEntree(entree));
            dispatch(TeacherRosterOrderingActions.setSelectedSides(sides));
            dispatch(
              TeacherRosterOrderingActions.addToCart({
                selectedStudents: { [studentId]: true },
                date: currentDateYMD,
                menuTypeId,
                accountId: userId,
              }),
            );
            dispatch(setSidebarOpen(false, SidebarLocations.ENTREE_SIDE_BAR));
          });

          restorePreviousSelections(prevEntree, prevSides);

          return null;
        }
        return state;
      });
      setDialogState((state) => ({ ...state, entree: undefined, sides: undefined, show: false, type: null }));
    }
  };

  const onDialogCancel = () => {
    setDialogState((state) => ({ ...state, loading: false, show: false, orderItems: [], type: null }));
  };

  const dialogProps = getDialogContent(dialogState.type);

  const footer = (
    <div className="table-footer">
      <div>
        <ButtonLink
          className="print"
          rel="noopener noreferrer"
          target="_blank"
          href={`${Routes[RouteId.TEACHER_ROSTER_ORDERING_PRINT]?.path}?${new URLSearchParams({
            dateFrom: currentDateYMD,
            dateTo: currentDateYMD,
          })}`}
          text="Print Orders"
          data-test-id="printOrdersBtn"
        />
        {!isBulkMode && (
          <Button
            type="button"
            color="primary"
            aria-label="Reset table sort order"
            onClick={() => {
              reactTableRef?.current?.resetSort(initialState.sortBy);
            }}
            data-test-id="resetSortBtn"
          >
            Reset Sort
          </Button>
        )}
      </div>
      <div className="footer-text" data-test-id="studentsSelectedLbl">
        Students Selected: {selectedIdsLength}
      </div>
      <div className="footer-text" data-test-id="totalOrdersLbl">
        Total Orders: {totalOrders} / {data.length}
      </div>
    </div>
  );

  return (
    <>
      <div ref={tableRef} className="teacher-roster-ordering-container">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-sm-12">
              <SubHeader
                rightComponent={
                  isCutoffWarningVisible && (
                    <CutOffWarningMessage isTeacher={isTeacher} orderingRules={district?.orderingRules} />
                  )
                }
              />
            </div>
            <div className="col-sm-12 col-lg-4 col-xl-3">
              <MenuTypePicker />
            </div>
            <div className="teacher-roster-ordering__bulk-switch col-12 col-sm-6 col-md-6 col-lg-4 col-xl-6 mb-4">
              {bulkOrderModeEnabled && (
                <div className="row__bulk-switch" data-test-id="bulkModeBtn">
                  Bulk Mode
                  <Switch
                    color="primary"
                    checked={isBulkMode}
                    onChange={() => dispatch(TeacherRosterOrderingActions.setBulkMode(!isBulkMode))}
                  />
                </div>
              )}
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
              <DateRangeNavigator mode={Constants.DateRangeMode.DAY} skipWeekends={true} />
            </div>
          </div>
        </div>
        {componentLoading && (
          <div className="container-fluid">
            <ComponentLoader />
          </div>
        )}
        {!componentLoading && !isBulkMode && (
          <div className="container-fluid teacher-roster-ordering__ordering-container px-0 flex-grow-1">
            <div className="row flex-grow-1 teacher-roster-ordering__row m-0">
              <div className="col-sm-12 col-lg-9 px-0 teacher-roster-ordering__table-col">
                <ScrollingReactTable
                  initialState={initialState}
                  columns={columns}
                  data={data}
                  autoResetPage={false}
                  autoResetSortBy={false}
                  footer={footer}
                  noDataText="Menus have not been published for this week. Please check back regularly for menu updates."
                  className="teacher-roster-ordering__ordering-table"
                />
              </div>
              <div className="col-sm-12 col-lg-3 px-0">
                <EntreeList
                  onEntreeClick={onEntreeClick}
                  selectedEntree={selectedEntree}
                  onClearClick={onClearClick}
                  menuItemsGrouped={menuItemsGrouped}
                  disabled={isOrderingDisabled || isBlackedOut}
                  selectedSides={selectedSides}
                />
              </div>
            </div>
          </div>
        )}
        {!componentLoading && isBulkMode && (
          <div className="container-fluid flex-grow-1 px-0 d-flex">
            <BulkOrderTable
              footer={footer}
              menuItems={menuItems}
              menuType={defaultMenuType}
              bulkOrderItems={localBulkCart?.[currentDateYMD]?.[menuTypeId] || {}}
              addOrRemoveBulkOrderItem={addOrRemoveBulkOrderItem}
              isOrderingDisabled={isOrderingDisabled}
            />
          </div>
        )}
        <EntreeSidebar
          entree={editCartItemState?.entree || entreeToConfigure}
          open={entreeSidebarOpen}
          onClose={onSidebarClose}
          foodGroupOptions={foodGroupOptions}
          menuItemsByProductCategory={menuItemsByProductCategory}
          menuType={defaultMenuType}
          products={products}
          selectedSides={editCartItemState?.sides || selectedSides}
          onSelectionConfirmed={onEntreeSelected}
          cancelBtnText={editCartItemState ? 'Close' : 'Cancel'}
          okBtnText={editCartItemState ? 'Submit' : 'Select'}
          product={editCartItemState?.entree?.product || entreeToConfigure?.product}
        />
        <CartSidebar
          open={showCart}
          onClose={() => {
            dispatch(setSidebarOpen(false, SidebarLocations.CART_SIDE_BAR));
          }}
          students={students}
          pickupLocationsByScope={pickupLocationsByScope}
          cart={teacherCart}
          bulkCart={bulkCart}
          menuTypes={menuTypes}
          deleteStudentOrder={removeFromCart}
          onPlaceOrderClick={submitOrder}
          loading={placingOrder}
          district={district}
          isTeacher={isTeacher}
          onBulkItemQuantityChange={onAddOrRemoveBulkOrderQuantity}
          deleteBulkItem={removeBulkItem}
        />
        <OrderConfirmationSidebar
          {...orderConfirmationState}
          open={orderConfirmationSidebarOpen}
          pickupLocations={pickupLocations}
          onClose={() => dispatch(setSidebarOpen(false, SidebarLocations.ORDER_CONFIRMATION_SIDE_BAR))}
          students={students}
        />
        <NotificationDialog
          showDialog={dialogState.show}
          onOkClick={onDialogConfirm}
          onCancelClick={onDialogCancel}
          cancelButtonText="Cancel"
          showCancel={true}
          loading={dialogState.loading}
          {...dialogProps}
        />
        <NotificationDialog
          showDialog={showAddErrorDialog}
          onOkClick={() => setShowAddErrorDialog(false)}
          onCancelClick={() => setShowAddErrorDialog(false)}
          okButtonText="Ok"
          title="Unable to add items to cart"
          message={addErrorMessage}
        />
      </div>
      <PageFooter
        leftContent={[
          <Button
            variant="outlined"
            color="primary"
            key="clear"
            aria-label="Remove all cart order items"
            disabled={
              (isBulkMode && Object.keys(localBulkCart).length === 0) ||
              (!isBulkMode && !hasStudentSelected && !selectedEntree)
            }
            onClick={() => {
              if (isBulkMode) {
                removeCurrentBulkOrderDay();
              } else {
                clearSelectedIds();
                dispatch(TeacherRosterOrderingActions.setSelectedEntree(null));
                dispatch(TeacherRosterOrderingActions.setSelectedSides({}));
              }
            }}
            data-test-id="studentClearBtn"
          >
            Clear
          </Button>,
        ]}
        rightContent={[
          <Button
            variant="contained"
            key="submit"
            color="primary"
            aria-label="Submit cart order items"
            disabled={!canAddToCart}
            onClick={onAddToCartClick}
            data-test-id="addToCartBtn"
          >
            Add to Cart
          </Button>,
        ]}
      />
    </>
  );
};

const initialState = {
  sortBy: [{ id: 'status', desc: false }],
  autoResetSortBy: false,
};

export default TeacherRosterOrdering;
