import React, { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { useAuth0 } from '@auth0/auth0-react';
import WarningIcon from '@material-ui/icons/Warning';

import MenuItem from 'Components/Shop/MenuItem/MenuItem';
import { isViolatingOrderingRules } from 'Components/Shop/ShopUtil';

import * as CommonSelectors from 'State/selectors';
import * as CartSelectors from 'Components/Shop/Cart/state/selectors';
import * as ShopSelectors from 'Pages/Shop/state/selectors';

import { Role, isAuthorized, TokenClaims } from 'Auth/User';
import * as Interfaces from 'Interfaces/Interfaces';
import * as Constants from 'Constants/Constants';

import './CalendarDay.less';
import { isDateBlackedOut } from 'Helpers/Helper';

const CalendarDay = (props: Interfaces.CalendarDayProps): ReactElement => {
  const { productCategoryId, date } = props;
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const isParent = isAuthorized(userRole, [Role.PARENT]);
  const cartItemIds = useSelector(CartSelectors.getMenuItemIds);
  const menuItemsGrouped = useSelector((state) =>
    ShopSelectors.getMenuItemsGrouped(state as Interfaces.AppState, props),
  );
  const hasALaCarteViolation = useSelector(ShopSelectors.hasALaCarteViolation(userRole));
  const hasReimbursableViolation = useSelector(ShopSelectors.hasReimbursableViolation(userRole));
  const student = useSelector(ShopSelectors.getStudent);
  const timezone = useSelector(ShopSelectors.getDistrictTimezone);
  const districts = useSelector(CommonSelectors.getDistricts);
  const district = districts[student?.districtId];
  const orderItems = useSelector(CommonSelectors.getOrderItemsByMenuKey);
  const menuItems = useMemo(() => menuItemsGrouped[productCategoryId] || [], [menuItemsGrouped, productCategoryId]);
  const targetDate = date.format(Constants.DATE_FORMAT_YMD);
  const isAlaCarte = productCategoryId === Constants.ALACARTE_KEY;
  const offerOnlyAlaCarte = useSelector(ShopSelectors.getOfferOnlyALaCarte);
  const hasItems = menuItems.length > 0;
  const sites = useSelector(CommonSelectors.getSites) ?? {};
  const selectedSite = useSelector(ShopSelectors.getSiteId) ?? '';

  const selectedSiteGroupTypeId = sites[selectedSite]?.siteGroupTypeId;

  if (isAlaCarte) {
    menuItems?.sort((a, b) => a?.product?.name?.localeCompare(b?.product?.name));
  }

  const dayInOrders = useMemo(
    () =>
      student &&
      menuItems?.some(
        (menuItem) =>
          menuItem.product?.productCategoryId !== Constants.PRODUCT_CATEGORIES.ALACARTE.id &&
          // Only check that there is an ordered item on the date and menutype. Orders for day may include items that are no longer on the menu.
          Object.keys(orderItems).some(
            (key) =>
              key.includes(`${student.id}.${targetDate}.${menuItem.mealTypeId}`) &&
              orderItems[key].productCategoryId !== Constants.PRODUCT_CATEGORIES.ALACARTE.id,
          ),
      ),
    [menuItems, orderItems, student, targetDate],
  );

  const dayInCart = useMemo(
    () =>
      menuItems?.some(
        (menuItem) =>
          menuItem.product?.productCategoryId !== Constants.PRODUCT_CATEGORIES.ALACARTE.id && cartItemIds[menuItem.id],
      ),
    [cartItemIds, menuItems],
  );

  const isInPast = useMemo(() => date?.isBefore(moment(), 'day'), [date]);

  const isBlackout = useMemo(() => isDateBlackedOut(district?.orderingRules, date), [date, district]);

  // finds minimum timestamp based on rules, then compares against current timestamp
  const orderingRuleViolation = useMemo(() => {
    return isParent && isViolatingOrderingRules(district, date, moment(), timezone);
  }, [date, district, isParent, timezone]);

  const isDisabled = useMemo(
    () =>
      dayInCart ||
      (isParent && hasALaCarteViolation && productCategoryId === Constants.PRODUCT_CATEGORIES.ALACARTE.id) ||
      (isParent && hasReimbursableViolation && productCategoryId === Constants.PRODUCT_CATEGORIES.ENTREE.id) ||
      orderingRuleViolation,
    [dayInCart, hasALaCarteViolation, hasReimbursableViolation, isParent, orderingRuleViolation, productCategoryId],
  );

  const isUnavailable = useMemo(
    () => (orderingRuleViolation || isBlackout || isInPast) && !dayInOrders,
    [orderingRuleViolation, isBlackout, isInPast, dayInOrders],
  );

  return (
    <React.Fragment>
      <div
        className={classNames({ 'calendar-day-container': true, mobile: isMobile })}
        data-test-id={`${date.format(Constants.DATE_FORMAT_USA)}.dayDateLbl`}
      >
        {menuItems &&
          Object.values(menuItems)
            .sort((a, b) => a.sortPriority - b.sortPriority)
            .map((menuItem) => {
              const orderItem = orderItems[`${student?.id}.${targetDate}.${menuItem.mealTypeId}.${menuItem.productId}`];
              const disableNonOrderedItems = dayInOrders && orderItem?.productId !== menuItem?.productId;
              return (
                <MenuItem
                  disabled={isDisabled || disableNonOrderedItems}
                  unavailable={isUnavailable}
                  id={menuItem.id}
                  key={menuItem.id}
                  siteGroupTypeId={selectedSiteGroupTypeId ?? student?.siteGroupTypeId}
                  alreadyOrdered={dayInOrders}
                />
              );
            })}
      </div>
      <div
        className={classNames({
          'menu-items-overlay': isDisabled || isUnavailable || dayInOrders || !hasItems,
          clickable: !isDisabled && dayInOrders,
          unavailable: isUnavailable || !hasItems,
          'top-row': !isAlaCarte,
        })}
      ></div>
      {((isUnavailable && !isAlaCarte) || (isUnavailable && offerOnlyAlaCarte && isAlaCarte)) && (
        <div className="ordering-unavailable" data-test-id="orderingUnavailableLbl">
          <WarningIcon className="ordering-unavailable__warning-icon" />
          <div>Ordering not available</div>
        </div>
      )}
      {((!hasItems && !isAlaCarte) || (isAlaCarte && !isUnavailable && offerOnlyAlaCarte && !hasItems)) && (
        <div className="ordering-unavailable">
          <WarningIcon className="ordering-unavailable__warning-icon" />
          <div>No items available</div>
        </div>
      )}
    </React.Fragment>
  );
};

export default React.memo(CalendarDay);
