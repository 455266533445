import React, { FC, useState } from 'react';
import { Switch } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

export interface ClassroomToggleProps {
  isDisabled: boolean;
  toggleDisabled: () => void;
}

export const ClassroomToggle: FC<ClassroomToggleProps> = ({ isDisabled, toggleDisabled }) => {
  const [classroomPickupLoading, setClassroomPickupLoading] = useState(false);

  const handleToggleClassroom = () => {
    setClassroomPickupLoading(true);
    toggleDisabled();
    setClassroomPickupLoading(false);
  };

  return (
    <div className={`pickup-location`}>
      <div className="name">
        Classroom pickup
        <Tooltip title="Parents / students will see their homeroom as the classroom pickup location option">
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div>
        <Switch
          checked={!isDisabled}
          onChange={handleToggleClassroom}
          color="primary"
          className="classRoomToggle"
          inputProps={{ 'aria-label': 'Classroom toggle' }}
          disabled={classroomPickupLoading}
        />
      </div>
    </div>
  );
};
