import { useEffect } from 'react';

export const useAutoPrintPage = (dependencyWithLength: any[] | any, otherDependencies: any[] = []) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        (Array.isArray(dependencyWithLength) && dependencyWithLength.length) ||
        Object.keys(dependencyWithLength).length
      ) {
        window.print();
      }
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [dependencyWithLength, ...otherDependencies]);
};
