import React, { ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { showReimbursableWarning } from 'Pages/Shop/state/actions';
import { getStudentDistrict } from 'Pages/Shop/state/selectors';

import CalendarWeekWarning from 'Components/Shop/CalendarWeekWarning/CalendarWeekWarning';

const ReimbursableWarning: React.FC<{}> = (): ReactElement | null => {
  const dispatch = useDispatch();
  const district = useSelector(getStudentDistrict);

  function onCloseWarningClick() {
    dispatch(showReimbursableWarning(false));
  }

  return (
    <CalendarWeekWarning
      onCloseClick={onCloseWarningClick}
      orderTypeText="reimbursable meals"
      link={district?.paymentProviderLink}
      amount={district?.orderingRules.reimbursableMinimum}
    />
  );
};

export default ReimbursableWarning;
