import { IconButton, Tooltip } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { NotificationDialog } from 'Components/Dialogs/NotificationDialog';
import { UploadFile, UploadFileProps } from 'Components/UploadFile/UploadFile';
import { FieldProps } from 'formik';
import React, { FC, useState } from 'react';
import { DropzoneOptions } from 'react-dropzone';
import './InputUpload.less';

export interface InputUploadProps extends FieldProps, Omit<UploadFileProps, 'setFieldValue' | 'onError' | 'ariaLabel'> {
  dropZoneOptions?: DropzoneOptions;
  onError?: (error: any) => void;
  placeholder?: string;
  label?: string;
}

export const InputUpload: FC<InputUploadProps> = ({
  dropZoneOptions,
  field,
  form,
  placeholder,
  setLoading,
  onError,
  onSuccess = () => {},
  preUploadProcessor,
  label,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const deleteUploadItem = async (data: any) => {
    setIsLoading(true);
    form.setFieldValue(field.name, undefined);
    setShowConfirmation(false);
    setIsLoading(false);
  };
  const displayValue = !field.value ? undefined : field.value.split('/').pop();
  return (
    <div className="input-upload">
      {field.value && (
        <div className="input-value">
          {!displayValue && !!placeholder && <span className="input-placeholder">{placeholder}</span>}
          {displayValue && (
            <div className="input-display">
              <Tooltip arrow placement="top" title={<span className="tooltip">Download {placeholder}</span>}>
                <a href={field.value} target="_blank" rel="noopener noreferrer">
                  {placeholder}
                </a>
              </Tooltip>
              <IconButton
                aria-label={`Click to remove the file named ${displayValue}`}
                type="button"
                classes={{ root: 'input-upload__close-btn' }}
                onClick={() => {
                  setShowConfirmation(true);
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            </div>
          )}
        </div>
      )}
      {!field.value && (
        <UploadFile
          options={dropZoneOptions}
          setLoading={setLoading}
          onSuccess={onSuccess}
          setFieldValue={(url: string) => {
            form.setFieldValue(field.name, url);
          }}
          onError={(e) => {
            console.error(e);
            if (onError) {
              onError(e);
            }
          }}
          preUploadProcessor={preUploadProcessor}
          label={label}
        />
      )}

      <NotificationDialog
        title={`Delete ${placeholder} Upload ${displayValue}`}
        message={`Are you sure you want to delete the ${placeholder} upload ${displayValue}? After confirming you must also click SAVE.`}
        showDialog={showConfirmation}
        showCancel={true}
        cancelButtonText="CANCEL"
        okButtonText="DELETE"
        onOkClick={deleteUploadItem}
        onCancelClick={() => {
          setShowConfirmation(false);
        }}
        dialogType="delete"
        loading={isLoading}
      />
    </div>
  );
};
