import React, { ReactElement, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SidebarLocations } from 'Constants/Enums';
import { useCommonActions } from 'State/actions';
import Button from 'Components/Button/Button';
import * as CommonActions from 'State/actions';
import Modal from 'Components/Modal/Modal';
import * as CancelOrderModalActions from './state/actions';
import * as CancelOrderModalSelectors from './state/selectors';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';

interface CancelOrderModalProps {
  getTeacherReport?: () => void;
  getShopData?: () => void;
}

const CancelOrderModal = (props: CancelOrderModalProps): ReactElement => {
  const { getTeacherReport, getShopData } = props;
  const dispatch = useDispatch();
  const { deleteOrderItems } = useCommonActions();
  const [cancelling, setCancelling] = useState<boolean>(false);

  const isOpen = useSelector(CancelOrderModalSelectors.getOpen);
  const orderItems = useSelector(CancelOrderModalSelectors.getOrderItems);

  const closeModal = () => {
    dispatch(CancelOrderModalActions.setOpen(false, []));
  };

  const cancelOrder = useCallback(async () => {
    setCancelling(true);
    dispatch(CancelOrderModalActions.setOpen(false, []));
    await deleteOrderItems(orderItems);
    setCancelling(false);
    dispatch(CommonActions.setSidebarOpen(false, SidebarLocations.MENU_ITEM_SIDE_BAR));

    if (getTeacherReport) {
      getTeacherReport();
    }

    if (getShopData) {
      getShopData();
    }
  }, [dispatch, deleteOrderItems, orderItems, getTeacherReport, getShopData]);

  return (
    <Modal className="linq-dialog" open={isOpen} maxWidth="md" onClose={closeModal}>
      <Modal.Header className="linq-dialog__header d-flex align-items-center" disableTypography={true}>
        <h3 className="linq-heading">Cancel Order</h3>
        <CloseIconButton onClick={closeModal} />
      </Modal.Header>
      <Modal.Content className="linq-dialog__content_scroll">Are you sure you want to cancel this order?</Modal.Content>
      <Modal.Actions className="linq-dialog__actions">
        <Button color="primary" variant="outlined" onClick={closeModal} aria-label="Click to abort.">
          Close
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={cancelOrder}
          aria-label="Click to proceed."
          loading={cancelling}
          disabled={cancelling}
        >
          Cancel Order
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CancelOrderModal;
