import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { globalConfig } from '../../config';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/embedly.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/third_party/embedly.min.css';

import Froala from 'react-froala-wysiwyg';

import './FroalaEditor.less';

export interface FroalaEditorProps {
  onContentChange: (content: string) => void;
  value?: string;
  errorMsg?: string;
  touched?: boolean;
  showOnlyTouchedError?: boolean;
}

const FroalaEditor: React.FC<FroalaEditorProps> = ({
  onContentChange,
  value,
  errorMsg,
  touched,
  showOnlyTouchedError,
}) => {
  const ref = useRef<any>({ editor: null });
  const [isFroalaInitialized, setIsFroalaInitialized] = useState(false);
  const [editor, setEditor] = useState<any>(undefined);
  const isErrorHidden = (errorMsg && showOnlyTouchedError && !touched) || !errorMsg;

  const handleModelChange = (model: string) => {
    onContentChange(model);
  };

  // Editor initialization
  useEffect(() => {
    if (ref.current.editor?.data) {
      ref.current.editor.data._init = null;
    }

    if (editor) {
      setEditor(ref.current.editor);
      editor && setIsFroalaInitialized(true);
    }
  }, [editor]);

  // Do after initialization
  useEffect(() => {
    if (editor && value && isFroalaInitialized) {
      editor.html.set(value);
    }
  }, [isFroalaInitialized, editor, value]);

  return (
    <div className={classNames('froala-container', !isErrorHidden && 'froala-container__error')}>
      <Froala
        ref={ref}
        model={value}
        onModelChange={handleModelChange}
        tag="textarea"
        config={{
          attribution: false,
          key: globalConfig.getPortalConfig().frKey,
          placeholderText: 'Description',
          toolbarButtons: {
            moreText: {
              buttons: [
                'bold',
                'italic',
                'underline',
                'strikeThrough',
                'subscript',
                'superscript',
                'fontFamily',
                'fontSize',
                'textColor',
                'backgroundColor',
                'inlineClass',
                'inlineStyle',
                'clearFormatting',
              ],
            },
            moreParagraph: {
              buttons: [
                'alignLeft',
                'alignCenter',
                'formatOLSimple',
                'alignRight',
                'alignJustify',
                'formatOL',
                'formatUL',
                'paragraphFormat',
                'paragraphStyle',
                'lineHeight',
                'outdent',
                'indent',
                'quote',
              ],
            },
            moreMisc: {
              buttons: ['undo', 'redo', 'selectAll'],
              align: 'right',
            },
          },
          pluginsEnabled: [
            'table',
            'spell',
            'quote',
            'save',
            'paragraphFormat',
            'paragraphStyle',
            'help',
            'draggable',
            'align',
            'link',
            'lists',
            'file',
            'image',
            'url',
            'video',
            'colors',
            'entities',
            'inlineClass',
            'inlineStyle',
            'imageTUI',
            'fontSize',
            'fontFamily',
          ],
        }}
      />
      {!isErrorHidden && (
        <p className="froala-editor__error MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{errorMsg}</p>
      )}
    </div>
  );
};

export default FroalaEditor;
