import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { PageLoaderNoRedux } from 'Components/Loaders/Loaders';

export default () => {
  const { logout } = useAuth0<{
    name: string;
  }>();

  useEffect(() => {
    logout();
  }, [logout]);

  return <PageLoaderNoRedux text="Logging out..." loading={true} />;
};
