import { DATE_FN_FORMATS } from 'Constants/Constants';
import { format, formatISO, isAfter, isBefore, isSameDay } from 'date-fns';

export const isSameOrBefore = (date: Date, dateToCompare: Date): boolean => {
  return isSameDay(date, dateToCompare) || isBefore(date, dateToCompare);
};

export const isSameOrAfter = (date: Date, dateToCompare: Date): boolean => {
  return isSameDay(date, dateToCompare) || isAfter(date, dateToCompare);
};

export const sortDateStrings = (dateStringA: string, dateStringB: string) => {
  const dateA = formatISO(new Date(dateStringA), { representation: 'date' });
  const dateB = formatISO(new Date(dateStringB), { representation: 'date' });
  return dateA.localeCompare(dateB);
};

export const convertToDBISODate = (date: Date) => {
  return formatISO(new Date(format(date, DATE_FN_FORMATS.YYYY_MM_DD)));
};
