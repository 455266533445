import { useState } from 'react';
import { ActionCreator } from 'redux';
import { cloneDeep, uniq } from 'lodash';
import { useDispatch } from 'react-redux';
import { datadogLogs } from '@datadog/browser-logs';
import { Action, CartItem, OrderItem, PickupLocation, Student } from 'Interfaces/Interfaces';
import { useAuth0 } from '@auth0/auth0-react';

import * as CommonActions from 'State/actions';

import { useApi } from 'Api/useApi';
import { Role, isAuthorized, TokenClaims } from 'Auth/User';

import * as Types from './types';
import { SidebarLocations } from 'Constants/Enums';

export const addItem = (cartItem: CartItem, delta: number, pickupLocationOptions: PickupLocation[]) => ({
  type: Types.CART_ADD_ITEM,
  payload: { cartItem, delta, pickupLocationOptions },
});

export const addChildItem = (menuItemId: string, childItem: CartItem) => ({
  type: Types.CART_ADD_CHILD_ITEM,
  payload: {
    menuItemId,
    childItem,
  },
});

export const clearCart = () => ({
  type: Types.CART_CLEAR,
});

export const removeItem = (cartItem: CartItem, delta: number) => ({
  type: Types.CART_REMOVE_ITEM,
  payload: {
    cartItem,
    delta,
  },
});

export const setItemComments = (cartItemId: string, comments: string) => ({
  type: Types.CART_SET_COMMENTS,
  payload: {
    cartItemId,
    comments,
  },
});

export const clearPickupLocations = () => ({
  type: Types.CART_CLEAR_PICKUP_LOCATIONS,
});

export const updatePickupLocations = (menuItemId: string, pickupLocationId: string) => ({
  type: Types.CART_SET_PICKUP_LOCATIONS,
  payload: {
    menuItemId,
    pickupLocationId,
  },
});

export const setOrderId = (payload: string) => ({
  type: Types.CART_SET_ORDER_ID,
  payload,
});

export const orderSubmitted: ActionCreator<Action> = () => ({
  type: Types.CART_SUBMIT_ORDER,
  payload: {},
});

export const orderNavigate: ActionCreator<Action> = () => ({
  type: Types.CART_TOGGLE_PICKUP,
  payload: {},
});

export const setError: ActionCreator<Action> = (payload: string) => ({
  type: Types.CART_SET_ERROR,
  payload,
});

export const setAccountId: ActionCreator<Action> = (payload: string | undefined) => ({
  type: Types.CART_SET_ACCOUNT_ID,
  payload,
});

export const useCartActions = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);

  const [actions] = useState({
    submitOrder: async (
      student: Student,
      orderItems: OrderItem[],
      existingOrderItems: { [key: string]: OrderItem },
    ) => {
      const payload = {
        orderItems,
      };
      try {
        let districtId = '';

        if (isAuthorized(userRole, [Role.PARENT])) {
          districtId = student.districtId;
        } else if (isAuthorized(userRole, [Role.TEACHER, Role.DIRECTOR, Role.MANAGER])) {
          districtId = TokenClaims.getDistrictId(user);
        }

        if (!districtId) {
          setError('districtId not found');
          datadogLogs.logger.error(`submitOrder - districtId not found =>`, { user, name: 'shop' });
          return;
        }

        if (uniq(payload.orderItems).length !== payload.orderItems.length) {
          datadogLogs.logger.error(`submitOrder - duplicates found`, { user, payload, name: 'shop' });
          payload.orderItems = uniq(payload.orderItems);
        }

        dispatch(setError(''));
        const results = (await api.post(`/districts/${districtId}/orderItems`, {
          orderItemsById: {
            [student.id]: payload.orderItems,
          },
        })) as any;
        const ordersByStudentId = results?.data || {};
        const newOrders = ordersByStudentId[student.id];

        if (newOrders && newOrders.length > 0) {
          const order: any = newOrders[0];
          const orderId = order.orderId;
          dispatch(setOrderId(orderId));
        }

        const newOrderItems = cloneDeep(existingOrderItems);

        newOrders.forEach((order: OrderItem) => {
          newOrderItems[order.id] = order;
        });

        dispatch(CommonActions.setOrderItems(newOrderItems));
        dispatch(orderSubmitted());
        dispatch(orderNavigate());
      } catch (err) {
        dispatch(setError(err?.response?.data?.message));
        datadogLogs.logger.debug(`submitOrder - error`, { name: 'shop', err });
      }
    },
    toggleCheckout: () => {
      dispatch(CommonActions.toggleSidebarOpen(SidebarLocations.CART));
      dispatch({ type: Types.CART_TOGGLE_CHECKOUT });
    },
    togglePickup: () => {
      dispatch(CommonActions.toggleSidebarOpen(SidebarLocations.PICKUP_SIDE_BAR));
      dispatch({ type: Types.CART_TOGGLE_PICKUP });
    },
  });

  return actions;
};
