import React, { ReactElement } from 'react';
import classNames from 'classnames';
import numeral from 'numeral';

import * as Interfaces from 'Interfaces/Interfaces';
import * as Constants from 'Constants/Constants';
import { isEmpty } from 'Helpers/Helper';

export interface StudentBalanceProps {
  student: Interfaces.Student;
  isParent: boolean;
  isMobile: boolean;
  hideDivider?: boolean;
}

const StudentBalance: React.FC<StudentBalanceProps> = ({
  student,
  isParent,
  isMobile,
  hideDivider,
}): ReactElement | null => {
  if (!isParent || isEmpty(student?.balance)) return null;
  return (
    <>
      {!hideDivider && <span className={classNames({ divider: true, mobile: isMobile })}>|</span>}
      <span className={classNames({ balance: true, mobile: isMobile })}>
        Balance: {numeral(student?.balance).format(Constants.USD_FORMAT)}
      </span>
    </>
  );
};

export default StudentBalance;
