import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState, OrderingRules } from 'Interfaces/Interfaces';
import Card from 'Components/Card/Card';
import * as CommonStateActions from 'State/actions';
import * as CommonStateSelectors from 'State/selectors';
import { SidebarLocations } from 'Constants/Enums';
import BulkOrderModeSideBar from '../Sidebars/BulkOrderModeSideBar';
import { isNil } from 'lodash';

export const RuleBulkOrderMode: FC<{ orderingRules: OrderingRules }> = ({ orderingRules }) => {
  const sidebarOpen = useSelector((state: AppState) =>
    CommonStateSelectors.getSidebarOpen(state, SidebarLocations.BULK_ORDER_MODE_RULE_SIDE_BAR),
  );
  const dispatch = useDispatch();

  const setSidebarOpen = (isOpen: boolean) => {
    dispatch(CommonStateActions.setSidebarOpen(isOpen, SidebarLocations.BULK_ORDER_MODE_RULE_SIDE_BAR));
  };

  return (
    <>
      <Card
        title={'Bulk Order Mode'}
        description={'Set rules for Bulk Order Mode'}
        onEditClick={() => setSidebarOpen(true)}
        editAriaLabel={'Set rules for Bulk Order Mode'}
      >
        <div className="ordering-rules__rules">
          <div className="ordering-rules__setting">
            <h6 className="linq-card__h6">Enable/Disable Bulk Order Mode</h6>
            <div className="setting-value" data-test-id={'enableDisableBulkOrderModeLbl'}>
              {isNil(orderingRules?.bulkOrderModeEnabled) || orderingRules?.bulkOrderModeEnabled
                ? 'Enabled'
                : 'Disabled'}
            </div>
          </div>
        </div>
      </Card>
      <BulkOrderModeSideBar open={sidebarOpen} setSidebarOpen={setSidebarOpen} orderingRules={orderingRules} />
    </>
  );
};
