import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import * as Constants from 'Constants/Constants';
import * as CommonActions from './actions';
import { useApi } from 'Api/useApi';
import * as LoaderActions from 'Components/Loaders/state/actions';
import { TokenClaims } from 'Auth/User';
import { AxiosResponse } from 'axios';
import * as Interfaces from 'Interfaces/Interfaces';

export const useStudents = (isTeacher: boolean, userDistrictId: any) => {
  const api = useApi();
  const dispatch = useDispatch();

  // get students
  useEffect(() => {
    async function getStudents() {
      if (isTeacher) {
        try {
          dispatch(LoaderActions.setPageLoaderActive(true));
          const results = (await api.get(`/districts/${userDistrictId}/students`)) as any;
          dispatch(
            CommonActions.setStudents(results?.data?.items, results?.data?.items.length, results?.data?.lastUpdatedAt),
          );
          dispatch(LoaderActions.setPageLoaderActive(false));
        } catch (err) {
          console.error(err);
        }
      } else {
        try {
          const results = (await api.get(`/students`)) as any;
          dispatch(CommonActions.setStudents(results?.data?.items, results?.data?.length));
        } catch (err) {
          console.error(err);
        }
      }
    }
    getStudents();
  }, [dispatch, userDistrictId, isTeacher, api]);
};

export const useParentCommonState = () => {
  const dispatch = useDispatch();

  const [orderItemLoading, setOrderItemLoading] = useState(false);
  const [studentLoading, setStudentLoading] = useState(false);

  useOrderItems(setOrderItemLoading);
  useStudentsByEntitlement(setStudentLoading);

  //watches the loading state for fetching students and order items in order to set the pageLoader state appropriately
  useEffect(() => {
    if (studentLoading || orderItemLoading) {
      dispatch(LoaderActions.setPageLoaderActive(true));
    }

    if (!studentLoading && !orderItemLoading) {
      dispatch(LoaderActions.setPageLoaderActive(false));
    }
  }, [studentLoading, orderItemLoading, dispatch]);
};

export const useOrderItems = (params?: any, setOrderItemLoading?: Dispatch<SetStateAction<boolean>>) => {
  const api = useApi();
  const dispatch = useDispatch();

  useEffect(
    () => {
      async function getOrderItems() {
        try {
          if (setOrderItemLoading) {
            setOrderItemLoading(true);
          }
          const results = (await api.get('/transactionHistory', {
            params: {
              dateFrom: moment().subtract(1, 'w').format(Constants.DATE_FORMAT_YMD),
              dateTo: moment().add(1, 'w').format(Constants.DATE_FORMAT_YMD),
              filterCanceled: true,
              ...params,
            },
          })) as any;
          dispatch(CommonActions.setOrderItems(results?.data?.data));
        } catch (err) {
          console.error(err);
        } finally {
          if (setOrderItemLoading) {
            setOrderItemLoading(false);
          }
        }
      }
      getOrderItems();
    },
    //eslint-disable-next-line
    [api, dispatch, params],
  );
};

export const useStudentsByEntitlement = (setStudentLoading?: Dispatch<SetStateAction<boolean>>) => {
  const dispatch = useDispatch();
  const api = useApi();

  useEffect(
    () => {
      async function getStudentsByEntitlement() {
        try {
          if (setStudentLoading) {
            setStudentLoading(true);
          }
          const results = (await api.get(`/students`, {
            params: {
              limit: 100,
            },
          })) as any;
          dispatch(CommonActions.setStudents(results?.data?.items, results?.data?.length));
        } catch (err) {
          console.error(err);
        } finally {
          if (setStudentLoading) {
            setStudentLoading(false);
          }
        }
      }
      getStudentsByEntitlement();
    },
    //eslint-disable-next-line
    [api, dispatch],
  );
};

export const useDistricts = (filter = 'name,orderingRules') => {
  const api = useApi();
  const dispatch = useDispatch();

  useEffect(() => {
    async function getOrderItems() {
      try {
        const results = (await api.get(`/districts`, {
          params: { filter },
        })) as any;
        dispatch(CommonActions.setDistricts(results?.data?.items));
      } catch (err) {
        console.error(err);
      }
    }
    getOrderItems();
  }, [api, dispatch, filter]);
};

export const useDistrictCommon = (isAuthorized: boolean) => {
  const api = useApi();
  const dispatch = useDispatch();
  const { user } = useAuth0();

  useEffect(() => {
    async function getDistrict() {
      dispatch(LoaderActions.setPageLoaderActive(true));
      const districtId = TokenClaims.getDistrictId(user);
      try {
        const results = (await api.get(`/districts/${districtId}`)) as any;
        dispatch(CommonActions.setDistrict(results?.data));
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(LoaderActions.setPageLoaderActive(false));
      }
    }

    if (isAuthorized) {
      getDistrict();
    }
  }, [dispatch, user, api, isAuthorized]);
};

export const useDistrict = (districtId: string) => {
  const api = useApi();
  const dispatch = useDispatch();
  const [districtLoading, setDistrictLoading] = useState(false);

  useEffect(() => {
    async function getDistrict() {
      try {
        setDistrictLoading(true);
        const results = (await api.get(`/districts/${districtId}`)) as any;
        dispatch(CommonActions.setDistrict(results?.data));
        setDistrictLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

    if (districtId) {
      getDistrict();
    }
  }, [api, dispatch, districtId]);

  return districtLoading;
};

export const useDistrictStudents = (districtId: string, includeInactiveStatuses = false) => {
  const api = useApi();
  const dispatch = useDispatch();
  const [state, setState] = useState<Interfaces.GetDistrictStudentState>({
    loading: false,
    totalLoaded: 0,
  });

  useEffect(() => {
    async function getDistrictStudents() {
      let studentList: Array<Interfaces.Student> = [];
      try {
        let next: string | undefined;
        dispatch(LoaderActions.setPageLoaderActive(true));

        setState((state) => ({ ...state, loading: true }));

        do {
          const response: AxiosResponse<Interfaces.CollectionResponse<Interfaces.Student>> = await api.get(
            `/districts/${districtId}/students`,
            {
              params: {
                next,
                limit: 1000,
                includeInactiveStatuses,
                outputFormat: Constants.OutputFormat.Array,
              },
            },
          );
          const result = response.data.items;
          studentList = studentList.concat(response.data.items);
          setState((state) => ({ ...state, totalLoaded: state.totalLoaded + result.length }));
          next = response.data.next;
        } while (next);
      } catch (err) {
      } finally {
        dispatch(CommonActions.setStudents(studentList, studentList.length));
        dispatch(LoaderActions.setPageLoaderActive(false));
        setState((state) => ({ ...state, loading: false }));
      }
    }

    if (districtId) {
      getDistrictStudents();
    }
  }, [dispatch, api, districtId, includeInactiveStatuses]);

  return state;
};

export const useSites = (districtId: string) => {
  const api = useApi();
  const dispatch = useDispatch();

  const [siteLoading, setSiteLoading] = useState(false);

  useEffect(() => {
    async function getSites() {
      try {
        setSiteLoading(true);
        const results = (await api.get(`/districts/${districtId}/sites`)) as any;
        dispatch(CommonActions.setSites(results?.data?.items));
      } catch (err) {
        console.error(err);
      } finally {
        setSiteLoading(false);
      }
    }

    if (districtId) {
      getSites();
    }
  }, [api, dispatch, districtId]);

  return siteLoading;
};
