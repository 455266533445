import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useLocation } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';

import { useTeacherRosterOrderingActions } from 'Pages/TeacherRosterOrdering/state/actions';
import * as TeacherRosterOrderingSelectors from 'Pages/TeacherRosterOrdering/state/selectors';
import * as MenuTypePickerSelectors from 'Components/MenuTypePicker/state/selectors';

import * as Constants from 'Constants/Constants';
import { useAutoPrintPage } from 'Helpers/useAutoPrintPage';

import './TeacherRosterOrderingPrint.less';
import { TokenClaims } from 'Auth/User';
import { useApi } from 'Api/useApi';
import classNames from 'classnames';
import { range } from 'lodash';
import { ReportMode, TeacherReportCellModel } from 'Interfaces/Interfaces';
import { PageLoaderNoRedux } from 'Components/Loaders/Loaders';

const TeacherRosterOrderingPrint = () => {
  const dispatch = useDispatch();
  let { search } = useLocation();
  const api = useApi();
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);
  const { getTeacherReport } = useTeacherRosterOrderingActions();
  const defaultMenuType = useSelector(MenuTypePickerSelectors.getDefaultMenuType);
  const menuTypeId = useSelector(MenuTypePickerSelectors.getMenuTypeId) || defaultMenuType.id;
  const [loading, setLoading] = React.useState(false);
  const [headers, setHeaders] = React.useState<string[][]>([]);
  const [studentCount, setStudentCount] = React.useState(0);
  const [data, setData] = React.useState<(string | TeacherReportCellModel)[][][]>([]);
  const [numberOfTables, setNumberOfTables] = React.useState(0);

  const siteId = TokenClaims.getSiteId(user);
  const homeroomId = TokenClaims.getHomeroomId(user);
  const homeroom = useSelector(TeacherRosterOrderingSelectors.getHomeroomName(homeroomId));
  const query = new URLSearchParams(search);
  const dateFrom = query.get('dateFrom');
  const dateTo = query.get('dateTo');

  useEffect(() => {
    const getReport = async () => {
      try {
        setLoading(true);
        const result = (await api.get(`/utility/reports/districts/${districtId}/teacher`, {
          params: {
            siteId,
            homeroomId,
            dateFrom,
            dateTo,
            menuTypeId,
            mode: ReportMode.Print,
          },
        })) as any;
        const requestData = result.data;

        const { headers: reportHeaders, totalStudents, tableData, tableCount } = requestData;
        setStudentCount(totalStudents);
        setHeaders(reportHeaders);
        setData(tableData);
        setNumberOfTables(tableCount);
      } catch {
      } finally {
        setLoading(false);
      }
    };

    getReport();
  }, [api, districtId, dispatch, homeroomId, siteId, menuTypeId, dateFrom, dateTo, getTeacherReport]);

  useAutoPrintPage(data, [homeroom]);

  return (
    <React.Fragment>
      <PageLoaderNoRedux loading={loading} />
      <div className="teacher-ordering-report-container">
        <header className="page-header">
          <div className="classroom-name">
            {homeroom} - {studentCount} Students
          </div>
          <div className="date">{moment(dateFrom).format(Constants.DATE_FORMAT_DMDY)}</div>
        </header>
        {range(numberOfTables).map((i) => {
          const headerRow = headers[i];
          const tableRows = data[i];

          return (
            <React.Fragment key={i}>
              <table className={classNames('teacher-report', i === 0 && 'teacher-report__first-table')} key={i}>
                <thead className="teacher-report__thead">
                  <tr className="teacher-report__header-row">
                    {headerRow.map((header: string) => {
                      const className = classNames({
                        'student-name': header === 'Student',
                        'student-allergens': header === 'Allergens',
                        'teacher-report__tr': true,
                        'teacher-report__pre-line': true,
                      });
                      return (
                        <th className={className} key={header}>
                          {header}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {tableRows.map((studentRow, j) => {
                    return (
                      <tr className="teacher-report__tr" key={j}>
                        {studentRow.map((value, k) => {
                          if (typeof value === 'string') {
                            return (
                              <td className="teacher-report__pre-line" key={k}>
                                {value}
                              </td>
                            );
                          } else {
                            const meal = value;
                            return (
                              <td className="teacher-report__pre-line" key={k}>
                                {meal.entree}
                                {'\n'}
                                <span className="teacher-report__sides">{meal.sides}</span>
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {i < numberOfTables - 1 && <div className="teacher-report__page-break" />}
            </React.Fragment>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default TeacherRosterOrderingPrint;
