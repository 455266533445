import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as Interfaces from 'Interfaces/Interfaces';
import { KeyboardDatePicker } from './KeyboardDatePicker';

export interface KeyboardDateRangePickerProps {
  fromDateProps: Interfaces.KeyboardDatePickerFieldProps;
  toDateProps: Interfaces.KeyboardDatePickerFieldProps;
}

export const KeyboardDateRangePickerField = ({ fromDateProps, toDateProps }: KeyboardDateRangePickerProps) => {
  return (
    <div className="date-range-picker">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker {...fromDateProps} className={'date-range-picker__from-date'} />
        <KeyboardDatePicker {...toDateProps} className={'date-range-picker__to-date'} />
      </MuiPickersUtilsProvider>
    </div>
  );
};
