import React from 'react';
import { MenuItem } from 'Interfaces/Interfaces';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { BULK_ORDER_ITEM_LIMIT, MOBILE_MEDIA_QUERY } from 'Constants/Constants';
import IncrementalNumberInput from 'Components/Form/IncrementalNumberInput';
import SpecialMealAccommodations from 'Components/SpecialMealAccommodations/SpecialMealAccommodations';

interface BulkOrderCardProps {
  menuItem?: MenuItem;
  onOpenClick: () => void;
  quantity: number;
  onQuantityChange: (menuItem: MenuItem, quantity: number) => void;
}

const BulkOrderCard = ({ menuItem, onOpenClick, quantity, onQuantityChange }: BulkOrderCardProps) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY });
  const [inputValue, setInputValue] = React.useState<number | string>(quantity);

  React.useEffect(() => {
    setInputValue(quantity);
  }, [quantity]);

  const product = menuItem?.product;

  if (!product) {
    // Render empty card to ensure same number of columns is always rendered otherwise
    // scrolling horizontally when there are no items on the right will cause the scrollbar to reset its position
    return <div className="bulk-order-card">&nbsp;</div>;
  }

  return (
    <div className="bulk-order-card">
      <div
        className={classNames('bulk-order-card__btn', {
          'menu-item-container': !isMobile,
          'menu-item-container-mobile': isMobile,
        })}
      >
        <button
          className="image bulk-order-card__image"
          style={{ backgroundImage: product.imageUrl ? `url(${product.imageUrl})` : undefined }}
          aria-label="View Product Information"
          onClick={() => onOpenClick()}
          data-test-id={`${product.name}.imageBtn`}
        />
        <div className="body bulk-order-card__product-body">
          <button className="side-bar__product-name side-bar__bulk-product-name" onClick={() => onOpenClick()}>{product.name}</button>
          <div className="bulk-order-card__row">
            <div className="bulk-order-card__input-container">
              <IncrementalNumberInput
                className="bulk-order-card__input"
                min={0}
                max={BULK_ORDER_ITEM_LIMIT}
                value={inputValue}
                onChange={(value: number | string) => {
                  setInputValue(value);
                }}
                onBlur={() => {
                  if (menuItem) {
                    const value = Number(inputValue) || 0;
                    onQuantityChange(menuItem, value);
                    setInputValue(value);
                  }
                }}
                onAddOrSubstract={(value: number) => {
                  if (menuItem) {
                    setInputValue(value);
                    onQuantityChange(menuItem, value);
                  }
                }}
                inputHeight={8}
              />
              <span
                className={classNames(
                  'bulk-order-card__total-ordered',
                  (inputValue === 0 || inputValue === '') && 'bulk-order-card__total-ordered-none',
                )}
                data-test-id={`${product.name}.orderedLbl`}
              >
                {inputValue || 0} Ordered
              </span>
            </div>
            <SpecialMealAccommodations
              specialMealAccommodationIds={product.specialMealAccommodationIds}
              customSpecialMealAccommodations={product.customSpecialMealAccommodations}
              hideLabel={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkOrderCard;
