import { Card as MuiCard, CardContent, CardHeader, IconButton } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import React from 'react';

import './Card.less';

interface CardProps {
  title: string;
  onEditClick?: () => void;
  editAriaLabel?: string;
  description?: string;
}

const Card: React.FC<CardProps> = ({ title, onEditClick, editAriaLabel, description, children }) => {
  const getCardAction = () => {
    if (onEditClick) {
      return (
        <IconButton onClick={onEditClick} aria-label={editAriaLabel} data-test-id={`${title}.editIconBtn`}>
          <CreateIcon />
        </IconButton>
      );
    }

    return null;
  };
  return (
    <MuiCard className="linq-card">
      <CardHeader title={title} action={getCardAction()} />
      <CardContent className="linq-card__space" data-test-id={`${title}.Description`}>
        {description && <p>{description}</p>}
        {children}
      </CardContent>
    </MuiCard>
  );
};

export default Card;
