import React, { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useAuth0 } from '@auth0/auth0-react';
import { isAuthorized, Role, TokenClaims } from 'Auth/User';
import { Env } from 'Interfaces/Interfaces';
import * as CommonHooks from 'State/hooks';
import * as CommonSelectors from 'State/selectors';
import { PendoAppName } from 'Constants/Constants';
import { hideResourceCenterOffClick } from './helpers/PendoSnippets';
import AccountValidator from './AccountValidator';
import NotificationContextProvider from './NotificationContextProvider';
import { DeveloperTools } from './DeveloperTools';
import AuthenticationValidator from './AuthenticationValidator';
import Routes from './Routes';
import { globalConfig } from './config';

import './styles/global.less';
import '@linq/snackpaq/dist/theme/blueberry-muffin.css';

const App = (): ReactElement => {
  const { user } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const userAccountId = user?.sub;
  const userEmail = user?.email;
  const userFullName = user?.name;
  const userDistrictId = TokenClaims.getDistrictId(user);
  const authorized: boolean = useMemo(
    () => isAuthorized(userRole, [Role.TEACHER, Role.DIRECTOR, Role.MANAGER]),
    [userRole],
  );

  const env = globalConfig.getPortalConfig().env;

  const isProduction = env === Env.Production;

  CommonHooks.useDistrictCommon(authorized);
  const district = useSelector(CommonSelectors.getDistrict(userDistrictId));

  // @ts-ignore
  if ((env === Env.Staging || env === Env.Production) && window.pendo && user && (district || !userDistrictId)) {
    const options: any = {
      events: {
        guidesLoaded: () => {
          //@ts-ignore
          window.pendo.attachEvent(document, 'click', hideResourceCenterOffClick);
        },
      },
      visitor: {
        id: userAccountId,
        email: userEmail,
        full_name: userFullName,
        role: userRole,
        app_name: PendoAppName,
      },
    };

    if (district) {
      options.account = {
        id: district.name,
        districtName: district.name,
        districtId: district.id,
        app_name: PendoAppName,
      };
    }

    //@ts-ignore
    window.pendo.initialize(options);
  }

  return (
    <BrowserRouter>
      <NotificationContextProvider>
        <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
          {!isProduction && <DeveloperTools />}
          <AuthenticationValidator>
            <AccountValidator>
              <>
                <Routes />
                <ToastContainer />
              </>
            </AccountValidator>
          </AuthenticationValidator>
        </DndProvider>
      </NotificationContextProvider>
    </BrowserRouter>
  );
};

export default App;
