import React, { ReactElement, useEffect, useMemo } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import moment from 'moment';
import { TokenClaims } from 'Auth/User';
import { createDayRange, getFriendlyNameFromSnakeCase } from 'Helpers/Helper';
import * as Constants from 'Constants/Constants';
import CalendarWeek from 'Components/Shop/CalendarWeek/CalendarWeek';
import MenuItemModal from 'Components/Shop/MenuItemModal/MenuItemModal';
import { ComponentLoader } from 'Components/Loaders/Loaders';
import * as LoadersSelectors from 'Components/Loaders/state/selectors';
import { getDayRange } from 'Components/DateRangeNavigator/state/selectors';
import * as DateRangeNavigatorActions from 'Components/DateRangeNavigator/state/actions';
import * as MenuTypePickerAction from 'Components/MenuTypePicker/state/actions';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import * as CommonActions from 'State/actions';
import * as CommonSelectors from 'State/selectors';
import * as Interfaces from 'Interfaces/Interfaces';
import { SidebarLocations } from 'Constants/Enums';
import DateRangeNavigator, { getMomentUnit } from 'Components/DateRangeNavigator/DateRangeNavigator';
import * as CommonHooks from 'State/hooks';
import * as ShopHooks from './state/hooks';
import * as ShopActions from './state/actions';

import './Shop.less';

const ShopPreview = (): ReactElement => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);
  const isLoading = useSelector(LoadersSelectors.getComponentLoaderActive);
  const showModal = useSelector((state: Interfaces.AppState) =>
    CommonSelectors.getSidebarOpen(state, SidebarLocations.MENU_ITEM_SIDE_BAR),
  );

  const dayRange = useSelector(getDayRange);
  const districts = useSelector(CommonSelectors.getDistricts);
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const { getSites } = CommonActions.useCommonActions();

  const { search } = useLocation();

  const { siteId, siteName, siteGroupTypeId, menuName, menuTypeId, month } = React.useMemo(
    () => {
      const query = new URLSearchParams(search);

      return {
        siteId: query.get('siteId') ?? '',
        siteName: query.get('siteName') ?? '',
        siteGroupTypeId: query.get('siteGroupTypeId') ?? '',
        menuName: query.get('menuName') ?? '',
        menuTypeId: query.get('menuTypeId') ?? '',
        month: query.get('month') ?? '',
      };
    },
    // eslint-disable-next-line
    [],
  );

  const menuStart = React.useMemo(() => moment(month).startOf('month').startOf('week'), [month]);

  useEffect(() => {
    let start = menuStart;

    if (start.isSameOrBefore(moment())) {
      start = moment().startOf('week');
    }

    const range = createDayRange({ startDate: start, unit: getMomentUnit(Constants.DateRangeMode.WEEK) });

    dispatch(DateRangeNavigatorActions.setDateRange(range));
    dispatch(DateRangeNavigatorActions.setStartDate(start));
  }, [dispatch, menuStart]);

  useEffect(() => {
    if (districtId) {
      getSites(districtId);
    }
  }, [dispatch, getSites, districtId]);

  useEffect(() => {
    dispatch(ShopActions.setSiteId(siteId));
  }, [siteId, dispatch]);

  useEffect(() => {
    dispatch(MenuTypePickerAction.setMenuTypeId(menuTypeId));
  }, [menuTypeId, dispatch]);

  const { startDate, endDate } = useMemo(() => {
    return {
      startDate: dayRange.startDate.format(Constants.DATE_FORMAT_YMD),
      endDate: dayRange.endDate.format(Constants.DATE_FORMAT_YMD),
    };
  }, [dayRange]);

  CommonHooks.useDistrict(districtId);
  const { getShopData } = ShopHooks.useShopData(undefined, districtId, menuTypeId, siteId);

  useEffect(() => {
    batch(() => {
      dispatch(CommonActions.setMenuItems());
      dispatch(CommonActions.setProducts());
      dispatch(CommonActions.setOrderItems());
    });
    getShopData();
  }, [dispatch, menuTypeId, startDate, endDate, getShopData]);

  return (
    <div className="shop-container">
      <SubHeader title={`Preview Menus - ${districts[districtId]?.name ?? ''}`} />
      <h4 className={'menu-sub-header'}>{`${getFriendlyNameFromSnakeCase(
        siteGroupTypeId,
      )} \\ ${siteName} \\ ${menuName}`}</h4>
      <div className="container-fluid m-0 p-0">
        <div className="row g-0">
          <div
            className={classNames('col-sm-12 col-lg-6 col-xl-6 px-0', 'shop-options-container', { mobile: isMobile })}
          ></div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4 px-0"></div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4 px-0">
            <DateRangeNavigator
              mode={Constants.DateRangeMode.WEEK}
              defaultDayOfYearToWeekday={true}
              disablePast={true}
            />
          </div>
        </div>
        <ComponentLoader />
        {!isLoading && <CalendarWeek />}
        {showModal && <MenuItemModal />}
      </div>
    </div>
  );
};

export default ShopPreview;
