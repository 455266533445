import { useAuth0 } from '@auth0/auth0-react';
import { Badge, Divider, ListItem, ListItemText, Menu, MenuItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import numeral from 'numeral';
import { Role, TokenClaims } from 'Auth/User';
import * as HeaderActions from 'Components/Header/state/actions';
import * as HeaderSelectors from 'Components/Header/state/selectors';
import HeaderMenu from 'Components/HeaderMenu/HeaderMenu';
import Cart from 'Components/Shop/Cart/Cart';
import * as CartActions from 'Components/Shop/Cart/state/actions';
import * as CartSelectors from 'Components/Shop/Cart/state/selectors';
import Pickup from 'Components/Shop/Pickup/Pickup';
import Button from 'Components/Button/Button';
import { SidebarLocations } from 'Constants/Enums';
import { getRoutePath, RouteId, Routes } from 'Constants/Routes';
import { USD_FORMAT } from 'Constants/Constants';
import * as TeacherRosterOrderingSelectors from 'Pages/TeacherRosterOrdering/state/selectors';
import React, { useCallback, useMemo, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as CommonStateActions from 'State/actions';
import { useCommonActions } from 'State/actions';
import * as ShopSelectors from '../../pages/Shop/state/selectors';

import './Header.less';

export default ({ adminSidebar = false }: { adminSidebar: boolean }) => {
  const { logout, user, loginWithRedirect } = useAuth0();
  const userRole = TokenClaims.getRole(user);
  const userId = TokenClaims.getUserId(user);
  const { getDistricts } = useCommonActions();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userButtonRef = useRef<HTMLButtonElement>(null);
  const menuVisible = useSelector(HeaderSelectors.getMenuVisible);
  const name = user?.name;
  const cartTotals = useSelector(CartSelectors.getCartTotals);
  const teacherCartTotals = useSelector(TeacherRosterOrderingSelectors.getCartTotals(userId));
  const isTeacher = userRole === Role.TEACHER;
  const isParent = userRole === Role.PARENT;
  const isGoogleAuthUser = userId.includes('google');

  // check for 'staff' provider string in id to handle 'continue with district staff account' path
  const isAdmin = TokenClaims.getAdmin(user) || TokenClaims.getUserId(user).includes('staff');

  const hideProfileLink = isGoogleAuthUser || (userRole === Role.TEACHER && !isAdmin);

  const cartSum = cartTotals.entrees + cartTotals.alacarte;
  const homeroomId = TokenClaims.getHomeroomId(user);
  const homeroomName = useSelector(TeacherRosterOrderingSelectors.getHomeroomName(homeroomId));
  const student = useSelector(ShopSelectors.getStudent);
  const location = useLocation();
  const cartActions = CartActions.useCartActions();

  const handleSkipToContent = () => {
    window.document
      .querySelector('main')
      ?.querySelector<HTMLElement>(
        'button:not([disabled]), [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      )
      ?.focus();
  };

  const handleMenuClick = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(HeaderActions.setMenuVisible(!menuVisible));
    },
    [dispatch, menuVisible],
  );

  const handleAccountClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.target as HTMLElement);
  };

  const cartVisible = useMemo(() => {
    return [Role.TEACHER, Role.PARENT].includes(userRole);
  }, [userRole]);

  const accountConfigurationVisible = useMemo(() => {
    return [Role.TEACHER, Role.MANAGER].includes(userRole);
  }, [userRole]);

  function onCartClick() {
    if (isTeacher) {
      dispatch(CommonStateActions.setSidebarOpen(true, SidebarLocations.CART_SIDE_BAR));
    } else {
      cartActions.toggleCheckout();
    }
  }

  const handleAdminClick = useCallback(() => {
    batch(() => {
      setAnchorEl(null);
      getDistricts('name,orderingRules');
      dispatch(CommonStateActions.setSidebarOpen(true, SidebarLocations.ADMIN_SIDE_BAR));
    });
  }, [dispatch, getDistricts]);

  const getClassroomBalanceHeader = () => {
    if (isTeacher) {
      return (
        <React.Fragment>
          <div className="divider"></div>
          <span className="unit-name">{homeroomName ?? ''}</span>
        </React.Fragment>
      );
    } else if (isParent && location.pathname === getRoutePath(RouteId.SHOP)) {
      const balance = student?.balance ? numeral(student.balance).format(USD_FORMAT) : '';

      return (
        <React.Fragment>
          <div className="divider"></div>
          <span className="unit-name">{`${student?.firstName ?? ''} ${student?.lastName ?? ''}`}</span>
          <div className="divider"></div>
          <span className="unit-name">{`Balance: ${balance}`}</span>
        </React.Fragment>
      );
    } else if (isParent && location.pathname === getRoutePath(RouteId.ORDER_CONFIRMATION)) {
      return (
        <React.Fragment>
          <div className="divider"></div>
          <span className="unit-name">{'Order Confirmation'}</span>
        </React.Fragment>
      );
    }

    return null;
  };

  return (
    <AppBar
      position="relative"
      className="header-wrapper linq-app-bar mdc-top-app-bar"
      id="header"
      aria-label="Main header for the site."
    >
      <nav className="header-container" aria-label="Top level site navigation">
        <button className="header-skip-to-content" onClick={handleSkipToContent}>
          SKIP TO CONTENT
        </button>
        <section className="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
          {user && (
            <IconButton
              aria-label="Menu"
              onKeyPress={(e) => {
                if (e.keyCode === 13) {
                  handleMenuClick(e);
                }
              }}
              onClick={handleMenuClick}
              data-test-id="navMenuBtn"
            >
              {menuVisible ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          )}
          <button
            className="mdc-top-app-bar__title btn-reset"
            aria-label="Linq Online Ordering"
            onClick={() => {
              navigate(Routes[RouteId.ROOT]?.path);
            }}
          >
            <img
              className="header__logo"
              src={process.env.PUBLIC_URL + '/LINQ-Online-Ordering-logo.svg'}
              alt="Linq Logo"
            />
            {getClassroomBalanceHeader()}
          </button>
        </section>
        {user && (
          <div
            className="header-actions mdc-top-app-bar__section mdc-top-app-bar__section--align-end"
            role="navigation"
            aria-label="User and Cart Navigation"
          >
            <IconButton aria-label="LINQ Online Ordering Help Center">
              <HelpOutlineIcon />
            </IconButton>
            <IconButton
              ref={userButtonRef}
              aria-label="User Settings"
              onClick={handleAccountClick}
              data-test-id="userSettingsBtn"
            >
              <PersonIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              onClose={() => setAnchorEl(null)}
              classes={{ list: 'header__menu' }}
            >
              <ListItem className="header__profile-name">
                <ListItemText className="header__menu-item-name">{!hideProfileLink ? 'Profile' : name}</ListItemText>
              </ListItem>
              <Divider className="header__menu-divider" />
              {!hideProfileLink && (
                <MenuItem
                  className="header__menu-item-text"
                  tabIndex="0"
                  onClick={() => {
                    navigate(isAdmin ? Routes[RouteId.DIRECTOR_PROFILE]?.path : Routes[RouteId.PROFILE]?.path);
                    setAnchorEl(null);
                  }}
                  data-test-id="profileBtn"
                >
                  {name}
                </MenuItem>
              )}
              {accountConfigurationVisible && (
                <MenuItem
                  tabIndex="0"
                  onClick={() => {
                    navigate(Routes[RouteId.ACCOUNT_CONFIGURATION]?.path as string);
                  }}
                  className="header__menu-item-text"
                  data-test-id="accountBtn"
                >
                  Account
                </MenuItem>
              )}
              {adminSidebar && (
                <MenuItem
                  className="header__menu-item-text"
                  tabIndex="0"
                  onClick={handleAdminClick}
                  data-test-id="adminBtn"
                >
                  Admin
                </MenuItem>
              )}
              <MenuItem
                className="header__menu-item-text"
                tabIndex="0"
                onClick={() => {
                  logout({ returnTo: window.location.origin });
                  setAnchorEl(null);
                }}
                data-test-id="signOutBtn"
              >
                Sign Out
              </MenuItem>
            </Menu>
            {cartVisible && (
              <div className="cart-container">
                <Badge
                  badgeContent={
                    <span className="linq-app-bar MuiBadge-colorPrimary">
                      {isTeacher ? teacherCartTotals : cartSum}
                    </span>
                  }
                  color="primary"
                  aria-label="Total Items in Cart"
                >
                  <IconButton onClick={onCartClick} aria-label="View Cart" data-test-id="viewCartBtn">
                    <ShoppingCart />
                  </IconButton>
                </Badge>
              </div>
            )}
          </div>
        )}
        {!user && (
          <div className="header-actions mdc-top-app-bar__section mdc-top-app-bar__section--align-end">
            {location.pathname !== getRoutePath(RouteId.REGISTER) && (
              <Button
                variant="outlined"
                color="primary"
                aria-label={'Register for an account'}
                className="submit-button"
                onClick={() => {
                  navigate(Routes[RouteId.REGISTER]?.path as string);
                }}
              >
                REGISTER
              </Button>
            )}

            <Button
              variant="contained"
              color="primary"
              aria-label={'Login to Linq Online Ordering'}
              className="submit-button"
              onClick={() => {
                loginWithRedirect({});
              }}
            >
              LOGIN
            </Button>
          </div>
        )}

        <Cart />
        <Pickup />
      </nav>
      <HeaderMenu />
    </AppBar>
  );
};
