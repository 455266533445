import { Reducer } from 'react';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';
import { CLOSE_ALL_SLIDEOUTS_AND_MODALS } from 'State/types';

const initialState: Interfaces.HeaderState = {
  menuVisible: false,
};

const HeaderReducer: Reducer<Interfaces.HeaderState, Interfaces.Action> = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.HEADER_SET_MENU_VISIBLE:
      return {
        ...state,
        menuVisible: payload,
      };
    case CLOSE_ALL_SLIDEOUTS_AND_MODALS:
      return {
        ...state,
        menuVisible: payload === 'menuVisible',
      }

    default:
      return state;
  }
};

export default HeaderReducer;
