import React, { FC, useState } from 'react';
import { Formik, Form, FormikValues, Field, FieldProps, FormikProps } from 'formik';
import Button from 'Components/Button/Button';
import { InputField } from 'Components/Form/MaterialForm';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'Components/Drawer';
import NotificationBanner from 'Components/NotificationBanner/NotificationBanner';
import { InputMode, SidebarLocations } from 'Constants/Enums';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import { useCommonActions } from 'State/actions';
import { OrderingRules } from 'Interfaces/Interfaces';

export interface ReimbursableRulesSidebarProps {
  open: boolean;
  orderingRules: OrderingRules;
  setSidebarOpen: (open: boolean) => void;
}

const ReimbursableRulesSidebar: FC<ReimbursableRulesSidebarProps> = ({
  open = false,
  orderingRules,
  setSidebarOpen,
}) => {
  const { updateDistrict } = useCommonActions();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async ({ reimbursableMinimum }: FormikValues) => {
    setLoading(true);
    const updatedDistrict = {
      orderingRules: {
        ...orderingRules,
        reimbursableMinimum: !reimbursableMinimum ? '' : reimbursableMinimum,
      },
    };
    await updateDistrict(updatedDistrict);
    setLoading(false);
    setSidebarOpen(false);
  };

  return (
    <Drawer open={open} id="online-ordering-card-labels" anchor="right" className="ordering-rules-sidebar-container">
      <Formik initialValues={orderingRules} onSubmit={handleSubmit} enableReinitialize>
        {({ handleReset }: FormikProps<{}>) => (
          <Form className="linq-drawer-form-content oo-drawer-form">
            <DrawerHeader>
              <h2>Edit Reimbursable Meals Rules</h2>
              <CloseIconButton
                onClick={() => {
                  setSidebarOpen(false);
                }}
              />
            </DrawerHeader>
            <NotificationBanner location={SidebarLocations.REIMB_RULES_SIDE_BAR} />
            <DrawerContent>
              <section>
                <p>Set minimum required meals balance to order reimbursable meals</p>
                <Field name="reimbursableMinimum">
                  {({ field, form }: FieldProps) => (
                    <InputField
                      id="reimbursableMinimum"
                      name="reimbursableMinimum"
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      defaultValue={field.value || ''}
                      value={field.value}
                      error={!!form.errors.reimbursableMinimum}
                      helperText={form.errors.reimbursableMinimum as string}
                      touched={!!form.errors.reimbursableMinimum}
                      isCurrency
                      mode={InputMode.Decimal}
                      numericSize={[7, 2]}
                      label="Reimbursable Meals Minimum"
                      inputProps={{
                        'data-test-id': 'reimbursableMealMinimumInput',
                      }}
                    />
                  )}
                </Field>
              </section>
            </DrawerContent>
            <DrawerFooter>
              <Button
                type="button"
                color="primary"
                variant="outlined"
                disabled={loading}
                onClick={() => {
                  setSidebarOpen(false);
                  handleReset();
                }}
                data-test-id="cancelBtn"
              >
                Cancel
              </Button>
              <Button
                aria-label="Submit"
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading}
                loading={loading}
                data-test-id="submitBtn"
              >
                Save
              </Button>
            </DrawerFooter>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default ReimbursableRulesSidebar;
