import { useState } from 'react';

import { useApi } from 'Api/useApi';
import { Newsfeed } from 'Interfaces/Interfaces';
import { AxiosResponse } from 'axios';

export const useEditNewsfeedActions = () => {
  const api = useApi();

  const [actions] = useState({
    postNewsfeed: async (districtId: string, newsfeed: Newsfeed): Promise<AxiosResponse<Newsfeed>> => {
      return api.post(`/districts/${districtId}/newsfeeds`, newsfeed);
    },
    patchNewsfeed: async (districtId: string, newsfeed: Newsfeed): Promise<AxiosResponse<Newsfeed>> => {
      return api.patch(`/districts/${districtId}/newsfeeds/${newsfeed.id}`, newsfeed);
    },
    getNewsfeed: async (districtId: string, id: string): Promise<AxiosResponse<Newsfeed>> => {
      return api.get(`/districts/${districtId}/newsfeeds/${id}`);
    },
  });

  return actions;
};
