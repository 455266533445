import React, { FC, useState } from 'react';
import { DropzoneOptions } from 'react-dropzone';
import axios, { AxiosResponse } from 'axios';
import { useApi } from 'Api/useApi';
import { Api } from 'Interfaces/Interfaces';
import FileDropzone from 'Components/FileDropzone/FileDropzone';
import { globalConfig } from '../../config';
import { NotificationContext } from '../../NotificationContextProvider';

type UploadResponse = { success: boolean; response?: AxiosResponse; url?: string; error?: any };

export const processUpload = async (
  api: Api,
  file: any,
  preUploadProcessor: undefined | ((data: any) => any),
): Promise<UploadResponse> => {
  try {
    let data = {
      name: file.name,
      uploadType: 'ProductAsset',
    };

    if (preUploadProcessor) {
      data = preUploadProcessor(data);
    }

    const results: any = await api.post(`/utility/import`, data);
    const uploadUrl = results?.data?.upload.url;
    const response = await axios.put(uploadUrl, file, {
      headers: {
        'Content-Disposition': 'inline',
        'Content-Type': 'image',
      },
    });

    const portal = globalConfig.getPortalDomain();
    const url = `${portal}/alacarte-assets/${results.data.districtId}/${results.data.name}`;
    return {
      success: response.status === 200,
      response,
      url,
    };
  } catch (error) {
    console.error('Error: ' + error);
    return {
      success: false,
      error: error,
    };
  }
};

export interface UploadFileProps {
  label?: string;
  options?: DropzoneOptions;
  onError: (error: any) => void;
  onSuccess?: () => void;
  setFieldValue: (files: any) => void;
  setLoading: (loading: boolean) => void;
  preUploadProcessor?: (data: any) => any;
}

export const UploadFile: FC<UploadFileProps> = ({
  label = 'Upload',
  options = {},
  setFieldValue,
  setLoading,
  onError,
  onSuccess = () => {},
  preUploadProcessor,
}) => {
  const [localLoading, setLocalLoading] = useState(false);
  const api = useApi();
  const { addError } = React.useContext(NotificationContext);
  const onDrop = async (acceptedFiles: any[], fileRejections: any[]) => {
    if (!fileRejections.length) {
      setLocalLoading(true);
      setLoading(true);
      const { success, url, ...response } = await processUpload(api, acceptedFiles[0], preUploadProcessor);
      if (success) {
        setFieldValue(url);
        onSuccess();
      } else {
        onError(response);
      }
      setLoading(false);
      setLocalLoading(false);
    } else {
      if (options.accept && Array.isArray(options.accept)) {
        const acceptedFilesTypes = options.accept.join(', ').trimEnd();
        addError({
          message: `Unsupported file type: Only files with the extensions ${acceptedFilesTypes} are accepted.`,
        });
      } else {
        addError({
          message: 'Unsupported file type.',
        });
      }
    }
  };

  return <FileDropzone label={label} loading={localLoading} onDrop={onDrop} {...options} />;
};
