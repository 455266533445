import React, { FC } from 'react';
import { get, cloneDeep } from 'lodash';
import { REPORT_SCOPE, REPORT_TIME_FRAME } from 'Constants/Enums';
import { ALACARTE_KEY, ALACARTE, UNASSOCIATED_MEAL_TYPE_ID } from 'Constants/Constants';
import { Report, ProductTotals, MenuType, Product } from 'Interfaces/Interfaces';
import TotalsTable from './TotalsTable';

export interface TotalsProps {
  mealsOrdered: boolean;
  menuTypes: { [key: string]: MenuType };
  menuTypeId?: string;
  pickupLocationId: string;
  report: Report;
  scope: REPORT_SCOPE;
  siteId: string;
  timeFrame: REPORT_TIME_FRAME;
}

type MealTypeProductTotals = { [mealTypeId: string]: ProductTotals };

const Totals: FC<TotalsProps> = ({
  mealsOrdered,
  menuTypes,
  menuTypeId,
  pickupLocationId,
  report,
  scope,
  siteId,
  timeFrame,
}) => {
  const products = get(report, 'products', {}) as { [productId: string]: Product };
  const totalsByMealType = get(report, `totalsByMealType`, {});
  const totalsBySite = get(report, `totalsBySite`, {});
  const totalsByPickupLocation = get(report, 'totalsByPickupLocation', {});
  const aLaCarteProductTotals = {} as { [productId: string]: number };

  let tableData: { [mealTypeId: string]: ProductTotals } | undefined;
  switch (scope) {
    case REPORT_SCOPE.DISTRICT:
      tableData = cloneDeep(totalsByMealType) as MealTypeProductTotals;
      break;
    case REPORT_SCOPE.SITE:
      tableData = cloneDeep((totalsBySite as any)[siteId]) as MealTypeProductTotals;
      break;
    case REPORT_SCOPE.LOCATION:
    case REPORT_SCOPE.LOCATION_GLOBAL:
      tableData = cloneDeep((totalsByPickupLocation as any)[pickupLocationId]) as MealTypeProductTotals;
      break;
  }

  if (tableData && menuTypeId && timeFrame === REPORT_TIME_FRAME.DAILY) {
    const totals = (tableData as any)[menuTypeId] as ProductTotals;
    tableData = { [menuTypeId]: totals };
  }

  // Show empty totals tables if the timeFrame is weekly
  // i.e. no lunch order still have lunch total container
  if (timeFrame === REPORT_TIME_FRAME.WEEKLY || timeFrame === REPORT_TIME_FRAME.MONTHLY) {
    if (tableData && menuTypes && Object.keys(menuTypes).length > Object.keys(tableData).length) {
      for (const mealType in menuTypes) {
        tableData[mealType] = tableData[mealType] || undefined;
      }
    }
  }

  // Need to filter out all the a la carte items into their own totals table.
  if (tableData && menuTypeId && tableData[menuTypeId]) {
    for (const productId in tableData[menuTypeId]) {
      if (products[productId] && products[productId].productCategoryId === ALACARTE_KEY) {
        aLaCarteProductTotals[productId] = tableData[menuTypeId][productId];
        delete tableData[menuTypeId][productId];
      }
    }
  }

  if (!mealsOrdered) {
    return null;
  }

  return (
    <div className="totals-container">
      {tableData && menuTypeId && tableData[menuTypeId] && (
        <TotalsTable
          key={menuTypeId}
          totals={tableData[menuTypeId]}
          products={products}
          name={menuTypes[menuTypeId]?.name}
          label={`Total ${menuTypes[menuTypeId]?.name} Orders`}
          showEmpty={
            (timeFrame === REPORT_TIME_FRAME.WEEKLY || timeFrame === REPORT_TIME_FRAME.MONTHLY) &&
            menuTypeId !== UNASSOCIATED_MEAL_TYPE_ID
          }
        />
      )}
      <TotalsTable
        totals={aLaCarteProductTotals}
        products={products}
        name={ALACARTE}
        label={`Total ${ALACARTE} Orders`}
        showEmpty={true}
      />
    </div>
  );
};

export default Totals;
