import React from 'react';
import { NotificationContext } from './NotificationContextProvider';

export class DeveloperTools extends React.Component {
  static contextType = NotificationContext;

  componentDidMount() {
    //@ts-ignore
    window.ooDevTools = this;
  }

  get addWarning() {
    const warningMessage = window.prompt('Enter a Message', '');

    this.context.addWarning({ message: warningMessage });

    return 'Warning Notification Added!';
  }

  get addError() {
    const errorMessage = window.prompt('Enter a Message', '');

    this.context.addError({ message: errorMessage });

    return 'Error Notification Added!';
  }

  get commands() {
    return 'Available Commands: addError, addWarning';
  }

  render() {
    return null;
  }
}
