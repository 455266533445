import * as Interfaces from 'Interfaces/Interfaces';
import { ToastOptions } from 'react-toastify';
import { InactiveStatuses, PrintLabelsStudentNameDisplayOrder, SiteScope } from './Enums';

// currency
export const USD_FORMAT = '$0,0.00';

// dates
export const DATE_FORMAT_YMD = 'YYYY-MM-DD';
export const DATE_FORMAT_DMY = 'DD-MM-YYYY';
export const DATE_FORMAT_DMD = 'ddd, MMM D';
export const DATE_FORMAT_DMDD = 'ddd, MMM DD';
export const DATE_FORMAT_DMDY = 'ddd, MMM D YYYY';
export const DATE_FORMAT_DMDDY = 'ddd, MMM DD, YYYY';
export const DATE_FORMAT_DD = 'D ddd';
export const DATE_FORMAT_USA = 'MM/DD/YYYY';
export const DATE_FORMAT_DATE_AND_TIME = `${DATE_FORMAT_USA} h:mm a`;
export const DATE_FORMAT_24_HOUR = `${DATE_FORMAT_YMD} hh:mm a`;

export const DATE_FNS_DATE_FORMAT_USA = 'MM/dd/yyyy';
export const DATE_FNS_DATE_FORMAT_YMD = 'yyyy-MM-dd';
export const DATE_FNS_FORMAT_DMDD = 'EEE, MMM dd';
export const DATE_FNS_DATE_AND_TIME_FORMAT_USA = `${DATE_FNS_DATE_FORMAT_USA} h:mm a`;

// numeral
export const NUMBER = '0,0';
export const NUMBER_WITH_DECIMAL = '0,0.00';

export const ALACARTE_KEY = 'ALACARTE';
export const FRUIT_KEY = 'FRUIT';
export const VEGETABLE_KEY = 'VEGETABLE';
export const GRAIN_KEY = 'GRAIN';
export const MILK_KEY = 'MILK';
export const ENTREE_KEY = 'ENTREE';

export const ALACARTE = 'A LA CARTE';
export const ENTREES = 'ENTREES';

export const ENTREE_PRODUCT_CATEGORY_ID = '5';
export const ALACARTE_PRODUCT_CATEGORY_ID = '6';

export const MOBILE_MEDIA_QUERY = '(max-width: 785px)';

export const STUDENT_NOT_FOUND_MESSAGE =
  'Student ID and last name do not match. Please refer to your official school transcript for the correct student ID and last name.';

export const HOMEROOM_UNASSIGNED = 'UNASSIGNED';

export const PICKUP_LOCATIONS: { [key: string]: Interfaces.PickupLocation } = {
  CLASSROOM: {
    id: 'CLASSROOM',
    name: 'Classroom',
    default: true,
  },
};

export const PRODUCT_CATEGORIES: { [key: string]: any } = {
  FRUIT: {
    id: 'FRUIT',
    name: 'Fruit',
  },
  VEGETABLE: {
    id: 'VEGETABLE',
    name: 'Vegetable',
  },
  GRAIN: {
    id: 'GRAIN',
    name: 'Grain',
  },
  MILK: {
    id: 'MILK',
    name: 'Milk',
  },
  ENTREE: {
    id: 'ENTREE',
    name: 'Entree',
  },
  ALACARTE: {
    id: 'ALACARTE',
    name: 'A la Carte',
  },
  CONDIMENT: {
    id: 'CONDIMENT',
    name: 'Condiment',
  },
};

export const PRODUCT_CATEGORIES_SORT_ORDER: { [key: string]: number } = {
  [PRODUCT_CATEGORIES.ENTREE.id]: 0,
  [PRODUCT_CATEGORIES.FRUIT.id]: 1,
  [PRODUCT_CATEGORIES.VEGETABLE.id]: 2,
  [PRODUCT_CATEGORIES.GRAIN.id]: 3,
  [PRODUCT_CATEGORIES.MILK.id]: 4,
  [PRODUCT_CATEGORIES.CONDIMENT.id]: 5,
  [PRODUCT_CATEGORIES.ALACARTE.id]: 6,
  UNKNOWN: 7,
};

export enum FOOD_GROUP_TYPES {
  FRUIT = 'FRUIT',
  VEGETABLE = 'VEGETABLE',
  GRAIN = 'GRAIN',
  MILK = 'MILK',
  CONDIMENT = 'CONDIMENT',
}

export const FOOD_GROUPS_MULTIPLE_ALLOWED = [
  FOOD_GROUP_TYPES.FRUIT,
  FOOD_GROUP_TYPES.VEGETABLE,
  FOOD_GROUP_TYPES.GRAIN,
  FOOD_GROUP_TYPES.CONDIMENT,
];
export const FOOD_GROUPS_OPTIONAL = [FOOD_GROUP_TYPES.MILK, FOOD_GROUP_TYPES.GRAIN, FOOD_GROUP_TYPES.CONDIMENT];
export const FOOD_GROUPS_REQUIRED: string[] = [];
export const FOOD_GROUPS_MULTIPLE_NOT_ALLOWED = [FOOD_GROUP_TYPES.MILK];

export const FOOD_GROUPS: { [key in FOOD_GROUP_TYPES]: { id: string; name: string } } = {
  [FOOD_GROUP_TYPES.FRUIT]: {
    id: 'FRUIT',
    name: 'Fruit',
  },
  [FOOD_GROUP_TYPES.VEGETABLE]: {
    id: 'VEGETABLE',
    name: 'Vegetable',
  },
  [FOOD_GROUP_TYPES.GRAIN]: {
    id: 'GRAIN',
    name: 'Grain',
  },
  [FOOD_GROUP_TYPES.MILK]: {
    id: 'MILK',
    name: 'Milk',
  },
  [FOOD_GROUP_TYPES.CONDIMENT]: {
    id: 'CONDIMENT',
    name: 'Condiment',
  },
};

export const REIMBURSABLE_MEAL_TYPES: { [key: string]: Interfaces.MenuType } = {
  FREE: {
    id: 'FREE',
    name: 'Free',
  },
  REDUCED: {
    id: 'REDUCED',
    name: 'Reduced',
  },
  PAID: {
    id: 'PAID',
    name: 'Paid',
  },
  ADULT: {
    id: 'ADULT',
    name: 'Adult',
  },
};

export const SITE_GROUP_TYPES: Interfaces.SiteGroupTypes = {
  [Interfaces.SITE_GROUP_TYPE_IDS.PRESCHOOL]: {
    id: Interfaces.SITE_GROUP_TYPE_IDS.PRESCHOOL,
    name: 'Preschool',
  },
  [Interfaces.SITE_GROUP_TYPE_IDS.ELEMENTARY_SCHOOL]: {
    id: Interfaces.SITE_GROUP_TYPE_IDS.ELEMENTARY_SCHOOL,
    name: 'Elementary School',
  },
  [Interfaces.SITE_GROUP_TYPE_IDS.INTERMEDIATE_SCHOOL]: {
    id: Interfaces.SITE_GROUP_TYPE_IDS.INTERMEDIATE_SCHOOL,
    name: 'Intermediate School',
  },
  [Interfaces.SITE_GROUP_TYPE_IDS.MIDDLE_SCHOOL]: {
    id: Interfaces.SITE_GROUP_TYPE_IDS.MIDDLE_SCHOOL,
    name: 'Middle School',
  },
  [Interfaces.SITE_GROUP_TYPE_IDS.JUNIOR_HIGH]: {
    id: Interfaces.SITE_GROUP_TYPE_IDS.JUNIOR_HIGH,
    name: 'Junior High',
  },
  [Interfaces.SITE_GROUP_TYPE_IDS.HIGH_SCHOOL]: {
    id: Interfaces.SITE_GROUP_TYPE_IDS.HIGH_SCHOOL,
    name: 'High School',
  },
  [Interfaces.SITE_GROUP_TYPE_IDS.EARLY_COLLEGE]: {
    id: Interfaces.SITE_GROUP_TYPE_IDS.EARLY_COLLEGE,
    name: 'Early College',
  },
  [Interfaces.SITE_GROUP_TYPE_IDS.K8]: {
    id: Interfaces.SITE_GROUP_TYPE_IDS.K8,
    name: 'K8',
  },
  [Interfaces.SITE_GROUP_TYPE_IDS.K12]: {
    id: Interfaces.SITE_GROUP_TYPE_IDS.K12,
    name: 'K12',
  },
  [Interfaces.SITE_GROUP_TYPE_IDS.CHARTER]: {
    id: Interfaces.SITE_GROUP_TYPE_IDS.CHARTER,
    name: 'Charter',
  },
  [Interfaces.SITE_GROUP_TYPE_IDS.ACADEMY]: {
    id: Interfaces.SITE_GROUP_TYPE_IDS.ACADEMY,
    name: 'Academy',
  },
};

export const SPECIAL_MEAL_ACCOMMODATIONS: { [key: string]: Interfaces.SpecialMealAccommodation } = {
  EGG: {
    id: 'EGG',
    name: 'Egg',
    icon: 'icon-egg',
  },
  FISH: {
    id: 'FISH',
    name: 'Fish',
    icon: 'icon-fish',
  },
  MILK: {
    id: 'MILK',
    name: 'Milk',
    icon: 'icon-dairy',
  },
  PEANUT: {
    id: 'PEANUT',
    name: 'Peanut',
    icon: 'icon-peanut',
  },
  SHELLFISH: {
    id: 'SHELLFISH',
    name: 'Shellfish',
    icon: 'icon-crab',
  },
  SOY: {
    id: 'SOY',
    name: 'Soy',
    icon: 'icon-soybean',
  },
  TREE_NUT: {
    id: 'TREE_NUT',
    name: 'Tree Nut',
    icon: 'icon-nut',
  },
  WHEAT: {
    id: 'WHEAT',
    name: 'Wheat',
    icon: 'icon-wheat',
  },
};

export enum ORDER_RANGE_OPTIONS {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export const ORDER_RANGES: { [key in ORDER_RANGE_OPTIONS]: { id: ORDER_RANGE_OPTIONS; name: string } } = {
  [ORDER_RANGE_OPTIONS.DAILY]: {
    id: ORDER_RANGE_OPTIONS.DAILY,
    name: 'Daily',
  },
  [ORDER_RANGE_OPTIONS.WEEKLY]: {
    id: ORDER_RANGE_OPTIONS.WEEKLY,
    name: 'Weekly',
  },
  [ORDER_RANGE_OPTIONS.MONTHLY]: {
    id: ORDER_RANGE_OPTIONS.MONTHLY,
    name: 'Monthly',
  },
};

export enum MAX_ORDER_RANGE_OPTIONS {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
}

export const MAX_ORDER_RANGES: { [key in MAX_ORDER_RANGE_OPTIONS]: { id: MAX_ORDER_RANGE_OPTIONS; name: string } } = {
  [MAX_ORDER_RANGE_OPTIONS.DAYS]: {
    id: MAX_ORDER_RANGE_OPTIONS.DAYS,
    name: 'Days',
  },
  [MAX_ORDER_RANGE_OPTIONS.WEEKS]: {
    id: MAX_ORDER_RANGE_OPTIONS.WEEKS,
    name: 'Weeks',
  },
  [MAX_ORDER_RANGE_OPTIONS.MONTHS]: {
    id: MAX_ORDER_RANGE_OPTIONS.MONTHS,
    name: 'Months',
  },
};

export enum MAX_ORDER_UP_TO_DAYS_OPTIONS {
  ADVANCE5DAY = 'ADVANCE5DAY',
  ADVANCE4DAY = 'ADVANCE4DAY',
  ADVANCE3DAY = 'ADVANCE3DAY',
  ADVANCE2DAY = 'ADVANCE2DAY',
  ADVANCE1DAY = 'ADVANCE1DAY',
}

export const MAX_ORDER_UP_TO_DAYS: { [key: string]: { id: string; name: string; days: number } } = {
  [MAX_ORDER_UP_TO_DAYS_OPTIONS.ADVANCE1DAY]: {
    id: MAX_ORDER_UP_TO_DAYS_OPTIONS.ADVANCE1DAY,
    name: 'one day in advance',
    days: 1,
  },
  [MAX_ORDER_UP_TO_DAYS_OPTIONS.ADVANCE2DAY]: {
    id: MAX_ORDER_UP_TO_DAYS_OPTIONS.ADVANCE2DAY,
    name: 'two days in advance',
    days: 2,
  },
  [MAX_ORDER_UP_TO_DAYS_OPTIONS.ADVANCE3DAY]: {
    id: MAX_ORDER_UP_TO_DAYS_OPTIONS.ADVANCE3DAY,
    name: 'three days in advance',
    days: 3,
  },
  [MAX_ORDER_UP_TO_DAYS_OPTIONS.ADVANCE4DAY]: {
    id: MAX_ORDER_UP_TO_DAYS_OPTIONS.ADVANCE4DAY,
    name: 'four days in advance',
    days: 4,
  },
  [MAX_ORDER_UP_TO_DAYS_OPTIONS.ADVANCE5DAY]: {
    id: MAX_ORDER_UP_TO_DAYS_OPTIONS.ADVANCE5DAY,
    name: 'five days in advance',
    days: 5,
  },
};

export enum MAX_ORDER_UP_TO_WEEKS_OPTIONS {
  ADVANCE3WEEK = 'ADVANCE3WEEK',
  ADVANCE2WEEK = 'ADVANCE2WEEK',
  ADVANCE1WEEK = 'ADVANCE1WEEK',
}

export const MAX_ORDER_UP_TO_WEEKS: { [key: string]: { id: string; name: string; weeks: number } } = {
  [MAX_ORDER_UP_TO_WEEKS_OPTIONS.ADVANCE1WEEK]: {
    id: MAX_ORDER_UP_TO_WEEKS_OPTIONS.ADVANCE1WEEK,
    name: 'one week in advance',
    weeks: 1,
  },
  [MAX_ORDER_UP_TO_WEEKS_OPTIONS.ADVANCE2WEEK]: {
    id: MAX_ORDER_UP_TO_WEEKS_OPTIONS.ADVANCE2WEEK,
    name: 'two weeks in advance',
    weeks: 2,
  },
  [MAX_ORDER_UP_TO_WEEKS_OPTIONS.ADVANCE3WEEK]: {
    id: MAX_ORDER_UP_TO_WEEKS_OPTIONS.ADVANCE3WEEK,
    name: 'three weeks in advance',
    weeks: 3,
  },
};

export enum MAX_ORDER_UP_TO_MONTHS_OPTIONS {
  ADVANCE6MONTH = 'ADVANCE6MONTH',
  ADVANCE5MONTH = 'ADVANCE5MONTH',
  ADVANCE4MONTH = 'ADVANCE4MONTH',
  ADVANCE3MONTH = 'ADVANCE3MONTH',
  ADVANCE2MONTH = 'ADVANCE2MONTH',
  ADVANCE1MONTH = 'ADVANCE1MONTH',
}

export const MAX_ORDER_UP_TO_MONTHS: { [key: string]: { id: string; name: string; months: number } } = {
  [MAX_ORDER_UP_TO_MONTHS_OPTIONS.ADVANCE1MONTH]: {
    id: MAX_ORDER_UP_TO_MONTHS_OPTIONS.ADVANCE1MONTH,
    name: 'one month in advance',
    months: 1,
  },
  [MAX_ORDER_UP_TO_MONTHS_OPTIONS.ADVANCE2MONTH]: {
    id: MAX_ORDER_UP_TO_MONTHS_OPTIONS.ADVANCE2MONTH,
    name: 'two months in advance',
    months: 2,
  },
  [MAX_ORDER_UP_TO_MONTHS_OPTIONS.ADVANCE3MONTH]: {
    id: MAX_ORDER_UP_TO_MONTHS_OPTIONS.ADVANCE3MONTH,
    name: 'three months in advance',
    months: 3,
  },
  [MAX_ORDER_UP_TO_MONTHS_OPTIONS.ADVANCE4MONTH]: {
    id: MAX_ORDER_UP_TO_MONTHS_OPTIONS.ADVANCE4MONTH,
    name: 'four months in advance',
    months: 4,
  },
  [MAX_ORDER_UP_TO_MONTHS_OPTIONS.ADVANCE5MONTH]: {
    id: MAX_ORDER_UP_TO_MONTHS_OPTIONS.ADVANCE5MONTH,
    name: 'five months in advance',
    months: 5,
  },
  [MAX_ORDER_UP_TO_MONTHS_OPTIONS.ADVANCE6MONTH]: {
    id: MAX_ORDER_UP_TO_MONTHS_OPTIONS.ADVANCE6MONTH,
    name: 'six months in advance',
    months: 6,
  },
};

export enum ORDER_DUE_BY_RULE_OPTIONS {
  WEEK = 'WEEK',
  ADVANCE5DAY = 'ADVANCE5DAY',
  ADVANCE4DAY = 'ADVANCE4DAY',
  ADVANCE3DAY = 'ADVANCE3DAY',
  ADVANCE2DAY = 'ADVANCE2DAY',
  DAY = 'DAY',
  SAME_DAY = 'SAME_DAY',
}

export const ORDER_DUE_BY_RULES: {
  [key in ORDER_DUE_BY_RULE_OPTIONS]: {
    id: ORDER_DUE_BY_RULE_OPTIONS;
    name: string;
    cutoffDays: number;
    ruleSuffix: string;
  };
} = {
  [ORDER_DUE_BY_RULE_OPTIONS.WEEK]: {
    id: ORDER_DUE_BY_RULE_OPTIONS.WEEK,
    name: 'One week in advance',
    cutoffDays: 7,
    ruleSuffix: 'one week in advance',
  },
  [ORDER_DUE_BY_RULE_OPTIONS.ADVANCE5DAY]: {
    id: ORDER_DUE_BY_RULE_OPTIONS.ADVANCE5DAY,
    name: 'Five days in advance',
    cutoffDays: 5,
    ruleSuffix: 'five days in advance',
  },
  [ORDER_DUE_BY_RULE_OPTIONS.ADVANCE4DAY]: {
    id: ORDER_DUE_BY_RULE_OPTIONS.ADVANCE4DAY,
    name: 'Four days in advance',
    cutoffDays: 4,
    ruleSuffix: 'four days in advance',
  },
  [ORDER_DUE_BY_RULE_OPTIONS.ADVANCE3DAY]: {
    id: ORDER_DUE_BY_RULE_OPTIONS.ADVANCE3DAY,
    name: 'Three days in advance',
    cutoffDays: 3,
    ruleSuffix: 'three days in advance',
  },
  [ORDER_DUE_BY_RULE_OPTIONS.ADVANCE2DAY]: {
    id: ORDER_DUE_BY_RULE_OPTIONS.ADVANCE2DAY,
    name: 'Two days in advance',
    cutoffDays: 2,
    ruleSuffix: 'two days in advance',
  },
  [ORDER_DUE_BY_RULE_OPTIONS.DAY]: {
    id: ORDER_DUE_BY_RULE_OPTIONS.DAY,
    name: 'One day in advance',
    cutoffDays: 1,
    ruleSuffix: 'one day in advance',
  },
  [ORDER_DUE_BY_RULE_OPTIONS.SAME_DAY]: {
    id: ORDER_DUE_BY_RULE_OPTIONS.SAME_DAY,
    name: 'Same day',
    cutoffDays: 0,
    ruleSuffix: 'on the day of',
  },
};

export const ORDER_CUT_OFF_DAYS: { [key: string]: any } = {
  '1': {
    id: '1',
    name: 'Monday',
  },
  '2': {
    id: '2',
    name: 'Tuesday',
  },
  '3': {
    id: '3',
    name: 'Wednesday',
  },
  '4': {
    id: '4',
    name: 'Thursday',
  },
  '5': {
    id: '5',
    name: 'Friday',
  },
};

export enum ORDER_DUE_BY_MONTH_OPTIONS {
  BEFORE_START = 'BEFORE_START',
}

export const ORDER_DUE_BY_MONTH: { [key in ORDER_DUE_BY_MONTH_OPTIONS]: any } = {
  [ORDER_DUE_BY_MONTH_OPTIONS.BEFORE_START]: {
    id: ORDER_DUE_BY_MONTH_OPTIONS.BEFORE_START,
    name: 'before start of month',
    ruleSuffix: 'before the start of',
  },
};

export const AM_PM = [
  { value: 'AM', text: 'AM' },
  { value: 'PM', text: 'PM' },
];

export const USER_REQUIRED_ATTRIBUTES: { [key: string]: string[] } = {
  teacher: ['siteId', 'homeroomId'],
  manager: ['siteId'],
};

export const PICKUP_LOCATION_RULES: { [key: string]: any } = {
  IndividualMeal: {
    id: 'IndividualMeal',
    name: 'Individual meal',
  },
  ByOrder: {
    id: 'ByOrder',
    name: 'By order',
  },
};

export const LabelStudentIdDisplayOptionsText: { [key in Interfaces.LabelStudentIdDisplayOptions]: string } = {
  [Interfaces.LabelStudentIdDisplayOptions.Show]: 'Include on labels',
  [Interfaces.LabelStudentIdDisplayOptions.Hide]: "Don't include on labels",
};

export const LabelAllergenOptionsDisplay: { [key in Interfaces.LabelAllergenOptions]: string } = {
  [Interfaces.LabelAllergenOptions.None]: "Don't include on labels",
  [Interfaces.LabelAllergenOptions.PlainText]: 'Include on labels',
  [Interfaces.LabelAllergenOptions.Circles]: 'Display as colored circles',
};

export enum ERROR_TYPE {
  STANDARD,
  UNKNOWN,
  PERMISSION,
  FAIL,
  SFTP,
  ORDER_SUBMISSION,
  ORDER_STUDENT_SUSPENDED,
  EDIT_NEWSFEED_NOT_FOUND,
  SITE_NOT_ENABLED_FOR_ORDERING,
}

export const GROUP_LABELS = {
  [SiteScope.SITE]: 'School Site Pickup Locations',
  [SiteScope.GLOBAL]: 'District Wide Pickup Locations',
  [`DEFAULT`]: 'Classroom Pickup Locations',
};

export enum SIS_TYPE {
  POWERSCHOOL = 'PowerSchool',
}

export const SYNTHESIS_SIS_LIST: { [key in SIS_TYPE]: { id: SIS_TYPE; name: string } } = {
  [SIS_TYPE.POWERSCHOOL]: {
    id: SIS_TYPE.POWERSCHOOL,
    name: 'PowerSchool',
  },
};

export enum EVENTS {
  ENTER = 'Enter',
}

export enum OutputFormat {
  KeyedJsonObject = 'object',
  Array = 'array',
}

export enum DateRangeMode {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  CUSTOM = 'CUSTOM',
}

export enum ToastType {
  Success = 'Success',
  Error = 'Error',
}

export const ToastConfig: { [key in ToastType]: ToastOptions } = {
  [ToastType.Success]: {
    position: 'bottom-right',
    autoClose: 5000,
    closeOnClick: true,
  },
  [ToastType.Error]: {
    position: 'bottom-right',
    autoClose: 5000,
    closeOnClick: true,
  },
};

export const BULK_ORDER_ITEM_LIMIT = 9999;
export const BULK_ORDER_ITEM_ID = 'BULK';

export const DATE_FN_FORMATS = {
  M_DD_YY_H_M: 'M-dd-yyyy hh:mm',
  M_DD_YYYY: 'M/dd/yyyy',
  YYYY_MM_DD: 'yyyy-MM-dd',
};

export enum FilterFieldType {
  select,
  radio,
  checkbox,
  dateRange,
  multiSelect,
}

export enum DateRangeErrorType {
  maxDateRange,
  toDatePriorToFromDate,
  noDateRange,
  invalidDate,
}

export const ACCEPTED_IMAGE_TYPES = ['.jpeg', '.png', '.gif', '.jpg'];

export enum DRAG_ITEM_TYPES {
  NEWSFEED_ROW = 'NEWSFEED_ROW',
  MENU_TYPE_ROW = 'MENU_TYPE_ROW',
}

export const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const INACTIVE_STATUSES = [InactiveStatuses.Withdrawn, InactiveStatuses.Inactive];

export const AllowOrderCommentsLabel = 'Allow comment box for order notes';

export const DoNotAllowOrderCommentsLabel = 'Do not allow comment box for order notes';

export const PendoAppName = 'Online Ordering';

export const PRINT_LABEL_SORT_OPTIONS = [
  { text: 'Pickup Location', value: 'pickupLocation.id' },
  { text: 'Date', value: 'date' },
  { text: 'Grade', value: 'student.grade' },
  { text: 'First Name', value: 'student.firstName' },
  { text: 'Last Name', value: 'student.lastName' },
  { text: 'Student Id', value: 'student.sisId' },
];

export const PRINT_LABEL_STUDENT_NAME_OPTIONS = [
  { text: 'First Name Last Name', value: PrintLabelsStudentNameDisplayOrder.FIRST_LAST },
  { text: 'Last Name, First Name', value: PrintLabelsStudentNameDisplayOrder.LAST_FIRST },
];

export const PRINT_LABEL_SORT_DEFAULT = ['date', 'pickupLocation.id', 'student.grade', 'student.lastName'];

export const EnableBulkOrderMode = 'Enable Bulk Order Mode';

export const DisableBulkOrderMode = 'Disable Bulk Order Mode';

export enum LogStatus {
  Success = 'Success',
  Failure = 'Failure',
  Warning = 'Warning',
}

export const UNASSOCIATED_MEAL_TYPE_ID = 'UNASSOCIATED_MEAL_TYPE_ID';
export const UNASSOCIATED_MEAL_TYPE_NAME = 'A La Carte Only';

export const UNASSOCIATED_MEAL_TYPE = { id: UNASSOCIATED_MEAL_TYPE_ID, name: UNASSOCIATED_MEAL_TYPE_NAME };

// Warnings
export const PROFILE_INCOMPLETE_WARNING = 'Profile Incomplete! Please update your information before continuing.';
export const MISSING_GRADE_PRICES_WARNING =
  'Prices have not been set for these grades. Orders for students in grades without set prices will not be processed.';
export const MISSING_SITE_GROUP_PRICES_WARNING =
  'Prices have not been set for these site groups. Orders for site groups without set prices will not be processed.';

export const SITE_GROUP_SORT_ORDER: Record<string, number> = {
  [Interfaces.SITE_GROUP_TYPE_IDS.PRESCHOOL]: 0,
  [Interfaces.SITE_GROUP_TYPE_IDS.ELEMENTARY_SCHOOL]: 1,
  [Interfaces.SITE_GROUP_TYPE_IDS.INTERMEDIATE_SCHOOL]: 2,
  [Interfaces.SITE_GROUP_TYPE_IDS.MIDDLE_SCHOOL]: 3,
  [Interfaces.SITE_GROUP_TYPE_IDS.HIGH_SCHOOL]: 4,
  [Interfaces.SITE_GROUP_TYPE_IDS.EARLY_COLLEGE]: 5,
  [Interfaces.SITE_GROUP_TYPE_IDS.K8]: 6,
  [Interfaces.SITE_GROUP_TYPE_IDS.K12]: 7,
  [Interfaces.SITE_GROUP_TYPE_IDS.CHARTER]: 8,
  [Interfaces.SITE_GROUP_TYPE_IDS.ACADEMY]: 9,
};

export enum NotificationType {
  missingPriceNotification = 'missingPriceNotification',
  orderCancellationNotification = 'orderCancellationNotification',
}
