import { ChevronLeft, ChevronRight, ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import { Paper } from '@material-ui/core';
import classNames from 'classnames';
import { DATE_FORMAT_DATE_AND_TIME } from 'Constants/Constants';
import { ConditionalWrapper } from 'Helpers/ConditionalWrapper';
import * as Interfaces from 'Interfaces/Interfaces';
import moment from 'moment';
import React, { FC, Fragment, useMemo } from 'react';
import {
  Column,
  FilterTypes,
  FilterValue,
  HeaderGroup,
  Row,
  TableOptions,
  useBlockLayout,
  useFilters,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
  useExpanded,
} from 'react-table';
import { TextFilter } from './Filters';
import './ReactTable.less';

const filterTypes: FilterTypes<FilterValue> = {
  textStartsWith: (rows: Array<Row>, id: string, filterValue: FilterValue) => {
    return rows.filter((row: Row) => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
        : true;
    });
  },
  dateAndTimeText: (rows: Array<Row>, id: string, filterValue: FilterValue) => {
    return rows.filter((row: Row) => {
      const rowValue = row.values[id];
      const date = moment(rowValue);
      const formattedDate = date.format(DATE_FORMAT_DATE_AND_TIME);

      return date.isValid() ? formattedDate.startsWith(String(filterValue).toLowerCase()) : true;
    });
  },
};

const defaultColumn: Partial<Column> = {
  Filter: TextFilter,
  disableFilters: true,
};

const ReactTable: FC<Interfaces.ReactTableProps> = ({
  columns,
  data,
  bottomLeftContainer,
  autoResetPage = false,
  showFooter = true,
  initialState = {},
  fetchData,
  manualPageCount,
  noDataText = 'No data available.',
  unitLabel,
  manualPageSize,
  className,
  disableSortRemove = true,
}) => {
  const tableOptions: TableOptions<object> = {
    columns,
    data,
    autoResetPage,
    initialState,
    manualPagination: !!fetchData,
    defaultColumn,
    filterTypes,
    disableSortRemove,
    paginateExpandedRows: false,
  };

  if (manualPageCount) {
    tableOptions.pageCount = manualPageCount;
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    rows,
    state: { pageIndex, pageSize },
  } = useTable(tableOptions, useFilters, useSortBy, useExpanded, usePagination, useBlockLayout, useResizeColumns);

  React.useEffect(() => {
    if (fetchData) {
      fetchData({ pageIndex, pageSize });
    }
  }, [fetchData, pageIndex, pageSize]);

  React.useEffect(() => {
    if (manualPageSize) {
      setPageSize(manualPageSize);
    }
  }, [setPageSize, manualPageSize]);

  const hasFilterableColumns = useMemo(
    () => headerGroups.some((headerGroup) => headerGroup.headers.some((column) => column.canFilter)),
    [headerGroups],
  );

  return (
    <>
      {unitLabel && (
        <div className="react-table-unit-label">
          Viewing {rows.length} {unitLabel}
        </div>
      )}
      <Paper className={classNames('react-table-container', className)}>
        {data.length === 0 && (
          <div
            className={classNames({
              'react-table__no-data-msg': !hasFilterableColumns,
              'react-table__no-data-msg-filterable': hasFilterableColumns,
            })}
          >
            {noDataText}
          </div>
        )}
        <div className="react-table-scroll-wrapper" style={{ overflowX: 'auto' }}>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: HeaderGroup, i: number) => {
                return (
                  <Fragment key={`${headerGroup.id}${i}`}>
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                      {headerGroup.headers.map((column) => {
                        return (
                          <th
                            className={(column as any).className}
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            style={{
                              maxWidth: `${column.width}px`,
                              minWidth: `${column.width}px`,
                              width: `${column.width}`,
                              cursor: !column.disableSortBy ? 'pointer' : 'default',
                            }}
                            key={column.id}
                          >
                            <ConditionalWrapper
                              condition={!column.disableSortBy}
                              wrapper={(children: React.ReactNode) => (
                                <button className="btn-reset header no-highlight">{children}</button>
                              )}
                            >
                              {column.render('Header')}
                              {!column.disableSortBy && (
                                <div className="arrows">
                                  {!column.isSorted && (
                                    <>
                                      <ArrowDropUp className={classNames('disabled', 'sort-up')} />
                                      <ArrowDropDown className={classNames('disabled', 'sort-down')} />
                                    </>
                                  )}

                                  {column.isSorted && (
                                    <>
                                      <ArrowDropUp
                                        className={classNames({ disabled: column.isSortedDesc, 'sort-up': true })}
                                      />
                                      <ArrowDropDown
                                        className={classNames({ disabled: !column.isSortedDesc, 'sort-down': true })}
                                      />
                                    </>
                                  )}
                                </div>
                              )}
                            </ConditionalWrapper>
                          </th>
                        );
                      })}
                    </tr>
                    {hasFilterableColumns && (
                      <tr {...headerGroup.getHeaderGroupProps()} key={`${headerGroup.id}-filter`}>
                        {headerGroup.headers.map((column) => (
                          <th
                            className={(column as any).className}
                            style={{
                              maxWidth: `${column.width}px`,
                              minWidth: `${column.width}px`,
                              width: `${column.width}`,
                            }}
                            key={column.id}
                          >
                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                          </th>
                        ))}
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps({
                            className: (cell.column as any).className,
                          })}
                          data-test-id={
                            (cell.column as any).dataTestId ? (cell.column as any).dataTestId(row) : undefined
                          }
                          style={{
                            maxWidth: `${cell.column.width}px`,
                            minWidth: `${cell.column.width}px`,
                            width: `${cell.column.width}`,
                            // indent row based on nesting (depth)
                            paddingLeft: `${row.depth > 0 ? 1.25 : 1}rem`,
                          }}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              {data.length === 0 && <tr className="no-data-row" />}
            </tbody>
          </table>
        </div>
        {showFooter && (
          <div className="pagination no-highlight">
            {bottomLeftContainer ? bottomLeftContainer : <div></div>}
            <div className="oo-row">
              <label className="sr-only" htmlFor="table-rows-per-page">
                Rows Per Page
              </label>
              {data.length > 0 && (
                <select
                  id="table-rows-per-page"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  onBlur={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Rows Per Page: {pageSize}
                    </option>
                  ))}
                </select>
              )}
              <div className="page-count">{data.length > 0 && `Page ${pageIndex + 1} of ${pageOptions.length}`}</div>
              <button
                disabled={!canPreviousPage}
                aria-label="Go to first page of results"
                className={classNames('arrow btn-reset first-page')}
                onClick={() => gotoPage(0)}
              >
                <ChevronLeft />
                <ChevronLeft />
              </button>
              <button
                disabled={!canPreviousPage}
                aria-label="Go to previous page of results"
                className={classNames('arrow btn-reset')}
                onClick={() => previousPage()}
              >
                <ChevronLeft />
              </button>
              <button
                disabled={!canNextPage}
                aria-label="Go to next page of results"
                className={classNames('arrow btn-reset')}
                onClick={() => nextPage()}
              >
                <ChevronRight />
              </button>
              <button
                disabled={!canNextPage}
                aria-label="Go to last page of results"
                className={classNames('arrow btn-reset last-page')}
                onClick={() => gotoPage(pageCount - 1)}
              >
                <ChevronRight />
                <ChevronRight />
              </button>
            </div>
          </div>
        )}
      </Paper>
    </>
  );
};

export default ReactTable;
