import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import Button from 'Components/Button/Button';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { isEqual, set } from 'lodash';
import { AxiosResponse } from 'axios';
import { endOfDay, startOfDay } from 'date-fns';
import { useAuth0, User } from '@auth0/auth0-react';
import * as CommonStateSelectors from 'State/selectors';
import * as CommonStateActions from 'State/actions';
import ReactTableV2 from 'Components/ReactTable/ReactTableV2/ReactTableV2';
import CloseIconButton from 'Components/Icons/CloseIconButton/CloseIconButton';
import UserInvite from 'Components/User/UserInvite';
import { ActionColumn } from 'Components/ReactTable/ActionColumn';
import { SelectField } from 'Components/Form/MaterialForm';
import { NotificationDialog } from 'Components/Dialogs/NotificationDialog';
import { Role, TokenClaims } from 'Auth/User';
import * as CommonSelectors from 'State/selectors';
import { useSites } from 'State/hooks';
import * as Helpers from 'Helpers/Helper';
import { useTableCheckboxSelection } from 'Helpers/hooks';
import * as Constants from 'Constants/Constants';
import { useApi } from 'Api/useApi';
import { AppState } from 'Interfaces/Interfaces';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import { SidebarLocations } from 'Constants/Enums';

import './UserManagement.less';

interface ManagedUser {
  blocked: boolean;
  admin: string;
  districtId: string;
  email: string;
  firstName: string;
  lastLoginTime: string;
  lastName: string;
  role: string;
  userId: string;
  userName: string;
  emailVerified?: boolean;
}

interface ManagedUserSearch extends Omit<Partial<ManagedUser>, 'lastLoginTime' | 'role'> {
  lastLoginTime?: { from: string; to: string };
  role?: string[];
}

interface UserSearch {
  filter?: ManagedUserSearch;
  search?: ManagedUserSearch;
}

enum InviteStatus {
  InProcess = 'IN_PROCESS',
  Complete = 'COMPLETE',
  Expired = 'EXPIRED',
  Retryable = 'RETRYABLE',
  Failed = 'FAILED',
  AccountExists = 'ACCOUNT_EXISTS',
}
interface InviteUser {
  email: string;
  firstName: string;
  lastName: string;
  sendTime: string;
  status: InviteStatus;
  totalSendCount?: number;
  statusLabel?: string;
}

const createSearchTermFilter = (searchValue: string, userSearch: UserSearch) => {
  const searchNoEndSpaces = searchValue.trimLeft().trimRight();
  const updatedSearchFilter = { ...userSearch };

  const firstLastNameSearch = getFirstNameLastNameSearch(searchNoEndSpaces);

  // if the search was formatted as a first name and last name, then filter on those fields only
  if (firstLastNameSearch && firstLastNameSearch.firstName && firstLastNameSearch.lastName) {
    updatedSearchFilter.filter = { ...userSearch.filter, ...firstLastNameSearch };
  } else {
    // apply search term to name, email, and role fields

    const startsWtihWildcard = `${searchNoEndSpaces}*`;
    updatedSearchFilter.search = {
      firstName: startsWtihWildcard,
      lastName: startsWtihWildcard,
      role: [startsWtihWildcard],
      // make email search a contains search to allow for search on just email domain
      email: `*${startsWtihWildcard}`,
    };
  }

  return updatedSearchFilter;
};

const getFirstNameLastNameSearch = (searchValue: string) => {
  // extract lastName and firstName if there is a comma in the search term
  if (searchValue.includes(',')) {
    const lastNamefirstName = searchValue.split(',').map((name) => name.replace(' ', ''));

    const lastName = lastNamefirstName[0] ? `${lastNamefirstName[0]}*` : '';
    const firstName = lastNamefirstName[1] ? `${lastNamefirstName[1]}*` : '';

    return { firstName, lastName };

    // extract firstName and lastName if there is a space within the search term
  } else if (searchValue.includes(' ')) {
    const firstNameLastName = searchValue.split(' ');

    return { firstName: `${firstNameLastName[0]}*`, lastName: `${firstNameLastName[1]}*` };
  }

  return undefined;
};

const UserManagement = () => {
  const apiRef = useRef(useApi());
  const [users, setUsers] = useState<ManagedUser[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [userPageCount, setUserPageCount] = useState<number>(0);
  const [invites, setInvites] = useState<InviteUser[]>([]);
  const [invitesFiltered, setInvitesFiltered] = useState<InviteUser[]>([]);
  const [changeRoleModalOpen, setChangeRoleModalOpen] = useState(false);
  const [userIdToChange, setUserIdToChange] = useState('0');
  const [currentUserRole, setCurrentUserRole] = useState('');

  const inviteUserModalOpen = useSelector((state: AppState) =>
    CommonStateSelectors.getSidebarOpen(state, SidebarLocations.USER_INVITE_SIDE_BAR),
  );
  const dispatch = useDispatch();

  const setInviteUserModalOpen = (isOpen: boolean) => {
    dispatch(CommonStateActions.setSidebarOpen(isOpen, SidebarLocations.USER_INVITE_SIDE_BAR));
  };

  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [deactivateLoading, setDeactivateLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showDeactivateUserConfirmation, setShowDeactivateUserConfirmation] = useState(false);
  const [showReactivateUserConfirmation, setShowReactivateUserConfirmation] = useState(false);
  const [showDeactivateAllConfirmation, setShowDeactivateAllConfirmation] = useState(false);
  const [showUsersTable, setShowUsersTable] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const { selectedIds, handleAllCheckboxSelection, handleCheckboxSelection } = useTableCheckboxSelection();

  const { user } = useAuth0();
  const userId = user?.sub ?? '';
  const districtId = TokenClaims.getDistrictId(user);
  const districts = useSelector(CommonSelectors.getDistricts);
  const sitesLoading = useSites(districtId);
  const sites = useSelector(CommonSelectors.getSites);
  const district = districts[districtId];
  const timezone = district?.timezone ?? 'America/New_York';
  const districtName = district?.name;
  const userIds = useMemo(
    () =>
      users?.reduce((acc, u) => {
        if (u.userId !== userId) {
          return { ...acc, [u.userId]: true };
        }
        return acc;
      }, {} as { [key: string]: any }),
    [userId, users],
  );
  const selectedIdsLength = useMemo(() => Object.values(selectedIds).length, [selectedIds]);
  const allUsersChecked = useMemo(() => isEqual(userIds, selectedIds), [selectedIds, userIds]);

  const getUsers = useCallback(
    async (fetchDataParameters = { limit: 10, from: 0 }) => {
      if (!Object.keys(fetchDataParameters.userSearch?.filter ?? {}).find((key) => key === 'role')) {
        // if no role is specified, then add a filter for all roles to filter out inactive/incomplete accounts
        set(fetchDataParameters ?? {}, 'userSearch.filter.role', ['director', 'teacher', 'parent', 'manager']);
      }
      try {
        setLoading(true);
        const { current: api } = apiRef;
        const response: AxiosResponse = await api.post(`utility/districts/${districtId}/searchUsers`, {
          ...fetchDataParameters,
        });

        const newUsers = response.data?.users;

        setUsers(newUsers ?? []);
        setTotalUsers(response.data?.total);
        setUserPageCount(Math.ceil((response.data?.total ?? 0) / response.data?.limit ?? 1));
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [districtId],
  );

  const getInvites = useCallback(async () => {
    const { current: api } = apiRef;
    const results: any = await api.get(`/utility/districts/${districtId}/invites`);
    const invitations: InviteUser[] = Object.values(results.data.items);
    invitations.forEach((invitation) => {
      switch (invitation.status) {
        case InviteStatus.Complete:
        case InviteStatus.Expired:
          invitation.statusLabel = 'Invite Sent';
          break;
        case InviteStatus.AccountExists:
          invitation.statusLabel = 'Existing Account Found';
          break;
        case InviteStatus.Retryable:
        case InviteStatus.InProcess:
          invitation.statusLabel = 'Invite Queued';
          break;
        case InviteStatus.Failed:
          invitation.statusLabel = 'Failed';
          break;
      }

      invitation.firstName = invitation.firstName?.toLowerCase();
      invitation.lastName = invitation.lastName?.toLowerCase();
      invitation.email = invitation.email?.toLowerCase();
    });

    setInvites(invitations);
    setInvitesFiltered(
      invitations.filter(
        (i) =>
          i.status === InviteStatus.Failed || i.status === InviteStatus.Expired || i.status === InviteStatus.Complete,
      ),
    );
  }, [districtId]);

  useEffect(() => {
    async function loadInvites() {
      setLoading(true);
      await getInvites();
      setLoading(false);
    }

    if (users.length > 0) {
      loadInvites();
    }
  }, [getInvites, users]);

  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });

  const inviteUser = async () => {
    setInviteUserModalOpen(true);
  };

  const bulkInvite = async (data: any) => {
    const { current: api } = apiRef;
    setLoading(true);
    const results: any = await api.post(`/utility/districts/${districtId}/invites`, invitesFiltered);
    setLoading(false);
    setInviteUserModalOpen(false);
    setSelectAll(false);
    getInvites();
    if (results.status === 202) {
      toast.info(`Invites have been queued for all selected invitations`, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
    return;
  };

  const handleBulkDeactivate = useCallback(async () => {
    const { current: api } = apiRef;
    setDeactivateLoading(true);
    let data = {
      blocked: true,
    };
    const allPromises = Object.keys(selectedIds).map((selectedUserId) =>
      api.patch(`/utility/users/${selectedUserId}`, data),
    );
    const [results] = await Promise.all(allPromises);
    if (results.status === 204) {
      toast.info(`Users have been suspended.`, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
      });
      await getUsers();
    }
    setDeactivateLoading(false);
    setShowDeactivateAllConfirmation(false);
  }, [getUsers, selectedIds]);

  const reinviteUser = async (user: User) => {
    const { current: api } = apiRef;
    const invite = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      homeroomId: user.homeroomId ?? '',
      siteId: user.siteId ?? '',
      districtId: districtId,
      districtName: districtName,
    };
    const invitations = [invite];
    setLoading(true);
    try {
      const results: any = await api.post(`/utility/districts/${districtId}/invites`, invitations);
      if (results.status === 202) {
        toast.info(`Invite has been queued for ${invite.email}`, {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
        });
        getInvites();
      }
    } finally {
      setLoading(false);
    }
  };

  const userLookup = useCallback(
    (id: string, property: string) => {
      const user = Object.values(users).find((i) => i.userId === id);
      return user ? user[property as keyof typeof UserManagement] : null;
    },
    [users],
  );

  const changeRole = async (id: string, role: string) => {
    setChangeRoleModalOpen(true);
    setUserIdToChange(id);
    setCurrentUserRole(role.toLowerCase());
  };

  const changeRoleSave = async (id: string, role: string) => {
    const { current: api } = apiRef;
    setLoading(true);
    let data = {
      role: role,
    };
    const results: any = await api.patch(`/utility/users/${id}`, data);
    setLoading(false);
    setUserIdToChange('');
    setCurrentUserRole('');
    setChangeRoleModalOpen(false);
    if (results.status === 204) {
      toast.info(`User role for ${userLookup(id, 'email')} has been changed`, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
    await getUsers();
  };

  const suspendUser = async (id: string) => {
    setShowDeactivateUserConfirmation(true);
    setUserIdToChange(id);
  };

  const reactivateUser = async (id: string) => {
    setShowReactivateUserConfirmation(true);
    setUserIdToChange(id);
  };

  const deleteUser = async (id: string) => {
    setShowConfirmation(true);
    setUserIdToChange(id);
  };

  const suspendUserSave = async () => {
    const { current: api } = apiRef;
    setActionLoading(true);
    let data = {
      blocked: true,
    };
    const results: any = await api.patch(`utility/users/${userIdToChange}`, data);
    if (results.status === 204) {
      toast.info(`User ${userLookup(userIdToChange, 'email')} has been suspended.`, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
    setActionLoading(false);
    setShowDeactivateUserConfirmation(false);
    await getUsers();
  };

  const reactivateUserSave = async () => {
    const { current: api } = apiRef;
    setActionLoading(true);
    let data = {
      blocked: false,
    };
    const results: any = await api.patch(`/utility/users/${userIdToChange}`, data);
    if (results.status === 204) {
      toast.info(`User ${userLookup(userIdToChange, 'email')} has been reactivated successfully.`, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
    setActionLoading(false);
    setShowReactivateUserConfirmation(false);
    await getUsers();
  };
  const deleteUserSave = async () => {
    const { current: api } = apiRef;
    setActionLoading(true);
    const results: any = await api.delete(`/utility/users/${userIdToChange}`);
    if (results.status === 204) {
      toast.info(`User ${userLookup(userIdToChange, 'email')} has been deleted successfully.`, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
    setActionLoading(false);
    setShowConfirmation(false);
    await getUsers();
  };

  const fetchUsersFromTableParams = React.useCallback(
    (
      paging: { pageSize: number; pageIndex: number },
      activeSort: object,
      activeFilters: any,
      search: string | undefined,
    ) => {
      async function fetchData(
        tablePaging: { pageSize: number; pageIndex: number },
        tableSort: any,
        tableFilters: any,
        searchValue: string | undefined,
      ) {
        let userSearch: UserSearch = { filter: {} };

        const sort = {};
        if (activeSort) {
          set(sort, tableSort.id, tableSort.desc ? 'desc' : 'asc');
        }

        Object.keys(tableFilters).forEach((key: string) => {
          let value = tableFilters[key].value;
          switch (tableFilters[key].columnAccessor) {
            case 'role':
              value = value.map((role: string) => role.toLowerCase());
              break;
            case 'lastLoginTime':
              value = {
                from: startOfDay(value.fromDate).toISOString(),
                to: endOfDay(value.toDate).toISOString(),
              };
              break;
            case 'blocked':
              value = value.every((val: string) => val === 'Blocked') ? true : false;
          }

          set(userSearch, `filter.${tableFilters[key].columnAccessor}`, value);
        });

        if (searchValue) {
          userSearch = createSearchTermFilter(searchValue, userSearch);
        }

        const from = tablePaging.pageIndex * tablePaging.pageSize;
        const limit = tablePaging.pageSize ?? 10;

        await getUsers({ userSearch, from, limit, sort });
      }
      fetchData(paging, activeSort, activeFilters, search);
    },
    [getUsers],
  );

  let columns = [
    {
      accessor: 'e',
      width: 45,
      disableSortBy: true,
      Header: (data: any) => (
        <Checkbox
          className="select-all-invites"
          onChange={() =>
            handleAllCheckboxSelection(data.sortedRows.length !== selectedIdsLength, data.sortedRows, 'userId')
          }
          checked={allUsersChecked}
          indeterminate={selectedIdsLength > 0 && !allUsersChecked}
          data-test-id={'selectAllUsersOrInvitesCheckbox'}
        />
      ),
      Cell: (data: any) => {
        if (data.row.original.userId === userId) {
          return null;
        }
        return (
          <Checkbox
            defaultChecked={selectedIds[data.row.original.userId]}
            key={data.row.original.userId}
            onClick={(e) => {
              e.preventDefault();
              handleCheckboxSelection(data.row.original.userId, e.shiftKey, data.sortedRows, 'userId');
            }}
          />
        );
      },
      dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.selectUserOrInvitecheckBox`,
    },
    {
      Header: 'LAST NAME',
      accessor: 'lastName',
      width: 250,
      disableSortBy: false,
      disableFilters: false,
      dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.lastNameLbl`,
    },
    {
      Header: 'FIRST NAME',
      accessor: 'firstName',
      width: 250,
      disableSortBy: false,
      disableFilters: false,
      dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.firstNameLbl`,
    },
    {
      Header: 'EMAIL',
      accessor: 'email',
      width: 300,
      disableSortBy: false,
      disableFilters: false,
      dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.emailLbl`,
    },
    {
      Header: 'ROLE',
      accessor: 'role',
      width: 250,
      disableSortBy: false,
      disableFilters: false,
      filterField: {
        fieldName: 'Role',
        fieldType: Constants.FilterFieldType.multiSelect,
        staticOptions: [
          { value: 'teacher', text: 'teacher' },
          { value: 'parent', text: 'parent' },
          { value: 'manager', text: 'manager' },
          { value: 'director', text: 'director' },
        ],
        ordinal: 2,
      },
      dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.roleLbl`,
    },
    {
      Header: 'BLOCKED',
      accessor: 'blocked',
      Cell: (data: any) => (data.row.original.blocked ? 'Y' : ''),
      width: 40,
      disableSortBy: true,
      disableFilters: false,
      filterField: {
        fieldName: 'Blocked',
        fieldType: Constants.FilterFieldType.checkbox,
        ordinal: 3,
        valueFieldAccesssor: 'blocked',
        staticOptions: ['Blocked', 'Active'],
        booleanField: true,
        booleanValueMap: { truthyValue: 'Blocked', falsyValue: 'Active' },
      },
      dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.blockedLbl`,
    },
    {
      Header: 'LAST LOGIN',
      accessor: 'lastLoginTime',
      sortType: (rowA: any, rowB: any) =>
        Helpers.sortIsoDateString(rowA.original.lastLoginTime, rowB.original.lastLoginTime),
      Cell: (data: any) => {
        return data.row.original.lastLoginTime
          ? moment(data.row.original.lastLoginTime).tz(timezone).format(Constants.DATE_FORMAT_DATE_AND_TIME)
          : '';
      },
      width: 300,
      disableSortBy: false,
      disableFilters: false,
      dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.lastLoginTimeLbl`,
      filterField: {
        fieldName: 'Last Login',
        fieldType: Constants.FilterFieldType.dateRange,
        ordinal: 1,
        dateRangeOptions: {
          fromDateLabel: 'From',
          toDateLabel: 'To',
          maxDayRange: 365,
        },
      },
    },
    {
      Header: '',
      accessor: 'userId',
      width: 35,
      disableSortBy: true,
      dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.actionsMenuBtn`,
      Cell: (data: any) => {
        if (data.row.original.userId === userId) {
          return null;
        }
        return (
          <ActionColumn id={`actions-for-${data.row.original.userId}`}>
            <MenuItem
              id={`actions-for-${data.row.original.userId}`}
              className="select-field__menu-option"
              onClick={() => changeRole(data.row.original.userId, data.row.original.role)}
              data-test-id={`${data.row.original.firstName} ${data.row.original.lastName}.changeRoleBtn`}
            >
              Change Role
            </MenuItem>
            {!data.row.original.blocked && (
              <MenuItem
                id={`actions-for-${data.row.original.userId}`}
                className="select-field__menu-option"
                onClick={() => suspendUser(data.row.original.userId)}
                data-test-id={`${data.row.original.firstName} ${data.row.original.lastName}.suspendUserBtn`}
              >
                Suspend User
              </MenuItem>
            )}
            {data.row.original.blocked && (
              <MenuItem
                id={`actions-for-${data.row.original.userId}`}
                className="select-field__menu-option"
                onClick={() => reactivateUser(data.row.original.userId)}
                data-test-id={`${data.row.original.firstName} ${data.row.original.lastName}.reactivateUserBtn`}
              >
                Reactivate User
              </MenuItem>
            )}
            <MenuItem
              id={`actions-for-${data.row.original.userId}`}
              className="select-field__menu-option"
              onClick={() => deleteUser(data.row.original.userId)}
              data-test-id={`${data.row.original.firstName} ${data.row.original.lastName}.removeUserBtn`}
            >
              Remove User
            </MenuItem>
          </ActionColumn>
        );
      },
    },
  ];
  const initialState = {
    disableSortRemove: true,
    disableMultiRemove: true,
    sortBy: [{ id: 'lastName', desc: false }],
  };

  let columnsInvites = [
    {
      accessor: 'e',
      width: '40',
      disableSortBy: true,
      Header: (
        <input
          type="checkbox"
          className="select-all-invites"
          onChange={() => setSelectAll(!selectAll)}
          checked={selectAll}
        />
      ),
      Cell: (row: any) => {
        return <input type="checkbox" defaultChecked={selectAll} disabled />;
      },
    },
    {
      Header: 'LAST NAME',
      accessor: 'lastName',
      width: 250,
      disableSortBy: false,
      disableFilters: false,
      filter: 'textStartsWith',
      dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.lastNameLbl`,
    },
    {
      Header: 'FIRST NAME',
      accessor: 'firstName',
      width: 250,
      disableSortBy: false,
      disableFilters: false,
      filter: 'textStartsWith',
      dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.firstNameLbl`,
    },
    {
      Header: 'EMAIL',
      accessor: 'email',
      width: 300,
      disableSortBy: false,
      disableFilters: false,
      dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.emailLbl`,
    },
    {
      Header: 'STATUS',
      accessor: 'statusLabel',
      width: 250,
      disableSortBy: false,
      disableFilters: false,
      filter: 'textStartsWith',
      filterField: {
        fieldName: 'Status',
        fieldType: Constants.FilterFieldType.multiSelect,
        ordinal: 1,
      },
      dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.statusLabelLbl`,
    },
    {
      Header: 'INVITED',
      accessor: 'sendTime',
      sortType: (rowA: any, rowB: any) => Helpers.sortIsoDateString(rowA.original.sendTime, rowB.original.sendTime),
      Cell: (data: any) => {
        return moment(data.row.original.sendTime).tz(timezone).format(Constants.DATE_FORMAT_DATE_AND_TIME);
      },
      width: 250,
      disableSortBy: false,
      disableFilters: false,
      filterField: {
        fieldName: 'Invited',
        fieldType: Constants.FilterFieldType.dateRange,
        ordinal: 4,
        dateRangeOptions: {
          fromDateLabel: 'From',
          toDateLabel: 'To',
          maxDayRange: 365,
        },
      },
      dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.sendTimeLbl`,
    },
    {
      Header: '# INVITES',
      accessor: 'totalSendCount',
      width: 50,
      disableSortBy: false,
      disableFilters: false,
      dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.totalSendCountLbl`,
    },
    {
      Header: 'HOMEROOM ID',
      accessor: 'homeroomId',
      width: 250,
      disableSortBy: false,
      disableFilters: false,
      filterField: {
        fieldName: 'Homeroom ID',
        fieldType: Constants.FilterFieldType.multiSelect,
        ordinal: 2,
      },
      dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.homeroomIdLbl`,
    },
    {
      Header: 'SITE',
      accessor: 'siteId',
      width: 250,
      disableSortBy: false,
      disableFilters: false,
      filterField: {
        fieldName: 'Site ID',
        fieldType: Constants.FilterFieldType.multiSelect,
        ordinal: 3,
      },
      Cell: (props: any) => {
        return sites[props?.row?.original?.siteId]?.name ?? '';
      },
      dataTestId: (row: any) => `${row.original.firstName} ${row.original.lastName}.siteIdLbl`,
    },
    {
      Header: '',
      accessor: 'userId',
      width: 35,
      disableSortBy: true,
      Cell: (data: any) => {
        return (
          <ActionColumn id={`actions-for-${data.row.original.userId}`}>
            {data.row.original.emailVerified ? (
              <MenuItem className="select-field__menu-option">Account Exists</MenuItem>
            ) : (
              <MenuItem
                className="select-field__menu-option"
                id={`actions-for-${data.row.original.userId}`}
                onClick={() => reinviteUser(data.row.original)}
              >
                Reinvite User
              </MenuItem>
            )}
          </ActionColumn>
        );
      },
    },
  ];
  const initialStateInvites = {
    sortBy: [{ id: 'sendTime', desc: true }],
  };

  return (
    <React.Fragment>
      <div className={classNames('user-management-container', { 'user-management-container-mobile': isMobile })}>
        <SubHeader
          title="User Management"
          rightComponent={
            <>
              <Button color="primary" variant="text" href="/import-data" data-test-id="bulkUploadBtn">
                BULK UPLOAD
              </Button>
              <Button color="primary" className="invite-btn" onClick={inviteUser} data-test-id="inviteBtn">
                + INVITE USER
              </Button>
            </>
          }
        />
        <div className="view-picker-container">
          <button
            onClick={() => setShowUsersTable(true)}
            className={classNames('btn-reset', { selected: showUsersTable })}
            id="users-tab-selector"
            data-test-id="usersTabBtn"
          >
            Users
          </button>
          <button
            onClick={() => setShowUsersTable(false)}
            className={classNames('btn-reset', { selected: !showUsersTable })}
            id="invites-tab-selector"
            data-test-id="invitesTabBtn"
          >
            Invites
          </button>
        </div>
        <div className="table-filters">
          <div className="left">
            {selectedIdsLength > 0 && showUsersTable && (
              <button onClick={() => setShowDeactivateAllConfirmation(true)}>
                Deactivate all {selectedIdsLength} users
              </button>
            )}
            {selectAll && !showUsersTable && (
              <button onClick={(data) => bulkInvite(data)}>Reinvite all {invitesFiltered.length} users</button>
            )}
          </div>
        </div>

        <div className="body">
          {showUsersTable && (
            <ReactTableV2
              initialState={initialState}
              columns={columns}
              data={users}
              loading={loading}
              showFooter={true}
              manualPageCount={userPageCount}
              fetchData={fetchUsersFromTableParams}
              unitLabel="Users"
              manualSearch={true}
              totalRows={totalUsers}
            />
          )}
          {!showUsersTable && (
            <ReactTableV2
              initialState={initialStateInvites}
              columns={columnsInvites}
              data={invites}
              showFooter={true}
              loading={sitesLoading}
              unitLabel="invites"
            />
          )}
        </div>
        <NotificationDialog
          title={`Suspend User ${userLookup(userIdToChange, 'email')}?`}
          message={`Are you sure you want to suspend ${userLookup(userIdToChange, 'email')}?`}
          showDialog={showDeactivateUserConfirmation}
          showCancel={true}
          cancelButtonText="CANCEL"
          okButtonText="Suspend"
          onOkClick={suspendUserSave}
          onCancelClick={() => setShowDeactivateUserConfirmation(false)}
          dialogType="delete"
          okIcon="delete"
          loading={actionLoading}
        />
        <NotificationDialog
          title={`Reactivate User ${userLookup(userIdToChange, 'email')}?`}
          message={`Are you sure you want to reactivate ${userLookup(userIdToChange, 'email')}?`}
          showDialog={showReactivateUserConfirmation}
          showCancel={true}
          cancelButtonText="CANCEL"
          okButtonText="Reactivate"
          onOkClick={reactivateUserSave}
          onCancelClick={() => setShowReactivateUserConfirmation(false)}
          dialogType="info"
          okIcon="checkmark"
          loading={actionLoading}
        />
        <NotificationDialog
          title={`Delete User ${userLookup(userIdToChange, 'email')}?`}
          message={`Are you sure you want to delete user ${userLookup(
            userIdToChange,
            'email',
          )}? WARNING: This action cannot be undone.`}
          showDialog={showConfirmation}
          showCancel={true}
          cancelButtonText="CANCEL"
          okButtonText="DELETE"
          onOkClick={deleteUserSave}
          onCancelClick={() => setShowConfirmation(false)}
          dialogType="delete"
          okIcon="delete"
          loading={actionLoading}
        />
        <NotificationDialog
          title={`Deactivate User Accounts?`}
          message={`Are you sure you want to deactivate ${selectedIdsLength} users that have active accounts?`}
          showDialog={showDeactivateAllConfirmation}
          showCancel={true}
          cancelButtonText="CANCEL"
          okButtonText="Deactivate Users"
          onOkClick={handleBulkDeactivate}
          onCancelClick={() => setShowDeactivateAllConfirmation(false)}
          dialogType="delete"
          okIcon="delete"
          loading={deactivateLoading}
        />
        <Dialog open={changeRoleModalOpen} className="linq-dialog" id="user-management-modal-content" fullWidth={true}>
          <DialogTitle disableTypography={true} className="linq-dialog__header d-flex align-items-center">
            <h3 className="linq-heading">Change Role for {userLookup(userIdToChange, 'email')}</h3>
            <CloseIconButton onClick={() => setChangeRoleModalOpen(false)} />
          </DialogTitle>
          <DialogContent className="linq-dialog__content">
            <SelectField
              id="role"
              name="role"
              value={currentUserRole}
              fieldLabel="User Role"
              options={Object.values(Role)
                .filter((r) => r !== Role.PARENT)
                .map((role) => {
                  return { text: Helpers.toTitleCase(role), value: role.toLowerCase() };
                })}
              onChange={(e) => {
                setCurrentUserRole(e.target.value);
              }}
              fullWidth={true}
            />
          </DialogContent>
          <DialogActions className="linq-dialog__actions">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setChangeRoleModalOpen(false)}
              data-test-id="cancelBtn"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={loading}
              loading={loading}
              onClick={() => changeRoleSave(userIdToChange, currentUserRole)}
              variant="contained"
              color="primary"
              data-test-id="saveBtn"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <UserInvite
          inviteUserModalOpen={inviteUserModalOpen}
          setInviteUserModalOpen={setInviteUserModalOpen}
          districtId={districtId}
          districtName={districtName}
          users={users}
          getInvites={getInvites}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
    </React.Fragment>
  );
};

export default UserManagement;
