import { useAuth0 } from '@auth0/auth0-react';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import { TokenClaims } from 'Auth/User';
import classNames from 'classnames';
import Button from 'Components/Button/Button';
import Card from 'Components/Card/Card';
import { useMenuTypePickerActions } from 'Components/MenuTypePicker/state/actions';
import * as MenuTypePickerSelectors from 'Components/MenuTypePicker/state/selectors';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import * as Constants from 'Constants/Constants';
import { SidebarLocations } from 'Constants/Enums';
import * as Helper from 'Helpers/Helper';
import { AppState, Product } from 'Interfaces/Interfaces';
import { useALaCarteActions } from 'Pages/ALaCarte/state/actions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import * as CommonStateActions from 'State/actions';
import * as CommonStateSelectors from 'State/selectors';
import './ALaCarte.less';
import ALaCarteSidebar from './ALaCarteSidebar';
import * as ALaCarteSelectors from './state/selectors';

const ALaCarte = () => {
  const dispatch = useDispatch();
  const sidebarOpen = useSelector((state: AppState) =>
    CommonStateSelectors.getSidebarOpen(state, SidebarLocations.A_LA_CARTE_SIDE_BAR),
  );
  const setSidebarOpen = (isOpen: boolean) => {
    dispatch(CommonStateActions.setSidebarOpen(isOpen, SidebarLocations.A_LA_CARTE_SIDE_BAR));
  };
  const [loading, setLoading] = useState(false);
  const [editItem, setEditItem] = useState<string>();
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);
  const products = useSelector(ALaCarteSelectors.getProducts);
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });
  const item = !products ? undefined : Object.values(products).find((e: Product) => e.id === editItem);
  const menuTypes = useSelector(MenuTypePickerSelectors.getMenuTypes);
  const aLaCarteActions = useALaCarteActions();
  const menuTypePickerActions = useMenuTypePickerActions();

  useEffect(() => {
    async function init() {
      setLoading(true);
      const promises = [
        aLaCarteActions.getProducts(districtId),
        menuTypePickerActions.getDistrictMenuTypes(districtId),
      ];
      await Promise.all(promises);
      setLoading(false);
    }
    init();
  }, [dispatch, districtId, aLaCarteActions, menuTypePickerActions]);

  const editALaCarteItem = (id: string) => {
    setEditItem(id);
    setSidebarOpen(true);
  };
  const createALaCarteItem = (event: any) => {
    setEditItem(undefined);
    setSidebarOpen(true);
  };

  return (
    <React.Fragment>
      <div className={classNames('a-la-carte-container', { 'a-la-carte-container-mobile': isMobile })}>
        <SubHeader
          title={'A la Carte'}
          rightComponent={
            <Button color="primary" onClick={(event) => createALaCarteItem(event)} data-test-id="addNewALaCarteItemBtn">
              + ADD NEW A LA CARTE ITEM
            </Button>
          }
        />
        {products && !loading && (
          <>
            <div className="a-la-carte__content">
              {Object.values(products).map((a: Product) => (
                <Card
                  key={a.id}
                  onEditClick={() => editALaCarteItem(a.id)}
                  editAriaLabel="Edit A la Carte Item"
                  title={a.name}
                  description={a.description}
                >
                  <div className="oo-row edges">
                    <div className="product-details">
                      <div className="product-details__image">
                        {a.imageUrl ? (
                          <img className="product-image" src={a.imageUrl} title={a.description} alt={`${a.name}`} />
                        ) : (
                          <div className="product-image-placeholder">
                            <FastfoodIcon />
                          </div>
                        )}
                      </div>
                    </div>
                    {a.prices && (
                      <div className="settings">
                        {Object.values(a.prices)
                          .sort((priceA: any, priceB: any) => {
                            const siteGroupIndexA = Object.keys(Constants.SITE_GROUP_TYPES).indexOf(
                              priceA.siteGroupTypeId,
                            );
                            const siteGroupIndexB = Object.keys(Constants.SITE_GROUP_TYPES).indexOf(
                              priceB.siteGroupTypeId,
                            );

                            return siteGroupIndexA - siteGroupIndexB;
                          })
                          .map((p: any, index: number) => (
                            <div
                              className="setting"
                              key={index}
                              hidden={!a.siteGroupTypeIds?.includes(p.siteGroupTypeId)}
                            >
                              <h6 className="linq-card__h6">{Helper.toTitleCase(p.siteGroupTypeId)}</h6>
                              <div
                                className="setting-value"
                                data-test-id={`${a.description}.${Helper.toTitleCase(p.siteGroupTypeId)}.priceLbl`}
                              >
                                ${typeof p.price === 'number' ? p.price.toFixed(2) : p.price}
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                    <div className="card-right-side">
                      {Object.keys(menuTypes).length > 0 && (
                        <div className="setting">
                          <h6 className="linq-card__h6">Menu</h6>
                          <div className="setting-value" data-test-id={`${a.description}.menuLbl`}>
                            {a.mealTypeIds
                              ?.map((menuTypeId) => menuTypes[menuTypeId]?.name)
                              .filter((name) => name)
                              .join(' and ')}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              ))}
            </div>
            <ALaCarteSidebar districtId={districtId} open={sidebarOpen} item={item} setSidebarOpen={setSidebarOpen} />
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default ALaCarte;
