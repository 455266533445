import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import React, { forwardRef, ReactElement, Ref } from 'react';

export interface CloseIconButtonProps {
  onClick: (event: React.MouseEvent) => void;
}

const CloseIconButton = forwardRef((props: CloseIconButtonProps, ref: Ref<HTMLButtonElement>): ReactElement => {
  const { onClick } = props;
  const className = classNames('linq-button-close-lg linq-drawer-header__close');

  return (
    <IconButton
      aria-label="close"
      ref={ref}
      className={className}
      onClick={onClick}
      type="button"
      data-test-id="closeIconBtn"
    >
      <span className="sr-only">Close</span>
      <CloseIcon />
    </IconButton>
  );
});

export default CloseIconButton;
