import * as Constants from 'Constants/Constants';
import * as Interfaces from 'Interfaces/Interfaces';
import { get } from 'lodash';
import { createSelector } from 'reselect';

import * as ShopSelectors from 'Pages/Shop/state/selectors';
import * as CommonSelectors from 'State/selectors';
import * as MenuTypePickerSelectors from 'Components/MenuTypePicker/state/selectors';

const root = (state: any) => {
  return get(state, 'cartState', {});
};

export const getAccountId = (state: object) => get(root(state), 'accountId');

export const getAccounts = (state: object) => get(root(state), 'accounts');

export const getAccount = (state: object) => {
  const accountId = getAccountId(state);
  const accounts = getAccounts(state);

  if (accounts && accountId) {
    return accounts[accountId];
  }

  return {};
};

export const getMenuItems = (state: object): Record<string, Interfaces.CartItem> =>
  get(getAccount(state), 'menuItems', {});
const getChildMenuItems = (state: object): Record<string, Interfaces.CartItem[]> =>
  get(getAccount(state), 'childMenuItems', {});
export const getPickupLocationOptionsByItem = (state: object): Record<string, Interfaces.CartItem[]> =>
  get(getAccount(state), 'pickupLocationOptionsByItem', {});
export const getPurchasedMenuItems = (state: object): Record<string, Interfaces.CartItem> =>
  get(getAccount(state), 'purchasedMenuItems', {});
export const getPurchasedChildMenuItems = (state: object): Record<string, Interfaces.CartItem[]> =>
  get(getAccount(state), 'purchasedChildMenuItems', {});
export const getPurchasedPickupLocations = (state: object): { [key: string]: string } =>
  get(getAccount(state), 'purchasedPickupLocations', {});
export const getError = (state: Interfaces.AppState): string => get(getAccount(state), 'error');
export const getOrderId = (state: Interfaces.AppState): string => get(getAccount(state), 'purchasedOrderId');
export const getMenuItemIds = createSelector(getMenuItems, (menuItems) => {
  return Object.values(menuItems).reduce((acc: { [key: string]: string }, menuItem: Interfaces.CartItem) => {
    acc[menuItem.id] = menuItem.id;
    return acc;
  }, {});
});

export const getCartTotals = createSelector([getMenuItems], (menuItems) => {
  const totals = {
    entrees: 0,
    alacarte: 0,
  };

  Object.keys(menuItems).forEach((key) => {
    const item = menuItems[key];
    const total = item.count;

    if (!item) {
      return;
    }

    if (item.productCategoryId === Constants.PRODUCT_CATEGORIES.ENTREE.id) {
      totals.entrees += total;
    } else if (item.productCategoryId === Constants.PRODUCT_CATEGORIES.ALACARTE.id) {
      totals.alacarte += total;
    }
  });

  return totals;
});

export const getShowCart = (state: object) => get(getAccount(state), 'showCart');
export const getShowPickup = (state: object) => get(getAccount(state), 'showPickup');

const constructCheckout = (
  menuItems: { [key: string]: Interfaces.CartItem },
  childMenuItems: { [key: string]: Interfaces.CartItem[] },
  pickupLocationIds: { [key: string]: string },
  pickupLocations: { [key: string]: Interfaces.PickupLocation },
  menuTypes: Record<string, Interfaces.MenuType>,
  includeChildItemsInName?: boolean,
) => {
  let data: any = {
    total: 0,
    count: 0,
    items: {},
  };

  Object.keys(menuItems).forEach((id) => {
    const itemCount = menuItems[id].count;
    if (!itemCount) return;
    const menuItem = menuItems[id];
    if (!menuItem) return;
    const menuType = menuTypes[menuItem.mealTypeId];
    const pickupLocationId = pickupLocationIds[menuItem.id];
    const pickupLocation = get(pickupLocations, pickupLocationId);

    const dateKey = menuItem?.date;
    let dateEntry = data.items[dateKey];
    if (!dateEntry) {
      dateEntry = [];
      data.items[dateKey] = dateEntry;
    }

    data.total += menuItem.price * itemCount;
    data.count += itemCount;

    let name = menuItem.productName;

    if (includeChildItemsInName) {
      const childItems = childMenuItems[id] ?? [];
      childItems.forEach((childItem) => {
        name += ` | ${childItem.productName}`;
      });
    }

    for (let i = 0; i < itemCount; ++i) {
      dateEntry.push({
        // TODO: Rename to menuType when API is ready
        mealType: menuType?.name,
        productName: name,
        price: menuItem.price,
        id: id,
        date: menuItem.date,
        pickupLocation: pickupLocation?.name,
        productCategoryId: menuItem.productCategoryId,
        items: childMenuItems[id] || [],
        comments: menuItem.comments,
      });
    }
  });

  return data;
};

export const getCheckout = createSelector(
  [
    getMenuItems,
    getChildMenuItems,
    getPurchasedPickupLocations,
    CommonSelectors.getPickupLocations,
    MenuTypePickerSelectors.getMenuTypes,
  ],
  (menuItems, childMenuItems, pickupLocationIds, pickupLocations, menuTypes) => {
    return constructCheckout(menuItems, childMenuItems, pickupLocationIds, pickupLocations, menuTypes);
  },
);

export const getOrderConfirmation = createSelector(
  [
    getPurchasedMenuItems,
    getPurchasedChildMenuItems,
    getPurchasedPickupLocations,
    CommonSelectors.getPickupLocations,
    MenuTypePickerSelectors.getMenuTypes,
  ],
  (menuItems, childMenuItems, pickupLocationIds, pickupLocations, menuTypes) => {
    return constructCheckout(menuItems, childMenuItems, pickupLocationIds, pickupLocations, menuTypes, true);
  },
);

export const getItemPickupLocations = (state: object) => get(getAccount(state), 'itemPickupLocations', {});

export const areAllPickupLocationsSelected = createSelector(
  [getMenuItemIds, getItemPickupLocations],
  (menuItemIds, itemPickupLocations) => {
    const ids = Object.keys(menuItemIds);
    return ids.every((id) => {
      return id in itemPickupLocations;
    });
  },
);

export const getOrderItems = createSelector(
  [
    getMenuItems,
    getChildMenuItems,
    MenuTypePickerSelectors.getMenuTypes,
    getItemPickupLocations,
    ShopSelectors.getStudent,
  ],
  (menuItems, childMenuItems, menuTypes, pickupLocations, student) => {
    const orderItems: Interfaces.OrderItem[] = [];

    Object.entries(menuItems).forEach(([id, item]) => {
      for (let i = 0; i < item.count; i++) {
        const oi = createOrderItem(id, menuItems, menuTypes, student, pickupLocations);
        if (oi) {
          orderItems.push(oi);
        }
      }
    });

    const childPickupLocations: { [key: string]: string } = {};

    Object.keys(childMenuItems).forEach((id) => {
      const childItems = childMenuItems[id];
      const parentPickupLocationId = pickupLocations[id];
      const childItemsMap = childItems.reduce((acc: Record<string, Interfaces.CartItem>, childItem) => {
        acc[childItem.id] = childItem;
        return acc;
      }, {});

      childItems.forEach((childItem) => {
        childPickupLocations[childItem.id] = parentPickupLocationId;
        const oi = createOrderItem(childItem.id, childItemsMap, menuTypes, student, childPickupLocations);
        if (oi) {
          oi.price = 0;
          orderItems.push(oi);
        }
      });
    });

    return orderItems;
  },
);

const createOrderItem = (
  id: string,
  menuItems: Record<string, Interfaces.CartItem>,
  mealTypes: Record<string, Interfaces.MenuType>,
  student: Interfaces.Student,
  pickupLocations: any,
): Interfaces.OrderItem | null => {
  const menuItem = menuItems[id];
  if (!menuItem) {
    return null;
  }

  const menuType =
    menuItem.mealTypeId === Constants.UNASSOCIATED_MEAL_TYPE_ID
      ? Constants.UNASSOCIATED_MEAL_TYPE
      : mealTypes[menuItem.mealTypeId];
  const pickUpLocationId = pickupLocations[id];

  return {
    id: '',
    districtId: student?.districtId,
    studentId: student?.id,
    // TODO: Rename to menuTypeId when API ready
    mealTypeId: menuType?.id ?? '',
    mealTypeName: menuType?.name,
    productId: menuItem.productId,
    date: menuItem?.date,
    pickupLocationId: pickUpLocationId,
    price: menuItem.price,
    siteId: menuItem.siteId,
    homeroomId: student?.homeroomId,
    productCategoryId: menuItem.productCategoryId,
    comments: menuItem.comments,
  };
};

export const getCartItemCountFactory = (id: string) =>
  createSelector([getMenuItems], (menuItems) => {
    const menuItem = menuItems[id];

    return menuItem ? menuItem.count : 0;
  });
