import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useApi } from 'Api/useApi';
import { SubHeader } from 'Components/SubHeader/SubHeader';
import { RouteId, getRoutePath } from 'Constants/Routes';
import ProfileForm from 'Components/ProfileForm/ProfileForm';
import { globalConfig } from '../../config';

const Register = () => {
  const api = useApi();
  const navigate = useNavigate();
  const { loginWithRedirect, user } = useAuth0();
  const [loading, setLoading] = React.useState(false);
  const appConfig = globalConfig.getPortalConfig();

  React.useEffect(() => {
    if (user) {
      navigate(getRoutePath(RouteId.ROOT));
    }
  }, [user, navigate]);

  const handleSubmit = async (formValues: any) => {
    try {
      setLoading(true);
      const results = (await api.post(`/utility/users/selfSignUp`, {
        firstName: formValues.firstName.trim(),
        lastName: formValues.lastName.trim(),
        email: formValues.email.trim(),
        password: formValues.password,
        clientId: appConfig.clientId,
      })) as any;

      if (results) {
        loginWithRedirect();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-layout-family">
        <SubHeader title="Welcome to LINQ Online Ordering!" className="sub-header-family" />
        <ProfileForm handleSubmit={handleSubmit} loading={loading} />
      </div>
    </React.Fragment>
  );
};

export default Register;
