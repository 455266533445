import React from 'react';
import { findIndex } from 'lodash';

export const useTableCheckboxSelection = () => {
  const [selectedIds, setSelectedIds] = React.useState<{ [key: string]: any }>({});
  const [previouslySelectedId, setPreviouslySelectedId] = React.useState<string | undefined>(undefined);

  const handleAllCheckboxSelection = React.useCallback((all: boolean, rows: any, fieldName: string) => {
    if (all) {
      const newSelectedIds: { [key: string]: any } = {};
      rows.forEach((row: any) => (newSelectedIds[row.original[fieldName]] = true));

      setSelectedIds(newSelectedIds);
    } else {
      setSelectedIds({});
    }
  }, []);

  const handleCheckboxSelection = React.useCallback(
    (id: string, shiftDown: boolean, sortedRows: any, fieldName: string) => {
      const newSelectedIds = { ...selectedIds };

      const shouldShiftSelect = previouslySelectedId && shiftDown;

      if (shouldShiftSelect) {
        //get the selected indexes based on the previous and current selections
        const previousSelectionIndex = findIndex(
          sortedRows,
          (row: any) => row.original[fieldName] === previouslySelectedId,
        );
        const currentSelectionIndex = findIndex(sortedRows, (row: any) => row.original[fieldName] === id);

        //determine which of the indexes is the start index and end which is the end index, then slice this section from the row data
        const startIndex = Math.min(previousSelectionIndex, currentSelectionIndex);
        const endIndex = Math.max(previousSelectionIndex, currentSelectionIndex);

        const rows = sortedRows.slice(startIndex, endIndex + 1);

        //determine the selection behavior based on whether the previous selection and current selections are being checked or unchecked
        const firstAndLastChecked = newSelectedIds[previouslySelectedId] || !newSelectedIds[id];

        //update the checked state
        rows.forEach((row: any) => {
          if (firstAndLastChecked) {
            newSelectedIds[row.original[fieldName]] = true;
          } else {
            delete newSelectedIds[row.original[fieldName]];
          }
        });
      } else {
        if (newSelectedIds[id]) {
          delete newSelectedIds[id];
        } else {
          newSelectedIds[id] = true;
        }
      }

      setPreviouslySelectedId(id);
      setSelectedIds(newSelectedIds);
    },
    [previouslySelectedId, selectedIds],
  );

  const clearSelectedIds = React.useCallback(() => {
    setSelectedIds({});
  }, []);

  return { selectedIds, handleAllCheckboxSelection, handleCheckboxSelection, clearSelectedIds, setSelectedIds };
};
