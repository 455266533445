import { useDispatch } from 'react-redux';
import { District, Site } from 'Interfaces/Interfaces';
import { PARENT_DASHBOARD_SET_STUDENT_DISTRICTS, PARENT_DASHBOARD_SET_STUDENT_SITES } from './types';
import { useApi } from 'Api/useApi';
import { useEffect } from 'react';

/**
 * Most parent student relationships will exist in a single district.
 * There could be an instance where a parent has multiple students in multiple
 * districts. Load all the district information based on the number of districts ids.
 * This information gets stored in a the parent state to avoid collisions
 * in state management with the common district state.
 * @param districtIds
 */
export const useStudentDistricts = (districtIds: string[]) => {
  const dispatch = useDispatch();
  const api = useApi();

  useEffect(() => {
    async function getStudentDistricts() {
      try {
        let districts: { [key: string]: District } = {};
        const promises = districtIds.map((districtId: string) => {
          return api.get(`/districts/${districtId}`);
        });

        const results = (await Promise.all(promises)) as any[];

        if (results.length) {
          districts = results.reduce(
            (acc: { [productId: string]: District }, { data: district }: { data: District }) => {
              if (district) {
                acc[district.id] = district;
              }
              return acc;
            },
            {},
          );
        }

        dispatch(setStudentDistricts(districts));
      } catch (err) {
        console.error(err);
      }
    }
    getStudentDistricts();
  }, [api, dispatch, districtIds]);
};

export const useStudentSites = (districtIds: string[]) => {
  const dispatch = useDispatch();
  const api = useApi();

  useEffect(() => {
    async function getStudentSites() {
      let sites: { [key: string]: Site } = {};
      const promises = districtIds.map((districtId: string) => {
        return api.get(`/districts/${districtId}/sites`);
      });

      const results = (await Promise.all(promises)) as any[];
      if (results.length) {
        sites = results.reduce((acc: { [productId: string]: Site }, { data: { items } }: any) => {
          return { ...acc, ...items };
        }, {});
      }
      dispatch(setStudentSites(sites));
    }
    getStudentSites();
  }, [api, dispatch, districtIds]);
};

export const setStudentDistricts = (payload: { [key: string]: District }) => ({
  type: PARENT_DASHBOARD_SET_STUDENT_DISTRICTS,
  payload,
});

export const setStudentSites = (payload: { [key: string]: Site }) => ({
  type: PARENT_DASHBOARD_SET_STUDENT_SITES,
  payload,
});
