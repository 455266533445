import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { USD_FORMAT } from 'Constants/Constants';
import { AppState, OrderingRules } from 'Interfaces/Interfaces';
import numeral from 'numeral';
import Card from 'Components/Card/Card';
import * as CommonStateSelectors from 'State/selectors';
import * as CommonStateActions from 'State/actions';
import ReimbursableRulesSidebar from '../Sidebars/ReimbursableRulesSidebar';
import { SidebarLocations } from 'Constants/Enums';

export const RuleReimbursable: FC<{ orderingRules: OrderingRules }> = ({ orderingRules = {} }) => {
  const sidebarOpen = useSelector((state: AppState) =>
    CommonStateSelectors.getSidebarOpen(state, SidebarLocations.REIMB_RULES_SIDE_BAR),
  );
  const dispatch = useDispatch();

  const setSidebarOpen = (isOpen: boolean) => {
    dispatch(CommonStateActions.setSidebarOpen(isOpen, SidebarLocations.REIMB_RULES_SIDE_BAR));
  };

  return (
    <>
      <Card
        title="Reimbursable Meal Balance"
        description="Set minimum required meals balance to order reimbursable meals"
        onEditClick={() => setSidebarOpen(true)}
        editAriaLabel="Edit Reimbursable Meal Rules"
      >
        <div className="ordering-rules__rules">
          <div className="ordering-rules__setting">
            <h6 className="linq-card__h6">Minimum Balance</h6>
            <div className="setting-value" data-test-id={'mealMinimumBalanceLbl'}>
              {!orderingRules.reimbursableMinimum ? '-' : numeral(orderingRules.reimbursableMinimum).format(USD_FORMAT)}
            </div>
          </div>
        </div>
      </Card>
      <ReimbursableRulesSidebar open={sidebarOpen} setSidebarOpen={setSidebarOpen} orderingRules={orderingRules} />
    </>
  );
};
