import { Reducer } from 'redux';

import * as Types from './types';
import * as Interfaces from 'Interfaces/Interfaces';

const initialState: Interfaces.MenuTypePickerState = {
  menuTypeId: null,
  menuTypes: {},
};

const MealTypePickerReducer: Reducer<Interfaces.MenuTypePickerState, Interfaces.Action> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case Types.MENU_TYPE_PICKER_SET_MEAL_TYPE_ID:
      return {
        ...state,
        menuTypeId: payload,
      };
    case Types.MENU_TYPE_PICKER_SET_MEAL_TYPES:
      return {
        ...state,
        menuTypes: payload,
      };
    default:
      return state;
  }
};

export default MealTypePickerReducer;
