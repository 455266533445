import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import { SubHeader } from 'Components/SubHeader/SubHeader';

import {
  RuleALaCarte,
  RuleOrderTime,
  RulePickupLocations,
  RuleLabels,
  RuleBlackoutDates,
  RuleReimbursable,
  RuleOrderComments,
  RuleMenuOrder,
  RuleBulkOrderMode,
} from 'Components/OrderingRules/Rules';

import * as CommonSelectors from 'State/selectors';
import * as Constants from 'Constants/Constants';
import { TokenClaims } from 'Auth/User';

import './OrderingRules.less';

const OrderingRules: FC<{}> = () => {
  const { user } = useAuth0();
  const districtId = TokenClaims.getDistrictId(user);
  const district = useSelector(CommonSelectors.getDistrict(districtId));
  const orderingRules = district?.orderingRules;
  const isMobile = useMediaQuery({ query: Constants.MOBILE_MEDIA_QUERY });

  return (
    <div className={classNames('ordering-rules-container', { 'ordering-rules-container-mobile': isMobile })}>
      <SubHeader title={'Ordering Rules'} />
      <div className="content">
        <RuleOrderTime orderingRules={orderingRules} />
        <RulePickupLocations orderingRules={orderingRules} />
        <RuleLabels orderingRules={orderingRules} />
        <RuleReimbursable orderingRules={orderingRules} />
        <RuleALaCarte orderingRules={orderingRules} />
        <RuleBlackoutDates orderingRules={orderingRules} />
        <RuleBulkOrderMode orderingRules={orderingRules} />
        <RuleOrderComments orderingRules={orderingRules} />
        <RuleMenuOrder orderingRules={orderingRules} />
      </div>
    </div>
  );
};

export default OrderingRules;
